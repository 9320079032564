export const UploadIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="61.1" height="62.1" viewBox="0 0 61.1 62.1"><defs><style>.a{fill:#dbdbdb;}.b{fill:#007e7a;}</style></defs><g transform="translate(-690 -235)"><path class="a" d="M715.8,285.9H704.7V297H701V285.9H690v-3.7h11.1V271.1h3.7v11.1h11.1v3.7ZM741.6,238a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H712.2v-3.7H738V279.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V241.7H704.9v22.1h-3.7V238Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V279.5Z"/><path class="b" d="M717.8,282.9H706.7V294H703V282.9H692v-3.7h11.1V268.1h3.7v11.1h11.1v3.7ZM743.6,235a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H714.2v-3.7H740V276.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V238.7H706.9v22.1h-3.7V235Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V276.5Z"/></g></svg>';

//AJUSTAR URL ABAIXO PRA ARQUIVO .ENV LIKE
export const storageAccUrl = process.env.REACT_APP_AZURE_STORAGE;

//#region PRESS TEMPLATES
export const PressBoilerPlatePT = `<p id="boilerPlateVale" 
                                        align="left"
                                        style="margin-bottom: 80px; 
                                        clear: both; 
                                        display: block;
                                        margin-top: 40px;
                                        color:#747678;
                                        line-height: 28px; 
                                        font-family: Arial, Helvetica, sans; 
                                        font-size: 11pt;">
                                            <strong>Sobre a Vale</strong>
                                            <br>
                                            <br>
                                            A Vale é uma mineradora global que existe para melhorar a vida e transformar o futuro juntos. Uma das maiores produtoras mundiais de minério de ferro e níquel e importante produtora de cobre, a Vale tem sede no Brasil e atuação ao redor do mundo. Suas operações compreendem sistemas logísticos integrados, incluindo aproximadamente 2.000 quilômetros de ferrovias, terminais marítimos e 10 portos distribuídos pelo mundo. A Vale tem as ambições de ser reconhecida pela sociedade como referência em segurança, a melhor operadora e a mais confiável, uma organização orientada aos talentos, líder em mineração sustentável, e referência em criação e compartilhamento de valor.
                                    </p>`;

export const PressBoilerPlateEN = `<p id="boilerPlateVale"
                                        align="left"
                                        style="margin-bottom: 80px; 
                                        clear: both; 
                                        display: block;
                                        margin-top: 40px;
                                        color:#747678;
                                        line-height: 28px; 
                                        font-family: Arial, Helvetica, sans; 
                                        font-size: 11pt;">
                                            <strong>About Vale</strong>
                                            <br>
                                            <br>
                                            Vale is a global mining company that exists to improve lives and transform the future together. One of the world's largest producers of iron ore and nickel and a major copper producer, Vale is headquartered in Brazil and operates around the world. Its operations comprise integrated logistics systems, including approximately 2,000 kilometres of railways, marine terminals and 10 ports distributed around the globe. Vale has the ambition to be recognized by society as a benchmark in safety, the best-in-class reliable operator, a talent-driven organization, a leader in sustainable mining, and a benchmark in creating and sharing value.
                                    </p>`;



export const PressFooterPT = `<p style="color: #747678;font-family: Arial;font-size: 10pt;margin-top: 20px;">
                                    © `+ new Date().getFullYear() +` - Vale | Todos os direitos reservados
                              </p>`;

export const PressFooterEN = `<p style="color: #747678;font-family: Arial;font-size: 10pt;margin-top: 20px;">
                                    © `+ new Date().getFullYear() +` - Vale | All rights reserved
                              </p>`;
                            
export const PressHeaderPT = `<span style="font-size: 38px; 
                                color: #686868; 
                                font-family: Caecilia">
                                Media Release
                              </span>`;   
                        
export const PressHeaderEN = `<span style="font-size: 38px; 
                                color: #686868; 
                                font-family: Caecilia">
                                Media Release
                              </span>`;   
//#endregion