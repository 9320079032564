import * as React from 'react';
import { Container, Row } from 'reactstrap';
import { GlobalStateContext } from '../general/GlobalState';
import { useTranslation } from 'react-i18next';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { Buttons } from '../utilities/Buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {
  GridComponent,
  ColumnsDirective,
  CommandColumn,
  ColumnDirective,
  Edit,
  Toolbar,
  Filter,
  Inject,
  ExcelExport,
  PdfExport,
  Sort,
  ContextMenu,
  Page,
  Resize,
  colGroupRefresh,
} from '@syncfusion/ej2-react-grids';
import { Browser } from '@syncfusion/ej2-base';
import i18n from 'i18next';
import {
  getManageInternalAreas,
  addManageInternalAreas,
  updateManageInternalAreas,
  enableDisableInternalArea,
} from './manageInternalAreasSVC';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import GenericModal from '../utilities/MessageModal/GenericModal';
import PermissionRedirect from '../utilities/PermissionRedirect/PermissionRedirect';

import { useForm } from 'react-hook-form';
import { hasPermissions } from '../utilities/Utils';

let userPermission;

export const Internal = () => {
  const [globalState] = React.useContext(GlobalStateContext);
  const { t } = useTranslation();
  const [manageDataArr, setManageDataArr] = React.useState([]);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [msgToShow, setMsgToShow] = React.useState('');
  const [modalData, setModalData] = React.useState();
  const [newOrEditArea, setNewOrEditArea] = React.useState('');
  const gridInstance = React.useRef();
  const dialogInstance = React.useRef();
  const [modalMessageParams, setmodalMessageParams] = React.useState({
    show: false,
  });

  const { register, handleSubmit, errors, reset, clearError } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ativo: true,
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const contextMenuItems = [
    'SortAscending',
    'SortDescending',
    'FirstPage',
    'PrevPage',
    'LastPage',
    'NextPage',
  ];


  React.useEffect(() => {
    gridInstance.current.refresh();
  }, [i18n.language]);

  React.useEffect(() => {
    buildInternalAreal();
  }, []);

  React.useEffect(() => {
    if (msgToShow) {
      let timer = setTimeout(() => {
        setMsgToShow('');
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [msgToShow]);

  const buildInternalAreal = async () => {
    await getManageInternalAreas(globalState.accessToken).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          setManageDataArr(data);
        });
      }
    });
  };

  React.useEffect(() => {
    let globalPermissions = globalState.permissions;
    userPermission = globalPermissions.filter((i) => i.idAreaAcesso === 27).map((i) => (i.permission));
  }, [globalState]);

  const active_colum = (args) => {
    return (
      <>
        {args.ativo ? (
          <span className="active yes">{t('ManageInternalAreas:yes')}</span>
        ) : (
          <span className="active no">{t('ManageInternalAreas:no')}</span>
        )}
      </>
    );
  };

  const openEdit = (args) => {
    setNewOrEditArea('EDIT');
    setModalData(args);
    setOpenEditModal(true);
  };

  const clearModal = () => {
    reset();
    setModalData(null);
    setOpenEditModal(false);
  };

  const editTemplate = (args) => {
    return hasPermissions(27, "Edit", globalState) && (
      <div className="editItems">
        <span onClick={() => openEdit(args)} className="mdl2 mdl2-pencil" />
      </div>
    );
  };

  const actionComplete = (args) => {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      if (Browser.isDevice) {
        args.dialog.height = window.innerHeight - 90 + 'px';
        args.dialog.dataBind();
      }
    }
  };

  const buildDialogMessage = (type, msg) => {
    if (type ==='success') {
      setmodalMessageParams({
        title: t('ModalMessages:title'),
        message: t('ModalMessages:created'),
        type: 'success',
        icon: 'mdl2-confirm',
        show: true,
      });
    } else if (type ==='error') {
      setmodalMessageParams({
        title: t('ManageInternalAreas:error_title'),
        message: t('ManageInternalAreas:error'),
        type: 'error',
        icon: 'e-icon-dlg-close e-icons',
        show: true,
      });
    } else if (type ==='updated') {
      setmodalMessageParams({
        title: t('ModalMessages:title'),
        message: t('ModalMessages:updated'),
        type: 'success',
        icon: 'mdl2-confirm',
        show: true,
      });
    } else if (type ==='custom-error') {
      setmodalMessageParams({
        title: t('ManageInternalAreas:error_title'),
        message: msg,
        type: 'error',
        icon: 'e-icon-dlg-close e-icons',
        show: true,
      });
    }
  };

  const handleNewArea = async (data) => {
    const addItem = async (dataInput) => {
      const data = {
        nome: dataInput.nome,
        ativo: true,
      };
      setManageDataArr((prevstate) => {
        const newDataArr = prevstate;
        newDataArr.unshift(data);
        return newDataArr;
      });
      gridInstance.current.refresh();
      return await addManageInternalAreas(globalState.accessToken, data);
    };

    gridInstance.current.refresh();
    dialogInstance.current.hide();
    const res = await addItem(data);
    if (res.ok && (res.status ===200 || res.status ===201)) {
      dialogInstance.current.hide();
      buildDialogMessage('success');
    } else {
      buildDialogMessage('error');
      console.log(res);
    }
    reset();
  };

  const updateItem = async (dataInput) => {
    // const promises = [];

    const dataToPersist = {
      id: modalData.id,
      nome: dataInput.nome,
      ativo: dataInput.ativo ==='yes' ? true : false,
    };


    try {
      updateManageInternalAreas(globalState.accessToken, dataToPersist).then(
        async (response) => {
          if (response.ok || response.status ===200) {
            setManageDataArr(
              gridInstance.current.dataSource.map((item) => {
                if (item.id ===modalData.id) item = dataToPersist;
                return item;
              }),
            );
            gridInstance.current.refresh();
            buildDialogMessage('updated');
            setOpenEditModal(false);
          } else {
            const err = await response.json()
            const msg = JSON.stringify(err.Mensagem && err.Mensagem ==="Existe pelo menos um item de orçamento de publicidade vinculado a essa área interna." 
            ? i18n.language === 'pt'? err.Mensagem : "There is at least one advertising budget item linked to this internal area." :
              `Ocorreu um erro no sistema. Favor entrar em contato com o administrador - OperationId: ${err.OperationId}`)
            buildDialogMessage('custom-error', msg)
            console.log(err);
          }
          reset();
        },
      );
    } catch (error) {
      // setOpenEditModal(false);
     console.log(error);
      buildDialogMessage('custom-error', error)
    }
  };
  const onSubmit = (data) => {
    if (newOrEditArea ==='NEW') handleNewArea(data);
    else if (newOrEditArea ==='EDIT') updateItem(data);
  };
  const onError = (errors, e) => console.log(errors, e);
  return (
    <>
      <PermissionRedirect id="27" />
      <div className="topTitleButtons">
        <Row>
          <div className="col-sm-8  col-12">
            <div className="title">
              <h1>{t('ManageInternalAreas:title')}</h1>
            </div>
          </div>
          <div className="col-sm-4 col-12">
            <div className="buttons">
              <Buttons
                controls={{
                  permit: userPermission ? userPermission : null,
                  new: () => {
                    setNewOrEditArea('NEW');
                    dialogInstance.current.show();
                  },
                }}
              />
            </div>
          </div>
        </Row>
      </div>
      <GenericModal params={modalMessageParams} />
      <div id="Annual">
        <div className="topContent">
          <span className="mdl2 mdl2-areas" />
          <label>{t('ManageInternalAreas:internal_area')}</label>
        </div>

        <DialogComponent
          id="modalAddOrEdit"
          width="500px"
          cssClass={'resett'}
          isModal={true}
          visible={openEditModal}
          ref={dialogInstance}
          showCloseIcon={true}
          close={clearModal}
          locale={i18n.language}
        >
          <form id="addEditDialog" onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="input">
              <label htmlFor="nome">{t('ManageInternalAreas:column_area')}</label>
              <div className="e-float-input">
                <TextBoxComponent
                  id="nome"
                  name="nome"
                  required={true}
                  htmlAttributes={{ maxlength: "100" }}
                  placeholder={t('ManageInternalAreas:placeholder_nome')}
                  validationRules={{ required: true }}
                  type="text"
                  ref={register({ required: true })}
                  value={modalData && modalData.nome}
                />
              </div>
              {errors.nome && <p className="error">{t('Validation:required')}</p>}
            </div>

            {newOrEditArea ==='EDIT' && (
              <div className="input">
                <label htmlFor="ativo" style={{ padding: '10px 72px 10px 0' }}>
                  {t('ManageInternalAreas:column_active')}
                </label>
                <div className="e-float-input">
                  <DropDownListComponent
                    id="ativo"
                    value={modalData ? (modalData.ativo ? 'yes' : 'no') : null}
                    name="ativo"
                    validationRules={{ required: true }}
                    fields={{ text: 'text', value: 'value' }}
                    placeholder={t('ManageInternalAreas:placeholder_ativo')}
                    dataSource={[
                      { text: t('ManageInternalAreas:yes'), value: 'yes' },
                      { text: t('ManageInternalAreas:no'), value: 'no' },
                    ]}
                    ref={register({ required: true })}
                  />
                </div>
                {errors.ativo && (
                  <p className="error">{t('Validation:required')}</p>
                )}
              </div>
            )}
            <div className="btn-wrapper">
              {newOrEditArea ==='NEW' && (
                <ButtonComponent cssClass="btn-modal" type="submit">
                  {t('Actions:send')}
                </ButtonComponent>
              )}

              {newOrEditArea ==='EDIT' && (
                <ButtonComponent cssClass="btn-modal" type="submit">
                  {t('Actions:save')}
                </ButtonComponent>
              )}
            </div>
          </form>
        </DialogComponent>

        <GridComponent
          id="managementItems"
          contextMenuItems={contextMenuItems}
          dataSource={manageDataArr}
          filterSettings={{ type: 'Menu' }}
          allowPaging={true}
          ref={gridInstance}
          pageSettings={{
            pageSize: 10,
            pageSizes: true,
          }}
          allowSorting={true}
          allowFiltering={true}
          locale={i18n.language}
          allowExcelExport={true}
          allowPdfExport={true}
          actionComplete={(e) => {
            actionComplete(e);
          }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={t('ManageInternalAreas:column_area')}
              field="nome"
              width="750"
              validationRules={{ required: true }}
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('ManageInternalAreas:column_active')}
              filter={{ type: 'CheckBox' }}
              filterItemTemplate={args => args.ativo ? t('ManageInternalAreas:yes') : t('ManageInternalAreas:no')}
              template={(args) => active_colum(args)}
              field="ativo"
              width="170"
              editType="booleanEdit"
            ></ColumnDirective>
            <ColumnDirective
              template={(args) => editTemplate(args)}
              width="80"
              headerText={t('ManageInternalAreas:edit')}
            ></ColumnDirective>
          </ColumnsDirective>
          <Inject
            services={[
              CommandColumn,
              Page,
              Sort,
              Filter,
              Toolbar,
              Edit,
              ExcelExport,
              PdfExport,
              Toolbar,
              ContextMenu,
              Resize,
            ]}
          />
        </GridComponent>
      </div>
    </>
  );
};
