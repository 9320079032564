import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, VirtualScroll, Sort, ContextMenu, Page, Resize, Edit } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent, ChangeEventArgs, MultiSelectComponent, DropDownTreeComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
//import { createSpinner, setSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';

import "../users/users.css"

import { users } from "../../services/UsersSVC";
import { getProfileTemplate } from "../../services/ProfileTemplateSVC";
import { getPermissions, getAccessAreas } from "../../services/SystemSVC";
import { createSpinner, hideSpinner, setSpinner, showSpinner } from "@syncfusion/ej2-popups";

let refresh;
let gridInstance;
let fullAcessArea = [];

export const TemplatesLists = (props) => {

    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const [profileTemplates, setProfileTemplates] = React.useState([]);
    const [isVisible, setIsVisible] = React.useState(false);

    const [fillUsersData, setFillUsersData] = React.useState([]);
    const [fillAccessAreasData, setFillAccessAreasData] = React.useState([]);
    const [fillPermissionsData, setFillPermissionsData] = React.useState([]);
    const [allTemplates, setAllTemplates] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true);

    const [placeHolderNome, setPlaceHolderNome] = React.useState();
    const [placeHolderAreaAcesso, setPlaceHolderAreaAcesso] = React.useState();
    const [placeHolderPermissao, setPlaceHolderPermissao] = React.useState();
    const [filter, setFilter] = React.useState({});

    const { t } = useTranslation();

    const contextMenuItems = ['SortAscending', 'SortDescending', 'FirstPage', 'PrevPage', 'LastPage', 'NextPage'];

    React.useEffect(() => {
        async function load() {
            // let _users = await users(globalState.accessToken)
            //     .then(response => {
            //         if (response.ok) {
            //             return response.json();
            //         }
            //     })
            let _accessAreas = await getAccessAreas(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                })
            let _permissions = await getPermissions(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                })
            let _profileTemplates = await getProfileTemplate(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                })

            if (_profileTemplates && _accessAreas) {
                _profileTemplates.map((pt) => {
                    pt.permissions.map((pe) => {
                        let area = _accessAreas.filter(i => i.id ===pe.idAccessArea);
                        if (area.length > 0) {
                            pe.area = area[0];
                        }
                    })
                })
            }

            // setFillUsersData(_users);
            setFillAccessAreasData(fillAreas(_accessAreas));
            //setFillAccessAreasData(_accessAreas);
            setFillPermissionsData(_permissions);
            setProfileTemplates(_profileTemplates);
            setAllTemplates(_profileTemplates);

            fullAcessArea = fillAreas(_accessAreas);
            hideSpinner(document.getElementById('loading'));
            setLoading(false);

        }
        load();
        setPlaceHolderNome(t("Search:place_holder"));
        setPlaceHolderAreaAcesso(t("Permissions:access_title"));
        setPlaceHolderPermissao(t("Permissions:permission_title"));

    }, [i18n.language]);

    const fillAreas = (items, currentItem = null, child = false) => {
        let arr = [];
        items.map((item) => {
            if (!child) {
                arr.push({ value: item.id, text: i18n.language ==="pt" ? item.nomePt : item.nomeEn, hasChildren: item.filhos.length > 0 ? true : false, breadCrumbAccessAreaEn: item.breadCrumbEn, breadCrumbAccessAreaPt: item.breadCrumbPt, isFather: true });
                if (item.filhos.length > 0) {
                    let children = fillAreas(item.filhos, item, true);
                    arr = arr.concat(children);
                }
            } else {
                arr.push({ value: item.id, text: i18n.language ==="pt" ? item.nomePt : item.nomeEn, parentValue: item.idPai, hasChildren: item.filhos.length > 0 ? true : false, breadCrumbAccessAreaEn: item.breadCrumbEn, breadCrumbAccessAreaPt: item.breadCrumbPt, isFather: false });
                if (item.filhos.length > 0) {
                    let children = fillAreas(item.filhos, item, true);
                    arr = arr.concat(children);
                }
            }
        });
        return arr;
    }

    const accessColumn = (args) => {

        let auxAccess = [];

        if (args.permissions.length) {
            args.permissions.map(item => {

                let cAcessArea = fullAcessArea.filter(aa => {
                    return aa.value === item.idAccessArea;
                });

                auxAccess.push(<span className="span_area">{cAcessArea[0].text}</span>);
            }
            )
        }

        return auxAccess;
    };

    const columnName = (args) => {
        return (
            <span onClick={() => props.edit(args.id)}>{args.nome}</span>
        );
    };

    const inputChange = e => {
        setFilter({ ...filter, [e.event.currentTarget.id]: e.value });
    }

    const dropdownTreeChange = e => {
        let arr = [];
        e.value.map(item => {
            const acessAreas = fillAccessAreasData.filter(_item => _item.value ===item && !_item.hasChildren).map(acessArea => String(acessArea.value));
            if (acessAreas.length)
                arr = arr.concat(acessAreas);
        });

        setFilter({ ...filter, [e.element.id]: arr })
    }

    const multiSelectChange = e => {
        setFilter({ ...filter, [e.element.id]: e.value })
    }

    //Search

    const filterTemplate = () => {

        let listTemplate = allTemplates;
        //Por nome
        if (filter.name)
            listTemplate = listTemplate.filter(item => item.nome.toLowerCase().includes(filter.name.toLowerCase()));

        if (filter.permissions && filter.permissions.length) {
            let arrPermissionsPt = [];
            filter.permissions.map(permissionId => {
                const itemPermission = fillPermissionsData.find(item => item.id === permissionId);
                if (itemPermission)
                    arrPermissionsPt.push(itemPermission.nomePt);
            });

            listTemplate = listTemplate.filter(templateItem => {

                let permissionsResult = arrPermissionsPt.every(item => {
                    return templateItem.permissions.filter(_item => _item.permissionsPt.includes(item)).length > 0;
                });
                let areasResult = filter && filter.areas && filter.areas.length > 0 && filter.areas.every(item => {
                    return templateItem.permissions.filter(_item => _item.idAccessArea ===item).length > 0;
                });

                return permissionsResult || areasResult;
            });
        }
        else {
            if (filter.areas && filter.areas.length) {
                listTemplate = listTemplate.filter(templateItem => {
                    const items = templateItem.permissions.filter(_item => filter.areas.indexOf(_item.idAccessArea.toString()) > -1);
                    return items.length === filter.areas.length;
                });
            }
        }
        gridInstance.dataSource = listTemplate;
        setIsVisible(false);
    }

    React.useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language]);

    React.useEffect(() => {
        if (isLoading && document.getElementById('loading')) {
            createSpinner({ target: document.getElementById('loading') })
            showSpinner(document.getElementById('loading'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [isLoading]);

    //End Search

    return (
        <>
            <div className={`busca ${isVisible ? "aberto" : ""}`}>
                <div className="filtroUsuarios d-flex">
                    <div className="SearchTitle">
                        <span className="mdl2 mdl2-search"></span>
                        <p>{t("Search:title")}</p>
                    </div>
                    <div className="searchUsers">
                        <div className="e-input-in-wrap">
                            <TextBoxComponent
                                className="e-input"
                                id="name"
                                placeholder={placeHolderNome}
                                change={inputChange}
                            />
                        </div>
                    </div>
                    <div className="btnBusca d-flex">
                        {!isVisible &&
                            <div className="btnFilter">
                                <ButtonComponent className="e-outline filter" cssClass='e-primary filter' onClick={() => setIsVisible(!isVisible)}><span className="mdl2 mdl2-filter"></span>{t("FilterButtons:filter")}</ButtonComponent>
                            </div>
                        }
                        {isVisible &&
                            <div className="btnFilter">
                                <ButtonComponent className="e-outline filter active-filter" cssClass='e-primary filter' onClick={() => setIsVisible(!isVisible)}><span className="mdl2 mdl2-filter"></span>{t("FilterButtons:filter")}</ButtonComponent>
                            </div>
                        }
                        <div className="btnSearch">
                            <ButtonComponent
                                cssClass='e-primary search'
                                onClick={filterTemplate}
                            >{t("FilterButtons:search")}</ButtonComponent>
                        </div>
                    </div>
                </div>
                {isVisible &&
                    <div className="filtrosOcultos dois d-flex">
                        <DropDownTreeComponent
                            id="areas"
                            name="dropdownAcessArea"
                            cssClass="e-filled"
                            changeOnBlur={false}
                            //selectAllText={t('Permissions:selectAllTitle')}
                            fields={{ dataSource: fillAccessAreasData, value: "value", text: "text", parentValue: "parentValue", hasChildren: "hasChildren" }}
                            showCheckBox={true}
                            //showSelectAll={true}
                            treeSettings={{ autoCheck: true }}
                            placeholder={placeHolderAreaAcesso}
                            value={filter.areas}
                            change={dropdownTreeChange}
                            locale={i18n.language}
                        />
                        <span className="mb-0">
                            <MultiSelectComponent
                                id="permissions"
                                cssClass="e-filled"
                                placeholder="Permissões"
                                dataSource={fillPermissionsData}
                                fields={{ text: (i18n.language ==="pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
                                placeholder={placeHolderPermissao}
                                value={filter.permissions}
                                change={multiSelectChange}
                            />
                        </span>
                    </div>
                }
            </div>
            { isLoading ? <div id="loading" /> :
                <div className="listaUsuarios">
                    <GridComponent
                        id='gridcomp'
                        dataSource={profileTemplates}
                        //filterSettings={{ type: 'Menu' }}
                        allowFiltering={false}
                        allowPaging={true}
                        pageSettings={{ pageSize: 10 }}
                        allowSorting={true}
                        contextMenuItems={contextMenuItems}
                        ref={grid => gridInstance = grid}
                        locale={i18n.language}>
                        <ColumnsDirective >
                            <ColumnDirective
                                template={columnName}
                                field='nome'
                                headerText={t("TemplateTable:name")}
                                width='300'></ColumnDirective>
                            <ColumnDirective
                                template={accessColumn}
                                //field={(i18n.language ==="pt") ? 'permissions.area.nomePt' : 'permissions.area.nomeEn' }
                                headerText={t("TemplateTable:access")}
                                width='500'></ColumnDirective>
                            <ColumnDirective
                                textAlign={"Center"}
                                field='usuariosVinculados'
                                headerText={t("TemplateTable:numberUsers")}
                                width='200'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Edit]} />
                    </GridComponent>

                </div>
            }
        </>
    );
}

