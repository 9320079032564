import React from 'react';
import { useTranslation } from 'react-i18next';

const VideoContent = ({ country, approval, category, regional }) => {
    const { t } = useTranslation();

    return (
        <div className="dropContent">
            <div className="leftContent">
                <label>{t("DigitalMedia:approver")}</label>
                <label>{t("DigitalMedia:category")}</label>
                <label>{t("DigitalMedia:country")}</label>
                <label>{t("DigitalMedia:regional")}</label>
            </div>
            <div className="rightContent">
                <label>{approval}</label>
                <label>{category}</label>
                <label>{country}</label>
                <label>{regional}</label>
            </div>
        </div>
    );
}

export default VideoContent;