import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../general/GlobalState";
import { Container, Row } from "reactstrap";
import { DataManager, Query, ODataV4Adaptor } from '@syncfusion/ej2-data';
import { createSpinner, showSpinner, setSpinner, hideSpinner, DialogComponent } from "@syncfusion/ej2-react-popups";
import { TextBoxComponent, MaskedTextBoxComponent, UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { AutoCompleteComponent, DropDownListComponent, MultiSelectComponent, CheckBoxSelection, Inject } from "@syncfusion/ej2-react-dropdowns";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  getPressMediaTypes,
  getCountries,
  getStates,
  getCities,
  getLiaisonStatus,
  getLiaisonActionTypes,
  getBusinessAreas,
  getRegional
} from "../../services/SystemSVC";
import {
  LiaisonTO,
  MailingContactTO,
  LiaisonAttachment,
  LiaisonHistory,
  addLiaisonItem,
  updateLiaisonItem,
  getLiaisonItem,
  downloadLiaisonAttachment,
  deleteLiaisonItem,
  duplicateLiaisonItem
} from "../../services/LiaisonSVC";
import { getMailingContactItems, getMailingGroupItems } from "../../services/MailingSVC";
import { getProfile } from "../../services/UsersSVC";
import { Actions } from "../utilities/Actions";
import { formatBytes, hasPermissions } from "../utilities/Utils";
import { useForm } from "react-hook-form";
import moment from 'moment';
import './liaison.css';
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";
import { countriesJSON } from '../../resources/countries.js';

export const LiaisonItem = (props) => {

  const countryDefault = countriesJSON.find(i => i.namePt === 'Brasil');

  const { t } = useTranslation();
  const { register, unregister, triggerValidation, errors, clearError } = useForm();
  let itemId = props.match.params.id;
  let uploaderRef;
  let dropAreaRef;
  let ddlStatesRef;
  let ddlCitiesRef;
  let txtEmailRef;
  let txtEditorialRef;
  let txtOccupationRef;
  let txtAddressRef;
  let txtDescriptionRef;
  let txtResponsaveisRef;
  let ddlMailingContactGroupRef;
  let dtConclusionRef;

  const target = document.getElementById("attachsDropAreaEle");
  const placeholderIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="42" viewBox="0 0 40 42"><defs><style>.liaisonA,.liaisonC{fill:#007e7a;font-family:FabricExternalMDL2Assets, Fabric External MDL2 Assets;}.liaisonA{font-size:30px;}.liaisonB{fill:#fff;}.liaisonC{font-size:20px;}</style></defs><g transform="translate(-218 -230)"><text class="liaisonA" transform="translate(218 260)"><tspan x="0" y="0"></tspan></text><circle class="liaisonB" cx="12" cy="12" r="12" transform="translate(234 248)"/><text class="liaisonC" transform="translate(237 270)"><tspan x="0" y="0"></tspan></text></g></svg>';

  const initialMailingContactTO = {
    email: [],
    telefone: []
  }

  const [globalState] = React.useContext(GlobalStateContext);
  const [redirect, setRedirect] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [loadedCount, setLoadedCount] = React.useState(0);
  const [loaded, setLoaded] = React.useState(false);
  const [hideMailingState, setHideMailingState] = React.useState('hide');
  const [hideMailingCity, setHideMailingCity] = React.useState('hide');
  const [jornalistSelected, setJornalistSelected] = React.useState(false);

  const [liaisonTO, setLiaisonTO] = React.useState(new LiaisonTO());
  const [mailingContatoTO, setMailingContatoTO] = React.useState(initialMailingContactTO);
  const [liaisonAttachments, setLiaisonAttachments] = React.useState([]);
  const [insertMailing, setInsertMailing] = React.useState(false);

  const [fillMediaTypesDataPt, setFillMediaTypesDataPt] = React.useState([]);
  const [fillMediaTypesDataEn, setFillMediaTypesDataEn] = React.useState([]);

  const [isLoadingItem, setIsLoadingItem] = React.useState(false);

  const [fillVehicleData, setFillVehicleData] = React.useState([]);

  const [fillBusinessAreaDataPt, setFillBusinessAreaDataPt] = React.useState([]);
  const [fillBusinessAreaDataEn, setFillBusinessAreaDataEn] = React.useState([]);

  const [fillCountryDataPt, setFillCountryDataPt] = React.useState([]);
  const [fillCountryDataEn, setFillCountryDataEn] = React.useState([]);

  const [fillStateDataPt, setFillStateDataPt] = React.useState([]);
  const [fillStateDataEn, setFillStateDataEn] = React.useState([]);

  const [fillCityDataPt, setFillCityDataPt] = React.useState([]);
  const [fillCityDataEn, setFillCityDataEn] = React.useState([]);

  const [fillLiaisonStatusDataPt, setFillLiaisonStatusDataPt] = React.useState([]);
  const [fillLiaisonStatusDataEn, setFillLiaisonStatusDataEn] = React.useState([]);

  const [fillLiaisonActionTypesDataPt, setFillLiaisonActionTypesDataPt] = React.useState([]);
  const [fillLiaisonActionTypesDataEn, setFillLiaisonActionTypesDataEn] = React.useState([]);

  const [fillRegionalDataPt, setFillRegionalDataPt] = React.useState([]);
  const [fillRegionalDataEn, setFillRegionalDataEn] = React.useState([]);

  const [fillMailingContactGroupData, setFillMailingContactGroupData] = React.useState([]);
  const [dialogDelete, setDialogDelete] = React.useState(false);
  const [openCountries, setOpenCountries] = React.useState(null);
  const [dataCountries, setDataCountries] = React.useState([]);
  const [profile, setProfile] = React.useState(null);
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const [dataFlags, setDataFlags] = React.useState([]);

  const [newPhone, setNewPhone] = React.useState(
    () => {
      if (mailingContatoTO.telefone.length > 0) {
        return mailingContatoTO.telefone;
      }
      return [{
        telefone: "",
        status: 0,
        country: countryDefault
      }]
    }
  );

  const [newEmail, setNewEmail] = React.useState(
    () => {
      return [{
        email: "",
        status: 0
      }]
    }
  );

  const phoneRef = React.useRef();

  const handlePhoneRef = (ref) => {
    if (phoneRef?.current?.contains(ref.target)) {
      return;
    }
    setOpenCountries(null);
  }

  const jornalistDataManager = new DataManager({
    url: process.env.REACT_APP_API_URL + '/_api/MailingContact/GetMailingContactItems',
    adaptor: new ODataV4Adaptor,
    crossDomain: true,
    headers: [{ 'Authorization': `Bearer ${globalState.accessToken}` }]
  });

  const _controls = {
    class: "liaison",
    savePrimary: false,
    customPermissions: itemId ? hasPermissions(7, "Edit", globalState) : hasPermissions(7, "Create", globalState),
    urlDuplicateLiaison: itemId ? (e) => duplicateLiaison(e) : null,
    urlSave: (e) => addOrUpdateRelease(e),
    urlCancel: () => setRedirect(true),
    urlDelete: itemId ? (e) => {
      e.preventDefault();
      setDialogDelete(true);
    } : null
  };

  React.useEffect(() => {
    createSpinner({ target: document.getElementById('loadingItem') })
    showSpinner(document.getElementById('loadingItem'));
    setSpinner({ type: 'Bootstrap' });
  }, [isLoadingItem]);

  const deleteItem = (e) => {
    e.preventDefault();
    deleteLiaisonItem(globalState.accessToken, itemId)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then(result => {
        setRedirect(true);
      })
      .catch(error => {
        console.error(error);
      })
  };

  const initialLoad = () => {
    getPressMediaTypes(globalState.accessToken)
      .then(response => {
        if (response.ok) {
          response.json().then(data => configMediaTypes(data));
        }
      });

    getMailingContactItems(globalState.accessToken, "?$apply=groupby((nomeVeiculo))&$orderby=NomeVeiculo")
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            setFillVehicleData(data);
            setLoadedCount(loadedCount => loadedCount + 1);
          });
        }
      });

    getMailingGroupItems(globalState.accessToken, "?$filter=not(conjunto)&$select=id,nome")
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            setFillMailingContactGroupData(data);
            setLoadedCount(loadedCount => loadedCount + 1);
          });
        }
      });

    getCountries(globalState.accessToken)
      .then(response => {
        if (response.ok) {
          response.json().then(data => configCountries(data));
        }
      });

    getStates(globalState.accessToken)
      .then(response => {
        if (response.ok) {
          response.json().then(data => configStates(data));
        }
      });

    getCities(globalState.accessToken)
      .then(response => {
        if (response.ok) {
          response.json().then(data => configCities(data));
        }
      });

    getLiaisonStatus(globalState.accessToken)
      .then(response => {
        if (response.ok) {
          response.json().then(data => configLiaisonStatus(data));
        }
      });

    getLiaisonActionTypes(globalState.accessToken)
      .then(response => {
        if (response.ok) {
          response.json().then(data => configLiaisonActionTypes(data));
        }
      });

    getBusinessAreas(globalState.accessToken)
      .then(response => {
        if (response.ok) {
          response.json().then(data => configBusinessArea(data));
        }
      });

    getRegional(globalState.accessToken)
      .then(response => {
        if (response.ok) {
          response.json().then(data => configRegionals(data));
        }
      });
  };

  const configMediaTypes = (_mediaTypes) => {
    setFillMediaTypesDataPt(_mediaTypes.map(x => {
      return {
        id: x.id,
        nome: x.nomePt
      };
    }));
    setFillMediaTypesDataEn(_mediaTypes.map(x => {
      return {
        id: x.id,
        nome: x.nomeEn
      };
    }));
    setLoadedCount(loadedCount => loadedCount + 1);
  };

  const configCountries = (_contries) => {
    setFillCountryDataPt(_contries.map(x => {
      return {
        id: x.id,
        nome: x.nomePt
      };
    }));
    setFillCountryDataEn(_contries.map(x => {
      return {
        id: x.id,
        nome: x.nomeEn
      };
    }));
    setLoadedCount(loadedCount => loadedCount + 1);
  };

  const configStates = (_states) => {
    setFillStateDataPt(_states.map(x => {
      return {
        id: x.id,
        nome: x.nomePt,
        idPais: x.idPais
      };
    }));
    setFillStateDataEn(_states.map(x => {
      return {
        id: x.id,
        nome: x.nomeEn,
        idPais: x.idPais
      };
    }));
    setLoadedCount(loadedCount => loadedCount + 1);
  };

  const configCities = (_cities) => {
    setFillCityDataPt(_cities.map(x => {
      return {
        id: x.id,
        nome: x.nomePt,
        idEstado: x.idEstado
      };
    }));
    setFillCityDataEn(_cities.map(x => {
      return {
        id: x.id,
        nome: x.nomeEn,
        idEstado: x.idEstado
      };
    }));
    setLoadedCount(loadedCount => loadedCount + 1);
  };

  const configLiaisonStatus = (_liaisonStatus) => {
    setFillLiaisonStatusDataPt(_liaisonStatus.map(x => {
      return {
        id: x.id,
        nome: x.nomePt
      };
    }));
    setFillLiaisonStatusDataEn(_liaisonStatus.map(x => {
      return {
        id: x.id,
        nome: x.nomeEn
      };
    }));
    setLoadedCount(loadedCount => loadedCount + 1);
  };

  const configLiaisonActionTypes = (_liaisonActionTypes) => {
    setFillLiaisonActionTypesDataPt(_liaisonActionTypes.map(x => {
      return {
        id: x.id,
        nome: x.nomePt
      };
    }));
    setFillLiaisonActionTypesDataEn(_liaisonActionTypes.map(x => {
      return {
        id: x.id,
        nome: x.nomeEn
      };
    }));
    setLoadedCount(loadedCount => loadedCount + 1);
  };

  const configBusinessArea = (_businessAreas) => {
    setFillBusinessAreaDataPt(_businessAreas.map(x => {
      return {
        id: x.id,
        nome: x.nomePt
      };
    }));
    setFillBusinessAreaDataEn(_businessAreas.map(x => {
      return {
        id: x.id,
        nome: x.nomeEn
      };
    }));
    setLoadedCount(loadedCount => loadedCount + 1);
  };

  const configRegionals = (_regionals) => {
    setFillRegionalDataPt(_regionals.map(x => {
      return {
        id: x.id,
        nome: x.nomePt
      };
    }));
    setFillRegionalDataEn(_regionals.map(x => {
      return {
        id: x.id,
        nome: x.nomeEn
      };
    }));
    setLoadedCount(loadedCount => loadedCount + 1);
  };

  const addOrUpdateRelease = async (e) => {
    e.preventDefault();
    setDisabledBtn(true);
    triggerValidation().then((validation) => {
      if (validation) {
        setIsLoadingItem(true);
        liaisonTO.anexos = liaisonAttachments ? liaisonAttachments : null;
        liaisonTO.mailingContato = mailingContatoTO ? mailingContatoTO : null;
        liaisonTO.jornalista = mailingContatoTO.jornalista ? mailingContatoTO.jornalista : null;
        liaisonTO.idTipoMidia = mailingContatoTO.idtipoMidia ? mailingContatoTO.idtipoMidia : null;
        liaisonTO.nomeVeiculo = mailingContatoTO.nomeVeiculo ? mailingContatoTO.nomeVeiculo : null;
        liaisonTO.email = mailingContatoTO.email ? mailingContatoTO.email : null;

        if (mailingContatoTO?.id) {
          liaisonTO.mailingContato.telefone = mailingContatoTO && mailingContatoTO.telefone.length > 0 ? mailingContatoTO.telefone.map(phone => ({
            ...phone,
            country: phone.country.nameEn
          })) : [];
          liaisonTO.telefone = [];
        } else {
          liaisonTO.telefone = newPhone && newPhone.length > 0 ? newPhone.map(phone => ({
            ...phone,
            country: phone.country.nameEn
          })) : [];
          liaisonTO.mailingContato.telefone = []
        }

        liaisonTO.dataConclusao = liaisonTO.dataConclusao ? moment(liaisonTO.dataConclusao).format('YYYY-MM-DDTHH:mm:ss') : null;
        liaisonTO.dataRecebimento = liaisonTO.dataRecebimento ? moment(liaisonTO.dataRecebimento).format('YYYY-MM-DDTHH:mm:ss') : null;

        liaisonTO.inserirMailing = insertMailing;

        const addOrUpdate = itemId ? updateLiaisonItem : addLiaisonItem;

        addOrUpdate(globalState.accessToken, JSON.stringify(liaisonTO))
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              return Promise.reject(response);
            }
          })
          .then(result => {
            setRedirect(true);
            setDisabledBtn(false);
          })
          .catch(error => {
            setIsLoadingItem(false);
            console.error(error);
            setDisabledBtn(false);
          })
      }
      setDisabledBtn(false);
    }).catch((error) => {
      console.log(error);
      setDisabledBtn(false);
    });
  }

  const triggerDuplicate = () => {
    duplicateLiaisonItem(globalState.accessToken, JSON.stringify(liaisonTO))
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then(() => {
        setRedirect(true);
      })
      .catch(error => {
        setIsLoadingItem(false);
        console.error(error);
      })
  }

  const duplicateLiaison = async (e) => {
    e.preventDefault();

    triggerValidation().then((validation) => {
      if (validation) {
        setIsLoadingItem(true);
        liaisonTO.anexos = liaisonAttachments ? liaisonAttachments : null;
        liaisonTO.mailingContato = mailingContatoTO ? mailingContatoTO : null;
        liaisonTO.jornalista = mailingContatoTO.jornalista ? mailingContatoTO.jornalista : null;
        liaisonTO.idTipoMidia = mailingContatoTO.idtipoMidia ? mailingContatoTO.idtipoMidia : null;
        liaisonTO.nomeVeiculo = mailingContatoTO.nomeVeiculo ? mailingContatoTO.nomeVeiculo : null;
        liaisonTO.email = mailingContatoTO.email ? mailingContatoTO.email : null;

        liaisonTO.mailingContato.telefone = mailingContatoTO && mailingContatoTO.telefone.length > 0 ? mailingContatoTO.telefone.map(phone => ({
          ...phone,
          country: phone.country.nameEn
        })) : [];
        liaisonTO.telefone = [];

        liaisonTO.dataConclusao = liaisonTO.dataConclusao ? moment(liaisonTO.dataConclusao).format('YYYY-MM-DDTHH:mm:ss') : null;
        liaisonTO.dataRecebimento = liaisonTO.dataRecebimento ? moment(liaisonTO.dataRecebimento).format('YYYY-MM-DDTHH:mm:ss') : null;
        liaisonTO.deadLine = liaisonTO.deadLine ? moment(liaisonTO.deadLine).format('YYYY-MM-DDTHH:mm:ss') : null;

        liaisonTO.inserirMailing = insertMailing;

        triggerDuplicate();
      }
    }).catch((error) => {
      console.log(error);
    });

  }

  const uploadAttachment = () => {
    document.getElementsByClassName("e-file-select-wrap")[0].querySelector("button").click();
    return false;
  }

  const uploaderCreated = () => {
    if (uploaderRef) {
      uploaderRef.dropArea = dropAreaRef;
      uploaderRef.dataBind();
    }
  }

  const onSetAttachmentsFiles = event => {
    event.filesData.map((file) => {
      const reader = new FileReader();
      reader.addEventListener("load", function () {
        let filePartsB64 = reader.result.split(',');
        let attach = new LiaisonAttachment(file.id, file.rawFile.type, file.rawFile.name);
        attach.fileB64 = filePartsB64[1];
        attach.status = 0;
        setLiaisonAttachments(liaisonAttachments => [...liaisonAttachments, attach]);
      }, false);
      reader.readAsDataURL(file.rawFile);
    });
  }

  const onRemovingAttachsFiles = (args) => {
    args.filesData.map((file) => {
      setLiaisonAttachments(liaisonAttachments => liaisonAttachments.filter((x) => (x.id !== file.id)));
    });
  };

  const jornalistNameSelected = (args) => {
    if (args.itemData) {
      let mailingContatoTO = new MailingContactTO(args.itemData.id,
        args.itemData.jornalista,
        args.itemData.idtipoMidia,
        args.itemData.nomeVeiculo,
        args.itemData.email,
        args.itemData.telefone,
        args.itemData.idpais,
        args.itemData.idestado,
        args.itemData.idcidade,
        args.itemData.editoria,
        args.itemData.cargo,
        args.itemData.endereco);

      setJornalistSelected(true);
      setMailingContatoTO(mct => mailingContatoTO);
      setNewEmail(mailingContatoTO.email);
      const newTelefone = mailingContatoTO.telefone.map(phone => ({
        ...phone,
        country: countriesJSON.find(country => country.nameEn === phone.country)
      }))
      setNewPhone(newTelefone);
      setLiaisonTO(lto => ({
        ...liaisonTO,
        mailingContato: mailingContatoTO,
        jornalista: null,
        idTipoMidia: null,
        nomeVeiculo: null,
        email: [],
        telefone: []
      }));
    }
  }

  const jornalistNameChanged = (args) => {
    if (!args.itemData) {
      setJornalistSelected(false);
      setInsertMailing(false);

      if (jornalistSelected) {
        setMailingContatoTO(mct => initialMailingContactTO);
      } else {
        setMailingContatoTO(mct => ({ ...mailingContatoTO, jornalista: null }));
      }
    } else {
      setMailingContatoTO(mct => ({ ...mailingContatoTO, jornalista: args.itemData.jornalista ? args.itemData.jornalista : null }));
    }
  }

  const setPropertyLiaisonTOState = (fieldName, fieldValue) => {
    setLiaisonTO(lto => ({ ...liaisonTO, [fieldName]: fieldValue }));
  };

  const addNewPhone = () => {
    const phones = newPhone.filter(phone => phone.status !== 2);

    if (phones) {
      setNewPhone(newPhone => [...newPhone, { telefone: "", status: 0, country: countryDefault, }])
    }
  }

  const addNewEmail = () => {
    const emails = newEmail.filter(email => email.status !== 2);

    if (emails) {
      setNewEmail(newEmail => [...newEmail, { email: "", status: 0 }])
    }
  }

  const removePhone = (index) => {
    let temp = [...newPhone];
    if (temp[index] !==null) {
      if (temp[index].telefone !=="") {
        let pos = mailingContatoTO.telefone.map(function (e) { return e.telefone; }).indexOf(temp[index].telefone);
        let phoneFilter = mailingContatoTO.telefone.filter(findPhone => findPhone.telefone ===temp[index].telefone)[0];
        if (phoneFilter.status !==0) {
          if (mailingContatoTO?.id) {
            mailingContatoTO.telefone[pos].status = 2;
          } else {
            temp[index].status = 2;
          }
        } else {
          mailingContatoTO.telefone.splice(pos, 1);
        }
      } if (temp[index].status ===0) {
        temp.splice(index, 1);
      }
    }
    setNewPhone(
      newPhone => [...temp]
    );

  }

  const handleChangePhone = (index, e) => {
    let value = e.value

    if (value) {
      if (newPhone[index].status ===3 || newPhone[index].status ===2) {
        newPhone[index].status = 1;
      }

      newPhone[index].telefone = e.value
    } else if (newPhone[index].status !== 0) {
      newPhone[index].status = 2;
    }
    if (newPhone[index].telefone !=="") {
      let phoneFilter = mailingContatoTO.telefone && mailingContatoTO.telefone.length > 0 && mailingContatoTO.telefone.filter(findPhone => findPhone.telefone ===newPhone[index].telefone);
      if (phoneFilter.length ===0) {
        mailingContatoTO.telefone.push(newPhone[index]);
      }
    }
    setMailingContatoTO(mailingTO => ({ ...mailingTO, telefone: newPhone }))
  }

  const removeEmail = (index) => {
    if (newEmail[index] !==null) {
      if (newEmail[index].email !=="") {
        let pos = mailingContatoTO.email.map(function (e) { return e.email; }).indexOf(newEmail[index].email);
        let phoneFilter = mailingContatoTO.email.filter(findEmail => findEmail.email ===newEmail[index].email)[0];
        if (phoneFilter.status !==0) {
          mailingContatoTO.email[pos].status = 2;
        } else {
          mailingContatoTO.email.splice(pos, 1);
        }
      } else if (newEmail[index].status ===0) {
        newEmail.splice(index, 1);
      }
    }
    setNewEmail(
      newEmail => [...newEmail]
    );

    setMailingContatoTO(mailingTO => ({ ...mailingTO, email: [...newEmail] }))
  }

  const handleChangeEmail = (index, e) => {
    let value = e.value

    if (value) {
      if (newEmail[index].status ===3 || newEmail[index].status ===2) {
        newEmail[index].status = 1;
      }

      newEmail[index].email = e.value
    } else if (newEmail[index].status !== 0) {
      newEmail[index].status = 2;
    }
    if (newEmail[index].email !=="") {
      let emailFilter = mailingContatoTO.email.filter(findEmail => findEmail.email ===newEmail[index].email);
      if (emailFilter.length ===0) {
        mailingContatoTO.email.push(newEmail[index]);
      }
    }
    setMailingContatoTO(mailingTO => ({ ...mailingTO, email: newEmail }))
  }

  const setPropertyMailingContactTOState = (fieldName, fieldValue) => {
    setMailingContatoTO(mto => ({ ...mailingContatoTO, [fieldName]: fieldValue }));
  };

  const countryMailingContactOnChange = (e) => {
    if (e.itemData) {
      ddlStatesRef.dataSource = i18n.language === 'en' ? fillStateDataEn : fillStateDataPt;
      ddlStatesRef.query = new Query().where('idPais', 'equal', e.itemData.id);
      ddlStatesRef.enabled = true;
      ddlStatesRef.text = null;
      ddlStatesRef.dataBind();

      setMailingContatoTO(mto => ({ ...mailingContatoTO, idpais: e.itemData.id }));
      e.itemData.id === 6 ? setHideMailingState(hms => '') : setHideMailingState(hms => 'hide');
    } else {
      if (ddlStatesRef) {
        ddlStatesRef.dataSource = null;
        ddlStatesRef.query = null;
        ddlStatesRef.enabled = false;
        ddlStatesRef.text = null;
        ddlStatesRef.dataBind();
      }

      if (ddlCitiesRef) {
        ddlCitiesRef.dataSource = null;
        ddlCitiesRef.query = null;
        ddlCitiesRef.enabled = false;
        ddlCitiesRef.text = null;
        ddlCitiesRef.dataBind();
      }

      setMailingContatoTO(mto => ({ ...mailingContatoTO, idpais: null, idestado: null, idcidade: null }));
      setHideMailingState(hms => 'hide');
      setHideMailingCity(hms => 'hide');
    }
  };

  const stateMailingContactOnChange = (e) => {
    if (e.itemData) {
      ddlCitiesRef.dataSource = i18n.language === 'en' ? fillCityDataEn : fillCityDataPt;
      ddlCitiesRef.query = new Query().where('idEstado', 'equal', e.itemData.id);
      ddlCitiesRef.enabled = true;
      ddlCitiesRef.text = null;
      ddlCitiesRef.dataBind();

      setMailingContatoTO(mto => ({ ...mailingContatoTO, idestado: e.itemData.id }));
      setHideMailingCity(hms => '');
    } else {
      if (ddlCitiesRef) {
        ddlCitiesRef.dataSource = null;
        ddlCitiesRef.query = null;
        ddlCitiesRef.enabled = false;
        ddlCitiesRef.text = null;
        ddlCitiesRef.dataBind();
      }

      setMailingContatoTO(mto => ({ ...mailingContatoTO, idestado: null, idcidade: null }));
      setHideMailingCity(hms => 'hide');
    }
  };

  const manageInsertMailingTO = () => {
    if (!jornalistSelected) {
      setInsertMailing(im => !insertMailing);
    }
  };

  const refEmail = (emailRef) => {
    register({ required: true, pattern: /^\S+@\S+$/ });
    txtEmailRef = emailRef;
  };

  const stateMailingContactGroupOnChange = (e) => {
    if (e.isInteracted) {
      let mailingContactGroups = e.value.map((value) => {
        return { id: value };
      });
      setMailingContatoTO({ ...mailingContatoTO, grupos: mailingContactGroups });
    }
  };

  const groupCheckboxSelectionCreated = (e) => {
    if (ddlMailingContactGroupRef) {
      ddlMailingContactGroupRef.showSelectAll = true;
      ddlMailingContactGroupRef.selectAllText = t('Generic:multiSelect_selectAll');
      ddlMailingContactGroupRef.unSelectAllText = t('Generic:multiSelect_unselectAll');
    }
  };

  const downloadAttachment = (fileName) => {
    downloadLiaisonAttachment(globalState.accessToken, itemId, fileName)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const removeAttachment = (fileName) => {
    let itemsArray = liaisonAttachments.filter(x => x.fileName !== fileName);
    let item = liaisonAttachments.filter(x => x.fileName === fileName);
    item[0].status = 2;
    setLiaisonAttachments(itemsArray.concat(item));
  };

  React.useEffect(() => {
    if (loading) {
      createSpinner({ target: document.getElementById('loadLiaison') });
      showSpinner(document.getElementById('loadLiaison'));
      setSpinner({ type: 'Bootstrap' });
    }
    uploaderCreated();
  }, [loading]);

  const now = new Date;

  React.useEffect(() => {
    setLiaisonTO({
      ...liaisonTO,
      responsaveis: globalState.userName,
      idRegional: globalState?.regional?.id,
      dataRecebimento: new Date(),
    })



    async function getItems() {
      let _countries = await getCountries(globalState.accessToken)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        });
      const currentCountries = countriesJSON.filter(country => {
        return _countries.filter(item => item.nomeEn === country.nameEn).length > 0
      })
      setDataCountries(currentCountries);
      setDataFlags(currentCountries)

      const newProfile = await getProfile(globalState.accessToken, globalState.userId)
        .then(res => {
          if (res.ok) {
            return res.json();
          }
        })
      setProfile(newProfile)
    }
    getItems();

    function getRefPhone() {
      document.addEventListener("mousedown", handlePhoneRef);

      return () => {
        document.removeEventListener("mousedown", handlePhoneRef);
      };
    };
    getRefPhone();

    initialLoad();
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        return false;
      }
    });
    uploaderCreated();
  }, []);

  React.useEffect(() => {
    if (!openCountries) {
      setDataFlags(dataCountries);
    }
  }, [openCountries]);

  React.useEffect(() => {
    setMailingContatoTO({
      ...mailingContatoTO,
      idPais: profile?.idPais,
      cargo: profile?.cargo,
      endereco: profile?.enderecoTrabalho
    })
  }, [profile]);


  React.useEffect(() => {
    if (txtEmailRef) txtEmailRef.placeholder = t('Liaison:email_placeholder');
    if (txtEditorialRef) txtEditorialRef.placeholder = t('Liaison:editorial_placeholder');
    if (txtOccupationRef) txtOccupationRef.placeholder = t('Liaison:occupation_placeholder');
    if (txtAddressRef) txtAddressRef.placeholder = t('Liaison:address_placeholder');
    if (txtDescriptionRef) txtDescriptionRef.placeholder = t('Liaison:staffDescription_placeholder');
    if (txtResponsaveisRef) txtResponsaveisRef.placeholder = t('Liaison:responsibles_placeholder');
    if (ddlMailingContactGroupRef) {
      ddlMailingContactGroupRef.selectAllText = t('Generic:multiSelect_selectAll');
      ddlMailingContactGroupRef.unSelectAllText = t('Generic:multiSelect_unselectAll');
    }
    if (dtConclusionRef) dtConclusionRef.placeholder = t('Liaison:conclusionDate_placeholder');
    uploaderCreated();
  }, [i18n.language]);

  React.useEffect(() => {
    if (loadedCount === 10) {
      if (itemId) {
        getLiaisonItem(globalState.accessToken, itemId)
          .then(response => {
            if (response.ok) {
              response.json().then(data => {
                let liaisonTO = new LiaisonTO(
                  data.id,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  data.idStatus,
                  data.dataConclusao,
                  data.descricao,
                  data.tipoAcao,
                  data.idAreaNegocio,
                  data.responsaveis,
                  data.idPais,
                  data.idRegional,
                  data.dataRecebimento,
                  null,
                  false,
                  data.autorRegistro);


                if (data.mailingContato) {

                  const newTelefone = data.mailingContato.telefone.map(phone => ({
                    ...phone,
                    country: countriesJSON.find(country => country.nameEn === phone.country)
                  }))

                  let mailingTO = new MailingContactTO(
                    data.mailingContato.id,
                    data.mailingContato.jornalista,
                    data.mailingContato.idtipoMidia,
                    data.mailingContato.nomeVeiculo,
                    data.mailingContato.email,
                    newTelefone,
                    data.mailingContato.idpais,
                    data.mailingContato.idestado,
                    data.mailingContato.idcidade,
                    data.mailingContato.editoria,
                    data.mailingContato.cargo,
                    data.mailingContato.endereco,
                    data.mailingContato.grupos);

                  liaisonTO.mailingContato = mailingTO;
                  setNewPhone(newTelefone)
                  setJornalistSelected(true);
                } else {
                  let mailingTO = new MailingContactTO(
                    null,
                    data.jornalista,
                    data.idTipoMidia,
                    data.nomeVeiculo,
                    data.email,
                    data.telefone);

                  const newTelefone = data.telefone.map(phone => ({
                    ...phone,
                    country: countriesJSON.find(country => country.nameEn === phone.country)
                  }))

                  setNewPhone(newTelefone)
                  liaisonTO.mailingContato = mailingTO;
                  setJornalistSelected(false);
                }

                if (data.historico && data.historico.length > 0) {
                  let histList = data.historico.map(item => {
                    let actionType = {
                      id: item.tipoAcao.id,
                      namePt: item.tipoAcao.nomePt,
                      nameEn: item.tipoAcao.nomeEn
                    };
                    return new LiaisonHistory(
                      item.IdAtendimento,
                      item.usuario,
                      actionType,
                      item.data
                    );
                  });

                  liaisonTO.historico = histList;
                }

                if (data.anexos) {
                  let attachments = data.anexos.map((item) => {
                    return new LiaisonAttachment(data.id, item.contentType, item.fileName, null, item.fileExtension, item.fileLength);
                  });
                  setLiaisonAttachments(x => attachments);
                }

                setLiaisonTO(lto => liaisonTO);
                setMailingContatoTO(mto => liaisonTO.mailingContato);
                setNewEmail(liaisonTO.mailingContato.email);
                setLoaded(true);
                uploaderCreated();
              });
            }
          });
      } else {
        setLoaded(true);
        uploaderCreated();
      }
    }

  }, [loadedCount]);

  React.useEffect(() => {
    if (loaded) {
      hideSpinner(document.getElementById('loadLiaison'));
      setLoading(false);
    }
    uploaderCreated();
  }, [loaded]);

  React.useEffect(() => {
    if (!insertMailing) unregister("country");
    uploaderCreated();
  }, [insertMailing]);

  React.useEffect(() => {
    if (mailingContatoTO && mailingContatoTO.id) {
      clearError(["name", "mediaType", "vehicle", "email", "country"]);
    }
    uploaderCreated();
  }, [mailingContatoTO]);

  React.useEffect(() => {
    uploaderCreated();
  }, [liaisonAttachments]);

  const handleChangeCountry = (country, index) => {
    const newSelectedPhone = [...newPhone];

    newSelectedPhone[index] = {
      ...newSelectedPhone[index],
      country: country,
      status: itemId && newSelectedPhone[index].id ? 1 : 0
    }

    setNewPhone(newSelectedPhone);
    setOpenCountries(null);
  }

  const newCountries = (item) => {
    const country = dataCountries.filter(i => i.nameEn === item);

    return country.map(i => i.flag);
  }

  const handleCountries = (index) => {
    if (!jornalistSelected) {
      setOpenCountries(index)
    }
  }

  const handleChangeFlags = (ev) => {
    const newDataFlags = dataCountries.filter(
      flags => ev.target.value !== ""
        ? i18n.language === "pt"
          ? flags?.namePt.toLowerCase().includes(ev.target.value.toLowerCase())
          : flags?.nameEn.toLowerCase().includes(ev.target.value.toLowerCase())
        : true
      );

    setDataFlags(newDataFlags);
  }

  return (
    <>
      {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
        redirect ? <Redirect push={true} to='/press/liaison' /> :
          <>
            <PermissionRedirect id="7" />
            <div className="DefaultTop" />
            <Container className="containerTop">
              <div className="topTitleButtons">
                <Row>
                  <div className="col-12">
                    <div className="title">
                      <h1>{itemId ? t('Liaison:edit_title') : t('Liaison:create_title')}</h1>
                    </div>
                  </div>
                </Row>
              </div>
              {isLoadingItem && <div id="loadingItem" />}
              {loading ? <div id="loadLiaison" /> :
                <form id="formLiaisonItem" style={{ display: 'inherit' }}>
                  <Row>
                    <div className="col-lg-3 col-12">
                      <div className="liaisonData item">
                        <div className="top">
                          <div className="av">
                            <div className="icon" dangerouslySetInnerHTML={{ __html: placeholderIcon }} />
                          </div>
                        </div>
                        <div className="input">
                          <label htmlFor="">{t('Liaison:name')}*</label>
                          <AutoCompleteComponent
                            name='name'
                            placeholder={t('Liaison:name_placeholder')}
                            dataSource={jornalistDataManager}
                            sortOrder="Ascending"
                            query={new Query()}
                            fields={{ value: 'jornalista' }}
                            select={jornalistNameSelected}
                            change={jornalistNameChanged}
                            value={mailingContatoTO ? mailingContatoTO.jornalista ? mailingContatoTO.jornalista : null : null}
                            minLength={3}
                            locale={i18n.language}
                            ref={register({ required: true })}
                            htmlAttributes={{ maxlength: 100 }}
                          />
                          {errors.name &&
                            <>
                              {errors.name.type === "required" &&
                                <label className="e-error" style={{ display: "block" }}>{t('Validation:required')}</label>
                              }
                            </>
                          }
                        </div>
                        <div className="input">
                          <label>{t('Liaison:mediaType')}*</label>
                          <DropDownListComponent
                            name='mediaType'
                            id='idTipoMidia'
                            placeholder={t('Liaison:mediaType_placeholder')}
                            locale={i18n.language}
                            dataSource={i18n.language === 'en' ? fillMediaTypesDataEn : fillMediaTypesDataPt}
                            fields={{ value: 'id', text: 'nome' }}
                            allowFiltering={true}
                            showClearButton={true}
                            value={mailingContatoTO ? mailingContatoTO.idtipoMidia ? mailingContatoTO.idtipoMidia : null : null}
                            enabled={!jornalistSelected}
                            change={(e) => setPropertyMailingContactTOState('idtipoMidia', e.itemData ? e.itemData.id : null)}
                            ref={register({ required: true })}
                            blur={() => triggerValidation('mediaType')}
                          />
                          {errors.mediaType &&
                            <>
                              {errors.mediaType.type === "required" &&
                                <label className="e-error" style={{ display: "block" }}>{t('Validation:required')}</label>
                              }
                            </>
                          }
                        </div>
                        <div className="input">
                          <label>{t('Liaison:vehicle')}*</label>
                          <AutoCompleteComponent
                            name='vehicle'
                            placeholder={t('Liaison:vehicle_placeholder_dropdown')}
                            locale={i18n.language}
                            dataSource={fillVehicleData}
                            fields={{ value: 'nomeVeiculo' }}
                            change={(e) => setPropertyMailingContactTOState('nomeVeiculo', e.itemData ? e.itemData.nomeVeiculo : null)}
                            value={mailingContatoTO ? mailingContatoTO.nomeVeiculo ? mailingContatoTO.nomeVeiculo : null : null}
                            enabled={!jornalistSelected}
                            ref={register({ required: true })}
                            htmlAttributes={{ maxlength: 100 }}
                            blur={() => triggerValidation('vehicle')}
                          />
                          {errors.vehicle &&
                            <>
                              {errors.vehicle.type === "required" &&
                                <label className="e-error" style={{ display: "block" }}>{t('Validation:required')}</label>
                              }
                            </>
                          }
                        </div>
                        <div className="input scroll-horizontal">
                          {newEmail.filter(phone => phone.status !== 2) &&
                            <div className='addLine'>
                              <label>{t('Liaison:email')}*</label>
                              {!jornalistSelected &&
                                <div className="mdl2 mdl2-plus"
                                  onClick={() => addNewEmail()}
                                />
                              }
                            </div>
                          }
                          {newEmail.length > 0 &&
                            newEmail.map((item, index) => item.status !== 2 && (
                              <div key={index}>
                                <TextBoxComponent
                                  name='email'
                                  id='email'
                                  placeholder={t('Liaison:email_placeholder')}
                                  locale={i18n.language}
                                  type='email'
                                  value={mailingContatoTO.email ? item.email : ""}
                                  change={(e) => handleChangeEmail(index, e)}
                                  enabled={!jornalistSelected}
                                  ref={register({ required: true, pattern: /^\S+@\S+$/ })}
                                  htmlAttributes={{ maxlength: 100 }}
                                  blur={() => triggerValidation('email')}
                                />
                                {!jornalistSelected &&
                                  <div className="mdl2 mdl2-minus"
                                    onClick={() => removeEmail(index)}
                                  />
                                }
                              </div>
                            ))
                          }
                          {errors.email &&
                            <>
                              {errors.email.type === "required" &&
                                <label className="e-error" style={{ display: "block" }}>{t('Validation:required')}</label>
                              }
                              {errors.email.type === "pattern" &&
                                <label className="e-error" style={{ display: "block" }}>{t('Validation:invalidMail')}</label>
                              }
                            </>
                          }
                        </div>
                        <div className="input scroll-horizontal">
                          {newPhone.filter(phone => phone.status !== 2) &&
                            <div className='addLine'>
                              <label>{t('Liaison:phone')}</label>
                              {!jornalistSelected &&
                                <div className="mdl2 mdl2-plus"
                                  onClick={() => addNewPhone()}
                                />
                              }
                            </div>
                          }
                          {newPhone.length > 0 &&
                            newPhone.map((item, index) => item.status !== 2 && (
                              <div key={index}>
                                <MaskedTextBoxComponent
                                  name='phone'
                                  id='telefone'
                                  className="numbers"
                                  locale={i18n.language}
                                  promptChar=" "
                                  mask={`${item?.country?.dialCode ? item.country.dialCode : '+'}[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]`}
                                  enabled={!jornalistSelected}
                                  value={mailingContatoTO.telefone ? item.telefone : ""}
                                  change={(e) => handleChangePhone(index, e)}
                                  htmlAttributes={{ maxlength: "20" }}
                                />
                                {!jornalistSelected &&
                                  <div className="mdl2 mdl2-minus"
                                    onClick={() => removePhone(index)}
                                  />
                                }
                                <div className="containerCountry" onClick={() => handleCountries(index)}>
                                  <img className='countryFlag' src={item?.country?.flag || newCountries(item?.country)} alt={i18n.language ==="pt" ? item?.country?.namePt : item?.country?.nameEn || item?.country} />
                                  <span className='countryIcon'>&#9660;</span>
                                </div>
                                {openCountries === index &&
                                  <div ref={phoneRef} className="dropDownList">
                                    <input onChange={handleChangeFlags} className="inputFlags" placeholder="Pesquise o país"/>
                                    {dataFlags.map((country, i) => (
                                      <div
                                        className="flex choosenLanguage e-control e-dropdown-btn e-lib e-btn"
                                        key={i}
                                        onClick={() => handleChangeCountry(country, index)}
                                      >
                                        <img className='countryFlag' src={country?.flag} alt={i18n.language ==="pt" ? country?.namePt : country?.nameEn} />
                                        <p className='countryName'>{country && `${i18n.language ==="pt" ? country?.namePt : country?.nameEn} ${country?.dialCode}`}</p>
                                      </div>
                                    ))}
                                  </div>
                                }
                              </div>
                            ))
                          }
                        </div>
                        {(!mailingContatoTO || (mailingContatoTO && !mailingContatoTO.id)) &&
                          <>
                            <div className="checkItem">
                              <CheckBoxComponent
                                label={t('Liaison:insertMailing')}
                                onChange={() => manageInsertMailingTO()}
                                checked={insertMailing}
                                locale={i18n.language}
                              />
                            </div>
                            {insertMailing &&
                              <>
                                <div className="input">
                                  <label>{t('Liaison:country')}</label>
                                  <DropDownListComponent
                                    name='country'
                                    placeholder={t('Liaison:country_placeholder')}
                                    dataSource={i18n.language === 'en' ? fillCountryDataEn : fillCountryDataPt}
                                    locale={i18n.language}
                                    fields={{ value: 'id', text: 'nome' }}
                                    allowFiltering={true}
                                    showClearButton={true}
                                    value={mailingContatoTO.idPais}
                                    change={countryMailingContactOnChange}
                                  />
                                </div>
                                <div className={`input ${hideMailingState}`}>
                                  <label>{t('Liaison:state')}</label>
                                  <DropDownListComponent
                                    name='state'
                                    placeholder={t('Liaison:state_placeholder')}
                                    locale={i18n.language}
                                    fields={{ value: 'id', text: 'nome' }}
                                    allowFiltering={true}
                                    showClearButton={true}
                                    value={mailingContatoTO ? mailingContatoTO.idestado : null}
                                    change={stateMailingContactOnChange}
                                    ref={(scope) => { ddlStatesRef = scope; }}
                                    enabled={false}
                                  />
                                </div>
                                <div className={`input ${hideMailingCity}`}>
                                  <label>{t('Liaison:city')}</label>
                                  <DropDownListComponent
                                    name='city'
                                    placeholder={t('Liaison:city_placeholder')}
                                    locale={i18n.language}
                                    fields={{ value: 'id', text: 'nome' }}
                                    allowFiltering={true}
                                    showClearButton={true}
                                    value={mailingContatoTO ? mailingContatoTO.idcidade : null}
                                    ref={(scope) => { ddlCitiesRef = scope; }}
                                    enabled={false}
                                    change={(e) => setPropertyMailingContactTOState('idcidade', e.itemData ? e.itemData.id : null)}
                                  />
                                </div>
                                <div className={`input`} id={'mailingContactGroupDiv'}>
                                  <label>{t('Liaison:group')}</label>
                                  <MultiSelectComponent
                                    name='mailingContactGroup'
                                    placeholder={t('Liaison:group_placeholder')}
                                    dataSource={fillMailingContactGroupData}
                                    locale={i18n.language}
                                    fields={{ value: 'id', text: 'nome' }}
                                    mode="CheckBox"
                                    showDropDownIcon={true}
                                    value={mailingContatoTO ? mailingContatoTO.grupos ? mailingContatoTO.grupos.map((value) => {
                                      return value.id;
                                    }) : null : null}
                                    change={(e) => stateMailingContactGroupOnChange(e)}
                                    ref={(scope) => { ddlMailingContactGroupRef = scope; }}
                                    created={groupCheckboxSelectionCreated}
                                  >
                                    <Inject services={[CheckBoxSelection]} />
                                  </MultiSelectComponent>
                                </div>
                                <div className="input">
                                  <label>{t('Liaison:editorial')}</label>
                                  <TextBoxComponent
                                    placeholder={t('Liaison:editorial_placeholder')}
                                    value={mailingContatoTO ? mailingContatoTO.editoria ? mailingContatoTO.editoria : null : null}
                                    change={(e) => setPropertyMailingContactTOState('editoria', e.value ? e.value : null)}
                                    htmlAttributes={{ maxlength: 500 }}
                                    ref={(r) => txtEditorialRef = r}
                                  />
                                </div>
                                <div className="input">
                                  <label>{t('Liaison:occupation')}</label>
                                  <TextBoxComponent
                                    placeholder={t('Liaison:occupation_placeholder')}
                                    value={mailingContatoTO ? mailingContatoTO.cargo ? mailingContatoTO.cargo : null : null}
                                    change={(e) => setPropertyMailingContactTOState('cargo', e.value ? e.value : null)}
                                    htmlAttributes={{ maxlength: 120 }}
                                    ref={(r) => txtOccupationRef = r}
                                  />
                                </div>
                                <div className="input">
                                  <label>{t('Liaison:address')}</label>
                                  <TextBoxComponent
                                    placeholder={t('Liaison:address_placeholder')}
                                    value={mailingContatoTO ? mailingContatoTO.endereco ? mailingContatoTO.endereco : null : null}
                                    change={(e) => setPropertyMailingContactTOState('endereco', e.value ? e.value : null)}
                                    htmlAttributes={{ maxlength: 250 }}
                                    ref={(r) => txtAddressRef = r}
                                  />
                                </div>
                              </>
                            }
                          </>
                        }
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="liaisonInfo">
                        <div className="top d-flex flex-row align-items-center" >
                          <div className="mdl2 mdl2-info" />
                          <h2> {t('Liaison:information_title')} </h2>
                        </div>
                        <div className="featured">
                          <div className="input">
                            <label>{t('Liaison:status')}*</label>
                            <DropDownListComponent
                              name="liaisonStatus"
                              cssClass="e-flat"
                              placeholder={t('Liaison:status_placeholder')}
                              locale={i18n.language}
                              dataSource={i18n.language === 'en' ? fillLiaisonStatusDataEn : fillLiaisonStatusDataPt}
                              fields={{ value: 'id', text: 'nome' }}
                              allowFiltering={true}
                              showClearButton={true}
                              value={liaisonTO ? liaisonTO.idStatus ? liaisonTO.idStatus : null : null}
                              change={(e) => {
                                setPropertyLiaisonTOState('idStatus', e.itemData ? e.itemData.id : null);
                                triggerValidation('liaisonStatus');
                              }}
                              ref={register({ required: true })}
                              blur={() => triggerValidation('liaisonStatus')}
                            />
                            {errors.liaisonStatus &&
                              <>
                                {errors.liaisonStatus.type === "required" &&
                                  <label className="e-error" style={{ display: "block" }}>{t('Validation:required')}</label>
                                }
                              </>
                            }
                          </div>
                          <div className="input">
                            <label>{t('Liaison:regional')}</label>
                            <DropDownListComponent
                              placeholder={t('Liaison:regional_placeholder')}
                              dataSource={i18n.language === 'en' ? fillRegionalDataEn : fillRegionalDataPt}
                              showClearButton={true}
                              fields={{ value: 'id', text: 'nome' }}
                              value={liaisonTO.idRegional ? liaisonTO.idRegional : ''}
                              language={i18n.language}
                              allowFiltering={true}
                              change={(e) => setPropertyLiaisonTOState('idRegional', e.itemData ? e.itemData.id : null)}
                            />
                          </div>
                        </div>
                        <div className="description">
                          <div className="input">
                            <label>{t('Liaison:conclusionDate')}</label>
                            <DatePickerComponent
                              cssClass="e-flat"
                              ref={datepicker => dtConclusionRef = datepicker}
                              placeholder={t('Liaison:conclusionDate_placeholder')}
                              locale={i18n.language}
                              allowEdit={false}
                              change={(e) => setPropertyLiaisonTOState('dataConclusao', e.value ? e.value : null)}
                              value={liaisonTO ? liaisonTO.dataConclusao ? liaisonTO.dataConclusao : null : null}
                              serverTimezoneOffset='0'
                            />
                          </div>
                          <div className="input">
                            <label>{t('Liaison:receiptDate')}*</label>
                            <DatePickerComponent
                              name='receiptDate'
                              placeholder={t('Liaison:receiptDate_placeholder')}
                              locale={i18n.language}
                              allowEdit={false}
                              ref={register({ required: true })}
                              change={(e) => {
                                setPropertyLiaisonTOState('dataRecebimento', e.value ? e.value : null);
                                triggerValidation('receiptDate');
                              }}
                              blur={() => triggerValidation('receiptDate')}
                              value={liaisonTO ? liaisonTO.dataRecebimento ? liaisonTO.dataRecebimento : null : null}
                            />
                            {errors.receiptDate &&
                              <>
                                {errors.receiptDate.type === "required" &&
                                  <label className="e-error" style={{ display: "block" }}>{t('Validation:required')}</label>
                                }
                              </>
                            }
                          </div>
                          <div className="input">
                            <label>{t('Liaison:actionType')}</label>
                            <DropDownListComponent
                              cssClass="e-flat"
                              placeholder={t('Liaison:actionType_placeholder')}
                              locale={i18n.language}
                              dataSource={i18n.language === 'en' ? fillLiaisonActionTypesDataEn : fillLiaisonActionTypesDataPt}
                              fields={{ value: 'id', text: 'nome' }}
                              allowFiltering={true}
                              showClearButton={true}
                              value={liaisonTO ? liaisonTO.tipoAcao ? liaisonTO.tipoAcao : null : null}
                              change={(e) => setPropertyLiaisonTOState('tipoAcao', e.itemData ? e.itemData.id : null)}
                            />
                          </div>
                          <div className="input">
                            <label>{t('Liaison:business_area')}*</label>
                            <DropDownListComponent
                              name="businessArea"
                              cssClass="e-flat"
                              placeholder={t('Liaison:business_area_placeholder')}
                              locale={i18n.language}
                              dataSource={i18n.language === 'en' ? fillBusinessAreaDataEn : fillBusinessAreaDataPt}
                              fields={{ value: 'id', text: 'nome' }}
                              allowFiltering={true}
                              showClearButton={true}
                              value={liaisonTO ? liaisonTO.idAreaNegocio ? liaisonTO.idAreaNegocio : null : null}
                              change={(e) => {
                                setPropertyLiaisonTOState('idAreaNegocio', e.itemData ? e.itemData.id : null);
                                triggerValidation('businessArea');
                              }}
                              ref={register({ required: true })}
                              blur={() => triggerValidation('businessArea')}
                            />
                            {errors.businessArea &&
                              <>
                                {errors.businessArea.type === "required" &&
                                  <label className="e-error" style={{ display: "block" }}>{t('Validation:required')}</label>
                                }
                              </>
                            }
                          </div>
                          <div className="input x2">
                            <label>{t('Liaison:recordAuthor')}</label>
                            <TextBoxComponent
                              cssClass="e-flat"
                              placeholder={t('Liaison:recordAuthor_placeholder')}
                              value={liaisonTO.AutorRegistro && itemId ? liaisonTO.AutorRegistro : globalState.userName}
                              enabled={false}
                            />
                          </div>
                          <div className="input x2">
                            <label>{t('Liaison:responsibles')}</label>
                            <TextBoxComponent
                              cssClass="e-flat"
                              placeholder={t('Liaison:responsibles_placeholder')}
                              value={liaisonTO.responsaveis ? liaisonTO.responsaveis : ''}
                              change={(e) => setPropertyLiaisonTOState('responsaveis', e.value ? e.value : null)}
                              ref={(r) => txtResponsaveisRef = r}
                            />
                          </div>
                          <div className="input x2">
                            <label>{t('Liaison:staffDescription')}</label>
                            <TextBoxComponent
                              cssClass="e-flat"
                              placeholder={t('Liaison:staffDescription_placeholder')}
                              multiline={true}
                              htmlAttributes={{ rows: "20" }}
                              rows={20}
                              ref={(r) => txtDescriptionRef = r}
                              change={(e) => setPropertyLiaisonTOState('descricao', e.value ? e.value : null)}
                              value={liaisonTO ? liaisonTO.descricao ? liaisonTO.descricao : null : null}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="attachments">
                        <div className="top d-flex flex-row align-items-center">
                          <div className="mdl2 mdl2-attachments" />
                          <h2>{t('Liaison:attachments_title')}</h2>
                        </div>
                        <div className="content d-flex flex-column">
                          <div className="uploader" ref={dropArea => dropAreaRef = dropArea} id="attachsDropAreaEle">
                            <p>
                              {t('Liaison:attachments_description_part1')}
                              <span onClick={() => uploadAttachment()}> {t('Liaison:attachments_description_part2')}</span>
                            </p>
                          </div>
                          <div className="e-upload e-control-wrapper e-lib e-keyboard e-form-upload">
                            <ul className="e-upload-files">
                              {itemId && liaisonAttachments && liaisonAttachments.length > 0 &&
                                liaisonAttachments.filter(a => a.status !== 2 && !a.fileB64).map((item) => {
                                  return <li className="e-upload-file-list" data-files-count="1">
                                    <span className="e-file-container pointerClick" onClick={() => downloadAttachment(item.fileName)}>
                                      <span className="e-file-name">{item.fileName}</span>
                                      <span className="e-file-type">&nbsp;</span>
                                      <span className="e-file-information">{formatBytes(item.fileLength, 1)}</span>
                                    </span>
                                    <span className="e-icons e-file-remove-btn" title="Retirar" onClick={() => removeAttachment(item.fileName)} />
                                  </li>
                                })
                              }
                            </ul>
                          </div>
                          <UploaderComponent
                            id="uploadAttachments"
                            ref={uploader => uploaderRef = uploader}
                            dropArea={target}
                            locale={i18n.language}
                            multiple={true}
                            created={uploaderCreated}
                            selected={onSetAttachmentsFiles}
                            removing={onRemovingAttachsFiles}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <Actions controls={_controls} />
                      {
                        itemId &&
                        <div className="changes">
                          <div className="top d-flex flex-row align-items-center">
                            <div className="mdl2 mdl2-historic" />
                            <h2>{t('ChangeHistory:title')}</h2>
                          </div>
                          <div className="content d-flex flex-column">
                            <div>
                              {liaisonTO.historico && liaisonTO.historico.map((item, index) => (
                                <div className="item" key={index}>
                                  <div className="info">
                                    <span className="name">{item.userName}</span>
                                    <span className="date">{(new Date(item.date)).toLocaleDateString(i18n.language)} {(new Date(item.date)).toLocaleTimeString(i18n.language, { hour12: false })}</span>
                                  </div>
                                  <div className="status">
                                    <span>{i18n.language ==="pt" ? item.actionType.namePt : item.actionType.nameEn}</span>
                                  </div>
                                </div>
                              )).reverse()}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </Row>
                </form>
              }
            </Container>
            <DialogComponent
              id="deleteModal"
              isModal={true}
              visible={dialogDelete}
              locale={i18n.language}
            >
              <div>
                <div className="divTrash">
                  <label className="mdl2 mdl2-trash"></label>
                  <div className="phraseDelete">
                    <label id="delete">{t('Actions:delete')}</label>
                    <label id="labelDelete">{t('Actions:confirmDeleteMsg')}</label>
                  </div>
                </div>
                <hr />
                <div className="footerButtons">
                  <button onClick={() => setDialogDelete(false)}>{t('Actions:cancel')}</button>
                  <button onClick={(e) => deleteItem(e)}>{i18n.language ==="pt" ? "Sim" : "Yes"}</button>
                </div>
              </div>
            </DialogComponent>
          </>
      }
    </>
  )
}
