import * as React from 'react';
import i18n from 'i18next';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/pages/Home';
import { Profile } from './components/pages/Profile';
import { Users } from './components/pages/Users';
import { UsersItem } from './components/pages/UsersItem';
import { Templates } from './components/pages/Templates';
import { TemplateItem } from './components/pages/TemplateItem';
import { Banners } from './components/pages/Banners';
import { Suppliers } from './components/pages/Suppliers';
import { SuppliersItem } from './components/pages/SuppliersItem';
import { Releases } from './components/pages/Releases';
import { ReleasesItem } from './components/pages/ReleasesItem';
import { Statements } from './components/pages/Statements/Statements';
import { StatementsItem } from './components/pages/Statements/StatementsItem';
import { PositionPaper } from './components/pages/PositionPaper/PositionPaper';
import { PositionPaperItem } from './components/pages/PositionPaper/PositionPaperItem';
import { Liaison } from './components/liaison/Liaison';
import { LiaisonItem } from './components/liaison/LiaisonItem';
import { LiaisonReport } from './components/liaisonReport/LiaisonReport';
import { Contacts } from './components/mailing/contacts/Contacts';
import { ContactsItem } from './components/mailing/contacts/ContactsItem';
import { GeneralContacts } from './components/generalMailing/contacts/Contacts';
import { GeneralContactsItem } from './components/generalMailing/contacts/ContactsItem';
import { Groups } from './components/mailing/groups/Groups';
import { GroupsItem } from './components/mailing/groups/GroupsItem';
import { GeneralGroups } from './components/generalMailing/groups/Groups';
import { GeneralGroupsItem } from './components/generalMailing/groups/GroupsItem';
import { QA } from './components/pages/QA/QA';
import { QAItem } from './components/pages/QA/QAItem';
import { WebcastNotices } from './components/pages/WebcastNotices/WebcastNotices';
import { WebcastNoticesItem } from './components/pages/WebcastNotices/WebcastNoticesItem';
import { AssignmentNotices } from './components/pages/AssignmentNotices/AssignmentNotices';
import { AssignmentNoticesItem } from './components/pages/AssignmentNotices/AssignmentNoticesItem';
import { PressSharedDocuments } from './components/sharedDocuments/Press';
import { PublicSharedDocuments } from './components/pages/PublicSharedDocuments';
import { LoginComponent } from './components/pages/LoginComponent.js';
import { getRoutes } from './components/utilities/Utils';
import { Breadcrumbs } from './components/utilities/Breadcrumb';
import { PlanningAndResearch } from './components/sharedDocuments/PlanningAndResearch';
import { EventsVisits } from './components/sharedDocuments/EventsVisits';
import { DigitalMediaSharedDocuments } from './components/sharedDocuments/DigitalMedia';
import { CommunicationWithEmployees } from './components/sharedDocuments/CommunicationWithEmployees';
import { CommunicationEmployees } from './components/communicationEmployees/CommunicationEmployees';
import { CommunicationEmployeesItem } from './components/communicationEmployees/CommunicationEmployeesItem';
import { Events } from './components/sharedDocuments/Events';
import { GlobalGuidelines } from './components/sharedDocuments/GlobalGuidelines';
import { GlobalCalendar } from './components/sharedDocuments/GlobalCalendar';
import { InternalCampaigns } from './components/sharedDocuments/InternalCampaigns';
import { DirectCommunication } from './components/sharedDocuments/DirectCommunication';
import { InternalResearchResults } from './components/sharedDocuments/InternalResearchResults';
import { SpecialProjects } from './components/sharedDocuments/SpecialProjects';
import { InternalVehicles } from './components/sharedDocuments/InternalVehicles';
import { BrandingSharedDocuments } from './components/sharedDocuments/BrandingAdvertising';
import { BrandCenter } from './components/sharedDocuments/BrandCenter';
import { PublicShareDocuments } from './components/publicShare/PublicShare';

import { Brazil } from './components/sharedDocuments/Brazil';
import { Canada } from './components/sharedDocuments/Canada';
import { China } from './components/sharedDocuments/China';
import { Global } from './components/sharedDocuments/Global';
import { Indonesia } from './components/sharedDocuments/Indonesia';
import { Japan } from './components/sharedDocuments/Japan';
import { Malasya } from './components/sharedDocuments/Malasya';
import { Mozambique } from './components/sharedDocuments/Mozambique';
import { NewCaledonia } from './components/sharedDocuments/NewCaledonia';
import { Oman } from './components/sharedDocuments/Oman';
import { Paraguay } from './components/sharedDocuments/Paraguay';
import { Singapore } from './components/sharedDocuments/Singapore';
import { Switzerland } from './components/sharedDocuments/Switzerland';
import { Peru } from './components/sharedDocuments/Peru';


import { GlobalStateProvider } from './components/general/GlobalState';
import { LoginStateProvider } from './components/login/LoginState';
import { AppInsightsContextProvider } from './components/general/AppInsightsContext';
import { MailingContactsProvider } from './components/mailing/contacts/MailingContactsContext';
import { MailingContactsProviderGeneral } from './components/generalMailing/contacts/MailingContactsContext';
import { loadCldr, L10n } from '../node_modules/@syncfusion/ej2-base';
import { ProfilePhotoProvider } from './components/utilities/ProfilePhoto/ProfilePhotoContext';
import { ReleaseStateProvider } from './components/releases/ReleaseState';
import { DigitalMediaProvider } from './components/digitalMedia/context/DigitalMediaContext';
import { LiaisonReportStateProvider } from './components/liaisonReport/LiaisonReportState';
import { PressReportStateProvider } from './components/pressReport/PressReportState';

import { DigitalMedia } from './components/digitalMedia/DigitalMedia';
import { ManageInternalAreas } from './components/manageInternalAreas/ManageInternalAreas';
import { ManageAnnualBudget } from './components/manageAnnualBudget/ManageAnnualBudget';
import { ManageContracts } from './components/manageContracts/ManageContracts';
import { MeasurementReport } from './components/measurementReport/MeasurementReport';
import { AdvertisingBudget } from './components/advertisingBudget/AdvertisingBudget';
import { FormContainerAdBudget } from './components/advertisingBudget/FormContainerAdBudget';
import { WhoIsWhoItem } from './components/whoiswho/WhoIsWhoItem';
import { MessageHouseItem } from './components/messageHouse/MessageHouseItem';

import { ContractProvider } from './components/manageContracts/context/ContractsContext';
import { ModalProvider } from './hooks/Modal';
import { MessageHouseProvider } from './components/messageHouse/context/MessageHouseContext';
import { ErrorLogs } from './components/errorLogs/ErrorLogs';

import { Historico } from './components/historico/Historico';

import { LastLocationProvider } from 'react-router-last-location';
import TagManager from 'react-gtm-module'
import { Signature } from './components/signature/Signature';
import { SignatureItem } from './components/signature/SignatureItem';
import { ReleaseReport } from './components/releases/ReleaseReport';
import { StatementsReport } from './components/statements/StatementsReport';
import { PositionReport } from './components/position/PositionReport';

const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GTM_ID}`
}

TagManager.initialize(tagManagerArgs)

loadCldr(
  require('../node_modules/cldr-data/supplemental/numberingSystems.json'),
  require('../node_modules/cldr-data/main/pt/ca-gregorian.json'),
  require('../node_modules/cldr-data/main/pt/numbers.json'),
  require('../node_modules/cldr-data/main/pt/timeZoneNames.json'),
  require('../node_modules/cldr-data/main/pt/currencies.json'),
  require('../node_modules/cldr-data/supplemental/weekData.json'),
);

L10n.load(require('../node_modules/@syncfusion/ej2-locale/src/pt.json'));

L10n.load(require('./resources/syncfusion.pt-BR.json'));
L10n.load(require('./resources/syncfusion.en-US.json'));

const renderRouter = (props, Component) => {
  const crumbs = getRoutes()
    .filter(({ path }) => props.match.path.indexOf(path) ===0)
    .map(({ path, ...rest }) => ({
      path: Object.keys(props.match.params).length
        ? Object.keys(props.match.params).reduce(
          (path, param) =>
            path.replace(`:${param}`, props.match.params[param]),
          path,
        )
        : path,
      ...rest,
    }));
  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Component {...props} />
    </>
  );
};

const App = () => {
  window.dataLayer.push({
    event: 'pageview'
  });
  return (
    <LastLocationProvider>
      <AppInsightsContextProvider>
        <GlobalStateProvider>
          <LoginStateProvider>
            <Layout>
              <ModalProvider>
                <ProfilePhotoProvider>
                  <Route path='/login' render={(props) => renderRouter(props, LoginComponent)} />
                  <Route exact path='/' render={(props) => renderRouter(props, Home)} />
                  <Route exact path='/profile/:id?' render={(props) => renderRouter(props, Profile)} />
                  <Route exact path='/admin/users' render={(props) => renderRouter(props, Users)} />
                  <MailingContactsProviderGeneral>
                    <Route exact path='/press/generalMailing/contacts' render={(props) => renderRouter(props, GeneralContacts)} />
                    <Route exact path='/press/generalMailing/contactsItem/:id?' render={(props) => renderRouter(props, GeneralContactsItem)} />
                  </MailingContactsProviderGeneral>
                  <Route exact path='/press/generalMailing/groups' render={(props) => renderRouter(props, GeneralGroups)} />
                  <Route exact path='/press/generalMailing/groupsItem/:id?' render={(props) => renderRouter(props, GeneralGroupsItem)} />
                  <Route exact path='/admin/usersItem/:id?' render={(props) => renderRouter(props, UsersItem)} />
                  <Route exact path='/admin/profiletemplates' render={(props) => renderRouter(props, Templates)} />
                  <Route exact path='/admin/profiletemplatesItem/:id?' render={(props) => renderRouter(props, TemplateItem)} />
                  <Route exact path='/admin/Suppliers' render={(props) => renderRouter(props, Suppliers)} />
                  <Route exact path='/admin/SuppliersItem/:id?' render={(props) => renderRouter(props, SuppliersItem)} />
                  <Route exact path='/admin/bannerhome' render={(props) => renderRouter(props, Banners)} />
                  <Route exact path='/admin/errorlogs' render={(props) => renderRouter(props, ErrorLogs)} />
                  <Route exact path='/admin/signature' render={(props) => renderRouter(props, Signature)} />
                  <Route exact path='/admin/signatureItem/:id?' render={(props) => renderRouter(props, SignatureItem)} />
                  <PressReportStateProvider>
                    <Route exact path='/press/releases/reports' render={(props) => renderRouter(props, ReleaseReport)} />
                    <Route exact path='/press/statements/reports' render={(props) => renderRouter(props, StatementsReport)} />
                    <Route exact path='/press/positionpaper/reports' render={(props) => renderRouter(props, PositionReport)} />
                  </PressReportStateProvider>
                  <ReleaseStateProvider>
                    <Route exact path='/press/releases' render={(props) => renderRouter(props, Releases)} />
                    <Route exact path='/press/releasesItem/:id?' render={(props) => renderRouter(props, ReleasesItem)} />
                    <Route exact path='/press/statements' render={(props) => renderRouter(props, Statements)} />
                    <Route exact path='/press/statementsItem/:id?' render={(props) => renderRouter(props, StatementsItem)} />
                    <Route exact path='/press/positionpaper' render={(props) => renderRouter(props, PositionPaper)} />
                    <Route exact path='/press/positionpaperItem/:id?' render={(props) => renderRouter(props, PositionPaperItem)} />
                    <Route exact path='/press/qa' render={(props) => renderRouter(props, QA)} />
                    <Route exact path='/press/qaItem/:id?' render={(props) => renderRouter(props, QAItem)} />
                    <Route exact path='/press/webcastnotices' render={(props) => renderRouter(props, WebcastNotices)} />
                    <Route exact path='/press/webcastnoticesItem/:id?' render={(props) => renderRouter(props, WebcastNoticesItem)} />
                    <Route exact path='/press/assignmentnotices' render={(props) => renderRouter(props, AssignmentNotices)} />
                    <Route exact path='/press/assignmentnoticesItem/:id?' render={(props) => renderRouter(props, AssignmentNoticesItem)} />
                    <Route exact path='/press/archive' render={(props) => renderRouter(props, Historico)} />
                  </ReleaseStateProvider>
                  <Route exact path='/planningresearch/shareddocuments' render={(props) => renderRouter(props, PlanningAndResearch)} />
                  <Route exact path='/eventsvisits' render={(props) => renderRouter(props, EventsVisits)} />

                  <Route exact path='/countries/brazil' render={(props) => renderRouter(props, Brazil)} />
                  <Route exact path='/countries/canada' render={(props) => renderRouter(props, Canada)} />
                  <Route exact path='/countries/china' render={(props) => renderRouter(props, China)} />
                  <Route exact path='/countries/global' render={(props) => renderRouter(props, Global)} />
                  <Route exact path='/countries/indonesia' render={(props) => renderRouter(props, Indonesia)} />
                  <Route exact path='/countries/oman' render={(props) => renderRouter(props, Oman)} />
                  <Route exact path='/countries/japan' render={(props) => renderRouter(props, Japan)} />
                  <Route exact path='/countries/malaysia' render={(props) => renderRouter(props, Malasya)} />
                  <Route exact path='/countries/mozambiquemalawi' render={(props) => renderRouter(props, Mozambique)} />
                  <Route exact path='/countries/newcaledonia' render={(props) => renderRouter(props, NewCaledonia)} />
                  <Route exact path='/countries/paraguay' render={(props) => renderRouter(props, Paraguay)} />
                  <Route exact path='/countries/peru' render={(props) => renderRouter(props, Peru)} />
                  <Route exact path='/countries/singapore' render={(props) => renderRouter(props, Singapore)} />
                  <Route exact path='/countries/switzerland' render={(props) => renderRouter(props, Switzerland)} />

                  <Route exact path='/press/liaison' render={(props) => renderRouter(props, Liaison)} />
                  <Route exact path='/press/liaisonItem/:id?' render={(props) => renderRouter(props, LiaisonItem)} />
                  <MailingContactsProvider>
                    <Route exact path='/press/mailing/contacts' render={(props) => renderRouter(props, Contacts)} />
                    <Route exact path='/press/mailing/contactsItem/:id?' render={(props) => renderRouter(props, ContactsItem)} />
                  </MailingContactsProvider>
                </ProfilePhotoProvider>
                <LiaisonReportStateProvider>
                  <Route exact path='/press/liaison/reports' render={(props) => renderRouter(props, LiaisonReport)} />
                </LiaisonReportStateProvider>
                <Route exact path='/press/mailing/groups' render={(props) => renderRouter(props, Groups)} />
                <Route exact path='/press/mailing/groupsItem/:id?' render={(props) => renderRouter(props, GroupsItem)} />
                <Route exact path='/press/shareddocuments' render={(props) => renderRouter(props, PressSharedDocuments)} />
                <DigitalMediaProvider>
                  <Route exact path='/digitalmedia/videos' render={(props) => renderRouter(props, DigitalMedia)} />
                </DigitalMediaProvider>
                <Route exact path='/digitalmedia/shareddocuments' render={(props) => renderRouter(props, DigitalMediaSharedDocuments)} />
                <Route exact path='/communicationemployees/shareddocuments' render={(props) => renderRouter(props, CommunicationWithEmployees)} />
                <Route exact path='/communicationemployees/globalcalendar' render={(props) => renderRouter(props, GlobalCalendar)} />
                <Route exact path='/communicationemployees/internalcampaigns' render={(props) => renderRouter(props, InternalCampaigns)} />
                <Route exact path='/communicationemployees/events' render={(props) => renderRouter(props, Events)} />
                <Route exact path='/communicationemployees/globalguidelines' render={(props) => renderRouter(props, GlobalGuidelines)} />
                <Route exact path='/communicationemployees/directcommunication' render={(props) => renderRouter(props, DirectCommunication)} />
                <Route exact path='/communicationemployees/internalresearchresults' render={(props) => renderRouter(props, InternalResearchResults)} />
                <Route exact path='/communicationemployees/specialprojects' render={(props) => renderRouter(props, SpecialProjects)} />
                <Route exact path='/communicationemployees/internalvehicles' render={(props) => renderRouter(props, InternalVehicles)} />
                <Route exact path='/communicationemployeesGroup' render={(props) => renderRouter(props, CommunicationEmployees)} />
                <Route exact path='/communicationemployeesGroup/communicationemployeesItem/:id?' render={(props) => renderRouter(props, CommunicationEmployeesItem)} />
                <Route exact path='/brandingadvertising/manageinternalareas' render={(props) => renderRouter(props, ManageInternalAreas)} />
                <Route exact path='/brandingadvertising/manageannualbudget' render={(props) => renderRouter(props, ManageAnnualBudget)} />
                <ContractProvider>
                  <Route exact path='/brandingadvertising/managecontracts' render={(props) => renderRouter(props, ManageContracts)} />
                </ContractProvider>
                <Route exact path='/brandingadvertising/measurementreport' render={(props) => renderRouter(props, MeasurementReport)} />
                <Route exact path='/brandingadvertising/advertisingbudget' render={(props) => renderRouter(props, AdvertisingBudget)} />
                <Route exact path='/brandingadvertising/advertisingbudgetItem/:id?' render={(props) => renderRouter(props, FormContainerAdBudget)} />
                <Route exact path='/brandingadvertising/shareddocuments' render={(props) => renderRouter(props, BrandingSharedDocuments)} />
                <Route exact path='/brandingadvertising/brandcenter' render={(props) => renderRouter(props, BrandCenter)} />
                {/* <Route exact path='/brandingadvertising/advertisingbudget/formContainerAdBudget' render={(props) => renderRouter(props, FormContainerAdBudget)} /> */}
                <Route exact path='/home/whoiswho' render={(props) => renderRouter(props, WhoIsWhoItem)} />
                <MessageHouseProvider>
                  <Route exact path='/home/messagehouseItem' render={(props) => renderRouter(props, MessageHouseItem)} />
                </MessageHouseProvider>
              </ModalProvider>
            </Layout>
          </LoginStateProvider>
          {/* <Route exact path='/publicSharedDocuments/:token' component={PublicSharedDocuments} /> */}
          <Route exact path='/publicSharedDocuments/:token' render={(props) => renderRouter(props, PublicSharedDocuments)} />
          <ModalProvider>
            <Route exact path='/publicShareDocuments' render={(props) => renderRouter(props, PublicShareDocuments)} />
          </ModalProvider>
        </GlobalStateProvider>
      </AppInsightsContextProvider>
    </LastLocationProvider>
  );
}

export default App;
