import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../../general/GlobalState";
import { Container, Row, Col } from "reactstrap";
import { Actions } from "../../utilities/Actions";
import { TextBoxComponent, FormValidator } from "@syncfusion/ej2-react-inputs";
import { AutoCompleteComponent, DropDownListComponent, MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import { ChipListComponent } from '@syncfusion/ej2-react-buttons';
import { createSpinner, hideSpinner, setSpinner, showSpinner, DialogComponent } from '@syncfusion/ej2-react-popups';
import { DataManager, Query, ODataV4Adaptor, Predicate } from '@syncfusion/ej2-data';
import './groups.css';
import { Export } from "../../utilities/Export";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { addOrUpdateGroupItem, getMailingGroupItem, deleteMailingGroupItem, exportMailingGroupContacts } from "../../../services/MailingSVC";
import { getCountries, getRegional } from "../../../services/SystemSVC";
import PermissionRedirect from "../../utilities/PermissionRedirect/PermissionRedirect";
import { hasPermissions } from "../../utilities/Utils";

export const GroupsItem = (props) => {
    let txtGroupNameRef;
    let autocompleteRef;
    let formRef;
    let idGroup = props.match.params.id;

    const { t } = useTranslation();
    const initialValue = {
        nome: "",
        idpais: 6,
        idregional: null,
        conjunto: null,
        membrosGrupo: null,
        gruposConjunto: null
    }

    const initialValueSteps = {
        stepCountriesPt: false,
        stepCountriesEn: false,
        stepRegionalPt: false,
        stepRegionalEn: false,
        stepGroupItem: !idGroup
    }

    const [globalState] = React.useContext(GlobalStateContext);
    const [redirect, setRedirect] = React.useState(false);
    const [countriesPt, setCountriesPt] = React.useState();
    const [countriesEn, setCountriesEn] = React.useState();
    const [regionsPt, setRegionsPt] = React.useState();
    const [regionsEn, setRegionsEn] = React.useState();
    const [showMembersSelect, setShowMembersSelect] = React.useState(null);
    const [itemsSelected, setItemsSelected] = React.useState([]);
    const [groupItem, setGroupItem] = React.useState(initialValue);
    const [loading, setLoading] = React.useState(true);
    const [steps, setSteps] = React.useState(initialValueSteps);
    const [dialogDelete, setDialogDelete] = React.useState(false);
    const [disabledBtn, setDisabledBtn] = React.useState(false);

    const _controls = {
        class: "group",
        savePrimary: false,
        excludePrimary: true,
        customPermissions: idGroup ? hasPermissions(11, "Edit", globalState) : hasPermissions(11, "Create", globalState),
        urlView: "",
        urlPreview: "",
        area: "Grupos Jornalistas",
        customPermissionsDelete: idGroup && hasPermissions(11, "Delete", globalState),
        urlDuplicate: "",
        urlSave: () => {
          if (formRef.validate()) {
                setDisabledBtn(true);
                let data = groupItem;
                if (showMembersSelect) {
                    data.conjunto = false;
                    data.membrosGrupo = itemsSelected;
                }
                else {
                    data.conjunto = true;
                    data.gruposConjunto = itemsSelected;
                }
                addOrUpdateGroupItem(globalState.accessToken, data).then(response => {
                  setRedirect(response.ok)
                  setDisabledBtn(false);
                }).catch(() => {
                  setDisabledBtn(false);
                });
            }
        },
        urlSend: "",
        urlCancel: () => setRedirect(true),
        urlDelete: idGroup ? () => setDialogDelete(true) : ""
    };

    const _export = {
        pdf: "",
        doc: "",
        excel: idGroup ? () => exportGroupContacts() : ""
    }

    const dataManager = new DataManager({
        url: showMembersSelect ? `${process.env.REACT_APP_API_URL}/_api/MailingContact/GetMailingContactItems?$select=id,jornalista`
            : `${process.env.REACT_APP_API_URL}/_api/MailingGroup/GetMailingGroupItems?$select=id,nome`,
        adaptor: new ODataV4Adaptor,
        crossDomain: true,
        headers: [{ 'Authorization': `Bearer ${globalState.accessToken}` }],
    });

    React.useEffect(() => {
        const formValidator = {
            rules: {
                'nome': {
                    required: [true, t('Validation:required')]
                },
                'idpais': {
                    required: [true, t('Validation:required')]
                }
            },
            customPlacement: (inputElement, errorElement) => {
                inputElement.parentElement.parentElement.appendChild(errorElement);
            }
        };
        formRef = new FormValidator('#formMailingGroup', formValidator);
    });

    React.useEffect(() => {
        let promises = [
            new Promise((resolve, reject) => {
                getCountries(globalState.accessToken).then(response => {
                    if (response.ok) {
                        response.json().then(items => {
                            let arrPt = [];
                            let arrEn = [];
                            items.map(item => {
                                arrPt.push({ id: item.id, nome: item.nomePt });
                                arrEn.push({ id: item.id, nome: item.nomeEn });
                            });
                            setCountriesPt(arrPt);
                            setCountriesEn(arrEn);
                            resolve();
                        });
                    }
                    else
                        reject();
                });

            }),
            new Promise((resolve, reject) => {
                getRegional(globalState.accessToken).then(response => {
                    if (response.ok) {
                        response.json().then(items => {
                            let arrPt = [];
                            let arrEn = [];
                            items.map(item => {
                                arrPt.push({ id: item.id, nome: item.nomePt });
                                arrEn.push({ id: item.id, nome: item.nomeEn });
                            });
                            setRegionsPt(arrPt);
                            setRegionsEn(arrEn);
                            resolve();
                        });
                    }
                    else
                        reject();
                });
            })
        ];

        Promise.all(promises).then(() => {
            if (idGroup) {
                getMailingGroupItem(globalState.accessToken, idGroup).then(response => {
                    if (response.ok)
                        response.json().then(setGroupItem);
                });
            }
        });
    }, []);

    React.useEffect(() => {
        if (txtGroupNameRef)
            txtGroupNameRef.placeholder = t('Group:name_placeholder');
    }, [i18n.language]);

    React.useEffect(() => {
        setSteps(steps => (
            {
                ...steps,
                stepCountriesPt: countriesPt !== undefined,
                stepCountriesEn: countriesEn !== undefined,
                stepRegionalPt: regionsPt !== undefined,
                stepRegionalEn: regionsEn !== undefined
            }
        ));
    }, [countriesPt, countriesEn, regionsPt, regionsEn]);

    React.useEffect(() => {
        const loaded = steps.stepCountriesPt && steps.stepCountriesEn && steps.stepRegionalPt && steps.stepRegionalEn && steps.stepGroupItem;
        const elemSpinner = document.getElementById('loadGroup');
        if (loaded && elemSpinner) {
            hideSpinner(elemSpinner);
            setLoading(false);
        }
    }, [steps]);

    React.useEffect(() => {
        if (loading) {
            createSpinner({ target: document.getElementById('loadGroup') });
            showSpinner(document.getElementById('loadGroup'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [loading]);

    React.useEffect(() => {
        if (groupItem.conjunto !==null)
            setShowMembersSelect(!groupItem.conjunto);
        if (idGroup && groupItem.id)
            setSteps(steps => ({ ...steps, stepGroupItem: true }));
    }, [groupItem]);

    React.useEffect(() => {
        let _itemsSelected = [];
        if (showMembersSelect !==null) {
            if (showMembersSelect && groupItem.membrosGrupo)
                _itemsSelected = groupItem.membrosGrupo.map(item => { return { text: item.jornalista, id: item.id } });
            else if (groupItem.gruposConjunto)
                _itemsSelected = groupItem.gruposConjunto.map(item => { return { text: item.nome, id: item.id } });
        }
        setItemsSelected(_itemsSelected);
    }, [showMembersSelect]);

    React.useEffect(() => {
        if (autocompleteRef)
            autocompleteRef.value = "";
    }, [itemsSelected]);

    const inputChange = e => {
        if (e.event)
            setGroupItem({ ...groupItem, [e.event.currentTarget.id]: e.value });
    }

    const dropdownlistChange = e => {
        if (e.element) {
            setGroupItem({ ...groupItem, [e.element.id]: e.value });
        }
    }

    const autocompleteSelect = e => {
        if (e.itemData) {
            const itemFound = itemsSelected.find(item => item.id === e.itemData.id);
            if (!itemFound)
                setItemsSelected(itemsSelected => [...itemsSelected, { text: showMembersSelect ? e.itemData.jornalista : e.itemData.nome, id: e.itemData.id }]);
        }
    }

    const autocompleteFiltering = e => {
        e.preventDefaultAction = true;
        var predicate = new Predicate('jornalista', 'contains', e.text, true);
        var query = new Query();
        //frame the query based on search string with filter type.
        query = (e.text !=="") ? query.where(predicate) : query;
        //pass the filter data source, filter query to updateData method.
        e.updateData(dataManager, query);
    }

    const autocompleteGroupFiltering = e => {
        e.preventDefaultAction = true;
        var predicate = new Predicate('nome', 'contains', e.text, true);
        var query = new Query();
        //frame the query based on search string with filter type.
        query = (e.text !=="") ? query.where(predicate) : query;
        //pass the filter data source, filter query to updateData method.
        e.updateData(dataManager, query);
    }

    const handleFormSubmit = e => {
        e.preventDefault();
    }

    const confirmDelete = () => {
        deleteMailingGroupItem(globalState.accessToken, idGroup).then(response => setRedirect(response.ok));
    }

    const exportGroupContacts = () => {
        exportMailingGroupContacts(globalState.accessToken, idGroup, i18n.language).then(response => response.blob()).then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "contacts.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
        }).catch(console.error);
    }

    return (
        <>
            <PermissionRedirect id="11" />
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                redirect ? <Redirect push={true} to='/press/mailing/groups' /> :
                    loading ? <div id="loadGroup" /> :
                        <>
                            {/* <div id="loadGroup" /> */}
                            <div className="DefaultTop" />
                            <Container className="containerTop">
                                <div className="topTitleButtons">
                                    <Row>
                                        <div className="col-12">
                                            <div className="title">
                                                <h1>{idGroup ? t('Group:edit_title') : t('Group:create_title')}</h1>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <Row>
                                    <div className="col-xl-1 col-auto">
                                        <div className="avatar">
                                            <div className="img">
                                                <div className="mdl2 mdl2-groups" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-md-7 col-12">
                                        <div className="groupItem">
                                            <div className="top d-flex flex-row align-items-center" >
                                                <div className="mdl2 mdl2-suitcase" />
                                                <h2>{t('Group:group_info')}</h2>
                                            </div>
                                            <form id="formMailingGroup" onSubmit={handleFormSubmit}>
                                                <div className="content">
                                                    <div className="line">
                                                        <div className="input">
                                                            <label>{t('Group:name')}*</label>
                                                            <TextBoxComponent
                                                                id="nome"
                                                                ref={textBox => txtGroupNameRef = textBox}
                                                                name="nome"
                                                                htmlAttributes={{ maxlength: "100" }}
                                                                placeholder={t('Group:name_placeholder')}
                                                                value={groupItem.nome}
                                                                change={inputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="line x2">
                                                        <div className="input">
                                                            <label>{t('Group:country')}*</label>
                                                            <DropDownListComponent
                                                                id="idpais"
                                                                name="idpais"
                                                                fields={{ text: "nome", value: "id" }}
                                                                placeholder={t('Group:country_placeholder')}
                                                                showClearButton={true}
                                                                allowFiltering={true}
                                                                dataSource={i18n.language === "pt" ? countriesPt : countriesEn}
                                                                value={groupItem.idpais}
                                                                change={dropdownlistChange}
                                                            />
                                                        </div>
                                                        <div className="input">
                                                            <label>Regional</label>
                                                            <DropDownListComponent
                                                                id="idregional"
                                                                fields={{ text: "nome", value: "id" }}
                                                                placeholder={t('Group:regional_placeholder')}
                                                                showClearButton={true}
                                                                dataSource={i18n.language === "pt" ? regionsPt : regionsEn}
                                                                value={groupItem.idregional}
                                                                change={dropdownlistChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="line">
                                                        <div className="radio">
                                                            <p>{t('Group:group_from')}</p>
                                                            <ul>
                                                                <li><RadioButtonComponent
                                                                    id=""
                                                                    name="group"
                                                                    label={t('Group:members')}
                                                                    checked={groupItem.conjunto === false}
                                                                    change={e => setShowMembersSelect(true)}
                                                                /></li>
                                                                <li><RadioButtonComponent
                                                                    id=""
                                                                    name="group"
                                                                    label={t('Group:groups')}
                                                                    checked={groupItem.conjunto}
                                                                    change={e => setShowMembersSelect(false)}
                                                                /></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="line">
                                                        <div className="input">
                                                            <label>{showMembersSelect !==null ? showMembersSelect ? t('Group:members') : t('Group:groups') : ""}</label>
                                                            <ChipListComponent
                                                                id="chip"
                                                                chips={itemsSelected}
                                                                enableDelete={true}
                                                            />
                                                            {showMembersSelect &&
                                                                <AutoCompleteComponent
                                                                    ref={autocomplete => autocompleteRef = autocomplete}
                                                                    name='name'
                                                                    placeholder={t('Group:members_placeholder')}
                                                                    dataSource={dataManager}
                                                                    sortOrder="Ascending"
                                                                    query={new Query()}
                                                                    fields={{ value: 'id', text: 'jornalista' }}
                                                                    filtering={autocompleteFiltering}
                                                                    select={autocompleteSelect}
                                                                    minLength={3}
                                                                    locale={i18n.language}
                                                                    htmlAttributes={{ maxlength: 100 }}
                                                                    actionFailure={e => console.error(e)}
                                                                />
                                                            }
                                                            {showMembersSelect === false &&
                                                                <AutoCompleteComponent
                                                                    ref={autocomplete => autocompleteRef = autocomplete}
                                                                    name='groups'
                                                                    placeholder={t('Group:groups_placeholder')}
                                                                    dataSource={dataManager}
                                                                    sortOrder="Ascending"
                                                                    query={new Query()}
                                                                    fields={{ value: 'id', text: 'nome' }}
                                                                    filtering={autocompleteGroupFiltering}
                                                                    select={autocompleteSelect}
                                                                    minLength={3}
                                                                    locale={i18n.language}
                                                                    htmlAttributes={{ maxlength: 100 }}
                                                                    actionFailure={e => console.error(e)}
                                                                />

                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-12">
                                        <Actions disabled={disabledBtn} controls={_controls} />
                                        <Export controls={_export} />
                                    </div>
                                </Row>
                            </Container>
                            <DialogComponent
                                id="deleteModal"
                                isModal={true}
                                visible={dialogDelete}
                                locale={i18n.language}
                            >
                                <div>
                                    <div className="divTrash">
                                        <label className="mdl2 mdl2-trash"></label>
                                        <div className="phraseDelete">
                                            <label id="delete">{t('Actions:delete')}</label>
                                            <label id="labelDelete">{t('Actions:confirmDeleteMsg')}</label>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="footerButtons">
                                        <button onClick={() => setDialogDelete(false)}>{t('Actions:cancel')}</button>
                                        <button onClick={() => confirmDelete()}>{i18n.language ==="pt" ? "Sim" : "Yes"}</button>
                                    </div>
                                </div>
                            </DialogComponent>
                        </>
            }
        </>
    )
}
