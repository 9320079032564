import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../../general/GlobalState";
import { Container, Row } from "reactstrap";
import { Buttons } from "../../utilities/Buttons";
import { StatementsList } from "../../statements/StatementsList";
import PermissionRedirect from '../../utilities/PermissionRedirect/PermissionRedirect';

let userPermission;

export const Statements = () => {

    const { t } = useTranslation();

    const [globalState] = React.useContext(GlobalStateContext);
    const [redirect, setRedirect] = React.useState(false);
    const [idStatement, setIdStatement] = React.useState(null);
    const [gridInstance, setGridInstance] = React.useState(null);

    React.useEffect(() => {
        let globalPermissions = globalState.permissions;
        userPermission = globalPermissions.filter((i) => i.accessArea === "Notas").map((i) => (i.permission));
    }, [globalState]);

    const _controls = {
        permit: userPermission ? userPermission : null,
        export: () => exportStatement(),
        import: "",
        new: () => setRedirect(true),
        back: "",
    };

    const editStatement = (info) => {
        setIdStatement(info.id);
        setRedirect(true);
    }

    const exportStatement = () => {
        if (gridInstance) {
            let lang = i18n.language;
            let gridInstanceExport = gridInstance;
            gridInstanceExport.dataSource.map(function (x) {
                x.bloqueado = x.bloqueado ? lang === 'pt' ? 'Bloqueado' : 'Blocked' : lang === 'pt' ? 'Terminado' : 'Finished';
                x.data = new Date(x.data).toLocaleDateString(lang);
                return x;
            });
            gridInstanceExport.excelExport();
        }
    }

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                redirect ? (
                    idStatement === null ?
                        <Redirect push={true} to='/press/statementsItem' />
                        :
                        <Redirect push={true} to={`/press/statementsItem/${idStatement}`} />
                ) : (
                        <>
                            <PermissionRedirect id="79" />

                            <div className="DefaultTop" />
                            <Container className="containerTop">
                                <div className="topTitleButtons">
                                    <Row>
                                        <div className="col-sm-8 col-12">
                                            <div className="title">
                                                <h1>{t('Statements:title')}</h1>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                            <div className="buttons">
                                                <Buttons controls={_controls} />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <Row>
                                    <div className="col-12">
                                        <StatementsList
                                            edit={editStatement}
                                            gridInstance={gridInstance}
                                            refStatement={setGridInstance}
                                        />
                                    </div>
                                </Row>
                            </Container>
                        </>
                    )
            }
        </>
    )
}