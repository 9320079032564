import * as React from 'react';


const initialState = {
    accessToken: null,
    userName: null,
    userPhoto: null,
    userEmail: null,
    userAdmin: false,
    userLanguage: null,
    regional: {
        id: null,
        namePt: null,
        nameEn: null
    },
    permissions: [{
        accessArea: null,
        permission: null,
        idAreaAcesso: null
    }]

};
const userCacheItem = localStorage.getItem("NovoCGCUserCache") ? JSON.parse(localStorage.getItem("NovoCGCUserCache")) : initialState;
const GlobalStateContext = React.createContext(userCacheItem);

const GlobalStateProvider = ({ children }) => {
    const [globalState, setGlobalState] = React.useState(userCacheItem);
    return (
        <GlobalStateContext.Provider value={[globalState,setGlobalState]}>
            {children}
        </GlobalStateContext.Provider>
    );
};

export { GlobalStateContext, GlobalStateProvider };


