import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../general/GlobalState";
import { Container, Row, Col } from "reactstrap";
import { TextBoxComponent, FormValidator } from "@syncfusion/ej2-react-inputs";
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent, Tooltip } from "@syncfusion/ej2-react-popups";
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Sort, ContextMenu, Page, Resize, Inject } from "@syncfusion/ej2-react-grids";
import { DataUtil } from "@syncfusion/ej2-data";
import { ReleaseStateContext } from "../releases/ReleaseState";
import { SendContent } from "./SendContent";
import { GroupsBox } from "../Shared/GroupsBox";
import { Actions } from "../utilities/Actions";
import { getCountries, getRegional } from "../../services/SystemSVC";
import { getMailingContactItems } from "../../services/MailingSVC";
import { addShippingStatusItem } from "../../services/PressSVC";
import './mailing.css';
import { GroupsBoxGeneral } from "../Shared/GroupsBoxGeneral";

export const SendItem = (props) => {
    let formObject;
    let gridInstance;
    let tooltip;
    let txtSubject;
    let txtMailList;

    const { t } = useTranslation();
    const initialValues = {
        assunto: "",
        receberCopia: false,
        listaEmails: "",
        idImprensa: props.idPress,
        grupos: []
    }

    const [globalState] = React.useContext(GlobalStateContext);
    const { releaseState, groupsSelected, groupsFilter, setGroupsFilter, setShowSendContent, generalGroupsSelected, generalGroupsFilter } = React.useContext(ReleaseStateContext);
    const [countriesPt, setCountriesPt] = React.useState([]);
    const [countriesEn, setCountriesEn] = React.useState([]);
    const [regionsPt, setRegionsPt] = React.useState([]);
    const [regionsEn, setRegionsEn] = React.useState([]);
    const [content, setContent] = React.useState(initialValues);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [gridDataSource, setGridDataSource] = React.useState([]);
    const [sendBtnDisabled, setSendBtnDisabled] = React.useState(true);

    const _controls = {
        area: "Releases",
        class: "release",
        savePrimary: false,
        excludePrimary: "",
        urlView: "",
        urlPreview: "",
        urlDuplicate: "",
        urlSave: "",
        urlSend: () => sendContent(),
        sendDisabled: sendBtnDisabled,
        urlCancel: "",
        urlDelete: "",
        urlReturn: () => setShowSendContent(false)
    };

    const getGroups = () => {
        var data = [];
        var flag = true;
        var lookup = {};
        const groups = gridInstance.dataSource.map(value => value.grupos);

        groups.forEach((arr) => {
            arr.forEach((item) => {
                if (!(item.nome in lookup)) {
                    data.push({ grupos: item.nome });
                    lookup[item.nome] = true
                }
            })
            if (!arr.length && flag) {
                data.push({ "": true })
            }
        });
        return data;
    }

    const gridActionBegin = (args) => {
        if (args.requestType === 'filterbeforeopen' && args.columnName === 'grupos') {
            args.filterModel.options.dataSource = getGroups();
        }
    }

    DataUtil.fnOperators.equal = function (rowValues, filterValue, ignoreCase, ignoreAccent) {
        if (Array.isArray(rowValues)) {
            if (!filterValue) {
                return !rowValues.length;
            }
            return JSON.stringify(rowValues).includes(filterValue)
        } else {
            if (ignoreAccent) {
                rowValues = DataUtil.ignoreDiacritics(rowValues);
                filterValue = DataUtil.ignoreDiacritics(filterValue);
            }
            if (ignoreCase) {
                return DataUtil.toLowerCase(rowValues) === DataUtil.toLowerCase(filterValue);
            }
            return rowValues === filterValue;
        }
    };

    DataUtil.fnOperators.notequal = function (rowValues, filterValue, ignoreCase, ignoreAccent) {
        if (Array.isArray(rowValues)) {
            if (!filterValue)
                return rowValues.length;
            else if (JSON.stringify(rowValues).includes(filterValue))
                return rowValues.length > 1;

            return !rowValues.length || !JSON.stringify(rowValues).includes(filterValue);

        } else {
            if (ignoreAccent) {
                rowValues = DataUtil.ignoreDiacritics(rowValues);
                filterValue = DataUtil.ignoreDiacritics(filterValue);
            }
            return !DataUtil.fnOperators.equal(rowValues, filterValue, ignoreCase);
        }
    };

    const renderCellGroup = (args) => {
        if (args.column.id === "grupos") {
            let content = "";

            args.data["grupos"].map((item, i) => {
                content += item.nome;
                if (i !== args.data["grupos"].length - 1)
                    content += ", ";
            });

            if (content) {
                tooltip = new Tooltip({
                    content: content
                }, args.cell);
            }
            return args.cell;
        }
    }

    const groupTemplate = (e) => {
        return (
            e.grupos.map(item => (
                <span className="dataGroup">{item.nome}</span>
            ))
        );
    }

    React.useEffect(() => {
        const formValidator = {
            rules: {
                'subjectMail': {
                    required: [true, t('Validation:required')]
                }
            },
            customPlacement: (inputElement, errorElement) => {
                inputElement.parentElement.parentElement.appendChild(errorElement);
            }
        };
        formObject = new FormValidator('#formSendItem', formValidator);
    });

    React.useEffect(() => {
        getCountries(globalState.accessToken).then(response => {
            if (response.ok)
                response.json().then(items => {
                    let arrPt = [];
                    let arrEn = [];
                    items.map(item => {
                        arrPt.push({ id: item.id, nome: item.nomePt });
                        arrEn.push({ id: item.id, nome: item.nomeEn });
                    });
                    setCountriesPt(arrPt);
                    setCountriesEn(arrEn);
                });
        });

        getRegional(globalState.accessToken).then(response => {
            if (response.ok)
                response.json().then(items => {
                    let arrPt = [];
                    let arrEn = [];
                    items.map(item => {
                        arrPt.push({ id: item.id, nome: item.nomePt });
                        arrEn.push({ id: item.id, nome: item.nomeEn });
                    });
                    setRegionsPt(arrPt);
                    setRegionsEn(arrEn);
                });
        });
        txtSubject.value = releaseState.releaseItem.titulo;
        setContent(content => ({ ...content, assunto: releaseState.releaseItem.titulo }));

    }, []);

    React.useEffect(() => {
        txtSubject.placeholder = t('SendContent:subject_mail_placeholder');
        txtMailList.placeholder = t('SendContent:list_sending_placeholder');
    }, [i18n.language]);

    React.useEffect(() => {
        if (openDialog) {
            if (groupsSelected.length) {
                // const qString = `?$select=jornalista,email,grupos&$expand=grupos&$filter=grupos/any(d:d/id in (${groupsSelected.join()}))`;
                getMailingContactItems(globalState.accessToken).then(response => {
                    if (response.ok)
                        response.json().then(setGridDataSource);
                });
            }
            else
                setGridDataSource([]);
        }
    }, [openDialog]);

    const emailTemplate = (e) => {
        console.log(e);
        return (
            <div className="flexColumn">
                {e.email.length > 0 && e.email.map(email => (
                    <span className="emailTemplate"> {email.email} </span>
                )
                )}
            </div>
        )
    }

    React.useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language]);

    React.useEffect(() => {
        setSendBtnDisabled(!content.receberCopia && !content.listaEmails && !groupsSelected.length && !generalGroupsSelected.length)
    }, [content, groupsSelected, generalGroupsSelected]);

    const inputChange = e => {
        if (e.event)
            setContent(content => ({ ...content, [e.event.target.id]: e.value }));
    }

    const checkBoxChange = e => {
        if (e.event)
            setContent(content => ({ ...content, [e.event.target.id]: e.checked }));
    }

    const dropdownlistChange = e => {
        if (e.element)
            setGroupsFilter(groupsFilter => ({ ...groupsFilter, [e.element.id]: e.value }));
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const emailsOnChange = (e) => {
        if (e.event) {
            if (e.value) {
                const emails = e.value.split(';')
                let emailsList = [];
                emails.map(email => {
                    const mail = email.trim();
                    if (validateEmail(mail))
                        emailsList.push(mail);
                });
                if (emailsList.length)
                    setContent(content => ({ ...content, listaEmails: emailsList.join(';') }));
            }
            else
                setContent(content => ({ ...content, listaEmails: "" }));
        }
    }

    React.useEffect(() => {
        console.log(generalGroupsFilter);
    }, [generalGroupsFilter]);

    const sendContent = () => {
        if (formObject.validate()) {
            const groups = [];
            groupsSelected.map(id => {
                groups.push({ id });
            });

            generalGroupsSelected.map(id => {
                groups.push({ id, grupoGeral: true });
            });

            content.grupos = groups;
            addShippingStatusItem(globalState.accessToken, content, props.idType).then(setShowSendContent(false));
        }
    }

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                <>
                    <div className="DefaultTop" />
                    <Container className="containerTop">
                        <div className="topTitleButtons">
                            <Row>
                                <div className="col-12">
                                    <div className="title">
                                        <h1>{t('SendContent:sending_content')}</h1>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <Row>
                            <div className="col-lg-9 col-12">
                                <div id="sendContent">
                                    <form id="formSendItem" onSubmit={(e) => e.preventDefault()}>
                                        <div className="emailDiv">
                                            <label>{t('SendContent:subject_mail')}*</label>
                                            <TextBoxComponent
                                                id="assunto"
                                                ref={textBox => txtSubject = textBox}
                                                name="subjectMail"
                                                htmlAttributes={{ maxLength: 200 }}
                                                cssClass="e-flat"
                                                placeholder={t('SendContent:subject_mail_placeholder')}
                                                change={inputChange}
                                            />
                                        </div>
                                        <CheckBoxComponent
                                            id="receberCopia"
                                            label={t('SendContent:receive_copy')}
                                            change={checkBoxChange}
                                        />
                                        <div className="textBoxDiv">
                                            <div className="textBoxStyle">
                                                <label>{t('SendContent:country')}</label>
                                                <DropDownListComponent
                                                    id="idpais"
                                                    fields={{ text: "nome", value: "id" }}
                                                    placeholder={t('SendContent:country_placeholder')}
                                                    showClearButton={true}
                                                    allowFiltering={true}
                                                    dataSource={i18n.language === "pt" ? countriesPt : countriesEn}
                                                    change={dropdownlistChange}
                                                />
                                            </div>
                                            <div className="textBoxStyle">
                                                <label>Regional</label>
                                                <DropDownListComponent
                                                    id="idregional"
                                                    fields={{ text: "nome", value: "id" }}
                                                    placeholder={t('SendContent:regional_placeholder')}
                                                    showClearButton={true}
                                                    dataSource={i18n.language === "pt" ? regionsPt : regionsEn}
                                                    change={dropdownlistChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="sendGroup">
                                            <div className="sendTop">
                                                <div>
                                                    <span className="mdl2 mdl2-group" />
                                                    <h1>{t('SendContent:sending_group')}</h1>
                                                </div>
                                                <button onClick={() => setOpenDialog(true)}><span>{t('SendContent:view_emails')}</span></button>
                                            </div>
                                            <hr />
                                            <GroupsBox type="press" />
                                        </div>
                                        <div className="sendGroup">
                                            <div className="sendTop">
                                                <div>
                                                    <span className="mdl2 mdl2-list" />
                                                    <h1>{t('SendContent:sending_list')}</h1>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="groupsDiv">
                                                <label>{t('SendContent:list_sending')}</label>
                                                <TextBoxComponent
                                                    id="list"
                                                    htmlAttributes={{ maxLength: 4000 }}
                                                    ref={textBox => txtMailList = textBox}
                                                    placeholder={t('SendContent:list_sending_placeholder')}
                                                    change={emailsOnChange}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-3 col-12">
                                <Actions controls={_controls} />
                            </div>
                        </Row>
                    </Container>
                    <DialogComponent
                        id="dlgEmails"
                        isModal={true}
                        width="878px"
                        visible={openDialog}
                        locale={i18n.language}
                        showCloseIcon={true}
                        beforeClose={() => setOpenDialog(false)}
                    >
                        <span className="header">E-mails</span>
                        <GridComponent
                            id='gridcomp'
                            ref={grid => gridInstance = grid}
                            dataSource={gridDataSource}
                            filterSettings={{ type: 'CheckBox' }}
                            allowFiltering={true}
                            allowPaging={true}
                            allowSorting={true}
                            allowExcelExport={true}
                            allowResizing={true}
                            pageSettings={{ pageSize: 9 }}
                            actionBegin={gridActionBegin}
                            queryCellInfo={renderCellGroup}
                            locale={i18n.language}>
                            <ColumnsDirective >
                                <ColumnDirective
                                    field='jornalista'
                                    headerText={t('Contacts:name')}
                                    width='200'>
                                </ColumnDirective>
                                <ColumnDirective
                                    field='email'
                                    headerText={t('Contacts:email')}
                                    template={emailTemplate}
                                    //template={(item)=> filterTemplate(i18n.language ==="pt" ? item.nomePaisPT : item.nomePaisEN)}
                                    width='240'>
                                </ColumnDirective>
                                <ColumnDirective
                                    id="grupos"
                                    field='grupos'
                                    template={groupTemplate}
                                    headerText={t('SendContent:groups')}
                                    width='400'>
                                </ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Resize, Sort, ContextMenu, Filter, Page]} />
                        </GridComponent>
                    </DialogComponent>
                </>
            }
        </>
    )
}
