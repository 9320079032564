import * as React from 'react';
import { GlobalStateContext } from "../../general/GlobalState";


const MailingContactsContext = React.createContext();

const MailingContactsProvider = ({ children }) => {

    const [globalState] = React.useContext(GlobalStateContext);
    const [mediaTypes, setMediaTypes] = React.useState(null);
    const [countries, setCountries] = React.useState(null);
    const [states, setStates] = React.useState(null);
    const [cities, setCities] = React.useState(null);
    const [item, setItem] = React.useState(null);
    const [groupsSelected, setGroupsSelected] = React.useState([]);
    const [contactListItems, setContactListItems] = React.useState(null);

    return (
        <MailingContactsContext.Provider 
            value={{mediaTypes,setMediaTypes, countries, setCountries, states, setStates, cities, setCities, item, setItem, groupsSelected, setGroupsSelected, contactListItems, setContactListItems}}>
            {children}
        </MailingContactsContext.Provider>
    );
};

export { MailingContactsContext, MailingContactsProvider };