import React, { useContext, createContext, useState } from 'react';

const MessageHouseContext = createContext({});

const MessageHouseProvider = ({ children }) => {
    const [modalData, setModalData] = useState();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [messageHouseItems, setMessageHouseItems] = useState([]);
    const [areasDICI, setAreasDICI] = useState([]);
    const [tempGuid, setTempGuid] = useState("");

    const openEdit = (args) => {
        setOpenEditModal(true);

        if (args) {
            setModalData(args);
        } else {
            setModalData(null);
        }
    }

    return (
        <MessageHouseContext.Provider value={{
            openEditModal,
            setOpenEditModal,
            modalData,
            setModalData,
            messageHouseItems,
            setMessageHouseItems,
            openEdit,
            areasDICI,
            setAreasDICI,
            tempGuid,
            setTempGuid
        }}>
            {children}
        </MessageHouseContext.Provider>
    );
};

const useMessageHouse = () => {
    const context = useContext(MessageHouseContext);

    if (!context) {
        throw new Error('useContract must be within an MessageHouseProvider');
    }

    return context;
};

export { useMessageHouse, MessageHouseProvider };