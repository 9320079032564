import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { LoginStateContext } from './LoginState';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { reset } from './../../services/UsersSVC';

export const ResetComponent = () => {

    const [loginState, setLoginState] = React.useContext(LoginStateContext);

    const { t } = useTranslation();

    const [errorMsg, setErrorMsg] = React.useState(null);

    const { handleSubmit, register, triggerValidation, watch } = useForm({ nativeValidation: true });
    const [validateReset, setValidateReset] = React.useState(true);
    const [validatecConfirm, setValidateConfirm] = React.useState(true);
    const [similarPassword, setSimilarPassword] = React.useState(true);
    const htmlPasswordAttributes = { name: "password", type: "password", maxlength: "100" };


    const onSubmit = async (data) => {
        const url = window.location.search;
        const tokenReset = url.slice(url.indexOf('=') + 1).split('&')[0];

        reset(tokenReset, data.NewPassword)
        .then(response => {
            if (response.ok) {
                setLoginState(loginState => ({ ...loginState, step: 1 }));
            } else {
                return Promise.reject(response);
            }
        })
        .catch(error => {
            console.error(error);
            setErrorMsg(error.statusText);
            setValidateReset(false);
            setValidateConfirm(false);
        });
    };

    let changePassword = async () => {
        const triggerReset = await triggerValidation("NewPassword");
        const triggerConfirm = await triggerValidation("ConfirmPassword");
        const watchReset = watch("NewPassword");
        const watchConfirm = watch("ConfirmPassword");

        setValidateReset(triggerReset);
        setValidateConfirm(triggerConfirm);

        if (!triggerReset || !triggerConfirm) {
            setValidateReset(false);
            setValidateConfirm(false);
        }

        if (watchConfirm !==watchReset) {
            setSimilarPassword(false);
        }

        if ((watchConfirm ===watchReset) && triggerReset && triggerConfirm) {
            handleSubmit(onSubmit)();
        }
    }

    return (
        <>
        <div className="meio">
            <h1>{t('Reset:reset_title')}</h1>
            <h2>{t('Reset:reset_message')}</h2>
            <form>
                <div>
                    <label htmlFor="NewPassword">{t('Reset:first_password')}</label>
                    <TextBoxComponent
                        name="NewPassword"
                        cssClass={!validateReset || !similarPassword ? "e-filled e-error" : "e-filled"}
                        placeholder={t('Reset:new_placeholder')}
                        htmlAttributes={htmlPasswordAttributes}
                        ref={register({
                            required: true,
                            pattern: {
                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#.?&])[A-Z,a-z\d@$!%*#.?&]{8,16}$/i,
                            }
                        })} />
                </div>
                <div>
                    <label htmlFor="ConfirmPassword">{t('Reset:second_password')}</label>
                    <TextBoxComponent
                        name="ConfirmPassword"
                        cssClass={!validatecConfirm || !similarPassword ? "e-filled e-error" : "e-filled"}
                        placeholder={t('Reset:confirm_placeholder')}
                        htmlAttributes={htmlPasswordAttributes}
                        ref={register({
                            required: true,
                            pattern: {
                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#.?&])[A-Za-z\d@$!%*#.?&]{8,16}$/i
                            }
                        })} />
                </div>
            </form>
        </div>
        {(!validateReset || !validatecConfirm) ?
            <div className="msgs">
                <span className="msgError">{t('Reset:invalid_password_message')}</span>
            </div>
        :
            errorMsg &&
            <div className="msgs">
                <span className="msgError">{errorMsg}</span>
            </div>
        }
        {!similarPassword &&
            <div className="msgs">
                <span className="msgError">{t('Reset:different_password_message')}</span>
            </div>
        }
        <div className="botoes right">
            <ProgressButtonComponent id="changePassword" cssClass="c" content={t('Reset:changeButton')}
                    onClick={() => changePassword()} enableProgress isPrimary></ProgressButtonComponent>
        </div>
        </>
    );
};

