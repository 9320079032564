import React, { useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState'
import { useTranslation } from 'react-i18next';
import { ContractContent } from './ContractContent';
import { Buttons } from '../utilities/Buttons';
import { hasPermissions } from '../utilities/Utils';

import '../manageContracts/manageContract.css';
import { useContract } from "./context/ContractsContext";
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";

let userPermission;

export const ManageContracts = () => {

    const [globalState] = useContext(GlobalStateContext);

    const { openEdit } = useContract();

    const { t } = useTranslation();

    useEffect(() => {
        let globalPermissions = globalState.permissions;
        userPermission = globalPermissions.filter((i) => i.idAreaAcesso === 30).map((i) => (i.permission));
        console.log(userPermission);
    }, [globalState]);

    const _controls = {
        permit: userPermission ? userPermission : null,
        savePrimary: false,
        urlCancel: true,
        new: () => openEdit(),
    };

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                <>
                    <PermissionRedirect id="30" />
                    <div className="DefaultTop" />
                    <Container className="containerTop semCaixa">
                        <div className="topTitleButtons">
                            <Row>
                                <div className="col-sm-8 col-12">
                                    <div className="title">
                                        <h1>{t('ManageContracts:title')}</h1>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <div className="buttons">
                                        <Buttons controls={_controls} />
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <Row>
                            <div className="col-lg-12 col-12">
                                <ContractContent />
                            </div>
                        </Row>
                    </Container>
                </>
            }
        </>
    );
}