import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { grantTemporaryAcess } from "../../services/SharedDocumentsSVC";
import { FormValidator } from '@syncfusion/ej2-react-inputs';
import { Redirect } from "react-router-dom";
import FileManager from "../utilities/SharedDocument"
import { createSpinner, showSpinner, setSpinner, hideSpinner } from "@syncfusion/ej2-react-popups";

import './home.css';
import { useModal } from "../../hooks/Modal";

let formObject;
export const FolderManager = (props) => {
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const { buildDialogMessage } = useModal();
    const { t } = useTranslation();

    const [showModal, setShowModal] = React.useState(false);
    const [showCopyPaste, setShowCopyPaste] = React.useState(false);
    const [url, setUrl] = React.useState("");
    const [data, setData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingFile, setIsLoadingFile] = React.useState(false);
    const [response, setResponse] = React.useState("");

    React.useEffect(() => {
        if (isLoadingFile) {
            createSpinner({ target: document.getElementById('loadingFile') })
            showSpinner(document.getElementById('loadingFile'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [isLoadingFile]);

    const modalButtons = [
        {
            type: "submit",
            buttonModel: {
                isPrimary: true,
                content: t("MyDocuments:share"),
                enableProgress: true
            },
            click: () => {
                if (formObject.validate()) {
                    if (data && data.emails && data.emails.length) {
                        setIsLoading(true);
                        grantTemporaryAcess(globalState.accessToken, data)
                            .then(response => {
                                setShowModal(false);
                                // setIsLoading(false);
                                buildDialogMessage({
                                    messagePt: "Compartilhamento feito com sucesso",
                                    messageEn: "Sharing done sucessfully",
                                    titlePt: "Sucesso",
                                    titleEn: "Success"
                                })
                            })
                            .catch(e => {
                                setResponse("Ocorreu um erro no compartilhamento");
                            });
                    } else {
                        setResponse("Email(s) não encontrado(s)");
                    }
                }
            }
        }
    ];

    React.useEffect(() => {
        setIsLoading(false);
        setResponse("");
    }, [showModal]);

    const modalClose = () => {
        document.getElementById('emails').value = '';
        setShowModal(false);
    }
    const emailsOnChange = (e) => {
        if (e.target.value) {
            const emails = e.target.value.split(';')
            let emailsList = [];
            emails.map(email => {
                const mail = email.trim();
                if (validateEmail(mail))
                    emailsList.push(mail);
            });
            if (emailsList.length)
                setData(obj => ({ ...obj, emails: emailsList }));
        }
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    React.useEffect(() => {
        const options = {
            rules: {
                'emails': {
                    required: [true, t('Validation:required')]
                }
            },
            customPlacement: (inputElement, errorElement) => {
                inputElement.parentElement.parentElement.appendChild(errorElement);
            }
        };
        formObject = new FormValidator('#formSharedDocument', options);
    }, []);

    React.useEffect(() => {
        createSpinner({ target: document.getElementById('loading') })
        showSpinner(document.getElementById('loading'));
        setSpinner({ type: 'Bootstrap' });
    }, [isLoading]);

    return (
        <>
            <div className="col-12">
                <div className="documentos">
                    <div className="cabecalho">
                        <p className="mdl2 mdl2-data" />
                        <span>{props.title || t('MyDocuments:title')}</span>
                    </div>
                    <div className="control-section">
                        <FileManager
                            parentProps={props}
                            data={setData}
                            modal={setShowModal}
                            setUrl={setUrl}
                            setLoading={setIsLoadingFile}
                            setShowCopyPaste={setShowCopyPaste}
                        />
                    </div>
                    <DialogComponent
                        id="modalShare"
                        width="662px"
                        isModal={true}
                        buttons={modalButtons}
                        visible={showModal}
                        showCloseIcon={true}
                        close={modalClose}
                        locale={i18n.language}
                    >
                        <div>
                            {!isLoading && <form id="formSharedDocument">

                                <div className="input">
                                    <label htmlFor="titulo">Emails*:</label>
                                    <div className="e-float-input">
                                        <textarea
                                            id="emails"
                                            name="emails"
                                            type="text"
                                            //defaultValue={data && data.chamada}
                                            placeholder="Digite aqui os emails separados por ';'"
                                            onChange={emailsOnChange}
                                        />
                                        <span className="e-float-line" />
                                    </div>
                                </div>
                            </form>}

                            {response && !isLoading && <div>
                                <span className="error">{response}</span>
                            </div>}

                            {isLoading && <div id="loading" />}
                        </div>
                    </DialogComponent>
                    <DialogComponent
                        id="modalCopyPaste"
                        width="662px"
                        isModal={true}
                        visible={showCopyPaste}
                        showCloseIcon={true}
                        close={() => setShowCopyPaste(false)}
                        locale={i18n.language}
                    >
                        <div className="clipboard-div">
                            <span>URL para compartilhamento</span>
                            <div className="input-container">
                                <div className="urlText">{url}</div>
                                <div className="btn-container">
                                    <button onClick={() => navigator.clipboard.writeText(url)} className="clipboard-icon"></button>
                                </div>
                            </div></div>
                    </DialogComponent>
                </div>
            </div>
            {isLoadingFile && <div className="overlay">
                <div id="loadingFile"></div>
            </div>}
        </>
    );
};
