import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { GlobalStateContext } from '../general/GlobalState';
import { useTranslation } from 'react-i18next';
import {
  AdvertisingBudgetProvider,
  AdvertisingBudgetContext,
} from './AdvertisingBudgetContext';
import { FormAdvertisingBudget } from './FormAdvertisingBudget';
import { getURLParamsByKey } from '../utilities/Utils';
import '../advertisingBudget/advertisingBudget.css';

export const FormContainerAdBudget = (props) => {
  const [globalState] = React.useContext(GlobalStateContext);
  const [redirect, setRedirect] = React.useState(false);
  const [IDAdBudget, setIDAdBudget] = React.useState(null);

  const { t } = useTranslation();

  React.useEffect(() => {
    setIDAdBudget(props.match.params.id);
    return () => {
      setIDAdBudget(null);
    }
  }, []);

  return (
    <>
      {globalState.accessToken === null ? (
        <Redirect push={true} to="/login" />
      ) : redirect ? (
        <Redirect push={true} to="/brandingadvertising/advertisingbudget" />
      ) : (
        <>
          <AdvertisingBudgetProvider>
            <div className="DefaultTop" />
            <Container className="containerTop">
              <div className="topTitleButtons">
                <Row>
                  <div className="col-sm-8 col-12">
                    <div className="title">
                      <h1>
                        {!IDAdBudget
                          ? t('FormContainerAdBudget:title')
                          : t('FormContainerAdBudget:title_edit')}
                      </h1>
                    </div>
                  </div>
                </Row>
              </div>
              <Row>
                <FormAdvertisingBudget IDAdBudget={IDAdBudget} />
              </Row>
            </Container>
          </AdvertisingBudgetProvider>
        </>
      )}
    </>
  );
};
