import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState';
import { FolderManager } from "../home/FolderManager";
import FileManager from "../utilities/SharedDocument"

import '../login/login.css';

export const PublicSharedDocuments = (props) => {

    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const { t } = useTranslation();

    return (
        <>
            <div className="DefaultTop" />
            <Container className="containerTop">
                <div className="topTitleButtons">
                    <Row>
                        <div className="col-12">
                            <div className="title">
                                <h1>{ t("MyDocuments:sharedDocuments") }</h1>
                            </div>
                        </div>
                    </Row>
                </div>
                <Row>
                    <div className="col-12">
                        {/* <FolderManager path="/" public={true} token={props.match.params.token} /> */}
                        <div className="documentos">
                            <div className="cabecalho">
                                <p className="mdl2 mdl2-data" />
                                <span>{t('MyDocuments:title')}</span>
                            </div>
                            <div className="control-section">
                                <FileManager parentProps={{path: "/", public: true, token: props.match.params.token}} />
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    );
}
