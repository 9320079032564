import * as React from "react";
import i18n from "i18next";
import { Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState';
import { Actions } from "../utilities/Actions";
import '../suppliers/suppliers.css';
import { TextBoxComponent, MaskedTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { FormValidator } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { GridComponent, ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-grids";
//import { ModalSuppliers } from "../suppliers/ModalSuppliers";
import { getSuppliers, addOrUpdateSupplier, deleteSupplier } from "../../services/SuppliersSVC";
import { getCountries, getRegional, getSupplierDescriptions, getSupplierTypes, getDICIAreas } from "../../services/SystemSVC";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import GenericModal from '../utilities/MessageModal/GenericModal';
import { createSpinner, setSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';

export const SuppliersItem = (props) => {

    let formObject;

    const [loading, setLoading] = React.useState(true);

    const [globalState] = React.useContext(GlobalStateContext);
    const [comboCountries, setComboCountries] = React.useState([]);
    const [comboRegional, setComboRegional] = React.useState([]);
    const [comboDescription, setComboDescription] = React.useState([]);
    const [comboSupplierTypes, setComboSupplierTypes] = React.useState([]);
    const [comboDICIAreas, setComboDICIAreas] = React.useState([]);
    const [redirect, setRedirect] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState({});
    const [regionalVisible, setRegionalVisible] = React.useState(false)
    const [modalMessageParams, setmodalMessageParams] = React.useState({ show: false, });
    const [disabledBtn, setDisabledBtn] = React.useState(false);
    const { t } = useTranslation();

    let idSupplier = props.match.params.id;

    const _controls = {
        className: "suppliers",
        savePrimary: false,
        excludePrimary: true,
        urlView: "",
        urlPreview: "",
        urlDuplicate: "",
        urlSave: (e) => saveSupplier(e),
        urlSend: "",
        urlCancel: () => setRedirect(true),
        urlDelete: idSupplier ? (e) => excludeSupplier(e, idSupplier) : ""
    };

    React.useEffect(() => {
        createSpinner({ target: document.getElementById('loadSup') })
        showSpinner(document.getElementById('loadSup'));
        setSpinner({ type: 'Bootstrap' });
    }, []);


    React.useEffect(() => {
        setLoading(true);
        async function load() {
            let _suppliers = await getSuppliers(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json() } });
            let _country = await getCountries(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json() } });
            let _region = await getRegional(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json() } });
            let _description = await getSupplierDescriptions(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json() } });
            let _supplierTypes = await getSupplierTypes(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json() } });
            let _DICIAreas = await getDICIAreas(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json() } });

            let idCountry = null;

            if (_suppliers) {
                _suppliers = _suppliers.filter(i => i.id ===idSupplier);
                if (_suppliers.length) {
                    idCountry = _suppliers[0].idPais;
                    setSelectedItem(_suppliers[0]);
                }
            }

            setComboCountries(_country);
            setComboRegional(_region);
            setComboDescription(_description);
            setComboSupplierTypes(_supplierTypes);
            setComboDICIAreas(_DICIAreas);

            //hideSpinner(document.getElementById('loadSup'));
            if (idSupplier && idCountry === 6) {
                setRegionalVisible(true)
            }
            setLoading(false);
        }
        load();
    }, [i18n.language]);

    React.useEffect(() => {
        const formValidator = {
            rules: {
                'nome': {
                    required: [true, t('Validation:required')]
                },
                'tipo': {
                    required: [true, t('Validation:required')]
                },
                //'cnpj': {
                //    required: [true, t('Validation:required')]
                //},
                'descricao': {
                    required: [true, t('Validation:required')]
                },
                'area': {
                    required: [true, t('Validation:required')]
                },
                'pais': {
                    required: [true, t('Validation:required')]
                },
                //'regional': {
                //    required: [true, t('Validation:required')]
                //},
                //'estadual': {
                //    required: [true, t('Validation:required')]
                //},
                //'municipal': {
                //    required: [true, t('Validation:required')]
                //},
                'endereco': {
                    required: [true, t('Validation:required')]
                }
            },
            customPlacement: (inputElement, errorElement) => {
                inputElement.parentElement.parentElement.appendChild(errorElement);
                setTimeout(() => {
                    errorElement.style.display = "none";
                }, 3000)
            }
        };
        formObject = new FormValidator('#formSupplierItem', formValidator);
    });


    const buildDialogMessage = (type, msg, callBack, okCallback, notOkCallback, confirmType) => {
        if (type ==='success') {
            setmodalMessageParams({
                title: t('ModalMessages:title'),
                message: t('ModalMessages:created'),
                type: 'success',
                icon: 'mdl2-confirm',
                show: true,
                exitCallback: callBack
            });
        } else if (type ==='updated') {
            setmodalMessageParams({
                title: t('ModalMessages:title'),
                message: t('ModalMessages:updated'),
                type: 'success',
                icon: 'mdl2-confirm',
                show: true,
                exitCallback: callBack
            });
        } else if (type ==='confirm') {
            setmodalMessageParams({
                title: confirmType ==="create" ? t('ModalMessages:title_create_item') : t('ModalMessages:title_update_item'),
                type: "success",
                actionType: 'confirm',
                confirmOkCallback: okCallback,
                confirmNotOkCallback: notOkCallback,
                icon: 'mdl2 mdl2-approve',
                show: true,
            });
        }
        else if (type ==='error') {
            setmodalMessageParams({
                title: t('ManageInternalAreas:error_title'),
                message: t('ManageInternalAreas:error'),
                type: 'error',
                icon: 'e-icon-dlg-close e-icons',
                show: true,
            });
        } else if (type ==='custom-error') {
            setmodalMessageParams({
                title: t('ManageInternalAreas:error_title'),
                message: msg,
                type: 'error',
                icon: 'e-icon-dlg-close e-icons',
                show: true,
                exitCallback: callBack
            });
        } else if (type ==='confirmDelete') {
            setmodalMessageParams({
                title: i18n.language ==="pt" ? 'Deletar item' : 'Delete item',
                type: "error",
                message: i18n.language ==="pt" ? 'Tem certeza que deseja excluir esse item?' : 'Are you sure you want to delete this item?',
                actionType: 'confirm',
                confirmOkCallback: okCallback,
                confirmNotOkCallback: notOkCallback,
                icon: 'mdl2 mdl2-trash',
                show: true,
            });
        } else if (type ==='close') {
            setmodalMessageParams({
                show: false,
            });
        }
    };

    let saveSupplier = (e) => {
        e.preventDefault();
        if (formObject.validate()) {
          setDisabledBtn(true);

            const handleConfirmOK = () => {
                const newItem = selectedItem
                if (!newItem.inscricaoEstadual)
                    newItem.inscricaoEstadual = ''
                if (!newItem.inscricaoMunicipal)
                    newItem.inscricaoMunicipal = ''
                if (!newItem.cnpj)
                    newItem.cnpj = ''
                addOrUpdateSupplier(globalState.accessToken, selectedItem)
                    .then(async response => {
                        if (response.ok) {
                            idSupplier ? buildDialogMessage('updated', '', () => { setRedirect(true) }) : buildDialogMessage('success', '', () => { setRedirect(true) });

                        } else {
                            const erroMSg = await response.json()
                            console.log(erroMSg);
                            buildDialogMessage('custom-error',
                                JSON.stringify(erroMSg.errors ? erroMSg.errors :
                                    `Ocorreu um erro no sistema. Favor entrar em contato com o administrador - OperationId: ${erroMSg.OperationId}`),
                                () => { buildDialogMessage('close') }
                            );
                        }
                        setDisabledBtn(false);
                    })
                    .catch(error => {
                      console.error(error)
                      setDisabledBtn(false);
                    });
            }
            const handleNotOK = () => {
                buildDialogMessage('close')
                setDisabledBtn(false);
            }
            buildDialogMessage('confirm', null, null, () => { handleConfirmOK() }, handleNotOK, idSupplier ? "updated" : 'create')
        }
    }

    let excludeSupplier = async (e, itemID) => {
        e.preventDefault();
        const handleNotOK = () => {
            buildDialogMessage('close')
        }
        const handleOK = async (itemID) => {
            await deleteSupplier(globalState.accessToken, itemID).then((response) => {
                if (response.ok) {
                    setRedirect(true);
                }
            });
            buildDialogMessage('close')
        }
        buildDialogMessage('confirmDelete', null, null, () => { handleOK(itemID) }, handleNotOK, '')
    }



    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                redirect ? <Redirect push={true} to='/admin/Suppliers' /> :
                    loading ? <div id="loadSup"></div> : (
                        <>
                            <div className="DefaultTop" />
                            <form id="formSupplierItem">
                                <Container className="containerTop">
                                    <div className="topTitleButtons">
                                        <Row>
                                            <div className="col-12">
                                                <div className="title">
                                                    <h1>{idSupplier ? t('Supplier:edit_title') : t('Supplier:new_title')}</h1>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                    <Row>
                                        <div className="col-xl-1 col-auto">
                                            <div className="avatar">
                                                <div className="img">
                                                    <div className="mdl2 mdl2-suppliers" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-md-7 col-12">
                                            <div className="supItem">
                                                <div className="top d-flex flex-row align-items-center" >
                                                    <div className="mdl2 mdl2-suitcase" />
                                                    <h2>{t('Supplier:information_supplier')}</h2>
                                                </div>
                                                <div className="content">
                                                    <div className="firstLine d-flex flex-row align-items-center">
                                                        <div className="input">
                                                            <label>{t('Supplier:name')}<label className="e-error"> *</label></label>
                                                            <TextBoxComponent
                                                                id="nome"
                                                                name="nome"
                                                                htmlAttributes={{ maxlength: "100" }}
                                                                placeholder={t('Supplier:name_placeholder')}
                                                                value={selectedItem && selectedItem.nome}
                                                                blur={(e) => setSelectedItem(selectedItem => ({ ...selectedItem, nome: e.value }))}
                                                                locale={i18n.language}
                                                            />
                                                        </div>
                                                        <div className="combo">
                                                            <label>{t('Suppliers:type_column')}<label class="e-error"> *</label></label>
                                                            <DropDownListComponent
                                                                id="tipo"
                                                                name="tipo"
                                                                dataSource={comboSupplierTypes}
                                                                fields={{ text: (i18n.language ==="pt") ? "nomePt" : "nomeEn", value: "id" }}
                                                                placeholder={t('Supplier:type_placeholder')}
                                                                value={selectedItem && selectedItem.idTipoFornecedor}
                                                                change={(e) => { if (e.itemData !==null) { setSelectedItem(selectedItem => ({ ...selectedItem, idTipoFornecedor: Number(e.itemData.id) })) } }}
                                                                locale={i18n.language}
                                                            />
                                                        </div>
                                                        <div className="number">
                                                            <label>{t('Supplier:cnpj')}</label>
                                                            <MaskedTextBoxComponent
                                                                id="cnpj"
                                                                name="cnpj"
                                                                mask={'00.000.000/0000-00'}
                                                                locale={i18n.language}
                                                                value={selectedItem && selectedItem.cnpj}
                                                                blur={(e) => {
                                                                    setSelectedItem({ ...selectedItem, cnpj: e.value })
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="secondLine d-flex flex-row align-items-center">
                                                        <div className="combo">
                                                            <label>{t('Supplier:description')}<label class="e-error"> *</label></label>
                                                            <DropDownListComponent
                                                                id="descricao"
                                                                name="descricao"
                                                                dataSource={comboDescription}
                                                                fields={{ text: (i18n.language ==="pt") ? "nomePt" : "nomeEn", value: "id" }}
                                                                placeholder={t('Supplier:description_placeholder')}
                                                                value={selectedItem && selectedItem.idDescricao}
                                                                change={(e) => { if (e.itemData !==null) { setSelectedItem({ ...selectedItem, idDescricao: Number(e.itemData.id) }) } }}
                                                                locale={i18n.language}
                                                            />
                                                        </div>
                                                        <div className="combo">
                                                            <label>{t('Supplier:area')}<label class="e-error"> *</label></label>
                                                            <DropDownListComponent
                                                                id="area"
                                                                name="area"
                                                                dataSource={comboDICIAreas}
                                                                fields={{ text: (i18n.language ==="pt") ? "nomePt" : "nomeEn", value: "id" }}
                                                                placeholder={t('Supplier:area_placeholder')}
                                                                value={selectedItem && selectedItem.idAreaDICI}
                                                                change={(e) => { if (e.itemData !==null) { setSelectedItem({ ...selectedItem, idAreaDICI: Number(e.itemData.id) }) } }}
                                                                locale={i18n.language}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="thirdLine d-flex flex-row align-items-center">
                                                        <div className="combo">
                                                            <label>{t('Supplier:country')}<label class="e-error"> *</label></label>
                                                            <DropDownListComponent
                                                                id="pais"
                                                                name="pais"
                                                                dataSource={comboCountries}
                                                                fields={{ text: (i18n.language ==="pt") ? "nomePt" : "nomeEn", value: "id" }}
                                                                placeholder={t('Supplier:country_placholder')}
                                                                value={selectedItem && selectedItem.idPais}
                                                                allowFiltering={true}
                                                                change={
                                                                    (e) => {
                                                                        if (e.itemData !==null) {
                                                                            setSelectedItem(selectedItem => ({ ...selectedItem, idPais: Number(e.itemData.id) }))
                                                                            if (Number(e.itemData.id) ===6) {
                                                                                setRegionalVisible(true);
                                                                            } else {
                                                                                setRegionalVisible(false)
                                                                                if (typeof (selectedItem) !=="undefined") {
                                                                                    if (typeof (selectedItem.idRegional) !=="undefined") {

                                                                                        setSelectedItem(selectedItem => ({ ...selectedItem, idRegional: undefined }))
                                                                                    }
                                                                                }
                                                                            }
                                                                        } else {
                                                                            setRegionalVisible(false)
                                                                            if (typeof (selectedItem) !=="undefined") {
                                                                                if (typeof (selectedItem.idRegional) !=="undefined") {

                                                                                    setSelectedItem(selectedItem => ({ ...selectedItem, idRegional: undefined }))
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                locale={i18n.language}

                                                            />
                                                        </div>

                                                        {(regionalVisible ===true)
                                                            ?
                                                            <div className="combo">
                                                                <label>{t('Supplier:regional')}</label>
                                                                <DropDownListComponent
                                                                    id="regional"
                                                                    name="regional"
                                                                    dataSource={comboRegional}
                                                                    fields={{ text: (i18n.language ==="pt") ? "nomePt" : "nomeEn", value: "id" }}
                                                                    placeholder={t('Supplier:regional_placeholder')}
                                                                    value={selectedItem && selectedItem.idRegional}
                                                                    change={(e) => { if (e.itemData !==null) { setSelectedItem({ ...selectedItem, idRegional: Number(e.itemData.id) }) } }}
                                                                    locale={i18n.language}
                                                                    showClearButton={true}
                                                                />
                                                            </div>

                                                            : null
                                                        }

                                                        <div className="number">
                                                            <label>{t('Supplier:subscription1')}</label>
                                                            <TextBoxComponent
                                                                id="estadual"
                                                                name="estadual"
                                                                htmlAttributes={{ maxlength: "100" }}
                                                                placeholder={t('Supplier:subscription1_placeholder')}
                                                                value={selectedItem && selectedItem.inscricaoEstadual}
                                                                blur={(e) => setSelectedItem(selectedItem => ({ ...selectedItem, inscricaoEstadual: e.value }))}
                                                                locale={i18n.language}
                                                            />
                                                        </div>
                                                        <div className="number">
                                                            <label>{t('Supplier:subscription2')}</label>
                                                            <TextBoxComponent
                                                                id="municipal"
                                                                name="municipal"
                                                                htmlAttributes={{ maxlength: "100" }}
                                                                placeholder={t('Supplier:subscription2_placeholder')}
                                                                value={selectedItem && selectedItem.inscricaoMunicipal}
                                                                blur={(e) => setSelectedItem(selectedItem => ({ ...selectedItem, inscricaoMunicipal: e.value }))}
                                                                locale={i18n.language}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="fourthLine d-flex flex-row align-items-center">
                                                        <div className="input">
                                                            <label>{t('Supplier:address')}<label class="e-error"> *</label></label>
                                                            <TextBoxComponent
                                                                id="endereco"
                                                                name="endereco"
                                                                htmlAttributes={{ maxlength: "200" }}
                                                                placeholder={t('Supplier:address_placeholder')}
                                                                value={selectedItem && selectedItem.endereco}
                                                                blur={(e) => setSelectedItem(selectedItem => ({ ...selectedItem, endereco: e.value }))}
                                                                locale={i18n.language}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-12">
                                            <Actions disabled={disabledBtn} controls={_controls} />
                                        </div>
                                    </Row>
                                </Container>
                            </form>
                        </>
                    )
            }
            <GenericModal params={modalMessageParams} />
        </>
    )
}
