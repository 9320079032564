import React, { useEffect } from 'react';
import './menu.css';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { LoginStateContext } from '../login/LoginState';
import { DropDownButtonComponent, ItemModel } from '@syncfusion/ej2-react-splitbuttons';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { EventEmitter } from '../utilities/Events'

import { menu } from '../../services/MenuSVC';

import { LanguageComponent } from '../general/LanguageComponent.js';
import { GlobalStateContext } from '../general/GlobalState';

export const AdminMenu = () => {

    const [loginState, setLoginState] = React.useContext(LoginStateContext);
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const [itemsAdmin, setItemsAdmin] = React.useState([]);
    const [photoUser, setPhotoUser] = React.useState(null);
    const [profile, setProfile] = React.useState([]);

    const { t } = useTranslation();

    const logout = () => {
        setGlobalState(globalState => ({ ...globalState, accessToken: null }));
        localStorage.removeItem("NovoCGCUserCache");
        setLoginState(loginState => ({ ...loginState, step: 1 }));
    };

    React.useEffect(() => {
        EventEmitter.subscribe('logout', () => logout());
        EventEmitter.subscribe('updateMyPhoto', () => updateUserPhoto());
        menu(globalState.accessToken, true)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(result => {
                setItemsAdmin(result);
            })
            .catch(error => {
                console.error(error);
            })
    }, []);

    React.useEffect(() => {
        if (globalState)
            updateUserPhoto();

    }, [globalState]);

    const updateUserPhoto = () => {
        let version = new Date().toISOString();
        setPhotoUser(`${globalState.userPhoto}&sizeType=31&v=${version}`);
        localStorage.setItem("NovoCGCUserCache", JSON.stringify(globalState));
    }


    const userName = globalState.userName;

    return (
        <div>
            <ul id="menuAd">
                <NavItem>
                    <NavLink tag={Link} to={`/profile/${globalState.userId}`}>{t('AdminMenu:profile')}</NavLink>
                </NavItem>
                {itemsAdmin && itemsAdmin.length > 0 &&
                    <NavItem>
                        <NavLink><strong>Admin</strong></NavLink>
                        <ul className="admin">
                            {itemsAdmin.map((item, index) => (
                                <React.Fragment key={index}>
                                    <NavItem>
                                        <NavLink tag={Link} to={item.url}>
                                            {i18n.language ==="pt" ? item.nomePT : item.nomeEN}
                                        </NavLink>
                                    </NavItem>
                                </React.Fragment>
                            ))}
                            <NavItem>
                                <NavLink tag={Link} to="/admin/errorlogs">
                                    {i18n.language ==="pt" ? "Logs de Erros" : "Error Logs"}
                                </NavLink>
                            </NavItem>
                        </ul>
                    </NavItem>
                }

                <NavItem className="logout">
                    <NavLink tag={Link} onClick={logout} to="/login">{t('AdminMenu:logout')}</NavLink>
                </NavItem>
            </ul>
            <DropDownButtonComponent target="#menuAd" className="adminMenu" cssClass="e-caret-hide">
                <div className="user">
                    <span>{userName}</span>
                    <div className="img">
                        <img src={photoUser} />
                    </div>
                </div>
                <div className="menuItemBox">
                    <div className="menuIcon" />
                </div>
            </DropDownButtonComponent>
        </div>
    )
}
