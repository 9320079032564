import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../general/GlobalState";
import { createSpinner, showSpinner, setSpinner, hideSpinner } from "@syncfusion/ej2-react-popups";
import { GridComponent, ColumnsDirective, ColumnDirective, ForeignKey, Filter, Inject, VirtualScroll, Sort, ContextMenu, Page, Resize, Edit, Toolbar, ExcelExport } from '@syncfusion/ej2-react-grids';
import { ComboBoxComponent, AutoCompleteComponent, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent, DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { getCountries, getAllBusinessAreas, getRegional } from "../../services/SystemSVC";
import { getPressItems, getPressItemsFiltered } from "../../services/PressSVC";
import { users } from "../../services/UsersSVC";
import '../releases/releases.css';
import { hasPermissions } from "../utilities/Utils";
import { useModal } from "../../hooks/Modal";

export const WebcastList = (props) => {

    const { t } = useTranslation();
    const { refWebcastNotice, gridInstance } = props;

    const [globalState] = React.useContext(GlobalStateContext);
    const [isVisible, setIsVisible] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [fillUsersData, setFillUsersData] = React.useState([]);
    const [fillBusinessAreaData, setFillBusinessAreaData] = React.useState([]);
    const [fillCountriesData, setFillCountriesData] = React.useState([]);
    const [fillRegionalData, setFillRegionalData] = React.useState([]);
    const [webcastNoticeItems, setWebcastNoticeItems] = React.useState(null);
    const [allWebcastNotices, setAllWebcastNotices] = React.useState(null);
    const [filteredWebcastNoticeItems, setFilteredWebcastNoticeItems] = React.useState({ idTipo: 6, idPais: 6 });
    const ddlRegionalRef = React.useRef();
    const { buildDialogMessage } = useModal();

    React.useEffect(() => {
        if (loading) {
            createSpinner({ target: document.getElementById('loadWebcastNotices') });
            showSpinner(document.getElementById('loadWebcastNotices'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [loading]);

    React.useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language]);

    React.useEffect(() => {
        setLoading(true);
        async function load() {
            let _users = await users(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                });
            let _businessAreas = await getAllBusinessAreas(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                });
            let _countries = await getCountries(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                });
            let _regional = await getRegional(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                });
            const filterQstring = globalState.regional ? `?$filter=idRegional eq ${globalState.regional.id}` : "";
            let _pressItems = await getPressItems(globalState.accessToken, 6, filterQstring)
                .then(response => { if (response.ok) { return response.json() } });

            if (_pressItems) {
              _pressItems = _pressItems.filter(item => {
                item.bloqueado = item.bloqueado ? i18n.language ==="pt" ? "Bloqueado" : "Blocked" : i18n.language ==="pt" ? "Terminado" : "Finished";
                return item;
              });

                setWebcastNoticeItems(_pressItems);
                setAllWebcastNotices(_pressItems);
            }

            let oldUsers = _pressItems?.map(item => {
              return { nome: item.autores }
            });

            const set = new Set();
            const newUsers = oldUsers.filter(item => {
              const duplicate = set.has(item?.nome);
              set.add(item?.nome);

              return !duplicate;
            })

            const newBusinessAreas = [..._businessAreas, { id: - 1, nomeEn: "All", nomePt: "Todos" }]
            const countries = [..._countries, { id: - 1, nomeEn: "All", nomePt: "Todos" }]
            const newRegional = [..._regional, { id: - 1, nomeEn: "All", nomePt: "Todos" }]

            setFillUsersData(newUsers);
            setFillBusinessAreaData(newBusinessAreas);
            setFillCountriesData(countries);
            setFillRegionalData(newRegional);

            hideSpinner(document.getElementById('loadWebcastNotices'));
            setLoading(false);
        }
        load();
    }, [i18n.language]);

    React.useEffect(()=>{
        if(fillRegionalData.length && !loading){
            if(globalState.regional)
                ddlRegionalRef.current.value = globalState.regional.id;
            else
                setIsVisible(false);
        }
    },[fillRegionalData, loading]);

    const statusWebcastNotice = [{
        text: i18n.language ==='pt' ? 'Bloqueado' : 'Blocked',
        value: true
    }, {
        text: i18n.language ==='pt' ? 'Terminado' : 'Finished',
        value: false
    }, {
      text: i18n.language ==='pt' ? 'Todos' : 'All',
      value: null
    }
    ]

    const filteredWebcastNotices = async () => {
        if (filteredWebcastNoticeItems) {
            let _filteredWebcastNotices = await getPressItemsFiltered(globalState.accessToken, filteredWebcastNoticeItems, 6)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                });

            _filteredWebcastNotices = _filteredWebcastNotices.filter(item => {
                item.bloqueado = item.bloqueado ? i18n.language ==="pt" ? "Bloqueado" : "Blocked" : i18n.language ==="pt" ? "Terminado" : "Finished";
                return item;
            });
            setWebcastNoticeItems(_filteredWebcastNotices);

            setIsVisible(false);
        } else {
            setWebcastNoticeItems(allWebcastNotices);
        }
    }

    let webcastNoticeDate = (args) => {
        const dateWebcastNotice = new Date(args.data);
        return (
            <span>{dateWebcastNotice.toLocaleDateString(i18n.language)}</span>
        )
    }

    let webcastNoticeTitle = (args) => {
        return (
            <span className="title" onClick={() => props.edit(args)}>{args.titulo}</span>
        );
    }

    let webcastNoticeStatus = (args) => {
      if (args.bloqueado === 'Bloqueado') {
        return (<span className="status nao">{i18n.language ==='pt' ? 'Bloqueado' : 'Blocked'}</span>)
      } else {
        return (<span className="status sim">{i18n.language ==='pt' ? 'Terminado' : 'Finished'}</span>)
      }
    }

    return (
        <>
            {loading ? <div id="loadWebcastNotices" /> :
                <>
                    <div className={`busca ${isVisible ? "aberto" : ""}`}>
                        <div className="filtroUsuarios d-flex">
                            <div className="SearchTitle">
                                <span className="mdl2 mdl2-search"></span>
                                <p>{t('WebcastNotices:search_text')}</p>
                            </div>
                            <div className="searchUsers">
                                <div className="e-input-in-wrap">
                                    <TextBoxComponent
                                        className="e-input"
                                        placeholder={t('WebcastNotices:search_placeholder')}
                                        locale={i18n.language}
                                        value={filteredWebcastNoticeItems && filteredWebcastNoticeItems.conteudo ? filteredWebcastNoticeItems.conteudo : ""}
                                        blur={(e) => setFilteredWebcastNoticeItems(
                                            filteredWebcastNoticeItems => ({
                                                ...filteredWebcastNoticeItems,
                                                conteudo: e.value
                                            })
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="btnBusca d-flex">
                                {!isVisible &&
                                    <div className="btnFilter">
                                        <ButtonComponent
                                            className="e-outline filter"
                                            cssClass='e-primary filter'
                                            onClick={() => setIsVisible(!isVisible)}>
                                            <span className="mdl2 mdl2-filter"></span>
                                            {t('WebcastNotices:filters')}
                                        </ButtonComponent>
                                    </div>
                                }
                                {isVisible &&
                                    <div className="btnFilter">
                                        <ButtonComponent
                                            className="e-outline filter active-filter"
                                            cssClass='e-primary filter'
                                            onClick={() => setIsVisible(!isVisible)}>
                                            <span className="mdl2 mdl2-filter"></span>
                                            {t('WebcastNotices:filters')}
                                        </ButtonComponent>
                                    </div>
                                }
                                <div className="btnSearch">
                                    <ButtonComponent cssClass='e-primary search'
                                        //onClick={() => filterWebcastNotices()}
                                        onClick={() => filteredWebcastNotices()}
                                    >
                                        {t('WebcastNotices:search')}
                                    </ButtonComponent>
                                </div>
                            </div>
                        </div>
                        {isVisible &&
                            <div className="filtrosOcultos seis d-flex">
                                <DateRangePickerComponent
                                    id="date"
                                    cssClass="e-filled"
                                    placeholder={t('WebcastNotices:date')}
                                    locale={i18n.language}
                                    change={(e) => setFilteredWebcastNoticeItems(
                                        filteredWebcastNoticeItems => ({
                                            ...filteredWebcastNoticeItems,
                                            dataInicio: e.value && e.value[0].toISOString().split('.')[0],
                                            dataFim: e.value && e.value[1].toISOString().split('.')[0]
                                        })
                                    )}
                                />
                                <AutoCompleteComponent
                                    id="autor"
                                    autofill={true}
                                    cssClass="e-filled"
                                    placeholder={t('WebcastNotices:author')}
                                    dataSource={fillUsersData}
                                    fields={{ value: 'nome' }}
                                    //change={(e) => setFilterWebcastNoticeAuthor(e.value)}
                                    change={(e) => setFilteredWebcastNoticeItems(
                                        filteredWebcastNoticeItems => ({
                                            ...filteredWebcastNoticeItems,
                                            autor: e.value
                                        })
                                    )}
                                />
                                <DropDownListComponent
                                    id="areaNegocio"
                                    cssClass="e-filled"
                                    placeholder={t('WebcastNotices:business_area')}
                                    showClearButton
                                    dataSource={fillBusinessAreaData}
                                    fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                                    locale={i18n.language}
                                    change={(e) => setFilteredWebcastNoticeItems(
                                        filteredWebcastNoticeItems => ({
                                            ...filteredWebcastNoticeItems,
                                            idAreaNegocio: e.value
                                        })
                                    )}

                                />
                                <DropDownListComponent
                                    id="idPais"
                                    cssClass="e-filled"
                                    placeholder={t('WebcastNotices:country')}
                                    showClearButton
                                    allowFiltering={true}
                                    dataSource={fillCountriesData}
                                    fields={{ text: (i18n.language ==="pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
                                    locale={i18n.language}
                                    //change={(e) => setFilterWebcastNoticeCountry(e.value)}
                                    value={filteredWebcastNoticeItems ? filteredWebcastNoticeItems.idPais : ""}
                                    change={(e) => setFilteredWebcastNoticeItems(
                                        filteredWebcastNoticeItems => ({
                                            ...filteredWebcastNoticeItems,
                                            idPais: e.value
                                        })
                                    )}
                                />
                                <DropDownListComponent
                                    id="regional"
                                    ref={ddlComponent => ddlRegionalRef.current = ddlComponent}
                                    cssClass="e-filled"
                                    placeholder={t('WebcastNotices:regional')}
                                    showClearButton
                                    dataSource={fillRegionalData}
                                    fields={{ text: (i18n.language ==="pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
                                    value={filteredWebcastNoticeItems ? filteredWebcastNoticeItems.idRegional : globalState.regional? globalState.regional.id : ''}
                                    locale={i18n.language}
                                    change={(e) => setFilteredWebcastNoticeItems(
                                        filteredWebcastNoticeItems => ({
                                            ...filteredWebcastNoticeItems,
                                            idRegional: e.value
                                        })
                                    )}
                                />
                                <DropDownListComponent
                                    id="status"
                                    cssClass="e-filled"
                                    placeholder={t('WebcastNotices:status')}
                                    showClearButton
                                    dataSource={statusWebcastNotice}
                                    fields={{ text: "text", value: "value" }}
                                    locale={i18n.language}
                                    value={filteredWebcastNoticeItems && filteredWebcastNoticeItems.bloqueado}
                                    change={(e) => setFilteredWebcastNoticeItems(
                                        filteredWebcastNoticeItems => ({
                                            ...filteredWebcastNoticeItems,
                                            bloqueado: e.value
                                        })
                                    )}
                                />
                            </div>
                        }
                    </div>
                    <div className="listaReleases">
                        <GridComponent
                            id='gridcomp'
                            dataSource={webcastNoticeItems}
                            filterSettings={{ type: 'Menu' }}
                            allowFiltering={false}
                            allowPaging={true}
                            allowSorting={true}
                            allowExcelExport={true}
                            ref={(grid) => refWebcastNotice(grid)}
                            locale={i18n.language}>
                            <ColumnsDirective >
                                <ColumnDirective
                                    field='data'
                                    template={webcastNoticeDate}
                                    headerText={t('WebcastNotices:date')}
                                    width='100'>
                                </ColumnDirective>
                                <ColumnDirective
                                    field='titulo'
                                    template={webcastNoticeTitle}
                                    headerText={t('WebcastNotices:name')}
                                    width='400'>
                                </ColumnDirective>
                                <ColumnDirective
                                    field="autores"
                                    headerText={t('WebcastNotices:author')}
                                    //template={(item)=> filterTemplate(i18n.language ==="pt" ? item.nomePaisPT : item.nomePaisEN)}
                                    width='350'>
                                </ColumnDirective>
                                <ColumnDirective
                                    field="bloqueado"
                                    template={webcastNoticeStatus}
                                    headerText={t('WebcastNotices:status')}
                                    width='150'>
                                </ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Edit, ForeignKey, Toolbar, ExcelExport]} />
                        </GridComponent>

                    </div>
                </>
            }
        </>
    )
}
