import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { UploaderComponent, TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { updateBanners, addBanners, deleteBanners } from "../../services/BannerHomeSVC";
import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-inputs';
import { createSpinner, setSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { useModal } from "../../hooks/Modal";
import { hasPermissions } from "../utilities/Utils";

const uploadIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="61.1" height="62.1" viewBox="0 0 61.1 62.1"><defs><style>.a{fill:#dbdbdb;}.b{fill:#007e7a;}</style></defs><g transform="translate(-690 -235)"><path class="a" d="M715.8,285.9H704.7V297H701V285.9H690v-3.7h11.1V271.1h3.7v11.1h11.1v3.7ZM741.6,238a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H712.2v-3.7H738V279.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V241.7H704.9v22.1h-3.7V238Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V279.5Z"/><path class="b" d="M717.8,282.9H706.7V294H703V282.9H692v-3.7h11.1V268.1h3.7v11.1h11.1v3.7ZM743.6,235a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H714.2v-3.7H740V276.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V238.7H706.9v22.1h-3.7V235Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V276.5Z"/></g></svg>';

let formObject;

export const ModalBanners = (props) => {

    const { setBannersList, bannersList, setRefresh, data, toggle } = props;

    const [globalState] = React.useContext(GlobalStateContext);
    const [bannerItem, setBannerItem] = React.useState(data);
    const [openModal, setOpenModal] = React.useState(false);
    const [openModalDelete, setOpenModalDelete] = React.useState(false);
    const [openModalConfirm, setOpenModalConfirm] = React.useState(false);
    const [showBannerInfo, setShowBannerInfo] = React.useState(false);
    const idiomaRef = React.useRef(null);
    const ativoRef = React.useRef(null);

    const { buildDialogMessage } = useModal();

    const [loadingModal, setLoadingModal] = React.useState(false);

    const { t } = useTranslation();

    let modalButtons;
    let modalDelete;
    let modalConfirmDelete;

    if (data !=="") {
        modalButtons = [
            {
                type: "submit",
                buttonModel: {
                    isPrimary: true,
                    content: i18n.language ==="pt" ? "Deletar" : "Delete",
                    cssClass: `${!hasPermissions(54, "Delete", globalState) && "hidden"} bannerDelete`
                },
                click: () => {
                    setOpenModalDelete(true);
                }
            },
            {
                type: "submit",
                buttonModel: {
                    isPrimary: true,
                    content: i18n.language ==="pt" ? "Salvar" : "Save",
                    cssClass: `${!hasPermissions(54, "Edit", globalState) && 'hidden'} bannerSave`
                },
                click: () => {
                    onSubmitClick();
                }
            }
        ]
    } else {
        modalButtons = [
            {
                type: "submit",
                buttonModel: {
                    cssClass: `${!hasPermissions(54, "Create", globalState) && 'hidden'} bannerSave`,
                    isPrimary: true,
                    content: i18n.language ==="pt" ? "Salvar" : "Save"
                },
                click: () => {
                    onSubmitClick();
                }
            }]
    }

    modalDelete = [
        {
            type: "submit",
            buttonModel: {
                isPrimary: false,
                content: i18n.language ==="pt" ? "Cancelar" : "Cancel",
                cssClass: "bannerDelete"
            },
            click: () => {
                setOpenModalDelete(false);
            }
        },
        {
            type: "submit",
            buttonModel: {
                isPrimary: true,
                content: i18n.language ==="pt" ? "Sim" : "Yes",
                cssClass: "bannerSave"
            },
            click: () => {
                deleteBanner();
            }
        }
    ]

    React.useEffect(() => {
        const options = {
            rules: {
                'titulo': {
                    required: [true, t('Validation:required')]
                },
                'chamada': {
                    required: [true, t('Validation:required')]
                },
                'idioma': {
                    required: [true, t('Validation:required')]
                },
                'ativo': {
                    required: [true, t('Validation:required')]
                }
            },
            customPlacement: (inputElement, errorElement) => {
                inputElement.parentElement.parentElement.appendChild(errorElement);
            }
        }
        formObject = new FormValidator('#idModalBanner', options);
    });

    const onSubmitClick = () => {
        if (formObject.validate() && bannerItem.imagemB64) {
            updateBanner();
            removeFiles();
            toggle.close();
        } else {
            if (!bannerItem.imagemB64) {
                document.getElementById("validateImage").innerHTML = i18n.language ==="pt" ? "Imagem obrigatória" : "Required image";
            } else {
                document.getElementById("validateImage").innerHTML = "";
            }
        }
    }

    const isDefaultDisabled = () => {
        if (data !=="" && hasPermissions(54, "Edit", globalState)) {
            return false;
        } else if (!data && hasPermissions(54, "Create", globalState)) {
            return false;
        } else {
            return true;
        }
    };

    const target = document.getElementById("uploadBanner");

    let uploadImage = () => {
        document.getElementsByClassName("e-file-select-wrap")[0].querySelector("button").click();
        return false;
    }

    let removeFiles = () => {
        const clearThumb = document.getElementById("UploadFiles") && document.getElementById("UploadFiles").ej2_instances[0];
        if (clearThumb !== null && clearThumb.clearAll !== null && typeof (clearThumb.clearAll) !== "undefined") {
            return clearThumb.clearAll();
        }
    }

    React.useEffect(() => {
        createSpinner({ target: document.getElementById('loadingBanner') });
        showSpinner(document.getElementById('loadingBanner'));
        setSpinner({ type: 'Bootstrap' });
    }, [loadingModal]);

    const onFileUploadSucceeded = (event) => {
        if (event.operation ==="upload") {
            const reader = new FileReader();
            const file = document.getElementById("UploadFiles").files[0];
            reader.addEventListener("load", function () {
                let bannerObj = { ...bannerItem };
                bannerObj.imagemB64 = reader.result;
                setBannerItem(bannerObj);
            }, false);
            reader.readAsDataURL(file);
        }
        if (event.operation ==="remove") {
            let bannerObj = { ...bannerItem };
            bannerObj.imagemB64 = "";
            setBannerItem(bannerObj);
            removeFiles();
        }
        document.getElementById("validateImage").innerHTML = "";
    }

    const textBoxOnChange = (args) => {
        let bannerObj = { ...bannerItem };
        bannerObj[args.target.id] = args.target.value;
        setBannerItem(bannerObj);
    }

    const comboBoxOnChange = (args) => {
        let bannerObj = { ...bannerItem };
        bannerObj[args.element.id] = args.value;
        setBannerItem(bannerObj);
    }

    const bannerIdioma = [{
        text: i18n.language ==="pt" ? "Português" : "Portuguese",
        value: 1
    }, {
        text: i18n.language ==="pt" ? "Inglês" : "English",
        value: 2
    }]

    const bannerStatus = [{
        text: i18n.language ==="pt" ? "Sim" : "Yes",
        value: true
    }, {
        text: i18n.language ==="pt" ? "Não" : "No",
        value: false
    }];

    React.useEffect(() => {
        if (data.id) {
            let version = new Date().toISOString();
            let bannerObj = { ...bannerItem };

            if (bannerObj.id) {
                setShowBannerInfo(true);
            }

            bannerObj.imagemB64 = `${process.env.REACT_APP_API_URL}/_api/BannerHome/GetBannerHome?token=${globalState.tokenLightWeight}&IdBannerHome=${data.id}&v=${version}`;
            setBannerItem(bannerObj);
            console.log("bannerObj", bannerObj);
        }
    }, []);

    React.useEffect(() => {
        setOpenModal(true);
    }, [props.status])

    let deleteBanner = () => {

        let version = new Date().toISOString();

        deleteBanners(globalState.accessToken, data.id);
        let findIdBanner = bannersList.filter(item => item.id !==data.id);
        setBannersList(findIdBanner);

        setRefresh(version);
    }

    let updateBanner = () => {

        if (bannerItem.imagemB64) {
            if (data) {
                let dataApi = {
                    id: bannerItem.id,
                    ativo: bannerItem.ativo,
                    chamada: bannerItem.chamada,
                    idIdioma: bannerItem.idIdioma,
                    imagemB64: bannerItem.imagemB64 ? bannerItem.imagemB64.split(',')[1] : null,
                    titulo: bannerItem.titulo,
                    link: bannerItem.link
                };

                updateBanners(globalState.accessToken, dataApi)
                    .then((response) => {
                        if (response.ok) { return response.json(); }
                    })
                    .then((result) => {
                        changeDataBanner(result);
                    })
                    .catch(error => {
                        buildDialogMessage({
                            titlePt: "Erro",
                            titleEn: "Error",
                            messagePt: "Erro ao atualizar o banner",
                            messageEn: "Error while updating the banner",
                            error: 500
                        });
                    });

                buildDialogMessage({
                    titlePt: "Sucesso",
                    titleEn: "Sucesso",
                    messagePt: "Banner atualizado com sucesso",
                    messageEn: "Banner successfully updated"
                });

            } else {
                let bannerObj = bannerItem;
                bannerObj.imagemB64 = bannerItem.imagemB64 ? bannerItem.imagemB64.split(',')[1] : null;

                addBanners(globalState.accessToken, bannerObj)
                    .then((response) => {
                        if (response.ok) { return response.json(); }
                    })
                    .then((result) => {
                        createNewBanner(result);
                    })
                    .catch(error => {
                        buildDialogMessage({
                            titlePt: "Erro",
                            titleEn: "Error",
                            messagePt: "Erro ao adicionar o banner",
                            messageEn: "Error while adding the banner",
                            error: 500
                        });
                    });


                buildDialogMessage({
                    titlePt: "Sucesso",
                    titleEn: "Sucesso",
                    messagePt: "Banner adicionado com sucesso",
                    messageEn: "Banner successfully added"
                });
            }
        }
    }

    const changeDataBanner = (id) => {

        let version = new Date().toISOString();

        bannersList.map(i => {
            if (i.id ===id) {
                i.ativo = bannerItem.ativo;
                i.chamada = bannerItem.chamada;
                i.idIdioma = bannerItem.idIdioma;
                i.src = `${process.env.REACT_APP_API_URL}/_api/BannerHome/GetBannerHome?token=${globalState.tokenLightWeight}&IdBannerHome=${data.id}&version=${version}`;
                i.titulo = bannerItem.titulo;
                i.link = bannerItem.link;
            }
        })

        setBannersList(bannersList);

        setRefresh(version);
    }

    const createNewBanner = (id) => {
        let version = new Date().toISOString();

        bannerItem.id = id;
        bannerItem.imagemB64 = "data:image/jpeg;base64," + bannerItem.imagemB64;
        bannerItem.src = `${process.env.REACT_APP_API_URL}/_api/BannerHome/GetBannerHome?token=${globalState.tokenLightWeight}&IdBannerHome=${id}&version=${version}`;
        bannersList.push(bannerItem);
        setBannersList(bannersList);

        setRefresh(version);
    }

    const confirmDelete = () => {
        deleteBanner();
        setOpenModalConfirm(true);
    }

    const closeModal = () => {
        removeFiles();
        toggle.close();
    }

    let dropAreaRef;
    let uploadObj;

    const onCreated = () => {
        if (uploadObj) {
            uploadObj.dropArea = dropAreaRef;
            uploadObj.dataBind();
        }
    }

    const onSetAttachmentsFiles = event => {
        if (event.filesData[0].statusCode ==="1") {
            setLoadingModal(true);
            event.filesData.map((file) => {
                const reader = new FileReader();
                reader.addEventListener("load", function () {
                    let bannerObj = { ...bannerItem };
                    bannerObj.imagemB64 = reader.result;
                    setBannerItem(bannerObj);
                    setShowBannerInfo(true);
                    setLoadingModal(false);
                }, false);
                reader.readAsDataURL(file.rawFile);
            });
            document.getElementById("validateImage").innerHTML = "";

        } else {
            document.getElementById("validateImage").innerHTML = "O tamanho do arquivo é muito grande";
        }

    }

    return (
        <div>
            {(bannerItem.imagemB64 || !data) && (props.status) && openModal &&
                <div>
                    <DialogComponent
                        id="modalBanners"
                        width="662px"
                        isModal={true}
                        buttons={modalButtons}
                        visible={openModal}
                        showCloseIcon={true}
                        beforeClose={toggle.close}
                        close={removeFiles}
                        locale={i18n.language}
                    >
                        {openModal ?
                            <form id="idModalBanner">
                                <div ref={dropAreaEle => dropAreaRef = dropAreaEle} className="upload" id="uploadBanner">
                                    {bannerItem && bannerItem.imagemB64 ?
                                        <div>
                                            <span id="change" onClick={() => uploadImage()}>
                                                <img src={bannerItem.imagemB64} />
                                            </span>
                                        </div>
                                        :
                                        <div className="new">
                                            <span dangerouslySetInnerHTML={{ __html: uploadIcon }} />
                                            <p>{i18n.language ==="pt" ? "Arraste o arquivo aqui ou faça o" : "Drag the file here or"}<span onClick={() => uploadImage()}> {i18n.language ==="pt" ? "carregamento" : "upload"}</span></p>
                                        </div>
                                    }
                                </div>

                                <div>
                                    {showBannerInfo && <div className="custom-e-upload e-upload e-control-wrapper e-lib e-keyboard e-form-upload">
                                        <ul className="e-upload-files">
                                            <li className="e-upload-file-list" data-files-count="1">
                                                <span className="e-file-container">
                                                    <span className="e-file-name">banner{bannerItem.id}.png</span>
                                                </span>
                                                {!isDefaultDisabled() && <button type="button" onClick={() => uploadImage()}>
                                                    {i18n.language ==="pt" ? "Trocar banner" : "Change banner"}
                                                </button>}
                                            </li>
                                        </ul>
                                    </div>}
                                </div>

                                <div id="validateImage" />

                                <UploaderComponent
                                    id="UploadFiles"
                                    name="upload"
                                    tyle="file"
                                    created={onCreated}
                                    selected={onSetAttachmentsFiles}
                                    // removing={onRemovingAttachsFiles}
                                    allowedExtensions=".jpg, .png, .jpeg"
                                    dropArea={target}
                                    htmlAttributes={{ tabindex: "0" }}
                                    maxFileSize={3000000}
                                    locale={i18n.language}
                                    multiple={false}
                                    // success={(event) => (onFileUploadSucceeded(event))}
                                    ref={upload => uploadObj = upload}
                                />
                                {loadingModal && <div id="loadingBanner" />}
                                <div className={`input ${loadingModal ? "removeMargin" : ""}`}>
                                    <label htmlFor="titulo">{i18n.language ==="pt" ? "Titulo *" : "Title *"}</label>
                                    <div className="e-float-input">
                                        <input
                                            id="titulo"
                                            name="titulo"
                                            maxLength="20"
                                            type="text"
                                            defaultValue={data && data.titulo}
                                            placeholder={i18n.language ==="pt" ? "Digite aqui o título do vídeo..." : "Enter the video title here ..."}
                                            onChange={(args) => (
                                                textBoxOnChange(args)
                                            )}
                                            disabled={isDefaultDisabled()}
                                        />
                                        <span className="e-float-line" />
                                    </div>
                                </div>

                                <div className="input">
                                    <label htmlFor="titulo">{i18n.language ==="pt" ? "Descrição" : "Description"} <label class="e-error">*</label></label>
                                    <div className="e-float-input">
                                        <textarea
                                            id="chamada"
                                            name="chamada"
                                            maxLength="255"
                                            type="text"
                                            defaultValue={data && data.chamada}
                                            placeholder={i18n.language ==="pt" ? "Digite aqui a chamada para o banner..." : "Type the banner call here ..."}
                                            onChange={(args) => (
                                                textBoxOnChange(args)
                                            )}
                                            disabled={isDefaultDisabled()}
                                        />
                                        <span className="e-float-line" />
                                    </div>
                                </div>

                                <div className="input">
                                    <label htmlFor="titulo">Link</label>
                                    <div className="e-float-input">
                                        <textarea
                                            id="link"
                                            name="link"
                                            type="text"
                                            defaultValue={data && data.link}
                                            placeholder={i18n.language ==="pt" ? "Cole a url aqui..." : "Paste the url here ..."}
                                            onChange={(args) => (
                                                textBoxOnChange(args)
                                            )}
                                            disabled={isDefaultDisabled()}
                                        />
                                        <span className="e-float-line" />
                                    </div>
                                </div>

                                <div className="half">
                                    <div className="input">
                                        <label htmlFor="idioma">{i18n.language ==="pt" ? "Idioma" : "Language"} <label class="e-error">*</label></label>
                                        <DropDownListComponent
                                            id="idIdioma"
                                            ref={idiomaRef}
                                            name="idioma"
                                            cssClass="e-filled"
                                            dataSource={bannerIdioma}
                                            fields={{ text: "text", value: "value" }}
                                            locale={i18n.language}
                                            value={data && data.idIdioma}
                                            created={() => {
                                                if (isDefaultDisabled()) {
                                                    ativoRef.current.enabled = false;
                                                    idiomaRef.current.enabled = false;
                                                }
                                            }}
                                            placeholder={i18n.language ==="pt" ? "Selecione o idioma" : "Select the language"}
                                            htmlAttributes={{ tabindex: "0" }}
                                            change={(args) => (
                                                comboBoxOnChange(args)
                                            )}
                                            disabled={isDefaultDisabled()}
                                        />
                                    </div>
                                    <div className="input">
                                        <label htmlFor="ativo">{i18n.language ==="pt" ? "Ativo" : "Active"} <label class="e-error">*</label></label>
                                        <DropDownListComponent
                                            id="ativo"
                                            ref={ativoRef}
                                            name="ativo"
                                            cssClass="e-filled"
                                            dataSource={bannerStatus}
                                            fields={{ text: "text", value: "value" }}
                                            locale={i18n.language}
                                            created={() => {
                                                if (isDefaultDisabled()) {
                                                    ativoRef.current.enabled = false;
                                                    idiomaRef.current.enabled = false;
                                                }
                                            }}
                                            value={data && data.ativo}
                                            placeholder={i18n.language ==="pt" ? "Selecione o status" : "Select status"} edrft43
                                            htmlAttributes={{ tabindex: "0" }}
                                            change={(args) => (
                                                comboBoxOnChange(args)
                                            )}
                                            disabled={isDefaultDisabled()}
                                        />
                                    </div>
                                </div>
                            </form>
                            :
                            <div id="loadModal" className="loadModal" />
                        }
                    </DialogComponent >
                    <DialogComponent
                        id="deleteModal"
                        isModal={true}
                        visible={openModalDelete}
                        locale={i18n.language}
                    >
                        <div>
                            {!openModalConfirm && <>
                                <div className="divTrash">
                                    <span className="mdl2 mdl2-trash" />
                                    <div className="phraseDelete">
                                        <label id="delete">{i18n.language ==="pt" ? "Excluir" : "Delete"}</label>
                                        <label id="labelDelete">{i18n.language ==="pt" ? "Certeza que deseja excluir?" : "Sure you want to delete?"}</label>
                                    </div>
                                </div>
                                <hr />
                            </>
                            }
                            {!openModalConfirm ?
                                <div className="footerButtons">
                                    <button onClick={() => setOpenModalDelete(false)}>{i18n.language ==="pt" ? "Cancelar" : "Cancel"}</button>
                                    <button onClick={() => confirmDelete()}>{i18n.language ==="pt" ? "Sim" : "Yes"}</button>
                                </div>
                                :
                                <div className="deleteComplete">
                                    <div className="confirmTrash">
                                        <span className="success mdl2 mdl2-trash"></span>
                                    </div>
                                    <label>{i18n.language ==="pt" ? "Item excluído com sucesso" : "Successfully deleted"}</label>
                                    <button onClick={() => closeModal()}>{i18n.language ==="pt" ? "Sair" : "Exit"}</button>
                                </div>
                            }
                        </div>
                    </DialogComponent>

                </div>
            }
        </div>
    );
}