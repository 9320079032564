import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from "../general/GlobalState";
import { Carousel, CarouselItem, CarouselIndicators, CarouselCaption } from 'reactstrap';
import { getBanners } from "../../services/BannerHomeSVC";
import './home.css';

export const BannerHome = (props) => {

    const [globalState] = React.useContext(GlobalStateContext);
    const [banner, setBanner] = React.useState([]);

    const { t } = useTranslation();

    React.useEffect(() => {

        async function load() {
            let _banner = await getBanners(globalState.accessToken, i18n.language ==="pt" ? 1 : 2)
                .then(response => { if (response.ok) { return response.json() } });

            let _findBanners;
            let _arrayBanners = [];

            if (_banner) {
                _findBanners = _banner.filter(item => item.ativo === true);
            }

            let version = new Date().toISOString();

            _findBanners.map((item) => {
                _arrayBanners.push(
                    {
                        src: `${process.env.REACT_APP_API_URL}/_api/BannerHome/GetBannerHome?token=${globalState.tokenLightWeight}&IdBannerHome=${item.id}&version=${version}`,
                        titulo: item.titulo,
                        chamada: item.chamada,
                        link: item.link,
                        id: item.id,
                        status: item.ativo,
                        language: item.idIdioma
                    }
                )
            });

            setBanner(_arrayBanners);

        }
        load();
    }, [i18n.language]);

    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === banner.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? banner.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    return (
        <Carousel
            className="boxBannerHome"
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
            <CarouselIndicators items={banner} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {banner && banner.map((i) => (
                <CarouselItem
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                    key={i.id}
                >
                    <CarouselCaption captionText={i.chamada} captionHeader={i.titulo} />
                    {i.link ?
                        <a href={i.link} target="_blank">
                            <img src={i.src} alt={i.title} />
                        </a>
                        :
                        <img src={i.src} alt={i.title} />
                    }
                </CarouselItem>
            ))}
        </Carousel>
    );
}