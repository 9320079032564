import React, { useContext, createContext, useState } from 'react';
import MessageModal from '../components/utilities/MessageModal/MessageModal';

const ModalContext = createContext(null);

const ModalProvider = ({ children }) => {
    const [modalMessageParams, setModalMessageParams] = useState({});

    const buildDialogMessage = ({
        error,
        titlePt,
        titleEn,
        messagePt,
        icon,
        type,
        messageEn
    }) => {

        if (modalMessageParams && !modalMessageParams.show) {
            if (error) {
                switch (error) {
                    case 401: {
                        messagePt = "Você não tem permissão para essa requisição";
                        messageEn = "You do not have permission to this request";
                        titlePt = "Um erro ocorreu";
                        titleEn = "An error has ocurred";
                        break;
                    }
                    case 403: {
                        messagePt = "Você não tem permissão para essa requisição";
                        messageEn = "You do not have permission to this request";
                        titlePt = "Um erro ocorreu";
                        titleEn = "An error has ocurred";
                        break;
                    }
                    case 500: {
                        messagePt = "Um erro ocorreu ao fazer a requisição";
                        messageEn = "An error has ocurred while making the request";
                        titlePt = "Um erro ocorreu";
                        titleEn = "An error has ocurred";
                        break;
                    }
                }
            }

            if (!icon && error) {
                icon = 'e-icon-dlg-close e-icons';
            } else if (!icon && !error) {
                icon = 'mdl2-confirm';
            }

            setModalMessageParams({
                title: {
                    titlePt,
                    titleEn,
                },
                message: {
                    messagePt,
                    messageEn
                },
                icon,
                show: true,
                error: error ? error : null
            });
        }
    };

    return (
        <ModalContext.Provider value={{
            buildDialogMessage,
            modalMessageParams,
            setModalMessageParams
        }}>
            {children}

            <MessageModal />
        </ModalContext.Provider>
    )
};

const useModal = () => {
    const context = useContext(ModalContext);

    if (!context && false) {
      throw new Error('useModal must be within an ModalProvider');
    }

    return context;
};

export { useModal, ModalProvider };
