//#region IMPORTS
import * as React from "react";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { GlobalStateContext } from "../general/GlobalState";

import { L10n } from "@syncfusion/ej2-base";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { createSpinner, showSpinner, setSpinner, hideSpinner, DialogComponent } from "@syncfusion/ej2-react-popups";
import { ButtonComponent, CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import {
  RichTextEditorComponent,
  HtmlEditor,
  Inject,
  Image,
  Link,
  Toolbar,
  QuickToolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Sort,
  ContextMenu,
  Page,
  Resize,
} from "@syncfusion/ej2-react-grids";

import {
  getReleaseDefault,
  richTextDefault,
  getReleaseAttachmentsHeader,
  getImagesTitle,
  getImagesTitleString,
} from "../releases/Default";
import { ReleaseStateContext } from "../releases/ReleaseState";
import "../releases/releases.css";

import { getCountries, getBusinessAreas, getRegional } from "../../services/SystemSVC";
import { users, getMyProfile } from "../../services/UsersSVC";
import { getSignatureItemByRegion } from "../../services/SignatureSVC";
import { uploadPressMedia, removeMedia, addPressItem, updatePressItem } from "../../services/PressSVC";

import {
  PressBoilerPlatePT,
  PressBoilerPlateEN,
  PressFooterPT,
  PressFooterEN,
  UploadIcon
} from "../utilities/Constants";

import { clearAttachmentsArea, getPressMediaTemplate, getPressVideoTemplate, getYoutubeVideoTemplate, mountSignature, youtube_parser } from '../../helpers/EmailCreator'
import { convertDateToUTC, elementContainsSelection, getRelativeFilePath, isOrContainsNode, langName, restoreSelectionDocument } from '../../helpers/Functions'

//#endregion

let userPermission;

export const PressForm = (props) => {
  //#region GLOBAL FORM STATE AND HELPERS

  const {
    content,
    idType,
    idAccessArea,
    itemExists,
    endPointUrl,
    storageUrl,
    listItemsUrl,
  } = props;

  const { t } = useTranslation();

  const [globalState]                                                 = React.useContext(GlobalStateContext);
  const { releaseState, setReleaseState, setRedirectId, redirectId }  = React.useContext(ReleaseStateContext);

  const [redirect, setRedirect]             = React.useState(false);
  const [editFirstLoad, setEditFirstLoad]   = React.useState(true);
  const [loading, setLoading]               = React.useState(true);
  const [activeUsers, setActiveUsers]       = React.useState([]);
  const [currentUser, setCurrentUser]       = React.useState({});

  //#endregion

  //#region FILE UPLOAD STATE
  const [videoSrc, setVideoSrc]                       = React.useState();
  const [showThumb, setShowThumb]                     = React.useState(false);
  const [percentSize, setPercentSize]                 = React.useState(1);
  const [fileParams, setFileParams]                   = React.useState("");
  const [filePath, setFilePath]                       = React.useState("");
  //#endregion

  //#region FORM FIELD STATE AND DATA
  const [blocked, setBlocked]                                 = React.useState(false);
  const [openModal, setOpenModal]                             = React.useState(false);
  const [openModalConfirm, setOpenModalConfirm]               = React.useState(false);
  const [areaNegocio, setAreaNegocio]                         = React.useState(0);
  const [idPais, setPais]                                     = React.useState(0);
  const [idRegional, setRegional]                             = React.useState(0);
  const [language, setLanguage]                               = React.useState(1);
  const [title, setTitle]                                     = React.useState("");
  const [subTitle, setSubTitle]                               = React.useState("");
  const [approver, setApprover]                               = React.useState("");
  const [authors, setAuthors]                                 = React.useState("");
  const [mediaGuid, setMediaGuid]                             = React.useState("");
  const [attachmentTitleCount, setAttachmentTitleCount]       = React.useState('');
  const [attachmentSubTitleCount, setAttachmentSubTitleCount] = React.useState('');
  const [attachmentAudioCount, setAttachmentAudioCount]       = React.useState('');
  const [fillBusinessAreaData, setFillBusinessAreaData]       = React.useState([]);
  const [fillCountriesData, setFillCountriesData]             = React.useState([]);
  const [fillRegionalData, setFillRegionalData]               = React.useState([]);
  const [itemId, setItemId]                                   = React.useState(null);
  const [signature, setSignature]                             = React.useState(null);
  const [rteContent, setRteContent]                           = React.useState(null);
  const [tableSelection, setTableSelection]                   = React.useState(null);
  const [date, setDate]                                       = React.useState(new Date());
  const [isArgs, setIsArgs]                                   = React.useState('');
  const [typeFileVideo, setTypeFileVideo]                     = React.useState('');

  const obj = {
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  };
  const { register, errors, handleSubmit } = useForm(obj);
  //#endregion

  //#region VARIABLES AND OBJECTS

  let txtAttachmentTitleRef =     React.useRef(null);
  let filePathUploaded =          React.useRef(null);
  let dialogObjYtb =              React.useRef(null);
  let txtSubtitleRef =            React.useRef(null);
  let txtApproverRef =            React.useRef(null);
  let datePickerRef =             React.useRef(null);
  let txtAuthorRef =              React.useRef(null);
  let txtAudioRef =               React.useRef(null);
  let gridMediaRef =              React.useRef(null);
  let formEditable =              React.useRef((idType ===1 || idType ===3) || releaseState.mailingStatus.length === 0);
  let txtTitleRef =               React.useRef(null);
  let sidebarRef =                React.useRef(null);
  let dialogObj =                 React.useRef(null);
  let uploadObj =                 React.useRef(null);
  let rteObj =                    React.useRef(null);
  let action =                    React.useRef(null);

  const releaseIdioma = [
    {
      text: t("Generic:pt"),
      value: 1,
    },
    {
      text: t("Generic:en"),
      value: 2,
    },
  ];

  const format = {
    types: [
      {
        text: t("Press:format_p"),
        value: "p",
        cssClass: "e-paragraph",
      },
      {
        text: t("Press:format_pre"),
        value: "pre",
        cssClass: "e-code",
      },
      {
        text: t("Press:format_blockquote"),
        value: "blockquote",
        cssClass: "e-quote",
      },
      {
        text: t("Press:format_h1"),
        value: "h1",
        cssClass: "e-h1",
      },
      {
        text: t("Press:format_h2"),
        value: "h2",
        cssClass: "e-h2",
      },
      {
        text: t("Press:format_h3"),
        value: "h3",
        cssClass: "e-h3",
      },
      {
        text: t("Press:format_h4"),
        value: "h4",
        cssClass: "e-h4",
      },
      {
        text: t("Press:format_h5"),
        value: "h5",
        cssClass: "e-h5",
      },
      {
        text: t("Press:format_h6"),
        value: "h6",
        cssClass: "e-h6",
      },
    ],
  };

  const itemsVale = [
    { key: "BoPla", text: "Boiler Plate" },
    { key: "Vid", text: t("Release:video") },
    { key: "Aud", text: t("Release:audio") },
    { key: "Img", text: t("Release:image") },
    { key: "Anx", text: t("Release:attachment") },
    { key: "Ger", text: t("Release:manageMedia") },
    { key: "Ytb", text: t("Press:youtube_video") },
  ];

  const formatOptions = {
    default: "Paragraph",
    width: "65px",
    types: [
      {
        text: "Paragraph",
        value: "P",
        cssClass: "e-paragraph",
        command: "Formats",
        subCommand: "P",
      },
      {
        text: "Code",
        value: "Pre",
        cssClass: "e-code",
        command: "Formats",
        subCommand: "Pre",
      },
      {
        text: "Heading 1",
        value: "H1",
        cssClass: "e-h1",
        command: "Formats",
        subCommand: "H1",
      },
      {
        text: "Heading 2",
        value: "H2",
        cssClass: "e-h2",
        command: "Formats",
        subCommand: "H2",
      },
      {
        text: "Heading 3",
        value: "H3",
        cssClass: "e-h3",
        command: "Formats",
        subCommand: "H3",
      },
      {
        text: "Heading 4",
        value: "H4",
        cssClass: "e-h4",
        command: "Formats",
        subCommand: "H4",
      },
    ],
  };

  const options = [
    "Formats",
    "FontColor",
    "Bold",
    "Italic",
    "Underline",
    "CreateLink",
    "|",
    "ClearFormat",
    "SuperScript",
    "SubScript",
    "LowerCase",
    "UpperCase",
    "|",
    "OrderedList",
    "UnorderedList",
    "Outdent",
    "Indent",
    "|",
    {
      tooltipText: t("Release:insertMedia"),
      template: "#btnVale",
    },
    "|",
    {
      tooltipText: t("Release:resetTemplate"),
      template: "#btnReset",
    },
    "|",
    "FullScreen",
  ];

  const toolbarSettings = {
    items: options,
    enableFloating: true,
  };

  const rteEnableHtmlSanitizer = {
    enableHtmlSanitizer: false
  }

  let asyncSettings = {
    saveUrl: `${endPointUrl}?${fileParams}`,
  };

  let rteFileUploadEleYtb = null;
  const rteFileUploadRefYtb = (element) => {

    rteFileUploadEleYtb = element;
  };

  let rteFileUploadEle = null;
  const rteFileUploadRef = (element) => {

    rteFileUploadEle = element;
  };

  let rteSectionEle = null;
  const rteSectionRef = (element) => {

    rteSectionEle = element;
  };

  let videoDropContainerEle = null;
  const videoDropContainerRef = (element) => {

    videoDropContainerEle = element;
  };

  const insertImageSettings = {
    allowedTypes: [".jpeg", ".jpg", ".png"],
    display: "inline",
    width: "auto",
    height: "auto",
    saveFormat: "Blob",
    saveUrl: `${endPointUrl}?${fileParams}`,
    path: filePath,
  };

  const contextMenuItems = [
    "SortAscending",
    "SortDescending",
    "FirstPage",
    "PrevPage",
    "LastPage",
    "NextPage",
  ];
  const target = document.getElementById("uploadVideo");

  const uploadImage = () => {

    document
      .getElementsByClassName("e-file-select-wrap")[0]
      .querySelector("button")
      .click();
    return false;
  };

  //#endregion

  //#region EFFECTS

  React.useEffect(() => {


    L10n.load({
      pt: {
        richtexteditor: {
          browse: "Carregar",
        },
      },
    });

    //SET LOADING SPINNER
    if (loading) {
      createSpinner({ target: document.getElementById("loadTextBox") });
      showSpinner(document.getElementById("loadTextBox"));
      setSpinner({ type: "Bootstrap" });
    }
    async function load() {

      let getInfo = async (getReq) => await getReq(globalState.accessToken)
        .then((response) => {

          if (response.ok) {
            return response.json();
          }
        });

      const _businessAreas  = await getInfo(getBusinessAreas);
      const _countries      = await getInfo(getCountries);
      const _regional       = await getInfo(getRegional);
      const _users          = await getInfo(users);
      const _currentUser    = await getInfo(getMyProfile);

      setCurrentUser(_currentUser);
      setFillBusinessAreaData(_businessAreas);
      setFillCountriesData(_countries);
      setFillRegionalData(_regional);
      setActiveUsers(_users);

      let fileItemIdParam;

      if (!itemExists) {
        setPais(_currentUser.idPais);
        setRegional(_currentUser.idRegional);
        setAuthors(_currentUser.nome);

        if (_currentUser.idRegional) {
          let signature = await getSignatureItemByRegion(
            globalState.accessToken,
            _currentUser.idRegional
          ).then((response) => {

            if (response && response.ok && response.status !== 204) {
              return response.json();
            }
          });

          setSignature(signature);
        }

      } else {
        fileItemIdParam = "&id=" + itemExists;
        setItemId(itemExists);
      }

      //MELHORAR O GUID (METODO)
      let guid = (
        generateGUID() +
        generateGUID() +
        "-" +
        generateGUID() +
        "-4" +
        generateGUID().substr(0, 3) +
        "-" +
        generateGUID() +
        "-" +
        generateGUID() +
        generateGUID() +
        generateGUID()
      ).toLowerCase();

      setFileParams(`pressTypeId=${idType}&mediaGuid=${guid}${fileItemIdParam ? fileItemIdParam : ""}`);
      setFilePath(`${storageUrl}${itemExists ? itemExists : guid}/`);
      setMediaGuid(guid);

      if (document.getElementById("loadTextBox"))
        hideSpinner(document.getElementById("loadTextBox"));

      setEditFirstLoad(false);
      setLoading(false);
    }
    load();
  }, []);

  //IF ITEM EXISTS/EDITFORM
  React.useEffect(() => {

    if (releaseState.releaseItem) {
      let releaseItem = releaseState.releaseItem;
      setBlocked(releaseItem.bloqueado);
      setLanguage(releaseItem.idIdioma);
      setTitle(releaseItem.titulo);
      setSubTitle(releaseItem.subtitulo);
      setAuthors(releaseItem.autores);
      setAreaNegocio(releaseItem.idAreaNegocio);
      setPais(releaseItem.idPais);
      setDate(new Date(releaseItem.data));
      setRegional(releaseItem.idRegional);
      setApprover(releaseItem.aprovadoPor);

      setRteContent(releaseItem.conteudo);
      setEditFirstLoad(false);
      formEditable.current =
        idType ===1 || idType ===3
          ? true
          : releaseState.mailingStatus.length === 0;
    }
    return function cleanup() {
      setEditFirstLoad(false);
    };
  }, [releaseState.releaseItem]);

  React.useEffect(() => {

    const load = async () => {

      const tdSig = document.querySelector("td#signature");

      if(tdSig) {
        const response = await getSignatureItemByRegion(globalState.accessToken, idRegional)

        if(response && response.ok && response.status !== 204) {
          const newSig = await response.json();
          tdSig.innerHTML = mountSignature(newSig, langName(language) || i18n.language, globalState.tokenLightWeight)
          setSignature(newSig);
        }
        else {
          tdSig.innerHTML = '';
          setSignature(null);
        }
      }
    }
    load();
  }, [idRegional])

  React.useEffect(() => {

    const load = async () => {

      const tdSig = document.querySelector("td#signature");

      if(tdSig) {
        const response = await getSignatureItemByRegion(globalState.accessToken, idRegional)

        if(response && response.ok && response.status !== 204) {
          const newSig = await response.json();
          setSignature(newSig);
          if (language && rteObj.current)
            updateRteHeaderTemplate(rteObj.current.value, newSig);
        }
        else {
          setSignature(null);
          if (language && rteObj.current)
            updateRteHeaderTemplate(rteObj.current.value, null);
        }

      }
    }
    load();

  }, [language]);

  //TRIGGERS WHEN HEADER FIELDS ARE CHANGED (title, date, subtitle)
  React.useEffect(() => {

    execUpdateRteHeaderTemplate();
  }, [date, title, subTitle]);

  const execUpdateRteHeaderTemplate = () => {

    if (rteObj.current && (date !== "Invalid Date" || title || subTitle)) {
      updateRteHeaderTemplate(rteObj.current.value, signature);
    }
  };

  React.useEffect(() => {

    if (itemId) {
      setReleaseState((releaseState) => ({
        ...releaseState,
        changeInfo: {
          ...releaseState.changeInfo,
          Id: parseInt(itemId),
        },
      }));
    }
  }, [itemId]);

  React.useEffect(() => {

    let globalPermissions = globalState.permissions;
    userPermission = globalPermissions
      .filter((i) => i.idAreaAcesso === idAccessArea)
      .map((i) => i.permission);
  }, [globalState]);

  React.useEffect(() => {

    if (txtTitleRef.current)
      txtTitleRef.current.placeholder = t("Press:title_placeholder");
    if (txtSubtitleRef.current)
      txtSubtitleRef.current.placeholder = t(
        "Press:subtitle_placeholder"
      );
    if (txtApproverRef.current)
      txtApproverRef.current.placeholder = t(
        "Press:approverBy_placeholder"
      );
    if (txtAuthorRef.current)
      txtAuthorRef.current.placeholder = t("Press:authors_placeholder");
    if (datePickerRef.current)
      datePickerRef.current.placeholder = t("Press:date_placeholder");

    if (document.getElementById("release_rte-edit-view"))
      document
        .getElementById("release_rte-edit-view")
        .setAttribute("contenteditable", false);
  }, [i18n.language]);

  //#endregion

  //#region FUNCTIONS

  var savedSelection;

  const saveSelection = (containerEl) => {

    let selection = window.getSelection();
    if (
      elementContainsSelection(
        document.getElementById("rteDefaultTBodyContent"),
        selection
      )
    ) {
      var range = window.getSelection().getRangeAt(0);
      var preSelectionRange = range.cloneRange();
      preSelectionRange.selectNodeContents(containerEl);
      preSelectionRange.setEnd(range.startContainer, range.startOffset);
      var start = preSelectionRange.toString().length;
      savedSelection = {
        start: start,
        end: start + range.toString().length,
      };
      setTableSelection(savedSelection);
    }
  };

  const restoreSelection = (containerEl, savedSel) => {

    savedSel = tableSelection;
    if (!savedSel) {
      savedSel = {
        start: 276,
        end: 276,
      };
    }

    var charIndex = 0,
      range = document.createRange();
    range.setStart(containerEl, 0);
    range.collapse(true);
    var nodeStack = [containerEl],
      node,
      foundStart = false,
      stop = false;

    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType ===3) {
        var nextCharIndex = charIndex + node.length;
        if (
          !foundStart &&
          savedSel.start >= charIndex &&
          savedSel.start <= nextCharIndex
        ) {
          range.setStart(node, savedSel.start - charIndex);
          foundStart = true;
        }
        if (
          foundStart &&
          savedSel.end >= charIndex &&
          savedSel.end <= nextCharIndex
        ) {
          range.setEnd(node, savedSel.end - charIndex);
          stop = true;
        }
        charIndex = nextCharIndex;
      } else {
        var i = node.childNodes.length;
        while (i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }

    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    return sel;
  };

  const saveSelectionDocument = (containerEl) => {

    var selection = document.selection;
    if (
      elementContainsSelection(
        document.getElementById("rteDefaultTBodyContent"),
        selection
      )
    ) {
      var selectedTextRange = document.selection.createRange();
      var preSelectionTextRange = document.body.createTextRange();
      preSelectionTextRange.moveToElementText(containerEl);
      preSelectionTextRange.setEndPoint("EndToStart", selectedTextRange);
      var start = preSelectionTextRange.text.length;

      savedSelection = {
        start: start,
        end: start + selectedTextRange.text.length,
      };
    }
  };

  const saveCaret = () => {

    if (window.getSelection && document.createRange) {
      //release_rte-edit-view
      saveSelection(document.getElementById("rteDefaultTBodyContent"));
    } else if (document.selection) {
      saveSelectionDocument(
        document.getElementById("rteDefaultTBodyContent")
      );
    }
  };

  const restoreCaret = () => {

    if (window.getSelection && document.createRange) {
      return restoreSelection(
        document.getElementById("rteDefaultTBodyContent"),
        savedSelection
      );
    } else if (document.selection) {
      return restoreSelectionDocument(
        document.getElementById("rteDefaultTBodyContent"),
        savedSelection
      );
    }
  };

  let updateRteHeaderTemplate = (html, sig) => {

    const doc = new DOMParser().parseFromString(html, "text/html");
    const contentObj          = getReleaseDefault(idType, language);
    const contentOtherLangObj = getReleaseDefault(idType, language === 1 ? 2 : 1);
    const lang                = language ? (language === 1 ? "pt" : "en") : "pt";

    const tdSig = doc.getElementById("signature")
    if (tdSig && sig && contentObj.signature !== sig )
      doc.getElementById("signature").innerHTML = mountSignature(sig, langName(language) || i18n.language, globalState.tokenLightWeight);

    doc.getElementById("rteTitle").innerHTML    = title || contentObj.title;
    doc.getElementById("rteSubtitle").innerHTML = subTitle || contentObj.subtitle;
    doc.getElementById("rteDate").innerHTML     = date.toLocaleDateString(lang);

    let currentContent = doc.getElementById("rteDefaultTBodyContent").innerHTML;

    if (currentContent === contentOtherLangObj.content || currentContent === contentObj.content) {
      doc.getElementById("rteDefaultTBodyContent").innerHTML =
        currentContent.replace(contentOtherLangObj.content, contentObj.content);
    }

    const headerEl = doc.querySelector("#tdSecondHeader td");
    if (headerEl) headerEl.innerHTML = contentObj.header;

    const boilerPlateEl = doc.getElementById("boilerPlateVale");
    if (boilerPlateEl) {
      boilerPlateEl.parentNode.removeChild(boilerPlateEl);
      doc.getElementById("boiler").innerHTML =
        language === 1 ? PressBoilerPlatePT : PressBoilerPlateEN;
    }

    const attachmentItem = doc.getElementById("attachmentItem");
    if (attachmentItem) {
      attachmentItem.innerHTML = language === 1 ? "Anexos" : "Attachments";
    }

    const footerEl = doc.querySelector('[id=footerVale] td');
    if (footerEl) footerEl.innerHTML = language === 1 ? PressFooterPT : PressFooterEN;

    rteObj.current.value = doc.documentElement.innerHTML;
    setRteContent(doc.documentElement.innerHTML);
  };

  let changeRichTextByTemplate = (html) => {

    if (!html) {
      saveCaret();
      html = rteObj.current.contentModule
        .getEditPanel()
        .getElementsByTagName("table")[0].outerHTML;
    }

    rteObj.current.value = html;
    restoreCaret();
  };

  let defaultRteTemplate = (e) => {

    let defaultRteContent = getReleaseDefault(idType);

    if (defaultRteContent.date !== new Date())
      defaultRteContent.date = date;
    if (defaultRteContent.title !== title && title)
      defaultRteContent.title = title;
    if (defaultRteContent.subtitle !== subTitle && subTitle)
      defaultRteContent.subtitle = subTitle;

    if (defaultRteContent.signature !== signature && signature)
      defaultRteContent.signature = mountSignature(signature, langName(language) || i18n.language, globalState.tokenLightWeight);

    rteObj.current.value = richTextDefault(
      defaultRteContent,
      i18n.language,
      idType
    );

    if (e) {
      e.preventDefault();
      setOpenModalConfirm(true);
    }
  };

  let selectedTemplate = (args) => {

    action.current = null;
    const lang = language ? language : i18n.language;
    const releaseDefaultObj = getReleaseDefault(idType, language);

    switch (args.item.key) {
      case "BoPla":
        var boilerPlateEl = document.getElementById("boilerPlateVale");
        if (boilerPlateEl)
          boilerPlateEl.parentNode.removeChild(boilerPlateEl);
        else {
          const boilerPlate =
            lang === 1
              ? PressBoilerPlatePT
              : lang === "pt"
                ? PressBoilerPlatePT
                : PressBoilerPlateEN;
          rteObj.current.contentModule
            .getDocument()
            .getElementById("boiler").innerHTML = boilerPlate;
        }
        changeRichTextByTemplate(
          rteObj.current.contentModule
            .getEditPanel()
            .getElementsByTagName("table")[0].outerHTML
        );
        break;
      case "Vid":
        document.getElementById("messageFileSize").innerHTML = `${t(
          "Release:uploadFileSizeMsg"
        )
          } `;
        document.getElementById("messageFileExt").innerHTML = `${t(
          "Release:extension"
        )
          }: .mp4`;
        uploadObj.current.allowedExtensions = ".mp4";
        uploadObj.current.maxFileSize = 1e9;
        action.current = args.item.key;
        insertFile(args.item.key);
        break;
      case "Ytb":
        var youtubeVideo = document.getElementById("youtube_video");
        action.current = args.item.key;
        insertYtb(args.item.key);
        break;
      case "Aud":
        document.getElementById("messageFileSize").innerHTML = `${t(
          "Release:uploadFileSizeMsg"
        )
          } `;
        document.getElementById("messageFileExt").innerHTML = `${t(
          "Release:extension"
        )
          }: .mp3`;
        uploadObj.current.allowedExtensions = ".mp3";
        uploadObj.current.maxFileSize = 1e9;
        action.current = args.item.key;
        insertFile(args.item.key);
        break;
      case "Img":
        document.getElementById("messageFileSize").innerHTML = `${t(
          "Release:uploadImageSizeMsg"
        )
          } `;
        document.getElementById("messageFileExt").innerHTML = `${t(
          "Release:extension"
        )
          }: .jpg /.png /.jpeg`;
        uploadObj.current.allowedExtensions = ".jpg,.png,.jpeg";
        uploadObj.current.maxFileSize = 20e6;
        action.current = args.item.key;
        insertFile(args.item.key);
        break;
      case "Ger":
        sidebarRef.current.toggle();
        break;
      default:
        document.getElementById("messageFileSize").innerHTML = `${t(
          "Release:uploadFileSizeMsg"
        )
          } `;
        document.getElementById("messageFileExt").innerHTML = ``;
        uploadObj.current.allowedExtensions = "";
        uploadObj.current.maxFileSize = 1e9;
        action.current = args.item.key;
        insertFile(args.item.key);
        break;
    }
  };

  let insertFile = (args) => {

    rteObj.current.contentModule.getEditPanel().focus();
    const dlgButtons = [
      {
        buttonModel: {
          content: t("Release:buttonInsert"),
          isPrimary: true,
          disabled: true,
        },
        click: () => onInsert(args),
      },
    ];
    dialogObj.current.buttons = dlgButtons;
    dialogObj.current.element.style.display = "";
    dialogObj.current.content = rteFileUploadEle;
    document
      .getElementById("videoTag")
      .setAttribute("style", "display:none;");
    dialogObj.current.show();

    document
      .getElementById("attachmentLabelWrapper")
      .setAttribute("style", "display:none");

    document
      .getElementById("audioLabelWrapper")
      .setAttribute("style", "display:none");

    if (args === "Anx") {
      document
        .getElementById("attachmentLabelWrapper")
        .setAttribute("style", "display:block");
    }

    if (args === "Aud") {
      document
        .getElementById("audioLabelWrapper")
        .setAttribute("style", "display:block");
    }
  };

  let insertYtb = (args) => {
    (rteObj.current.contentModule.getEditPanel()).focus();
    const dlgButtons = [{ buttonModel: { content: t('Release:buttonInsert'), isPrimary: true }, click: () => onInsert(args) },
    ];
    dialogObjYtb.current.buttons = dlgButtons;
    dialogObjYtb.current.element.style.display = '';
    dialogObjYtb.current.content = rteFileUploadEleYtb;
    dialogObjYtb.current.show();
  }

  let onInsert = (action) => {

    const fileInfo = uploadObj.current.getFileInfo(0, null);
    let fileFullPath = filePathUploaded.current;
    const sel = restoreCaret();
    let range = rteObj.current.getRange();

    switch (action) {
      case "Vid":
        let canvas = document.querySelector("#canvas"),
          video = document.querySelector("#video");
        let serverUrl = video.getAttribute("serverurl");
        let currentSrc = video.currentSrc;
        let splitSrc = currentSrc.split("/");
        let vidFileName = splitSrc.pop();
        let mountThumbName = vidFileName.split(".")[0];
        let fileName = mountThumbName + "_thumb.png";
        canvas.toBlob((blob) => {
          var file = new File([blob], fileName);
          async function saveThumb() {
            await uploadPressMedia(
              globalState.accessToken,
              fileParams,
              file
            )
              .then((response) => response.text())
              .then((response) => {
                if (response) {
                  range.collapse(true);
                  var thumbWrapperDiv =
                    document.createElement("div");
                  thumbWrapperDiv.id = "thumbWrapper";
                  thumbWrapperDiv.setAttribute(
                    "contenteditable",
                    "false"
                  );
                  const videoTemplate = getPressVideoTemplate(
                    serverUrl,
                    response
                  );
                  thumbWrapperDiv.insertAdjacentHTML(
                    "beforeend",
                    videoTemplate
                  );
                  range.insertNode(thumbWrapperDiv);
                  // Move the caret immediately after the inserted element
                  range.setStartAfter(thumbWrapperDiv);
                  range.collapse(true);
                  sel.removeAllRanges();
                  sel.addRange(range);
                }
              });
          }
          saveThumb();
        });
        break;
      case "Aud":
        var audiosWrapper = rteObj.current.contentModule
          .getDocument()
          .getElementById("rteAudios");

        const audioTemplate = getPressMediaTemplate(
          `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-audio2.png`,
          fileInfo.name,
          fileFullPath,
          "mp3",
          txtAudioRef.current.value || ''
        );
        audiosWrapper.insertAdjacentHTML("beforeend", `${audioTemplate}`);

        // Move the caret immediately after the inserted span
        range.setStartAfter(audiosWrapper);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        break;
      case "Img":
        range.collapse(true);
        var imgWrapperDiv = document.createElement("div");
        imgWrapperDiv.id = "imgWrapper";
        imgWrapperDiv.setAttribute(
          "style",
          "display: inline-block; padding: 15px 0px 15px 0px;"
        );
        imgWrapperDiv.setAttribute("contenteditable", false);
        const imageTemplate = getPressMediaTemplate(
          fileFullPath,
          "",
          "",
          "img"
        );
        imgWrapperDiv.insertAdjacentHTML("beforeend", imageTemplate);
        range.insertNode(imgWrapperDiv);

        // Move the caret immediately after the inserted span
        range.setStartAfter(imgWrapperDiv);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        break;
      case "Ytb":
        range.collapse(true);
        var youtubeWrapperDiv = document.createElement("div");
        youtubeWrapperDiv.id = "youtubeVideoWrapper";
        youtubeWrapperDiv.setAttribute('contenteditable', false);
        const urlLabel = document.getElementById('youtubeLabel').value;
        const url = youtube_parser(urlLabel);
        if (!url) {
            document.getElementById("erroYtb").innerHTML = t('Press:erroYtb');
            return;
        } else {
            document.getElementById("erroYtb").innerHTML = "";
        }
        const youtubeTemplate = getYoutubeVideoTemplate(url);
        youtubeWrapperDiv.insertAdjacentHTML('beforeend', youtubeTemplate);
        range.insertNode(youtubeWrapperDiv);
        // Move the caret immediately after the inserted span
        range.setStartAfter(youtubeWrapperDiv);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        document.getElementById('youtubeLabel').value = "";
        break;
      default:
        var attachmentsWrapper = rteObj.current.contentModule
          .getDocument()
          .getElementById("rteAttachments");
        range.setStart(attachmentsWrapper, 0);
        range.collapse(true);
        var attachmentsHeaderTr = rteObj.current.contentModule
          .getDocument()
          .getElementById("attachmentsHeader");
        if (!attachmentsHeaderTr) {
          const attachmentTemplate = getReleaseAttachmentsHeader(
            language === 1 ? "Anexos" : "Attachments"
          );
          attachmentsWrapper.innerHTML = `<td> ${attachmentTemplate}</td> `;
          range.setStartAfter(attachmentsWrapper);
        }

        var attachmentsArea = rteObj.current.contentModule
          .getDocument()
          .querySelectorAll("[id=attachmentWrapper]");
        var index = -1;
        for (var i = 0; i < attachmentsArea.length; i++) {
          if (attachmentsArea[i].childElementCount === 0) {
            index = i;
            break;
          }
        }
        if (index > -1) {
          const mediaTemplate = getPressMediaTemplate(
            `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-attach.png`,
            txtAttachmentTitleRef.current.value || '',
            fileFullPath,
            "attachment"
          );
          attachmentsArea[index].innerHTML = mediaTemplate;
          // Move the caret immediately after the inserted span
          range.setStartAfter(attachmentsArea[index]);
        } else {

          var attachmentWrapperTd = document.createElement("td");
          attachmentWrapperTd.id = "attachmentWrapper";

          attachmentWrapperTd.setAttribute("valign", "center");
          attachmentWrapperTd.setAttribute("bgcolor", "#ffffff");
          attachmentWrapperTd.setAttribute("contenteditable", false);
          attachmentWrapperTd.setAttribute(
            "style",
            "color:#FFFFFF;text-align:center;border:5px solid #FFFFFF;vertical-align:top;"
          );

          const attachmentFilename = txtAttachmentTitleRef.current.value ||
            (language === 1 ? "Anexo" : "Attachment");
          const mediaTemplate = getPressMediaTemplate(
            `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-attach.png`,
            attachmentFilename,
            fileFullPath,
            "attachment"
          );
          attachmentWrapperTd.innerHTML = mediaTemplate;
          var attachmentsTr = rteObj.current.contentModule
            .getDocument()
            .getElementById("rowAttachments");
          attachmentsTr.appendChild(attachmentWrapperTd);
          range.setStartAfter(attachmentWrapperTd);
        }

        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        break;
    }
    uploadObj.current.clearAll();
    dialogOverlay();
    setIsArgs('');
    setTypeFileVideo('');
    setAttachmentTitleCount('');
    setAttachmentSubTitleCount('');
    setAttachmentAudioCount('');
    txtAttachmentTitleRef.current.value = "";
    txtAudioRef.current.value = "";
  };

  let dialogOverlay = () => {

    let activeEle = dialogObj.current.element.querySelector(
      ".char_block.e-active"
    );

    let activeEleYtb = dialogObjYtb.current.element.querySelector(
      ".char_block.e-active"
    );

    if (activeEle || activeEleYtb) {
      activeEle.classList.remove("e-active");
    }
    uploadObj.current.clearAll();
    dialogObj.current.hide();
    dialogObjYtb.current.hide();
    if (document.getElementById("uploadVideo")) {
      document
        .getElementById("uploadVideo")
        .setAttribute("style", "display:block;");
      document.querySelector("#uploadVideo .e-error").innerHTML = "";
    }
    setTypeFileVideo('');
    setIsArgs('');
    setAttachmentTitleCount('');
    setAttachmentSubTitleCount('');
    setAttachmentAudioCount('');
    txtAttachmentTitleRef.current.value = "";
    txtAudioRef.current.value = "";
  };

  function RenderVideoTag({ videoSrc, showThumb }) {
    return (
      <div id="videoTag"
        style={{display: `${isArgs.toLowerCase() === 'vid'
          || isArgs.toLowerCase() === 'ytb' ? `${typeFileVideo ? 'block' : 'none'}` : 'none'}`}}
      >
        <p
          id="thumbMsg"
          style={{ display: "none" }}
          className={"successMessenge"}
        >
          {t("Release:thumbnail_generated")}
        </p>
        <div>
          <video
            preload="metadata"
            serverurl={videoSrc && videoSrc.url}
            crossOrigin={"Anonymous"}
            style={{ maxWidth: "100%", width: "100%" }}
            className={"thumb-video"}
            id="video"
            controls
            onLoadedMetadata={() => videoOnload()}
          >
            <source
              src={videoSrc && videoSrc.blob}
              type="video/mp4"
            />
          </video>
        </div>
        <div style={{ position: "relative" }}>
          <ButtonComponent
            type={"button"}
            className={"thumb-btnGenerate"}
            onClick={(e) => generateThumbOnClick(e)}
          >
            {t("Press:video_thumb_button")}
          </ButtonComponent>
        </div>
        <div>
          <i>
            <label>{t("Press:video_thumb_info")}</label>
          </i>
          <div>
            <canvas
              style={{ maxWidth: "100%", display: "none" }}
              hidden={showThumb}
              id="canvas"
            ></canvas>
          </div>
        </div>
      </div>
    );
  }

  let onRemoveFile = (args) => {

    if (document.getElementById("uploadVideo"))
      document
        .getElementById("uploadVideo")
        .setAttribute("style", "display:block;");

    if (document.getElementById("videoTag"))
      document
        .getElementById("videoTag")
        .setAttribute("style", "display:none;");

    document
      .querySelector("#customTbarDlg button.e-primary")
      .setAttribute("disabled", "disabled");
    args.postRawFile = false;
    const fileInfo = uploadObj.current.getFileInfo(0, null);
    let qstring = `mediaPath = ${mediaGuid} /${fileInfo.name}`;
    removeMedia(globalState.accessToken, qstring, idType);
    setTypeFileVideo('');
    setIsArgs('');
    setAttachmentTitleCount('');
    setAttachmentSubTitleCount('');
    setAttachmentAudioCount('');
  };

  let videoOnload = () => {

    let canvas = document.querySelector("#canvas"),
      video = document.querySelector("#video");
    canvas.width = video?.videoWidth * percentSize;
    canvas.height = video?.videoHeight * percentSize;
  };

  const uploaderCreated = () => {

    if (uploadObj.current) {
      uploadObj.current.dropArea = document.getElementById("uploadVideo");
      uploadObj.current.dataBind();
    }
  };

  const uploaderSelected = (e) => {

    document.querySelector("#uploadVideo .e-error").innerHTML = "";
    if (e.filesData[0].statusCode === "0") {
      e.cancel = true;
      document.querySelector("#uploadVideo .e-error").innerHTML =
        e.filesData[0].status;
    }
  };

  let onFileUploadSuccess = (e) => {

    setTypeFileVideo(e.file.type)
    filePathUploaded.current = e.e.srcElement.response;
    document
      .getElementById("uploadVideo")
      .setAttribute("style", "display:none;");
    let gridDataSource = [...gridMediaRef.current.dataSource];
    if (e.operation === "upload") {
      document
        .querySelector("#customTbarDlg button.e-primary")
        .removeAttribute("disabled");
      switch (action.current) {
        case "Vid":
          if (e.file.type === "mp4") {
            document
              .getElementById("videoTag")
              .setAttribute("style", "display:block;");
            gridDataSource.push({
              url: e.e.srcElement.response,
              type: "Video",
            });
            gridMediaRef.current.dataSource = gridDataSource;

            var file =
              document.getElementById("fileUpload").files[0];
            var url = URL.createObjectURL(file);
            setVideoSrc((videoSrc) => ({
              ...videoSrc,
              blob: url,
              url: e.e.srcElement.response,
            }));
          }
          break;
        case "Aud":
          gridDataSource.push({
            url: e.e.srcElement.response,
            type: "Audio",
            title: txtAudioRef.current.value || '',
          });
          gridMediaRef.current.dataSource = gridDataSource;
          break;
        case "Img":
          gridDataSource.push({
            url: e.e.srcElement.response,
            type: "Image",
          });
          gridMediaRef.current.dataSource = gridDataSource;
          break;
        default:
          gridDataSource.push({
            url: e.e.srcElement.response,
            type: "Attachment",
            title: txtAttachmentTitleRef.current.value || '',
          });
          gridMediaRef.current.dataSource = gridDataSource;
          break;
      }
    }
  };

  let generateThumbOnClick = () => {

    let canvas = document.querySelector("#canvas"),
      video = document.querySelector("#video"),
      canvasCtx = canvas.getContext("2d");
    canvas.setAttribute(
      "style",
      "display:none; width:100%; margin-bottom:1em"
    );
    canvasCtx.drawImage(
      video,
      0,
      0,
      video.videoWidth * percentSize,
      video.videoHeight * percentSize
    );
    document
      .getElementById("thumbMsg")
      .setAttribute("style", "display:block;");
  };

  const fileUploading = (e) => {

    e.currentRequest.setRequestHeader(
      "Authorization",
      "Bearer " + globalState.accessToken
    );
  };

  const generateGUID = () => {

    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };

  const imageUploadingHandler = (e) => {

    e.currentRequest.setRequestHeader(
      "Authorization",
      "Bearer " + globalState.accessToken
    );
  };

  const selectionBeforeDropdownClick = () => {

    let selec = window.getSelection();
    if (elementContainsSelection(document.getElementById("rteDefaultTable"), selec) ) {
      saveCaret();
    }
  };

  const rteCreated = () => {

    document
      .getElementById("release_rte-edit-view")
      .setAttribute("contenteditable", false);
    document
      .querySelector("[contenteditable]")
      .addEventListener("paste", (e) => {

        e.preventDefault();
        const text = (e.originalEvent || e).clipboardData.getData(
          "text/plain"
        );
        window.document.execCommand("insertText", false, text);
      });

    if (releaseState.releaseItem.id) {
      const imageTitle = getImagesTitleString(idType, language);

      const imageTitlePT = getImagesTitleString(idType, "1");
      const imageTitleEN = getImagesTitleString(idType, "2");
      let newTag = releaseState.releaseItem.conteudo;

      if (
        newTag.indexOf(
          `${process.env.REACT_APP_AZURE_STORAGE}/press/System/${imageTitlePT}`
        ) === -1 &&
        newTag.indexOf(
          `${process.env.REACT_APP_AZURE_STORAGE}/press/System/${imageTitleEN}`
        ) === -1
      ) {
        newTag = releaseState.releaseItem.conteudo.replace(
          '<tr id="header">',
          `<td id="header"> ${getImagesTitle(idType, i18n.language)} `
        );
      }

      if (newTag.indexOf('<td id="signature"') === -1) {
        newTag = newTag.replace(
          `<tr id="rteAttachments" contenteditable="false">`,
          `<tr>
            <td id="signature" contentEditable="true">
                ${signature && mountSignature(signature, langName(language) || i18n.language, globalState.tokenLightWeight)}
            </td>
          </tr>
          <tr id="rteAttachments" contenteditable="false">
        `
        );
      }

      if (
        newTag.indexOf("- Vale | Todos os direitos reservados") ===
        -1 &&
        newTag.indexOf("- Vale | All rights reserved") === -1
      ) {
        newTag = newTag.replace(
          "<tfoot>",
          `<tfoot>
              <tr id="footerVale">
                <td>
                  ${language === 1 ? PressFooterPT
                    : language === "pt" ? PressFooterPT
                    : PressFooterEN
                  }
                </td>
              </tr>`
        );
      }

      rteObj.current.value = newTag;
    } else {
      defaultRteTemplate();
    }
  };

  const rteBeforeQuickToolbarOpen = (e) => {

    e.cancel = true;
  };

  const rteBeforeDialogClose = (e) => {

    restoreCaret();
  };

  const rteActionBegin = (e) => {

    let range = rteObj.current.getRange();
    e.cancel = !isOrContainsNode(
      range.commonAncestorContainer,
      document.getElementById("rteDefaultTBodyContent")
    );
  };

  const clearUnusedMedias = () => {

    var urlRegex = /(https?:\/\/[^\s]+)/g;
    let mediasUrls = [
      ...new Set(releaseState.releaseItem.conteudo.match(urlRegex)),
    ];
    const systemIconPath = "/press/System";
    if (mediasUrls) {
      let serverUrls = mediasUrls.filter(
        (item) =>
          item.indexOf(`${process.env.REACT_APP_AZURE_STORAGE}`) >
          -1 && item.indexOf(systemIconPath) === -1
      );
      let newMediasUrls = rteObj.current.value.match(urlRegex);
      if (serverUrls && newMediasUrls && newMediasUrls.length) {
        const urls = newMediasUrls.filter(
          (item) =>
            item.indexOf(`${process.env.REACT_APP_AZURE_STORAGE}`) >
            -1 && item.indexOf(systemIconPath) === -1
        );
        let mediasDeleted = serverUrls.filter((x) => !urls.includes(x));
        mediasDeleted.map((item) => {

          let qstring = `mediaPath=${getRelativeFilePath(
            item.slice(0, -1)
          )}`;
          removeMedia(globalState.accessToken, qstring, idType);
        });
      } else {
        serverUrls.map((item) => {

          let qstring = `mediaPath=${getRelativeFilePath(
            item.slice(0, -1)
          )}`;
          removeMedia(globalState.accessToken, qstring, idType);
        });
      }
    }
  };

  const onSubmitClick = (data) => {
    props.setDisabledBtn(true);

    if (itemId) clearUnusedMedias();

    const addOrUpdate = itemId ? updatePressItem : addPressItem;
    const dataAPI = {
      id: itemId,
      bloqueado: blocked,
      idIdioma: language || 1,
      titulo: title,
      subtitulo: subTitle,
      autores: authors,
      idAreaNegocio: areaNegocio || null,
      idPais: idPais,
      data: convertDateToUTC(date).toISOString(),
      idRegional: idRegional || null,
      aprovadoPor: approver || "",
      conteudo: document.getElementById("rteDefaultTable").outerHTML,
      idTipo: idType,
      mediaGuid: mediaGuid,
    };

    addOrUpdate(globalState.accessToken, dataAPI, idType)
      .then((response) => {

        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then((result) => {

        if (idType ===1 || idType ===3) {
          setReleaseState({
            releaseItem: null,
            urlItemId: null,
            changeInfo: null,
          });
          setRedirectId(result.id);
        } else {
          setRedirect(true);
        }
        props.setDisabledBtn(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        props.setDisabledBtn(false);
      });
  };

  const sidebarCreated = () => {

    sidebarRef.current.element.style.visibility = "";
  };

  const sidebarOpen = (e) => {

    if (gridMediaRef) gridCreated();
  };

  const renderMediaTemplate = (args) => {

    switch (args.type) {
      case "Video":
        return (
          <video
            width={200}
            className={"thumb-video"}
            id="video"
            controls
          >
            <source src={args.url} type="video/mp4"></source>
          </video>
        );
      case "Image":
        return <img src={args.url} width={200} />;
      default:
        return <span>{args.title}</span>;
    }
  };

  const removeMediaTemplate = (args) => {

    return (
      <div className="removeItem">
        <span
          style={{
            cursor: "pointer",
            padding: "10px",
            marginRight: "5px",
          }}
          onClick={() => {

            const doc = new DOMParser().parseFromString(
              rteObj.current.value,
              "text/html"
            );
            let el = doc.querySelector(`a[href="${args.url}"]`);

            let containerId;

            switch (args.type) {
              case "Video":
                containerId = "#thumbWrapper";
                break;
              case "Image":
                el = doc.querySelector(
                  `img[src="${args.url}"]`
                );
                containerId = "#imgWrapper";
                break;
              case "Audio":
                containerId = "#audioWrapper";
                break;
              default:
                containerId = "#attachmentWrapper";
                break;
            }
            var html = "";

            if (el) {
              el.closest(containerId).parentNode.removeChild(
                el.closest(containerId)
              );
              html = doc.documentElement.innerHTML;
              if (args.type === "Attachment") {
                html = clearAttachmentsArea(html);
              }
            }

            let gridDataSource = gridMediaRef.current.dataSource;
            gridMediaRef.current.dataSource = gridDataSource.filter(
              (x) => x.url !==args.url
            );

            rteObj.current.value = html;
          }}
          className="mdl2 mdl2-trash"
        />
      </div>
    );
  };

  const gridCreated = () => {

    const doc = new DOMParser().parseFromString(
      rteObj.current.value,
      "text/html"
    );
    const videoContainers = doc.querySelectorAll("#thumbWrapper a");
    const audioContainers = doc.querySelectorAll("#audioWrapper a");
    const attachmentContainers = doc.querySelectorAll(
      "#attachmentWrapper a"
    );
    const imgContainers = doc.querySelectorAll("#imgWrapper img");
    let arr = [];
    for (var i = 0; i < videoContainers.length; i++) {
      arr.push({ url: videoContainers[i].href, type: "Video" });
    }
    for (var i = 0; i < audioContainers.length; i++) {
      arr.push({
        url: audioContainers[i].href,
        type: "Audio",
        title: audioContainers[i].lastElementChild?.innerHTML,
      });
    }
    for (var i = 0; i < attachmentContainers.length; i++) {
      arr.push({
        url: attachmentContainers[i].href,
        type: "Attachment",
        title: attachmentContainers[i].lastElementChild?.innerHTML,
      });
    }
    for (var i = 0; i < imgContainers.length; i++) {
      arr.push({ url: imgContainers[i].src, type: "Image" });
    }

    gridMediaRef.current.dataSource = arr;
  };

  //#endregion
  const resetButtonEvent = (e) => {

    e.preventDefault();
    setOpenModal(true);
  };

  const editPlaceholder = () => {
    const rteDefaultTBodyContent = document.getElementById('rteDefaultTBodyContent').innerText;

    const placeholder = {
      value: /^Digite aqui um conteúdo para o release\.\.\.$/.test(rteDefaultTBodyContent)
      || /^Type the release content in here\.\.\.$/.test(rteDefaultTBodyContent)
      || /^Digite aqui um conteúdo para a nota\.\.\.$/.test(rteDefaultTBodyContent)
      || /^Type the statement content in here\.\.\.$/.test(rteDefaultTBodyContent)
      || /^Digite aqui um conteúdo para o posicionamento.\.\.$/.test(rteDefaultTBodyContent)
      || /^Type the position paper content in here\.\.\.$/.test(rteDefaultTBodyContent)
      || /^Digite aqui um conteúdo para o Q \& A\.\.\.$/.test(rteDefaultTBodyContent)
      || /^Type the Q&A content in here\.\.\.$/.test(rteDefaultTBodyContent)
      || /^Digite aqui um conteúdo para o aviso de webcast\.\.\.$/.test(rteDefaultTBodyContent)
      || /^Type the webcast notice content in here\.\.\.$/.test(rteDefaultTBodyContent)
      || /^Digite aqui um conteúdo para o aviso de pauta\.\.\.$/.test(rteDefaultTBodyContent)
      || /^Type the assignment notice content in here\.\.\.$/.test(rteDefaultTBodyContent)
    };

    if (placeholder.value) {
      document.getElementById('rteDefaultTBodyContent').innerText = '';

      setTimeout(() => {

        document.getElementById('rteDefaultTBodyContent').focus()
      }, 100);
    };
  };

  const AttachmentTitleRef = (ev) => {

    setAttachmentTitleCount(ev.value.length)
  }

  const AttachmentAudioRef = (ev) => {

    setAttachmentAudioCount(ev.value.length)
  }

  return (
    <div>
      {loading ? (
        <div id="loadTextBox" />
      ) : redirect ? (
        <Redirect push={true} to={`${listItemsUrl}`} />
      ) : (
        <div className="textBox">
          <form
            id="formReleaseItem"
            onSubmit={handleSubmit(onSubmitClick)}
          >
            <div className="top">
              {/* IDIOMA */}
              <div className="lang">
                <DropDownListComponent
                  id="idioma"
                  name="idioma"
                  cssClass="e-filled"
                  dataSource={releaseIdioma}
                  fields={{ text: "text", value: "value" }}
                  locale={i18n.language}
                  htmlAttributes={{ required: true }}
                  placeholder={t("Press:language")}
                  value={language ? language : 1}
                  change={(e) => setLanguage(e.value)}
                  enabled={formEditable.current}
                />
              </div>
              {/* IDIOMA END */}

              {/* BLOQUEIO ADMIN */}
              {((globalState && globalState.userAdmin) ||
                //ou se o criador é o mesmo que o usuário logado
                (idAccessArea &&
                  userPermission.indexOf("Admin") !==-1) ||
                (idAccessArea &&
                  userPermission.indexOf("Inclusão") !=
                  -1)) && (
                  <CheckBoxComponent
                    id="block"
                    label={t("Press:block_view")}
                    checked={blocked}
                    change={(e) => setBlocked(e.checked)}
                    disabled={!formEditable.current}
                  />
                )}
              {/* BLOQUEIO ADMIN END */}
            </div>

            {/* TÍTULO */}
            <div className="input">
              <label htmlFor="title">
                {t("Press:title")}{" "}
                <span className="e-error">*</span>
              </label>
              <TextBoxComponent
                id="title"
                name="titulo"
                ref={(textBox) => {

                  register(textBox, { required: true });
                  txtTitleRef.current = textBox;
                }}
                placeholder={t("Press:title_placeholder")}
                htmlAttributes={{ maxlength: 255 }}
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                enabled={formEditable.current}
              />
              {errors.titulo &&
                errors.titulo.type === "required" && (
                  <label
                    className="e-error"
                    style={{ display: "block" }}
                  >
                    {t("Validation:required")}
                  </label>
                )}
            </div>
            {/* TÍTULO END */}

            {/* SUBTITULO */}
            <div className="input">
              <label htmlFor="subtitle">
                {t("Press:subtitle")}
              </label>
              <TextBoxComponent
                id="subtitle"
                ref={(textBox) =>
                  (txtSubtitleRef.current = textBox)
                }
                name="subtitulo"
                value={subTitle}
                placeholder={t("Press:subtitle_placeholder")}
                multiline={true}
                onChange={(e) => setSubTitle(e.target.value)}
                htmlAttributes={{ maxlength: 255 }}
                enabled={formEditable.current}
              />
            </div>
            {/* SUBTITULO END */}

            <div className="box">
              <div className="first">
                {/* AREA DE NEGOCIO DDL */}
                <div className="input">
                  <label htmlFor="IdAreaNegocio">
                    {t("Press:area_management")}{" "}
                    <span className="e-error">*</span>
                  </label>
                  <DropDownListComponent
                    id="IdAreaNegocio"
                    name="areaNegocio"
                    ref={register({
                      required: true,
                      min: 1,
                    })}
                    placeholder={t(
                      "Press:area_management_placeholder"
                    )}
                    dataSource={fillBusinessAreaData}
                    fields={{
                      text:
                        i18n.language === "pt"
                          ? "nomePt"
                          : "nomeEn",
                      value: "id",
                    }}
                    value={areaNegocio}
                    change={(e) => setAreaNegocio(e.value)}
                    enabled={formEditable.current}
                  />
                  {errors.areaNegocio &&
                    errors.areaNegocio.type === "min" && (
                      <label
                        className="e-error"
                        style={{ display: "block" }}
                      >
                        {t("Validation:required")}
                      </label>
                    )}
                </div>
                {/* AREA DE NEGOCIO DDL END */}

                {/* PAIS DDL */}
                <div className="input">
                  <label htmlFor="idPais">
                    {t("Press:country")}{" "}
                    <span className="e-error">*</span>
                  </label>
                  <DropDownListComponent
                    id="idPais"
                    name="pais"
                    allowFiltering={true}
                    ref={register({
                      required: true,
                      min: 0,
                    })}
                    placeholder={t(
                      "Press:country_placeholder"
                    )}
                    dataSource={fillCountriesData}
                    fields={{
                      text:
                        i18n.language === "pt"
                          ? "nomePt"
                          : "nomeEn",
                      value: "id",
                    }}
                    value={
                      idPais ? idPais : currentUser.idPais
                    }
                    change={(e) => setPais(e.value)}
                    enabled={formEditable.current}
                  />
                  {errors.pais &&
                    errors.pais.type === "required" && (
                      <label
                        className="e-error"
                        style={{ display: "block" }}
                      >
                        {t("Validation:required")}
                      </label>
                    )}
                </div>
                {/* PAIS DDL END */}

                {/* REGIONAL DDL */}
                <div className="input">
                  <label htmlFor="idRegional">
                    {t("Press:regional")}
                  </label>
                  <DropDownListComponent
                    id="idRegional"
                    placeholder={t(
                      "Press:regional_placeholder"
                    )}
                    dataSource={fillRegionalData}
                    value={idRegional}
                    fields={{
                      text:
                        i18n.language === "pt"
                          ? "nomePt"
                          : "nomeEn",
                      value: "id",
                    }}
                    change={(e) => {

                      setRegional(e.value)
                      getSignatureItemByRegion(
                        globalState.accessToken,
                        e.value
                      ).then((response) => {

                        if (response && response.ok && response.status !== 204) {
                          return response.json();
                        }
                      }).then(signature => {

                        setSignature(signature);
                      })
                    }}
                    enabled={formEditable.current}
                  />
                </div>
                {/* REGIONAL DDL END */}

                {/* DATE PICKER */}
                <div className="input">
                  <label htmlFor="date">
                    {t("Press:date")}{" "}
                    <span className="e-error">*</span>
                  </label>
                  <DatePickerComponent
                    id="date"
                    name="data"
                    ref={(datepicker) => {

                      register(datepicker, {
                        required: true,
                      });
                      datePickerRef.current = datepicker;
                    }}
                    placeholder={t(
                      "Press:date_placeholder"
                    )}
                    value={date.toLocaleDateString(
                      i18n.language
                    )}
                    locale={i18n.language}
                    change={(e) =>
                      setDate(
                        e.value
                          ? new Date(e.value)
                          : new Date()
                      )
                    }
                    enabled={formEditable.current}
                  />
                  {errors.data &&
                    errors.data.type === "required" && (
                      <label
                        className="e-error"
                        style={{ display: "block" }}
                      >
                        {t("Validation:required")}
                      </label>
                    )}
                </div>
                {/* DATE PICKER END */}
              </div>
              <div className="second">
                {/* APROVADOR TXT */}
                <div className="input">
                  <label htmlFor="aprovador">
                    {t("Press:approverBy")}
                  </label>
                  <TextBoxComponent
                    id="aprovador"
                    htmlAttributes={{ maxLength: 100 }}
                    ref={(textBox) =>
                      (txtApproverRef.current = textBox)
                    }
                    placeholder={t(
                      "Press:approverBy_placeholder"
                    )}
                    name="aprovadoPor"
                    value={approver}
                    onChange={(e) =>
                      setApprover(e.target.value)
                    }
                    enabled={formEditable.current}
                  />
                </div>
                {/* APROVADOR TXT END */}

                {/* AUTORES TXT */}
                <div className="input">
                  <label htmlFor="autores">
                    {t("Press:authors")}{" "}
                    <span className="e-error">*</span>
                  </label>
                  <TextBoxComponent
                    id="autores"
                    name="autores"
                    htmlAttributes={{ maxLength: 200 }}
                    ref={(textBox) => {

                      register(textBox, {
                        required: true,
                      });
                      txtAuthorRef.current = textBox;
                    }}
                    cssClass="e-filled"
                    placeholder={t(
                      "Press:authors_placeholder"
                    )}
                    value={
                      authors ? authors : currentUser.nome
                    }
                    onChange={(e) =>
                      setAuthors(e.target.value)
                    }
                    enabled={formEditable.current}
                  />
                  {errors.autores &&
                    errors.autores.type === "required" && (
                      <label
                        className="e-error"
                        style={{ display: "block" }}
                      >
                        {t("Validation:required")}
                      </label>
                    )}
                </div>
                {/* AUTORES TXT END */}
              </div>
            </div>

            {/* WYSIWYG EDITOR */}
            <div
              className="boxRichText"
              ref={rteSectionRef}
              id="rteSection"
            >
              {/* EDITOR RT */}
              <RichTextEditorComponent
                id="release"
                ref={(scope) => {

                  rteObj.current = scope;
                }}
                toolbarSettings={toolbarSettings}
                format={formatOptions}
                beforeQuickToolbarOpen={
                  rteBeforeQuickToolbarOpen
                }
                beforeDialogClose={rteBeforeDialogClose}
                beforeQuickToolbarOpen={
                  rteBeforeQuickToolbarOpen
                }
                actionBegin={rteActionBegin}
                locale={i18n.language}
                insertImageSettings={insertImageSettings}
                imageUploading={imageUploadingHandler}
                created={rteCreated}
                format={format}
                enabled={formEditable.current}
                enableHtmlSanitizer={rteEnableHtmlSanitizer}
                focus={editPlaceholder}
              >
                <Inject
                  services={[
                    HtmlEditor,
                    Toolbar,
                    Image,
                    Link,
                    QuickToolbar,
                  ]}
                />
              </RichTextEditorComponent>
              {/* EDITOR RT END */}

              {/* BTN DDL VALE */}
              <DropDownButtonComponent
                id="btnVale"
                items={itemsVale}
                select={(args) => selectedTemplate(args)}
                beforeOpen={(args) =>
                  selectionBeforeDropdownClick()
                }
              >
                <span>{t("Release:insert")}</span>
              </DropDownButtonComponent>
              {/* BTN DDL VALE END */}

              {/* BTN RESET */}
              <ButtonComponent
                id="btnReset"
                cssClass="e-tbar-btn e-flat"
                onClick={resetButtonEvent}
              >
                <span className="mdl2 mdl2-reset" />
              </ButtonComponent>
              {/* BTN RESET */}

              {/* VIDEO DIALOG */}
              <DialogComponent
                id="customTbarDlg"
                ref={(scope) => {

                  dialogObj.current = scope;
                }}
                overlayClick={dialogOverlay}
                close={dialogOverlay}
                visible={false}
                showCloseIcon={true}
                width="45%"
                target={"#rteSection"}
                isModal={true}
                style={{ minHeight: 350 }}
              ></DialogComponent>
              {/* VIDEO DIALOG END */}

              <div
                id="customTbarDialog"
                style={{ display: "none" }}
              >
                <div
                  id="rteSpecial_char"
                  ref={rteFileUploadRef}
                >
                  {/* THUMBNAIL SELECTION */}
                  <div>
                    <RenderVideoTag
                      videoSrc={videoSrc}
                      showThumb={showThumb}
                    />
                  </div>
                  {/* THUMBNAIL SELECTION END */}

                  <div
                    className="control-pane"
                    ref={videoDropContainerRef}
                  >
                    <div className="control-section uploadpreview">
                      <div style={{ width: "100%" }}>
                        <div
                          className="upload_wrapper"
                          style={{ width: "100%" }}
                        >
                          {/* Render Uploader */}
                          <div
                            className="fileUpload"
                            id="uploadVideo"
                          >
                            <div className="new">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: UploadIcon,
                                }}
                              />
                              <p>
                                {t("Press:drag_here_or")}
                                <span
                                  onClick={() =>
                                    uploadImage()
                                  }
                                >
                                  {" " + t("Press:upload")}
                                </span>
                              </p>
                              <span
                                id="messageFileSize"
                                className="fileInfo"
                              ></span>
                              <span
                                id="messageFileExt"
                                className="fileInfo"
                              ></span>
                              <span className="e-error"></span>
                            </div>
                          </div>
                          <UploaderComponent
                            id="fileUpload"
                            name="fileUpload"
                            type="file"
                            dropArea={target}
                            multiple={false}
                            ref={(scope) => {

                              uploadObj.current =
                                scope;
                            }}
                            asyncSettings={
                              asyncSettings
                            }
                            created={
                              uploaderCreated
                            }
                            selected={
                              uploaderSelected
                            }
                            uploading={
                              fileUploading
                            }
                            success={(e) =>
                              onFileUploadSuccess(
                                e
                              )
                            }
                            removing={(e) =>
                              onRemoveFile(e)
                            }
                          ></UploaderComponent>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ATTACHMENT SUBTITLE */}
                  <div
                    className="input"
                    id={"attachmentLabelWrapper"}
                    style={{ display: "none" }}
                  >
                    <div id="BoxInputCount">
                      <label htmlFor="attachmentLabel">
                        {t("Press:attachment_subtitle")}
                      </label>
                      <TextBoxComponent
                        id="attachmentLabel"
                        ref={(textBox) =>
                          (txtAttachmentTitleRef.current = textBox)
                        }
                        input={(ev) => AttachmentTitleRef(ev)}
                        name="attachmentLabel"
                        placeholder={t(
                          "Press:attachment_subtitle_placeholder"
                        )}
                        htmlAttributes={{ maxlength: 17 }}
                      />
                      <div id="InputCount">
                        {((attachmentTitleCount
                          ? 0 + attachmentTitleCount: txtAttachmentTitleRef?.current?.length
                            ? 0 + txtAttachmentTitleRef.current.length : 0) + '/17')
                        }
                      </div>
                    </div>
                  </div>
                  {/* ATTACHMENT SUBTITLE END */}
                  {/* AUDIO SUBTITLE */}
                  <div
                    className="input"
                    id={"audioLabelWrapper"}
                    style={{ display: "none" }}
                  >
                    <div id="BoxInputCount">
                      <label
                        htmlFor="audioLabel"
                      >
                        {t("Press:audio_subtitle")}
                      </label>
                      <TextBoxComponent
                        id="audioLabel"
                        ref={(textBox) =>
                        (txtAudioRef.current =
                          textBox)
                        }
                        input={(ev) => AttachmentAudioRef(ev)}
                        name="audioLabel"
                        placeholder={t(
                          "Press:audio_subtitle_placeholder"
                        )}
                        htmlAttributes={{ maxlength: 17 }}
                      />
                      <div id="InputCount">
                        {((attachmentAudioCount
                          ? 0 + attachmentAudioCount : txtAudioRef?.current?.length
                            ? 0 + txtAudioRef.current.length : 0) + '/17')
                        }
                      </div>
                    </div>
                  </div>
                  {/* AUDIO SUBTITLE END */}
                  {/* YOUTUBE VIDEO */}
                  <DialogComponent id='customTbarDlg'
                      ref={(scope) => { dialogObjYtb.current = scope }}
                      overlayClick={dialogOverlay}
                      close={dialogOverlay}
                      visible={false}
                      showCloseIcon={true}
                      width='45%'
                      target={'#rteSection'}
                      isModal={true}
                      style={{ minHeight: 150 }}>
                  </DialogComponent>
                  {/* YOUTUBE TXT END */}
                  <div id="customTbarDialogYtb" style={{ display: 'none' }}>
                      <div id="rteSpecial_char" ref={rteFileUploadRefYtb} >
                          <div className="input">
                              <label htmlFor="youtubeLabel">{t('Press:youtubeVideo_subtitle')}</label>
                              <TextBoxComponent
                                  id="youtubeLabel"
                                  htmlAttributes={{ maxLength: 200 }}
                                  placeholder={t('Press:youtubeVideo_subtitle_placeholder')}
                                  name="youtubeLabel"
                              />
                          </div>
                          <span id="erroYtb" className="e-error"></span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            {/* WYSIWYG EDITOR END */}
          </form>

          <SidebarComponent
            id="sidebar"
            ref={(sidebar) => (sidebarRef.current = sidebar)}
            style={{ visibility: "hidden" }}
            created={sidebarCreated}
            type="Push"
            position="Right"
            open={sidebarOpen}
            closeOnDocumentClick={true}
          >
            <div className="sidebar-content">
              <GridComponent
                id="gridMedia"
                ref={(gridComponent) =>
                  (gridMediaRef.current = gridComponent)
                }
                contextMenuItems={contextMenuItems}
                dataSource={[]}
                filterSettings={{ type: "CheckBox" }}
                allowPaging={true}
                allowSorting={true}
                locale={i18n.language}
                allowFiltering={true}
                allowResizing={true}
                pageSettings={{ pageSize: 6 }}
                created={gridCreated}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    id=""
                    fields="url"
                    headerText={t("Release:media")}
                    textAlign="Center"
                    width="250"
                    template={renderMediaTemplate}
                  ></ColumnDirective>
                  <ColumnDirective
                    field="type"
                    headerText={t("Release:type")}
                    textAlign="Center"
                    width="60"
                  />
                  <ColumnDirective
                    template={removeMediaTemplate}
                    width="60"
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Resize,
                    Sort,
                    ContextMenu,
                    Filter,
                    Page,
                  ]}
                />
              </GridComponent>
            </div>
          </SidebarComponent>
        </div>
      )}
      <DialogComponent
        id="deleteModal"
        isModal={true}
        visible={openModal}
        locale={i18n.language}
      >
        <div>
          <div className="divTrash complete">
            <label className="mdl2 mdl2-confirm"></label>
            <div className="phraseDelete">
              <label className="confirm" id="confirm">
                {t("Press:reset_template")}
              </label>
              <label id="labelDelete">
                {t("Press:reset_template_desc")}
              </label>
            </div>
          </div>
          <hr />
          {!openModalConfirm ? (
            <div className="footerButtons">
              <button onClick={() => setOpenModal(false)}>
                {t("Actions:cancel")}
              </button>
              <button onClick={defaultRteTemplate}>
                {t("Actions:confirm")}
              </button>
            </div>
          ) : (
            <div className="deleteComplete complete">
              <div className="confirmTrash">
                <span className="success mdl2 mdl2-confirm"></span>
              </div>
              <label>{t("Press:reset_confirm")}</label>
              <button
                onClick={() => {

                  execUpdateRteHeaderTemplate();
                  setOpenModal(false);
                  setOpenModalConfirm(false);
                }}
              >
                {t("Actions:exit")}
              </button>
            </div>
          )}
        </div>
      </DialogComponent>
    </div>
  );
};
