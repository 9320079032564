import { requestAPI } from '../components/utilities/Utils';

export async function getSupplierTypes(token) {
    return requestAPI("_api/System/GetSupplierTypes", "GET", "", null, token, false);
}

export async function getDICIAreas(token) {
    return requestAPI("_api/System/GetDICIAreas", "GET", "", null, token, false);
}

export async function getAccessAreas(token) {
    return requestAPI("_api/System/GetAccessAreas", "GET", "", null, token, false);
}

export async function getCountries(token) {
    return requestAPI("_api/System/GetCountries", "GET", "", null, token, false);
}

export async function getPermissions(token) {
    return requestAPI("_api/System/GetPermissions", "GET", "", null, token, false);
}

export async function getProfileAreas(token, lang) {
    const qstring = `?$orderby=${lang === 'pt' ? 'nomePt' : 'nomeEn'}`;
    return requestAPI("_api/System/GetProfileAreas", "GET", qstring, null, token, false);
}

export async function getProfileDepartments(token, lang) {
    const qstring = `?$orderby=${lang === 'pt' ? 'nomePt' : 'nomeEn'}`;
    return requestAPI("_api/System/GetProfileDepartments", "GET", qstring, null, token, false);
}

export async function getProfileManagement(token, lang) {
    const qstring = `?$orderby=${lang === 'pt' ? 'nomePt' : 'nomeEn'}`;
    return requestAPI("_api/System/GetProfileManagement", "GET", qstring, null, token, false);
}

export async function getRegional(token) {
    return requestAPI("_api/System/GetRegional", "GET", "", null, token, false);
}

export async function getSupplierDescriptions(token) {
    return requestAPI("_api/System/GetSupplierDescriptions", "GET", "", null, token, false);
}

export async function getUserTypes(token) {
    return requestAPI("_api/System/GetUserTypes", "GET", "", null, token, false);
}

export async function getBusinessAreas(token) {
    return requestAPI("_api/System/GetBusinessAreas", "GET", "", null, token, false);
}

export async function getAllBusinessAreas(token) {
  return requestAPI("_api/System/GetAllBusinessAreas", "GET", "", null, token, false);
}

export async function getMailingStatus(token) {
    return requestAPI("_api/System/GetMailingStatus", "GET", "", null, token, false);
}

export async function getReportMailingStatus(token) {
    return requestAPI("_api/System/GetReportMailingStatus", "GET", "", null, token, false);
}

export async function getMediaTypes(token) {
    return requestAPI("_api/System/GetMediaTypes", "GET", "", null, token, false);
}

export async function getPressMediaTypes(token) {
    return requestAPI("_api/System/GetPressMediaTypes", "GET", "", null, token, false);
}

export async function getStates(token) {
    return requestAPI("_api/System/GetStates", "GET", "", null, token, false);
}

export async function getCities(token) {
    return requestAPI("_api/System/GetCities", "GET", "", null, token, false);
}

export async function getLiaisonActionTypes(token) {
    return requestAPI("_api/System/GetLiaisonActionTypes", "GET", "", null, token, false);
}

export async function getLiaisonStatus(token) {
    return requestAPI("_api/System/GetLiaisonStatus", "GET", "", null, token, false);
}

export async function getCategories(token) {
    return requestAPI("_api/System/GetCoreVideoCategories", "GET", "", null, token, false);
}

export async function getSocialMedias(token) {
    return requestAPI("_api/System/GetSocialMedias", "GET", "", null, token, false);
}

export async function getStatus(token) {
    return requestAPI("_api/System/GetCoreVideoStatus", "GET", "", null, token, false);
}
