import { requestAPI } from '../components/utilities/Utils';

export class LiaisonReportFilter {
    constructor(vehicle, responsibles, businessArea, startPeriod, endPeriod, status, country, regional) {
        this.vehicle = vehicle;
        this.responsibles = responsibles;
        this.businessArea = businessArea;
        this.startPeriod = startPeriod;
        this.endPeriod = endPeriod;
        this.status = status;
        this.country = country;
        this.regional = regional;
    }
}

export class LiaisonTO {
    constructor(id,
                jornalista,
                idTipoMidia,
                nomeVeiculo,
                email,
                telefone,
                mailingContato,
                idStatus,
                dataConclusao,
                descricao,
                tipoAcao,
                idAreaNegocio,
                responsaveis,
                idPais,
                idRegional,
                dataRecebimento,
                deadLine,
                anexos,
                inserirMailing,
                autorRegistro,
                historico) {
        this.id = id ? id : null;
        this.jornalista = jornalista ? jornalista : null;
        this.idTipoMidia = idTipoMidia ? idTipoMidia : null;
        this.nomeVeiculo = nomeVeiculo ? nomeVeiculo : null;
        this.email = email ? email : null;
        this.telefone = telefone ? telefone : null;
        this.mailingContato = mailingContato ? mailingContato : null;
        this.idStatus = idStatus ? idStatus : null;
        this.dataConclusao = dataConclusao ? dataConclusao : null;
        this.descricao = descricao ? descricao : null;
        this.tipoAcao = tipoAcao ? tipoAcao : null;
        this.idAreaNegocio = idAreaNegocio ? idAreaNegocio : null;
        this.responsaveis = responsaveis ? responsaveis : null;
        this.idPais = idPais ? idPais : null;
        this.idRegional = idRegional ? idRegional : null;
        this.dataRecebimento = dataRecebimento ? dataRecebimento : null;
        this.deadLine = deadLine ? deadLine : null;
        this.anexos = anexos ? anexos : null;
        this.inserirMailing = inserirMailing ? inserirMailing : null;
        this.autorRegistro = autorRegistro ? autorRegistro : null;
        this.historico = historico ? historico : null;
    }
}

export class MailingContactTO {
    constructor(id, jornalista, idtipoMidia, nomeVeiculo, email, telefone, idpais, idestado, idcidade, editoria, cargo, endereco, grupos) {
        this.id = id ? id : null;
        this.jornalista = jornalista ? jornalista : null;
        this.idtipoMidia = idtipoMidia ? idtipoMidia : null;
        this.nomeVeiculo = nomeVeiculo ? nomeVeiculo : null;
        this.email = email ? email : null;
        this.telefone = telefone ? telefone : null;
        this.idpais = idpais ? idpais : null;
        this.idestado = idestado ? idestado : null;
        this.idcidade = idcidade ? idcidade : null;
        this.editoria = editoria ? editoria : null;
        this.cargo = cargo ? cargo : null;
        this.endereco = endereco ? endereco : null;
        this.grupos = grupos ? grupos : null;
    }
}

export class LiaisonAttachment {
    constructor(id, contentType, fileName, fileB64, fileExtension, fileLength, status) {
        this.id = id ? id : null;
        this.contentType = contentType ? contentType : null;
        this.fileName = fileName ? fileName : null;
        this.fileB64 = fileB64 ? fileB64 : null;
        this.fileExtension = fileExtension ? fileExtension : null;
        this.fileLength = fileLength ? fileLength : null;
        this.status = status ? status : 3;
    }
}

export class LiaisonHistory {
    constructor(liaisonId, userName, actionType, date) {
        this.liaisonId = liaisonId ? liaisonId : null;
        this.userName = userName ? userName : null;
        this.actionType = actionType ? actionType : null;
        this.date = date ? date : null;
    }
}

export async function getLiaisonReportItems(token, filters) {
  let fullFilterArray = [];
  let fullFilter = '';

    if(filters.vehicle) fullFilterArray.push(`contains(nomeVeiculo,'${filters.vehicle.trim()}')`);
    if(filters.responsibles) fullFilterArray.push(`contains(responsaveis,'${filters.responsibles.trim()}')`);
    if(filters.businessArea) fullFilterArray.push(`AreaNegocio/Id eq ${filters.businessArea}`);
    if(filters.startPeriod) {
        let startDay = ("0" + filters.startPeriod.getDate()).slice(-2);
        let startMonth = ("0" + (filters.startPeriod.getMonth() + 1)).slice(-2);
        let startPeriodDtStr = `${filters.startPeriod.getFullYear()}-${startMonth}-${startDay}T00:00:00.000Z`;
        fullFilterArray.push(`dataRecebimento gt ${startPeriodDtStr}`);
    }
    if(filters.endPeriod) {
        let endDay = ("0" + filters.endPeriod.getDate()).slice(-2);
        let endMonth = ("0" + (filters.endPeriod.getMonth() + 1)).slice(-2);
        let endPeriodDtStr = `${filters.endPeriod.getFullYear()}-${endMonth}-${endDay}T23:59:59.999Z`;
        fullFilterArray.push(`dataRecebimento lt ${endPeriodDtStr}`);
    }
    if(filters.status) fullFilterArray.push(`idStatus eq ${filters.status}`);
    if(filters.country) fullFilterArray.push(`idPais eq ${filters.country}`);
    if(filters.regional) fullFilterArray.push(`idRegional eq ${filters.regional}`);

    if(fullFilterArray.length > 0) {
        fullFilter = `filter(${fullFilterArray.join(' and ')})/`;
    }

    var withFilters = `?$apply=${fullFilter}groupby((AreaNegocio/Id,AreaNegocio/NomePt,AreaNegocio/NomeEn), aggregate($count as QuantidadeAtendimentos))`;

    return requestAPI("_api/Liaison/GetLiaisonItems", "GET", withFilters, null, token, null);
}

export async function exportLiaisonReport(token, body) {
  const raw = JSON.stringify(body);
  return requestAPI("_api/Liaison/ExportLiaisonReport", "POST", '', raw, token, null);
}

export async function getLiaisonItems(token) {
    const qString = "?$orderby=id desc"
    return requestAPI("_api/Liaison/GetLiaisonItems", "GET", qString, null, token, null);
}

export async function addLiaisonItem(token, body) {
    return requestAPI("_api/Liaison/AddLiaisonItem", "POST", '', body, token, null);
}

export async function updateLiaisonItem(token, body) {
    return requestAPI("_api/Liaison/UpdateLiaisonItem", "POST", '', body, token, null);
}

export async function duplicateLiaisonItem(token, body) {
  return requestAPI("_api/Liaison/DuplicateLiaisonItem", "POST", '', body, token, null);
}

export async function getLiaisonItem(token, IdLiaison) {
    return requestAPI("_api/Liaison/GetLiaisonItem", "GET", `?IdLiaison=${IdLiaison}`, null, token, null);
}

export async function downloadLiaisonAttachment(token, IdLiaison, fileName) {
    return requestAPI("_api/Liaison/DownloadLiaisonAttachment", "GET", `?IdLiaison=${IdLiaison}&fileName=${fileName}`, null, token, null, true);
}

export async function deleteLiaisonItem(token, IdLiaison) {
    return requestAPI("_api/Liaison/DeleteLiaisonItem", "POST", `?IdLiaison=${IdLiaison}`, null, token, null);
}
