import { ODataV4Adaptor  } from '@syncfusion/ej2-data';

export class SerialNoAdaptor extends ODataV4Adaptor {
    processResponse() {
        const original = super.processResponse.apply(this, arguments);
        if (original.result && original.count) {
            return { result: original.result, count: original.count };
        } else {
            return original;
        }
    }
}