import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from "../general/GlobalState";
import { MailingContactsContext } from "../generalMailing/contacts/MailingContactsContext";
import { getMailingGroupItemsGeneral } from "../../services/MailingGeneralSVC";
import { ReleaseStateContext } from "../releases/ReleaseState";
import { MultiSelectComponent, CheckBoxSelection, Inject } from "@syncfusion/ej2-react-dropdowns";
import '../mailing/contacts/contacts.css';

export const GroupsBoxGeneral = (props) => {
    let multiSelectGroups;
    const [globalState] = React.useContext(GlobalStateContext);
    const context = React.useContext(props.type ? ReleaseStateContext : MailingContactsContext);
    const [groups, setGroups] = React.useState(null);
    const { t } = useTranslation();

    React.useEffect(() => {
        let qString = '';
        if (props.type) {
            context.setGroupsSelected([]);
            qString = '?$filter=quantidadeMembros gt 0'
        }
        getMailingGroupItemsGeneral(globalState.accessToken, qString).then(response => {
            if (response.ok) {
                response.json().then(result => {
                    setGroups(result);
                });
            }
        });
    }, []);

    React.useEffect(() => {
        if (context.generalGroupsFilter && groups) {
            const groupsFiltered = groups.filter(item => (!context.generalGroupsFilter.idpais || item.idpais === context.generalGroupsFilter.idpais) &&
                (!context.generalGroupsFilter.idregional || item.idregional === context.generalGroupsFilter.idregional));
            multiSelectGroups.dataSource = groupsFiltered;

        }
    }, [context.generalGroupsFilter]);

    // React.useEffect(() => {
    //     console.log(context);
    // }, [context]);

    const multiSelectChange = e => {
        context.setGeneralGroupsSelected(e.value);
    }

    return (
        <div className="content d-flex flex-column">
            {groups &&
                <MultiSelectComponent
                    id="groups"
                    ref={multiSelect => multiSelectGroups = multiSelect}
                    cssClass="e-filled"
                    dataSource={groups}
                    fields={{ value: "id", text: "nome" }}
                    placeholder={t("Contact:groups_placeholder")}
                    mode="Box"
                    change={multiSelectChange}
                    value={context.groupsSelected}
                >
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            }
        </div>
    )
}
