import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN from "./resources/enus.json";
import PT from "./resources/ptbr.json";

const cache = localStorage.getItem("NovoCGCUserCache") && JSON.parse(localStorage.getItem("NovoCGCUserCache")) ;
const initLng = cache && cache.userLanguage ? String(cache.userLanguage) :
    String(navigator.userLanguage).indexOf("pt") !== -1 || String(navigator.language).indexOf("pt") !== -1 ? "pt" : "en";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: EN,
    pt: PT
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        //lng: "pt",
        lng: initLng,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;