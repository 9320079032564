import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, ColumnSeries } from '@syncfusion/ej2-react-charts';
import { Export } from "../utilities/Export";
import { GlobalStateContext } from "../general/GlobalState";
import { PressReportStateContext } from "./PressReportState";
import { exportPressReport } from "../../services/PressSVC";

export const ReportComponent = ({idTipo}) => {

  const { t } = useTranslation();
  const [globalState] = React.useContext(GlobalStateContext);
  const [pressReportState] = React.useContext(PressReportStateContext);
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [dateRangeLabel, setDateRangeLabel] = React.useState('');
  const [newData, setNewData] = React.useState([]);

  React.useEffect(() => {
    if (pressReportState && pressReportState.data.length > 0) {

      let seriesData = pressReportState.data[0].pressReport.map(i => {

        return {
          AreaNegocio: i.title,
          QuantidadeAtendimentos: i.quantity
        };
      })

      let newSeriesData = {
        IdAreaNegocio: pressReportState?.allData?.businessArea,
        language: i18n.language,
        DataInicio: pressReportState?.allData?.startPeriod,
        DataFim: pressReportState?.allData?.endPeriod,
        IdatendimentoStatus: pressReportState?.allData?.status,
        IdPais: pressReportState?.allData?.country,
        IdRegional: pressReportState?.allData?.regional,
        idTipo: idTipo,
        isPT: i18n.language === "pt" ? true : false,
        Bloqueado: pressReportState?.allData?.status
      };

      setData(seriesData);
      setNewData(newSeriesData);

      let sum = 0;
      pressReportState.data[0].pressReport.map(i => {
        sum += i.quantity;
      })
      setTotal(sum);
    }

  }, [pressReportState.data, globalState.userLanguage]);

  React.useEffect(() => {
    if (pressReportState &&
      pressReportState?.allData.length > 0) {

      if (pressReportState?.allData?.startPeriod && pressReportState?.allData?.endPeriod) {
        let rangeDate;

        if (globalState.userLanguage === "en") {
          rangeDate = `From ${pressReportState?.allData?.startPeriod.toLocaleDateString("en")} to ${pressReportState?.allData?.endPeriod.toLocaleDateString("en")}`;
        } else {
          rangeDate = `De ${pressReportState?.allData?.startPeriod.toLocaleDateString("pt")} até ${pressReportState?.allData?.endPeriod.toLocaleDateString("pt")}`;
        }

        setDateRangeLabel(rangeDate);
      } else {
        setDateRangeLabel(null);
      }
    }
  }, [pressReportState?.allData?.startPeriod, pressReportState?.allData?.endPeriod, globalState.userLanguage]);

  const legendSettings = {
    visible: true,
    position: 'Custom',
    location: { x: 450, y: 20 },
    textStyle: {
      color: '#747876',
      fontFamily: 'Arial'
    }
  };

  const exportReport = (args) => {
    const extension = () => {
      let exportDoc = {
        "doc": ".docx",
        "excel": ".xlsx"
      }
      return exportDoc[args];
    }

    const dataExport = {...newData, extension: extension()}

    exportPressReport(globalState.accessToken, dataExport, idTipo).then(async (res) => {
      const blob = await res.blob();
      const newBlob = new Blob([blob], { type: args === 'doc'
        ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      let url = window.URL.createObjectURL(newBlob);
      let a = document.createElement('a');
      a.download = `export${extension()}`;
      a.href = url;
      a.click();
      a.remove();
    }).catch(console.error);
  }

  const exportPDF = () => {
    window.print();
  }

  const _export = {
    pdf: () => exportPDF(),
    doc: () => exportReport('doc'),
    excel: () => exportReport('excel')
  }

  const verifyTypeId = () => {
    let type = {
      1:'PressReport:description-release',
      2:'PressReport:description-statement',
      3:'PressReport:description-position',
    }
    return type[idTipo];
  }

  return (
    <>
      {
        data &&
        data.length > 0 &&
        <div id="reportComponent">
          <div>
            <div className="reportTopLabel">{t('PressReport:topLabel')}</div>
            <div className="dateDiv">
              {
                dateRangeLabel &&
                <div>
                  <span className="mdl2 mdl2-calendar" />
                  <label>{dateRangeLabel}</label>
                </div>
              }
              <p>{t(`${verifyTypeId()}`) }</p>
            </div>
            <ChartComponent
              id="graph"
              width="590"
              height="auto"
              legendSettings={legendSettings}
              background="#F7F9F9"
              primaryXAxis={{
                valueType: 'Category',
                title: '',
                majorTickLines: { width: 0 },
                majorGridLines: { width: 0 },
                labelStyle: { color: '#747876', fontFamily: 'Arial', size: 10, textAlignment: 'End' },
                labelIntersectAction: 'Wrap',
              }}
              primaryYAxis={{
                majorTickLines: { width: 0 },
                majorGridLines: { width: 0 }
              }}
              axisLine={{ color: 'blue' }}
              locale={i18n.language}
              tooltip={{ enable: true }}
            >
              <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, Category]} />
              <SeriesCollectionDirective>
                <SeriesDirective fill='#EDB111' name={i18n.language === "pt" ? "Quantidade" : "Amount"} dataSource={data} xName='AreaNegocio' yName='QuantidadeAtendimentos' type='Column' columnWidth="0.4" />
              </SeriesCollectionDirective>
            </ChartComponent>
          </div>
          <div id="rightTable">
            <div className="header">
              <label>{t('PressReport:tableHeaderBusinessArea')}</label>
              <label>{t('PressReport:tableHeaderLiaisons')}</label>
            </div>
            {data &&
              data.length > 0 &&
              data.map((i, index) => {
                return (
                  <div key={index} className="dataRightTable">
                    <label>{i.AreaNegocio}</label>
                    <label>{i.QuantidadeAtendimentos}</label>
                  </div>
                );
              })
            }
            <div className="header">
              <label>Total</label>
              <label>{total}</label>
            </div>
            <Export controls={_export} doc='mdl2-word' id='exportReport' />
          </div>
        </div>
      }
    </>
  );
}
