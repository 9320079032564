import React from 'react';
import './MessageModal.css';
import i18n from 'i18next';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { useModal } from '../../../hooks/Modal';

function MessageModal(props) {
    const [show, setshow] = React.useState();

    const { modalMessageParams, setOpenModal, setModalMessageParams } = useModal();

    React.useEffect(() => {
        if (modalMessageParams)
            setshow(modalMessageParams.show);
    }, [modalMessageParams]);

    return show ? (
        <DialogComponent
            id="messageModal"
            isModal={true}
            visible={show}
            style={{ maxHeight: '231px !important' }}
            locale={i18n.language}
        >
            <div>
                <div className={`divTrash ${modalMessageParams && modalMessageParams.error && 'red'}`}>
                    <span
                        className={`${modalMessageParams && modalMessageParams.error ? 'error' : 'success'
                            } mdl2 ${modalMessageParams && modalMessageParams.icon}`}
                    ></span>
                    <div className="phraseModal">
                        <label
                            id="title"
                            className={`${modalMessageParams && modalMessageParams.error && 'red'}`}
                        >
                            {i18n.language ==="pt" ? modalMessageParams && modalMessageParams.title.titlePt : modalMessageParams && modalMessageParams.title.titleEn}
                        </label>
                    </div>
                </div>
                <hr />
                <div className="messageComplete">
                    <div className={`confirmTrash ${modalMessageParams && modalMessageParams.error && 'red'}`}>
                        <span
                            className={`${modalMessageParams && modalMessageParams.error ? 'error' : 'success'
                                } mdl2 ${modalMessageParams && modalMessageParams.icon}`}
                        ></span>
                    </div>
                    <label className={`${modalMessageParams && modalMessageParams.error && 'red'}`}>
                        {i18n.language ==="pt" ? modalMessageParams && modalMessageParams.message.messagePt : modalMessageParams && modalMessageParams.message.messageEn}
                    </label>
                    <button
                        onClick={() => {
                            setshow(false);
                            setModalMessageParams({ ...modalMessageParams, show: false });
                        }}
                    >
                        {i18n.language ==='pt' ? 'Sair' : 'Exit'}
                    </button>
                </div>
            </div>
        </DialogComponent>
    ) : null;
}

export default MessageModal;
