import React, { useContext, useEffect, useState } from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ComboBoxComponent, AutoCompleteComponent, MultiSelectComponent, CheckBoxSelection, DropDownTreeComponent, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, ForeignKey, Filter, Group, Inject, Sort, ContextMenu, Page, Resize, Edit, ExcelExport, PdfExport } from '@syncfusion/ej2-react-grids';
import { getWhoisWhoUsersFull } from "../../services/UsersSVC";
import { GlobalStateContext } from "../general/GlobalState";
import { Link } from 'react-router-dom';

export const WhoIsWhoContent = ({ refWhoisWho, setRefWhoisWho, gridInstance }) => {

    const [globalState] = useContext(GlobalStateContext);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        async function getUsers() {
            const users = await getWhoisWhoUsersFull(globalState.accessToken).then(res => {
                if (res.ok)
                    return res.json();
            })

            setUsers(users);
        }
        getUsers();
    }, []);

    React.useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language]);

    const { t } = useTranslation();

    useEffect(() => {
        if (refWhoisWho) {
            refWhoisWho.refresh();
        }
    }, [i18n.language]);

    const nameTemplate = (args) => {
        return (
            <div className="nameTemplateDiv">
                <Link style={{
                    display: "flex",
                    alignItems: "center"
                }} to={`/profile/${args.id}`}>
                    <img src={`${process.env.REACT_APP_API_URL}​​/_api/Users/GetUserPhoto?token=${globalState.tokenLightWeight}&userId=${args.id}&sizeType=62`} alt="" />
                    <label>{args.nome}</label>
                </Link>
            </div >
        );
    }

    const phoneTemplate = (args) => {
        return (
            <div className="phoneTemplateDiv">
                {args && args.telefone && args.telefone.length > 0 &&
                    args.telefone.map(currentPhone => (
                        <span key={currentPhone}>{currentPhone}</span>
                    ))
                }
            </div>
        )
    }

    const superiorTemplate = (args) => {
        return (
            <div className="superiorTemplateDiv">
                <Link to={`/profile/${args && args.superiorDireto && args.superiorDireto.id}`}>
                    {args && args.superiorDireto && <img src={`${process.env.REACT_APP_API_URL}​​/_api/Users/GetUserPhoto?token=${globalState.tokenLightWeight}&userId=${args.superiorDireto.id}&sizeType=62`} alt="" />}
                    {args && args.superiorDireto && <label>{args.superiorDireto.nome}</label>}
                </Link>
            </div>
        );
    }

    return (
        <>
            <div id="WhoIsWhoContent" className="caixaArrastar">
                <GridComponent
                    id="whoiswhoGrid"
                    dataSource={users}
                    allowPaging={true}
                    allowSorting={true}
                    allowGrouping={true}
                    allowFiltering={true}
                    allowResizing={true}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    filterSettings={{ type: 'CheckBox' }}
                    locale={i18n.language}
                    ref={grid => setRefWhoisWho(grid)}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='nome' template={(args) => nameTemplate(args)} headerText={t('WhoisWho:column_name')} width='140' />
                        <ColumnDirective field='superiorDireto.nome' template={(args) => superiorTemplate(args)} headerText={t('WhoisWho:column_superior')} width='140' />
                        <ColumnDirective field={i18n.language ==="pt" ? "departamento.nomePt" : "departamento.nomeEn"} headerText={t('WhoisWho:column_department')} width='95' />
                        <ColumnDirective field={i18n.language ==="pt" ? "gerencia.nomePt" : "gerencia.nomeEn"} headerText={t('WhoisWho:column_office')} width='95' />
                        <ColumnDirective field={i18n.language ==="pt" ? "area.nomePt" : "area.nomeEn"} headerText={t('WhoisWho:column_area')} width='95' />
                        <ColumnDirective field='cargo' headerText={t('WhoisWho:column_management')} width='95' />
                        <ColumnDirective field={i18n.language ==="pt" ? "pais.nomePt" : "pais.nomeEn"} headerText={t('WhoisWho:column_country')} width='95' />
                        <ColumnDirective field={i18n.language ==="pt" ? "regional.nomePt" : "regional.nomeEn"} headerText="Regional" width='95' />
                        <ColumnDirective field='email' headerText="E-mail" width='95' />
                        {/* <ColumnDirective field='telefone' template={phoneTemplate} headerText={t('WhoisWho:column_phone')} width='95' /> */}
                    </ColumnsDirective>
                    <Inject services={[Page, Group, Sort, Resize, Filter, ExcelExport, PdfExport]} />
                </GridComponent>
            </div>
        </>
    )
}