import * as React from "react";
import { Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState'
import { useTranslation } from 'react-i18next';
import { Actions } from "../utilities/Actions";
import '../profile/profile.css';
import { getMyProfile, updateMyProfile } from "../../services/UsersSVC";
import { SuppliersLists } from "../suppliers/SuppliersLists";
import { Buttons } from "../utilities/Buttons";
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";

export const Suppliers = () => {

    const [globalState] = React.useContext(GlobalStateContext);
    const [redirect, setRedirect] = React.useState(false);
    const [idItem, setIdItem] = React.useState(null);
    const { t } = useTranslation();

    const _controls = {
        export: "",
        import: "",
        new: () => setRedirect(true),
        back: "",
    };


    let editSuppliers = (id) => {
        setIdItem(id);
    }

    React.useEffect(() => {
        if (idItem) {
            setRedirect(true);
        }
    }, [idItem]);

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> : (
                redirect ? (
                    idItem === null ?
                        <Redirect push={true} to='/admin/SuppliersItem' />
                        :
                        <Redirect push={true} to={`/admin/SuppliersItem/${idItem}`} />
                ) : (
                    <>
                        <PermissionRedirect id="53" />
                        <div className="DefaultTop" />
                        <Container className="containerTop">
                            <div className="topTitleButtons">
                                <Row>
                                    <div className="col-sm-8 col-12">
                                        <div className="title">
                                            <h1>{t('Suppliers:title')}</h1>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-12">
                                        <div className="buttons">
                                            <Buttons controls={_controls} />
                                        </div>
                                    </div>
                                </Row>
                            </div>
                            <Row>
                                <div className="col-12">
                                    <SuppliersLists edit={editSuppliers} />
                                </div>
                            </Row>
                        </Container>
                    </>
                )
            )}
        </>
    );
}