import React, { useEffect } from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import './utilities.css';

export const Buttons = (props) => {

    const { controls } = props;
    const { t } = useTranslation();
    const [globalState] = React.useContext(GlobalStateContext);

    return (
        <div className="buttons">
            {controls.export &&
                <div className="btnExport">
                    <ButtonComponent
                        cssClass='e-outline export'
                        type='button'
                        onClick={controls.export}
                    >{t('Buttons:export')}</ButtonComponent>
                </div>
            }
            {
                controls.exportPdf &&
                <div className="btnExport">
                    <ButtonComponent
                        cssClass='e-outline export'
                        type='button'
                        onClick={controls.exportPdf}
                    >{t('Buttons:exportPdf')}</ButtonComponent>
                </div>
            }
            {controls.import &&
                <div className="btnExport">
                    <ButtonComponent
                        cssClass='e-outline inport'
                        type='button'
                        onClick={controls.import}
                    >{t('Buttons:import')}</ButtonComponent>
                </div>
            }
            {controls.new && (globalState.userAdmin ||
                (controls.permit !==null ?
                    controls.permit.indexOf("Admin") !==-1 || controls.permit.indexOf("Inclusão") !==-1
                    :
                    false
                )) &&
                <div className="btnNew">
                    <ButtonComponent
                        cssClass='e-primary new'
                        type='button'
                        onClick={controls.new}
                    >{t('Buttons:new')}</ButtonComponent>
                </div>
            }
            {controls.back &&
                <div className="btnBack">
                    <ButtonComponent
                        cssClass='e-flat back'
                        type='button'
                    //onClick={controls.back}
                    >{t('Buttons:back')}</ButtonComponent>
                </div>
            }
        </div>
    );
}
