import React from 'react';
import { Redirect } from "react-router-dom";
import { Container } from 'reactstrap';
import { NavMenu } from '../components/menu/NavMenu';
import { GlobalStateContext } from './general/GlobalState';

export const Layout = ({ children }) => {
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);

    return (
        <>
            { <NavMenu />}
            {children}
        </>
    );
}