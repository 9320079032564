import { requestAPI } from '../components/utilities/Utils';

// export class PressReportFilter {
//   constructor(businessArea, startPeriod, endPeriod, status, country, regional) {
//     this.areaNegocio = businessArea;
//     this.startPeriod = startPeriod;
//     this.endPeriod = endPeriod;
//     this.bloqueado = status;
//     this.pais = country;
//     this.regional = regional;
//   }
// }

export async function getPressItems(token, pressTypeId, qString) {
  switch (pressTypeId) {
    case 1:
      return requestAPI("_api/Release/GetReleaseItems", "GET", qString, null, token, false);
    case 2:
      return requestAPI("_api/Statement/GetStatementsItems", "GET", qString, null, token, false);
    case 3:
      return requestAPI("_api/PositionPaper/GetPositionPaperItems", "GET", qString, null, token, false);
    case 4:
      return requestAPI("_api/QA/GetQAItems", "GET", qString, null, token, false);
    case 6:
      return requestAPI("_api/WebcastNotice/GetWebcastNoticeItems", "GET", qString, null, token, false);
    case 7:
      return requestAPI("_api/AssignmentNotice/GetAssignmentNoticeItems", "GET", qString, null, token, false);
  }
}

export async function getPressItemsFiltered(token, body, pressTypeId, reports) {

  const newBody = { ...body, reports: reports ? true : false };
  let raw = JSON.stringify(newBody);

  switch (pressTypeId) {
    case 1:
      return requestAPI("_api/Release/GetReleaseFiltered", "POST", "", raw, token, false);
    case 2:
      return requestAPI("_api/Statement/GetStatementsFiltered", "POST", "", raw, token, false);
    case 3:
      return requestAPI("_api/PositionPaper/GetPositionPaperFiltered", "POST", "", raw, token, false);
    case 4:
      return requestAPI("_api/QA/GetQAFiltered", "POST", "", raw, token, false);
    case 6:
      return requestAPI("_api/WebcastNotice/GetWebcastNoticeFiltered", "POST", "", raw, token, false);
    case 7:
      return requestAPI("_api/AssignmentNotice/GetAssignmentNoticeFiltered", "POST", "", raw, token, false);
  }
}

export async function exportPressReport(token, body, pressTypeId) {

  var raw = JSON.stringify(body);

  switch (pressTypeId) {
    case 1:
      return requestAPI("_api/Release/ExportReleaseReport", "POST", "", raw, token, null);
    case 2:
      return requestAPI("_api/Statement/ExportStatementReport", "POST", "", raw, token, null);
    case 3:
      return requestAPI("_api/PositionPaper/ExportPositionPaperReport", "POST", "", raw, token, null);
    // case 4:
    //   return requestAPI("_api/QA/ExportQAReport", "POST", "", raw, token, null);
    // case 6:
    //   return requestAPI("_api/WebcastNotice/ExportWebcastNoticeReport", "POST", "", raw, token, null);
    // case 7:
    //   return requestAPI("_api/AssignmentNotice/ExportAssignmentNoticeReport", "POST", "", raw, token, null);
  }
}

export async function getPressItem(token, id, pressTypeId) {

  let qstring = "?pressId=" + id;

  switch (pressTypeId) {
    case 1:
      return requestAPI("_api/Release/GetReleaseItem", "GET", qstring, null, token, false);
    case 2:
      return requestAPI("_api/Statement/GetStatementItem", "GET", qstring, null, token, false);
    case 3:
      return requestAPI("_api/PositionPaper/GetPositionPaperItem", "GET", qstring, null, token, false);
    case 4:
      return requestAPI("_api/QA/GetQAItem", "GET", qstring, null, token, false);
    case 6:
      return requestAPI("_api/WebcastNotice/GetWebcastNoticeItem", "GET", qstring, null, token, false);
    case 7:
      return requestAPI("_api/AssignmentNotice/GetAssignmentNoticeItem", "GET", qstring, null, token, false);
  }

}

export async function addPressItem(token, body, pressTypeId) {
  var form_data = new FormData();
  for (var key in body) {
    if (key !== "midias") {
      if (body[key])
        form_data.append(key, body[key]);
    }
  }
  if (body.midias) {
    for (var midia in body.midias) {
      form_data.append("midias", body.midias[midia])
    }
  }

  switch (pressTypeId) {
    case 1:
      return requestAPI("_api/Release/AddReleaseItem", "POST", "", form_data, token, true);
    case 2:
      return requestAPI("_api/Statement/AddStatementItem", "POST", "", form_data, token, true);
    case 3:
      return requestAPI("_api/PositionPaper/AddPositionPaperItem", "POST", "", form_data, token, true);
    case 4:
      return requestAPI("_api/QA/AddQAItem", "POST", "", form_data, token, true);
    case 6:
      return requestAPI("_api/WebcastNotice/AddWebcastNoticeItem", "POST", "", form_data, token, true);
    case 7:
      return requestAPI("_api/AssignmentNotice/AddAssignmentNoticeItem", "POST", "", form_data, token, true);
  }
}

export async function updatePressItem(token, body, pressTypeId) {

  var form_data = new FormData();
  for (var key in body) {
    if (key !== "midias") {
      if (body[key])
        form_data.append(key, body[key]);
    }
  }

  if (body.midias) {
    for (var midia in body.midias) {
      form_data.append("midias", body.midias[midia])
    }
  }

  console.log('body', body)
  console.log('form_data', form_data)

  switch (pressTypeId) {
    case 1:
      return requestAPI("_api/Release/UpdateReleaseItem", "POST", "", form_data, token, true);
    case 2:
      return requestAPI("_api/Statement/UpdateStatementItem", "POST", "", form_data, token, true);
    case 3:
      return requestAPI("_api/PositionPaper/UpdatePositionPaperItem", "POST", "", form_data, token, true);
    case 4:
      return requestAPI("_api/QA/UpdateQAItem", "POST", "", form_data, token, true);
    case 6:
      return requestAPI("_api/WebcastNotice/UpdateWebcastNoticeItem", "POST", "", form_data, token, true);
    case 7:
      return requestAPI("_api/AssignmentNotice/UpdateAssignmentNoticeItem", "POST", "", form_data, token, true);
  }
}

export async function deletePressItem(token, id, pressTypeId) {

  let qstring = "?pressId=" + id;

  switch (pressTypeId) {
    case 1:
      return requestAPI("_api/Release/DeleteReleaseItem", "POST", qstring, null, token, false);
    case 2:
      return requestAPI("_api/Statement/DeleteStatementItem", "POST", qstring, null, token, false);
    case 3:
      return requestAPI("_api/PositionPaper/DeletePositionPaperItem", "POST", qstring, null, token, false);
    case 4:
      return requestAPI("_api/QA/DeleteQAItem", "POST", qstring, null, token, false);
    case 6:
      return requestAPI("_api/WebcastNotice/DeleteWebcastNoticeItem", "POST", qstring, null, token, false);
    case 7:
      return requestAPI("_api/AssignmentNotice/DeleteAssignmentNoticeItem", "POST", qstring, null, token, false);
  }
}

export async function duplicatePressItem(token, id, pressTypeId) {
  const qString = `?pressId=${id}`;
  switch (pressTypeId) {
    case 1:
      return requestAPI("_api/Release/DuplicatePressItem", "POST", qString, null, token, false);
    case 2:
      return requestAPI("_api/Statement/DuplicatePressItem", "POST", qString, null, token, false);
    case 3:
      return requestAPI("_api/PositionPaper/DuplicatePressItem", "POST", qString, null, token, false);
    case 4:
      return requestAPI("_api/QA/DuplicatePressItem", "POST", qString, null, token, false);
    case 6:
      return requestAPI("_api/WebcastNotice/DuplicatePressItem", "POST", qString, null, token, false);
    case 7:
      return requestAPI("_api/AssignmentNotice/DuplicatePressItem", "POST", qString, null, token, false);
  }
}

export async function duplicateForwardPressItem(token, id, pressTypeId, regionalId, conteudo) {
  const raw = JSON.stringify({
    pressId: id,
    regionalId, conteudo
  })

  switch (pressTypeId) {
    case 1:
      return requestAPI("_api/Release/ForwardPressItem", "POST", "", raw, token, false);
    case 2:
      return requestAPI("_api/Statement/ForwardPressItem", "POST", "", raw, token, false);
    case 3:
      return requestAPI("_api/PositionPaper/ForwardPressItem", "POST", "", raw, token, false);
    case 4:
      return requestAPI("_api/QA/ForwardPressItem", "POST", "", raw, token, false);
    case 6:
      return requestAPI("_api/WebcastNotice/ForwardPressItem", "POST", "", raw, token, false);
    case 7:
      return requestAPI("_api/AssignmentNotice/ForwardPressItem", "POST", "", raw, token, false);
  }
}

export async function getMailingShippingStatusItems(token, id) {
  const qstring = id ? `?pressId=${id}&$orderby=id desc` : '';
  return requestAPI("_api/Release/GetMailingShippingStatusItems", "GET", qstring, null, token, false);
}

export async function getMailingReportItems(token, id) {
  const qstring = id ? `?mailingShippingStatusId=${id}` : '';
  return requestAPI("_api/Release/GetMailingReportItems", "GET", qstring, null, token, false);
}

export async function uploadPressMedia(token, qstring, blob) {
  var form_data = new FormData();
  form_data.append("file", blob);
  return requestAPI("_api/Release/UploadPressMedia?", "POST", qstring, form_data, token, true);
}

export async function addShippingStatusItem(token, body, type) {
  const raw = JSON.stringify(body);
  switch (type) {
    case 1:
      return requestAPI("_api/Release/AddShippingStatusItem", "POST", "", raw, token, false);
    case 2:
      return requestAPI("_api/Statement/AddShippingStatusItem", "POST", "", raw, token, false);
    case 3:
      return requestAPI("_api/PositionPaper/AddShippingStatusItem", "POST", "", raw, token, false);
    case 4:
      return requestAPI("_api/QA/AddShippingStatusItem", "POST", "", raw, token, false);
    case 6:
      return requestAPI("_api/WebcastNotice/AddShippingStatusItem", "POST", "", raw, token, false);
    case 7:
      return requestAPI("_api/AssignmentNotice/AddShippingStatusItem", "POST", "", raw, token, false);
  }
}

export async function removeMedia(token, qstring, type) {
  qstring = `?pressTypeId=${type}&${qstring}`;
  switch (type) {
    case 1:
      return requestAPI("_api/Release/RemovePressMedia", "POST", qstring, null, token, false);
    case 2:
      return requestAPI("_api/Statement/RemovePressMedia", "POST", qstring, null, token, false);
    case 3:
      return requestAPI("_api/PositionPaper/RemovePressMedia", "POST", qstring, null, token, false);
    case 4:
      return requestAPI("_api/QA/RemovePressMedia", "POST", qstring, null, token, false);
    case 6:
      return requestAPI("_api/WebcastNotice/RemovePressMedia", "POST", qstring, null, token, false);
    case 7:
      return requestAPI("_api/AssignmentNotice/RemovePressMedia", "POST", qstring, null, token, false);
  }
}
