import { requestAPI } from '../components/utilities/Utils';

export async function getProfileTemplate(token) {
    return requestAPI("_api/ProfileTemplate/GetProfileTemplates", "GET", "", null, token, false);
}

export async function addProfileTemplate(token, body) {
    const raw = JSON.stringify(body);
    return requestAPI("_api/ProfileTemplate/AddProfileTemplate", "POST", "", raw, token, false);
}

export async function updateProfileTemplate(token, body) {
    const raw = JSON.stringify(body);
    return requestAPI("_api/ProfileTemplate/UpdateProfileTemplate", "POST", "", raw, token, false);
}

export async function deleteProfileTemplate(token, id) {
    const qstring = `?profileId=${id}`;
    return requestAPI("_api/ProfileTemplate/DeleteProfileTemplate", "DELETE", qstring, null, token, false);
}