import * as React from "react";
import { SharedDocumentsComponent } from './SharedDocumentsComponent';
import { FolderManager } from "../home/FolderManager";

import { useTranslation } from "react-i18next";

export const DirectCommunication = () => {
    const { t } = useTranslation();

    return <SharedDocumentsComponent
        permissionId="67"
        path="Comunicação Direta"
        folderManagerTitle={t("EmpCommunication:direct_communication")}
        title={t("EmpCommunication:direct_communication")}
    />
}
