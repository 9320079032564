import * as React from "react";
import i18n from "i18next";
import { Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState'
import { ModalBanners } from "../banners/ModalBanners";
import { BannersList } from "../banners/BannersList";
import { Buttons } from "../utilities/Buttons";
import { getBanners } from "../../services/BannerHomeSVC";
import { Actions } from "../utilities/Actions";
import '../banners/banners.css';
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";


export const Banners = () => {

    const [globalState] = React.useContext(GlobalStateContext);
    const [dataUser, setDataUser] = React.useState('');
    const [gridRefresh, setGridRefresh] = React.useState(false);
    const [showDialog, setShowDialog] = React.useState(false);
    const [dataBanner, setDataBanner] = React.useState(null);
    const [catchRefresh, setCatchRefresh] = React.useState(null);

    const [bannersList, setBannersList] = React.useState([]);

    const { t } = useTranslation();

    const _controls = {
        export: "",
        import: "",
        new: () => editBanner(''),
        back: "",
    };

    const _info = {
        close: () => setShowDialog(false),
        refresh: () => setGridRefresh(true)
    }

    let editBanner = (info) => {
        console.log("info", info);
        setDataBanner(info);
        setShowDialog(true);
    }

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                <>
                    <PermissionRedirect id="54" />
                    <div className="DefaultTop" />
                    <Container className="containerTop banner">
                        <div className="topTitleButtons">
                            <Row>
                                <div className="col-sm-8 col-12">
                                    <div className="title">
                                        <h1>{t('Banners:title')}</h1>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <div className="buttons">
                                        <Buttons controls={_controls} />
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <Row>
                            <div className="col-12">
                                <BannersList setBannersList={setBannersList} bannersList={bannersList} catchRefresh={catchRefresh} edit={editBanner} update={dataBanner} refresh={gridRefresh} />
                            </div>
                        </Row>
                    </Container>
                    {showDialog &&
                        <ModalBanners setBannersList={setBannersList} bannersList={bannersList} setRefresh={setCatchRefresh} status={showDialog} toggle={_info} data={dataBanner} />
                    }
                </>
            }
        </>
    );
}