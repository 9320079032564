import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../general/GlobalState";
import { createSpinner, showSpinner, setSpinner, hideSpinner } from "@syncfusion/ej2-react-popups";
import { GridComponent, ColumnsDirective, ColumnDirective, ForeignKey, Filter, Inject, VirtualScroll, Sort, ContextMenu, Page, Resize, Edit, Toolbar, ExcelExport } from '@syncfusion/ej2-react-grids';
import { ComboBoxComponent, AutoCompleteComponent, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent, DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { getCountries, getAllBusinessAreas, getRegional } from "../../services/SystemSVC";
import { getPressItems, getPressItemsFiltered } from "../../services/PressSVC";
import { users } from "../../services/UsersSVC";
import '../releases/releases.css';
import { useModal } from "../../hooks/Modal";
import { hasPermissions } from "../utilities/Utils";

export const QAList = (props) => {

    const { t } = useTranslation();
    const { refQA, gridInstance } = props;

    const [globalState] = React.useContext(GlobalStateContext);
    const [isVisible, setIsVisible] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [fillUsersData, setFillUsersData] = React.useState([]);
    const [fillBusinessAreaData, setFillBusinessAreaData] = React.useState([]);
    const [fillCountriesData, setFillCountriesData] = React.useState([]);
    const [fillRegionalData, setFillRegionalData] = React.useState([]);
    const [qaItems, setQAItems] = React.useState(null);
    const [allQA, setAllQA] = React.useState(null);
    const [filteredQAItems, setFilteredQAItems] = React.useState({ idTipo: 4, idPais: 6 });
    const ddlRegionalRef = React.useRef();
    const { buildDialogMessage } = useModal();

    React.useEffect(() => {
        if (loading) {
            createSpinner({ target: document.getElementById('loadQA') });
            showSpinner(document.getElementById('loadQA'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [loading]);

    React.useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language]);

    React.useEffect(() => {
        setLoading(true);
        async function load() {
            let _users = await users(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                });
            let _businessAreas = await getAllBusinessAreas(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                });
            let _countries = await getCountries(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                });
            let _regional = await getRegional(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                });
            const filterQstring = globalState.regional ? `?$filter=idRegional eq ${globalState.regional.id}` : "";
            let _pressItems = await getPressItems(globalState.accessToken, 4, filterQstring)
                .then(response => { if (response.ok) { return response.json() } });

            if (_pressItems) {
              _pressItems = _pressItems.filter(item => {
                item.bloqueado = item.bloqueado ? i18n.language ==="pt" ? "Bloqueado" : "Blocked" : i18n.language ==="pt" ? "Terminado" : "Finished";
                return item;
              });

                setQAItems(_pressItems);
                setAllQA(_pressItems);
            }

            let oldUsers = _pressItems?.map(item => {
              return { nome: item.autores }
            });

            const set = new Set();
            const newUsers = oldUsers.filter(item => {
              const duplicate = set.has(item?.nome);
              set.add(item?.nome);

              return !duplicate;
            })

            const newBusinessAreas = [..._businessAreas, { id: - 1, nomeEn: "All", nomePt: "Todos" }]
            const countries = [..._countries, { id: - 1, nomeEn: "All", nomePt: "Todos" }]
            const newRegional = [..._regional, { id: - 1, nomeEn: "All", nomePt: "Todos" }]

            setFillUsersData(newUsers);
            setFillBusinessAreaData(newBusinessAreas);
            setFillCountriesData(countries);
            setFillRegionalData(newRegional);

            hideSpinner(document.getElementById('loadQA'));
            setLoading(false);
        }
        load();
    }, [i18n.language]);

    React.useEffect(()=>{
        if(fillRegionalData.length && !loading){
            if(globalState.regional)
                ddlRegionalRef.current.value = globalState.regional.id;
            else
                setIsVisible(false);
        }
    },[fillRegionalData, loading]);

    const statusQA = [{
        text: i18n.language ==='pt' ? 'Bloqueado' : 'Blocked',
        value: true
    }, {
        text: i18n.language ==='pt' ? 'Terminado' : 'Finished',
        value: false
    }, {
      text: i18n.language ==='pt' ? 'Todos' : 'All',
      value: null
    }
    ]

    const filteredQA = async () => {
        if (filteredQAItems) {
            let _filteredQA = await getPressItemsFiltered(globalState.accessToken, filteredQAItems, 4)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                });

            _filteredQA = _filteredQA.filter(item => {
                item.bloqueado = item.bloqueado ? i18n.language ==="pt" ? "Bloqueado" : "Blocked" : i18n.language ==="pt" ? "Terminado" : "Finished";
                return item;
            });


            setQAItems(_filteredQA);

            setIsVisible(false);
        } else {
            setQAItems(allQA);
        }
    }

    let qaDate = (args) => {
        const dateQA = new Date(args.data);
        return (
            <span>{dateQA.toLocaleDateString(i18n.language)}</span>
        )
    }

    let qaTitle = (args) => {
        return (
            <span className="title" onClick={() => props.edit(args)}>{args.titulo}</span>
        );
    }

    let qaStatus = (args) => {
      if (args.bloqueado === 'Bloqueado') {
        return (<span className="status nao">{i18n.language ==='pt' ? 'Bloqueado' : 'Blocked'}</span>)
      } else {
        return (<span className="status sim">{i18n.language ==='pt' ? 'Terminado' : 'Finished'}</span>)
      }
    }

    return (
        <>
            {loading ? <div id="loadQA" /> :
                <>
                    <div className={`busca ${isVisible ? "aberto" : ""}`}>
                        <div className="filtroUsuarios d-flex">
                            <div className="SearchTitle">
                                <span className="mdl2 mdl2-search"></span>
                                <p>{t('QA:search_text')}</p>
                            </div>
                            <div className="searchUsers">
                                <div className="e-input-in-wrap">
                                    <TextBoxComponent
                                        className="e-input"
                                        placeholder={t('QA:search_placeholder')}
                                        locale={i18n.language}
                                        value={filteredQAItems && filteredQAItems.conteudo ? filteredQAItems.conteudo : ""}
                                        blur={(e) => setFilteredQAItems(
                                            filteredQAItems => ({
                                                ...filteredQAItems,
                                                conteudo: e.value
                                            })
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="btnBusca d-flex">
                                {!isVisible &&
                                    <div className="btnFilter">
                                        <ButtonComponent
                                            className="e-outline filter"
                                            cssClass='e-primary filter'
                                            onClick={() => setIsVisible(!isVisible)}>
                                            <span className="mdl2 mdl2-filter"></span>
                                            {t('QA:filters')}
                                        </ButtonComponent>
                                    </div>
                                }
                                {isVisible &&
                                    <div className="btnFilter">
                                        <ButtonComponent
                                            className="e-outline filter active-filter"
                                            cssClass='e-primary filter'
                                            onClick={() => setIsVisible(!isVisible)}>
                                            <span className="mdl2 mdl2-filter"></span>
                                            {t('QA:filters')}
                                        </ButtonComponent>
                                    </div>
                                }
                                <div className="btnSearch">
                                    <ButtonComponent cssClass='e-primary search'
                                        //onClick={() => filterQA()}
                                        onClick={() => filteredQA()}
                                    >
                                        {t('QA:search')}
                                    </ButtonComponent>
                                </div>
                            </div>
                        </div>
                        {isVisible &&
                            <div className="filtrosOcultos seis d-flex">
                                <DateRangePickerComponent
                                    id="date"
                                    cssClass="e-filled"
                                    placeholder={t('QA:date')}
                                    locale={i18n.language}
                                    change={(e) => setFilteredQAItems(
                                        filteredQAItems => ({
                                            ...filteredQAItems,
                                            dataInicio: e.value && e.value[0].toISOString().split('.')[0],
                                            dataFim: e.value && e.value[1].toISOString().split('.')[0]
                                        })
                                    )}
                                />
                                <AutoCompleteComponent
                                    id="autor"
                                    autofill={true}
                                    cssClass="e-filled"
                                    placeholder={t('QA:author')}
                                    dataSource={fillUsersData}
                                    fields={{ value: 'nome' }}
                                    //change={(e) => setFilterQAAuthor(e.value)}
                                    change={(e) => setFilteredQAItems(
                                        filteredQAItems => ({
                                            ...filteredQAItems,
                                            autor: e.value
                                        })
                                    )}
                                />
                                <DropDownListComponent
                                    id="areaNegocio"
                                    cssClass="e-filled"
                                    placeholder={t('QA:business_area')}
                                    showClearButton
                                    dataSource={fillBusinessAreaData}
                                    fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                                    locale={i18n.language}
                                    change={(e) => setFilteredQAItems(
                                        filteredQAItems => ({
                                            ...filteredQAItems,
                                            idAreaNegocio: e.value
                                        })
                                    )}

                                />
                                <DropDownListComponent
                                    id="idPais"
                                    cssClass="e-filled"
                                    placeholder={t('QA:country')}
                                    showClearButton
                                    dataSource={fillCountriesData}
                                    fields={{ text: (i18n.language ==="pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
                                    locale={i18n.language}
                                    allowFiltering={true}
                                    //change={(e) => setFilterQACountry(e.value)}
                                    value={filteredQAItems ? filteredQAItems.idPais : ""}
                                    change={(e) => setFilteredQAItems(
                                        filteredQAItems => ({
                                            ...filteredQAItems,
                                            idPais: e.value
                                        })
                                    )}
                                />
                                <DropDownListComponent
                                    id="regional"
                                    ref={ddlComponent => ddlRegionalRef.current = ddlComponent}
                                    cssClass="e-filled"
                                    placeholder={t('QA:regional')}
                                    showClearButton
                                    dataSource={fillRegionalData}
                                    fields={{ text: (i18n.language ==="pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
                                    value={filteredQAItems ? filteredQAItems.idRegional : globalState.regional? globalState.regional.id : ''}
                                    locale={i18n.language}
                                    change={(e) => setFilteredQAItems(
                                        filteredQAItems => ({
                                            ...filteredQAItems,
                                            idRegional: e.value
                                        })
                                    )}
                                />
                                <DropDownListComponent
                                    id="status"
                                    cssClass="e-filled"
                                    placeholder={t('QA:status')}
                                    showClearButton
                                    dataSource={statusQA}
                                    fields={{ text: "text", value: "value" }}
                                    locale={i18n.language}
                                    value={filteredQAItems && filteredQAItems.bloqueado}
                                    change={(e) => setFilteredQAItems(
                                        filteredQAItems => ({
                                            ...filteredQAItems,
                                            bloqueado: e.value
                                        })
                                    )}
                                />
                            </div>
                        }
                    </div>
                    <div className="listaReleases">
                        <GridComponent
                            id='gridcomp'
                            dataSource={qaItems}
                            filterSettings={{ type: 'Menu' }}
                            allowFiltering={false}
                            allowPaging={true}
                            allowSorting={true}
                            allowExcelExport={true}
                            ref={(grid) => refQA(grid)}
                            locale={i18n.language}>
                            <ColumnsDirective >
                                <ColumnDirective
                                    field='data'
                                    template={qaDate}
                                    headerText={t('QA:date')}
                                    width='100'>
                                </ColumnDirective>
                                <ColumnDirective
                                    field='titulo'
                                    template={qaTitle}
                                    headerText={t('QA:name')}
                                    width='400'>
                                </ColumnDirective>
                                <ColumnDirective
                                    field="autores"
                                    headerText={t('QA:author')}
                                    //template={(item)=> filterTemplate(i18n.language ==="pt" ? item.nomePaisPT : item.nomePaisEN)}
                                    width='350'>
                                </ColumnDirective>
                                <ColumnDirective
                                    field="bloqueado"
                                    template={qaStatus}
                                    headerText={t('QA:status')}
                                    width='150'>
                                </ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Edit, ForeignKey, Toolbar, ExcelExport]} />
                        </GridComponent>

                    </div>
                </>
            }
        </>
    )
}
