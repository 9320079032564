import { requestAPI } from '../utilities/Utils';

export async function getManageAnnualBudget(token) {
  // const qstring; //= idioma ? `?$filter=IdIdioma eq ${idioma}` : '';
  const qString = "?$orderby=id desc"

  return requestAPI(
    '_api/BrandingAdvertising/Management/AnnualBudget/GetAnnualBudgetItems',
    'GET',
    qString,
    null,
    token,
    false,
  );
}

export async function updateManageAnnualBudget(token, body) {
  const raw = JSON.stringify(body);
  return requestAPI(
    '_api/BrandingAdvertising/Management/AnnualBudget/UpdateAnnualBudget',
    'POST',
    '',
    raw,
    token,
    false,
  );
}

export async function addManageAnnualBudget(token, body) {
  const raw = JSON.stringify(body);
  return requestAPI(
    '_api/BrandingAdvertising/Management/AnnualBudget/AddAnnualBudget',
    'POST',
    '',
    raw,
    token,
    false,
  );
}
