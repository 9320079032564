import * as React from "react";
import i18n from "i18next";
import { Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState';
import { Actions } from "../utilities/Actions";
import '../templates/templates.css';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent, MultiSelectComponent, CheckBoxSelection, Inject, DropDownTreeComponent } from "@syncfusion/ej2-react-dropdowns";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Page } from "@syncfusion/ej2-react-grids";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { getProfileTemplate, addProfileTemplate, updateProfileTemplate, deleteProfileTemplate } from "../../services/ProfileTemplateSVC";
import { getAccessAreas, getPermissions } from "../../services/SystemSVC";
import { FormValidator } from '@syncfusion/ej2-react-inputs';
import { hasPermissions } from "../utilities/Utils";
import { useModal } from '../../hooks/Modal';

export const TemplateItem = (props) => {

    let gridInstance;
    let idTemplate = props.match.params.id;
    let formObject;
    let dropdownTreeAreasRef;
    let multiSelectPermissionsRef;

    let auxProfileTemplateName = null;
    let auxAccessArea = [];
    let auxPermission = [];
    let auxIdsPermissions = [];
    let auxPermissionsPt = [];
    let auxPermissionsEn = [];
    let auxGridPermission = [];

    const [globalState] = React.useContext(GlobalStateContext);
    const [redirect, setRedirect] = React.useState(false);
    const [comboPermissions, setComboPermissions] = React.useState([]);
    const [comboAccessAreas, setComboAccessAreas] = React.useState([]);
    const [profileTemplate, setProfileTemplate] = React.useState({});
    const [gridDataSource, setGridDataSource] = React.useState(null);
    const [fillAccessAreas, setFillAccessAreas] = React.useState(null);

    const [placeholderName, setPlaceholderName] = React.useState("");
    const [placeholderTitle, setPlaceholderTitle] = React.useState("");

    const [isLoading, setLoading] = React.useState(true);
    const [dialogDelete, setDialogDelete] = React.useState(false);
    const [disabledBtn, setDisabledBtn] = React.useState(false);

    const { t } = useTranslation();

    const { buildDialogMessage } = useModal();

    const _controls = {
        class: "templates",
        savePrimary: false,
        excludePrimary: true,
        urlView: "",
        urlPreview: "",
        urlDuplicate: "",
        urlSave: (e) => saveTemplate(e),
        urlSend: "",
        urlCancel: () => setRedirect(true),
        urlDelete: idTemplate ? (e) => {
            e.preventDefault();
            setDialogDelete(true);
        } : ""
    };

    React.useEffect(() => {
        async function load() {
            let _permissions = await getPermissions(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                })
            let _accessAreas = await getAccessAreas(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                })
            let _profileTemplate = await getProfileTemplate(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                })

            if (_profileTemplate && _accessAreas) {
                _profileTemplate = _profileTemplate.filter(i => i.id ===idTemplate)[0];
                if (_profileTemplate) {
                    if (_profileTemplate.permissions) {
                        _profileTemplate.permissions.map((pe) => {
                            let area = _accessAreas.filter(i => i.id ===pe.idAccessArea);
                            if (area.length > 0) {
                                pe.area = area[0];
                            }
                        })

                        auxGridPermission = _profileTemplate.permissions;
                        setProfileTemplate(_profileTemplate)
                    }
                }
            }

            setComboPermissions(_permissions);
            if (_accessAreas) {

                setFillAccessAreas(fillAreas(_accessAreas));
                setComboAccessAreas(fillAreas(_accessAreas));
            }

            setPlaceholderName(t('ProfileTemplates:modelName_placeholder'));

            if (idTemplate) {
                setPlaceholderTitle(t('ProfileTemplates:header_edit'));
            } else {
                setPlaceholderTitle(t('ProfileTemplates:header_new'));
            }
        }

        load();

    }, [i18n.language]);

    React.useEffect(() => {
        const formValidator = {
            rules: {
                'nome': {
                    required: [true, t('Validation:required')]
                }
                //'dropdownAcessArea': {
                //    required: [true, t('Validation:required')]
                //},
                //'dropdownPermission': {
                //    required: [true, t('Validation:required')]
                //}
            },
            customPlacement: (inputElement, errorElement) => {
                inputElement.parentElement.parentElement.appendChild(errorElement);
                setTimeout(() => {
                    errorElement.style.display = "none";
                }, 3000)
            }
        };
        formObject = new FormValidator('#formProfileTemplate', formValidator);
    });

    React.useEffect(() => {
        if (typeof (profileTemplate.permissions) !=="undefined") {
            let auxGridDataSource = profileTemplate.permissions.filter(p => { return p.status !==1 });
            setGridDataSource(auxGridDataSource);
        }
    }, [profileTemplate]);

    React.useEffect(() => {
        if (gridDataSource && gridInstance)
            gridInstance.dataSource = gridDataSource;
        dropdownTreeAreasRef.value = [];
        multiSelectPermissionsRef.value = [];
    }, [gridDataSource]);

    React.useEffect(() => {
        setLoading(true);
    }, [gridDataSource]);

    const columnArea = (args) => {
        return <span>{args && (i18n.language ==="pt" ? args.breadCrumbAccessAreaPt.join(' > ') : args.breadCrumbAccessAreaEn.join(' > '))}</span>
    };

    const columnPermission = (args) => {
        return <span>{args && (i18n.language ==="pt" ? args.permissionsPt.join(', ') : args.permissionsEn.join(', '))}</span>
    };

    function deleteRow(e, args) {
        e.preventDefault();
        let index = -1;
        gridDataSource.map((p, i) => {
            if (p.idAccessArea === args.idAccessArea) {
                if (p.status === 2)
                    p.status = 1;
                else
                    index = i;
            }
        });
        if (index > -1)
            gridDataSource.splice(index, 1);

        setProfileTemplate(profileTemplate => (
            {
                ...profileTemplate,
                permissions: gridDataSource
            }
        ))
    }

    const columnButton = (args) => {
        return (
            <ButtonComponent
                id=""
                cssClass="e-flat e-danger"
                onClick={(e) => deleteRow(e, args)}
            >{t("Actions:delete")}</ButtonComponent>
        )
    }

    const fillAreas = (items, currentItem = null, child = false) => {
        let arr = [];
        items.map((item) => {
            if (!child) {
                arr.push({ value: item.id, text: i18n.language ==="pt" ? item.nomePt : item.nomeEn, hasChildren: item.filhos.length > 0 ? true : false, breadCrumbAccessAreaEn: item.breadCrumbEn, breadCrumbAccessAreaPt: item.breadCrumbPt, isFather: true });
                if (item.filhos.length > 0) {
                    let children = fillAreas(item.filhos, item, true);
                    arr = arr.concat(children);
                }
            } else {
                arr.push({ value: item.id, text: i18n.language ==="pt" ? item.nomePt : item.nomeEn, parentValue: item.idPai, hasChildren: item.filhos.length > 0 ? true : false, breadCrumbAccessAreaEn: item.breadCrumbEn, breadCrumbAccessAreaPt: item.breadCrumbPt, isFather: false });
                if (item.filhos.length > 0) {
                    let children = fillAreas(item.filhos, item, true);
                    arr = arr.concat(children);
                }
            }
        });
        return arr;
    }

    const profileTemplateNameChange = (e) => {

        if (e.value !==null && e.value.length !==0) {
            auxProfileTemplateName = e.value;
        }
    }

    React.useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language]);

    const accessAreaChange = (e) => {
        auxAccessArea = [];
        if (e.value !==null && e.value.length !==0) {
            e.value.map(ac => {
                comboAccessAreas.map(a => {
                    if (a.value.toString() ===ac) {
                        if (!a.hasChildren) {
                            auxAccessArea.push(a);
                        }
                    }
                })
            })
        }
    }

    const permissionChange = (e) => {
        auxPermission = [];
        auxIdsPermissions = [];
        auxPermissionsPt = [];
        auxPermissionsEn = [];

        if (e.value !==null && e.value !==0) {
            e.value.map(p => {
                comboPermissions.map(pe => {
                    if (pe[(i18n.language) ==="pt" ? "nomePt" : "nomeEn"] ===p) {
                        auxPermission.push(pe);
                    }
                });
            });
            auxPermission.map(aux => {
                auxIdsPermissions.push(aux.id);
                auxPermissionsPt.push(aux.nomePt);
                auxPermissionsEn.push(aux.nomeEn);
            });
        }
    }

    const includeTemplate = (e) => {
        e.preventDefault();
        // setLoading(false);
        let profileTemplatePermission = [];

        if (profileTemplate.permissions) {
            profileTemplate.permissions.map((p) => {
                profileTemplatePermission.push(p);
            });
        }

        if (profileTemplate.nome) {
            auxProfileTemplateName = profileTemplate.nome;
        }

        if (auxAccessArea.length && auxIdsPermissions.length) {
            auxAccessArea.map(ac => {
                let obj = {
                    idAccessArea: ac.value,
                    idsPermissions: auxIdsPermissions,
                    permissionsPt: auxPermissionsPt,
                    permissionsEn: auxPermissionsEn,
                    breadCrumbAccessAreaEn: ac.breadCrumbAccessAreaEn,
                    breadCrumbAccessAreaPt: ac.breadCrumbAccessAreaPt,
                    status: 0
                }

                if (profileTemplatePermission.length) {
                    profileTemplatePermission.map(ptp => {
                        if (ptp.idAccessArea === ac.value) {
                            if (ptp.status === 2)
                                ptp.status = 1;
                            else {
                                let index = profileTemplatePermission.findIndex(element => element.idAccessArea === ptp.idAccessArea && element.status === 0);
                                if (index > -1)
                                    profileTemplatePermission.splice(index, 1);
                            }
                        }
                    })
                }

                profileTemplatePermission.push(obj);
            })


            setProfileTemplate(profileTemplate => (
                {
                    ...profileTemplate,
                    nome: auxProfileTemplateName,
                    permissions: profileTemplatePermission
                }
            ));
            document.getElementById("dropdownAcessAreaMessage").innerHTML = "";
            document.getElementById("dropdownPermissionMessage").innerHTML = "";

        }
        else {
            if (!auxAccessArea.length) {
                document.getElementById("dropdownAcessAreaMessage").innerHTML = "Campo obrigatório";
            } else {
                document.getElementById("dropdownAcessAreaMessage").innerHTML = "";
            }

            if (!auxIdsPermissions.length) {
                document.getElementById("dropdownPermissionMessage").innerHTML = "Campo obrigatório";
            } else {
                document.getElementById("dropdownPermissionMessage").innerHTML = "";
            }
        }

    }

    const saveTemplate = (e) => {

        e.preventDefault();

        try {
          if (formObject.validate()) {
                setDisabledBtn(true);
                if (typeof (profileTemplate.id) ==="undefined") {
                    if (auxProfileTemplateName !==null) {
                        profileTemplate.nome = auxProfileTemplateName;
                    }
                    if (profileTemplate.permissions) {
                        addProfileTemplate(globalState.accessToken, profileTemplate)
                            .then(response => {
                                if (response.ok) {
                                    setRedirect(true);
                                }
                                setDisabledBtn(false);
                            })
                            .catch(error => {
                              console.error(error)
                              setDisabledBtn(false);
                            });
                    } else {
                        alert("Os campos Área de acesso e Permissão devem estar preenchidos");
                        setDisabledBtn(false);
                    }
                }
                else {
                    if (auxProfileTemplateName !==null) {
                        profileTemplate.nome = auxProfileTemplateName;
                    }

                    updateProfileTemplate(globalState.accessToken, profileTemplate)
                        .then(response => {
                            if (response.ok) {
                                setRedirect(true);
                            }
                            setDisabledBtn(false);
                        })
                        .catch(error => {
                          console.error(error)
                          setDisabledBtn(false);
                        });
                }
            }
        } catch (e) {
            console.log("saveTemplate - TemplateItem.js >>> ", e);
        }
    }

    let confirmDelete = (e) => {
        e.preventDefault();

        deleteProfileTemplate(globalState.accessToken, idTemplate).then(response => {
            setRedirect(response.ok);
        });
    }

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                redirect ? <Redirect push={true} to='/admin/profiletemplates' /> : (
                    <>
                        <div className="DefaultTop" />
                        <form id="formProfileTemplate">
                            <Container className="containerTop">
                                <div className="topTitleButtons">
                                    <Row>
                                        <div className="col-12">
                                            <div className="title">
                                                <h1>{placeholderTitle}</h1>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <Row>
                                    <div className="col-lg-9 col-12">
                                        <div className="tempItem">
                                            <div className="top d-flex flex-row align-items-center">
                                                <div className="mdl2 mdl2-data" />
                                                <h2>{t("ProfileTemplates:details")}</h2>
                                            </div>
                                            <div className="content d-flex flex-row align-items-center">
                                                <div className="input">
                                                    <label htmlFor="nome">{t("ProfileTemplates:name")} <label class="e-error">*</label></label>
                                                    <TextBoxComponent
                                                        id="nome"
                                                        name="nome"
                                                        cssClass="e-filled"
                                                        htmlAttributes={{ maxlength: "100" }}
                                                        placeholder={placeholderName}
                                                        value={profileTemplate && profileTemplate.nome}
                                                        change={profileTemplateNameChange}
                                                    />
                                                </div>
                                                <div className="combo">
                                                    <label htmlFor="area">{t("ProfileTemplates:accessArea")}</label>
                                                    <DropDownTreeComponent
                                                        id="dropdownAcessArea"
                                                        ref={dropdownTree => dropdownTreeAreasRef = dropdownTree}
                                                        name="dropdownAcessArea"
                                                        cssClass="e-filled"
                                                        //changeOnBlur={false}
                                                        //selectAllText={t('Permissions:selectAllTitle')}
                                                        fields={{ dataSource: comboAccessAreas, value: "value", text: "text", parentValue: "parentValue", hasChildren: "hasChildren" }}
                                                        showCheckBox={true}
                                                        //showSelectAll={true}
                                                        treeSettings={{ autoCheck: true }}
                                                        placeholder={t('ProfileTemplates:accessArea')}
                                                        value={[]}
                                                        change={accessAreaChange}
                                                        locale={i18n.language}
                                                    />
                                                    <span class="error" id="dropdownAcessAreaMessage"></span>
                                                </div>
                                                {isLoading &&
                                                    <div className="combo">
                                                        <label htmlFor="permition">{t("ProfileTemplates:permission")}</label>
                                                        <MultiSelectComponent
                                                            id="dropdownPermission"
                                                            ref={multiSelect => multiSelectPermissionsRef = multiSelect}
                                                            name="dropdownPermission"
                                                            cssClass="e-filled"
                                                            // selectAllText={t('Permissions:selectAllTitle')}
                                                            dataSource={comboPermissions}
                                                            fields={{ text: (i18n.language) ==="pt" ? "nomePt" : "nomeEn" }}
                                                            placeholder={t("ProfileTemplates:permission_placeholder")}
                                                            mode="CheckBox"
                                                            locale={i18n.language}
                                                            change={permissionChange}
                                                        //filterBarPlaceholder="Search countries"
                                                        //popupHeight="350px"
                                                        >
                                                            <Inject services={[CheckBoxSelection]} />
                                                        </MultiSelectComponent>
                                                        <span class="error" id="dropdownPermissionMessage"></span>
                                                    </div>
                                                }
                                                <div className="divButton">
                                                    <ButtonComponent
                                                        cssClass="e-primary"
                                                        onClick={(e) => { includeTemplate(e) }}
                                                    >{t("ProfileTemplates:include")}</ButtonComponent>
                                                </div>
                                            </div>

                                            <div className="table">
                                                {(gridDataSource && fillAccessAreas) &&
                                                    <GridComponent
                                                        id='gridcomp'
                                                        ref={grid => gridInstance = grid}
                                                        allowPaging={true}
                                                        pageSettings={{ pageSize: 10 }}
                                                        locale={i18n.language}
                                                        //dataSource={profileTemplate.permissions}
                                                        dataSource={gridDataSource}
                                                        filterSettings={{ type: 'Menu' }}
                                                    >

                                                        <ColumnsDirective>
                                                            <ColumnDirective
                                                                template={(args) => columnArea(args)}
                                                                // headerText="Área"
                                                                headerText={t('TablePermissions:area')}
                                                                width="400"
                                                            />
                                                            <ColumnDirective
                                                                template={(args) => columnPermission(args)}
                                                                // headerText="Permissões"
                                                                headerText={t('TablePermissions:permission')}
                                                                width="400"
                                                            />
                                                            <ColumnDirective
                                                                template={(args) => columnButton(args)}
                                                                // headerText="Ação"
                                                                headerText={t('TablePermissions:action')}
                                                                width="100"
                                                            />
                                                        </ColumnsDirective>

                                                        <Inject services={[Filter, Page]} />
                                                    </GridComponent>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-12">
                                        <Actions disabled={disabledBtn} controls={_controls} />
                                    </div>
                                </Row>
                            </Container>
                        </form>
                        <DialogComponent
                            id="deleteModal"
                            isModal={true}
                            visible={dialogDelete}
                            locale={i18n.language}
                        >
                            <div>
                                <div className="divTrash">
                                    <label className="mdl2 mdl2-trash"></label>
                                    <div className="phraseDelete">
                                        <label id="delete">{t('Actions:delete')}</label>
                                        <label id="labelDelete">{t('Actions:confirmDeleteMsg')}</label>
                                    </div>
                                </div>
                                <hr />
                                <div className="footerButtons">
                                    <button onClick={() => setDialogDelete(false)}>{t('Actions:cancel')}</button>
                                    <button onClick={(e) => confirmDelete(e)}>{i18n.language ==="pt" ? "Sim" : "Yes"}</button>
                                </div>
                            </div>
                        </DialogComponent>
                    </>
                )
            }
        </>
    )
}
