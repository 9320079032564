import * as React from "react";
import { SharedDocumentsComponent } from './SharedDocumentsComponent';
import { useTranslation } from "react-i18next";

export const Events = () => {
    const { t } = useTranslation();

    return <SharedDocumentsComponent permissionId="68" path="Eventos"
        folderManagerTitle={t("EmpCommunication:events")}
        title={t("EmpCommunication:events")} 
    />
}