import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../general/GlobalState";
import { Container, Row } from "reactstrap";
import { Buttons } from "../utilities/Buttons";
import { ReleasesList } from "../releases/ReleasesList";
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";

let userPermission;

export const Releases = () => {

    const { t } = useTranslation();

    const [globalState] = React.useContext(GlobalStateContext);
    const [redirect, setRedirect] = React.useState(false);
    const [idRelease, setIdRelease] = React.useState(null);
    const [gridInstance, setGridInstance] = React.useState(null);


    //React.useEffect(() => {
    //    async function load() {
    //        let _pressItems = await getPressItems(globalState.accessToken, 1)
    //            .then(response => { if (response.ok) { return response.json() } });

    //        setReleasesItems(_pressItems);
    //        console.log(_pressItems);
    //    }
    //    load();
    //}, [i18n.language]);

    React.useEffect(() => {
        let globalPermissions = globalState.permissions;
        userPermission = globalPermissions.filter((i) => i.accessArea === "Releases").map((i) => (i.permission));
    }, [globalState]);

    const _controls = {
        permit: userPermission ? userPermission : null,
        export: () => exportRelease(),
        import: "",
        new: () => setRedirect(true),
        back: "",
    };

    const editRelease = (info) => {
        setIdRelease(info.id);
        setRedirect(true);
    }

    const exportRelease = () => {
        if (gridInstance) {
            let lang = i18n.language;
            let gridInstanceExport = gridInstance;
            gridInstanceExport.dataSource.map(function (x) {
                x.data = new Date(x.data).toLocaleDateString(lang);
                return x;
            });
            gridInstanceExport.excelExport();
        }
    }


    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                redirect ? (
                    idRelease === null ?
                        <Redirect push={true} to='/press/releasesItem' />
                        :
                        <Redirect push={true} to={`/press/releasesItem/${idRelease}`} />
                ) : (
                        <>
                            <PermissionRedirect id="77" />

                            <div className="DefaultTop" />
                            <Container className="containerTop">
                                <div className="topTitleButtons">
                                    <Row>
                                        <div className="col-sm-8 col-12">
                                            <div className="title">
                                                <h1>{t('Releases:title')}</h1>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                            <div className="buttons">
                                                <Buttons controls={_controls} />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <Row>
                                    <div className="col-12">
                                        <ReleasesList
                                            //items={releasesItems}
                                            gridInstance={gridInstance}
                                            edit={editRelease}
                                            refRelease={setGridInstance}
                                        />
                                    </div>
                                </Row>
                            </Container>
                        </>
                    )
            }
        </>
    )
}
