import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState'
import { useTranslation } from 'react-i18next';

import { Videos } from './Videos';
import { ModalVideo } from './ModalVideo';
import { Buttons } from '../utilities/Buttons';

import './digitalMedia.css';
import { useDigitalMedia } from "./context/DigitalMediaContext";
import { getVideos, getApprovalUsers, getVideosFour, getVideosEight } from '../../services/DigitalMediaSVC';
import { getCategories, getCountries, getRegional, getStatus } from "../../services/SystemSVC";
import PermissionRedirect from '../utilities/PermissionRedirect/PermissionRedirect';
import { createSpinner, showSpinner, setSpinner, hideSpinner } from "@syncfusion/ej2-react-popups";

import MessageModal from '../utilities/MessageModal/MessageModal';

let userPermission;

export const DigitalMedia = () => {

    const [globalState] = useContext(GlobalStateContext);
    const [isDataFetched, setIsDataFetched] = useState(false);

    const {
        editModal,
        modalIsVisible,
        modalMessageParams,
        changeModalVisible,
        setCategoryList,
        setCountriesList,
        setStatusList,
        setRegionalList,
        setUsers,
        buildDialogMessage,
        setPendingVideos,
        setApprovedVideos
    } = useDigitalMedia();

    const { t } = useTranslation();

    useEffect(() => {
        let globalPermissions = globalState.permissions;
        userPermission = globalPermissions.filter((i) => i.idAreaAcesso === 21).map((i) => (i.permission));
    }, [globalState]);

    const _controls = {
        permit: userPermission ? userPermission : null,
        export: "",
        import: "",
        new: () => editModal(),
        back: "",
    };

    const _info = {
        close: () => changeModalVisible(false)
    }

    useEffect(() => {
        async function asyncFunc() {

            const [
                pendingVideos,
                approvedVideos,
                countriesList,
                regionalList,
                categoryList,
                statusList,
                approvalUsersList
            ] = await Promise.all(
                await Promise.all(
                    [
                        getVideosFour({
                            token: globalState.accessToken
                        }),
                        getVideosEight({
                            token: globalState.accessToken
                        }),
                        getCountries(globalState.accessToken),
                        getRegional(globalState.accessToken),
                        getCategories(globalState.accessToken),
                        getStatus(globalState.accessToken),
                        getApprovalUsers(globalState.accessToken)
                    ]
                ).then(response => {
                    return response.map(res => {
                        if (res.ok) {
                            return res.json();
                        }
                    });
                })
            );

            // pendingVideos.value = pendingVideos.value.map(video => objectConvert(video));
            // approvedVideos.value = approvedVideos.value.map(video => objectConvert(video));

            setCountriesList(countriesList);
            setRegionalList(regionalList);
            setStatusList(statusList);
            setCategoryList(categoryList);
            setUsers(approvalUsersList);
            setApprovedVideos(approvedVideos);
            setPendingVideos(pendingVideos);
            setIsDataFetched(true);
        }
        asyncFunc();
    }, []);

    useEffect(() => {
        createSpinner({ target: document.getElementById('loadingDigitalMedia') })
        showSpinner(document.getElementById('loadingDigitalMedia'));
        setSpinner({ type: 'Bootstrap' });
    }, [isDataFetched]);

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                <>
                    <PermissionRedirect id="21" />
                    <MessageModal params={modalMessageParams} />
                    {isDataFetched &&
                        <>
                            <div className="DefaultTop" />
                            <Container className="containerTop">
                                <div className="topTitleButtons">
                                    <Row>
                                        <div className="col-sm-8 col-12">
                                            <div className="title">
                                                <h1>{t('DigitalMedia:title')}</h1>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                            <div className="buttons">
                                                <Buttons
                                                    controls={_controls}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Container>
                            <Videos />
                        </>
                    }
                    {modalIsVisible &&
                        <ModalVideo
                            toggle={_info}
                        />
                    }
                    { !isDataFetched && <div id="loadingDigitalMedia" />}
                </>
            }
        </>
    );
}