import React, { useRef, useState } from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import {
  GridComponent, ColumnsDirective, ColumnDirective, ForeignKey,
  Filter, Inject, Sort, ContextMenu, Page, Resize, Edit
} from '@syncfusion/ej2-react-grids';
import { ExcelExport, Toolbar } from '@syncfusion/ej2-react-grids';
import { DropDownTreeComponent, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { createSpinner, setSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { Query } from '@syncfusion/ej2-data';
import { getSuppliers } from '../../services/SuppliersSVC';
import "./users.css"

import { users, usersFull } from "../../services/UsersSVC";
import {
  getCountries, getAccessAreas, getProfileAreas, getProfileDepartments,
  getProfileManagement, getUserTypes, getRegional
} from "../../services/SystemSVC";

export const UsersList = (props) => {

  const { gridExport, gridExportChange, gridInstance } = props;

  const superiorDiretoRef = useRef(null);
  const cargoRef = useRef(null);
  const areaRef = useRef(null);
  const paisRef = useRef(null);
  const emailRef = useRef(null);
  const regionalRef = useRef(null);
  const areaNegocioRef = useRef(null);
  const tipoUsuarioRef = useRef(null);
  const departamentoRef = useRef(null);
  const fornecedorRef = useRef(null);
  const gerenciaRef = useRef(null);

  const [globalState] = React.useContext(GlobalStateContext);

  const [fillUsersData, setFillUsersData] = React.useState(null);
  const [fillData, setFillData] = React.useState([]);
  const [fillUsersDataFull, setFillUsersDataFull] = React.useState([]);
  const [fillCountriesData, setFillCountriesData] = React.useState([]);
  const [fillAccessAreasData, setFillAccessAreasData] = React.useState([]);
  const [fillBusinessAreaData, setFillBusinessAreaData] = React.useState([]);
  const [fillDepartamentData, setFillDepartamentData] = React.useState([]);
  const [fillManagementData, setFillManagementData] = React.useState([]);
  const [fillUserTypesData, setFillUserTypesData] = React.useState([]);
  const [fillSupplierDescriptionsData, setSupplierDescriptionsData] = React.useState([]);
  const [fillRegionalData, setFillRegionalData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);

  const [persistence, setPersistence] = useState(false);

  const [filters, setFilters] = React.useState([]);

  React.useEffect(() => {
    if (isLoading && document.getElementById('loadUsersList')) {
      createSpinner({ target: document.getElementById('loadUsersList') })
      showSpinner(document.getElementById('loadUsersList'));
      setSpinner({ type: 'Bootstrap' });
    }
  }, [isLoading]);

  const [isVisible, setIsVisible] = React.useState(false);
  const { t } = useTranslation();
  let onChange = (args, text) => {
    const columnId = args.element.id;
    const item = args.itemData;
    if (item && typeof item.id !== "number" && !text) {
      args.element.value = "";
      return;
    };
    const items = args.value && args.value.length > 0 ? args.value.map(x => parseInt(x)) : args.value;

    let newFilters = [...filters];
    let newIndex = newFilters.findIndex(x => x.key === columnId);
    if (newIndex !== -1)
    newFilters.splice(newIndex, 1);

    const filtersSelected = [...newFilters];

    if (item) {
      let index = filtersSelected.findIndex(_item => _item.key === columnId && _item.value === item.id);
      if (index > -1)
        filtersSelected[index].value.push(item.id);
        else
        filtersSelected.push({ key: columnId, value: [item.id], nomePt: item.nomePt, nomeEn: item.nomeEn });
      }
      else if (items && items.length > 0) {
      let index = filtersSelected.findIndex(_item => _item.key === columnId);

      if (index > -1)
        filtersSelected[index].value = items;
        else
        filtersSelected.push({ key: columnId, value: items });
      }
      else {
        let index = filtersSelected.findIndex(_item => _item.key === columnId);
      if (index !== -1)
        filtersSelected.splice(index, 1);
      }
      setFilters(filtersSelected);
    };

  const onTextBlur = (e, columnId) => {
    let hasFilter = !!filters.filter((f) => f.key === columnId).length;
    if (!hasFilter) {
      const args = {
        element: {
          id: columnId
        },
        itemData: {
          id: e && e.value ? e.value : e && e.target ? e.target.value : ""
        }
      };
      if (args.itemData.id) {
        onChange(args, true);
      }
    } else {
      const newFilter = [...filters];
      const item = newFilter.find(f => f.key === columnId);
      item.value[0] = e && e.value ? e.value : e && e.target ? e.target.value : "";
      setFilters(newFilter);
    }
  };

  React.useEffect(() => {
    setLoading(true);

    function load() {
      let promises = [
        new Promise((resolve, reject) => {
          users(globalState.accessToken)
            .then(response => {
              if (response.ok) {
                response.json().then(_users => {
                  setFillUsersData(_users);
                  resolve();
                });
              }
              else {
                setFillUsersData([]);
              }
            })
        }),
        new Promise((resolve, reject) => {
          usersFull(globalState.accessToken).then(_usersFull => {
            const _users = _usersFull.filter(user => {
              user.ultimoLogin = user.ultimoLogin && new Date(user.ultimoLogin);
              return user;
            });
            setFillUsersDataFull(_users);
            setFillData(_users);
            resolve();
          })
            .catch(e => {
              hideSpinner(document.getElementById('loadUsersList'));
              setLoading(false);
              setFillUsersDataFull([]);
              setFillData([]);
            });
        }),
        new Promise((resolve, reject) => {
          getCountries(globalState.accessToken)
            .then(response => {
              if (response.ok) {
                response.json().then(_countries => {
                  setFillCountriesData(_countries);
                  resolve();
                });
              }
              else {
                setFillCountriesData([]);
              }
            });
        }),
        new Promise((resolve, reject) => {
          getAccessAreas(globalState.accessToken)
            .then(response => {
              if (response.ok) {
                response.json().then(_accessAreas => {
                  setFillAccessAreasData(fillAreas(_accessAreas));
                  resolve();
                });
              }
              else {
                setFillAccessAreasData([]);
              }
            });
        }),
        new Promise((resolve, reject) => {
          getProfileAreas(globalState.accessToken, i18n.language)
            .then(response => {
              if (response.ok) {
                response.json().then(_profileAreas => {
                  setFillBusinessAreaData(_profileAreas);
                  resolve();
                });
              }
              else {
                setFillBusinessAreaData([]);
              }
            });
        }),
        new Promise((resolve, reject) => {
          getProfileDepartments(globalState.accessToken, i18n.language)
            .then(response => {
              if (response.ok) {
                response.json().then(_departments => {
                  setFillDepartamentData(_departments);
                  resolve();
                });
              }
              else {
                setFillDepartamentData([]);
              }
            });
        }),
        new Promise((resolve, reject) => {
          getProfileManagement(globalState.accessToken, i18n.language)
            .then(response => {
              if (response.ok) {
                response.json().then(_management => {
                  setFillManagementData(_management);
                  resolve();
                });
              }
              else {
                setFillManagementData([]);
              }
            });
        }),
        new Promise((resolve, reject) => {
          getUserTypes(globalState.accessToken)
            .then(response => {
              if (response.ok) {
                response.json().then(_userTypes => {
                  setFillUserTypesData(_userTypes);
                  resolve();
                });
              }
              else {
                setFillUserTypesData([]);
              }
            });
        }),
        new Promise((resolve, reject) => {
          getSuppliers(globalState.accessToken)
            .then(response => {
              if (response.ok) {
                response.json().then(_supplierDescriptions => {
                  setSupplierDescriptionsData(_supplierDescriptions);
                  resolve();
                });
              }
              else {
                setSupplierDescriptionsData([]);
              }
            });
        }),
        new Promise((resolve, reject) => {
          getRegional(globalState.accessToken)
            .then(response => {
              if (response.ok) {
                response.json().then(_regional => {
                  setFillRegionalData(_regional);
                  resolve();
                });
              }
              else {
                setFillRegionalData([]);
              }
            });
        })
      ];

      Promise.all(promises).then(() => {
        if (document.getElementById('loadUsersList')) {
          hideSpinner(document.getElementById('loadUsersList'));
          setLoading(false);
        }
      }).catch((e) => {
        console.log('teste error');
      })
    }
    load();
  }, [i18n.language]);

  const usersName = (args) => {
    return (
      <span onClick={() => props.edit(args.userId)} className="nomeUsuarios"> {args.nome} </span>
    )
  };

  const fillAreas = (items, child = false) => {
    let arr = [];
    items.map((item) => {
      if (!child) {
        arr.push({ value: item.id, text: i18n.language === "pt" ? item.nomePt : item.nomeEn, hasChildren: item.filhos.length > 0 ? true : false, breadCrumbAccessAreaEn: item.breadCrumbEn, breadCrumbAccessAreaPt: item.breadCrumbPt, isFather: true });
        if (item.filhos.length > 0) {
          let children = fillAreas(item.filhos, item, true);
          arr = arr.concat(children);
        }
      } else {
        arr.push({ value: item.id, text: i18n.language === "pt" ? item.nomePt : item.nomeEn, parentValue: item.idPai, hasChildren: item.filhos.length > 0 ? true : false, breadCrumbAccessAreaEn: item.breadCrumbEn, breadCrumbAccessAreaPt: item.breadCrumbPt, isFather: false });
        if (item.filhos.length > 0) {
          let children = fillAreas(item.filhos, item, true);
          arr = arr.concat(children);
        }
      }
    });
    return arr;
  }

  React.useEffect(() => {
    if (gridExport === true) {
      gridInstance.current.excelExport();
      gridExportChange(false);
    }

  }, [gridExport]);

  const [filterUserName, setFilterUserName] = React.useState(null);

  function _onChange(e) {
    if (e && e.target && e.target.value) {
      setFilterUserName(e.target.value);
    }
    else {
      setFilterUserName('');
    }
  }

  React.useEffect(() => {
    if (filterUserName === '') {
      setFillUsersDataFull(fillData);
      gridInstance.current.dataSource = fillData;
    }
  }, [filterUserName]);

  function onSearchClick() {

    if (filterUserName || (filters && !!filters.length)) {
      let filtered = !filterUserName ? fillData : fillData.filter((item) => item.nome.toLowerCase().indexOf(filterUserName.toLowerCase()) > -1);

      if (filters && !!filters.length) {
        filters.map((f) => {
          if (f.value && !!f.value.length) {
            if (f.key === "idAccessArea") {
              let currentFiltered = [];
              f.value.map((v) => {
                currentFiltered = currentFiltered.concat(filtered.filter((i) => i.permissions && i.permissions.filter((p) => p[f.key] === v).length > 0));
              });
              filtered = [...new Set(currentFiltered)];
            }
            else {
              f.value.map((v) => {
                filtered = typeof v === "number" ? filtered.filter((i) => i[f.key] === v) : filtered.filter((i) => i[f.key] && i[f.key].toLowerCase().indexOf(v ? v.toLowerCase() : "") > -1);
              });

              if (!f.value[0] && f.key === "cargo") {
                filtered = fillData;
              }
            }
          }
        });
      }

      filtered = [...new Set(filtered)];
      const users = filtered.filter(user => {
        user.ultimoLogin = user.ultimoLogin && new Date(user.ultimoLogin);
        return user;
      })
      setFillUsersDataFull(users);
      gridInstance.current.dataSource = users;
      setIsVisible(false);
    }
    else {
      if (fillData.length > 0) {
        setFillUsersDataFull(fillData);
        setIsVisible(false);
      }
    }
  }

  React.useEffect(() => {
    if (gridInstance && gridInstance.current && fillUsersDataFull && !isLoading) {
      gridInstance.current.refresh();
    }
  }, [fillUsersDataFull]);

  const onFiltering = (e) => {
    let query = new Query();
    query = (e.text !== '') ? query.where('nome', 'contains', e.text, true) : query;
    e.updateData(this.searchData, query);
  };

  React.useEffect(() => {
    if (gridInstance && gridInstance.current)
      gridInstance.current.refresh();
  }, [i18n.language]);

  const getValue = () => {
    const item = filters.find(item => item.key === "idAccessArea");

    if (item) {
      return item.value;
    }

    return "";
  };

  let userStatus = () => {
    return (<span className="sim">{i18n.language ==='pt' ? 'Ativo' : 'Active'}</span>)
  }

  return (
    <>
      <div className={`busca ${isVisible ? "aberto" : ""}`}>
        {!isLoading &&
          <div className="filtroUsuarios d-flex">
            <div className="SearchTitle">
              <span className="mdl2 mdl2-search"></span>
              <p>{t('Search:title')}</p>
            </div>
            <div className="searchUsers">
              <div className="e-input-in-wrap">
                <TextBoxComponent onChange={_onChange} id="nome" className="e-input" placeholder={t('Users:search_placeholder')} />
              </div>
            </div>
            <div className="btnBusca d-flex">
              {!isVisible &&
                <div className="btnFilter">
                  <ButtonComponent className="e-outline filter" cssClass='e-primary filter' onClick={() => setIsVisible(!isVisible)}><span className="mdl2 mdl2-filter"></span>{t('FilterButtons:filter')}</ButtonComponent>
                </div>
              }
              {isVisible &&
                <div className="btnFilter">
                  <ButtonComponent className="e-outline filter active-filter" cssClass='e-primary filter' onClick={() => setIsVisible(!isVisible)}><span className="mdl2 mdl2-filter"></span>{t('FilterButtons:filter')}</ButtonComponent>
                </div>
              }
              <div className="btnSearch">
                <ButtonComponent onClick={onSearchClick} cssClass='e-primary search'>{t('FilterButtons:search')}</ButtonComponent>
              </div>
            </div>
          </div>}
        {(isVisible && !isLoading) &&
          <div className="filtrosOcultos seis d-flex">
            <DropDownListComponent
              ref={superiorDiretoRef}
              id="idSuperiorDireto"
              cssClass="e-filled"
              placeholder={t('ProfData:immediate_leader')}
              dataSource={fillUsersData || []}
              created={() => setPersistence(true)}
              enablePersistence={persistence}
              fields={{ text: "nome", value: "id" }}
              change={onChange}
              filtering={onFiltering}
              showClearButton
            />
            <DropDownTreeComponent
              id="idAccessArea"
              ref={areaRef}
              cssClass="e-filled"
              fields={{ dataSource: fillAccessAreasData, value: "value", text: "text", parentValue: "parentValue", hasChildren: "hasChildren" }}
              showCheckBox={true}
              treeSettings={{ autoCheck: true }}
              placeholder={t('ComboPlaceHolder:access_area')}
              change={onChange}
              value={getValue()}
              showDropDownIcon={true}
              mode="Delimiter"
            />
            <TextBoxComponent
              id="email"
              cssClass="e-filled"
              ref={emailRef}
              placeholder={t('UserVale:email')}
              enablePersistence={persistence}
              blur={(e) => onTextBlur(e, "email")}
            />
            <DropDownListComponent
              id="idPerfilArea"
              cssClass="e-filled"
              ref={areaNegocioRef}
              placeholder={t('ProfData:business_area')}
              enablePersistence={persistence}
              dataSource={fillBusinessAreaData}
              fields={{ text: i18n.language === "pt" ? "nomePt" : "nomeEn", value: "id" }}
              change={onChange}
              showClearButton
            />
            <DropDownListComponent
              id="idPerfilDepartamento"
              ref={departamentoRef}
              cssClass="e-filled"
              placeholder={t("UserVale:department")}
              value=""
              enablePersistence={persistence}
              dataSource={fillDepartamentData}
              fields={{ text: (i18n.language) === "pt" ? "nomePt" : "nomeEn", value: "id" }}
              change={onChange}
              showClearButton
            />
            <DropDownListComponent
              id="idPerfilGerencia"
              cssClass="e-filled"
              ref={gerenciaRef}
              placeholder={t("UserVale:management")}
              enablePersistence={persistence}
              dataSource={fillManagementData}
              fields={{ text: (i18n.language) === "pt" ? "nomePt" : "nomeEn", value: "id" }}
              change={onChange}
              showClearButton
            />
            <TextBoxComponent
              id="cargo"
              cssClass="e-filled"
              ref={cargoRef}
              placeholder={t("UserVale:position")}
              enablePersistence={persistence}
              blur={(e) => onTextBlur(e, "cargo")}
            />
            <DropDownListComponent
              id="idPais"
              cssClass="e-filled"
              ref={paisRef}
              placeholder={t('UserVale:country')}
              allowFiltering={true}
              dataSource={fillCountriesData}
              enablePersistence={persistence}
              fields={{ text: (i18n.language === "pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
              change={onChange}
              showClearButton
            />
            <DropDownListComponent
              id="idRegional"
              cssClass="e-filled"
              ref={regionalRef}
              placeholder={t('UserVale:regional')}
              created={() => setPersistence(true)}
              enablePersistence={persistence}
              showClearButton={true}
              dataSource={fillRegionalData}
              fields={{ text: (i18n.language === "pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
              change={onChange}
            />
            <DropDownListComponent
              id="idTipoUsuario"
              cssClass="e-filled"
              ref={tipoUsuarioRef}
              placeholder={t('ComboPlaceHolder:user_type')}
              dataSource={fillUserTypesData}
              enablePersistence={persistence}
              fields={{ text: (i18n.language === "pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
              change={onChange}
              showClearButton
            />
            <DropDownListComponent
              id="idFornecedor"
              cssClass="e-filled"
              ref={fornecedorRef}
              placeholder={t('UserVale:supplier')}
              dataSource={fillSupplierDescriptionsData}
              enablePersistence={persistence}
              fields={{ text: 'nome', value: 'id' }}
              change={onChange}
              showClearButton
            />
          </div>
        }
      </div>
      {
        isLoading ? <div id="loadUsersList" />
          :
          <div className="listaUsuarios">
            <GridComponent
              id='gridcomp'
              dataSource={fillUsersDataFull}
              filterSettings={{ type: 'Menu' }}
              allowFiltering={false}
              allowPaging={true}
              allowSorting={true}
              allowExcelExport={true}
              ref={gridInstance}
              locale={i18n.language}>
              <ColumnsDirective>
                <ColumnDirective
                  field='nome'
                  template={usersName}
                  headerText={t('UserList:name')}
                  width='250'>
                </ColumnDirective>
                <ColumnDirective
                  field='email'
                  headerText={t('UserList:email')}
                  width='200'>
                </ColumnDirective>
                <ColumnDirective
                  field={i18n.language === "pt" ? 'nomePaisPT' : 'nomePaisEN'}
                  headerText={t('UserList:pais')}
                  width='100'>
                </ColumnDirective>
                <ColumnDirective
                  field={i18n.language === "pt" ? "nomeRegionalPT" : "nomeRegionalEN"}
                  headerText={t('UserList:regional')}
                  width='250'>
                </ColumnDirective>
                <ColumnDirective
                  field='ultimoLogin'
                  headerText={t('UserList:last_login')}
                  format={i18n.language === "pt" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                  width='150'>
                </ColumnDirective>
                <ColumnDirective
                  field={i18n.language === "pt" ? "tipoUsuarioPT" : "tipoUsuarioEN"}
                  headerText={t('UserList:type')}
                  width='100'>
                </ColumnDirective>
                <ColumnDirective
                  field={"ativo"}
                  headerText={t('UserList:status')}
                  template={userStatus}
                  width='150'>
                </ColumnDirective>
              </ColumnsDirective>
              <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Edit, ForeignKey, Toolbar, ExcelExport]} />
            </GridComponent>
          </div>
      }
    </>
  );
}
