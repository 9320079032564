import * as React from "react";

import { Buttons } from "../utilities/Buttons";
import { Redirect } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from "../general/GlobalState";
import { GridComponent, ColumnsDirective, ColumnDirective, ForeignKey, Filter, Inject, Sort, ContextMenu, Page, Resize, Edit, Toolbar, ExcelExport } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { getSignatureItems } from "../../services/SignatureSVC";
import { getRegional } from "../../services/SystemSVC";
import { createSpinner, showSpinner, setSpinner, hideSpinner } from "@syncfusion/ej2-react-popups";
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";

export const Signature = () => {
  const [signatureItems, setSignatureItems] = React.useState([]);
  const [loadingSignature, setLoadingSignature] = React.useState(true);
  const [gridInstance, setGridInstance] = React.useState(null);
  const [globalState] = React.useContext(GlobalStateContext);
  const [redirect, setRedirect] = React.useState(false);
  const [fillRegionalData, setFillRegionalData] = React.useState([]);
  const [idSignature, setIdSignature] = React.useState(null);
  const [regionalFilter, setRegionalFilter] = React.useState(null);
  const [signatureFilter, setSignatureFilter] = React.useState([]);

  let regionalData = React.useRef();

  let signatureRegional = (args) => {
    return (
      <span className="title" onClick={() => editSignature(args)}>{
        i18n.language === "pt"
          ? args?.regional?.nomePt
          : args?.regional?.nomeEn
      }</span>
    )
  }

  let signatureTitle = (args) => {
    return (<span>{i18n.language === 'pt' ? args.tituloPT : args.tituloEN}</span>)
  }

  let userPermission;

  const { t } = useTranslation();

  const inputChange = (obj) => {
    setRegionalFilter(obj.value);
  }

  const filteredSignature = () => {
    let filteredItems = [...signatureItems];
    filteredItems = filteredItems?.filter(x => {

      const lowerCaseAndIncludes = (element, paramIncludes) => {
        return element.toLowerCase().includes(paramIncludes.toLowerCase());
      }

      return lowerCaseAndIncludes(x.regional.nomeEn, regionalFilter)
        ? x.regional.nomeEn.toLowerCase()
        : lowerCaseAndIncludes(x.regional.nomePt, regionalFilter)
        && x.regional.nomePt.toLowerCase()
    });

    setSignatureFilter(filteredItems)
  }

  React.useEffect(() => {
    async function load() {

      let signatures = await getSignatureItems(globalState.accessToken).then(response => {
        if (response.ok) {
          return response.json();
        }
      })

      setSignatureItems(signatures);
      setSignatureFilter(signatures);

      let _regional = await getRegional(globalState.accessToken)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        });
      setFillRegionalData(_regional);
      regionalData.current = _regional;
      if (gridInstance) {
        gridInstance.refresh();
      }
    }

    load();
  }, [i18n.language]);

  React.useEffect(() => {
    if (signatureItems && document.getElementById('loadSignature')) {
      hideSpinner(document.getElementById('loadSignature'));
      setLoadingSignature(false);
    }
  }, [signatureItems]);

  React.useEffect(() => {
    if (loadingSignature && document.getElementById('loadSignature')) {
      createSpinner({ target: document.getElementById('loadSignature') })
      showSpinner(document.getElementById('loadSignature'));
      setSpinner({ type: 'Bootstrap' });
    }
  }, [loadingSignature]);

  React.useEffect(() => {
    let globalPermissions = globalState.permissions;
    userPermission = globalPermissions.filter((i) => i.accessArea === "Signature").map((i) => (i.permission));
  }, [globalState]);

  React.useEffect(() => {
    if (gridInstance)
      gridInstance.refresh();
  }, [i18n.language, fillRegionalData]);

  const _controls = {
    permit: userPermission ? userPermission : null,
    import: "",
    new: () => setRedirect(true),
    back: "",
  };

  const editSignature = (info) => {
    setIdSignature(info.id);
    setRedirect(true);
  }
  return (
    <>
      {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
        redirect ? (
          idSignature === null
            ?
            <Redirect push={true} to='/admin/signatureItem' />
            :
            <Redirect push={true} to={`/admin/signatureItem/${idSignature}`} />
        ) : (
          <>
            <PermissionRedirect id="64" />
            <div className="DefaultTop" />
            <Container className="containerTop">
              <div className="topTitleButtons">
                <Row>
                  <div className="col-sm-8 col-12">
                    <div className="title">
                      <h1>{t('Signature:titleName')}</h1>
                    </div>
                  </div>
                  <div className="col-sm-4 col-12">
                    <div className="buttons">
                      <Buttons controls={_controls} />
                    </div>
                  </div>
                </Row>
              </div>

              <div
                className="busca aberto"
              >
                <div className="filtroUsuarios d-flex">
                  <div className="SearchTitle">
                    <span className="mdl2 mdl2-search"></span>
                    <p>{t('Releases:search_text')}</p>
                  </div>
                  <div className="searchUsers">
                    <div className="e-input-in-wrap">
                      <TextBoxComponent
                        className="e-input"
                        placeholder={t('Signature:placeholder_search')}
                        change={(e) => inputChange(e)}
                      />
                    </div>
                  </div>
                  <div className="btnBusca d-flex">
                    <div className="btnSearch">
                      <ButtonComponent cssClass='e-primary search'
                        onClick={filteredSignature}
                      >
                        {t('Releases:search')}
                      </ButtonComponent>
                    </div>
                  </div>
                </div>
              </div>
              {loadingSignature ? <div id="loadSignature" className="loadSignature" /> :
                <>
                  {signatureItems &&
                    <Row>
                      <div className="col-12">
                        <GridComponent
                          id='gridcomp'
                          dataSource={signatureFilter}
                          filterSettings={{ type: 'Menu' }}
                          allowFiltering={false}
                          allowPaging={true}
                          allowSorting={true}
                          allowExcelExport={true}
                          ref={(grid) => setGridInstance(grid)}
                          locale={i18n.language}
                        >
                          <ColumnsDirective>
                            <ColumnDirective
                              field='regional'
                              template={(e) => signatureRegional(e, fillRegionalData)}
                              headerText={t('Signature:regional')}
                              width='33%'>
                            </ColumnDirective>
                            <ColumnDirective
                              field={`${i18n.language === "pt" ? "tituloPT" : "tituloEN"}`}
                              template={signatureTitle}
                              headerText={t('Signature:title')}
                              width='33%'>
                            </ColumnDirective>
                          </ColumnsDirective>
                          <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Edit, ForeignKey, Toolbar, ExcelExport]} />
                        </GridComponent>
                      </div>
                    </Row>
                  }
                </>
              }
            </Container>
          </>
        )}
    </>
  )
}
