import React, { useContext, useState, useEffect } from "react";
import { GlobalStateContext } from '../general/GlobalState'
import { useTranslation } from 'react-i18next';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Sort, ContextMenu, Page, Resize } from "@syncfusion/ej2-react-grids";
import { useContract } from './context/ContractsContext';
import { getContracts, getContractsHistory, deleteItem } from '../../services/ContractsSVC';
import { getSuppliers } from '../../services/SuppliersSVC';
import ModalComponent from './ModalComponent';
import { getDateTime, hasPermissions } from '../utilities/Utils';
import i18n from "i18next";
import { useModal } from "../../hooks/Modal";
import { setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { DialogComponent } from "@syncfusion/ej2-react-popups";

export const ContractContent = () => {

    const [globalState] = useContext(GlobalStateContext);

    const [historyContracts, setHistoryContracts] = useState([]);
    const [fullHistoryContracts, setFullHistoryContracts] = useState([]);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(-1);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);

    const { openEdit, contracts, setContracts, setSuppliers } = useContract();
    const { buildDialogMessage } = useModal();

    const { t } = useTranslation();

    let gridInstance;
    let gridHistory;

    useEffect(() => {
        async function getItems() {
            if (hasPermissions(30, "", globalState)) {
                const contracts = await getContracts(globalState.accessToken).then(async res => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        buildDialogMessage({
                            error: res.status,
                        });
                    }
                });

                const contractsFiltered = contracts && contracts.length > 0 && contracts.filter(item => {
                    item.inicioVigencia = new Date(item.inicioVigencia);
                    item.fimVigencia = new Date(item.fimVigencia);
                    return item;
                });

                const suppliers = await getSuppliers(globalState.accessToken).then(async res => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        let errorMessage = {};

                        errorMessage.messagePt = "Erro ao fazer requisição"
                        errorMessage.messageEn = "Error while processing your request"

                        buildDialogMessage({
                            error: true,
                            titlePt: "Um erro ocorreu",
                            titleEn: "An error has ocurred",
                            messagePt: `${errorMessage.messagePt}`,
                            messageEn: `${errorMessage.messageEn}`
                        });
                    }
                });

                if (suppliers) {
                    setSuppliers(suppliers);
                }
                if (contractsFiltered) {
                    setContracts(contractsFiltered);
                }
            }
        }
        getItems();
    }, []);

    const contextMenuItems = ['SortAscending', 'SortDescending', 'FirstPage', 'PrevPage', 'LastPage', 'NextPage'];
    const editOptions = { allowEditing: true, allowAdding: true, allowDeleting: true };
    const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];

    const contratoTemplate = (args) => {
        return (
            <>
                {args.contrato &&
                    <span className="contrato">{args.contrato}</span>
                }
            </>
        );
    }

    const dateAndTime = args => {
        return (
            <>
                {
                    args.data && <span>{new Date(args.data).toLocaleDateString(i18n.language)}</span>
                }
            </>
        )
    };

    const openDelete = (id) => {
        setOpenModalDelete(true);
        setDeleteId(id);
    };

    const editTemplate = (args) => {
        return (
            <div className="editItems">
                {hasPermissions(30, "Edit", globalState) && <span onClick={() => openEdit(args)} className="mdl2 mdl2-pencil" />}
                {hasPermissions(30, "Delete", globalState) && <span onClick={() => openDelete(args.id)} className="mdl2 mdl2-trash" />}
            </div>
        );
    }

    const getCurrentHistory = async (id) => {
        const contractsHistory = await getContractsHistory(globalState.accessToken, id).then(async res => {
            if (res.ok) {
                return res.json();
            } else {
                buildDialogMessage({
                    error: res.ok,
                })
            }
        });

        const contracts = [...contractsHistory];

        contracts.filter(agreementHistory => {

        })

        setFullHistoryContracts(contractsHistory);
        setHistoryContracts(contracts);
    }

    const confirmDelete = async () => {
        await deleteItem(globalState.accessToken, deleteId);

        const newContracts = [...contracts];
        const index = newContracts.findIndex(contract => contract.id ===deleteId);

        newContracts.splice(index, 1);

        setContracts(newContracts);

        setOpenModalConfirm(true);
    }

    const finalTemplate = (agreementHistory) => {
        switch (agreementHistory.tipoDado) {
            case "Date": {
                return <span>{new Date(agreementHistory.dadoFinal).toLocaleDateString()}</span>
            }
            case "Decimal": {
                return <span>{Intl.NumberFormat("pt", {
                    style: 'currency',
                    currency: 'BRL',
                }).format(agreementHistory.dadoFinal)}</span>
            }
            default: {
                return <span>{agreementHistory.dadoFinal}</span>
            }
        }
    }

    const initialTemplate = (agreementHistory) => {
        switch (agreementHistory.tipoDado) {
            case "Date": {
                return <span>{new Date(agreementHistory.dadoInicial).toLocaleDateString()}</span>
            }
            case "Decimal": {
                return <span>{Intl.NumberFormat("pt", {
                    style: 'currency',
                    currency: 'BRL',
                }).format(agreementHistory.dadoInicial)}</span>
            }
            default: {
                return <span>{agreementHistory.dadoInicial}</span>
            }
        }
    };

    const closeDeleteModal = () => {
        setOpenModalDelete(false);
        setOpenModalConfirm(false);
    };

    React.useEffect(() => {
        setCurrencyCode("BRL");
        if (gridInstance)
            gridInstance.refresh();
        if (gridHistory)
            gridHistory.refresh();
    }, [i18n.language]);

    const format = ""

    return (
        <div id="Contracts">
            {/* <div className="topContent">
                <span className="mdl2 mdl2-money" />
                <label>{i18n.language === 'pt'? "Contratos" : 'Contracts' }</label>
            </div> */}
            <GridComponent
                id="managementContract"
                className="withoutTopContent"
                dataSource={contracts}
                editSettings={editOptions}
                contextMenuItems={contextMenuItems}
                toolbar={toolbarOptions}
                rowSelected={(row) => getCurrentHistory(row.data.id)}
                filterSettings={{ type: 'CheckBox' }}
                allowPaging={true}
                allowFiltering={true}
                allowSorting={true}
                locale={i18n.language}
                ref={grid => gridInstance = grid}
            >
                <ColumnsDirective>
                    <ColumnDirective headerText={t('ManageContracts:column_agreement')} template={(args) => contratoTemplate(args)} field="contrato" width="182"></ColumnDirective>
                    <ColumnDirective headerText={t('ManageContracts:column_agency')} field="agencia.nome" width="182"></ColumnDirective>
                    <ColumnDirective
                        format={i18n.language ==="pt" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                        headerText={t('ManageContracts:column_start')}
                        field="inicioVigencia"
                        width="182"></ColumnDirective>
                    <ColumnDirective
                        format={i18n.language ==="pt" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                        headerText={t('ManageContracts:column_end')}
                        field="fimVigencia"
                        width="182"
                    ></ColumnDirective>
                    <ColumnDirective
                        headerText={t('ManageContracts:column_value')}
                        field="valor"
                        type="number"
                        format={{
                            currency: "BRL",
                            format: "c2"
                        }}
                        filter={{ type: 'Menu' }}
                        width="182"
                    ></ColumnDirective>
                    <ColumnDirective
                        headerText={t('ManageContracts:column_balance')}
                        field="saldo"
                        type="number"
                        filter={{ type: 'Menu' }}
                        format={{
                            currency: "BRL",
                            format: "c2"
                        }}
                        width="182">
                    </ColumnDirective>
                    <ColumnDirective template={(args) => editTemplate(args)} width="82"></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Resize, Sort, ContextMenu, Filter, Page]} />
            </GridComponent >

            <div className="historicDiv">
                <span className="mdl2 mdl2-historic" />
                <h2>{i18n.language ==="pt" ? "Histórico de alterações" : "Change history"}</h2>
            </div>

            <GridComponent
                dataSource={historyContracts}
                editSettings={editOptions}
                contextMenuItems={contextMenuItems}
                toolbar={toolbarOptions}
                filterSettings={{ type: 'Menu' }}
                allowPaging={true}
                allowFiltering={true}
                allowSorting={true}
                locale={i18n.language}
                ref={grid => gridHistory = grid}
            >
                <ColumnsDirective>
                    <ColumnDirective headerText={t('ManageContracts:column_name')} field="usuarioAlteracao.nome" width="180"></ColumnDirective>
                    <ColumnDirective headerText={t('ManageContracts:column_email')} field="usuarioAlteracao.email" width="180"></ColumnDirective>
                    <ColumnDirective template={(args) => dateAndTime(args)} headerText={t('ManageContracts:column_date')} field="data" width="180"></ColumnDirective>
                    <ColumnDirective headerText={t('ManageContracts:column_action')} field="tipoAcao.nomePt" width="180"></ColumnDirective>
                    <ColumnDirective headerText={t('ManageContracts:column_field')} field="campoPt" width="180"></ColumnDirective>
                    <ColumnDirective template={initialTemplate} headerText={t('ManageContracts:column_initialData')} field="dadoInicial" width="180"></ColumnDirective>
                    <ColumnDirective template={finalTemplate} headerText={t('ManageContracts:column_finalData')} field="dadoFinal" width="180"></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Resize, Sort, ContextMenu, Filter, Page]} />
            </GridComponent >

            <ModalComponent />

            <DialogComponent
                id="deleteModal"
                isModal={true}
                visible={openModalDelete}
                locale={i18n.language}
            >
                <div>
                    <div className="divTrash">
                        <label className="mdl2 mdl2-trash"></label>
                        <div className="phraseDelete">
                            {
                                openModalConfirm ?
                                    <label className="labelModal delete">{t("Actions:delete")}</label>
                                    :
                                    <label id="labelDelete">{t("Actions:confirmDeleteMsg")}</label>
                            }
                        </div>
                    </div>
                    <hr />
                    {!openModalConfirm ?
                        <div className="footerButtons">
                            <button onClick={() => setOpenModalDelete(false)}>{t("Actions:cancel")}</button>
                            <button onClick={confirmDelete}>{t("Actions:confirm")}</button>
                        </div>
                        :
                        <div className="deleteComplete">
                            <div className="confirmTrash">
                                <span className="success mdl2 mdl2-trash"></span>
                            </div>
                            <label>{t("DigitalMedia:deletedItem")}</label>
                            <button onClick={() => closeDeleteModal()}>{t("Actions:exit")}</button>
                        </div>
                    }
                </div >
            </DialogComponent>
        </div >
    );
}