import * as React from 'react';
import { GlobalStateContext } from '../general/GlobalState';
import { DataContent } from './DataContent';
import { CostContent } from './CostContent';
import { FileContent } from './FileContent';
import { ControlContent } from './ControlContent';
import { HistoricContent } from './HistoricContent';

import { Actions } from '../utilities/Actions';
import { getSuppliers } from '../../services/SuppliersSVC';
import { AdvertisingBudgetContext } from './AdvertisingBudgetContext';
import {
  getAdvertisingBudgetDataTypes,
  getAdvertisingBudgetDataStatus,
  addAdvertisingBudget,
  getAdvertisingBudgetItemByID,
  getActiveContractInPeriodItems,
  getManageInternalAreas,
  getManageAnnualBudget,
  getAllContractItems,
  updateAdvertisingBudget
} from './AdvertisingBudgetSVC';
import i18n from 'i18next';
import { hasPermissions, uuidv4 } from '../utilities/Utils';
import { Redirect } from 'react-router-dom';
import { getMediaTypes } from '../../services/SystemSVC';
import { useTranslation } from 'react-i18next';
import GenericModal from '../utilities/MessageModal/GenericModal';
import {
  DialogComponent,
  createSpinner,
  showSpinner,
  setSpinner,
} from '@syncfusion/ej2-react-popups';

export const FormAdvertisingBudget = ({ IDAdBudget }) => {
  const [globalState] = React.useContext(GlobalStateContext);
  const [tempGuid, setTempGuid] = React.useState('');
  const [options, setOptions] = React.useState({
    agencia: [],
    areas: [],
    anos: [],
    tipos: [],
    status: [],
    tiposMidia: [],
    contratos: [],
    contratosAll: []
  });
  const [optionsComplete, setOptionsComplete] = React.useState({
    agencia: [],
    areas: [],
    anos: [],
    tipos: [],
    status: [],
    tiposMidia: [],
    contratos: [],
    contratosAll: []
  });
  const { t } = useTranslation();
  const [dataIsValidToInsert, setDataIsValidToInsert] = React.useState(false);
  const [modalMessageParams, setmodalMessageParams] = React.useState({
    show: false,
  });
  const [userPermission, setUserPermission] = React.useState([]);
  const [redirect, setRedirect] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const [
    globalAdBudget,
    setGlobalAdBudget,
    resetglobalAdBudget,
  ] = React.useContext(AdvertisingBudgetContext);
  const gridHistoricInstance = React.useRef(null);
  React.useEffect(() => {
    const abortController = new AbortController()
    getAllDropDownOptions();
    const guid = uuidv4();
    setTempGuid(guid);
    return () => {
      abortController.abort()
    }
  }, []);

  React.useEffect(() => {
    if (IDAdBudget) {
      setloading(true)
      initItem();
    }
  }, [IDAdBudget]);


  React.useEffect(() => {
    if (loading) {
      createSpinner({ target: document.getElementById('loadingItem') });
      showSpinner(document.getElementById('loadingItem'));
      setSpinner({ type: 'Bootstrap' });
    }
  }, [loading]);


  React.useEffect(() => {
    if (gridHistoricInstance && gridHistoricInstance.current) {
      gridHistoricInstance.current.dataSource = globalAdBudget.historico
      gridHistoricInstance.current.refresh()
    }
  }, [globalAdBudget]);


  React.useEffect(() => {
    if (optionsComplete.status.length > 0 && optionsComplete.tipos.length > 0 && optionsComplete.tiposMidia.length > 0) {
      const newOptions = {
        ...options,
        "status": buildDropDownItems(optionsComplete.status, { textKey: i18n.language ==='pt' ? 'nomePt' : 'nomeEn', value: 'id' }),
        "tipos": buildDropDownItems(optionsComplete.tipos, { textKey: i18n.language ==='pt' ? 'nomePt' : 'nomeEn', value: 'id' }),
        "tiposMidia": buildDropDownItems(optionsComplete.tiposMidia, { textKey: i18n.language ==='pt' ? 'nomePt' : 'nomeEn', value: 'id' })
      }
      setOptions(newOptions);
      setGlobalAdBudget(prevstate =>
      ({
        ...prevstate,
        historico: historicDataFormatByType(prevstate, newOptions)
      }));
    }

  }, [i18n.language]);

  React.useEffect(() => {
    if (dataIsValidToInsert && !IDAdBudget) {
      createAdBudgetItem();
    } else if (dataIsValidToInsert && IDAdBudget) {
      updateItemAdBudget()
    }
  }, [dataIsValidToInsert]);

  React.useEffect(() => {
    if (options && options.status && options.status.length > 0 &&
      options.tipos && options.tipos.length > 0 &&
      options.tiposMidia && options.tiposMidia.length > 0 &&
      globalAdBudget && globalAdBudget.historico && globalAdBudget.historico.length > 0 &&
      globalAdBudget.historicoTransformed ===false
    ) {
      setGlobalAdBudget(prevstate => {
        return {
          ...prevstate,
          historicoTransformed: true,
          historico: historicDataFormatByType(prevstate, options)
        }
      })
    }
  }, [options, globalAdBudget]);



  const historicDataFormatByType = (jsonItem, arrOptions) => {

    const convertData = (agreementHistory, value2, arrOptions) => {
      const value = agreementHistory[value2] ? agreementHistory[value2] : ''
      switch (agreementHistory.tipoDado) {
        case "Date": {
          return new Date(value).toLocaleDateString(i18n.language)
        }
        case "Decimal": {
          return Intl.NumberFormat(i18n.language, {
            style: 'currency',
            currency: 'BRL',
          }).format(value)
        }
        case "AdvertisingBudgetDataStatus": {
          let textValue = arrOptions.status ?
            arrOptions.status.filter(item => item.value ===value) : []
          textValue = textValue.length > 0 ? textValue[0].text : value
          return textValue
        }
        case "AdvertisingBudgetDataType": {
          let textValue = arrOptions.tiposMidia ?
            arrOptions.tiposMidia.filter(item => item.value ===value) : []
          textValue = textValue.length > 0 ? textValue[0].text : value
          return textValue
        }
        case "AdvertisingBudgetAttachmentStatus": {
          let textValue = arrOptions.status ?
            arrOptions.status.filter(item => item.value ===value) : []
          textValue = textValue.length > 0 ? textValue[0].text : value
          return textValue
        }
        case "AdvertisingBudgetMediaType": {
          let textValue = arrOptions.tiposMidia ?
            arrOptions.tiposMidia.filter(item => item.value ===value) : []
          textValue = textValue.length > 0 ? textValue[0].text : value
          return textValue
        }

        case "AdvertisingBudgetAttachmentType": {
          let textValue = arrOptions.tipos ?
            arrOptions.tipos.filter(item => item.value ===value) : []
          textValue = textValue.length > 0 ? textValue[0].text : value
          return textValue
        }
        case "Boolean": {
          return i18n.language ==='pt' ? value : value ==="Sim" ? 'Yes' : "No"
        }
        default: {
          return value
        }
      }
    };
    jsonItem.historico = jsonItem.historico.map(item => {
      return {
        ...item,
        campoEn: item.campoEn,
        campoPt: item.campoPt,
        dadoFinal: item.dadoFinal,
        dadoInicial: item.dadoInicial,
        dadoFinalNEW: convertData(item, 'dadoFinal', arrOptions),
        dadoInicialNEW: convertData(item, 'dadoInicial', arrOptions),
        data: new Date(item.data),
        id: item.id,
        tipoAcao: item.tipoAcao,
        tipoDado: item.tipoDado,
        usuarioAlteracao: item.usuarioAlteracao,
      }
    })
    return jsonItem.historico
  }

  const initItem = async () => {
    const convertAllArrayItemsDateStringToDate = (jsonItem) => {
      if (jsonItem.pagamentos) {
        jsonItem.pagamentos = jsonItem.pagamentos.map(item => {
          item.dataPagamento = !item.dataPagamento || item.dataPagamento === '0001-01-01T00:00:00' ?
            null : new Date(item.dataPagamento)
          return item
        })
      }
      if (jsonItem.anexos) {
        jsonItem.anexos = jsonItem.anexos.map(item => {
          item.aprovadoReprovadoEm = !item.aprovadoReprovadoEm || item.aprovadoReprovadoEm === '0001-01-01T00:00:00' ?
            null : new Date(item.aprovadoReprovadoEm)
          item.criadoEm = !item.criadoEm || item.criadoEm === '0001-01-01T00:00:00' ? null : new Date(item.criadoEm)
          return item
        })
      }
      return jsonItem
    }
    const response = await getAdvertisingBudgetItemByID(
      globalState.accessToken,
      IDAdBudget,
    );
    if (response.status ===200) {
      let jsonItem = await response.json();
      jsonItem = convertAllArrayItemsDateStringToDate(jsonItem)
      setGlobalAdBudget(prevstate => ({ ...prevstate, ...jsonItem }));
      console.log('jsonItem', jsonItem);
    } else {
      const msg = i18n.language === 'pt' ? "O Item não existe ou você não tem permissão para visualizá-lo" : "The Item does not exist or you do not have permission to view it"
      buildDialogMessage('custom-error', msg, () => { setRedirect(true) });
      console.log(response);
    }
    setloading(false)

  };


  const buildDropDownItems = (data, objMap) => {
    if (data.length > 0)
      return data.map((i) => {
        return {
          text: i[objMap.textKey],
          value: i[objMap.value],
        };
      });
    else
      return data
  }

  const getAllDropDownOptions = () => {

    const getDropDownOptions = async (fn, optionKey, objMap) => {
      let response = await fn(globalState.accessToken)
      response = await response.json()
      return { response, optionKey, objMap }
    }
    let promises = []

    promises.push(getDropDownOptions(getAdvertisingBudgetDataStatus, "status", { textKey: i18n.language ==='pt' ? 'nomePt' : 'nomeEn', value: 'id' }))
    promises.push(getDropDownOptions(getAdvertisingBudgetDataTypes, "tipos", { textKey: i18n.language ==='pt' ? 'nomePt' : 'nomeEn', value: 'id' }))
    promises.push(getDropDownOptions(getMediaTypes, "tiposMidia", { textKey: i18n.language ==='pt' ? 'nomePt' : 'nomeEn', value: 'id' }))
    promises.push(getDropDownOptions(getManageInternalAreas, "areas", { textKey: 'nome', value: 'id' }))
    promises.push(getDropDownOptions(getSuppliers, "agencia", { textKey: 'nome', value: 'id' }))
    promises.push(getDropDownOptions(getManageAnnualBudget, "anos", { textKey: 'ano', value: 'id' }))
    promises.push(getDropDownOptions(getActiveContractInPeriodItems, "contratos", { textKey: 'contrato', value: 'id' }))
    promises.push(getDropDownOptions(getAllContractItems, "contratosAll", { textKey: 'contrato', value: 'id' }))


    Promise.all(promises).then(async getResult => {
      try {
        setOptionsComplete((prevstate) => {
          const allResponse = getResult.map(item => ({ [item.optionKey]: item.response }))
          const obj = {}
          allResponse.forEach(item => { const objKey = Object.keys(item)[0]; obj[objKey] = item[objKey] })
          return obj
        });
        const obj = {}
        setOptions((prevstate) => {
          const allResponse = getResult.map(item =>
            ({ [item.optionKey]: buildDropDownItems(item.response, item.objMap) }))
          allResponse.forEach(item => { const objKey = Object.keys(item)[0]; obj[objKey] = item[objKey] })
          console.log('options', obj);
          return obj
        });
      } catch (error) {
        console.log(error);
      }
    }).catch(err => {
      console.log('Erro ao obter algum das dropdown options', err);
    })
  };

  const getItemDataToInsert = () => {
    const newItem = globalAdBudget;

    if (!IDAdBudget || !newItem.tempGuid) {
      newItem.tempGuid = tempGuid;
    }
    if (!IDAdBudget) {
      delete newItem.id
    }


    // remove guid caso exista
    newItem.anexos = newItem.anexos.map((data) => {
      if (isNaN(data.id))
        delete data.id
      else
        data.id = parseInt(data.id)
      return data
    });
    // remove guid caso exista
    newItem.custos = newItem.custos.map((data) => {
      if (isNaN(data.id))
        delete data.id
      else
        data.id = parseInt(data.id)
      return data
    });

    // remove guid caso exista
    newItem.pagamentos = newItem.pagamentos.map((data) => {
      if (isNaN(data.id))
        delete data.id
      else
        data.id = parseInt(data.id)
      return data
    });

    !hasPermissions(32, "Admin", globalState) && delete newItem.pagamentos
    delete newItem.historico
    delete newItem.historicoTransformed
    return newItem;
  };

  const buildDialogMessage = (type, msg, callBack) => {
    if (type ==='success') {
      setmodalMessageParams({
        title: t('ModalMessages:title'),
        message: t('ModalMessages:created'),
        type: 'success',
        icon: 'mdl2-confirm',
        show: true,
        exitCallback: callBack
      });
    } else if (type ==='error') {
      setmodalMessageParams({
        title: t('ManageInternalAreas:error_title'),
        message: t('ManageInternalAreas:error'),
        type: 'error',
        icon: 'e-icon-dlg-close e-icons',
        show: true,
      });
    } else if (type ==='updated') {
      setmodalMessageParams({
        title: t('ModalMessages:title'),
        message: t('ModalMessages:updated'),
        type: 'success',
        icon: 'mdl2-confirm',
        show: true,
        exitCallback: callBack
      });
    } else if (type ==='custom-error') {
      setmodalMessageParams({
        title: t('ManageInternalAreas:error_title'),
        message: msg,
        type: 'error',
        icon: 'e-icon-dlg-close e-icons',
        show: true,
        exitCallback: callBack
      });
    }
  };

  const createAdBudgetItem = async () => {
    setDisabledBtn(true)
    setloading(true)
    const item = getItemDataToInsert();
    const result = await addAdvertisingBudget(globalState.accessToken, item);
    if (result.ok) {
      setloading(false)
      resetglobalAdBudget();
      console.log('item added', item);
      setDataIsValidToInsert(false);
      buildDialogMessage('success', '', () => { setRedirect(true) });
      setDisabledBtn(false);
    } else {
      setloading(false)
      buildDialogMessage('error');
      const err = await result.json();
      console.log(err);
      buildDialogMessage('custom-error', JSON.stringify(err.errors ? err.errors :
        `Ocorreu um erro no sistema. Favor entrar em contato com o administrador - OperationId: ${err.OperationId}`), () => { setRedirect(true) });
      setDisabledBtn(false);
    }
  };

  const updateItemAdBudget = async () => {
    setDisabledBtn(true)
    setloading(true)
    const item = getItemDataToInsert();
    const result = await updateAdvertisingBudget(globalState.accessToken, item);
    if (result.ok) {
      setloading(false)
      console.log('item updated', item);
      setDataIsValidToInsert(false);
      buildDialogMessage('updated', '', () => { setRedirect(true) });
      setDisabledBtn(false);
    } else {
      setloading(false)
      buildDialogMessage('error');
      const err = await result.json();
      console.log(err);
      buildDialogMessage('custom-error',
        JSON.stringify(err.errors ? err.errors :
          `Ocorreu um erro no sistema. Favor entrar em contato com o administrador - OperationId: ${err.OperationId}`),
        () => { setRedirect(true) }
      );
      setDisabledBtn(false);
    }
  }

  React.useEffect(() => {
    let globalPermissions = globalState.permissions;
    setUserPermission(globalPermissions.filter((i) => i.idAreaAcesso === 32).map((i) => (i.permission)));
  }, [globalState]);


  const _controls = {
    class: 'branding',
    form: 'data-form',
    customPermissions: IDAdBudget ? (hasPermissions(32, "Edit", globalState) || globalState.fornecedor) : hasPermissions(32, "Create", globalState),
    savePrimary: false,
    urlSave: () => { },
    urlCancel: () => {
      if (window.confirm('Tem certeza que deseja sair? Todos os dados não enviados serão perdidos')) {
        setRedirect(true)
      }
    },
  };

  return (
    <>
      {redirect ? (
        <Redirect push={true} to={`/brandingadvertising/advertisingbudget`} />
      ) : (
        <>
          {loading && <div style={{ zIndex: '99999' }} id="loadingItem" />}
          <GenericModal params={modalMessageParams} />

          <div className="col-lg-9 col-12">
            <div id="FormAdvertisingBudget">
              {options && <DataContent options={options} setDataIsValidToInsert={setDataIsValidToInsert} />}
              {(globalAdBudget.id || IDAdBudget ===null) && tempGuid && <FileContent options={options} IDAdBudget={IDAdBudget} tempGuid={tempGuid} />}
              {(globalAdBudget.id || IDAdBudget ===null) && <CostContent data={globalAdBudget.custos} options={options} IDAdBudget={IDAdBudget} />}
              {IDAdBudget && hasPermissions(32, "Edit", globalState) && <ControlContent data={globalAdBudget.pagamentos} options={options} IDAdBudget={IDAdBudget} />}
              {IDAdBudget && hasPermissions(32, "Edit", globalState) && <HistoricContent options={optionsComplete} gridHistoricInstance={gridHistoricInstance} />}
            </div>
          </div>

          <div className="col-lg-3 col-12">
            <Actions disabled={disabledBtn} controls={_controls} />
          </div>

        </>
      )}
    </>
  );
};
