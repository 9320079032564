import * as React from 'react';

let numberStep = () => {
    let url = window.location.search;
    if (url.indexOf('_token') > -1) {
        return 3;
    } else {
        return 1;
    }
}

const initialState = {
    step: numberStep()
};
const LoginStateContext = React.createContext(initialState);

const LoginStateProvider = ({ children }) => {
    const [loginState, setLoginState] = React.useState(initialState);
    const [redirectMessage, setRedirectMessage] = React.useState("");

    return (
        <LoginStateContext.Provider value={[loginState, setLoginState, redirectMessage, setRedirectMessage]}>
            {children}
        </LoginStateContext.Provider>
    );
};

export { LoginStateContext, LoginStateProvider };