export const countriesJSON = [
    {
        "nameEn": "Afghanistan",
        "namePt": "Afeganistão",
        "dialCode": "+93",
        "isoCode": "AF",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/af.svg`
    },
    {
        "nameEn": "Aland Islands",
        "namePt": "Ilhas Aland",
        "dialCode": "+358",
        "isoCode": "AX",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ax.svg`
    },
    {
        "nameEn": "Albania",
        "namePt": "Albânia",
        "dialCode": "+355",
        "isoCode": "AL",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/al.svg`
    },
    {
        "nameEn": "Algeria",
        "namePt": "Argélia",
        "dialCode": "+213",
        "isoCode": "DZ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/dz.svg`
    },
    {
        "nameEn": "AmericanSamoa",
        "namePt": "Samoa Americana",
        "dialCode": "+1684",
        "isoCode": "AS",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/as.svg`
    },
    {
        "nameEn": "Andorra",
        "namePt": "Andorra",
        "dialCode": "+376",
        "isoCode": "AD",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ad.svg`
    },
    {
        "nameEn": "Angola",
        "namePt": "Angola",
        "dialCode": "+244",
        "isoCode": "AO",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ao.svg`
    },
    {
        "nameEn": "Anguilla",
        "namePt": "Anguilla",
        "dialCode": "+1264",
        "isoCode": "AI",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ai.svg`
    },
    {
        "nameEn": "Antarctica",
        "namePt": "Antártica",
        "dialCode": "+672",
        "isoCode": "AQ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/aq.svg`
    },
    {
        "nameEn": "Antigua and Barbuda",
        "namePt": "Antigua e Barbuda",
        "dialCode": "+1268",
        "isoCode": "AG",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ag.svg`
    },
    {
        "nameEn": "Argentina",
        "namePt": "Argentina",
        "dialCode": "+54",
        "isoCode": "AR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ar.svg`
    },
    {
        "nameEn": "Armenia",
        "namePt": "Armênia",
        "dialCode": "+374",
        "isoCode": "AM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/am.svg`
    },
    {
        "nameEn": "Aruba",
        "namePt": "Aruba",
        "dialCode": "+297",
        "isoCode": "AW",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/aw.svg`
    },
    {
        "nameEn": "Ascension Island",
        "namePt": "Ilha Ascensão",
        "dialCode": "+247",
        "isoCode": "AC",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ac.svg`
    },
    {
        "nameEn": "Australia",
        "namePt": "Austrália",
        "dialCode": "+61",
        "isoCode": "AU",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/au.svg`
    },
    {
        "nameEn": "Austria",
        "namePt": "Áustria",
        "dialCode": "+43",
        "isoCode": "AT",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/at.svg`
    },
    {
        "nameEn": "Azerbaijan",
        "namePt": "Azerbaijão",
        "dialCode": "+994",
        "isoCode": "AZ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/az.svg`
    },
    {
        "nameEn": "Bahamas",
        "namePt": "Bahamas",
        "dialCode": "+1242",
        "isoCode": "BS",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bs.svg`
    },
    {
        "nameEn": "Bahrain",
        "namePt": "Bahrein",
        "dialCode": "+973",
        "isoCode": "BH",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bh.svg`
    },
    {
        "nameEn": "Bangladesh",
        "namePt": "Bangladesh",
        "dialCode": "+880",
        "isoCode": "BD",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bd.svg`
    },
    {
        "nameEn": "Barbados",
        "namePt": "Barbados",
        "dialCode": "+1246",
        "isoCode": "BB",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bb.svg`
    },
    {
        "nameEn": "Belarus",
        "namePt": "Bielo-Rússia",
        "dialCode": "+375",
        "isoCode": "BY",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/by.svg`
    },
    {
        "nameEn": "Belgium",
        "namePt": "Bélgica",
        "dialCode": "+32",
        "isoCode": "BE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/be.svg`
    },
    {
        "nameEn": "Belize",
        "namePt": "Belize",
        "dialCode": "+501",
        "isoCode": "BZ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bz.svg`
    },
    {
        "nameEn": "Benin",
        "namePt": "Benin",
        "dialCode": "+229",
        "isoCode": "BJ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bj.svg`
    },
    {
        "nameEn": "Bermuda",
        "namePt": "Bermudas",
        "dialCode": "+1441",
        "isoCode": "BM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bm.svg`
    },
    {
        "nameEn": "Bhutan",
        "namePt": "Butão",
        "dialCode": "+975",
        "isoCode": "BT",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bt.svg`
    },
    {
        "nameEn": "Bolivia",
        "namePt": "Bolívia",
        "dialCode": "+591",
        "isoCode": "BO",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bo.svg`
    },
    {
        "nameEn": "Bosnia and Herzegovina",
        "namePt": "Bósnia e Herzegovina",
        "dialCode": "+387",
        "isoCode": "BA",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ba.svg`
    },
    {
        "nameEn": "Botswana",
        "namePt": "Botswana",
        "dialCode": "+267",
        "isoCode": "BW",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bw.svg`
    },
    {
        "nameEn": "Brazil",
        "namePt": "Brasil",
        "dialCode": "+55",
        "isoCode": "BR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/br.svg`
    },
    {
        "nameEn": "British Indian Ocean Territory",
        "namePt": "Território Britânico do Oceano Índico",
        "dialCode": "+246",
        "isoCode": "IO",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/io.svg`
    },
    {
        "nameEn": "Brunei Darussalam",
        "namePt": "Brunei Darussalam",
        "dialCode": "+673",
        "isoCode": "BN",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bn.svg`
    },
    {
        "nameEn": "Bulgaria",
        "namePt": "Bulgária",
        "dialCode": "+359",
        "isoCode": "BG",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bg.svg`
    },
    {
        "nameEn": "Burkina Faso",
        "namePt": "Burkina Faso",
        "dialCode": "+226",
        "isoCode": "BF",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bf.svg`
    },
    {
        "nameEn": "Burundi",
        "namePt": "Burundi",
        "dialCode": "+257",
        "isoCode": "BI",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bi.svg`
    },
    {
        "nameEn": "Cambodia",
        "namePt": "Camboja",
        "dialCode": "+855",
        "isoCode": "KH",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/kh.svg`
    },
    {
        "nameEn": "Cameroon",
        "namePt": "Camarões",
        "dialCode": "+237",
        "isoCode": "CM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/cm.svg`
    },
    {
        "nameEn": "Canada",
        "namePt": "Canadá",
        "dialCode": "+1",
        "isoCode": "CA",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ca.svg`
    },
    {
        "nameEn": "Cape Verde",
        "namePt": "Cabo Verde",
        "dialCode": "+238",
        "isoCode": "CV",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/cv.svg`
    },
    {
        "nameEn": "Cayman Islands",
        "namePt": "Ilhas Cayman",
        "dialCode": "+1345",
        "isoCode": "KY",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ky.svg`
    },
    {
        "nameEn": "Central African Republic",
        "namePt": "República Centro-Africana",
        "dialCode": "+236",
        "isoCode": "CF",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/cf.svg`
    },
    {
        "nameEn": "Chad",
        "namePt": "Chade",
        "dialCode": "+235",
        "isoCode": "TD",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/td.svg`
    },
    {
        "nameEn": "Chile",
        "namePt": "Chile",
        "dialCode": "+56",
        "isoCode": "CL",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/cl.svg`
    },
    {
        "nameEn": "China",
        "namePt": "China",
        "dialCode": "+86",
        "isoCode": "CN",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/cn.svg`
    },
    {
        "nameEn": "Christmas Island",
        "namePt": "Ilha do Natal",
        "dialCode": "+61",
        "isoCode": "CX",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/cx.svg`
    },
    {
        "nameEn": "Cocos (Keeling) Islands",
        "namePt": "Ilhas Cocos (Keeling)",
        "dialCode": "+61",
        "isoCode": "CC",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/cc.svg`
    },
    {
        "nameEn": "Colombia",
        "namePt": "Colômbia",
        "dialCode": "+57",
        "isoCode": "CO",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/co.svg`
    },
    {
        "nameEn": "Comoros",
        "namePt": "Comores",
        "dialCode": "+269",
        "isoCode": "KM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/km.svg`
    },
    {
        "nameEn": "Congo",
        "namePt": "Congo",
        "dialCode": "+242",
        "isoCode": "CG",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/cg.svg`
    },
    {
        "nameEn": "Cook Islands",
        "namePt": "Ilhas Cook",
        "dialCode": "+682",
        "isoCode": "CK",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ck.svg`
    },
    {
        "nameEn": "Costa Rica",
        "namePt": "Costa Rica",
        "dialCode": "+506",
        "isoCode": "CR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/cr.svg`
    },
    {
        "nameEn": "Croatia",
        "namePt": "Croácia",
        "dialCode": "+385",
        "isoCode": "HR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/hr.svg`
    },
    {
        "nameEn": "Cuba",
        "namePt": "Cuba",
        "dialCode": "+53",
        "isoCode": "CU",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/cu.svg`
    },
    {
        "nameEn": "Cyprus",
        "namePt": "Chipre",
        "dialCode": "+357",
        "isoCode": "CY",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/cy.svg`
    },
    {
        "nameEn": "Czech Republic",
        "namePt": "República Checa",
        "dialCode": "+420",
        "isoCode": "CZ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/cz.svg`
    },
    {
        "nameEn": "Democratic Republic of the Congo",
        "namePt": "República Democrática do Congo",
        "dialCode": "+243",
        "isoCode": "CD",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/cd.svg`
    },
    {
        "nameEn": "Denmark",
        "namePt": "Dinamarca",
        "dialCode": "+45",
        "isoCode": "DK",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/dk.svg`
    },
    {
        "nameEn": "Djibouti",
        "namePt": "Djibouti",
        "dialCode": "+253",
        "isoCode": "DJ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/dj.svg`
    },
    {
        "nameEn": "Dominica",
        "namePt": "Dominica",
        "dialCode": "+1767",
        "isoCode": "DM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/dm.svg`
    },
    {
        "nameEn": "Dominican Republic",
        "namePt": "República Dominicana",
        "dialCode": "+1849",
        "isoCode": "DO",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/do.svg`
    },
    {
        "nameEn": "Ecuador",
        "namePt": "Equador",
        "dialCode": "+593",
        "isoCode": "EC",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ec.svg`
    },
    {
        "nameEn": "Egypt",
        "namePt": "Egito",
        "dialCode": "+20",
        "isoCode": "EG",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/eg.svg`
    },
    {
        "nameEn": "El Salvador",
        "namePt": "El Salvador",
        "dialCode": "+503",
        "isoCode": "SV",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sv.svg`
    },
    {
        "nameEn": "Equatorial Guinea",
        "namePt": "Guiné Equatorial",
        "dialCode": "+240",
        "isoCode": "GQ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gq.svg`
    },
    {
        "nameEn": "Eritrea",
        "namePt": "Eritreia",
        "dialCode": "+291",
        "isoCode": "ER",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/er.svg`
    },
    {
        "nameEn": "Estonia",
        "namePt": "Estônia",
        "dialCode": "+372",
        "isoCode": "EE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ee.svg`
    },
    {
        "nameEn": "Eswatini",
        "namePt": "Eswatini",
        "dialCode": "+268",
        "isoCode": "SZ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sz.svg`
    },
    {
        "nameEn": "Ethiopia",
        "namePt": "Etiópia",
        "dialCode": "+251",
        "isoCode": "ET",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/et.svg`
    },
    {
        "nameEn": "Falkland Islands (Malvinas)",
        "namePt": "Ilhas Falkland (Malvinas)",
        "dialCode": "+500",
        "isoCode": "FK",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/fk.svg`
    },
    {
        "nameEn": "Faroe Islands",
        "namePt": "Ilhas Faroe",
        "dialCode": "+298",
        "isoCode": "FO",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/fo.svg`
    },
    {
        "nameEn": "Fiji",
        "namePt": "Fiji",
        "dialCode": "+679",
        "isoCode": "FJ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/fj.svg`
    },
    {
        "nameEn": "Finland",
        "namePt": "Finlândia",
        "dialCode": "+358",
        "isoCode": "FI",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/fi.svg`
    },
    {
        "nameEn": "France",
        "namePt": "França",
        "dialCode": "+33",
        "isoCode": "FR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/fr.svg`
    },
    {
        "nameEn": "French Guiana",
        "namePt": "Guiana Francesa",
        "dialCode": "+594",
        "isoCode": "GF",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gf.svg`
    },
    {
        "nameEn": "French Polynesia",
        "namePt": "Polinésia Francesa",
        "dialCode": "+689",
        "isoCode": "PF",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/pf.svg`
    },
    {
        "nameEn": "Gabon",
        "namePt": "Gabão",
        "dialCode": "+241",
        "isoCode": "GA",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ga.svg`
    },
    {
        "nameEn": "Gambia",
        "namePt": "Gâmbia",
        "dialCode": "+220",
        "isoCode": "GM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gm.svg`
    },
    {
        "nameEn": "Georgia",
        "namePt": "Georgia",
        "dialCode": "+995",
        "isoCode": "GE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ge.svg`
    },
    {
        "nameEn": "Germany",
        "namePt": "Alemanha",
        "dialCode": "+49",
        "isoCode": "DE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/de.svg`
    },
    {
        "nameEn": "Ghana",
        "namePt": "Gana",
        "dialCode": "+233",
        "isoCode": "GH",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gh.svg`
    },
    {
        "nameEn": "Gibraltar",
        "namePt": "Gibraltar",
        "dialCode": "+350",
        "isoCode": "GI",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gi.svg`
    },
    {
        "nameEn": "Greece",
        "namePt": "Grécia",
        "dialCode": "+30",
        "isoCode": "GR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gr.svg`
    },
    {
        "nameEn": "Greenland",
        "namePt": "Groenlândia",
        "dialCode": "+299",
        "isoCode": "GL",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gl.svg`
    },
    {
        "nameEn": "Grenada",
        "namePt": "Grenada",
        "dialCode": "+1473",
        "isoCode": "GD",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gd.svg`
    },
    {
        "nameEn": "Guadeloupe",
        "namePt": "Guadalupe",
        "dialCode": "+590",
        "isoCode": "GP",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gp.svg`
    },
    {
        "nameEn": "Guam",
        "namePt": "Guam",
        "dialCode": "+1671",
        "isoCode": "GU",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gu.svg`
    },
    {
        "nameEn": "Guatemala",
        "namePt": "Guatemala",
        "dialCode": "+502",
        "isoCode": "GT",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gt.svg`
    },
    {
        "nameEn": "Guernsey",
        "namePt": "Guernsey",
        "dialCode": "+44",
        "isoCode": "GG",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gg.svg`
    },
    {
        "nameEn": "Guinea",
        "namePt": "Guiné",
        "dialCode": "+224",
        "isoCode": "GN",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gn.svg`
    },
    {
        "nameEn": "Guinea-Bissau",
        "namePt": "Guiné-Bissau",
        "dialCode": "+245",
        "isoCode": "GW",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gw.svg`
    },
    {
        "nameEn": "Guyana",
        "namePt": "Guiana",
        "dialCode": "+592",
        "isoCode": "GY",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gy.svg`
    },
    {
        "nameEn": "Haiti",
        "namePt": "Haiti",
        "dialCode": "+509",
        "isoCode": "HT",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ht.svg`
    },
    {
        "nameEn": "Holy See (Vatican City State)",
        "namePt": "Santa Sé (Estado da Cidade do Vaticano)",
        "dialCode": "+379",
        "isoCode": "VA",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/va.svg`
    },
    {
        "nameEn": "Honduras",
        "namePt": "Honduras",
        "dialCode": "+504",
        "isoCode": "HN",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/hn.svg`
    },
    {
        "nameEn": "Hong Kong",
        "namePt": "Hong Kong",
        "dialCode": "+852",
        "isoCode": "HK",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/hk.svg`
    },
    {
        "nameEn": "Hungary",
        "namePt": "Hungria",
        "dialCode": "+36",
        "isoCode": "HU",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/hu.svg`
    },
    {
        "nameEn": "Iceland",
        "namePt": "Islândia",
        "dialCode": "+354",
        "isoCode": "IS",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/is.svg`
    },
    {
        "nameEn": "India",
        "namePt": "Índia",
        "dialCode": "+91",
        "isoCode": "IN",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/in.svg`
    },
    {
        "nameEn": "Indonesia",
        "namePt": "Indonésia",
        "dialCode": "+62",
        "isoCode": "ID",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/id.svg`
    },
    {
        "nameEn": "Iran",
        "namePt": "Irã",
        "dialCode": "+98",
        "isoCode": "IR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ir.svg`
    },
    {
        "nameEn": "Iraq",
        "namePt": "Iraque",
        "dialCode": "+964",
        "isoCode": "IQ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/iq.svg`
    },
    {
        "nameEn": "Ireland",
        "namePt": "Irlanda",
        "dialCode": "+353",
        "isoCode": "IE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ie.svg`
    },
    {
        "nameEn": "Isle of Man",
        "namePt": "Ilha de Man",
        "dialCode": "+44",
        "isoCode": "IM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/im.svg`
    },
    {
        "nameEn": "Israel",
        "namePt": "Israel",
        "dialCode": "+972",
        "isoCode": "IL",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/il.svg`
    },
    {
        "nameEn": "Italy",
        "namePt": "Itália",
        "dialCode": "+39",
        "isoCode": "IT",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/it.svg`
    },
    {
        "nameEn": "Ivory Coast / Cote d'Ivoire",
        "namePt": "Costa do Marfim",
        "dialCode": "+225",
        "isoCode": "CI",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ci.svg`
    },
    {
        "nameEn": "Jamaica",
        "namePt": "Jamaica",
        "dialCode": "+1876",
        "isoCode": "JM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/jm.svg`
    },
    {
        "nameEn": "Japan",
        "namePt": "Japão",
        "dialCode": "+81",
        "isoCode": "JP",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/jp.svg`
    },
    {
        "nameEn": "Jersey",
        "namePt": "Jersey",
        "dialCode": "+44",
        "isoCode": "JE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/je.svg`
    },
    {
        "nameEn": "Jordan",
        "namePt": "Jordânia",
        "dialCode": "+962",
        "isoCode": "JO",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/jo.svg`
    },
    {
        "nameEn": "Kazakhstan",
        "namePt": "Cazaquistão",
        "dialCode": "+77",
        "isoCode": "KZ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/kz.svg`
    },
    {
        "nameEn": "Kenya",
        "namePt": "Quênia",
        "dialCode": "+254",
        "isoCode": "KE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ke.svg`
    },
    {
        "nameEn": "Kiribati",
        "namePt": "Kiribati",
        "dialCode": "+686",
        "isoCode": "KI",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ki.svg`
    },
    {
        "nameEn": "Democratic People's Republic of Korea",
        "namePt": "República Popular Democrática da Coreia",
        "dialCode": "+850",
        "isoCode": "KP",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/kp.svg`
    },
    {
        "nameEn": "Republic of South Korea",
        "namePt": "República da Coreia do Sul",
        "dialCode": "+82",
        "isoCode": "KR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/kr.svg`
    },
    {
        "nameEn": "Kosovo",
        "namePt": "Kosovo",
        "dialCode": "+383",
        "isoCode": "XK",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/xk.svg`
    },
    {
        "nameEn": "Kuwait",
        "namePt": "Kuwait",
        "dialCode": "+965",
        "isoCode": "KW",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/kw.svg`
    },
    {
        "nameEn": "Kyrgyzstan",
        "namePt": "Quirguistão",
        "dialCode": "+996",
        "isoCode": "KG",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/kg.svg`
    },
    {
        "nameEn": "Laos",
        "namePt": "Laos",
        "dialCode": "+856",
        "isoCode": "LA",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/la.svg`
    },
    {
        "nameEn": "Latvia",
        "namePt": "Letônia",
        "dialCode": "+371",
        "isoCode": "LV",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/lv.svg`
    },
    {
        "nameEn": "Lebanon",
        "namePt": "Líbano",
        "dialCode": "+961",
        "isoCode": "LB",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/lb.svg`
    },
    {
        "nameEn": "Lesotho",
        "namePt": "Lesoto",
        "dialCode": "+266",
        "isoCode": "LS",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ls.svg`
    },
    {
        "nameEn": "Liberia",
        "namePt": "Libéria",
        "dialCode": "+231",
        "isoCode": "LR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/lr.svg`
    },
    {
        "nameEn": "Libya",
        "namePt": "Líbia",
        "dialCode": "+218",
        "isoCode": "LY",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ly.svg`
    },
    {
        "nameEn": "Liechtenstein",
        "namePt": "Liechtenstein",
        "dialCode": "+423",
        "isoCode": "LI",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/li.svg`
    },
    {
        "nameEn": "Lithuania",
        "namePt": "Lituânia",
        "dialCode": "+370",
        "isoCode": "LT",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/lt.svg`
    },
    {
        "nameEn": "Luxembourg",
        "namePt": "Luxemburgo",
        "dialCode": "+352",
        "isoCode": "LU",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/lu.svg`
    },
    {
        "nameEn": "Macau",
        "namePt": "Macau",
        "dialCode": "+853",
        "isoCode": "MO",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mo.svg`
    },
    {
        "nameEn": "Madagascar",
        "namePt": "Madagáscar",
        "dialCode": "+261",
        "isoCode": "MG",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mg.svg`
    },
    {
        "nameEn": "Malawi",
        "namePt": "Malawi",
        "dialCode": "+265",
        "isoCode": "MW",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mw.svg`
    },
    {
        "nameEn": "Malaysia",
        "namePt": "Malásia",
        "dialCode": "+60",
        "isoCode": "MY",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/my.svg`
    },
    {
        "nameEn": "Maldives",
        "namePt": "Maldivas",
        "dialCode": "+960",
        "isoCode": "MV",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mv.svg`
    },
    {
        "nameEn": "Mali",
        "namePt": "Mali",
        "dialCode": "+223",
        "isoCode": "ML",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ml.svg`
    },
    {
        "nameEn": "Malta",
        "namePt": "Malta",
        "dialCode": "+356",
        "isoCode": "MT",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mt.svg`
    },
    {
        "nameEn": "Marshall Islands",
        "namePt": "Ilhas Marshall",
        "dialCode": "+692",
        "isoCode": "MH",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mh.svg`
    },
    {
        "nameEn": "Martinique",
        "namePt": "Martinica",
        "dialCode": "+596",
        "isoCode": "MQ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mq.svg`
    },
    {
        "nameEn": "Mauritania",
        "namePt": "Mauritânia",
        "dialCode": "+222",
        "isoCode": "MR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mr.svg`
    },
    {
        "nameEn": "Mauritius",
        "namePt": "Maurício",
        "dialCode": "+230",
        "isoCode": "MU",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mu.svg`
    },
    {
        "nameEn": "Mayotte",
        "namePt": "Mayotte",
        "dialCode": "+262",
        "isoCode": "YT",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/yt.svg`
    },
    {
        "nameEn": "Mexico",
        "namePt": "México",
        "dialCode": "+52",
        "isoCode": "MX",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mx.svg`
    },
    {
        "nameEn": "Micronesia, Federated States of Micronesia",
        "namePt": "Micronésia, Estados Federados da Micronésia",
        "dialCode": "+691",
        "isoCode": "FM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/fm.svg`
    },
    {
        "nameEn": "Moldova",
        "namePt": "Moldova",
        "dialCode": "+373",
        "isoCode": "MD",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/md.svg`
    },
    {
        "nameEn": "Monaco",
        "namePt": "Mônaco",
        "dialCode": "+377",
        "isoCode": "MC",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mc.svg`
    },
    {
        "nameEn": "Mongolia",
        "namePt": "Mongólia",
        "dialCode": "+976",
        "isoCode": "MN",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mn.svg`
    },
    {
        "nameEn": "Montenegro",
        "namePt": "Montenegro",
        "dialCode": "+382",
        "isoCode": "ME",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/me.svg`
    },
    {
        "nameEn": "Montserrat",
        "namePt": "Montserrat",
        "dialCode": "+1664",
        "isoCode": "MS",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ms.svg`
    },
    {
        "nameEn": "Morocco",
        "namePt": "Marrocos",
        "dialCode": "+212",
        "isoCode": "MA",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ma.svg`
    },
    {
        "nameEn": "Mozambique",
        "namePt": "Moçambique",
        "dialCode": "+258",
        "isoCode": "MZ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mz.svg`
    },
    {
        "nameEn": "Myanmar",
        "namePt": "Mianmar",
        "dialCode": "+95",
        "isoCode": "MM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mm.svg`
    },
    {
        "nameEn": "Namibia",
        "namePt": "Namibia",
        "dialCode": "+264",
        "isoCode": "NA",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/na.svg`
    },
    {
        "nameEn": "Nauru",
        "namePt": "Nauru",
        "dialCode": "+674",
        "isoCode": "NR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/nr.svg`
    },
    {
        "nameEn": "Nepal",
        "namePt": "Nepal",
        "dialCode": "+977",
        "isoCode": "NP",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/np.svg`
    },
    {
        "nameEn": "Netherlands",
        "namePt": "Países Baixos",
        "dialCode": "+31",
        "isoCode": "NL",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/nl.svg`
    },
    {
        "nameEn": "Netherlands Antilles",
        "namePt": "Antilhas Holandesas",
        "dialCode": "+599",
        "isoCode": "AN",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/an.svg`
    },
    {
        "nameEn": "New Caledonia",
        "namePt": "Nova Caledônia",
        "dialCode": "+687",
        "isoCode": "NC",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/nc.svg`
    },
    {
        "nameEn": "New Zealand",
        "namePt": "Nova Zelândia",
        "dialCode": "+64",
        "isoCode": "NZ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/nz.svg`
    },
    {
        "nameEn": "Nicaragua",
        "namePt": "Nicarágua",
        "dialCode": "+505",
        "isoCode": "NI",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ni.svg`
    },
    {
        "nameEn": "Niger",
        "namePt": "Níger",
        "dialCode": "+227",
        "isoCode": "NE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ne.svg`
    },
    {
        "nameEn": "Nigeria",
        "namePt": "Nigéria",
        "dialCode": "+234",
        "isoCode": "NG",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ng.svg`
    },
    {
        "nameEn": "Niue",
        "namePt": "Niue",
        "dialCode": "+683",
        "isoCode": "NU",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/nu.svg`
    },
    {
        "nameEn": "Norfolk Island",
        "namePt": "Ilha Norfolk",
        "dialCode": "+672",
        "isoCode": "NF",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/nf.svg`
    },
    {
        "nameEn": "North Macedonia",
        "namePt": "Macedônia do Norte",
        "dialCode": "+389",
        "isoCode": "MK",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mk.svg`
    },
    {
        "nameEn": "Northern Mariana Islands",
        "namePt": "Ilhas Marianas do Norte",
        "dialCode": "+1670",
        "isoCode": "MP",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mp.svg`
    },
    {
        "nameEn": "Norway",
        "namePt": "Noruega",
        "dialCode": "+47",
        "isoCode": "NO",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/no.svg`
    },
    {
        "nameEn": "Oman",
        "namePt": "Omã",
        "dialCode": "+968",
        "isoCode": "OM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/om.svg`
    },
    {
        "nameEn": "Pakistan",
        "namePt": "Paquistão",
        "dialCode": "+92",
        "isoCode": "PK",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/pk.svg`
    },
    {
        "nameEn": "Palau",
        "namePt": "Palau",
        "dialCode": "+680",
        "isoCode": "PW",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/pw.svg`
    },
    {
        "nameEn": "Palestine",
        "namePt": "Palestina",
        "dialCode": "+970",
        "isoCode": "PS",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ps.svg`
    },
    {
        "nameEn": "Panama",
        "namePt": "Panamá",
        "dialCode": "+507",
        "isoCode": "PA",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/pa.svg`
    },
    {
        "nameEn": "Papua New Guinea",
        "namePt": "Papua Nova Guiné",
        "dialCode": "+675",
        "isoCode": "PG",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/pg.svg`
    },
    {
        "nameEn": "Paraguay",
        "namePt": "Paraguai",
        "dialCode": "+595",
        "isoCode": "PY",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/py.svg`
    },
    {
        "nameEn": "Peru",
        "namePt": "Peru",
        "dialCode": "+51",
        "isoCode": "PE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/pe.svg`
    },
    {
        "nameEn": "Philippines",
        "namePt": "Filipinas",
        "dialCode": "+63",
        "isoCode": "PH",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ph.svg`
    },
    {
        "nameEn": "Pitcairn",
        "namePt": "Pitcairn",
        "dialCode": "+872",
        "isoCode": "PN",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/pn.svg`
    },
    {
        "nameEn": "Poland",
        "namePt": "Polônia",
        "dialCode": "+48",
        "isoCode": "PL",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/pl.svg`
    },
    {
        "nameEn": "Portugal",
        "namePt": "Portugal",
        "dialCode": "+351",
        "isoCode": "PT",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/pt.svg`
    },
    {
        "nameEn": "Puerto Rico",
        "namePt": "Porto Rico",
        "dialCode": "+1939",
        "isoCode": "PR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/pr.svg`
    },
    {
        "nameEn": "Qatar",
        "namePt": "Catar",
        "dialCode": "+974",
        "isoCode": "QA",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/qa.svg`
    },
    {
        "nameEn": "Reunion",
        "namePt": "Reunião",
        "dialCode": "+262",
        "isoCode": "RE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/re.svg`
    },
    {
        "nameEn": "Romania",
        "namePt": "Romênia",
        "dialCode": "+40",
        "isoCode": "RO",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ro.svg`
    },
    {
        "nameEn": "Russia",
        "namePt": "Rússia",
        "dialCode": "+7",
        "isoCode": "RU",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ru.svg`
    },
    {
        "nameEn": "Rwanda",
        "namePt": "Ruanda",
        "dialCode": "+250",
        "isoCode": "RW",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/rw.svg`
    },
    {
        "nameEn": "Saint Barthelemy",
        "namePt": "São Bartolomeu",
        "dialCode": "+590",
        "isoCode": "BL",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/bl.svg`
    },
    {
        "nameEn": "Saint Helena, Ascension and Tristan Da Cunha",
        "namePt": "Santa Helena, Ascensão e Tristão da Cunha",
        "dialCode": "+290",
        "isoCode": "SH",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sh.svg`
    },
    {
        "nameEn": "Saint Kitts and Nevis",
        "namePt": "São Cristóvão e Nevis",
        "dialCode": "+1869",
        "isoCode": "KN",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/kn.svg`
    },
    {
        "nameEn": "Saint Lucia",
        "namePt": "Santa Lúcia",
        "dialCode": "+1758",
        "isoCode": "LC",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/lc.svg`
    },
    {
        "nameEn": "Saint Martin",
        "namePt": "São Martinho",
        "dialCode": "+590",
        "isoCode": "MF",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/mf.svg`
    },
    {
        "nameEn": "Saint Pierre and Miquelon",
        "namePt": "São Pedro e Miquelão",
        "dialCode": "+508",
        "isoCode": "PM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/pm.svg`
    },
    {
        "nameEn": "Saint Vincent and the Grenadines",
        "namePt": "São Vicente e Granadinas",
        "dialCode": "+1784",
        "isoCode": "VC",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/vc.svg`
    },
    {
        "nameEn": "Samoa",
        "namePt": "Samoa",
        "dialCode": "+685",
        "isoCode": "WS",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ws.svg`
    },
    {
        "nameEn": "San Marino",
        "namePt": "San Marino",
        "dialCode": "+378",
        "isoCode": "SM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sm.svg`
    },
    {
        "nameEn": "Sao Tome and Principe",
        "namePt": "São Tomé e Príncipe",
        "dialCode": "+239",
        "isoCode": "ST",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/st.svg`
    },
    {
        "nameEn": "Saudi Arabia",
        "namePt": "Arábia Saudita",
        "dialCode": "+966",
        "isoCode": "SA",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sa.svg`
    },
    {
        "nameEn": "Senegal",
        "namePt": "Senegal",
        "dialCode": "+221",
        "isoCode": "SN",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sn.svg`
    },
    {
        "nameEn": "Serbia",
        "namePt": "Sérvia",
        "dialCode": "+381",
        "isoCode": "RS",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/rs.svg`
    },
    {
        "nameEn": "Seychelles",
        "namePt": "Seychelles",
        "dialCode": "+248",
        "isoCode": "SC",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sc.svg`
    },
    {
        "nameEn": "Sierra Leone",
        "namePt": "Serra Leoa",
        "dialCode": "+232",
        "isoCode": "SL",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sl.svg`
    },
    {
        "nameEn": "Singapore",
        "namePt": "Cingapura",
        "dialCode": "+65",
        "isoCode": "SG",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sg.svg`
    },
    {
        "nameEn": "Sint Maarten",
        "namePt": "Sint Maarten",
        "dialCode": "+1721",
        "isoCode": "SX",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sx.svg`
    },
    {
        "nameEn": "Slovakia",
        "namePt": "Eslováquia",
        "dialCode": "+421",
        "isoCode": "SK",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sk.svg`
    },
    {
        "nameEn": "Slovenia",
        "namePt": "Eslovênia",
        "dialCode": "+386",
        "isoCode": "SI",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/si.svg`
    },
    {
        "nameEn": "Solomon Islands",
        "namePt": "Ilhas Salomão",
        "dialCode": "+677",
        "isoCode": "SB",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sb.svg`
    },
    {
        "nameEn": "Somalia",
        "namePt": "Somália",
        "dialCode": "+252",
        "isoCode": "SO",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/so.svg`
    },
    {
        "nameEn": "South Africa",
        "namePt": "África do Sul",
        "dialCode": "+27",
        "isoCode": "ZA",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/za.svg`
    },
    {
        "nameEn": "South Georgia and the South Sandwich Islands",
        "namePt": "Geórgia do Sul e Ilhas Sandwich do Sul",
        "dialCode": "+500",
        "isoCode": "GS",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gs.svg`
    },
    {
        "nameEn": "South Sudan",
        "namePt": "Sudão do Sul",
        "dialCode": "+211",
        "isoCode": "SS",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ss.svg`
    },
    {
        "nameEn": "Spain",
        "namePt": "Espanha",
        "dialCode": "+34",
        "isoCode": "ES",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/es.svg`
    },
    {
        "nameEn": "Sri Lanka",
        "namePt": "Sri Lanka",
        "dialCode": "+94",
        "isoCode": "LK",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/lk.svg`
    },
    {
        "nameEn": "Sudan",
        "namePt": "Sudão",
        "dialCode": "+249",
        "isoCode": "SD",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sd.svg`
    },
    {
        "nameEn": "Suriname",
        "namePt": "Suriname",
        "dialCode": "+597",
        "isoCode": "SR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sr.svg`
    },
    {
        "nameEn": "Svalbard and Jan Mayen",
        "namePt": "Svalbard e Jan Mayen",
        "dialCode": "+47",
        "isoCode": "SJ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sj.svg`
    },
    {
        "nameEn": "Sweden",
        "namePt": "Suécia",
        "dialCode": "+46",
        "isoCode": "SE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/se.svg`
    },
    {
        "nameEn": "Switzerland",
        "namePt": "Suíça",
        "dialCode": "+41",
        "isoCode": "CH",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ch.svg`
    },
    {
        "nameEn": "Syrian Arab Republic",
        "namePt": "República Árabe da Síria",
        "dialCode": "+963",
        "isoCode": "SY",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/sy.svg`
    },
    {
        "nameEn": "Taiwan",
        "namePt": "Taiwan",
        "dialCode": "+886",
        "isoCode": "TW",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/tw.svg`
    },
    {
        "nameEn": "Tajikistan",
        "namePt": "Tajiquistão",
        "dialCode": "+992",
        "isoCode": "TJ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/tj.svg`
    },
    {
        "nameEn": "Tanzania, United Republic of Tanzania",
        "namePt": "Tanzânia, República Unida da Tanzânia",
        "dialCode": "+255",
        "isoCode": "TZ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/tz.svg`
    },
    {
        "nameEn": "Thailand",
        "namePt": "Tailândia",
        "dialCode": "+66",
        "isoCode": "TH",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/th.svg`
    },
    {
        "nameEn": "Timor-Leste",
        "namePt": "Timor-Leste",
        "dialCode": "+670",
        "isoCode": "TL",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/tl.svg`
    },
    {
        "nameEn": "Togo",
        "namePt": "Ir",
        "dialCode": "+228",
        "isoCode": "TG",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/tg.svg`
    },
    {
        "nameEn": "Tokelau",
        "namePt": "Toquelau",
        "dialCode": "+690",
        "isoCode": "TK",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/tk.svg`
    },
    {
        "nameEn": "Tonga",
        "namePt": "Tonga",
        "dialCode": "+676",
        "isoCode": "TO",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/to.svg`
    },
    {
        "nameEn": "Trinidad and Tobago",
        "namePt": "Trinidad e Tobago",
        "dialCode": "+1868",
        "isoCode": "TT",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/tt.svg`
    },
    {
        "nameEn": "Tunisia",
        "namePt": "Tunísia",
        "dialCode": "+216",
        "isoCode": "TN",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/tn.svg`
    },
    {
        "nameEn": "Turkey",
        "namePt": "Turquia",
        "dialCode": "+90",
        "isoCode": "TR",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/tr.svg`
    },
    {
        "nameEn": "Turkmenistan",
        "namePt": "Turcomenistão",
        "dialCode": "+993",
        "isoCode": "TM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/tm.svg`
    },
    {
        "nameEn": "Turks and Caicos Islands",
        "namePt": "Ilhas Turcas e Caicos",
        "dialCode": "+1649",
        "isoCode": "TC",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/tc.svg`
    },
    {
        "nameEn": "Tuvalu",
        "namePt": "Tuvalu",
        "dialCode": "+688",
        "isoCode": "TV",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/tv.svg`
    },
    {
        "nameEn": "Uganda",
        "namePt": "Uganda",
        "dialCode": "+256",
        "isoCode": "UG",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ug.svg`
    },
    {
        "nameEn": "Ukraine",
        "namePt": "Ucrânia",
        "dialCode": "+380",
        "isoCode": "UA",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ua.svg`
    },
    {
        "nameEn": "United Arab Emirates",
        "namePt": "Emirados Árabes Unidos",
        "dialCode": "+971",
        "isoCode": "AE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ae.svg`
    },
    {
        "nameEn": "United Kingdom",
        "namePt": "Reino Unido",
        "dialCode": "+44",
        "isoCode": "GB",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/gb.svg`
    },
    {
        "nameEn": "United States",
        "namePt": "Estados Unidos",
        "dialCode": "+1",
        "isoCode": "US",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/us.svg`
    },
    {
        "nameEn": "Uruguay",
        "namePt": "Uruguai",
        "dialCode": "+598",
        "isoCode": "UY",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/uy.svg`
    },
    {
        "nameEn": "Uzbekistan",
        "namePt": "Uzbequistão",
        "dialCode": "+998",
        "isoCode": "UZ",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/uz.svg`
    },
    {
        "nameEn": "Vanuatu",
        "namePt": "Vanuatu",
        "dialCode": "+678",
        "isoCode": "VU",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/vu.svg`
    },
    {
        "nameEn": "Venezuela, Bolivarian Republic of Venezuela",
        "namePt": "Venezuela, República Bolivariana da Venezuela",
        "dialCode": "+58",
        "isoCode": "VE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ve.svg`
    },
    {
        "nameEn": "Vietnam",
        "namePt": "Vietnã",
        "dialCode": "+84",
        "isoCode": "VN",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/vn.svg`
    },
    {
        "nameEn": "Virgin Islands, British",
        "namePt": "Ilhas Virgens Britânicas",
        "dialCode": "+1284",
        "isoCode": "VG",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/vg.svg`
    },
    {
        "nameEn": "Virgin Islands, U.S.",
        "namePt": "Ilhas Virgens, EUA",
        "dialCode": "+1340",
        "isoCode": "VI",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/vi.svg`
    },
    {
        "nameEn": "Wallis and Futuna",
        "namePt": "Wallis e Futuna",
        "dialCode": "+681",
        "isoCode": "WF",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/wf.svg`
    },
    {
        "nameEn": "Yemen",
        "namePt": "Iémen",
        "dialCode": "+967",
        "isoCode": "YE",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/ye.svg`
    },
    {
        "nameEn": "Zambia",
        "namePt": "Zâmbia",
        "dialCode": "+260",
        "isoCode": "ZM",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/zm.svg`
    },
    {
        "nameEn": "Zimbabwe",
        "namePt": "Zimbábue",
        "dialCode": "+263",
        "isoCode": "ZW",
        "flag": `${process.env.REACT_APP_AZURE_STORAGE}/shareddocumentspublic/flags/zw.svg`
    }
]

