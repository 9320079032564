import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../../general/GlobalState";
import { Container, Row } from "reactstrap";
import { Buttons } from "../../utilities/Buttons";
import { WebcastList } from "../../webcast/WebcastList";
import PermissionRedirect from "../../utilities/PermissionRedirect/PermissionRedirect";

let userPermission;

export const WebcastNotices = () => {

    const { t } = useTranslation();

    const [globalState] = React.useContext(GlobalStateContext);
    const [redirect, setRedirect] = React.useState(false);
    const [idWebcastNotice, setIdWebcastNotice] = React.useState(null);
    const [gridInstance, setGridInstance] = React.useState(null);

    React.useEffect(() => {
        let globalPermissions = globalState.permissions;
        userPermission = globalPermissions.filter((i) => i.idAccessArea === 13).map((i) => (i.permission));
    }, [globalState]);

    const _controls = {
        permit: userPermission ? userPermission : null,
        export: () => exportWebcastNotice(),
        import: "",
        new: () => setRedirect(true),
        back: "",
    };

    const editWebcastNotice = (info) => {
        setIdWebcastNotice(info.id);
        setRedirect(true);
    }

    const exportWebcastNotice = () => {
        if (gridInstance) {
            let lang = i18n.language;
            let gridInstanceExport = gridInstance;
            gridInstanceExport.dataSource.map(function (x) {
                x.bloqueado = x.bloqueado ? lang === 'pt' ? 'Bloqueado' : 'Blocked' : lang === 'pt' ? 'Terminado' : 'Finished';
                x.data = new Date(x.data).toLocaleDateString(lang);
                return x;
            });
            gridInstanceExport.excelExport();
        }
    }

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                redirect ? (
                    idWebcastNotice === null ?
                        <Redirect push={true} to='/press/webcastnoticesItem' />
                        :
                        <Redirect push={true} to={`/press/webcastnoticesItem/${idWebcastNotice}`} />
                ) : (
                        <>
                            <PermissionRedirect id="13" />

                            <div className="DefaultTop" />
                            <Container className="containerTop">
                                <div className="topTitleButtons">
                                    <Row>
                                        <div className="col-sm-8 col-12">
                                            <div className="title">
                                                <h1>{t('WebcastNotices:title')}</h1>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                            <div className="buttons">
                                                <Buttons controls={_controls} />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <Row>
                                    <div className="col-12">
                                        <WebcastList
                                            edit={editWebcastNotice}
                                            gridInstance={gridInstance}
                                            refWebcastNotice={setGridInstance}
                                        />
                                    </div>
                                </Row>
                            </Container>
                        </>
                    )
            }
        </>
    )
}