import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState'
import { useTranslation } from 'react-i18next';
import { DropDownListComponent, AutoCompleteComponent, MultiSelectComponent, CheckBoxSelection, DropDownTreeComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { createSpinner, showSpinner, setSpinner, hideSpinner } from "@syncfusion/ej2-react-popups";

import MessageModal from '../utilities/MessageModal/MessageModal';

import { getDateTime, hasPermissions } from '../utilities/Utils';

import i18n from "i18next";
import { useDigitalMedia } from "./context/DigitalMediaContext";
import { getVideosEight, getVideosFour, approveReproveVideo } from '../../services/DigitalMediaSVC';
import VideoContent from './VideoContent';
import { useModal } from "../../hooks/Modal";

export const Videos = (props) => {

    const {
        editModal,
        pendingVideos,
        setPendingVideos,
        setApprovedVideos,
        approvedVideos,
        currentItem,
        changeCurrentItem,
        regionalList,
        countriesList,
        categoryList,
        statusList,
        users
    } = useDigitalMedia();

    const [globalState] = useContext(GlobalStateContext);

    const { t } = useTranslation();

    const [isVisible, setIsVisible] = useState(false);
    const [openModalApprove, setOpenModalApprove] = useState(false);
    const [openModalDisapprove, setOpenModalDisapprove] = useState(false);
    const [comentario, setComentario] = useState("");

    const [isLoadingPendingVideos, setIsLoadingPendingVideos] = useState(false);
    const [isLoadingApprovedVideos, setIsLoadingApprovedVideos] = useState(false);

    const [isLoadingItem, setIsLoadingItem] = useState(false);

    const [persistence, setPersistence] = useState(false);

    const { buildDialogMessage } = useModal();

    const [filters, setFilters] = useState({});

    const [openedItems, setOpenedItems] = useState([]);

    const dropDownItem = (id) => {

        const index = openedItems.findIndex(items => items ===id);
        const items = [...openedItems];

        if (index !== -1) {
            items.splice(index, 1);
            setOpenedItems(items);
            document.getElementById(`spin-${id}`).className = "opened arrowDiv mdl2 mdl2-arrowUp";
        } else {
            setOpenedItems(items => [...items, id]);
            document.getElementById(`spin-${id}`).className = "closed arrowDiv mdl2 mdl2-arrowUp";
        }
    }

    const getStatusLabel = (namePt) => {
        const status = {
            "Aprovado": "approvedLabel",
            "Reprovado": "disapprovedLabel",
            "Pendente": "waitingLabel"
        }

        return status[namePt];
    };

    useEffect(() => {
        console.log(filters);
    }, [filters])

    const videoProps = (item) => {
        return {
            approval: item.aprovadoPor && item.aprovadoPor.nome,
            country: i18n.language ==="pt" ? item.pais && item.pais.nomePt : item.pais && item.pais.nomeEn,
            category: i18n.language ==="pt" ? item.nucleoVideoCategoria && item.nucleoVideoCategoria.nomePt : item.nucleoVideoCategoria && item.nucleoVideoCategoria.nomeEn,
            regional: i18n.language ==="pt" ? item.regional && item.regional.nomePt : item.regional && item.regional.nomeEn,
        };
    };

    useEffect(() => {
        createSpinner({ target: document.getElementById('loadingMorePending') })
        showSpinner(document.getElementById('loadingMorePending'));
        setSpinner({ type: 'Bootstrap' });

        createSpinner({ target: document.getElementById('loadingMoreApproved') })
        showSpinner(document.getElementById('loadingMoreApproved'));
        setSpinner({ type: 'Bootstrap' });

        createSpinner({ target: document.getElementById('loadingItem') })
        showSpinner(document.getElementById('loadingItem'));
        setSpinner({ type: 'Bootstrap' });

    }, [isLoadingApprovedVideos, isLoadingPendingVideos, isLoadingItem]);

    const onSend = async () => {

        setIsLoadingApprovedVideos(true);
        setIsLoadingPendingVideos(true);

        const [pendingVideos, approvedVideos] = await Promise.all(await Promise.all([
            getVideosFour({
                filters,
                token: globalState.accessToken
            }),
            getVideosEight({
                filters,
                token: globalState.accessToken
            })
        ]).then(response => {
            return response.map(res => {
                if (res.ok)
                    return res.json();
            })
        }));

        setPendingVideos(pendingVideos);
        setApprovedVideos(approvedVideos);
        setIsLoadingApprovedVideos(false);
        setIsLoadingPendingVideos(false);
        setIsVisible(false);
    };

    const onChange = (e) => {
        if (e && e.event)
            setFilters({
                ...filters,
                [e.event.target.name]: e.value
            });
    };

    const loadMorePending = async () => {
        setIsLoadingPendingVideos(true);
        const pendingVideosList = await getVideosFour({
            token: globalState.accessToken,
            paginationUrl: pendingVideos["@odata.nextLink"]
        }).then(res => {
            if (res.ok)
                return res.json();
        });

        setPendingVideos(videos => ({
            ...pendingVideosList,
            value: [
                ...videos.value,
                ...pendingVideosList.value
            ]
        }));
        setIsLoadingPendingVideos(false);
    };

    const loadMoreApproved = async () => {
        setIsLoadingApprovedVideos(true);
        const approvedVideosList = await getVideosEight({
            token: globalState.accessToken,
            paginationUrl: approvedVideos["@odata.nextLink"]
        }).then(res => {
            if (res.ok)
                return res.json();
        });

        setApprovedVideos(videos => ({
            ...approvedVideosList,
            value: [
                ...videos.value,
                ...approvedVideosList.value
            ]
        }));
        setIsLoadingApprovedVideos(false);
    };

    const onAutoCompleteChange = (e, name) => {
        if (e && e.itemData && !e.itemData.id) {
            return;
        }
        setFilters({
            ...filters,
            [name]: e.itemData
        })
    };

    const onSelectChange = (e, name) => {
        setFilters({
            ...filters,
            [name]: e.itemData
        })
    };

    const onDateChange = (e, name) => {
        setFilters({
            ...filters,
            [name]: {
                startDate: e.startDate,
                endDate: e.endDate
            }
        })
    };

    const deleteModal = () => {
        setOpenModalApprove(false);
        setOpenModalDisapprove(false);
    }

    const Img = useCallback((i) => {
        return (
            <img src={`${process.env.REACT_APP_API_URL}/_api/CoreVideos/GetVideoThumbnail?token=${globalState.tokenLightWeight}&IdOrGuid=${i.id}&hash=${Date.now()}`} alt="" />
        )
    }, []);

    const onRepproveModal = (id) => {
        setComentario("");
        setOpenModalDisapprove(true);
        changeCurrentItem(id);
    };

    const onApproveModal = (id) => {
        setComentario("");
        setOpenModalApprove(true);
        changeCurrentItem(id);
    };

    const onSubmitClick = async (id) => {

        setOpenModalApprove(false);
        setOpenModalDisapprove(false);
        setIsLoadingItem(true);

        const videoIndex = [...pendingVideos.value].findIndex(video => video.id ===currentItem.id);
        const pendingData = [...pendingVideos.value];
        const approvedData = { ...approvedVideos };

        const currentStatus = statusList.find(status => status.id ===id);

        pendingData[videoIndex] = {
            ...pendingData[videoIndex],
            nucleoVideoStatus: currentStatus,
            comentario,
            aprovadoEm: new Date()
        }

        const payload = {
            id: pendingData[videoIndex].id,
            aprovado: id ===2 ? true : false,
            comentario
        }

        const res = await approveReproveVideo(globalState.accessToken, JSON.stringify(payload)).then(res => {
            if (res.ok)
                return res.json();
        });

        if (res) {
            if (id ===2) {
                const [newApprovedItem] = pendingData.splice(videoIndex, 1);

                setPendingVideos(state => ({ ...state, value: pendingData }))

                if (pendingVideos["@odata.nextLink"]) {
                    const skipNumber = pendingVideos.value.length - 1;

                    let skipString = pendingVideos["@odata.nextLink"].split("skip=");

                    skipString[1] = skipNumber;

                    skipString = skipString.join("skip=");

                    const pendingVideosList = await getVideosFour({
                        token: globalState.accessToken,
                        paginationUrl: skipString
                    }).then(res => {
                        if (res.ok)
                            return res.json();
                    });

                    if (pendingVideosList && pendingVideosList.value && pendingVideosList.value.length > 0) {

                        let paginationUrl = `${process.env.REACT_APP_API_URL}/_api/CoreVideoPaginationFour?$apply=filter(nucleoVideoStatus/id%20ne%202)&$orderby=enviadoEm%20desc&$skip=${pendingData.length + 1}`;

                        if (pendingVideosList.value.length <= 1) {
                            paginationUrl = "";
                        }

                        const newVideos = {
                            ...pendingVideos,
                            value: [
                                ...pendingData,
                                pendingVideosList.value[0],
                            ]
                        };

                        newVideos["@odata.nextLink"] = paginationUrl;

                        setPendingVideos(newVideos);
                    } else {
                        setPendingVideos(videos => ({
                            paginationUrl: "",
                            value: [
                                ...videos.value
                            ]
                        }))
                    }
                }

                let paginationUrl = `${process.env.REACT_APP_API_URL}/_api/CoreVideoPaginationEight?$apply=filter(nucleoVideoStatus/id%20eq%202)&$orderby=enviadoEm%20desc&$skip=${approvedData.value.length}`;
                
                approvedData["@odata.nextLink"] = paginationUrl;
                
                const lastIndexDate = approvedData.value[approvedData.value.length - 1].enviadoEm;

                if (new Date(newApprovedItem.enviadoEm) > new Date(lastIndexDate) && approvedData && approvedData.value && approvedData.value.length % 8 ===0) {
                    approvedData.value.unshift(newApprovedItem);

                    approvedData.value.pop();

                    approvedData.value.sort((a, b) => new Date(b.enviadoEm) - new Date(a.enviadoEm))
                }

                if (approvedData && approvedData.value && approvedData.value.length % 8 !== 0) {
                    approvedData.value.unshift(newApprovedItem);

                    approvedData.value.sort((a, b) => new Date(b.enviadoEm) - new Date(a.enviadoEm));

                    approvedData["@odata.nextLink"] = "";
                }


                setApprovedVideos(approvedData);

                buildDialogMessage({
                    titlePt: "Sucesso",
                    titleEn: "Success",
                    messagePt: "Vídeo aprovado com sucesso",
                    messageEn: "Video successfully approved",
                    type: 'success',
                    icon: 'mdl2-confirm',
                    show: true,
                });
            } else {
                buildDialogMessage({
                    titlePt: "Sucesso",
                    titleEn: "Success",
                    messagePt: "Vídeo reprovado com sucesso",
                    messageEn: "Video successfully reproved",
                    type: 'error',
                    icon: 'mdl2 mdl2-disapprove',
                    show: true,
                });


                setPendingVideos(video => ({
                    ...video,
                    value: [
                        ...pendingData
                    ],
                }))
            }

        } else {
            setOpenModalApprove(false);
            setOpenModalDisapprove(false);

            buildDialogMessage({
                titlePt: "Erro",
                titleEn: "Error",
                messagePt: "Um erro ocorreu ao aprovar/reprovar o vídeo",
                messageEn: "An error has ocurred while approving/disapproving the video ",
                type: 'error',
                icon: 'e-icon-dlg-close e-icons',
                show: true,
            });

        }


        setIsLoadingItem(false);
    }

    return (
        <>
            <Container>
                <Row>
                    <div className="col-12">
                        <div className={`busca ${isVisible ? "aberto" : ""}`}>
                            <div className="filtroUsuarios d-flex">
                                <div className="SearchTitle">
                                    <span className="mdl2 mdl2-search"></span>
                                    <p>{t('Search:title')}</p>
                                </div>
                                <div className="searchUsers">
                                    <div className="e-input-in-wrap">
                                        <TextBoxComponent
                                            id="titulo"
                                            name="titulo"
                                            className="e-input"
                                            placeholder={i18n.language ==="pt" ? "Busque pelo título..." : "Search by title..."}
                                            change={onChange}
                                        />
                                    </div>
                                </div>
                                {isLoadingItem && <div id="loadingItem" />}
                                <div className="btnBusca d-flex">
                                    {!isVisible &&
                                        <div className="btnFilter">
                                            <ButtonComponent className="e-outline filter" cssClass='e-primary filter' onClick={() => setIsVisible(!isVisible)}><span className="mdl2 mdl2-filter"></span>{t('FilterButtons:filter')}</ButtonComponent>
                                        </div>
                                    }
                                    {isVisible &&
                                        <div className="btnFilter">
                                            <ButtonComponent className="e-outline filter active-filter" cssClass='e-primary filter' onClick={() => setIsVisible(!isVisible)}><span className="mdl2 mdl2-filter"></span>{t('FilterButtons:filter')}</ButtonComponent>
                                        </div>
                                    }
                                    <div className="btnSearch">
                                        <ButtonComponent
                                            onClick={onSend}
                                            cssClass='e-primary search'
                                        >
                                            {t('FilterButtons:search')}
                                        </ButtonComponent>
                                    </div>
                                </div>
                            </div>
                            {isVisible &&
                                <div className="filtrosOcultos seis plus d-flex">
                                    <AutoCompleteComponent
                                        id="enviado"
                                        cssClass="e-filled"
                                        placeholder={t('DigitalMedia:sent_filter')}
                                        dataSource={users}
                                        fields={{ value: "nome" }}
                                        value={filters && filters.enviadoPor && filters.enviadoPor.nome}
                                        change={(e) => onAutoCompleteChange(e, "enviadoPor")}
                                    />
                                    <DropDownListComponent
                                        id="categoria"
                                        cssClass="e-filled"
                                        placeholder={t('DigitalMedia:category_filter')}
                                        dataSource={categoryList}
                                        fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                                        value={filters && filters.categoria && filters.categoria.id}
                                        change={(e) => onSelectChange(e, "categoria")}
                                        showClearButton

                                    />
                                    <DateRangePickerComponent
                                        id="enviadoEm"
                                        name="enviadoEm"
                                        cssClass="e-filled"
                                        placeholder={t('DigitalMedia:sentData_filter')}
                                        allowEdit={false}
                                        showClearButton={true}
                                        enablePersistence={persistence}
                                        created={() => setPersistence(true)}
                                        change={(e) => onDateChange(e, "enviadoEm")}
                                    />
                                    <DropDownListComponent
                                        id="pais"
                                        name="pais"
                                        cssClass="e-filled"
                                        placeholder={t('DigitalMedia:country_filter')}
                                        dataSource={countriesList}
                                        fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                                        value={filters && filters.pais && filters.pais.id}
                                        change={(e) => onSelectChange(e, "pais")}
                                        showClearButton

                                    />
                                    <DropDownListComponent
                                        id="regional"
                                        name="regional"
                                        cssClass="e-filled"
                                        placeholder="Regional"
                                        dataSource={regionalList}
                                        fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                                        value={filters && filters.regional && filters.regional.id}
                                        change={(e) => onSelectChange(e, "regional")}
                                        showClearButton
                                    />

                                    <AutoCompleteComponent
                                        id="aprovadoPor"
                                        name="aprovadoPor"
                                        cssClass="e-filled"
                                        placeholder={t('DigitalMedia:approved_filter')}
                                        dataSource={users}
                                        fields={{ value: "nome" }}
                                        value={filters && filters.aprovadoPor && filters.aprovadoPor.nome}
                                        change={(e) => onAutoCompleteChange(e, "aprovadoPor")}
                                    />
                                    <DropDownListComponent
                                        id="nucleoVideoStatus"
                                        name="nucleoVideoStatus"
                                        cssClass="e-filled"
                                        placeholder="Status"
                                        dataSource={statusList}
                                        fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                                        value={filters && filters.nucleoVideoStatus && filters.nucleoVideoStatus.id}
                                        change={(e) => onSelectChange(e, "nucleoVideoStatus")}
                                        showClearButton
                                    />
                                    <DateRangePickerComponent
                                        id="aprovadoEm"
                                        name="aprovadoEm"
                                        cssClass="e-filled"
                                        placeholder={t('DigitalMedia:approvedDate_filter')}
                                        allowEdit={false}
                                        showClearButton={true}
                                        created={() => setPersistence(true)}
                                        enablePersistence={persistence}
                                        change={(e) => onDateChange(e, "aprovadoEm")}
                                    />
                                    <TextBoxComponent
                                        id="file"
                                        name="nomeArquivoVideo"
                                        cssClass="e-filled"
                                        placeholder={t("DigitalMedia:name_filter")}
                                        value={filters && filters.nomeArquivoVideo}
                                        change={onChange}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </Row>
            </Container>
            <Container fluid={true}>
                <Row>
                    {
                        pendingVideos && pendingVideos.value.length > 0 &&
                        <>
                            <div className="mainWaitingDiv">
                                <Container>
                                    <Row>
                                        <h1 className="videoTitle">{t("DigitalMedia:waitingApproval")}</h1>
                                        <div className="waitingApproval">
                                            {pendingVideos.value.map((i, index) => (
                                                <div key={index} id={`item-${index}`} className="videosItems">
                                                    <div onClick={() => editModal(i.id)} className="videoInfo">
                                                        <Img id={i.id} />
                                                        <div className="playerVideo" />
                                                    </div>
                                                    <div className="videoContent">
                                                        <div onClick={() => dropDownItem(i.id)} className="subtitleImage">
                                                            <span className="subtitle">{i.titulo}</span>
                                                            <span id={`spin-${i.id}`} className="arrowDiv mdl2 mdl2-arrowUp" />
                                                        </div>
                                                        <div className="innerContent">
                                                            <img src={`${process.env.REACT_APP_API_URL}/_api/Users/GetUserPhoto?token=${globalState.tokenLightWeight}&userId=${i.enviadoPor.id}&sizeType=31`} alt="" />

                                                            <div>
                                                                <label>{i.enviadoPor.nome}</label>
                                                                <span>{getDateTime(i.enviadoEm)}</span>
                                                            </div>
                                                            <label className={`label ${getStatusLabel(i.nucleoVideoStatus.nomePt)}`}>{i18n.language ==="pt" ? i.nucleoVideoStatus.nomePt : i.nucleoVideoStatus.nomeEn}</label>
                                                        </div>
                                                        {openedItems.findIndex(items => items ===i.id) > -1 && <VideoContent {...videoProps(pendingVideos.value[index])} />}
                                                        {
                                                            (
                                                                (hasPermissions(21, "Approval", globalState) &&
                                                                    i.aprovadoPor.id ===globalState.userId) ||
                                                                hasPermissions(21, "Admin", globalState)
                                                            )
                                                            && i.nucleoVideoStatus.id === 1 &&
                                                            <div className="bottomActions">
                                                                <button className="e-control e-btn e-lib e-flat buttons disapprove" onClick={() => onRepproveModal(i.id)}>{t("DigitalMedia:disapprove")}</button>
                                                                <button className="e-control e-btn e-lib e-flat buttons approve" onClick={() => onApproveModal(i.id)}>{t("DigitalMedia:approve")}</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        {isLoadingPendingVideos &&
                                            <div id="loadingMorePending" className="loading" />
                                        }
                                    </Row>
                                    <Row>
                                        {pendingVideos && pendingVideos["@odata.nextLink"] &&
                                            <div className="loadMoreDiv">
                                                <button onClick={() => loadMorePending()}>{t("DigitalMedia:loadMore")}</button>
                                            </div>
                                        }
                                    </Row>
                                </Container>
                            </div>
                        </>
                    }
                </Row>
            </Container>
            <Container>
                <Row>
                    <div className="videoContentBox">
                        <label className="videoTitle">{t('DigitalMedia:videos')}</label>
                        <div>
                            <div className="approved">
                                {approvedVideos && approvedVideos.value && approvedVideos.value.length > 0 ? (
                                    approvedVideos.value.map((i, index) => (
                                        <div key={index} id={`item-${index}`} className="videosItems">
                                            <div onClick={() => editModal(i.id)} className="videoInfo">
                                                <Img id={i.id} />
                                                <div className="playerVideo" />
                                            </div>
                                            <div className="videoContent">
                                                <div onClick={() => dropDownItem(i.id)} className="subtitleImage">
                                                    <span className="subtitle">{i.titulo}</span><span id={`spin-${i.id}`} className="arrowDiv mdl2 mdl2-arrowUp" />
                                                </div>
                                                <div className="innerContent">
                                                    <img src={`${process.env.REACT_APP_API_URL}/_api/Users/GetUserPhoto?token=${globalState.tokenLightWeight}&userId=${i.enviadoPor.id}&sizeType=31`} alt="" />
                                                    <div>
                                                        <label>{i.enviadoPor.nome}</label>
                                                        <span>{getDateTime(i.enviadoEm)}</span>
                                                    </div>
                                                    <label className="label approvedLabel">{i18n.language ==="pt" ? i.nucleoVideoStatus.nomePt : i.nucleoVideoStatus.nomeEn}</label>
                                                </div>
                                                {openedItems.findIndex(items => items ===i.id) > -1 && <VideoContent {...videoProps(approvedVideos.value[index])} />}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="notFound">{t("DigitalMedia:notFound")}</p>
                                )}
                            </div>
                            {isLoadingApprovedVideos && <div id="loadingMoreApproved" className="loading" />}

                            {approvedVideos && approvedVideos["@odata.nextLink"] &&
                                <div className="loadMoreDiv">
                                    <button onClick={() => loadMoreApproved()}>{t("DigitalMedia:loadMore")}</button>
                                </div>
                            }
                        </div>
                    </div>
                </Row>
            </Container>

            <DialogComponent
                id="approveModal"
                className="changesModal"
                isModal={true}
                visible={openModalApprove}
                // buttons={modalApproveButtons}
                locale={i18n.language}
            >
                <div>
                    <div className="divIcon Approve">
                        <label className="mdl2 mdl2-confirm"></label>
                        <div className="phraseDelete">
                            <label className="labelModal approve">{t("DigitalMedia:approve")}</label>
                            <label id="labelDelete">{t("DigitalMedia:modalApproval")}</label>
                        </div>
                    </div>
                    <hr />
                    <div className="content">
                        <span>{t("DigitalMedia:comment")}</span>
                        <TextBoxComponent
                            id="textArea"
                            multiline={true}
                            value={comentario}
                            change={(e) => setComentario(e.value)}
                            placeholder={i18n.language ==="pt" ? "Deixe aqui seu comentário" : "Leave your review here ..."}
                        />
                    </div>
                </div>
                <div class="approve e-footer-content">
                    <button onClick={() => deleteModal()} class="e-control e-btn e-lib e-primary e-flat" data-ripple="true">Cancelar</button>
                    <button onClick={() => onSubmitClick(2)} class="e-control e-btn e-lib e-primary e-flat" data-ripple="true">Aprovar</button>
                </div>
            </DialogComponent>

            <DialogComponent
                id="disapproveModal"
                className="changesModal"
                isModal={true}
                visible={openModalDisapprove}
                // buttons={modalDisapproveButtons}
                locale={i18n.language}
            >
                <div>
                    <div className="divIcon Disapprove">
                        <label className="mdl2 mdl2-disapprove"></label>
                        <div className="phraseDelete">
                            <label className="labelModal delete">{t("DigitalMedia:disapprove")}</label>
                            <label id="labelDelete">{t("DigitalMedia:modalDisapproval")}</label>
                        </div>
                    </div>
                    <hr />
                    <div className="content">
                        <span>{t("DigitalMedia:comment")}</span>
                        <TextBoxComponent
                            id="textArea"
                            multiline={true}
                            change={(e) => setComentario(e.value)}
                            placeholder={i18n.language ==="pt" ? "Deixe aqui seu comentário" : "Leave your review here ..."}
                        />
                    </div>
                </div>
                <div class="disapprove e-footer-content">
                    <button onClick={() => deleteModal()} class="e-control e-btn e-lib e-primary e-flat" data-ripple="true">Cancelar</button>
                    <button onClick={() => onSubmitClick(3)} class="e-control e-btn e-lib buttonDisapprove e-primary e-flat" data-ripple="true">Reprovar</button>
                </div>
            </DialogComponent>
        </>
    );
}