import * as React from "react";
import { SharedDocumentsComponent } from './SharedDocumentsComponent';
import { useTranslation } from "react-i18next";

export const SpecialProjects = () => {
    const { t } = useTranslation();

    return <SharedDocumentsComponent 
        permissionId="71" 
        path="Projetos Especiais"
        folderManagerTitle={t("EmpCommunication:special_projects")}
        title={t("EmpCommunication:special_projects")} 
    />
}