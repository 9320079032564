import * as React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row } from "reactstrap";
import { ReportComponent } from "./ReportComponent";
import { FilterComponent } from "./FilterComponent";
import { LiaisonReportStateContext } from "./LiaisonReportState";
import '../liaisonReport/liaisonReport.css';
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";

export const LiaisonReport = () => {

    const { t } = useTranslation();
    const [liaisonReportState] = React.useContext(LiaisonReportStateContext);

    return (
        <>
        <PermissionRedirect id="8" />
            <div id="barOnTop"></div>
            <Container className="containerTop" id="leftContainer">
                <Row>
                    <div className="col-12">
                        <div className="labelOnTop">
                            {t("LiaisonReport:title")}
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className="col-lg-4 col-12">
                        <FilterComponent />
                    </div>
                    {
                        liaisonReportState &&
                        liaisonReportState.data &&
                        liaisonReportState.data.length > 0 &&

                        <div className="col-lg-8 col-12">
                            <ReportComponent />
                        </div>
                    }
                </Row>
            </Container>
        </>
    )
}
