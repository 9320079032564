import React from 'react';
import './MessageModal.css';
import i18n from 'i18next';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

function GenericModal({ params }) {
  const [show, setshow] = React.useState();
  React.useEffect(() => {
    setshow(params.show);
  }, [params]);
  return show ? (
    <DialogComponent
      id="messageModal"
      isModal={true}
      beforeClose={() => { setshow(false) }}
      showCloseIcon={params.actionType ==="approval"}
      visible={show}
      style={{ maxHeight: '231px !important', zIndex: '2000' }}
      locale={i18n.language}
    >
      <div>
        {/* MODAL HEADER */}
        <div className={`divTrash ${params.type !== 'success' && 'red'}`}>
          <span
            className={`${params.type === 'success' ? 'success' : 'error'
              } mdl2 ${params.icon}`}
          ></span>
          <div className="phraseModal">
            <label id="title" className={`${params.type !== 'success' && 'red'}`}>
              {params.title}
            </label>
            {
              params.actionType ==="approval" &&
              <label id="secondLabel" className={`${params.type !== 'success' && 'red'}`}>
                {i18n.language ==='pt' ? 'Escolha uma opção' : "Choose an option"}
              </label>
            }
            {
              params.actionType ==="confirm" &&
              <label id="secondLabel">
                {i18n.language ==='pt' ? 'Certeza que deseja executar essa ação ?' : "Are you sure you want to perform this action?"}
              </label>
            }
          </div>
        </div>
        <hr />

        {/* MODAL BODY */}
        <div className="messageComplete">
          {/* BODY INFORMATIVO */}
          {!params.actionType &&
            <>
              <div className={`confirmTrash ${params.type !== 'success' && 'red'}`}>
                <span
                  className={`${params.type === 'success' ? 'success' : 'error'
                    } mdl2 ${params.icon}`}
                ></span>
              </div>
              <label className={`${params.type !== 'success' && 'red'}`}>
                {params.message}
              </label>
              <button
                onClick={() => {
                  setshow(false);
                  if (params.exitCallback) {
                    params.exitCallback()
                  }
                }}
              >
                {i18n.language ==='pt' ? 'Sair' : 'Exit'}
              </button>
            </>
          }
          {/* BODY APROVAÇÃO */}
          {params.actionType ==="approval" &&
            <div className="deleteComplete">
              <div className="footerButtons">
                <button className={'reproveButton'}
                  onClick={params.reproveCallback}>
                  {i18n.language ==="pt" ? "Reprovar" : "Reprove"}
                </button>
                <button
                  onClick={params.approveCallback}>
                  {i18n.language ==="pt" ? "Aprovar" : "Approve"}</button>
              </div>
            </div>
          }
          {/* BODY CONFIRMAÇÃO */}
          {params.actionType ==="confirm" &&
            <div className="deleteComplete">
              <div className="footerButtons">
                <button
                  onClick={params.confirmNotOkCallback}>
                  {i18n.language ==="pt" ? "Não" : "No"}
                </button>
                <button
                  onClick={params.confirmOkCallback}>
                  {i18n.language ==="pt" ? "Sim" : "Yes"}</button>
              </div>
            </div>
          }

        </div>
      </div>
    </DialogComponent>
  ) : null
}
export default GenericModal;