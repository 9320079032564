import { requestAPI } from '../components/utilities/Utils';

export async function getBanners(token, idioma) {
    const qstring = idioma ? `?$filter=IdIdioma eq ${idioma}&$orderby=ativo desc, id desc` : '?$orderby=ativo desc, id desc';
    return requestAPI("_api/BannerHome/GetBannersHome", "GET", qstring, null, token, false);
}

export async function updateBanners(token, body) {
    const raw = JSON.stringify(body);
    return requestAPI("_api/BannerHome/UpdateBannerHome", "POST", "", raw, token, false);
}

export async function addBanners(token, body) {
    const raw = JSON.stringify(body);
    return requestAPI("_api/BannerHome/AddBannerHome", "POST", "", raw, token, false);
}

export async function deleteBanners(token, id) {
    const qstring = `?bannerId=${id}`;
    return requestAPI("_api/BannerHome/DeleteBannerHome", "DELETE", qstring, null, token, false);
}