import React from 'react';
import { ReactComponent as ReactLogo } from '../../css/imgs/logo_vale.svg';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { LanguageComponent } from '../general/LanguageComponent.js';
import { GlobalStateContext } from '../general/GlobalState';
import { useTranslation } from 'react-i18next';
import { AdminMenu } from './AdminMenu';
import { MainMenu } from './MainMenu';
import './menu.css';
import { MainMenuMobile } from './MainMenuMobile';

export const NavMenu = () => {
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const [collapsed, setCollapsed, toLogin, setToLogin] = React.useState(false);

    const { t } = useTranslation();

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    const logout = () => {
        setGlobalState(globalState => ({ ...globalState, accessToken: null }));
        localStorage.removeItem("NovoCGCUserCache");
    };
    

    return (
        <header className="geral">
            <Navbar className="first navbar-expand-md navbar-toggleable-md bg-white border-bottom" light>
                <Container>
                    <div className="d-flex flex-row align-items-bottom mr-md-auto">
                        {globalState.accessToken &&
                            <NavbarToggler className="menuMobile mr-2" onClick={toggleNavbar} />
                        }
                        <ReactLogo id="logo" />
                        {globalState.accessToken ?
                            <>
                            <NavbarBrand id="title" tag={Link} to="/">{t('Generic:title')}</NavbarBrand>
                            <NavbarBrand id="initials" tag={Link} to="/">{t('Generic:initials')}</NavbarBrand>
                            </>
                            :
                            <>
                            <NavbarBrand id="title">{t('Generic:title')}</NavbarBrand>
                            <NavbarBrand id="initials">{t('Generic:initials')}</NavbarBrand>
                            </>
                        }
                    </div>
                    <div className="d-flex flex-row align-items-bottom">
                        <LanguageComponent />
                        {globalState.accessToken &&
                        <AdminMenu />
                        }
                    </div>
                </Container>
            </Navbar>
            {globalState.accessToken &&
            <Navbar className={`second navbar-expand-md navbar-toggleable-md border-bottom pt-0 pb-0 ${collapsed && 'collapsed'}`} light>
                <Container>
                    {collapsed ?
                        <MainMenuMobile close={toggleNavbar}/>
                        :
                        <MainMenu />
                    }
                </Container>
            </Navbar>
            }
        </header>
    );
}