import { requestAPI } from '../components/utilities/Utils';

export async function login(loginModel) {
    const raw = JSON.stringify({ "Email": loginModel.email, "Senha": loginModel.password });
    return requestAPI("_api/Users/Token", "POST", "", raw, null, false);
}

export async function forgot(userEmail) {
    const qstring = "?_userEmail=" + userEmail;
    return requestAPI("_api/Users/ForgotPassword", "POST", qstring, null, null, false);
}

export async function reset(token, newPassword) {
    const newData = encodeURIComponent(newPassword);
    const qstring = `?_token=${token}&_newPassword=${newData}`;
    return requestAPI("_api/Users/ResetPassword", "POST", qstring, null, null, false);
}

export async function users(token) {
    return requestAPI("_api/Users/GetUsers", "GET", "?$orderby=nome", null, token, false);
}

export async function getProfile(token, userId) {
    return requestAPI("_api/Users/GetProfile", "GET", userId ? `?userId=${userId}` : "", null, token, false);
}

export function getProfilePhoto(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
        if (xhr.readyState ===4) {
            callback(this.response)
        }
    }
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export async function usersFull(token, userId) {
    const filter = userId ? `?$filter=userId eq ${userId}` : "";

    return new Promise((resolve, reject) => {
        requestAPI("_api/Users/GetUsersFull", "GET", filter, null, token, false)
            .then(response => {
                if (response.ok)
                    resolve(response.json());
                else
                    reject(null);
            })
            .catch(error => {
                console.error(error);
                reject(null);
            });
    });
}

export async function addOrUpdateUsersFull(token, body) {
    const raw = JSON.stringify(body);
    const endPointURL = body.userId ? "_api/Users/UpdateUserFull" : "_api/Users/AddUserFull"
    return requestAPI(endPointURL, "POST", "", raw, token, false);
}

export async function getMyProfile(token) {
    return requestAPI("_api/Users/GetMyProfile", "GET", "", null, token, false);
}

export async function updateMyProfile(token, body) {
    const raw = JSON.stringify(body);
    return requestAPI("_api/Users/UpdateMyProfile", "POST", "", raw, token, false);
}

export async function update_password(token, newPassword) {
    const newData = encodeURIComponent(newPassword);
    const qstring = `?_newPassword=${newData}`;
    return requestAPI("_api/Users/UpdatePassword", "POST", qstring, null, token, false);
}

export async function fullReset(token, userId) {
    const qstring = `?userId=${userId}`;
    return requestAPI("_api/Users/ResetUserFullPassword", "POST", qstring, null, token, false);
}

export async function deleteUser(token, id) {
    const qstring = `?userId=${id}`;
    return requestAPI("_api/Users/DeleteUserFull", "DELETE", qstring, null, token, false);
}

export async function getFilteredUsers(token, username) {
    let filters = username ? `?$apply=filter(contains(tolower(nome),'${username.trim().toLowerCase()}'))&orderBy=nome desc&$top=10` : "?$top=10";
    return requestAPI("_api/Users/GetWhoIsWhoUsers", "GET", filters, null, token, false);
};

export async function getHorizontalUsers(token, id) {
    const qString = "?fullName=false&$top=17";
    return requestAPI("_api/Users/GetWhoIsWhoUsers", "GET", qString, null, token, false);
};

export async function getWhoisWhoUsersFull(token) {
    return requestAPI("_api/Users/GetWhoIsWhoUsersFull", "GET", "", null, token, false);
};

export async function getChartObj(token, userId) {
    const filters = userId && `?userId=${userId}`
    return requestAPI("_api/Users/GetWhoIsWhoDataChart", "GET", filters, null, token, false);
};
