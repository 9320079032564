import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { FileManagerComponent, Inject, NavigationPane, DetailsView, Toolbar } from '@syncfusion/ej2-react-filemanager';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { downloadFile } from "../../services/SharedDocumentsSVC";
import { sharePublicAccess } from "../../services/SharedDocumentsSVC";

let fileManagerObj;
function FileManager({ parentProps, data, modal, setLoading, setUrl, setShowCopyPaste }) {
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const toastInstance = React.useRef(null);

    const { t } = useTranslation();

    const qstring = parentProps.path !=='/' ? `?path=/${parentProps.path}` : '';

    const beforeSend = (args) => {
        if (fileManagerObj)
            console.log('path', fileManagerObj.path);
        // if (parentProps.path !== "/") {
        //     let data = JSON.parse(args.ajaxSettings.data);
        //     if(Array.isArray(data)){

        //         if(data.length){
        //             let dataObj = data[0];
        //             if (!dataObj.path.includes(parentProps.path)) {
        //                 dataObj.path = dataObj.path.replace('/', `/${parentProps.path}/`);
        //             }
        //             args.ajaxSettings.data = JSON.stringify(data);
        //         }
        //     }
        //     else{
        //         if (!data.path.includes(parentProps.path)) {
        //             data.path = data.path.replace('/', `/${parentProps.path}/`);
        //         }
        //         args.ajaxSettings.data = JSON.stringify({
        //             ...data,
        //             path: data.path
        //         })
        //     }

        // }

        args.ajaxSettings.beforeSend = function (args) {
            args.httpRequest.setRequestHeader("Authorization", "Bearer " + globalState.accessToken);
            args.httpRequest.setRequestHeader("lang", i18n.language);
        }
    };

    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const beforeLoadImage = (args) => {
        const token = parentProps.token || globalState.tokenLightWeight;
        args.imageUrl = `${args.imageUrl}'&token=${token}`;

        const pathQString = getParameterByName('path', args.imageUrl);
        if (pathQString.indexOf(`/${parentProps.path}`) !== 0) {
            const imgUrl = args.imageUrl.replace(pathQString, `/${parentProps.path}${pathQString}`);
            args.imageUrl = `${imgUrl}&token=${token}`;
        }
    };

    const beforeDownload = (args) => {
        console.log({...args})
        args.cancel = true;
        const token = parentProps.token ? null : globalState.accessToken;
        const filename = args.data.data.length ===1 && args.data.data[0].isFile ? args.data.data[0].name : "Files.zip";
        args.data.token = parentProps.token;

        downloadFile(parentProps.public, token, qstring, args.data)
            .then((response) => {
                if (response.ok) {
                    toastInstance.current.show(toasts[0]);
                    response.blob().then((blob) => {
                        toastInstance.current.show(toasts[1]);
                        const url = window.URL.createObjectURL(new Blob([blob]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                    });
                }
            });
    };

    const failed = (args) => {
        if (args.error !== undefined && args.error.code === "401") {
            args.error.message = "Você não tem permissão para executar esta ação.";
        }

    }

    const menuOpen = (args) => {
        if (fileManagerObj.selectedItems.length > 1 || (args.fileDetails.length === 1 && args.fileDetails[0].name === 'CGC')) {
            fileManagerObj.disableMenuItems(["Share"]);
        }

        for (const i in args.items) {
            if (args.items[i].id === fileManagerObj.element.id + '_cm_share') {
                args.items[i].iconCss = 'e-icons e-fe-share';
            }

            if (args.items[i].id === fileManagerObj.element.id + '_cm_generate') {
                args.items[i].iconCss = 'e-icons e-create-link';
            }
        }
    }

    const menuClick = (args) => {
        if (args.item.id === 'azure-file_cm_share') {
            const fileDetails = args.fileDetails[0];
            const filePath = fileDetails.filterPath + fileDetails.name;
            const isFolder = fileDetails.type === "Directory";
            data({ caminho: filePath, pasta: isFolder });
            modal(true);
        }

        if (args.item.id === 'azure-file_cm_generate') {
            const fileDetails = args.fileDetails[0];
            const filePath = fileDetails.filterPath
            const isFolder = fileDetails.type === "Directory";

            setLoading(true);

            sharePublicAccess(globalState.accessToken, { caminho: filePath, fileName: fileDetails.name, pasta: isFolder }).then(async response => {
                if (response.ok) {
                    const url = await response.text();
                    setUrl(url);
                    setLoading(false);
                    setShowCopyPaste(true);
                }
            });
        }
    }

    const toasts = [
        { title:  i18n.language ==='pt' ? 'Download iniciado !' : 'Download started !', content: i18n.language ==='pt' ? 'Esta operação pode durar alguns minutos dependendo do tamanho do arquivo selecionado.': 'This operation may take a few minutes depending on the size of the selected file.', cssClass: 'e-toast-warning', icon: 'e-warning toast-icons' },
        { title:  i18n.language ==='pt' ? 'Download concluído !' : 'Download completed !', content: i18n.language ==='pt' ? 'Download concluído com sucesso.' : 'Download completed successfully.' , cssClass: 'e-toast-success' }
    ];

    const publicContextMenuSettings = {
        file: ['Open', '|', 'Download'],
        folder: ['Open', '|', 'Download'],
        layout: ['SortBy', 'View', 'Refresh', '|', 'Details', '|', 'SelectAll']
    };

    const contextMenuSettings = {
        file: ['Open', '|', 'Cut', 'Copy', '|', 'Delete', 'Rename', '|', 'Details', '|', 'Share', 'Generate'],
        folder: ['Open', '|', 'Cut', 'Copy', 'Paste', '|', 'Delete', 'Rename', '|', 'Details', '|', 'Share'],
        layout: ['SortBy', 'View', 'Refresh', '|', 'Paste', '|', 'NewFolder', 'Upload', '|', 'Details', '|', 'SelectAll'],
    };

    return (
        <>
            <FileManagerComponent view="Details" id="azure-file"
                ref={fileManager => fileManagerObj = fileManager}
                ajaxSettings={{
                    url: parentProps.public
                    ? parentProps.token
                      ? `${process.env.REACT_APP_API_URL}/_api/SharedDocuments/GetPublicSharedDocuments?token=${parentProps.token}`
                      : `${process.env.REACT_APP_API_URL}/_api/SharedDocuments/GetPublicSharedDocumentsTokenless`
                    : `${process.env.REACT_APP_API_URL}/_api/SharedDocuments/SharedDocumentsOperations${qstring}`,
                    getImageUrl: parentProps.public
                    ? parentProps.token
                      ? `${process.env.REACT_APP_API_URL}/_api/SharedDocuments/PublicSharedDocumentsGetImage`
                      : `${process.env.REACT_APP_API_URL}/_api/SharedDocuments/PublicSharedDocumentsGetImageTokenless`
                    : `${process.env.REACT_APP_API_URL}/_api/SharedDocuments/SharedDocumentsGetImage`,
                    uploadUrl: `${process.env.REACT_APP_API_URL}/_api/SharedDocuments/SharedDocumentsUpload${qstring}`,
                    //downloadUrl: parentProps.public ? `${process.env.REACT_APP_API_URL}/_api/SharedDocuments/PublicSharedDocumentsDownload`
                    //                                :`${process.env.REACT_APP_API_URL}/_api/SharedDocuments/SharedDocumentsDownload`
                }}
                locale={i18n.language}
                path={parentProps.path}
                allowDragAndDrop={!parentProps.public}
                beforeSend={beforeSend}
                beforeImageLoad={beforeLoadImage}
                beforeDownload={beforeDownload}
                uploadSettings={{ minFileSize: 0, maxFileSize: 5e+8 }}
                failure={failed}
                toolbarSettings={{ visible: !parentProps.public }}
                detailsViewSettings={{
                    columns: [{ field: 'name', headerText: t('MyDocuments:column_name'), customAttributes: { class: 'e-fe-grid-name' }, minWidth: 30, template: '<span class="e-fe-text">${name}</span>' },
                    { field: '_fm_modified', headerText: 'DateModified', type: 'dateTime', format: 'MMMM dd, yyyy HH:mm', width: '0' },
                    { field: 'size', headerText: 'Size', width: '0', template: '${size}' }]
                }}
                contextMenuSettings={parentProps.public ? publicContextMenuSettings : contextMenuSettings}
                menuClick={menuClick}
                menuOpen={menuOpen}
            >
                <Inject services={[NavigationPane, DetailsView, Toolbar]} />
            </FileManagerComponent>
            <ToastComponent ref={toast => toastInstance.current = toast} position={{ X: 'Right' }} />
        </>
    )
}
export default React.memo(FileManager, (prev, next) => prev.value === next.value);
