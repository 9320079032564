import * as React from "react";
import i18n from "i18next";
import { Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { AccordionComponent, AccordionItemsDirective, AccordionItemDirective } from "@syncfusion/ej2-react-navigations";
//import { Inject } from "@syncfusion/ej2-react-dropdowns";
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Sort, ContextMenu, Page, Resize, Inject } from "@syncfusion/ej2-react-grids";
import { Tooltip } from '@syncfusion/ej2-react-popups';
import { DataUtil } from "@syncfusion/ej2-data";
import * as signalR from '@microsoft/signalr'
import { ReleaseStateContext } from "../releases/ReleaseState";
import { getMailingShippingStatusItems } from "../../services/PressSVC";
import { getMailingStatus } from "../../services/SystemSVC";
import { ModalReleases } from "../releases/ModalReleases";
import './releases.css';

let grid;
let tooltip;
export const MailingStatus = ({ itemId }) => {

    const [globalState] = React.useContext(GlobalStateContext);
    const [accordionStatus, setAccordionStatus] = React.useState(false);
    const [mailingStatus, setMailingStatus] = React.useState(null);
    const [gridSource, setGridSource] = React.useState(null);
    const [mailingStatusSelected, setMailingStatusSelected] = React.useState(0);
    const accordionRef = React.useRef(null);
    const mailingStatusRef = React.useRef(null);

    const { t } = useTranslation();
    const contextMenuItems = ['SortAscending', 'SortDescending', 'FirstPage', 'PrevPage', 'LastPage', 'NextPage'];

    React.useEffect(() => {

        getMailingStatus(globalState.accessToken).then(response => {
            if (response.ok) {
                response.json().then(result => {
                    mailingStatusRef.current = result;
                    setMailingStatus(result);
                });
            }
        });

        // const connection = new signalR.HubConnectionBuilder()
        //     .withUrl(`${process.env.REACT_APP_AZFUNCTION_URL}/api/MailingStatus`)
        //     .configureLogging(signalR.LogLevel.Information)
        //     .withAutomaticReconnect()
        //     .build();

        // connection.on('mailingStatus', mailingStatusReceiver);
        // connection.onclose(() => console.log('disconnected'));
        // connection.start().then(() => console.log('connected')).catch(console.error);

        // accordionRef.current.addItem({
        //     expanded: true,
        //     header: `<div class="title d-flex flex-row align-items-center">
        //         <div class="mdl2 mdl2-sending"> </div>
        //         <h2>${t("Press:shippingStatus")}</h2>
        //     </div>`,
        //     content: contentStatus
        // });
        // accordionRef.current.removeItem(0)
        // accordionRef.current.select(0);

    }, []);

    React.useEffect(() => {
        if (mailingStatus) {
            loadMailingShippingStatus();
        }
    }, [mailingStatus])

    React.useEffect(() => {
        if (gridSource) {
            grid.dataSource = gridSource;
            // const connection = new signalR.HubConnectionBuilder()
            //     .withUrl(`${process.env.REACT_APP_AZFUNCTION_URL}/api/MailingStatus`)
            //     .configureLogging(signalR.LogLevel.Information)
            //     .withAutomaticReconnect()
            //     .build();

            // connection.on('mailingStatus', mailingStatusReceiver);
            // connection.onclose(() => console.log('disconnected'));
            // connection.start().then(() => console.log('connected')).catch(console.error);

            // accordionRef.current.addItem({
            //     expanded: true,
            //     header: `<div class="title d-flex flex-row align-items-center">
            //         <div class="mdl2 mdl2-sending"> </div>
            //         <h2>${t("Press:shippingStatus")}</h2>
            //     </div>`,
            //     content: contentStatus
            // });
            // accordionRef.current.removeItem(0)
            // accordionRef.current.select(0);
        }
    }, [gridSource]);

    const mailingStatusReceiver = mailingShippingStatusItem => {
        if (grid && grid.dataSource && grid.dataSource.length > 0) {
            grid.dataSource.map((item, index) => {
                if (item.id === mailingShippingStatusItem.IdMailingStatusEnvio && itemId ===mailingShippingStatusItem.IdImprensa) {
                    const mailingStatusFiltered = mailingStatusRef.current.find(ms => ms.id === mailingShippingStatusItem.IdMailingStatus);
                    const data = [...grid.dataSource];

                    if (mailingShippingStatusItem.Sucesso)
                        data[index].sucesso = mailingShippingStatusItem.Sucesso;
                    if (mailingShippingStatusItem.Erro)
                        data[index].erro = mailingShippingStatusItem.Erro;

                    data[index].statusPt = mailingStatusFiltered.nomePt;
                    data[index].statusEn = mailingStatusFiltered.nomeEn;
                    setGridSource(data);
                } else if (itemId ===mailingShippingStatusItem.IdImprensa) {
                    loadMailingShippingStatus();
                }
            })
        }
    }

    const loadMailingShippingStatus = () => {
        getMailingShippingStatusItems(globalState.accessToken, itemId).then(response => {
            if (response.ok && mailingStatusRef.current) {
                response.json().then(result => {
                    result.map(item => {
                        const mailingStatusFiltered = mailingStatusRef.current.find(ms => ms.id === item.idMailingStatus);
                        if (mailingStatusFiltered) {
                            item.statusPt = mailingStatusFiltered.nomePt;
                            item.statusEn = mailingStatusFiltered.nomeEn;
                        }
                    })
                    setGridSource(result);
                });
            }
        });
    }

    const onGridRowSelected = (e) => {
        setMailingStatusSelected(e.data.id);
        setTimeout(() => grid.clearSelection())
    }

    const getGroups = () => {
        var data = [];
        var flag = true;
        var lookup = {};
        const groups = grid.dataSource.map(value => value.grupos);

        groups.forEach((arr) => {
            arr.forEach((item) => {
                if (!(item.nome in lookup)) {
                    data.push({ grupos: item.nome });
                    lookup[item.nome] = true
                }
            })
            if (!arr.length && flag) {
                data.push({ "": true })
            }
        });
        return data;
    }

    const gridCreated = () => {
        grid.dataSource = gridSource;
    }

    const gridActionBegin = (args) => {
        if (args.requestType === 'filterbeforeopen' && args.columnName === 'grupos') {
            args.filterModel.options.dataSource = getGroups();
        }
    }

    DataUtil.fnOperators.equal = function (rowValues, filterValue, ignoreCase, ignoreAccent) {
        if (Array.isArray(rowValues)) {
            if (!filterValue) {
                return !rowValues.length;
            }
            return JSON.stringify(rowValues).includes(filterValue)
        } else {
            if (ignoreAccent) {
                rowValues = DataUtil.ignoreDiacritics(rowValues);
                filterValue = DataUtil.ignoreDiacritics(filterValue);
            }
            if (ignoreCase) {
                return DataUtil.toLowerCase(rowValues) === DataUtil.toLowerCase(filterValue);
            }
            return rowValues === filterValue;
        }
    };

    DataUtil.fnOperators.notequal = function (rowValues, filterValue, ignoreCase, ignoreAccent) {
        if (Array.isArray(rowValues)) {
            if (!filterValue)
                return rowValues.length;
            else if (JSON.stringify(rowValues).includes(filterValue))
                return rowValues.length > 1;

            return !rowValues.length || !JSON.stringify(rowValues).includes(filterValue);

        } else {
            if (ignoreAccent) {
                rowValues = DataUtil.ignoreDiacritics(rowValues);
                filterValue = DataUtil.ignoreDiacritics(filterValue);
            }
            return !DataUtil.fnOperators.equal(rowValues, filterValue, ignoreCase);
        }
    };

    const renderCellGroup = (args) => {
        if (args.column.id === "grupos") {
            let content = "";

            args.data["grupos"].map((item, i) => {
                content += item.nome;
                if (i !== args.data["grupos"].length - 1)
                    content += ", ";
            });

            if (content) {
                tooltip = new Tooltip({
                    content: content
                }, args.cell);
            }
            return args.cell;
        }
    }

    const groupTemplate = (e) => {
        return (
            e.grupos.map(item => (
                <span className="dataGroup">{item.nome}</span>
            ))
        );
    }

    const successTemplate = (e) => {
        return (
            <span className="dataSuccess">{e.sucesso}</span>
        );
    }

    const erroTemplate = (e) => {
        return (
            <span className="dataErro">{e.erro}</span>
        );
    }
    React.useEffect(() => {
        if (grid) {
            grid.refresh();
        }

        if (accordionRef) {
            accordionRef.current.addItem({
                expanded: true,
                header: `<div class="title d-flex flex-row align-items-center">
                    <div class="mdl2 mdl2-sending"> </div>
                    <h2>${t("Press:shippingStatus")}</h2>
                </div>`,
                content: contentStatus
            });
        }

    }, [i18n.language]);

    const contentStatus = React.useCallback(() => {
        return (
            <>
                <div>
                    <GridComponent
                        id="gridRelease"
                        ref={gridComponent => grid = gridComponent}
                        contextMenuItems={contextMenuItems}
                        dataSource={gridSource}
                        filterSettings={{ type: 'CheckBox' }}
                        allowPaging={true}
                        allowSorting={true}
                        locale={i18n.language}
                        allowFiltering={true}
                        allowResizing={true}
                        pageSettings={{ pageSize: 4 }}
                        actionBegin={gridActionBegin}
                        queryCellInfo={renderCellGroup}
                        rowSelected={onGridRowSelected}
                        created={gridCreated}
                    >
                        <ColumnsDirective>
                            <ColumnDirective
                                id=""
                                field="id"
                                headerText="ID"
                                allowResizing={false}
                                width="75"
                            ></ColumnDirective>
                            <ColumnDirective
                                id=""
                                field="dataEnvio"
                                type="date"
                                format={{ type: "date", format: i18n.language === "pt" ? "d/M/y HH:mm" : "M/d/y HH:mm" }}
                                headerText={t('Press:sentDate')}
                                width="140"
                                filter={{ type: 'Menu' }}
                            ></ColumnDirective>
                            <ColumnDirective
                                id=""
                                field="nomeUsuario"
                                headerText={t('Press:responsible')}
                                autoFit={true}
                            ></ColumnDirective>
                            <ColumnDirective
                                id="grupos"
                                field="grupos"
                                template={groupTemplate}
                                headerText={t('Press:groups')}
                                width="200"
                            ></ColumnDirective>
                            <ColumnDirective
                                id=""
                                field={i18n.language === "pt" ? "statusPt" : "statusEn"}
                                headerText="Status"
                                width="159"
                            ></ColumnDirective>
                            <ColumnDirective
                                id=""
                                field="sucesso"
                                template={successTemplate}
                                headerText={t('Press:success')}
                                width="120"
                            ></ColumnDirective>
                            <ColumnDirective
                                id=""
                                field="erro"
                                template={erroTemplate}
                                headerText={t('Press:error')}
                                width="105"
                            ></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Resize, Sort, ContextMenu, Filter, Page,]} />
                    </GridComponent>
                </div>
            </>
        )
    }, [gridSource]);

    const CustomAccordion = React.useCallback(() => (
        <AccordionComponent
            id="status"
            locale={i18n.language}
            className={i18n.language}
            ref={accordionRef}
        >
        </AccordionComponent>
    ), [i18n.language]);

    return (
        <>
            <CustomAccordion />

            {mailingStatusSelected > 0 &&
                <ModalReleases
                    mailingStatusSelected={mailingStatusSelected}
                    setMailingStatusSelected={setMailingStatusSelected}
                />
            }
        </>
    )
}