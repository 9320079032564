import React, { useEffect, useState } from "react";
import { GlobalStateContext } from '../general/GlobalState'
import { useTranslation } from 'react-i18next';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Sort, Page, Group, AggregateColumnsDirective, AggregateDirective, AggregatesDirective, Aggregate, AggregateColumnDirective } from "@syncfusion/ej2-react-grids";
import i18n from "i18next";
import '../measurementReport/measurementReport.css';
import { getMeasurementItems } from '../../services/MeasurementReportSVC';
import { useModal } from '../../hooks/Modal';

export const Measurement = () => {

    const [globalState] = React.useContext(GlobalStateContext);

    const [items, setItems] = useState([]);

    const { t } = useTranslation();

    let gridInstance;

    const { buildDialogMessage } = useModal();

    const agreementTemplate = (args) => {
        return (
            <span className="contrato">{args.contrato}</span>
        );
    }

    useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language])

    const templateSumProduction = (args) => {
        return (
            <span className="sumClass">Sub-total: {(args.Sum).toLocaleString(i18n.language)}</span>
        );
    }

    const templateSum = (args) => {
        return (
            <span className="sumClass">{i18n.language ==="pt" ? "Soma" : "Sum"}: {(args.Sum).toLocaleString(i18n.language)}</span>
        );
    }

    useEffect(() => {
        async function getItems() {
            const measurementItems = await getMeasurementItems(globalState.accessToken).then(async res => {
                if (res.ok) {
                    return res.json();
                } else {
                    buildDialogMessage({
                        error: res.ok,
                    })
                }
            });

            const measurementItemsFiltered = measurementItems.filter(item => {
                item.dataPagamento = new Date(item.dataPagamento);
                item.honorarios = !item.honorarios ? 0 : item.honorarios;
                return item;
            });

            setItems(measurementItemsFiltered);
        }
        getItems();
    }, []);

    return (
        <div id="measurement" className="caixaArrastar">
            <div>
                <GridComponent
                    id="measurementGrid"
                    dataSource={items}
                    filterSettings={{ type: 'CheckBox' }}
                    allowPaging={true}
                    allowSorting={true}
                    allowGrouping={true}
                    allowFiltering={true}
                    locale={i18n.language}
                    ref={grid => gridInstance = grid}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='contrato' template={agreementTemplate} headerText={t('MeasurementReport:column_agreement')} width='140' />
                        <ColumnDirective field='agencia' headerText={t('MeasurementReport:column_agency')} width='130' />
                        <ColumnDirective field='centroCusto' headerText={t('MeasurementReport:column_cost')} width='130' />
                        <ColumnDirective field='dataPagamento' format={i18n.language ==="pt" ? "dd/MM/yyyy" : "MM/dd/yyyy"} headerText={t('MeasurementReport:column_payday')} width='130' />
                        <ColumnDirective field='valorPagoProducao' type="number" format={{
                            currency: "BRL",
                            format: "c2"
                        }} headerText={t('MeasurementReport:column_valueProduction')} width='150' />
                        <ColumnDirective field='valorPagoMidia' type="number" format={{
                            currency: "BRL",
                            format: "c2"
                        }} headerText={t('MeasurementReport:column_valueMedia')} width='150' />
                        <ColumnDirective
                            field='honorarios'
                            type="number"
                            format={{
                                currency: "BRL",
                                format: "c2"
                            }}
                            headerText={t('MeasurementReport:column_fees')}
                            width='130' />
                    </ColumnsDirective>

                    <AggregatesDirective >
                        <AggregateDirective>
                            <AggregateColumnsDirective>
                                <AggregateColumnDirective field='valorPagoProducao' format="c2" type='Sum' footerTemplate={templateSumProduction} />
                                <AggregateColumnDirective field='valorPagoMidia' format="c2" type='Sum' footerTemplate={templateSum} />
                                <AggregateColumnDirective field='honorarios' format="c2" type='Sum' footerTemplate={templateSum} />
                            </AggregateColumnsDirective>
                        </AggregateDirective>
                    </AggregatesDirective>

                    <Inject services={[Filter, Page, Group, Aggregate, Sort]} />
                </GridComponent>
            </div>
        </div>
    );
}