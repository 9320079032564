import * as React from 'react';
import { GlobalStateContext } from '../general/GlobalState';
import { useTranslation } from 'react-i18next';
import {
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  AggregateColumnDirective,
  Aggregate,
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Group,
  Filter,
  Inject,
  ExcelExport,
  Sort,
  ContextMenu,
  Page,
  Resize,
  closeFilterDialog,
} from '@syncfusion/ej2-react-grids';
import { getFormContainerAdBudgets } from './AdvertisingBudgetSVC';
import i18n from 'i18next';
import {
  DialogComponent,
  createSpinner,
  showSpinner,
  setSpinner,
} from '@syncfusion/ej2-react-popups';
import { setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { hasPermissions } from "../utilities/Utils";

export const AdvertisingGrid = (props) => {
  setCulture('pt');
  setCurrencyCode('BRL');
  const { setIdAdvertising, gridInstance, setItemsGridExport } = props;
  const [globalState] = React.useContext(GlobalStateContext);
  const { t } = useTranslation();
  const [items, setItems] = React.useState([]);
  const [loading, setloading] = React.useState(false);
  let [itemsFiltred, setItemsFiltred] = React.useState([]);

  const buildGridItems = async () => {
    setloading(true)
    await getFormContainerAdBudgets(globalState.accessToken).then(
      (response) => {
        if (response.ok) {
          response.json().then((data) => {
            setItems(data);
            setItemsFiltred(data);
            gridInstance.current.dataSource = data;
            setloading(false)
          }).catch(err => {
            setloading(false)
            console.log(err);
          })
        }
      },
    );
  };

  React.useEffect(() => {
    setItemsGridExport(items); 
  }, [items]);

  React.useEffect(() => {
    if (loading) {
      createSpinner({ target: document.getElementById('loading') });
      showSpinner(document.getElementById('loading'));
      setSpinner({ type: 'Bootstrap' });
    }
  }, [loading]);

  React.useEffect(() => {
    buildGridItems();
    gridInstance.current.refresh();
  }, [i18n.language]);

  const calculateSaldoTotal = () => {

    const numberToCurrency = (n) => {
      return n.toLocaleString(i18n.language, {
        style: 'currency',
        currency: 'BRL',
      });
    };

    let orcamento = 0;
    let pagamento = 0;
    let total = 0;
    var distinctYearsBudget = [];
    var map = new Map();

    if (itemsFiltred && itemsFiltred.length > 0) {
      for (const item of itemsFiltred) {
        if (item.orcamentoAnual && item.orcamentoAnual.ano && !map.has(item.orcamentoAnual.ano)) {
          map.set(item.orcamentoAnual.ano, true);
          distinctYearsBudget.push({
            ...item,
          });
        }
      }

      distinctYearsBudget.forEach((item) => {
        orcamento += item.orcamentoAnual.orcamento;
      });
      itemsFiltred.forEach((item) => {
        if(item.pagamentoTotal) pagamento += item.pagamentoTotal;
      });
      total = orcamento - pagamento;
    }

    return {
      orcamento: orcamento,
      orcamentoCurrency: numberToCurrency(orcamento),
      pagamento: pagamento,
      pagamentoCurrency: numberToCurrency(pagamento),
      total: total,
      totalCurrency: numberToCurrency(total),
    };
  };

  const totalFooterSum = (args) => {
    if (hasPermissions(32, "Admin", globalState)) {
      const allTotals = calculateSaldoTotal();
      return (
        <div className="footerContent">
          <span className="footerLabel">
            {i18n.language ==='pt' ?
              'Saldo (Orçamento - Pagamento): ' : 'Balance (Budget - Payment): '}<br />
            <span className={`footerTotal  ${allTotals.total < 0 ? 'red' : ''}`} >
              {allTotals.totalCurrency}
            </span>
          </span>

          <span>
            {i18n.language ==='pt' ? 'Orçamento' : 'Budget'} : {allTotals.orcamentoCurrency}
          </span>
          <span>
            {i18n.language ==='pt' ? 'Pagamento' : 'Payment'} : {allTotals.pagamentoCurrency}
          </span>

        </div>
      );
    } else {
      return ''
    }

  };

  const nameTemplate = (args) => {
    return (
      <span onClick={() => setIdAdvertising(args.id)} className="nomeTemplate">
        {args.nome}
      </span>
    );
  };
  const discountFromAnnualBudgetTemplate = (args) =>{
    if (args.abaterOrcamentoAnual) {
      return (<span>{i18n.language ==='pt' ? 'Sim' : 'Yes'}</span>)
    } else {
      return (<span>{i18n.language ==='pt' ? 'Não' : 'No'}</span>)
    }

  }

  const footerSum = (args) => {
    return (
      <span className="sumClass">
        {i18n.language ==='pt' ? 'Soma' : 'Sum'}:{' '}
        {args.Sum.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </span>
    );
  };
  const productionTemplate = (args) => {
    return <span>$ {args.custoProducao.toLocaleString(i18n.language)}</span>;
  };
  const mediaTemplate = (args) => {
    return <span>$ {args.custoMidia.toLocaleString(i18n.language)}</span>;
  };
  const totalTemplate = (args) => {
    return <span>$ {args.custoTotal.toLocaleString(i18n.language)}</span>;
  };
  const payProductionTemplate = (args) => {
    return (
      <span>$ {args.pagamentoProducao.toLocaleString(i18n.language)}</span>
    );
  };
  const payMediaTemplate = (args) => {
    return <span>$ {args.pagamentoMidia.toLocaleString(i18n.language)}</span>;
  };
  const payTotalTemplate = (args) => {
    return <span>$ {args.pagamentoTotal.toLocaleString(i18n.language)}</span>;
  };
  const toBRL = valor => {
    return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }
  const templateCustoProducao = (data) => {
    return <span>{data.custoProducao && toBRL(data.custoProducao)}</span>;
  };
  const templateCustoHonorarios = (data) => {
    return <span>{data.custoHonorarios && toBRL(data.custoHonorarios)}</span>;
  };
  const templateCustoTotal = (data) => {
    return <span>{data.custoTotal && toBRL(data.custoTotal)}</span>;
  };
  const templateCustoMidia = (data) => {
    return <span>{data.custoMidia && toBRL(data.custoMidia)}</span>;
  };
  const templatePagamentoProducao = (data) => {
    return <span>{data.pagamentoProducao && toBRL(data.pagamentoProducao)}</span>;
  };
  const templatePagamentoMidia = (data) => {
    return <span>{data.pagamentoMidia && toBRL(data.pagamentoMidia)}</span>;
  };
  const templatePagamentoHonorarios = (data) => {
    return <span>{data.pagamentoHonorarios && toBRL(data.pagamentoHonorarios)}</span>;
  };
  const templatePagamentoTotal = (data) => {
    return <span>{data.pagamentoTotal && toBRL(data.pagamentoTotal)}</span>;
  };


  const contextMenuItems = [
    'SortAscending',
    'SortDescending',
    'FirstPage',
    'PrevPage',
    'LastPage',
    'NextPage',
  ];

  const actionComplete = (args) => {
    const setarItemsFiltrados = async () => {
      try {
        const items = args.rows.map(item => {
          const newItem = item.data
          return newItem
        })
        console.log('data', items);
        setItemsFiltred(items);
      } catch (error) {
        console.log(error);
      }
    }
    if (args && ((args.action ==='filter') || (args.requestType && args.requestType && args.rows && args.requestType === 'refresh'))) {
      setarItemsFiltrados()
    } else if (args && args.action ==="clear-filter") {
      setItemsFiltred(items);
    }
  }
  return (
    <div id="AdvertisingGrid" className="caixaArrastar">
      {loading && <div style={{ zIndex: '99999' }} id="loading" />}
      {items && (
        <GridComponent
          id="advertisingGrid"
          dataSource={items}
          filterSettings={{ type: 'CheckBox' }}
          allowPaging={true}
          allowFiltering={true}
          allowSorting={true}
          allowResizing={true}
          allowGrouping={true}
          allowExcelExport={true}
          actionComplete={actionComplete}
          pageSettings={{ pageCount: 3, pageSizes: true }}
          contextMenuItems={contextMenuItems}
          locale={i18n.language}
          ref={gridInstance}
          enablePersistence={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_name')}
              template={(args) => nameTemplate(args)}
              field="nome"
              width="230"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_agency')}
              field="agencia"
              width="230"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_area')}
              field="areaInterna"
              width="230"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_center')}
              field="centroCusto"
              width="180"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_internal')}
              field="ordemInterna"
              width="200"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_production')}
              field="custoProducao"
              filter={{ type: 'Menu' }}
              // template={templateCustoProducao}
              width="200"
              format={'C2'}
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_media')}
              field="custoMidia"
              filter={{ type: 'Menu' }}
              width="200"
              format={'C2'}
            // template={templateCustoMidia}
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_honorarios')}
              field="custoHonorarios"
              filter={{ type: 'Menu' }}
              width="200"
              format={'C2'}
            // template={templateCustoHonorarios}
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_total')}
              // template={(args) => totalTemplate(args)}
              field="custoTotal"
              filter={{ type: 'Menu' }}
              width="200"
              format={'C2'}
            // template={templateCustoTotal}
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_payProduction')}
              field="pagamentoProducao"
              filter={{ type: 'Menu' }}
              width="200"
              format={'C2'}
            // template={(args) => payProductionTemplate(args)}
            // template={templatePagamentoProducao}
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_payMedia')}
              field="pagamentoMidia"
              filter={{ type: 'Menu' }}
              width="200"
              format={'C2'}
            // template={templatePagamentoMidia}
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_payFees')}
              field="pagamentoHonorarios"
              filter={{ type: 'Menu' }}
              width="220"
              format={'C2'}
            // template={templatePagamentoHonorarios}
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_payTotal')}
              field="pagamentoTotal"
              filter={{ type: 'Menu' }}
              width="240"
              format={'C2'}
            // template={templatePagamentoTotal}
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_discount_annual_budget')}
              field="abaterOrcamentoAnual"
              width="135"
              filterItemTemplate={discountFromAnnualBudgetTemplate}
              template={discountFromAnnualBudgetTemplate}
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_budget')}
              field="orcamentoAnual.ano"
              width="200"
            ></ColumnDirective>

          </ColumnsDirective>

          <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective
                  field="custoProducao"
                  type="Sum"
                  footerTemplate={footerSum}
                />
                <AggregateColumnDirective
                  field="custoMidia"
                  type="Sum"
                  footerTemplate={footerSum}
                />
                <AggregateColumnDirective
                  field="custoTotal"
                  type="Sum"
                  footerTemplate={footerSum}
                />
                <AggregateColumnDirective
                  field="custoHonorarios"
                  type="Sum"
                  footerTemplate={footerSum}
                />
                <AggregateColumnDirective
                  field="pagamentoProducao"
                  type="Sum"
                  footerTemplate={footerSum}
                />
                <AggregateColumnDirective
                  field="pagamentoMidia"
                  type="Sum"
                  footerTemplate={footerSum}
                />
                <AggregateColumnDirective
                  field="pagamentoHonorarios"
                  type="Sum"
                  footerTemplate={footerSum}
                />
                <AggregateColumnDirective
                  field="pagamentoTotal"
                  type="Sum"
                  footerTemplate={footerSum}
                />
              </AggregateColumnsDirective>
            </AggregateDirective>

            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective
                  field="pagamentoTotal"
                  type="Sum"
                  footerTemplate={totalFooterSum}
                />
              </AggregateColumnsDirective>
            </AggregateDirective>
          </AggregatesDirective>

          <Inject
            services={[
              Filter,
              Sort,
              Page,
              Aggregate,
              ContextMenu,
              Group,
              ExcelExport,
              Resize,
            ]}
          />
        </GridComponent>
      )}

    </div>
  );
};
