import { requestAPI } from '../components/utilities/Utils';
import { mountSignature } from '../helpers/EmailCreator';

export async function getSignatureItems(token) {
    const reqUrl = "_api/Signature/GetSignaturesItems";
    return requestAPI(reqUrl, "GET", "", null, token, false);
}

export async function getSignatureItemByRegion(token, regionalId) {
    const qstring = regionalId ? `?regionalId=${regionalId}` : '';
    return requestAPI("_api/Signature/GetSignaturesItemByRegional", "GET", qstring, null, token, false);
};

export async function getAndMountSignature(globalState, regionalId, lang) {
	
	const response = await getSignatureItemByRegion(globalState.accessToken, regionalId);
	if(response && response.ok && response.status !== 204) {

		const newSig = await response.json();

		return {
			newSig, 
			newSigContent: mountSignature(newSig, lang, globalState.tokenLightWeight)
		};
	} else {
		return {newSig: null, newSigContent: ""}
	}
}

export async function getSignatureItem(token, id) {
    const qstring = id ? `?signatureId=${id}` : '';
    return requestAPI("_api/Signature/GetSignaturesItem", "GET", qstring, null, token, false);
}

export async function addOrUpdateSignatureItem(token, body) {
    const raw = JSON.stringify(body);
    const endPointURL = body.id ? "_api/Signature/UpdateSignatureItem" : "_api/Signature/AddSignatureItem"
    return requestAPI(endPointURL, "POST", "", raw, token, false);
}

export function getSignaturePhoto(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
        if (xhr.readyState ===4) {
            callback(this.response)
        }
    }
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export async function deleteSignature(token, id) {
    const qstring = `?signatureId=${id}`;
    return requestAPI("_api/Signature/DeleteSignatureItem", "POST", qstring, null, token, false);
}
