import React, { useEffect, useState, useContext, useCallback, useRef, useMemo } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from "../general/GlobalState";
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { getCountries, getRegional } from "../../services/SystemSVC";
import { getMyProfile, getWhoIsWhoUsers } from "../../services/UsersSVC";
import { createSpinner, setSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { Redirect, Link } from "react-router-dom";
import './home.css';

import { getFilteredUsers } from '../../services/UsersSVC';

export const UserHome = (props) => {

    const tab = useRef(null);
    const inputRef = useRef(null);
    const formRef = useRef(null);

    const [loadingUser, setLoadingUser] = useState(true);
    const [globalState, setGlobalState] = useContext(GlobalStateContext);
    const [profile, setProfile] = useState(null);
    const [country, setCountry] = useState(null);
    const [region, setRegion] = useState(null);
    const [photoUser, setPhotoUser] = useState(null);
    const [nameUser, setNameUser] = useState(null);
    const [fillUsersDataFull, setFillUsersDataFull] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [users, setUsers] = useState(null);
    const [userNameInput, setUserNameInput] = useState("");

    //const [contentText1, setContentText1] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (loadingUser && document.getElementById('loadUserHome')) {
            createSpinner({ target: document.getElementById('loadUserHome') })
            showSpinner(document.getElementById('loadUserHome'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [loadingUser]);

    useEffect(() => {
        setLoadingUser(true);
        async function load() {
            let _profile = await getMyProfile(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json() } });
            let _country = await getCountries(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json() } });
            let _region = await getRegional(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json() } });

            setProfile(_profile);
            setCountry(_country);
            setRegion(_region);

            if (document.getElementById('loadUserHome')) {
                hideSpinner(document.getElementById('loadUserHome'));
                setLoadingUser(false);
            }
        }
        load();
    }, [i18n.language]);

    useEffect(() => {
        if (profile && country && region) {
            renderTabProfile();
        }
    }, [profile]);

    useEffect(() => {
        async function getUsers() {
            const whoIsWhoUsers = await getFilteredUsers(globalState.accessToken).then(res => {
                if (res.ok)
                    return res.json();
            });

            setUsers(whoIsWhoUsers);
        }
        getUsers();
    }, []);


    const headerText = [
        { text: `<div class='mdl2 mdl2-user'></div>${t('UserHome:my_profile')}` },
        { text: `<div class='mdl2 mdl2-orgchart'></div>${t('UserHome:who_is_who')}` }
    ];

    const renderTabProfile = () => {

        const birth = new Date(profile.dataNascimento);
        const pais = profile.idPais && country.filter(i => i.id ===profile.idPais);
        const reg = profile.idRegional && region.filter(i => i.id ===profile.idRegional);

        return (
            <div className="menuHome">
                <div>
                    <span className="mdl2 mdl2-mail" />
                    <h5>{profile.email}</h5>
                </div>
                {profile.dataNascimento &&
                    <div>
                        <span className="mdl2 mdl2-birthday" />
                        <h5>{birth.toLocaleDateString(i18n.language)}</h5>
                    </div>
                }
                {profile.idPais &&
                    <div>
                        <span className="mdl2 mdl2-globe" />
                        <h5>{pais ? (i18n.language) ==="pt" ? pais[0].nomePt : pais[0].nomeEn : ""}</h5>
                    </div>
                }
                {profile.idPais && profile.idPais ===6 && reg &&
                    <div>
                        <span className="mdl2 mdl2-pinmap" />
                        <h5>{(i18n.language) ==="pt" ? reg[0].nomePt : reg[0].nomeEn}</h5>
                    </div>
                }
                {profile.usuarioTelefone.length > 0 &&
                    <div style={{ alignItems: "flex-start" }}>
                        <span className="mdl2 mdl2-cellphone" />
                        <div className="phoneStyle">
                            {profile.usuarioTelefone.map((item, index) => (
                                <h4 key={index}>
                                    <MaskedTextBoxComponent
                                        promptChar=" "
                                        insertKeyMode="insert"
                                        hidePrompt={true}
                                        mask='\+[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]'
                                        // placeholder={t('UserVale:number')}
                                        value={item.telefone}
                                        disabled
                                    />
                                </h4>
                            ))}
                        </div>
                    </div>
                }
                <hr />
                <a onClick={props.goProfile}>{t('UserHome:edit')}</a>
            </div>
        )

    }

    const debounce = (func, wait, immediate) => {
        var timeout;

        return (...args) => {
            var context = this;

            var later = () => {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };

            var callNow = immediate && !timeout;

            clearTimeout(timeout);

            timeout = setTimeout(later, wait);

            if (callNow) func.apply(context, args);
        };
    }

    const getNewUsers = useCallback(
        debounce(async (name) => {
            const newUsers = await getFilteredUsers(globalState.accessToken, name).then(res => {
                if (res.ok)
                    return res.json();
            });
            if (tab && tab.current) {

                const items = [
                    {
                        header: headerText[0],
                        content: {
                            template: renderTabProfile
                        }
                    },
                    {
                        header: headerText[1],
                        content: {
                            template: renderTabWhoisWho,
                            data: { users: newUsers }
                        }
                    }
                ]

                tab.current.addTab([items[1]], 1);
                tab.current.removeTab(2);
                tab.current.refresh();
                inputRef.current.value = name;
                inputRef.current.focus();
            }
        }, 500),
        []
    );

    const renderTabWhoisWho = ({ users }) => {
        return (
            <div>
                <div className="whoIswhoUsers">
                    <div className="e-input-group e-icon-left">
                        <span className="e-input-group-icon mdl2 mdl2-search" />
                        <div className="e-input-in-wrap">
                            <input
                                onChange={(args) => getNewUsers(args.target.value)}
                                className="e-input"
                                ref={inputRef}
                                name="input"
                                type="text"
                                placeholder={i18n.language ==="pt" ? "Busque no Who is who..." : "Search Who's who ..."} />
                        </div>
                    </div>

                    <div className="showWhoUsers">
                        {users && users.length > 0 &&
                            users.map((i, index) => {
                                return (
                                    <div className="whoUser" key={index}>
                                        <Link to={`/profile/${i.id}`}>
                                            <img src={`${process.env.REACT_APP_API_URL}​​/_api/Users/GetUserPhoto?token=${globalState.tokenLightWeight}&userId=${i.id}&sizeType=31`} alt="" />
                                            <label>{i.nome}</label>
                                        </Link>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className="seeMore">
                        <label onClick={whoRedirect}>{i18n.language ==="pt" ? "Ver mais" : "See more"}</label>
                    </div>
                </div>
            </div>
        )
    };

    const whoRedirect = () => {
        setRedirect(true);
    }

    useEffect(() => {
        let version = new Date().toISOString();
        setPhotoUser(`${globalState.userPhoto}&sizeType=62&v=${version}`);

        setNameUser(globalState.userName);
    }, [globalState]);

    return (
        loadingUser ? <div id="loadUserHome" /> :
            redirect ? <Redirect push={true} to='/home/whoiswho' /> :
                <div className="mainHome">
                    {profile && country && region &&
                        <>
                            <div className="perfil">
                                <div>
                                    <img src={photoUser} />
                                </div>
                                <div className="name">
                                    <h3>{t('UserHome:message')}</h3>
                                    <h4>{profile.nome}</h4>
                                </div>
                            </div>
                            <div className="divTab">
                                {profile && country && region &&
                                    <TabComponent created={() => {
                                        const items = [
                                            {
                                                header: headerText[0],
                                                content: {
                                                    template: renderTabProfile
                                                }
                                            },
                                            {
                                                header: headerText[1],
                                                content: {
                                                    template: renderTabWhoisWho,
                                                    data: { users: users }
                                                }
                                            }
                                        ]

                                        tab && tab.current && tab.current.addTab(items);
                                        tab && tab.current && tab.current.refresh();
                                    }} ref={tab} className="mainTab">
                                    </TabComponent>
                                }
                            </div>
                        </>
                    }
                </div>
    );
}
