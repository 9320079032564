import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../general/GlobalState";
import { Container, Row } from "reactstrap";
import { Buttons } from "../utilities/Buttons";
import { Measurement } from "./MeasurementContent";
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";

import { useModal } from '../../hooks/Modal';

export const MeasurementReport = () => {

    const { t } = useTranslation();

    const { buildDialogMessage } = useModal();

    const [globalState] = React.useContext(GlobalStateContext);

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                <>
                    <PermissionRedirect id="31" />

                    <div className="DefaultTop" />
                    <Container className="containerTop">
                        <div className="topTitleButtons">
                            <Row>
                                <div className="col-12">
                                    <div className="title">
                                        <h1>{t('MeasurementReport:title')}</h1>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <Row>
                            <div className="col-12">
                                <Measurement />
                            </div>
                        </Row>
                    </Container>
                </>
            }
        </>
    )
}