import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { createSpinner, showSpinner, setSpinner, DialogComponent } from "@syncfusion/ej2-react-popups";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { UploaderComponent, RemovingEventArgs } from "@syncfusion/ej2-react-inputs";
import '../releases/releases.css';

const ModalSignature = ({ index, setSocialMediaSelected, socialMediaSelected, setShowDialog }) => {

    const uploadIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="61.1" height="62.1" viewBox="0 0 61.1 62.1"><defs><style>.a{fill:#dbdbdb;}.b{fill:#007e7a;}</style></defs><g transform="translate(-690 -235)"><path class="a" d="M715.8,285.9H704.7V297H701V285.9H690v-3.7h11.1V271.1h3.7v11.1h11.1v3.7ZM741.6,238a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H712.2v-3.7H738V279.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V241.7H704.9v22.1h-3.7V238Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V279.5Z"/><path class="b" d="M717.8,282.9H706.7V294H703V282.9H692v-3.7h11.1V268.1h3.7v11.1h11.1v3.7ZM743.6,235a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H714.2v-3.7H740V276.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V238.7H706.9v22.1h-3.7V235Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V276.5Z"/></g></svg>';

    let uploaderRef;
    let dialogRef;
    let dropAreaRef;

    const [photo, setPhoto] = React.useState({});
    const [openDialog, setOpenDialog] = React.useState(false);
    const [globalState] = React.useContext(GlobalStateContext);
    const [cachePhoto, setCachePhoto] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const { t } = useTranslation();
    const modalButtons = [{
        'click': () => {
            updatePhoto();
            removeFiles();
            setOpenDialog(false);
        },
        buttonModel: {
            isPrimary: true,
            content: t('Actions:save')
        }
    }];

    let onFileUploadSuccess = (e) => {
        console.log(e);
        // filePathUploaded.current = e.e.srcElement.response;
        // document.getElementById("uploadVideo").setAttribute('style', 'display:none;');
        // let gridDataSource = [...gridMediaRef.current.dataSource];
        // if (e.operation === 'upload') {
        //     document.querySelector('#customTbarDlg button.e-primary').removeAttribute('disabled');
        //     switch (action.current) {
        //         case "Vid":
        //             if (e.file.type === 'mp4') {
        //                 document.getElementById('videoTag').setAttribute('style', 'display:block;');
        //                 gridDataSource.push({ url: e.e.srcElement.response, type: 'Video' });
        //                 gridMediaRef.current.dataSource = gridDataSource;

        //                 var file = document.getElementById("fileUpload").files[0];
        //                 var url = URL.createObjectURL(file);
        //                 setVideoSrc(videoSrc => ({ ...videoSrc, blob: url, url: e.e.srcElement.response }));
        //             }
        //             break;
        //         case "Aud":
        //             gridDataSource.push({ url: e.e.srcElement.response, type: 'Audio' });
        //             gridMediaRef.current.dataSource = gridDataSource;
        //             break;
        //         case "Img":
        //             gridDataSource.push({ url: e.e.srcElement.response, type: 'Image' });
        //             gridMediaRef.current.dataSource = gridDataSource;
        //             break;
        //         default:
        //             gridDataSource.push({ url: e.e.srcElement.response, type: 'Attachment', title: txtAttachmentTitleRef.current.value });
        //             gridMediaRef.current.dataSource = gridDataSource;
        //             break;
        //     }

        // }

    }

    // const onSetAttachmentsFiles = event => {
    //     if (event.filesData[0].statusCode ==="1") {
    //         setLoading(true);
    //         event.filesData.map((file) => {
    //             const reader = new FileReader();
    //             const firstFile = document.getElementById("uploadPhoto").files[0];
    //             reader.addEventListener("load", function () {
    //                 const name = firstFile.name;
    //                 const i = name.lastIndexOf('.');
    //                 const fileName = name.substring(0, i);
    //                 const ext = name.substring(i + 1);

    //                 setPhoto(photo => ({ ...photo, name: fileName, remove: false, size: firstFile.size, type: ext, b64: reader.result }));
    //                 setLoading(false);
    //             }, false);
    //             reader.readAsDataURL(file.rawFile);
    //         });
    //     } else {
    //         document.getElementById("validateImage").innerHTML = "O tamanho do arquivo é muito grande";
    //     }

    //     document.getElementById("validateImage").innerHTML = "";
    // }

    let onRemoveFile = (args) => {
        console.log('remove file');
        // if (document.getElementById("uploadVideo"))
        //     document.getElementById("uploadVideo").setAttribute('style', 'display:block;');

        // if (document.getElementById('videoTag'))
        //     document.getElementById('videoTag').setAttribute('style', 'display:none;');

        // document.querySelector('#customTbarDlg button.e-primary').setAttribute('disabled', 'disabled');
        // args.postRawFile = false;
        // const fileInfo = uploadObj.current.getFileInfo(0, null);
        // let qstring = `mediaPath=${mediaGuid}/${fileInfo.name}`;
        // removeMedia(globalState.accessToken, qstring, idType);
    }

    const uploaderSelected = e => {
        document.querySelector('#uploadVideo .e-error').innerHTML = '';
        if (e.filesData[0].statusCode === "0") {
            e.cancel = true;
            document.querySelector('#uploadVideo .e-error').innerHTML = e.filesData[0].status;
        }
    }


    const fileUploading = (e) => {
        e.currentRequest.setRequestHeader("Authorization", "Bearer " + globalState.accessToken);
    }

    const target = document.getElementById("uploadPhoto");

    React.useEffect(() => {
        if (photo) {
            if (uploaderRef) {
                uploaderRef.dropArea = dropAreaRef;
                uploaderRef.dataBind();
            }
        }
    }, [photo]);

    React.useEffect(() => {
        if (openDialog)
            dialogRef.buttons = modalButtons;
    }, [openDialog]);

    const onClose = () => {
        removeFiles();
        // if (photo.remove || !photo.b64) {
        //     setPhoto(photo => ({
        //         name: photo.name,
        //         size: photo.size,
        //         type: photo.type,
        //         url: photo.url
        //     }));
        // }
    };

    React.useEffect(() => {
        createSpinner({ target: document.getElementById('loadingPhoto') });
        showSpinner(document.getElementById('loadingPhoto'));
        setSpinner({ type: 'Bootstrap' });
    }, [loading]);

    const removeFiles = () => {
        const clearThumb = document.getElementById("uploadPhoto") && document.getElementById("uploadPhoto").ej2_instances[0];
        if (clearThumb !== null && clearThumb.clearAll !== null && typeof (clearThumb.clearAll) !== "undefined") {
            return clearThumb.clearAll();
        }
    }

    const uploaderCreated = () => {
        if (photo) {
            uploaderRef.files = photo.b64 || photo.url ? [photo] : null;
        }
        if (uploaderRef) {
            uploaderRef.dropArea = dropAreaRef;
            uploaderRef.dataBind();
        }
    }

    const updatePhoto = () => {
        console.log('updatePhoto');
        // setPhoto(photo => {
        //     if (photo.remove) {
        //         if (photo.url)
        //             return { ...photo, b64: null, url: null, delete: true, changed: true };
        //         else
        //             return { ...photo, b64: null, url: null, changed: true };
        //     }
        //     return { ...photo, changed: true }
        // });
    }

    // const onCreated = () => {
    //     if (uploaderRef) {
    //         uploaderRef.dropArea = dropAreaRef;
    //         uploaderRef.dataBind();
    //     }
    // }

    const onRemovingAttachsFiles = (args) => {
        console.log("onRemoving");
        // setPhoto(photo => {
        //     return { ...photo, remove: true }
        // });
        // removeFiles();
    };

    const onSetAttachmentsFiles = event => {
        if (event.filesData[0].statusCode ==="1") {
            setLoading(true);
            event.filesData.map((file) => {

                const newSelected = [...socialMediaSelected];

                const reader = new FileReader();
                // const firstFile = document.getElementById("uploadPhoto").files[0];
                reader.addEventListener("load", function () {
                    // const name = firstFile.name;
                    // const i = name.lastIndexOf('.');
                    // const fileName = name.substring(0, i);
                    // const ext = name.substring(i + 1);

                    // setPhoto(photo => ({ ...photo, name: fileName, remove: false, size: firstFile.size, type: ext, b64: reader.result }));
                    newSelected[index].iconeB64 = reader.result;
                    setSocialMediaSelected(newSelected);
                    setShowDialog(false);
                    // setLoading(false);
                }, false);
                reader.readAsDataURL(file.rawFile);
            });
        } else {
            // document.getElementById("validateImage").innerHTML = "O tamanho do arquivo é muito grande";
        }

        // document.getElementById("validateImage").innerHTML = "";
    }

    const downloadAttachment = ({ fileName, url }) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    const removeAttachment = () => {
        console.log("removing");
        // setPhoto(photo => {
        //     if (photo.b64 && photo.url)
        //         return { ...photo, b64: null };
        //     else
        //         return { ...photo, remove: true }
        // });
        // removeFiles();
    };

    const uploadPhoto = () => {
        document.getElementsByClassName("e-file-select-wrap")[0].querySelector("button").click();
        return false;
    }

    // let asyncSettings = {
    //     saveUrl: `${process.env.REACT_APP_API_URL}/_api/Signature/UploadPressMedia?${fileParams}`
    // };

    const beforeClose = () => {
        console.log("beforeClosing");
        // setOpenDialog(false);
        // toggle && toggle.close();
    };

    return (
        <DialogComponent id='customTbarDlg'
            ref={dialog => dialogRef = dialog}
            // buttons={dlgButtons}
            // overlayClick={dialogOverlay}
            // close={dialogOverlay}
            // header={header}
            visible={true}
            showCloseIcon={true}
            width='45%'
            // target={'#rteSection'}
            isModal={true}
            style={{ minHeight: 350 }}>
            <div id="customTbarDialog">
                <div id="rteSpecial_char" >
                    <div className='control-pane'>
                        <div className='control-section uploadpreview'>
                            <div style={{ width: '100%' }}>
                                <div className='upload_wrapper' style={{ width: '100%' }}>
                                    {/* Render Uploader */}
                                    <div className="fileUpload" id="uploadPhoto">
                                        <div className="new">
                                            <span dangerouslySetInnerHTML={{ __html: uploadIcon }} />
                                            <p>
                                                {i18n.language ==="pt" ? "Arraste o arquivo aqui ou faça o" : "Drag the file here or"}
                                                <span onClick={() => uploadPhoto()}> {i18n.language ==="pt" ? "carregamento" : "upload"}</span>

                                            </p>
                                            <span id="messageFileSize" className="fileInfo"></span>
                                            <span id="messageFileExt" className="fileInfo"></span>
                                            <span className="e-error"></span>
                                        </div>
                                    </div>
                                    <UploaderComponent
                                        id='fileUpload'
                                        name="fileUpload"
                                        type='file'
                                        dropArea={target}
                                        ref={uploader => uploaderRef = uploader}
                                        multiple={false}
                                        // ref={(scope) => { uploadObj.current = scope }}
                                        // asyncSettings={asyncSettings}
                                        created={uploaderCreated}
                                        // allowedExtensions={".jpg", ".png", ".jpeg"}
                                        selected={uploaderSelected}
                                        uploading={fileUploading}
                                        success={(e) => onFileUploadSuccess(e)}
                                        removing={e => onRemoveFile(e)}
                                        beforeUpload={(e) => console.log(e)}
                                        selected={(e) => onSetAttachmentsFiles(e)}
                                    ></UploaderComponent>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ATTACHMENT SUBTITLE */}
                    <div className="input" id={'attachmentLabelWrapper'} style={{ display: 'none' }}>
                        <label htmlFor="attachmentLabel">{t('Press:attachment_subtitle')}</label>
                        <TextBoxComponent
                            id="attachmentLabel"
                            // ref={textBox => txtAttachmentTitleRef.current = textBox}
                            name="attachmentLabel"
                            placeholder={t('Press:attachment_subtitle_placeholder')}
                            htmlAttributes={{ maxlength: 17 }}
                        />
                    </div>
                    {/* ATTACHMENT SUBTITLE END */}

                </div>
            </div>
        </DialogComponent>
    )
};

export default ModalSignature;