import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../../general/GlobalState";
import { MailingContactsContext } from "./MailingContactsContext";
import { GridComponent, ColumnsDirective, ColumnDirective, ForeignKey, Filter, Inject, VirtualScroll, Sort, ContextMenu, Page, Resize, Edit, ExcelExport } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { createSpinner, hideSpinner, setSpinner, showSpinner } from '@syncfusion/ej2-react-popups';
import { hasPermissions } from "../../utilities/Utils";
import { useModal } from "../../../hooks/Modal";
import { countriesJSON } from '../../../resources/countries.js';

let gridInstance;
export const ContactsList = (props) => {

    let textBoxSearch;
    const { gridRef } = props;
    const [globalState] = React.useContext(GlobalStateContext);
    const { mediaTypes, countries, states, cities, setItem, contactListItems } = React.useContext(MailingContactsContext);
    const [isVisible, setIsVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [itemSelected, setItemSelected] = React.useState(null);
    const [filter, setFilter] = React.useState({});

    const { buildDialogMessage } = useModal();

    const { t } = useTranslation();

    React.useEffect(() => {
        setIsVisible(false);
        if (textBoxSearch)
            textBoxSearch.placeholder = t('Contacts:search_placeholder');
    }, [i18n.language]);

    React.useEffect(() => {
        if (loading) {
            createSpinner({ target: document.getElementById('loadContactsList') });
            showSpinner(document.getElementById('loadContactsList'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [loading]);

    React.useEffect(() => {
        const elemSpinner = document.getElementById('loadContactsList');
        if (contactListItems && elemSpinner) {
            hideSpinner(elemSpinner);
            setLoading(false);
        }
    }, [contactListItems]);

    const inputChange = e => {
        setFilter({ ...filter, [e.event.currentTarget.id]: e.value });
    }
    const dropdownlistChange = e => {
        setFilter({ ...filter, [e.element.id]: e.value });
    }

    const filterGrid = e => {
        setIsVisible(false);
        if (gridInstance) {
            let dataFiltered = [...contactListItems];
            const filterValue = filter.tbSearch.trim().toLowerCase();

            if (filterValue)
                dataFiltered = dataFiltered.filter(item => {

                    console.log(item)
                    return item.jornalista && item.jornalista.toLowerCase().includes(filterValue)
                    || item.nomeVeiculo && item.nomeVeiculo.toLowerCase().includes(filterValue)
                    || item.telefone && item.telefone.some(tel => tel.telefone && tel.telefone.toLowerCase().includes(filterValue))
                });
            if (filter.mediaTypes)
                dataFiltered = dataFiltered.filter(item => item.idtipoMidia ===filter.mediaTypes);
            if (filter.countries)
                dataFiltered = dataFiltered.filter(item => item.idpais ===filter.countries);
            if (filter.states)
                dataFiltered = dataFiltered.filter(item => item.idestado ===filter.states);
            if (filter.cities)
                dataFiltered = dataFiltered.filter(item => item.idcidade ===filter.cities);

            gridInstance.dataSource = dataFiltered;
        }
    }

    const titleTemplate = (e) => {
        return (
            <div className="flexColumn">
                <span onClick={() => setItemSelected(e.id)} className="jornalistaTemplate"> {e.jornalista} </span>
            </div>
        )
    }

    const emailTemplate = (e) => {
        return (
            <div className="flexColumn">
                {e.email.length > 0 && e.email.map((email, index) => (
                    <span key={index}> {email.email} </span>
                ))}
            </div>
        )
    }

    const phoneTemplate = (e) => {
        return (
            <div className="flexColumn">
                {e.telefone.length > 0 && e.telefone.map((phone, index) => {
                    const currentCountry = countriesJSON.find(country => country.nameEn === phone.country);
                    return (
                        <span key={index} onClick={() => setItemSelected(e.id)} className="phoneTemplate"> {currentCountry && currentCountry.dialCode + phone.telefone} </span>
                    )
                }
                )}
            </div>
        )
    }

    React.useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language]);

    return (
        itemSelected ? <Redirect push={true} to={`/press/mailing/contactsItem/${itemSelected}`} /> :
            loading ? <div id="loadContactsList" /> :
                <>
                    <div className={`busca ${isVisible ? "aberto" : ""}`}>
                        <div className="filtroUsuarios d-flex">
                            <div className="SearchTitle">
                                <span className="mdl2 mdl2-search"></span>
                                <p>{t('Contacts:search_text')}</p>
                            </div>
                            <div className="searchUsers">
                                <div className="e-input-in-wrap">
                                    <TextBoxComponent
                                        id="tbSearch"
                                        placeholder={t('Contacts:search_placeholder')}
                                        ref={textBox => textBoxSearch = textBox}
                                        className="e-input"
                                        change={inputChange}
                                    />
                                </div>
                            </div>
                            <div className="btnBusca d-flex">
                                {!isVisible &&
                                    <div className="btnFilter">
                                        <ButtonComponent
                                            className="e-outline filter"
                                            cssClass='e-primary filter'
                                            onClick={() => setIsVisible(!isVisible)}>
                                            <span className="mdl2 mdl2-filter"></span>
                                            {t('Contacts:filters')}
                                        </ButtonComponent>
                                    </div>
                                }
                                {isVisible &&
                                    <div className="btnFilter">
                                        <ButtonComponent
                                            className="e-outline filter active-filter"
                                            cssClass='e-primary filter'
                                            onClick={() => setIsVisible(!isVisible)}>
                                            <span className="mdl2 mdl2-filter"></span>
                                            {t('Contacts:filters')}
                                        </ButtonComponent>
                                    </div>
                                }
                                <div className="btnSearch">
                                    <ButtonComponent
                                        cssClass='e-primary search'
                                        onClick={filterGrid}
                                    >

                                        {t('Contacts:search')}

                                    </ButtonComponent>
                                </div>
                            </div>
                        </div>
                        {isVisible &&
                            <div className="filtrosOcultos quatro d-flex">
                                <DropDownListComponent
                                    id="mediaTypes"
                                    cssClass="e-filled"
                                    placeholder={t('Contacts:media_type')}
                                    dataSource={mediaTypes}
                                    fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                                    value={filter.mediaTypes}
                                    change={dropdownlistChange}
                                    locale={i18n.language}
                                    showClearButton={true}
                                />
                                <DropDownListComponent
                                    id="countries"
                                    cssClass="e-filled"
                                    placeholder={t('Contacts:country')}
                                    dataSource={countries}
                                    fields={{ text: (i18n.language ==="pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
                                    value={filter.countries}
                                    allowFiltering={true}
                                    change={dropdownlistChange}
                                    locale={i18n.language}
                                    showClearButton={true}
                                />
                                <DropDownListComponent
                                    id="states"
                                    cssClass="e-filled"
                                    placeholder={t('Contacts:state')}
                                    dataSource={states}
                                    fields={{ text: (i18n.language ==="pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
                                    value={filter.states}
                                    change={dropdownlistChange}
                                    locale={i18n.language}
                                    showClearButton={true}
                                />
                                <DropDownListComponent
                                    id="cities"
                                    cssClass="e-filled"
                                    placeholder={t('Contacts:city')}
                                    dataSource={cities}
                                    fields={{ text: (i18n.language) ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                                    value={filter.cities}
                                    change={dropdownlistChange}
                                    locale={i18n.language}
                                    showClearButton={true}
                                />
                            </div>
                        }
                    </div>
                    <div className="contactslist">
                        {contactListItems &&
                            <GridComponent
                                id='gridcomp'
                                ref={grid => gridInstance = grid}
                                dataSource={contactListItems}
                                filterSettings={{ type: 'Menu' }}
                                allowFiltering={false}
                                allowPaging={true}
                                allowSorting={true}
                                allowExcelExport={true}
                                allowResizing={true}
                                locale={i18n.language}>
                                <ColumnsDirective >
                                    <ColumnDirective
                                        field='jornalista'
                                        headerText={t('Contacts:name')}
                                        width='100'
                                        template={titleTemplate}
                                        >
                                    </ColumnDirective>
                                    <ColumnDirective
                                        field='email'
                                        headerText={t('Contacts:email')}
                                        template={(e) => emailTemplate(e)}
                                        width='100'>
                                    </ColumnDirective>
                                    <ColumnDirective
                                        field='nomeVeiculo'
                                        headerText={t('Contacts:media_outlet')}
                                        width='100'>
                                    </ColumnDirective>
                                    <ColumnDirective
                                        field={i18n.language ==='pt' ? 'tipoMidiaPt' : 'tipoMidiaEn'}
                                        headerText={t('Contacts:media_type')}
                                        width='100'>
                                    </ColumnDirective>
                                    <ColumnDirective
                                        field='telefone'
                                        headerText={t('Contacts:phone')}
                                        template={(e) => phoneTemplate(e)}
                                        width='100'>
                                    </ColumnDirective>
                                    <ColumnDirective
                                        field={i18n.language ==='pt' ? 'importadoPt' : 'importadoEn'}
                                        headerText={t('Contacts:imported')}
                                        width='100'>
                                    </ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Edit, ForeignKey, ExcelExport]} />
                            </GridComponent>
                        }
                    </div>
                </>
    )
}
