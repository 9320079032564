import { requestAPI } from '../components/utilities/Utils';

export async function getContracts(token) {
    return requestAPI("_api/BrandingAdvertising/Management/Contract/GetContractItems", "GET", "?$filter=ativo eq true", null, token, false);
}

export async function getContractsHistory(token, contractId) {
    const params = `?contractId=${contractId}&$orderBy=data desc`;

    return requestAPI("_api/BrandingAdvertising/Management/Contract/GetContractHistoryItems", "GET", params, null, token, false);
}

export async function addItem(token, body) {
    return requestAPI("_api/BrandingAdvertising/Management/Contract/AddContract", "POST", "", body, token, false);
}

export async function updateItem(token, body) {
    return requestAPI("_api/BrandingAdvertising/Management/Contract/UpdateContract", "POST", "", body, token, false);
}

export async function deleteItem(token, id) {
    const params = `?contractId=${id}`;
    return requestAPI("_api/BrandingAdvertising/Management/Contract/DisableContract", "POST", params, null, token, false);
}