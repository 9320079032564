import * as React from 'react';

const initialState = {
  id: 0,
  nome: null,
  agencia: {
    id: null,
    nome: null,
  },
  areaInterna: {
    id: null,
    nome: null,
    ativo: true,
  },
  orcamentoAnual: {
    id: null,
    ano: null,
    orcamento: null,
    saldo: null,
    ativo: true,
  },
  responsavel: null,
  centroCusto: null,
  ordemInterna: null,
  abaterOrcamentoAnual: false,
  dataInicial: null,
  dataFinal: null,
  ativo: true,
  tempGuid: null,
  anexos: [],
  pagamentos: [],
  custos: [],
  historico: [],
  historicoTransformed: false
};

const AdvertisingBudgetContext = React.createContext();

const AdvertisingBudgetProvider = ({ children }) => {
  const [globalAdBudget, setGlobalAdBudget] = React.useState(initialState);

  const resetglobalAdBudget = () => {
    setGlobalAdBudget(initialState);
  };

  return (
    <AdvertisingBudgetContext.Provider
      value={[globalAdBudget, setGlobalAdBudget, resetglobalAdBudget]}    >
      {children}
    </AdvertisingBudgetContext.Provider>
  );
};

export { AdvertisingBudgetContext, AdvertisingBudgetProvider };
