import * as React from "react";
import { SharedDocumentsComponent } from './SharedDocumentsComponent';
import { useTranslation } from "react-i18next";

export const InternalCampaigns = () => {
    const { t } = useTranslation();

    return <SharedDocumentsComponent 
        permissionId="66" 
        path="Campanhas Internas"
        folderManagerTitle={t("EmpCommunication:internal_campaigns")}
        title={t("EmpCommunication:internal_campaigns")} 
    />
}