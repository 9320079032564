import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState'
import { UserHome } from '../home/UserHome';
import { BannerHome } from '../home/BannerHome';
import { WhoIsWhoHome } from '../home/WhoIsWhoHome';
import { MessageHouse } from '../home/MessageHouse';
import { FolderManager } from "../home/FolderManager";
import { getBanners } from "../../services/BannerHomeSVC";

export const Home = () => {

    const [redirect, setRedirect] = React.useState(false);
    const [globalState] = React.useContext(GlobalStateContext);

    const [checkBanners, setCheckBanners] = React.useState(false);

    const { t } = useTranslation();

    React.useEffect(() => {

        setCheckBanners(false);
        async function load() {
            let _banner = await getBanners(globalState.accessToken, i18n.language ==="pt" ? 1 : 2)
                .then(response => { if (response.ok) { return response.json() } });

            let _findBanners = [];

            if (_banner) {
                _findBanners = _banner.filter(item => item.ativo === true);
            }

            if (_findBanners && _findBanners.length > 0) {
                setCheckBanners(true);
            }
        }
        load();
    }, [i18n.language]);

    return (
        globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
            redirect ? <Redirect push={true} to='/profile' /> : (
                <div className="home">
                    <Container>
                        <Row>
                            <div className="col-lg-4 col-12">
                                <UserHome goProfile={() => setRedirect(true)} />
                                <MessageHouse />
                            </div>
                            <div className="col-lg-8 col-12">
                                {checkBanners &&
                                    <BannerHome />
                                }
                                <WhoIsWhoHome />
                                <FolderManager path="/" />
                            </div>
                        </Row>
                    </Container>
                </div>
            )
    );
}