import * as React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { GlobalStateContext } from '../general/GlobalState';
import { useTranslation } from 'react-i18next';

import { AdvertisingGrid } from '../advertisingBudget/AdvertisingGrid';
import { Buttons } from '../utilities/Buttons';

import '../advertisingBudget/advertisingBudget.css';
import PermissionRedirect from '../utilities/PermissionRedirect/PermissionRedirect';
import { hasPermissions } from "../utilities/Utils";


export const AdvertisingBudget = () => {
  const [globalState] = React.useContext(GlobalStateContext);
  const [redirect, setRedirect] = React.useState(false);
  const [isNewAdvertising, setIsNewAdvertising] = React.useState(false);
  const [idAdvertising, setIdAdvertising] = React.useState(null);
  const [userPermission, setUserPermission] = React.useState([]);
  const [itemsGridExport, setItemsGridExport] = React.useState([]);
  let history = useHistory();
  let gridInstance = React.useRef(null);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (idAdvertising !==null) {
      setRedirect(true);
    }
  }, [idAdvertising]);

  const _controls = {
    permit: userPermission ? userPermission : null,
    export: () => exportToExcel(),
    import: '',
    new: hasPermissions(32, "Admin", globalState) ? () => setRedirect(true) : false,
    back: '',
  };

  React.useEffect(() => {
    let globalPermissions = globalState.permissions;
    setUserPermission(globalPermissions.filter((i) => i.idAreaAcesso === 32).map((i) => (i.permission)));
  }, [globalState]);

  React.useEffect(() => {
    console.log(history);
  }, []);

  const toBRL = valor => {
    return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  const exportToExcel = () => {
    if (gridInstance.current) {
      const items = itemsGridExport.filter((item) => {
        item.custoProducao = toBRL(item.custoProducao);
        item.custoMidia = toBRL(item.custoMidia);
        item.custoHonorarios = toBRL(item.custoHonorarios);
        item.custoTotal = toBRL(item.custoTotal);
        item.pagamentoProducao = toBRL(item.pagamentoProducao);
        item.pagamentoMidia = toBRL(item.pagamentoMidia);
        item.pagamentoHonorarios = toBRL(item.pagamentoHonorarios);
        item.pagamentoTotal = toBRL(item.pagamentoTotal);
        return item;
      });
      const excelExportProperties = {
        dataSource: items
      };

      gridInstance.current.excelExport(excelExportProperties);
    }
  };

  return (
    <>
      {globalState.accessToken === null ? (
        <Redirect push={true} to="/login" />
      ) : redirect ? (
        idAdvertising === null ? (
          <Redirect push={true} to="/brandingadvertising/advertisingbudgetItem" />
        ) : (
          <Redirect push={true}
            to={`/brandingadvertising/advertisingbudgetItem/${idAdvertising}`}
          />
        )
      ) : (
        <>
          <PermissionRedirect id="32" />
          <div className="DefaultTop" />
          <Container className="containerTop">
            <div className="topTitleButtons">
              <Row>
                <div className="col-sm-8 col-12">
                  <div className="title">
                    <h1>{t('AdvertisingBudget:title')}</h1>
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div className="buttons">
                    <Buttons controls={_controls} />
                  </div>
                </div>
              </Row>
            </div>
            <Row>
              <div className="col-lg-12 col-12">
                <AdvertisingGrid
                  setIdAdvertising={setIdAdvertising}
                  gridInstance={gridInstance}
                  setItemsGridExport={setItemsGridExport}
                />
              </div>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};
