import React, { useContext, createContext, useState } from 'react';

const ContractsContext = createContext({});

const ContractProvider = ({ children }) => {
    const [modalData, setModalData] = useState();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [contracts, setContracts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    const openEdit = (args) => {
        setOpenEditModal(true);

        if (args) {
            setModalData(args);
        } else {
            setModalData(null);
        }
    }

    return (
        <ContractsContext.Provider value={{
            openEditModal,
            setOpenEditModal,
            contracts,
            setContracts,
            modalData,
            suppliers,
            setSuppliers,
            openEdit
        }}>
            {children}
        </ContractsContext.Provider>
    );
};

const useContract = () => {
    const context = useContext(ContractsContext);

    if (!context) {
        throw new Error('useContract must be within an ContractProvider');
    }

    return context;
};

export { useContract, ContractProvider };