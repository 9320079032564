import React, { useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { getChartObj } from '../../services/UsersSVC';
import { GlobalStateContext } from "../general/GlobalState";

export const WhoIsWho = ({ idUser }) => {

    const [globalState] = useContext(GlobalStateContext);
    const [listSubordinates, setListSubordinates] = useState([]);
    const [listChart, setListChart] = useState(null);

    useEffect(() => {
        async function getChart() {
            const chartObj = await getChartObj(globalState.accessToken, idUser).then(res => {
                if (res.ok)
                    return res.json();
            });

            const [chartTree] = list_to_tree(chartObj);

            setListChart(chartTree);


        }
        getChart();
    }, []);

    const list_to_tree = (list) => {
        var map = {}, node, roots = [], i;

        for (i = 0; i < list.length; i += 1) {
            map[list[i].id] = i; // initialize the map
            list[i].children = []; // initialize the children
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.manager) {
                // if you have dangling branches check that map[node.parentId] exists
                list[map[node.manager]].children.push(node);
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    const TopWho = ({ item }) => {
        if (item && item.children.length > 0) {
            setListSubordinates(item.children);
        }
        return (
            <>
                { listChart && item && (item.children.length > 0 || listChart.children.length ===0) && <>
                    <div className="Diretores">
                        <div className={`mainNode ${item.id ===idUser ? "userWho" : ""}`}>
                            {item.isManager && <div className="shieldIcon" />}
                            <div className="innerNode">
                                <a href={`/profile/${item.id}`}><span className="name">{item.name}</span></a>
                                <span className="role">{item.role}</span>
                                <span className="role2">{item.role2}</span>
                            </div>
                            <img src={`${process.env.REACT_APP_API_URL}/_api/Users/GetUserPhoto?token=${globalState.tokenLightWeight}&userId=${item.id}&sizeType=42`} alt="" />
                        </div>
                        <div className="vertical connector" />
                    </div>
                    { item.children && item.children.length > 0 && <TopWho item={item.children[0]} />}
                </>
                }
            </>
        )
    }

    return (
        <div className="whoIswho">

            <div className="top d-flex flex-row align-items-center">
                <div className="mdl2 mdl2-hierarchy" />
                <h2>Who is Who</h2>
            </div>
            <div className="content">
                <div className="topWho">
                    {listChart && <TopWho item={listChart} />}
                </div>
                <div className="bottomWho">
                    {listSubordinates &&
                        listSubordinates.map((i, index) => (
                            <div key={index} className="Subordinados">
                                <div className={`mainNode ${i.id ===idUser ? "userWho" : ""}`}>
                                    {i.isManager && <div className="shieldIcon" />}
                                    <div className="innerNode">
                                        <a href={`/profile/${i.id}`}><span className="name">{i.name}</span></a>
                                        <span className="role">{i.role}</span>
                                        <span className="role2">{i.role2}</span>
                                    </div>
                                    <img src={`${process.env.REACT_APP_API_URL}/_api/Users/GetUserPhoto?token=${globalState.tokenLightWeight}&userId=${i.id}&sizeType=31`} alt="" />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
