﻿import { EventEmitter } from './Events';
import i18n from 'i18next';

const hostURL = process.env.REACT_APP_API_URL + '/';
//const hostURL = "https://novocgc-api.azurewebsites.net/";
// const hostURL = 'https://localhost:5001/';

export async function requestAPI(endPointURL, method, queryString, body, token, isFormData, test, hasPaginationUrl) {

    var myHeaders = new Headers();
    if (!isFormData)
        myHeaders.append("Content-Type", "application/json");
    if (token)
        myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: method,
        headers: myHeaders,
        redirect: 'follow'
    };
    if (body)
        requestOptions.body = body;

    if (test)
        requestOptions.responseType = 'arraybuffer';
    const response = await fetch(`${!hasPaginationUrl ? hostURL : ""}${endPointURL}${queryString}`, requestOptions);
    if (!response.ok && response.status === 401)
        EventEmitter.dispatch('logout');
    return response;
}
export const getURLParamsByKey = (name) => {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};
export function getRoutes() {
    return [
        { path: "/login", namePt: "Login" },
        { path: "/", namePt: "", breadcrumb: { ignore: true } },
        { path: "/profile", namePt: "Admin", breadcrumb: { ignore: true } },
        { path: "/admin/users", namePt: "Admin", breadcrumb: { noLink: true } },
        { path: "/admin/usersItem/:id?", namePt: "User", breadcrumb: { ignore: true } },
        { path: "/admin/profiletemplates", namePt: "Admin", breadcrumb: { noLink: true } },
        { path: "/admin/profiletemplatesItem/:id?", namePt: "profileTemplate", breadcrumb: { link: "/admin/profiletemplates" } },
        { path: "/admin/Suppliers", namePt: "Admin", breadcrumb: { noLink: true } },
        { path: "/admin/SuppliersItem/:id?", namePt: "supplier", breadcrumb: { link: "/admin/Suppliers" } },
        { path: "/admin/bannerhome", namePt: "Admin", breadcrumb: { noLink: true } },
        { path: "/admin/errorlogs", namePt: "Admin", breadcrumb: { noLink: true } },
        { path: "/admin/signature", namePt: "Admin", nameEn: "Admin", breadcrumb: { noLink: true } },
        { path: "/admin/signatureItem/:id?", namePt: "Assinaturas", nameEn: "Signature", breadcrumb: { link: "/admin/signature" } },
        { path: "/press/releases", namePt: "Imprensa", nameEn: "Press", breadcrumb: { noLink: true } },
        { path: "/press/releasesItem/:id?", namePt: "Releases", nameEn: "Press Releases", breadcrumb: { link: "/press/releases" } },
        { path: "/press/releases/reports", namePt: "Relatórios", nameEn: "Reports", breadcrumb: { link: "/press/releases" } },
        { path: "/press/archive", namePt: "Imprensa", nameEn: "Press", breadcrumb: { noLink: true } },
        { path: "/press/statements", namePt: "Imprensa", nameEn: "Press", breadcrumb: { noLink: true } },
        { path: "/press/statements/reports", namePt: "Relatórios", nameEn: "Reports", breadcrumb: { link: "/press/statements" } },
        { path: "/press/statementsItem/:id?", namePt: "Notas", nameEn: "Statements", breadcrumb: { link: "/press/statements" } },
        { path: "/press/positionpaper", namePt: "Imprensa", nameEn: "Press", breadcrumb: { noLink: true } },
        { path: "/press/positionpaper/reports", namePt: "Relatórios", nameEn: "Reports", breadcrumb: { link: "/press/positionpaper" } },
        { path: "/press/positionpaperItem/:id?", namePt: "Posicionamentos", nameEn: "Position Paper", breadcrumb: { link: "/press/positionpaper" } },
        { path: "/press/liaison", namePt: "Imprensa", nameEn: "Press", breadcrumb: { noLink: true } },
        { path: "/press/liaisonItem/:id?", namePt: "Atendimento", nameEn: "Liaison", breadcrumb: { link: "/press/liaison" } },
        { path: "/press/liaison/reports", namePt: "Atendimento", nameEn: "Liaison", breadcrumb: { link: "/press/liaison" } },
        { path: "/press/mailing/contacts", namePt: "Imprensa", nameEn: "Press", breadcrumb: { noLink: true } },
        { path: "/press/mailing/contactsItem/:id?", namePt: "Contatos Jornalistas", nameEn: "Journalist Contacts", breadcrumb: { link: "/press/mailing/contacts" } },
        { path: "/press/mailing/groups", namePt: "Imprensa", nameEn: "Press", breadcrumb: { noLink: true } },
        { path: "/press/mailing/groupsItem/:id?", namePt: "Grupos Jornalistas", nameEn: "Journalists Groups", breadcrumb: { link: "/press/mailing/groups" } },
        { path: "/press/generalMailing/contacts", namePt: "Imprensa", nameEn: "Press", breadcrumb: { noLink: true } },
        { path: "/press/generalMailing/contactsItem/:id?", namePt: "Contatos Internos", nameEn: "Internal Contacts", breadcrumb: { link: "/press/generalMailing/contacts" } },
        { path: "/press/generalMailing/groups", namePt: "Imprensa", nameEn: "Press", breadcrumb: { noLink: true } },
        { path: "/press/generalMailing/groupsItem/:id?", namePt: "Grupos Internos", nameEn: "Internal Groups", breadcrumb: { link: "/press/generalMailing/groups" } },
        { path: "/press/qa", namePt: "Imprensa", nameEn: "Press", breadcrumb: { noLink: true } },
        { path: "/press/qaItem/:id?", namePt: "Q & A", nameEn: "Q & A", breadcrumb: { link: "/press/qa" } },
        { path: "/press/webcastnotices", namePt: "Imprensa", nameEn: "Press", breadcrumb: { noLink: true } },
        { path: "/press/webcastnoticesItem/:id?", namePt: "Avisos de Webcast", nameEn: "Webcast notices", breadcrumb: { link: "/press/webcastnotices" } },
        { path: "/press/assignmentnotices", namePt: "Imprensa", nameEn: "Press", breadcrumb: { noLink: true } },
        { path: "/press/assignmentnoticesItem/:id?", namePt: "Avisos de Pauta", nameEn: "Assignment Notices", breadcrumb: { link: "/press/assignmentnotices" } },
        { path: "/press/shareddocuments", namePt: "Imprensa", nameEn: "Press", breadcrumb: { noLink: true } },
        { path: "/publicSharedDocuments/:token", namePt: "Documentos Compartilhados", nameEn: "Shared Documents", breadcrumb: { noLink: true } },
        { path: "/planningresearch/shareddocuments", namePt: "Planejamento e Pesquisa", nameEn: "Planning and Research", breadcrumb: { noLink: true } },
        { path: "/eventsvisits", namePt: "Eventos e Visitas", nameEn: "Events and Visits", breadcrumb: { noLink: true } },
        { path: "/digitalmedia/videos", namePt: "Mídias Digitais", nameEn: "Digital Media", breadcrumb: { noLink: true } },
        { path: "/digitalmedia/shareddocuments", namePt: "Mídias Digitais", nameEn: "Digital Media", breadcrumb: { noLink: true } },
        { path: "/communicationemployees/shareddocuments", namePt: "Comunicação com Empregados", nameEn: "Communication with Employees", breadcrumb: { noLink: true } },
        { path: "/communicationemployees/globalcalendar", namePt: "Calendário Global", nameEn: "Global Calendar", breadcrumb: { noLink: true } },
        { path: "/communicationemployees/internalcampaigns", namePt: "Campanhas Internas", nameEn: "Internal Campaigns", breadcrumb: { noLink: true } },
        { path: "/communicationemployees/events", namePt: "Eventos", nameEn: "Events", breadcrumb: { noLink: true } },
        { path: "/communicationemployees/globalguidelines", namePt: "Guidelines Globais", nameEn: "Global Guidelines", breadcrumb: { noLink: true } },
        { path: "/communicationemployees/internalresearchresults", namePt: "Resultado Pesquisas Internas", nameEn: "Internal Research Results", breadcrumb: { noLink: true } },
        { path: "/communicationemployees/specialprojects", namePt: "Projetos Especiais", nameEn: "Special Projects", breadcrumb: { noLink: true } },
        { path: "/communicationemployees/directcommunication", namePt: "Comunicação Direta", nameEn: "Direct Communication", breadcrumb: { noLink: true } },
        { path: "/communicationemployees/internalvehicles", namePt: "Veículos Internos", nameEn: "Internal Vehicles", breadcrumb: { noLink: true } },
        { path: "/communicationemployeesGroup", namePt: "Grupos Comunicação com Empregados", nameEn: "Communication with Employees Groups", breadcrumb: { noLink: true } },
        { path: "/communicationemployeesGroup/communicationemployeesItem/:id?", namePt: "Comunicação com Empregados", nameEn: "Communication with Employees", breadcrumb: { link: "/communicationemployeesGroup" } },
        { path: "/brandingadvertising/manageinternalareas", namePt: "Marca e Publicidade", nameEn: "Branding and Advertising", breadcrumb: { noLink: true } },
        { path: "/brandingadvertising/manageannualbudget", namePt: "Marca e Publicidade", nameEn: "Branding and Advertising", breadcrumb: { noLink: true } },
        { path: "/brandingadvertising/managecontracts", namePt: "Marca e Publicidade", nameEn: "Branding and Advertising", breadcrumb: { noLink: true } },
        { path: "/brandingadvertising/measurementreport", namePt: "Marca e Publicidade", nameEn: "Branding and Advertising", breadcrumb: { noLink: true } },
        { path: "/brandingadvertising/advertisingbudget", namePt: "Marca e Publicidade", nameEn: "Branding and Advertising", breadcrumb: { noLink: true } },
        { path: "/brandingadvertising/advertisingbudgetItem/:id?", namePt: "Orçamento de Publicidade", nameEn: "Advertising Budget", breadcrumb: { link: "/brandingadvertising/advertisingbudget" } },
        { path: "/brandingadvertising/shareddocuments", namePt: "Marca e Publicidade", nameEn: "Branding and Advertising", breadcrumb: { noLink: true } },
        { path: "/brandingadvertising/brandcenter", namePt: "Marca e Publicidade", nameEn: "Branding and Advertising", breadcrumb: { noLink: true } },
        { path: "/publicShareDocuments", namePt: "Documentos Públicos Compartilhados", nameEn: "Public Shared Documents", breadcrumb: { noLink: true } },

        { path: "/countries", namePt: "Países", nameEn: "Countries", breadcrumb: { noLink: true } },
        { path: "/countries/brazil", namePt: "Brasil", nameEn: "Brazil", breadcrumb: { noLink: true } },
        { path: "/countries/canada", namePt: "Canadá", nameEn: "Canada", breadcrumb: { noLink: true } },
        { path: "/countries/china", namePt: "China", nameEn: "China", breadcrumb: { noLink: true } },
        { path: "/countries/global", namePt: "Global", nameEn: "Global", breadcrumb: { noLink: true } },
        { path: "/countries/indonesia", namePt: "Indonésia", nameEn: "Indonesia", breadcrumb: { noLink: true } },
        { path: "/countries/oman", namePt: "Omã", nameEn: "Oman", breadcrumb: { noLink: true } },
        { path: "/countries/japan", namePt: "Japão", nameEn: "Japan", breadcrumb: { noLink: true } },
        { path: "/countries/malaysia", namePt: "Malásia", nameEn: "Malaysia", breadcrumb: { noLink: true } },
        { path: "/countries/mozambiquemalawi", namePt: "Moçambique / Malawi", nameEn: "Mozambique / Malawi", breadcrumb: { noLink: true } },
        { path: "/countries/newcaledonia", namePt: "Nova Caledônia", nameEn: "New Caledonia", breadcrumb: { noLink: true } },
        { path: "/countries/paraguay", namePt: "Paraguai", nameEn: "Paraguay", breadcrumb: { noLink: true } },
        { path: "/countries/peru", namePt: "Peru", nameEn: "Peru", breadcrumb: { noLink: true } },
        { path: "/countries/singapore", namePt: "Singapura", nameEn: "Singapore", breadcrumb: { noLink: true } },
        { path: "/countries/switzerland", namePt: "Suiça", nameEn: "Switzerland", breadcrumb: { noLink: true } },

        { path: "/home/whoiswho", namePt: "Home" },
        { path: "/home/messagehouseItem", namePt: "Home", nameEn: "Home", breadcrumb: { link: "/" } },
        { path: "/teste", namePt: "Home" }
    ];
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export function getDateTime(date) {
    return `${new Date(date).toLocaleDateString()} ${new Date(date).toLocaleTimeString()}`;
}

export function hasPermissions(areaId, type, globalState) {

    if (globalState.userAdmin) {
        return true;
    }

    const isAreaAdmin = globalState.permissions.find(permission => permission.idAreaAcesso ===areaId && permission.idPermissao ===1);

    if (isAreaAdmin) {
        return true;
    }

    let editPermissionId;

    switch (type) {
        case "Create": {
            editPermissionId = 3;
            break;
        }
        case "Edit": {
            editPermissionId = 4;
            break;
        }
        case "Delete": {
            editPermissionId = 5;
            break;
        }
        case "Approval": {
            editPermissionId = 6;
        }
    }

    let hasPermission;

    if (type) {
        hasPermission = globalState.permissions.find(permission => permission.idAreaAcesso ===areaId && permission.idPermissao ===editPermissionId);
    } else {
        hasPermission = globalState.permissions.find(permission => permission.idAreaAcesso ===areaId);
    }

    if (!hasPermission) {
        return false;
    } else {
        return true;
    }

};
