import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { LoginStateContext } from './LoginState';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { Redirect } from "react-router-dom";
import { login } from "../../services/UsersSVC";
import { Login } from "../../models/LoginModels";
import { useLastLocation } from 'react-router-last-location';

export const EnterComponent = () => {

    const [loginState, setLoginState, redirectMessage, setRedirectMessage] = React.useContext(LoginStateContext);
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);

    const { t } = useTranslation();

    const [errorMsg, setErrorMsg] = React.useState(null);

    const [toHome, setToHome] = React.useState(false);
    const [validateEmail, setValidateEmail] = React.useState(true);
    const [validatePassword, setValidatePassword] = React.useState(true);
    const [processing, setProcessing] = React.useState('e-success e-small');
    const { handleSubmit, register, triggerValidation } = useForm({ nativeValidation: true });

    const htmlEmailAttributes = { name: "email", type: "email", maxlength: "100" };
    const htmlPasswordAttributes = { name: "password", type: "password", maxlength: "100" };
    const lastLocation = useLastLocation();

    const onSubmit = async (data) => {
        contractBegin();
        login(new Login((data.Email).toLowerCase(), data.Password))
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            })
            .then(result => {
                var reg = result.regional !==null ? result.regional : null;
                setGlobalState(globalState => ({
                    ...globalState,
                    userAdmin: result.adminGlobal,
                    accessToken: result.token,
                    tokenLightWeight: result.tokenLightWeight,
                    userName: result.nome,
                    userPhoto: `${process.env.REACT_APP_API_URL}/_api/Users/GetUserPhoto?token=${result.tokenLightWeight}&userId=${result.id}`,
                    userEmail: data.Email.toLowerCase(),
                    userId: result.id,
                    userAdmin: result.adminGlobal,
                    regional: reg,
                    fornecedor: result.fornecedor,
                    permissions: result.permissoes && result.permissoes.length > 0 ?
                        result.permissoes.map((item, index) => ({
                            idAccessArea: item.idAreaAcesso,
                            accessArea: item.areaAcesso,
                            permission: item.permissao,
                            idAreaAcesso: item.idAreaAcesso,
                            idPermissao: item.idPermissao
                        })) :
                        []
                }));
                //localStorage.setItem("NovoCGCUserCache", JSON.stringify(globalState));
                //contractEnd();
                setToHome(true);
                setLoginState(loginState => ({ ...loginState, step: 0 }));

            })
            .catch(error => {
                console.error(error);
                setErrorMsg(error.statusText);
                setRedirectMessage("");
                setValidateEmail(false);
                setValidatePassword(false);
            });

    };

    let contractBegin = () => {
        setProcessing('e-success e-small e-round');
    }

    let contractEnd = () => {
        setProcessing('e-success e-small');
    }

    let beforeSubmit = async (e) => {
        e.preventDefault();
        const filledInEmail = await triggerValidation("Email");
        const filledInPassword = await triggerValidation("Password");
        if (filledInEmail || filledInPassword) {
            setRedirectMessage("");
        }
        setValidateEmail(filledInEmail);
        setValidatePassword(filledInPassword);
        handleSubmit(onSubmit)();
    }

    let forgotPassword = () => {
        setLoginState(loginState => ({ ...loginState, step: 2 }));
    }

    React.useEffect(() => {
        if (globalState.accessToken) {
            localStorage.setItem("NovoCGCUserCache", JSON.stringify(globalState));
            contractEnd();
            setToHome(true);
        }
    }, [globalState]);

    React.useEffect(() => {
        if (toHome) {
            console.log(lastLocation);
        }
    }, [toHome]);

    return (
        <>
            {toHome ? <Redirect push={true} to={lastLocation} /> :
                <>
                    <div className="meio">
                        <h1>{t('Enter:welcome_message')}</h1>
                        <h2>{t('Enter:login_message')}</h2>
                        <form onSubmit={(e) => beforeSubmit(e)}>
                            <div>
                                <label htmlFor="email">{t('Enter:label_login')}</label>
                                <TextBoxComponent
                                    id="email"
                                    name="Email"
                                    cssClass={!validateEmail ? "e-filled e-error" : "e-filled"}
                                    htmlAttributes={htmlEmailAttributes}
                                    placeholder={t('Enter:email_placeholder')}
                                    ref={register({
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                        }
                                    })} />
                            </div>
                            <div>
                                <label htmlFor="password">{t('Enter:label_password')}</label>
                                <TextBoxComponent
                                    id="password"
                                    cssClass={!validatePassword ? "e-filled e-error" : "e-filled"}
                                    name="Password"
                                    htmlAttributes={htmlPasswordAttributes}
                                    placeholder={t('Enter:password_placeholder')}
                                    ref={register({
                                        required: true
                                    })} />
                            </div>
                            {(!validateEmail || !validatePassword) ?
                                <div className="msgs">
                                    <span className="msgError">{t('Enter:invalid_message')}</span>
                                </div>
                                :
                                errorMsg &&
                                <div className="msgs">
                                    <span className="msgError">{errorMsg}</span>
                                </div>
                            }
                            <div className="msgs msgSuccess">
                                <span>{redirectMessage}</span>
                            </div>
                            <div className="botoes">
                                <ButtonComponent type="button" id="forgotPassword" cssClass="e-flat e-primary"
                                    onClick={() => forgotPassword()}>{t('Enter:forgot_password')}</ButtonComponent>
                                <ProgressButtonComponent type="submit" role="button" id="contract" cssClass="processing" content={t('Enter:submitButton_content')}
                                    enableProgress begin={() => contractBegin} end={() => contractEnd} isPrimary></ProgressButtonComponent>
                            </div>
                        </form>
                    </div>
                </>
            }
        </>
    );
}