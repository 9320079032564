import React, { useContext, useState, useEffect } from "react";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useForm } from 'react-hook-form';
import { useContract } from './context/ContractsContext';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import i18n from "i18next";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GlobalStateContext } from '../general/GlobalState'
import { useModal } from "../../hooks/Modal";
import { useTranslation } from 'react-i18next';
import { addItem, updateItem } from '../../services/ContractsSVC';
import { setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
setCulture('pt');
setCurrencyCode("BRL");

const ModalComponent = () => {

    const [globalState] = useContext(GlobalStateContext);

    const { openEditModal, modalData, setOpenEditModal, contracts, setContracts, suppliers } = useContract();

    const { t } = useTranslation();

    const { buildDialogMessage } = useModal();

    const { register, handleSubmit, setValue, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            contrato: modalData && modalData.contrato,
            agencia: modalData && modalData.agencia,
            inicioVigencia: modalData && modalData.inicioVigencia,
            fimVigencia: modalData && modalData.fimVigencia,
            valor: modalData && modalData.valor
        },
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    });

    useEffect(() => {
        register('agencia', {
            required: true
        });
        register('valor', {
            required: true
        });
        register('fimVigencia', {
            required: true
        })
        register('inicioVigencia', {
            required: true
        })
    }, [register]);

    const onSubmitClick = (data) => {
        setOpenEditModal(false);

        console.log(data);

        if (modalData) {
            updateContract(data);
        } else {
            createContract(data);
        }
    }

    const getError = (error) => {
        switch (error.type) {
            case "required": {
                return (
                    <div className="error">{t("Validation:required")}</div>
                )
            }
        }
    }

    let modalButtons = [
        {
            type: "submit",
            buttonModel: {
                isPrimary: true,
                content: i18n.language ==="pt" ? "Salvar" : "Save",
                cssClass: "bannerSave"
            },
            click: () => {
                handleSubmit(onSubmitClick)();
            }
        }
    ]

    const createContract = async (data) => {
        const item = {
            ...data,
            inicioVigencia: `${data.inicioVigencia.getFullYear()}-${data.inicioVigencia.getMonth() + 1}-${data.inicioVigencia.getDate()}`,
            fimVigencia: `${data.fimVigencia.getFullYear()}-${data.fimVigencia.getMonth() + 1}-${data.fimVigencia.getDate()}`,
            valor: Number(data.valor)
        }

        const newItem = await addItem(globalState.accessToken, JSON.stringify(item)).then(async res => {
            if (res.ok) {
                return res.json();
            } else {
                buildDialogMessage({
                    error: res.ok,
                })
            }
        });

        if (newItem && newItem.id) {
            newItem.inicioVigencia = new Date(item.inicioVigencia);
            newItem.fimVigencia = new Date(item.fimVigencia);

            setContracts(state => [...state, newItem]);
        }
    };

    const updateContract = async (data) => {
        const item = {
            ...modalData,
            ...data,
            agencia: data.agencia ? data.agencia : modalData.agencia,
            inicioVigencia: `${data.inicioVigencia.getFullYear()}-${data.inicioVigencia.getMonth() + 1}-${data.inicioVigencia.getDate()}`,
            fimVigencia: `${data.fimVigencia.getFullYear()}-${data.fimVigencia.getMonth() + 1}-${data.fimVigencia.getDate()}`,
            valor: Number(data.valor)
        }

        const newItem = await updateItem(globalState.accessToken, JSON.stringify(item)).then(async res => {
            if (res.ok) {
                return res.json();
            } else {
                buildDialogMessage({
                    error: res.ok,
                })
            }
        });

        if (newItem && newItem.id) {
            const oldContracts = [...contracts];
            const index = oldContracts.findIndex(contract => contract.id ===modalData.id);

            oldContracts[index] = newItem;

            setContracts(oldContracts);
        }
    };

    useEffect(() => {
        console.log(modalData);
    }, [modalData]);

    return (

        <DialogComponent
            id="modalEdit"
            width="662px"
            isModal={true}
            buttons={modalButtons}
            visible={openEditModal}
            showCloseIcon={true}
            close={() => setOpenEditModal(false)}
            locale={i18n.language}
        >
            <form>
                <div className="input">
                    <label htmlFor="contract">{i18n.language ==="pt" ? "Contrato *" : "Contract *"}</label>
                    <div className="e-float-input">
                        <TextBoxComponent
                            id="contrato"
                            name="contrato"
                            // className={errors.contrato ? "error" : ""}
                            htmlAttributes={{ maxlength: "100" }}
                            ref={register({
                                required: true
                            })}
                            type="text"
                            value={modalData && modalData.contrato}
                        />
                        {errors && errors.contrato && getError(errors.contrato)}
                    </div>
                </div>

                <div className="input">
                    <label htmlFor="agency">{i18n.language ==="pt" ? "Agência *" : "Agency *"}</label>
                    <div className="e-float-input">
                        <DropDownListComponent
                            id="agencia"
                            name="agencia"
                            maxLength="20"
                            fields={{ value: 'nome' }}
                            dataSource={suppliers}
                            value={modalData && modalData.agencia && modalData.agencia.nome}
                            change={(args) => setValue("agencia", args.itemData)}
                        />
                        {errors && errors.agencia && getError(errors.agencia)}
                    </div>
                </div>

                <div className="wrapFlexDiv">
                    <div className="input">
                        <label htmlFor="endTerm">{i18n.language ==="pt" ? "Inicio da Vigência *" : "Start of term *"}</label>
                        <div>
                            <DatePickerComponent
                                id="inicioVigencia"
                                name="inicioVigencia"
                                strictMode={true}
                                // className={errors.inicioVigencia ? "error" : ""}
                                value={modalData && new Date(modalData.inicioVigencia)}
                                locale={i18n.language}
                                change={args => setValue('inicioVigencia', args.value)}
                            />
                            {errors && errors.inicioVigencia && getError(errors.inicioVigencia)}
                        </div>
                    </div>

                    <div className="input">
                        <label htmlFor="endTerm">{i18n.language ==="pt" ? "Fim da Vigência *" : "End of term *"}</label>
                        <div>
                            <DatePickerComponent
                                id="fimVigencia"
                                name="fimVigencia"
                                strictMode={true}
                                // className={errors.fimVigencia ? "error" : ""}

                                value={modalData && new Date(modalData.fimVigencia)}
                                locale={i18n.language}
                                change={args => setValue('fimVigencia', args.value)}
                            />
                            {errors && errors.fimVigencia && getError(errors.fimVigencia)}
                        </div>
                    </div>

                    <div className="input">
                        <label htmlFor="value">{i18n.language ==="pt" ? "Valor *" : "Value *"}</label>
                        <div>
                            <NumericTextBoxComponent
                                id="valor"
                                format="c2"
                                value={modalData && Number(modalData.valor)}
                                name="valor"
                                change={args => setValue("valor", args.value)}
                            />
                            {errors && errors.valor && getError(errors.valor)}
                        </div>
                    </div>
                </div>
            </form>
        </DialogComponent>
    )
};

export default ModalComponent;