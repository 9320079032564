import React, { useEffect, useState, useRef, useCallback, useContext, useMemo } from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { createSpinner, DialogComponent, setSpinner, showSpinner } from "@syncfusion/ej2-react-popups";
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useDigitalMedia } from "./context/DigitalMediaContext";
import { useForm } from "react-hook-form";
import { uuidv4, formatBytes, hasPermissions } from '../utilities/Utils';
import { updateVideo, addVideo, removeVideoStorage, downloadDigitalMediaAttachment, getVideosEight, getVideosFour } from '../../services/DigitalMediaSVC';
import { useModal } from "../../hooks/Modal";

const uploadIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="61.1" height="62.1" viewBox="0 0 61.1 62.1"><defs><style>.a{fill:#dbdbdb;}.b{fill:#007e7a;}</style></defs><g transform="translate(-690 -235)"><path class="a" d="M715.8,285.9H704.7V297H701V285.9H690v-3.7h11.1V271.1h3.7v11.1h11.1v3.7ZM741.6,238a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H712.2v-3.7H738V279.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V241.7H704.9v22.1h-3.7V238Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V279.5Z"/><path class="b" d="M717.8,282.9H706.7V294H703V282.9H692v-3.7h11.1V268.1h3.7v11.1h11.1v3.7ZM743.6,235a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H714.2v-3.7H740V276.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V238.7H706.9v22.1h-3.7V235Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V276.5Z"/></g></svg>';

export const ModalVideo = ({ toggle }) => {

    const [globalState] = useContext(GlobalStateContext);

    const dropdownListCountry = useRef(null);
    const dropdownListCategory = useRef(null);
    const autoCompleteApproverRef = useRef(null);
    const dropdownListRegional = useRef(null);
    const generatedThumbResponse = useRef(null);
    const [tempGuid, setTempGuid] = useState("");
    // const [currentItem, setCurrentItem] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [videoBlob, setVideoBlob] = useState();
    const [videoSrc, setVideoSrc] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [openModalApprove, setOpenModalApprove] = useState(false);
    const [imgUrlChanged, setImgUrlChanged] = useState(false);
    const [editPage, setEditPage] = useState(false);
    const [blockIdList, setBlockIdList] = useState([]);
    const [uploadChunkSize, setUploadChunkSize] = React.useState(1000000);
    const [showPlayer, setShowPlayer] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [isLoadingItem, setIsLoadingItem] = useState(false);
    const [isVideoChanging, setIsVideoChanging] = useState(false);

    const { buildDialogMessage } = useModal();

    const {
        changeModalVisible,
        currentItem,
        countriesList,
        regionalList,
        categoryList,
        statusList,
        users,
        modalIsVisible,
        approvedVideos,
        setApprovedVideos,
        pendingVideos,
        setPendingVideos
    } = useDigitalMedia();

    let isInteraction;
    const videoRef = useRef(null);

    const submitForm = async values => {

        if (!isLoadingItem) {

            if (editPage) {

                if (values.aprovadoPor.id ===currentItem.enviadoPor.id) {
                    setError("aprovadoPor", "sameUser");
                    return;
                }

                setIsLoadingItem(true);

                const properties = uploadedFile ? {
                    nomeArquivoVideo: uploadedFile && uploadedFile.name,
                    formatoArquivoVideo: uploadedFile && uploadedFile.type,
                    tamanhoArquivoVideo: uploadedFile && uploadedFile.size,
                    duracaoArquivoVideo: uploadedFile && new Date(1000 * uploadedFile.duration).toISOString().substr(11, 8)
                } : {};

                const newVideoData = {
                    ...currentItem,
                    ...values,
                    ...properties,
                    videoMiniaturaB64: imgUrlChanged ? values.imgUrl.split(',')[1] : "",
                    nucleoVideoStatus: statusList.find(status => status.id ===1),
                    nucleoVideoCategoria: values.categoria,
                }

                try {
                    const video = await updateVideo(globalState.accessToken, JSON.stringify(newVideoData)).then(response => {
                        if (response.ok) {
                            return response.json();
                        }
                    })

                    newVideoData.id = video.id;
                } catch (e) {
                    buildDialogMessage({
                        titlePt: "Erro",
                        titleEn: "Error",
                        messagePt: "Um erro ocorreu ao atualizar o vídeo",
                        messageEn: "An error has ocurred while updating the video",
                        icon: 'e-icon-dlg-close e-icons',
                    });
                }

                const newPendingVideos = [...pendingVideos.value];
                const currentVideoIndex = [...pendingVideos.value].findIndex(videos => videos.id ===currentItem.id);

                newPendingVideos[currentVideoIndex] = newVideoData;

                setPendingVideos(state => ({ ...state, value: newPendingVideos }));
                setIsLoadingItem(false);

                buildDialogMessage({
                    titlePt: "Sucesso",
                    titleEn: "Success",
                    messageEn: "Video successfully updated",
                    messagePt: "Video atualizado com sucesso"
                });

            } else {

                if (values.aprovadoPor.id ===globalState.userId) {
                    setError("aprovadoPor", "sameUser");
                    return;
                }

                setIsLoadingItem(true);

                const modalVideoPayload = {
                    ...values,
                    enviadoEm: new Date(),
                    enviadoPor: {
                        email: globalState.userEmail,
                        nome: globalState.userName,
                        id: globalState.userId
                    },
                    nucleoVideoStatus: statusList.find(status => status.id ===1),
                    nucleoVideoCategoria: values.categoria,
                    nomeArquivoVideo: uploadedFile.name,
                    formatoArquivoVideo: uploadedFile.type,
                    tamanhoArquivoVideo: uploadedFile.size,
                    duracaoArquivoVideo: new Date(1000 * uploadedFile.duration).toISOString().substr(11, 8),
                    tempGuid,
                    videoMiniaturaB64: values.imgUrl.split(',')[1]
                }

                const video = await addVideo(globalState.accessToken, JSON.stringify(modalVideoPayload)).then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        buildDialogMessage({
                            titlePt: "Erro",
                            titleEn: "Error",
                            messagePt: "Erro ao atualizar o vídeo",
                            messageEn: "Error while adding the video",
                            error: 500
                        });
                    }
                });

                modalVideoPayload.id = video.id;

                const newPendingVideos = { ...pendingVideos };
                let paginationUrl = `${process.env.REACT_APP_API_URL}/_api/CoreVideoPaginationFour?$apply=filter(nucleoVideoStatus/id%20ne%202)&$orderby=enviadoEm%20desc&$skip=${newPendingVideos.value.length}`;

                if (newPendingVideos && newPendingVideos.value && newPendingVideos.value.length > 0 && newPendingVideos.value.length % 4 ===0) {
                    newPendingVideos.value.pop();

                    const pendingVideosList = await getVideosFour({
                        token: globalState.accessToken,
                        paginationUrl
                    }).then(res => {
                        if (res.ok)
                            return res.json();
                    });

                    if (pendingVideosList && pendingVideosList.value && pendingVideosList.value.length === 0) {
                        paginationUrl = "";
                    }

                    newPendingVideos["@odata.nextLink"] = paginationUrl;
                }

                newPendingVideos.value.unshift(modalVideoPayload);

                setPendingVideos(newPendingVideos);
                setIsLoadingItem(false);

                buildDialogMessage({
                    titlePt: "Sucesso",
                    titleEn: "Success",
                    messageEn: "Video successfully added",
                    messagePt: "Video adicionado com sucesso"
                });
            }

            changeModalVisible(false);
        }
    };

    const getError = (error) => {
        console.log(error);
        switch (error.type) {
            case "required": {
                return (
                    <div className="error">{t("Validation:required")}</div>
                )
            }
            case "validate": {
                return (
                    <div className="error">{t("Validation:required")}</div>
                )
            }
            case "sameUser": {
                return (
                    <div className="error">{t("Validation:sameUser")}</div>
                )
            }
        }
    }

    const downloadAttachment = () => {
        const url = window.URL.createObjectURL(new Blob([videoBlob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${currentItem.titulo}.${currentItem.formatoArquivoVideo}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    const { register, handleSubmit, getValues, setValue, errors, setError } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            titulo: currentItem && currentItem.titulo,
            aprovadoPor: currentItem && currentItem.aprovadoPor,
            descricao: currentItem && currentItem.descricao,
            pais: currentItem && currentItem.pais,
            categoria: currentItem && currentItem.nucleoVideoCategoria,
            regional: currentItem && currentItem.regional,
            comentario: currentItem && currentItem.comentario,
            imgUrl: ""
        },
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    })

    const formRef = useRef(null);

    const dropAreaRef = useRef(null);

    let uploadObj;

    const { t } = useTranslation();

    useEffect(() => {
        setOpenModal(true);
    }, [modalIsVisible])

    const asyncSettings = {
        saveUrl: `${process.env.REACT_APP_API_URL}/_api/CoreVideos/UploadVideoAttachmentChunks?IdOrGuid=${tempGuid ? tempGuid : currentItem && currentItem.id}`,
        removeUrl: `${process.env.REACT_APP_API_URL}/_api/CoreVideos/RemoveVideoAttachment?IdOrGuid=${tempGuid ? tempGuid : currentItem && currentItem.id}`,
        chunkSize: uploadChunkSize
    };

    const autoUpload = true;

    const values = getValues();

    const onRemoveFile = (args) => {
        if (args && args.currentRequest) {
            args.postRawFile = false;
        }
    }

    const onPausing = (args) => {
        if (args.event !== null && !navigator.onLine) {
            isInteraction = true;
        } else {
            isInteraction = false;
        }
    }

    const onResuming = (args) => {
        if (args.event !== null && !navigator.onLine) {
            isInteraction = true;
        } else {
            isInteraction = false;
        }
    }

    const onBeforeFailure = (args) => {
        //let proxy = this;
        args.cancel = isInteraction;
        // interval to check network availability on every 500 milliseconds
        let clearTimeInterval = setInterval(() => {
            if (navigator.onLine && uploadObj && uploadObj.filesData[0] && uploadObj.filesData[0].statusCode ===4) {
                uploadObj.resume(uploadObj.filesData);
                clearSetInterval();
            } else {
                if (!isInteraction && uploadObj && uploadObj.filesData[0] && uploadObj.filesData[0].statusCode ===3) {
                    uploadObj.pause(uploadObj.filesData);
                }
            }
        }, 500);
        // clear Interval after when network is available.
        const clearSetInterval = () => {
            clearInterval(clearTimeInterval);
        }
    }

    const isOwnerOrAdmin = useMemo(() => {
        return (currentItem &&
            currentItem.enviadoPor.id === globalState.userId) ||
            (globalState.userAdmin ||
                hasPermissions(21, "Admin", globalState))
    }, [currentItem]);

    const isApproved = useMemo(() => {
        return currentItem && currentItem.nucleoVideoStatus.id ===2;
    }, [currentItem]);

    const isDefaultDisabled = () => {

        if (currentItem && hasPermissions(21, "Edit", globalState) && (isOwnerOrAdmin) && !isApproved) { // caso um item esteja sendo editado
            return false;
        } else if (!currentItem && hasPermissions(21, "Create", globalState) && !editPage) { // caso um item novo esteja sendo criado
            return false;
        } else {
            return true;
        }

    };

    const chunkUploaded = (e) => {
        //let responseBlockId = JSON.parse(e.event.currentTarget.response);
        setBlockIdList(blockIdList => [...blockIdList, e.event.currentTarget.response]);
    }

    const whenFileSelected = (e) => {
        let chunkSize = e.fileInfo.size / 10;
        //uploadObj.asyncSettings.chunkSize = chunkSize;//parseInt(e.itemData.value, 10);
        setUploadChunkSize(uploadChunkSize => uploadChunkSize = chunkSize);
    }

    useEffect(() => {
        if (currentItem) {
            setEditPage(true);
            setValue("imgUrl", "hasThumb");
            downloadDigitalMediaAttachment(globalState.tokenLightWeight, currentItem.id)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    setVideoBlob(blob);
                    setVideoSrc(url);
                });
        } else {
            setTempGuid(uuidv4());
        }
    }, []);

    let modalButtons;

    if (currentItem) {
        modalButtons = [
            {
                type: "submit",
                buttonModel: {
                    isPrimary: false,
                    content: i18n.language ==="pt" ? "Deletar" : "Delete",
                    cssClass: `bannerDelete ${!(hasPermissions(21, "Delete", globalState) && (!isApproved || hasPermissions(21, "Admin", globalState)) && (isOwnerOrAdmin) && currentItem) && 'hidden'}`
                },
                click: () => {
                    setOpenModalDelete('true');
                }
            },
            {
                type: "submit",
                buttonModel: {
                    isPrimary: true,
                    content: i18n.language ==="pt" ? "Salvar" : "Save",
                    cssClass: `bannerSave ${!(hasPermissions(21, "Edit", globalState) && (isOwnerOrAdmin) && !isApproved && currentItem) && 'hidden'}`
                },
                click: () => {
                    !isLoadingItem && handleSubmit(submitForm)();
                }
            }
        ]
    } else {
        modalButtons = [
            {
                type: "submit",
                buttonModel: {
                    isPrimary: true,
                    content: i18n.language ==="pt" ? "Salvar" : "Save",
                    cssClass: "bannerSave"
                },
                click: () => {
                    handleSubmit(submitForm)();
                }
            }
        ]
    }

    let generateThumbOnClick = (e) => {
        e.preventDefault();
        let canvas = document.querySelector('#canvas');
        let video = document.querySelector('#video');
        canvas.width = 300;
        canvas.height = 175;
        let canvasCtx = canvas.getContext("2d");
        // canvas.setAttribute('style', 'display: none; width:100%; margin-bottom:1em')
        canvasCtx.drawImage(video, 0, 0, 320, 175);

        setValue("imgUrl", canvas.toDataURL(), {
            shouldValidate: false
        });

        setImgUrlChanged(true);
        generatedThumbResponse.current.style.display = "block";
    }

    let removeFiles = () => {
        changeModalVisible(false);
        const clearThumb = document.getElementById("UploadFiles") && document.getElementById("UploadFiles").ej2_instances[0];
        if (clearThumb !== null && clearThumb.clearAll !== null && typeof (clearThumb.clearAll) !== "undefined") {
            return clearThumb.clearAll();
        }
    }

    const uploadImage = () => {
        document.getElementsByClassName("e-file-select-wrap")[0].querySelector("button").click();
        return false;
    }

    const changeVideo = () => {
        setVideoSrc("");
        setValue("imgUrl", "");
        setShowPlayer(false);
        setIsVideoChanging(true);
        // setTempGuid(uuidv4());
    };

    useEffect(() => {
        createSpinner({ target: document.getElementById('modalLoading') })
        showSpinner(document.getElementById('modalLoading'));
        setSpinner({ type: 'Bootstrap' });


        createSpinner({ target: document.getElementById('loadingItem') })
        showSpinner(document.getElementById('loadingItem'));
        setSpinner({ type: 'Bootstrap' });
    }, [isLoadingItem]);

    useEffect(() => {
        if (currentItem && currentItem.pais && currentItem.pais.id ===6) {
            dropdownListRegional.current.enabled = true;
        } else {
            dropdownListRegional.current.enabled = false;
        }
    }, [currentItem]);

    const confirmDelete = async () => {
        await removeVideoStorage(globalState.accessToken, currentItem.id);

        if (currentItem.nucleoVideoStatus.id ===2) {
            const index = [...approvedVideos.value].findIndex(video => video.id ===currentItem.id);
            const newApprovedVideos = [...approvedVideos.value];

            newApprovedVideos.splice(index, 1);

            setApprovedVideos(state => ({ ...state, value: newApprovedVideos }))

            if (approvedVideos["@odata.nextLink"]) {
                const skipNumber = approvedVideos.value.length - 1;

                let skipString = approvedVideos["@odata.nextLink"].split("skip=");

                skipString[1] = skipNumber;

                skipString = skipString.join("skip=");

                const approvedList = await getVideosEight({
                    token: globalState.accessToken,
                    paginationUrl: skipString
                }).then(res => {
                    if (res.ok)
                        return res.json();
                });

                if (approvedList && approvedList.value && approvedList.value.length > 0) {

                    let paginationUrl = `${process.env.REACT_APP_API_URL}/_api/CoreVideoPaginationEight?$apply=filter(nucleoVideoStatus/id%20ne%202)&$orderby=enviadoEm%20desc&$skip=${newApprovedVideos.length + 1}`;

                    if (approvedList.value.length <= 1) {
                        paginationUrl = "";
                    }

                    const newVideos = {
                        ...approvedVideos,
                        value: [
                            ...newApprovedVideos,
                            approvedList.value[0],
                        ]
                    };

                    newVideos["@odata.nextLink"] = paginationUrl;

                    setApprovedVideos(newVideos);
                } else {
                    setApprovedVideos(videos => ({
                        paginationUrl: "",
                        value: [
                            ...videos.value
                        ]
                    }))
                }
            }

        } else {
            const index = [...pendingVideos.value].findIndex(video => video.id ===currentItem.id);
            const newPendingVideos = [...pendingVideos.value];

            newPendingVideos.splice(index, 1);

            setPendingVideos(state => ({ ...state, value: newPendingVideos }))

            if (pendingVideos["@odata.nextLink"]) {
                const skipNumber = pendingVideos.value.length - 1;

                let skipString = pendingVideos["@odata.nextLink"].split("skip=");

                skipString[1] = skipNumber;

                skipString = skipString.join("skip=");

                const pendingVideosList = await getVideosFour({
                    token: globalState.accessToken,
                    paginationUrl: skipString
                }).then(res => {
                    if (res.ok)
                        return res.json();
                });

                if (pendingVideosList && pendingVideosList.value && pendingVideosList.value.length > 0) {

                    let paginationUrl = `${process.env.REACT_APP_API_URL}/_api/CoreVideoPaginationFour?$apply=filter(nucleoVideoStatus/id%20ne%202)&$orderby=enviadoEm%20desc&$skip=${newPendingVideos.length + 1}`;

                    if (pendingVideosList.value.length <= 1) {
                        paginationUrl = "";
                    }

                    const newVideos = {
                        ...pendingVideos,
                        value: [
                            ...newPendingVideos,
                            pendingVideosList.value[0],
                        ]
                    };

                    newVideos["@odata.nextLink"] = paginationUrl;

                    setPendingVideos(newVideos);
                } else {
                    setPendingVideos(videos => ({
                        paginationUrl: "",
                        value: [
                            ...videos.value
                        ]
                    }))
                }
            }
        }

        setOpenModalConfirm(true);
    }

    const chunkComplete = async (args) => {
        var url = URL.createObjectURL(args.file.rawFile);

        let reader = new FileReader();
        reader.onload = function () {
            var aud = new Audio(reader.result);
            aud.onloadedmetadata = function () {
                setUploadedFile({ ...args.file, duration: aud.duration });
                setVideoSrc(url);
                setIsVideoChanging(false);
            };
        };
        reader.readAsDataURL(args.file.rawFile);
    };


    const fileUploading = (e) => {
        e.currentRequest.setRequestHeader("Authorization", "Bearer " + globalState.accessToken);
        if (blockIdList.length > 0) e.currentRequest.setRequestHeader("blockIdList", blockIdList.join(','));
    }

    const closeModal = () => {
        setOpenModalDelete(false);
        // changeModalVisible(false);
        toggle.close();
    }

    let videoOnload = () => {
        let canvas = document.querySelector('#canvas'),
            video = document.querySelector('#video');
        if (canvas) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
        }
    }

    const dropdownListCreated = () => {
        if (!isDefaultDisabled()) {
            if (currentItem) {
                if (currentItem && currentItem.pais && currentItem.pais.id && currentItem.pais.id ===6) {
                    dropdownListRegional.current.enabled = true;
                } else {
                    dropdownListRegional.current.enabled = false;
                }
                dropdownListCountry.current.enabled = true;
                dropdownListCategory.current.enabled = true;
                autoCompleteApproverRef.current.enabled = true;
            }
        } else {
            if (currentItem) {
                if (currentItem && currentItem.pais && currentItem.pais.id && currentItem.pais.id ===6) {
                    dropdownListRegional.current.enabled = true;
                } else {
                    dropdownListRegional.current.enabled = false;
                }
                dropdownListCountry.current.enabled = false;
                dropdownListCategory.current.enabled = false;
                autoCompleteApproverRef.current.enabled = false;
            }
        }
    };

    useEffect(() => {
        register('aprovadoPor', {
            validate: (value) => value && !!value.id || 'This is required.'
        });
        register('pais', {
            required: true,
        });
        register('categoria', {
            required: true,
        });
        register('regional', {
            required: false
        });
    }, [register]);

    const RenderVideoTag = useCallback(({ videoSrc }) => {
        return (
            <>
                <video onPlay={() => setShowPlayer(false)} onPause={() => setShowPlayer(true)} ref={videoRef} preload="metadata" crossOrigin={'Anonymous'} style={{ maxWidth: '100%', width: "100%" }} className={"thumb-video"} controls id="video" onLoadedMetadata={() => videoOnload()}>
                    <source src={videoSrc} type='video/mp4' />
                </video>
                {(!isApproved && (!editPage && (hasPermissions(21, "Edit", globalState) || hasPermissions(21, 'Create', globalState)))) && <button className="generateThumb" onClick={(e) => generateThumbOnClick(e)} type="button">{i18n.language ==="pt" ? "Escolher essa miniatura" : "Change thumbnail"}</button>}
            </>
        )
    }, []);

    return (
        <>
            <DialogComponent
                id="modalVideos"
                width="662px"
                isModal={true}
                buttons={modalButtons}
                visible={openModal}
                showCloseIcon={true}
                beforeClose={toggle.close}
                close={removeFiles}
                locale={i18n.language}
            >
                <form id="idModalBanner" ref={formRef} onSubmit={handleSubmit(submitForm)}>
                    <div ref={dropAreaRef} className={`upload ${videoSrc && "hidden"}`} id="uploadBanner">
                        <div className="new">
                            {!currentItem || isVideoChanging ?
                                <>
                                    <span dangerouslySetInnerHTML={{ __html: uploadIcon }} />
                                    {i18n.language ==="pt" ?
                                        <p>
                                            Arraste o arquivo aqui ou faça o<span onClick={() => uploadImage()}> carregamento</span>
                                            <span className="messageFileSize">Tamanho máximo permitido é de 1 GB.</span>

                                        </p>
                                        :
                                        <p>
                                            Drag the file here or<span onClick={() => uploadImage()}> upload</span>
                                            <span className="messageFileSize">Maximum allowed size is 1 GB.</span>

                                        </p>
                                    }
                                </> : <div id="modalLoading" />

                            }
                        </div>
                    </div>

                    <>
                        <div id="validateImage">

                        </div>
                        <UploaderComponent
                            id="UploadFiles"
                            name="upload"
                            type="file"
                            cssClass={videoSrc && "hidden"}
                            asyncSettings={asyncSettings}
                            allowedExtensions=".mp4"
                            dropArea={dropAreaRef && dropAreaRef.current}
                            htmlAttributes={{ tabindex: "0" }}
                            maxFileSize={1000000000}
                            locale={i18n.language}
                            autoUpload={autoUpload}
                            removing={onRemoveFile}
                            beforeRemove={(args) => {
                                args.currentRequest.setRequestHeader("Authorization", "Bearer " + globalState.accessToken);
                            }}
                            pausing={onPausing}
                            uploading={fileUploading}
                            chunkUploading={fileUploading}
                            chunkFailure={onBeforeFailure}
                            fileListRendering={whenFileSelected}
                            chunkSuccess={chunkUploaded}
                            success={(chunkComplete)}
                            resuming={onResuming}
                            multiple={false}
                            ref={upload => uploadObj = upload}
                        />
                    </>

                    <div className="videoContainer">
                        {
                            videoSrc && <RenderVideoTag
                                videoSrc={videoSrc} />
                        }

                        {showPlayer && <div className="playerVideo" onClick={() => {
                            videoRef.current.play()
                        }} />}
                        <canvas width="300" height="300" style={{ display: "none" }} id="canvas"></canvas>
                    </div>

                    {videoSrc &&
                        <>
                            <div className="detailsDiv">
                                <div>
                                    <label>{t("DigitalMedia:archive")}</label>
                                    <span>{uploadedFile ? uploadedFile.name : currentItem && currentItem.nomeArquivoVideo}</span>
                                </div>
                                <div>
                                    <label>{t("DigitalMedia:format")}</label>
                                    <span>{uploadedFile ? uploadedFile.type : currentItem && currentItem.formatoArquivoVideo}</span>
                                </div>
                                <div>
                                    <label>{t("DigitalMedia:size")}</label>
                                    <span>{uploadedFile ? formatBytes(uploadedFile.size) : currentItem && formatBytes(currentItem.tamanhoArquivoVideo)}</span>
                                </div>
                                <div>
                                    <label>{t("FolderContent:column_file")}</label>
                                    <span>{uploadedFile ? new Date(1000 * uploadedFile.duration).toISOString().substr(11, 8) : currentItem && currentItem.duracaoArquivoVideo}</span>
                                </div>
                            </div>

                            <div className="detailsDiv">
                                <div>
                                    <label>{i18n.language ==="pt" ? "Link do arquivo" : "File link"}</label>
                                    <a
                                        // download
                                        target="_blank"
                                        className="link"
                                        onClick={() => downloadAttachment()}
                                    >
                                        {t("DigitalMedia:downloadVideo")}
                                    </a>
                                </div>
                                {(!isDefaultDisabled() || (!editPage && (hasPermissions(21, "Edit", globalState) || hasPermissions(21, 'Create', globalState)))) && <button type="button" onClick={() => changeVideo()}>
                                    {i18n.language ==="pt" ? "Trocar vídeo" : "Change video"}
                                </button>}
                            </div>
                        </>
                    }

                    <p style={{ display: "none", marginTop: 10 }} className={"thumbGenerated-message"} ref={generatedThumbResponse}>{t("DigitalMedia:thumbGeneratedResponse")}</p>

                    {errors.imgUrl && <p className={"thumbGenerated-message thumbGenerated-message-error"}>{t("Validation:missingImage")}</p>}

                    <div className="input">
                        <label htmlFor="titulo">{i18n.language ==="pt" ? "Titulo" : "Title"} <label className="e-error">*</label></label>
                        <div className="e-float-input">
                            <input
                                ref={register({
                                    required: true,
                                    min: 0,
                                    max: 255,
                                })}
                                id="titulo"
                                name="titulo"
                                className={errors.titulo ? "error" : ""}
                                maxLength="100"
                                type="text"
                                disabled={editPage && isDefaultDisabled()}
                                // value={getValues("titulo")}
                                // value={currentItem && currentItem.titulo}
                                placeholder={i18n.language ==="pt" ? "Digite aqui o título do vídeo ..." : "Type the video title here ..."}
                            // onChange={(args) => (setModalVideo({ ...modalVideo, titulo: args.target.value }))}
                            />
                            <span className="e-float-line" />
                        </div>
                        {errors && errors.titulo && getError(errors.titulo)}
                    </div>

                    {currentItem && currentItem.approved &&
                        <div id="innerApprovals">
                            <label>{t("DigitalMedia:approvals")}</label>
                            <div id="innerContentModal" className="innerContent">
                                {/* <img src={currentItem.enviadoPor.profileUrl} alt="" /> */}
                                <div>
                                    <label>{currentItem.enviadoPor.name}</label>
                                    <span>{currentItem.dateTime}</span>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="inputsModal">
                        <div className="leftInputs">
                            <div className="input">
                                <label htmlFor="aprovadoPor">{t("DigitalMedia:approver")} <label class="e-error">*</label></label>
                                <AutoCompleteComponent
                                    id="aprovadoPor"
                                    name="aprovadoPor"
                                    cssClass={`${(!isApproved && (!editPage && (hasPermissions(21, "Edit", globalState) || hasPermissions(21, 'Create', globalState)))) ? "e-filled" : ""} ${errors.aprovadoPor ? "error" : ""}`}
                                    dataSource={users}
                                    value={currentItem && currentItem.aprovadoPor && currentItem.aprovadoPor.nome}
                                    fields={{ value: "nome" }}
                                    created={dropdownListCreated}
                                    ref={autoCompleteApproverRef}
                                    locale={i18n.language}
                                    placeholder={i18n.language ==="pt" ? "Digite o nome do aprovador" : "Enter the name of the approver"}
                                    htmlAttributes={{ tabindex: "0" }}
                                    change={(args) => setValue('aprovadoPor', args.itemData)}
                                />
                                {errors && errors.aprovadoPor && getError(errors.aprovadoPor)}
                            </div>

                            <div className="input">
                                <label htmlFor="pais">{t("DigitalMedia:country")} <label class="e-error">*</label></label>
                                <DropDownListComponent
                                    id="pais"
                                    name="pais"
                                    ref={ref => dropdownListCountry.current = ref}
                                    cssClass={`${(!isApproved && (!editPage && (hasPermissions(21, "Edit", globalState) || hasPermissions(21, 'Create', globalState)))) ? "e-filled" : ""} ${errors.country ? "error" : ""}`}
                                    dataSource={countriesList}
                                    fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                                    locale={i18n.language}
                                    created={dropdownListCreated}
                                    allowFiltering={true}
                                    value={currentItem && currentItem.pais && currentItem.pais.id}
                                    placeholder={i18n.language ==="pt" ? "Selecione o país" : "Select country"}
                                    htmlAttributes={{ tabindex: "0" }}
                                    change={(args) => {
                                        setValue('pais', args.itemData)
                                        if (args && args.itemData && args.itemData.id ===6) {
                                            dropdownListRegional.current.enabled = true;
                                        } else {
                                            dropdownListRegional.current.enabled = false;
                                            dropdownListRegional.current.value = null;
                                            setValue('regional', null);
                                        }
                                    }}
                                />
                                {errors && errors.pais && getError(errors.pais)}
                            </div>

                        </div>

                        <div className="rightInputs">
                            <div className="input">
                                <label htmlFor="categoria">{t("DigitalMedia:category")} <label class="e-error">*</label></label>
                                <DropDownListComponent
                                    id="categoria"
                                    name="categoria"
                                    ref={ref => dropdownListCategory.current = ref}
                                    cssClass={`${(!isApproved && (!editPage && (hasPermissions(21, "Edit", globalState) || hasPermissions(21, 'Create', globalState)))) ? "e-filled" : ""} ${errors.categoria ? "error" : ""}`}
                                    dataSource={categoryList}
                                    fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                                    locale={i18n.language}
                                    created={dropdownListCreated}
                                    value={currentItem && currentItem.nucleoVideoCategoria && currentItem.nucleoVideoCategoria.id}
                                    placeholder={i18n.language ==="pt" ? "Selecione uma categoria" : "Select a category"}
                                    htmlAttributes={{ tabindex: "0" }}
                                    change={(args) => setValue('categoria', args.itemData)}
                                />
                                {errors && errors.categoria && getError(errors.categoria)}
                            </div>

                            <div className="input">
                                <label htmlFor="regional">{t("DigitalMedia:regional")}</label>
                                <DropDownListComponent
                                    id="regional"
                                    name="regional"
                                    ref={ref => dropdownListRegional.current = ref}
                                    cssClass={`${!isApproved && "e-valid-input e-filled"} ${(!isApproved && (!editPage && (hasPermissions(21, "Edit", globalState) || hasPermissions(21, 'Create', globalState)))) ? "e-filled" : ""} ${errors.regional ? "error" : ""}`}
                                    dataSource={regionalList}
                                    fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                                    locale={i18n.language}
                                    created={dropdownListCreated}
                                    value={currentItem && currentItem.regional && currentItem.regional.id}
                                    placeholder={i18n.language ==="pt" ? "Selecione uma regional" : "Select a regional"}
                                    htmlAttributes={{ tabindex: "0" }}
                                    enabled={false}
                                    change={(args) => setValue('regional', args.itemData)}
                                />
                                {errors && errors.regional && getError(errors.regional)}
                            </div>
                        </div>
                    </div>

                    {isLoadingItem && <div id="loadingItem" />}

                    <input ref={register({
                        required: "Thumbnail required"
                    })} type="text" id="imgUrl" name="imgUrl" style={{ display: "none" }} />

                    <div className="input">
                        <label htmlFor="descricao">{t("DigitalMedia:description")} <label class="e-error">*</label></label>
                        <div className="e-float-input">
                            <textarea
                                id="descricao"
                                name="descricao"
                                ref={register({
                                    required: true
                                })}
                                disabled={editPage && isDefaultDisabled()}
                                className={errors.descricao ? "error" : ""}
                                maxLength="200"
                                type="text"
                                // value={currentItem && currentItem.descricao}
                                placeholder={i18n.language ==="pt" ? "Digite aqui uma descrição para o vídeo..." : "Enter here a description for the video ..."}
                            // change={(args) => setValue('descricao', args.target.value)}
                            />
                            <span className="e-float-line" />
                        </div>
                        {errors && errors.descricao && getError(errors.descricao)}

                    </div>

                    {currentItem && currentItem.comentario &&
                        <div className="input">
                            <label htmlFor="titulo">{t("DigitalMedia:comments")}</label>
                            <div className="e-float-input">
                                <textarea
                                    id="comentario"
                                    name="comentario"
                                    ref={register({
                                        required: false
                                    })}
                                    className={`${errors.comentario ? "error" : ""}`}
                                    type="text"
                                    disabled={true}
                                    //defaultValue={currentItem && currentItem.descricao}
                                    placeholder={i18n.language ==="pt" ? "Digite aqui um comentário" : "Enter here a comment ..."}
                                // onChange={(args) => (setModalVideo({ ...modalVideo, commen: args.target.value }))}
                                />
                                <span className="e-float-line" />
                                {errors && errors.descricao && getError(errors.descricao)}
                            </div>
                        </div>
                    }

                </form>
            </DialogComponent >
            <DialogComponent
                id="deleteModal"
                isModal={true}
                visible={openModalDelete}
                locale={i18n.language}
            >
                <div>
                    <div className="divTrash">
                        {openModalApprove ?
                            <label className="mdl2 mdl2-confirm"></label>
                            :
                            <label className="mdl2 mdl2-trash"></label>
                        }
                        <div className="phraseDelete">
                            {openModalApprove ?
                                <label className="labelModal approve">{t("DigitalMedia:approve")}</label>
                                :
                                <label className="labelModal delete">{t("Actions:delete")}</label>
                            }
                            {openModalApprove ?
                                <label id="labelDelete">{t("DigitalMedia:modalApproval")}</label>
                                :
                                <label id="labelDelete">{t("Actions:confirmDeleteMsg")}</label>

                            }
                        </div>
                    </div>
                    <hr />
                    {!openModalConfirm ?
                        <div className="footerButtons">
                            <button onClick={() => setOpenModalDelete(false)}>{t("Actions:cancel")}</button>
                            <button onClick={confirmDelete}>{t("Actions:confirm")}</button>
                        </div>
                        :
                        <div className="deleteComplete">
                            <div className="confirmTrash">
                                <span className="success mdl2 mdl2-trash"></span>
                            </div>
                            <label>{t("DigitalMedia:deletedItem")}</label>
                            <button onClick={() => closeModal()}>{t("Actions:exit")}</button>
                        </div>
                    }
                </div >
            </DialogComponent>
        </>
    );
}
