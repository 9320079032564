import { requestAPI } from '../components/utilities/Utils';

export async function grantTemporaryAcess(token, body) {
    const raw = JSON.stringify(body);
    return requestAPI("_api/SharedDocuments/SharedDocumentsGrantTemporaryAcess", "POST", "", raw, token, false);
}

export async function sharePublicAccess(token, body) {
    const raw = JSON.stringify(body);
    return requestAPI("_api/SharedDocuments/SharedDocumentsCopyToPublicFolder", "POST", "", raw, token, false);
}

export async function downloadFile(isPublic, token, qstring, body) {
    const endPointURL = isPublic
    ?
        body.token 
      ? "_api/SharedDocuments/PublicSharedDocumentsDownload"
      : "_api/SharedDocuments/PublicSharedDocumentsDownloadTokenless"
    : "_api/SharedDocuments/SharedDocumentsDownload";
    var form_data = new FormData();
    form_data.append('downloadInput', JSON.stringify(body));
    return requestAPI(endPointURL, "POST", qstring, form_data, token, true);
}
