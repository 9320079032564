import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from "../general/GlobalState";
import { createSpinner, showSpinner, setSpinner, hideSpinner } from "@syncfusion/ej2-react-popups";
import { GridComponent, ColumnsDirective, ColumnDirective, ForeignKey, Filter, Inject, Sort, ContextMenu, Page, Resize, Edit, Toolbar, ExcelExport } from '@syncfusion/ej2-react-grids';
import { AutoCompleteComponent, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { getAllBusinessAreas, getCountries, getRegional } from "../../services/SystemSVC";
import { getPressItems, getPressItemsFiltered } from "../../services/PressSVC";
import '../releases/releases.css';

export const ReleasesList = (props) => {
    // debugger;

    const idTipo = 1;

    const { t } = useTranslation();
    const { refRelease, gridInstance } = props;

    const [globalState] = React.useContext(GlobalStateContext);
    const [isVisible, setIsVisible] = React.useState(true);
    const [fillUsersData, setFillUsersData] = React.useState([]);
    const [fillBusinessAreaData, setFillBusinessAreaData] = React.useState([]);
    const [fillCountriesData, setFillCountriesData] = React.useState([]);
    const [fillRegionalData, setFillRegionalData] = React.useState([]);
    const [releasesItems, setReleasesItems] = React.useState(null);
    const [allReleases, setAllReleases] = React.useState(null);
    const [filteredReleasesItems, setFilteredReleasesItems] = React.useState({ idTipo: idTipo, idPais: 6 });
    const [loadingReleases, setLoadingReleases] = React.useState(true);
    const [persistence, setPersistence] = React.useState(false);
    // const statusValue = (
    //   filteredReleasesItems?.bloqueado === null
    //     ? i18n.language ==='pt' ? 'Todos' : 'All'
    //     : filteredReleasesItems.bloqueado === false
    //       ? i18n.language ==='pt' ? 'Bloqueado' : 'Blocked'
    //       : i18n.language ==='pt' ? 'Terminado' : 'Finished'
    // );

    const ddlRegionalRef = React.useRef();

    const changePosition = (arr, from, to) => {
      arr.splice(to, 0, arr.splice(from, 1)[0]);
      return arr;
    }

    React.useEffect(() => {
        if (releasesItems && document.getElementById('loadRelease')) {
            hideSpinner(document.getElementById('loadRelease'));
            setLoadingReleases(false);
        }
    }, [releasesItems]);

    React.useEffect(() => {
        if (loadingReleases && document.getElementById('loadRelease')) {
            createSpinner({ target: document.getElementById('loadRelease') })
            showSpinner(document.getElementById('loadRelease'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [loadingReleases]);

    React.useEffect(() => {
        async function load() {
            let _businessAreas = await getAllBusinessAreas(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                });
            let _countries = await getCountries(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                });
            let _regional = await getRegional(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                });

            let filterQstring;

            if (typeof globalState.regional ==="array") {
                filterQstring = globalState.regional && globalState.regional.length > 0 ? `?$filter=idRegional eq ${globalState.regional.id}` : "";
            } else {
                filterQstring = globalState.regional ? `?$filter=idRegional eq ${globalState.regional.id}` : "";
            }

            let _pressItems = await getPressItems(globalState.accessToken, idTipo, filterQstring)
                .then(response => { if (response.ok) { return response.json() } });

            if (_pressItems) {
              _pressItems = _pressItems.filter(item => {
                item.bloqueado = item.bloqueado ? i18n.language ==="pt" ? "Bloqueado" : "Blocked" : i18n.language ==="pt" ? "Terminado" : "Finished";
                return item;
              });
              setReleasesItems(_pressItems);
              setAllReleases(_pressItems);
            }

            let oldUsers = _pressItems?.map(item => {
              return { nome: item.autores }
            });

            const set = new Set();
            const newUsers = oldUsers.filter(item => {
              const duplicate = set.has(item?.nome);
              set.add(item?.nome);

              return !duplicate;
            })

            setFillUsersData(newUsers);

            const newBusinessAreas = [..._businessAreas, { id: - 1, nomeEn: "All", nomePt: "Todos" }]
            const countries = [..._countries, { id: - 1, nomeEn: "All", nomePt: "Todos" }]
            const newRegional = [..._regional, { id: - 1, nomeEn: "All", nomePt: "Todos" }]

            let newCountries = countries;
            newCountries = changePosition(countries, 5, 0)

            setFillBusinessAreaData(newBusinessAreas);
            setFillCountriesData(newCountries);
            setFillRegionalData(newRegional);
        }
        load();
    }, [i18n.language]);

    React.useEffect(() => {
      if (globalState.regional && ddlRegionalRef && ddlRegionalRef.current)
            ddlRegionalRef.current.value = globalState.regional.id;
        else {
            setIsVisible(false);
        }
    }, [fillRegionalData]);

    const statusRelease = [
      {
        text: i18n.language ==='pt' ? 'Bloqueado' : 'Blocked',
        value: true
      }, {
        text: i18n.language ==='pt' ? 'Terminado' : 'Finished',
        value: false
      }, {
        text: i18n.language ==='pt' ? 'Todos' : 'All',
        value: null
      }
    ]

    React.useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language]);

    const filteredReleases = async () => {
      if (filteredReleasesItems) {
        let _filteredReleases = await getPressItemsFiltered(globalState.accessToken, filteredReleasesItems, idTipo)
          .then(response => {
            if (response.ok) {
              return response.json();
            }
          })

        _filteredReleases = _filteredReleases?.filter(item => {
          item.bloqueado = item.bloqueado ? i18n.language ==="pt" ? "Bloqueado" : "Blocked" : i18n.language ==="pt" ? "Terminado" : "Finished";
          return item;
        });

        setReleasesItems(_filteredReleases);
        setIsVisible(false);
      } else {
        setReleasesItems(allReleases);
      }
    }

    let releaseDate = (args) => {
        const dateRelease = new Date(args.data);
        return (
            <span>{dateRelease.toLocaleDateString(i18n.language)}</span>
        )
    }

    let releaseTitle = (args) => {
        return (
            <span className="title" onClick={() => props.edit(args)}>{args.titulo}</span>
        );
    }

    let releaseStatus = (args) => {
      if (args.bloqueado === 'Bloqueado') {
        return (<span className="status nao">{i18n.language ==='pt' ? 'Bloqueado' : 'Blocked'}</span>)
      } else {
        return (<span className="status sim">{i18n.language ==='pt' ? 'Terminado' : 'Finished'}</span>)
      }
    }

  const handleChangeRegional = (e) => {
    const { value } = e;
    setFilteredReleasesItems(
      filteredReleasesItems => ({
        ...filteredReleasesItems,
        idRegional: value
      })
    )
  }

    return (
        <>
            <div className={`busca ${isVisible ? "aberto" : ""}`}>
                <div className="filtroUsuarios d-flex">
                    <div className="SearchTitle">
                        <span className="mdl2 mdl2-search"></span>
                        <p>{t('Releases:search_text')}</p>
                    </div>
                    <div className="searchUsers">
                        <div className="e-input-in-wrap">
                            <TextBoxComponent
                                className="e-input"
                                placeholder={t('Releases:placeholder_search')}
                                locale={i18n.language}
                                value={filteredReleasesItems && filteredReleasesItems.conteudo ? filteredReleasesItems.conteudo : ""}
                                blur={(e) => setFilteredReleasesItems(
                                    filteredReleasesItems => ({
                                        ...filteredReleasesItems,
                                        conteudo: e.value
                                    })
                                )}
                            />
                        </div>
                    </div>
                    <div className="btnBusca d-flex">
                        {!isVisible &&
                            <div className="btnFilter">
                                <ButtonComponent
                                    className="e-outline filter"
                                    cssClass='e-primary filter'
                                    onClick={() => setIsVisible(!isVisible)}>
                                    <span className="mdl2 mdl2-filter"></span>
                                    {t('Releases:filters')}
                                </ButtonComponent>
                            </div>
                        }
                        {isVisible &&
                            <div className="btnFilter">
                                <ButtonComponent
                                    className="e-outline filter active-filter"
                                    cssClass='e-primary filter'
                                    onClick={() => setIsVisible(!isVisible)}>
                                    <span className="mdl2 mdl2-filter"></span>
                                    {t('Releases:filters')}
                                </ButtonComponent>
                            </div>
                        }
                        <div className="btnSearch">
                            <ButtonComponent cssClass='e-primary search'
                                onClick={() => filteredReleases()}
                            >
                                {t('Releases:search')}
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
                {isVisible &&
                    <div className="filtrosOcultos seis d-flex">
                        <DateRangePickerComponent
                            id="date"
                            cssClass="e-filled"
                            placeholder={t('Releases:date')}
                            locale={i18n.language}
                            enablePersistence={persistence}
                            created={() => setPersistence(true)}
                            change={(e) => setFilteredReleasesItems(
                              filteredReleasesItems => ({
                                ...filteredReleasesItems,
                                dataInicio: e.startDate && e.startDate.toISOString().split('.')[0],
                                dataFim: e.endDate && e.endDate.toISOString().split('.')[0]
                              })
                            )}
                          />
                        <AutoCompleteComponent
                            id="autor"
                            autofill={true}
                            cssClass="e-filled"
                            placeholder={t('Releases:author')}
                            dataSource={fillUsersData}
                            fields={{ value: 'nome' }}
                            showClearButton
                            value={filteredReleasesItems && filteredReleasesItems.autor}
                            change={(e) => setFilteredReleasesItems(
                                filteredReleasesItems => ({
                                    ...filteredReleasesItems,
                                    autor: e.value
                                })
                            )}
                        />
                        <DropDownListComponent
                            id="areaNegocio"
                            cssClass="e-filled"
                            placeholder={t('Releases:business_area')}
                            dataSource={fillBusinessAreaData}
                            showClearButton
                            fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                            locale={i18n.language}
                            value={filteredReleasesItems && filteredReleasesItems.idAreaNegocio}
                            change={(e) => setFilteredReleasesItems(
                                filteredReleasesItems => ({
                                    ...filteredReleasesItems,
                                    idAreaNegocio: e.value
                                })
                            )}
                        />
                        <DropDownListComponent
                            id="idPais"
                            cssClass="e-filled"
                            placeholder={t('Releases:country')}
                            dataSource={fillCountriesData}
                            allowFiltering={true}
                            showClearButton
                            fields={{ text: (i18n.language ==="pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
                            locale={i18n.language}
                            value={filteredReleasesItems ? filteredReleasesItems.idPais : ""}
                            change={(e) => setFilteredReleasesItems(
                                filteredReleasesItems => ({
                                    ...filteredReleasesItems,
                                    idPais: e.value
                                })
                            )}
                        />
                        {fillRegionalData.length &&
                          <DropDownListComponent
                              id="regional"
                              ref={ddlComponent => ddlRegionalRef.current = ddlComponent}
                              cssClass="e-filled"
                              placeholder={t('Releases:regional')}
                              dataSource={fillRegionalData}
                              showClearButton
                              fields={{ text: (i18n.language ==="pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
                              value={filteredReleasesItems ? filteredReleasesItems.idRegional : globalState.regional ? globalState.regional.id : ''}
                              locale={i18n.language}
                              change={handleChangeRegional}
                          />
                        }
                        {/* {console.log('statusValue', statusValue)} */}
                        <DropDownListComponent
                            id="status"
                            cssClass="e-filled"
                            placeholder={t('Releases:status')}
                            dataSource={statusRelease}
                            showClearButton
                            fields={{ text: "text", value: "value" }}
                            locale={i18n.language}
                            value={filteredReleasesItems && filteredReleasesItems.bloqueado}
                            change={(e) => setFilteredReleasesItems(
                                filteredReleasesItems => ({
                                    ...filteredReleasesItems,
                                    bloqueado: e.value
                                })
                            )}
                        />
                    </div>
                }
            </div>
            {loadingReleases ? <div id="loadRelease" className="loadRelease" /> :
                <div className="listaReleases">
                    {releasesItems &&
                        <GridComponent
                            id='gridcomp'
                            dataSource={releasesItems}
                            filterSettings={{ type: 'Menu' }}
                            allowFiltering={false}
                            allowPaging={true}
                            allowSorting={true}
                            allowExcelExport={true}
                            ref={(grid) => refRelease(grid)}
                            locale={i18n.language}>
                            <ColumnsDirective >
                                <ColumnDirective
                                    field='data'
                                    template={releaseDate}
                                    headerText={t('Releases:date')}
                                    width='100'>
                                </ColumnDirective>
                                <ColumnDirective
                                    field='titulo'
                                    template={releaseTitle}
                                    headerText={t('Releases:name')}
                                    width='400'>
                                </ColumnDirective>
                                <ColumnDirective
                                    field="autores"
                                    headerText={t('Releases:author')}
                                    width='350'>
                                </ColumnDirective>
                                <ColumnDirective
                                    field="bloqueado"
                                    template={releaseStatus}
                                    headerText={t('Releases:status')}
                                    width='150'>
                                </ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Edit, ForeignKey, Toolbar, ExcelExport]} />
                        </GridComponent>
                    }
                </div>
            }
        </>
    )
}
