import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { LoginStateContext } from './LoginState';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { forgot } from "../../services/UsersSVC";

export const ForgotComponent = () => {

    const [loginState, setLoginState, redirectMessage, setRedirectMessage] = React.useContext(LoginStateContext);

    const { t } = useTranslation();

    const [errorMsg, setErrorMsg] = React.useState(null);

    const { handleSubmit, register, triggerValidation, watch } = useForm({ nativeValidation: true });
    const htmlEmailAttributes = { type: "email", maxlength: "100", autoCapitalize: false };
    const [validateForgot, setValidateForgot] = React.useState(true);
    const [validatecConfirm, setValidateConfirm] = React.useState(true);
    const [similarEmail, setSimilarEmail] = React.useState(true);

    const onSubmit = async (data) => {
        forgot(data.Email)
            .then(response => {
                if (response.ok) {
                    setRedirectMessage("Email enviado com sucesso");
                    setLoginState(loginState => ({ ...loginState, step: 1 }));
                    return response.text();
                } else {
                    return Promise.reject(response);
                }
            })
            .catch(error => {
                console.error(error);
                setErrorMsg(error.statusText);
            });
    };

    let changeSolicitation = async () => {
        const triggerForgot = await triggerValidation("Email");
        const triggerConfirm = await triggerValidation("Confirm");
        const watchForgot = watch("Email").toLowerCase();
        const watchConfirm = watch("Confirm").toLowerCase();

        setValidateForgot(triggerForgot);
        setValidateConfirm(triggerConfirm);

        if (!triggerForgot || !triggerConfirm) {
            setValidateForgot(false);
            setValidateConfirm(false);
        }

        if (watchConfirm !==watchForgot) {
            setSimilarEmail(false);
        }

        if ((watchConfirm ===watchForgot) && triggerForgot && triggerConfirm) {
            handleSubmit(onSubmit)();
        }
    };

    let backLogin = () => {
        setLoginState(loginState => ({ ...loginState, step: 1 }));
    }

    return (
        <>
            <div className="meio">
                <h1>{t('Forgot:forgot_title')}</h1>
                <h2>{t('Forgot:forgot_message')}</h2>
                <form>
                    <div>
                        <label htmlFor="Email">{t('Forgot:label_first')}</label>
                        <TextBoxComponent
                            name="Email"
                            cssClass={!validateForgot || !similarEmail ? "e-filled e-error" : "e-filled"}
                            htmlAttributes={htmlEmailAttributes}
                            placeholder={t('Forgot:first_email_placeholder')}
                            ref={register({
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                }
                            })} />
                    </div>
                    <div>
                        <label htmlFor="Confirm">{t('Forgot:second_email')}</label>
                        <TextBoxComponent
                            name="Confirm"
                            cssClass={!validatecConfirm || !similarEmail ? "e-filled e-error" : "e-filled"}
                            htmlAttributes={htmlEmailAttributes}
                            placeholder={t('Forgot:second_email_placeholder')}
                            ref={register({
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                }
                            })} />
                    </div>
                </form>
            </div>

            {(!validateForgot || !validatecConfirm) ?
                <div className="msgs">
                    <span className="msgError">{t('Forgot:invalid_email_message')}</span>
                </div>
                :
                errorMsg &&
                <div className="msgs">
                    <span className="msgError">{errorMsg}</span>
                </div>
            }

            {!similarEmail &&
                <div className="msgs">
                    <span className="msgError">{t('Forgot:different_email_message')}</span>
                </div>
            }

            <div className="botoes">
                <ButtonComponent id="backToLogin" cssClass="e-flat e-primary"
                    onClick={() => backLogin()}>{t('Forgot:backButton')}</ButtonComponent>
                <ProgressButtonComponent id="sendEmail" cssClass="processing" content={t('Forgot:sendButton')}
                    onClick={() => changeSolicitation()} enableProgress isPrimary></ProgressButtonComponent>
            </div>
            <div>
                <span></span>
            </div>
        </>
    );
}