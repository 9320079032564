import { getFileExtension } from './Functions'

export function clearAttachmentsArea(html) {

  const doc = new DOMParser().parseFromString(html, "text/html");
  const attachmentContainer = doc.getElementById("attachmentWrapper");
  if (!attachmentContainer) {
    const attachmentsContainer = doc.getElementById("rteAttachments");
    attachmentsContainer.innerHTML = "";
  }
  return doc.documentElement.innerHTML;
}

export function youtube_parser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match && match[7].length ===11) ? match[7] : false;
}

export function getPressMediaTemplate(iconPath, filename, mediaUrl, type, description) {

  const newExt = getFileExtension(mediaUrl)

  switch(type) {
    case 'mp3':
      return `<table cellpadding="0" cellspacing="0" border="0">
        <tr>
          <td align="center" valign="center"
            style="
              padding: 5px;
              text-align: center;
              vertical-align: middle;
            "
          >
            <span>
              <a id="attachmentContainer" href="${mediaUrl}" target="_blank"
                style="
                  text-decoration: none;
                  cursor: pointer;
                "
              >
                <img src="${iconPath}"
                  id='attachment-pressForm'
                  mediaurl="${mediaUrl}"
                >
              </a>
            </span>
          </td>
          <td valign="center"
            style="
              color: #1F8D8A;
              padding: 5px 10px;
              vertical-align: middle;
            "
          >
            <a id="attachmentContainer"
              href="${mediaUrl}"
              target="_blank"
              contentEditable="false"
              style="
                text-decoration: none;
                cursor: pointer;
                color: #007E7A;
              "
            >
              ${filename || ''}
            </a>
            <span contentEditable="false"
              style="color: #007E7A;"
            >
              ${description ? '<br>' + description : ''}
            </span>
          </td>
        </tr>
      </table>`
    case 'attachment':
      return `<table cellpadding="0" cellspacing="0" border="0">
        <tr>
          <td align="center" valign="center" width="100"
            style="
              border: 1px #aaa solid;
              text-align: center;
              vertical-align: middle;
              padding: 10px;
              width: 100px;
              max-width: 100px;
            "
          >
            <span>
              <a id="attachmentContainer" href="${mediaUrl}" target="_blank"
                style="
                  text-decoration: none;
                  cursor: pointer;
                "
              >
                <img src="${newExt}"
                  id='attachment-pressForm'
                  mediaurl="${newExt}"
                  height="50"
                  style="min-height:50px; height:50px; max-height:50px;"
                >
              </a>
            </span>
          </td>
        </tr>
        <tr>
          <td align="center" valign="center" width="100"
            style="
              background-color: #1F8D8A;
              border: 1px #1F8D8A solid;
              color: white;
              padding: 5px 10px;
              text-align: center;
              vertical-align: middle;
              width: 100px;
              max-width: 100px;
              word-break: break-word;
            "
          >
            <span>${filename || ''}</span>
          </td>
        </tr>
      </table>`
    default:
      return `<img src="${iconPath}" width="600" style="min-width:600px; width:600px; max-width:600px; display: block">`
  }

}

export function mountSignature({id, idregional, tituloPT, tituloEN, urlpt, urlen, colaboradores, redesSociais}, lang, token) {

  const title = lang === "pt" ? tituloPT : tituloEN;
  const getUrlFromSocialMedia = (sm) => lang === "pt" ? sm.url : sm.urlen;

  return title ? `
    <p style="
      font-size: 11pt;
      font-family: Calibri, sans-serif;
      margin-right: 0;
      margin-left: 0;"
    >
    <span style="
      color: #747678;
      font-size: 10pt;
      font-family: Arial, sans-serif;"
    >
      ${title}<br />
      ${colaboradores.filter(i => i.nome || i.telefone || i.email || i.celular)
          .map((colaborador) => `
            <span>${colaborador.nome && colaborador.nome}</span>
            <span>${colaborador.telefone && (colaborador.nome && "- Tel: ") + colaborador.telefone}</span>
            <span>${colaborador.celular && ((colaborador.telefone && "/ Cel: ") || (colaborador.nome && "- Cel: ")) + colaborador.celular}</span>
            ${(colaborador.nome || colaborador.telefone || colaborador.celular) && colaborador.email && `<br />`}
            <a href="mailto:${colaborador.email}" target="_blank">
              ${colaborador.email && colaborador.email}
            </a>`
          )
          .join("<br/>")
      }
      <a href="${lang === "pt" ? urlpt : urlen}" target="_blank">
        <span style="color: #999999"><br/>${lang === "pt" ? urlpt : urlen}</span>
      </a>
      <br />
      <br />
    </span>
    ${redesSociais.filter(getUrlFromSocialMedia)
      .map(SM => `
        <span style="
          color: #747678;
          font-size: 10pt;
          font-family: Arial, sans-serif;
          margin-bottom: 5px;
          display: inline-block;"
        >
          ${SM.id ?
            `<img src="${process.env.REACT_APP_AZURE_STORAGE}/press/socialmedias/${SM.id}/socialMedia.png"
              border="0"
              id="x__x0000_i1051"
              style="width: 24px; height: 24px"
            />`
            : `${SM.iconeB64 && `
              <img
                src="${SM.iconeB64}"
                border="0"
                id="x__x0000_i1051"
                style="width: 24px; height: 24px"
              />`
            }`
          }
        </span>
        <span style="
          color: #747678;
          font-size: 10pt;
          font-family: Arial, sans-serif;
          vertical-align: middle;"
        >&nbsp;&nbsp;
          <a target="_blank"
            href="${getUrlFromSocialMedia(SM)}"
          >
            <span style="color: #999999">
              ${getUrlFromSocialMedia(SM)}
            </span>
          </a>
        </span>`
      ).join("<br/>")
    }
  </p>` : "";
}

export function getYoutubeVideoTemplate(id) {
  const youtubeVideo =
    `<!--[if !mso]> <!---->
        <!--[if (mso)|(IE)]>
            <table width="600" align="center" cellpadding="0" cellspacing="0"><tr><td>
        <![endif]-->
        <div style="max-width: 600px;margin: 20px 0 0;max-height:450px;" height="450px;" contenteditable="false">
        <!--[if !mso]-->
            <a href="https://www.youtube.com/watch?v=${id}" target="_blank" class="video-preview" style="background-color: #5b5f66; background-image: radial-gradient(circle at center, #5b5f66, #1d1f21); display: block; text-decoration: none;">
                <table cellpadding="0" cellspacing="0" border="0" width="100%" background="https://img.youtube.com/vi/${id}/hqdefault.jpg" style="background-size: cover; min-height: 450px;max-height: 450px; min-width: 600px;">
                    <tr style="transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);">
                        <td width="25%">
                            <img src="${process.env.REACT_APP_AZURE_STORAGE}/press/System/placehold_150x337.gif" alt="" width="100%" border="0" style="height: auto; opacity: 0; visibility: hidden !important;">
                        </td>
                        <td width="50%" align="center" valign="middle">
                            <div class="play-button" style="background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.1)); border: 4px solid white; border-radius: 50%; box-shadow: 0 1px 2px rgba(0,0,0,0.3), inset 0 1px 2px rgba(0,0,0,0.3); height: 34px; margin: 0 auto; padding: 18px 16px 18px 24px; transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1); width: 30px;box-sizing: content-box;">
                            <div style="border-color: transparent transparent transparent white; border-style: solid; border-width: 17px 0 17px 30px; display: block; font-size: 0; height: 0; Margin: 0 auto; width: 0;">&nbsp;</div>
                            </div>
                        </td>
                        <td width="25%">
                            &nbsp;
                        </td>
                    </tr>
                </table>
            </a>
        <!--[endif]-->
        <!--[if vml]>
            <v:group xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" coordsize="600,337" coordorigin="0,0" href="https://www.youtube.com/watch?v=${id}" style="width:600px;height:350px;">
            <v:rect fill="t" stroked="f" style="position:absolute;width:600"><v:fill src="https://img.youtube.com/vi/${id}/hqdefault.jpg" type="frame" /></v:rect>
            <v:oval fill="t" strokecolor="white" strokeweight="4px" style="position:absolute;left:261;top:129;width:78;height:78"><v:fill color="black" opacity="30%" /></v:oval>
            <v:shape coordsize="24,32" path="m,l,32,24,16,xe" fillcolor="white" stroked="f" style="position:absolute;left:289;top:151;width:30;height:34;" />
            </v:group>
        <![endif]-->
        </div>
        <!--[if (mso)|(IE)]>
                </td>
                </tr>
            </table>
        <![endif]-->&nbsp;
    <!-- <![endif]-->
    <!--[if mso]>
        <div style="max-width: 600px;margin: 20px 0 0;" width="600;" contenteditable="false">
            <a href="https://www.youtube.com/watch?v=${id}" target="_blank" width="600" style="display:block"><img src="https://img.youtube.com/vi/${id}/hqdefault.jpg" width="600"></a>
        </div>
    <![endif]-->
    `
  return youtubeVideo;
}

export function getPressVideoTemplate(videoUrl, thumbUrl) {
  return `
    <!--[if (mso)| (IE)]>
      <table width="600" style="margin: 0 auto;" align="center" cellpadding="0" cellspacing="0" role="presentation">
        <tr>
          <td>
    <![endif]-->

    <div style="max-width: 600px; min-width: 320px;margin: 20px 0 0;" contenteditable="false">
      <td valign="center" width="10">
        &nbsp;
      </td>
      <!--[if !mso]-->
        <a href="${videoUrl}" target="_blank" class="video-preview"
          style="
            background-color: #5b5f66;
            background-image: radial-gradient(circle at center, #5b5f66, #1d1f21);
            display: block;
            text-decoration: none;
          "
        >
          <table cellpadding="0" cellspacing="0" border="0" width="100%" background="${thumbUrl}" role="presentation" style="animation: video-frames 15s ease infinite; -webkit-animation: video-frames 15s ease infinite; background-size: cover; min-height: 180px; min-width: 320px; max-width: 600px; margin: 0 auto;">
            <tr style="transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);">
              <td width="25%">
                <img src="${process.env.REACT_APP_AZURE_STORAGE}/press/System/placehold_150x337.gif" alt="" width="100%" border="0" style="height: auto; opacity: 0; visibility: hidden !important;">
              </td>
              <td width="50%" align="center" valign="middle">
                <div class="play-button" style="background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.1)); border: 4px solid white; border-radius: 50%; box-shadow: 0 1px 2px rgba(0,0,0,0.3), inset 0 1px 2px rgba(0,0,0,0.3); height: 34px; margin: 0 auto; padding: 18px 16px 18px 24px; transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1); width: 30px;box-sizing: content-box;">
                  <div style="border-color: transparent transparent transparent white; border-style: solid; border-width: 17px 0 17px 30px; display: block; font-size: 0; height: 0; Margin: 0 auto; width: 0;">&nbsp;</div>
                </div>
              </td>
              <td width="25%">
                  &nbsp;
              </td>
            </tr>
          </table>
        </a>
      <!--[endif]-->
      <!--[if vml]>
      <v:group xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" coordsize="600,337" coordorigin="0,0" href="${videoUrl}" style="width:600px;height:337px;">
        <v:rect fill="t" stroked="f" style="position:absolute;width:600;height:337;"><v:fill src="${thumbUrl}" type="frame" /></v:rect>
        <v:oval fill="t" strokecolor="white" strokeweight="4px" style="position:absolute;left:261;top:129;width:78;height:78"><v:fill color="black" opacity="30%" /></v:oval>
        <v:shape coordsize="24,32" path="m,l,32,24,16,xe" fillcolor="white" stroked="f" style="position:absolute;left:289;top:151;width:30;height:34;" />
      </v:group>
      <![endif]-->
      <td valign="center" width="10">
        &nbsp;
      </td>
    </div>

    <!--[if (mso)|(IE)]>
          </td>
        </tr>
      </table>
    <![endif]-->`
};
