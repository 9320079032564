import * as React from 'react';
import { GlobalStateContext } from '../general/GlobalState';
import { useTranslation } from 'react-i18next';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Edit,
  Toolbar,
  Filter,
  Inject,
  Sort,
  ContextMenu,
  Page,
  Resize
} from '@syncfusion/ej2-react-grids';
import i18n from 'i18next';
import {
  DialogComponent,
  createSpinner,
  showSpinner,
  setSpinner,
} from '@syncfusion/ej2-react-popups';
import {
  downloadAttachment
} from './AdvertisingBudgetSVC';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useForm } from 'react-hook-form';
import { Buttons } from '../utilities/Buttons';
import { uuidv4 } from '../utilities/Utils';
import { AdvertisingBudgetContext } from './AdvertisingBudgetContext';
import { getURLParamsByKey } from '../utilities/Utils';
import GenericModal from '../utilities/MessageModal/GenericModal';
import { hasPermissions } from "../utilities/Utils";

export const FileContent = ({ options, tempGuid, IDAdBudget }) => {
  const [globalAdBudget, setGlobalAdBudget] = React.useContext(AdvertisingBudgetContext);
  const [globalState] = React.useContext(GlobalStateContext);

  const {
    register, handleSubmit, errors, setValue, clearError, reset, setError, } = useForm({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      defaultValues: { anexo: null, tipo: { id: null, nome: null } },
    });
  const { t } = useTranslation();
  const contextMenuItems = [
    'SortAscending',
    'SortDescending',
    'FirstPage',
    'PrevPage',
    'LastPage',
    'NextPage',
  ];


  const gridInstance = React.useRef(null);
  const dialogInstance = React.useRef();

  const [openModal, setOpenModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);

  const [blockIdList, setBlockIdList] = React.useState([]);
  const [gridData, setGridData] = React.useState([]);
  const [uploadChunkSize, setUploadChunkSize] = React.useState(1000000);
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [typeOfForm, settypeOfForm] = React.useState('');
  const [tempGuidFile, setTempGuidFile] = React.useState('');

  const [modalMessageParams, setmodalMessageParams] = React.useState({
    show: false,
  });

  // EFFECTS

  React.useEffect(() => {
    gridInstance.current.refresh();
  }, [i18n.language]);

  React.useEffect(() => {
    gridInstance.current.refresh();
  }, [globalAdBudget]);

  React.useEffect(() => {
    if (loading) {
      createSpinner({ target: document.getElementById('loading') });
      showSpinner(document.getElementById('loading'));
      setSpinner({ type: 'Bootstrap' });
    }
  }, [loading]);

  // EFFECTS
  React.useEffect(() => {
    if (loading2) {
      createSpinner({ target: document.getElementById('loading2') });
      showSpinner(document.getElementById('loading2'));
      setSpinner({ type: 'Bootstrap' });
    }
  }, [loading2]);


  const addTipoStatusNomeToAllItens = (anexos, opts) => {
    return anexos.map((item) => {
      const statusItem = options.status.filter((i) => i.value ===item.status.id)
      const tipoItem = options.tipos.filter((i) => i.value ===item.tipo.id)
      return {
        ...item,
        status: {
          ...item.status,
          nome: item.status.nome ? item.status.nome : statusItem.length > 0 ? statusItem[0].text : '',
        },
        tipo: {
          ...item.tipo,
          nome: item.tipo.nome ? item.tipo.nome : tipoItem.length > 0 ? tipoItem[0].text : '',
        },
      };

    });
  }

  React.useEffect(() => {
    if (globalAdBudget.anexos.length > 0 && options.tipos.length > 0 && options.status.length > 0) {
      let anexos = globalAdBudget.anexos;
      anexos = addTipoStatusNomeToAllItens(anexos, options)
      anexos = anexos.filter(item => item.ativo)
      setGridData(anexos);
      gridInstance.current.dataSource = anexos;
      gridInstance.current.refresh();
    }
  }, [globalAdBudget, options.status, options.tipos]);

  React.useEffect(() => {
    if (openModal ===false) {
      setValue('tipoMidia.id', null);
    }
  }, [openModal])


  const openEdit = (args) => {
    if (args) {
      setValue('tipo.id', args.tipo.id, { shouldValidate: true });
      setValue('id', args.id ? args.id : args.tempGuid, {
        shouldValidate: true,
      });
      setValue('status', args.status.id, { shouldValidate: true });
      setValue('nomeArquivo', args.nomeArquivo, { shouldValidate: true });
    } else {
      setTempGuidFile(uuidv4());
    }
    setOpenModal(true);
    dialogInstance.current.show();
  };

  const clearModal = () => {
    setValue('id', null);
    setValue('tipo.id', null)
    setValue('status', null);
    setValue('nomeArquivo', null);
  }

  // TEMPLATES
  const statusTemplate = (args) => {
    return (
      <span
        className={
          args.status.id ===2
            ? 'status aprovado'
            : args.status.id ===1 ? 'status aguardando' : 'status reprovado'
        }
      >
        {args.status.id ===2 ? i18n.language ==='pt' ? 'Aprovado' : 'Approved' : ''}
        {args.status.id ===1 ? i18n.language ==='pt' ? 'Pendente' : 'Pending' : ''}
        {args.status.id ===3 ? i18n.language ==='pt' ? 'Reprovado' : 'Reproved' : ''}
      </span>
    );
  };


  const downloadAnexo = async (args, gb) => {
    const id = tempGuidFile ? tempGuidFile : args.id;
    const idBudget = globalAdBudget.id ? globalAdBudget.id : tempGuid 
    setLoading2(true)
    downloadAttachment(globalState.accessToken, idBudget, id).then((response) => response.blob())
      .then((blob) => {
        console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${args.nomeArquivo}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoading2(false)
      });

    // const blob = await anexo.blob()
    // const url = window.URL.createObjectURL(new Blob([blob]));

  }

  const nomeArquivoTemplate = (args) => {
    return (
      <span
        style={{ cursor: "pointer", color: "#007E7A" }}
        onClick={() => downloadAnexo(args)}>{args.nomeArquivo}</span>
    );
  };




  const editTemplate = (args) => {
    const isAdmin = hasPermissions(32, "Admin", globalState)
    return (
      <div className="editItems">

        {/* editar */}
        {
          (isAdmin || args.status.id ===1) &&
          <span
            style={{ cursor: 'pointer', padding: '10px', marginRight: '5px' }}
            onClick={() => {
              settypeOfForm('EDIT');
              openEdit(args);
            }}
            className="mdl2 mdl2-pencil"
          />
        }


        {/* aprovar */}
        {
          (isAdmin && args.status.id ===1 && !args.tempGuid) &&
          <span
            style={{ cursor: 'pointer', padding: '10px' }}
            onClick={() => {
              initFileApprove(args)
            }}
            className="mdl2 mdl2-approve"
          />
        }

        {/* deletar */}
        {
          (isAdmin || args.status.id ===1) &&
          <span
            style={{ cursor: 'pointer', padding: '10px' }}
            onClick={() => {
              const handleNotOK = () => {
                buildDialogMessage('close')
              }
              const handleOK = (args) => {
                updateItem(args, true)
                buildDialogMessage('close')
              }
              buildDialogMessage('confirmDelete', null, null, () => { handleOK(args) }, handleNotOK, '')
            }}
            className="mdl2 mdl2-trash"
          />
        }

      </div>
    );
  };

  const formatDateColumnTemplate = (args) => {
    return args.criadoEm ? dateFormat(args.criadoEm) : '';
  };
  const formatDateApprovalColumnTemplate = (args) => {
    return args.aprovadoReprovadoEm ? dateFormat(args.aprovadoReprovadoEm) : '';
  };


  const dateFormat = (date) => {
    return <span>{new Date(date).toLocaleString(i18n.language)}</span>;
  };



  const buildDialogMessage = (type, msg, callBack, okCallback, notOkCallback, confirmType) => {
    if (type ==='approval') {
      setmodalMessageParams({
        title: t('ModalMessages:title_approve'),
        message: t('ModalMessages:approved'),
        type: 'success',
        actionType: 'approval',
        approveCallback: okCallback,
        reproveCallback: notOkCallback,
        icon: 'mdl2-confirm',
        show: true,
        exitCallback: callBack
      });
    } else if (type ==='approved') {
      setmodalMessageParams({
        title: t('ModalMessages:title_approve'),
        message: t('ModalMessages:approved'),
        type: 'success',
        icon: 'mdl2-confirm',
        show: true,
        exitCallback: callBack
      });
    } else if (type ==='reproved') {
      setmodalMessageParams({
        title: t('ModalMessages:title_reprove'),
        message: t('ModalMessages:reproved'),
        type: 'error',
        icon: 'mdl2 mdl2-disapprove',
        show: true,
      });
    } else if (type ==='confirm') {
      setmodalMessageParams({
        title: confirmType ==="approve" ? t('ModalMessages:title_approve_confirm') : t('ModalMessages:title_reprove_confirm'),
        type: confirmType ==="approve" ? "success" : 'error',
        actionType: 'confirm',
        confirmOkCallback: okCallback,
        confirmNotOkCallback: notOkCallback,
        icon: confirmType ==="approve" ? 'mdl2 mdl2-approve' : 'mdl2 mdl2-disapprove',
        show: true,
      });
    }
    else if (type ==='error') {
      setmodalMessageParams({
        title: t('ManageInternalAreas:error_title'),
        message: t('ManageInternalAreas:error'),
        type: 'error',
        icon: 'e-icon-dlg-close e-icons',
        show: true,
      });
    }
    else if (type ==='close') {
      setmodalMessageParams({
        show: false,
      });
    } else if (type ==='confirmDelete') {
      setmodalMessageParams({
        title: i18n.language ==="pt" ? 'Deletar item' : 'Delete item',
        type: "error",
        message: i18n.language ==="pt" ? 'Tem certeza que deseja excluir esse item?' : 'Are you sure you want to delete this item?',
        actionType: 'confirm',
        confirmOkCallback: okCallback,
        confirmNotOkCallback: notOkCallback,
        icon: 'mdl2 mdl2-trash',
        show: true,
      });
    }
  };

  // BUSINESS
  const createAttachmentItem = (data) => {
    setLoading(true)
    let newData = {
      id: data.id,
      nomeArquivo: uploadedFile.rawFile.name,
      status: {
        id: 1,
        nome: 'Pendente',
      },
      tipo: {
        id: data.tipo.id,
        nome: options.tipos.filter((i) => i.value ===data.tipo.id)[0].text,
      },
      statusOperacao: 0,
      tempGuid: data.id,
      ativo: true
    };

    let newGridData = [];
    setGridData((prevstate) => {
      prevstate.unshift(newData);
      newGridData = prevstate.filter(item => item.ativo)
      return newGridData;
    });

    setGlobalAdBudget((prevState) => {
      return {
        ...prevState,
        anexos: newGridData,
      }
    });
    gridInstance.current.dataSource = newGridData;
    gridInstance.current.refresh();
    dialogInstance.current.hide();
    setLoading(false)
  }
  const updateItem = (data, isLogicalDelete) => {
    setLoading(true)

    setGlobalAdBudget((prevState) => {
      let anexosArr = [];
      const isIdTemp = isNaN(data.id) ? true : false;
      var itemID = data.id

      if (!isIdTemp && data.id) {
        itemID = parseInt(data.id.toString())
      } else {
        itemID = data.tempGuid
      }

      const qstringIId = getURLParamsByKey('Id')
      const anexoItem = prevState.anexos.filter(i => i.id === itemID || i.tempGuid === itemID)[0]
      const statusOperacao = IDAdBudget || qstringIId ? 1 : anexoItem.statusOperacao
      const nomeTipoMidia = options.tipos.filter((i) => i.value ===data.tipo.id)[0]

      let obj = {
        ...anexoItem,
        statusOperacao: statusOperacao,
        tipo: {
          id: data.tipo.id,
          nome: nomeTipoMidia ? nomeTipoMidia.text : data.tipo.nome,
        },
        id: itemID,
        ativo: isLogicalDelete ? false : anexoItem.ativo,
      };

      anexosArr = prevState.anexos.map((item) => {
        if (item.id ===obj.id) { item = obj }
        return item
      });
      if (!IDAdBudget && !qstringIId && isLogicalDelete) {
        anexosArr = prevState.anexos.filter((item) => {
          return item.id !== obj.id
        });
      }

      anexosArr = addTipoStatusNomeToAllItens(anexosArr, options)
      return {
        ...prevState,
        anexos: anexosArr,
      };
    });

    dialogInstance.current.hide()
    setLoading(false)
  }
  const initFileApprove = (args) => {
    const setNewFileStatus = (data, status) => {
      const anexoItem = gridInstance.current.dataSource.filter(i => i.id === data.id || i.tempGuid === data.id)[0]
      const qstringIId = getURLParamsByKey('Id')
      const statusOperacao = IDAdBudget || qstringIId ? 1 : anexoItem.statusOperacao
      let obj = {
        ...anexoItem,
        status: { ...status },
        statusOperacao: statusOperacao,
      };
      let anexosArr = [];
      setGlobalAdBudget((prevState) => {
        anexosArr = addTipoStatusNomeToAllItens(prevState.anexos)
        anexosArr = anexosArr.map((item) => {
          if (item.id ===obj.id) item = obj
          return item
        });
        return {
          ...prevState,
          anexos: anexosArr,
        };
      });

    }
    const handleNotOK = () => {
      buildDialogMessage('close')
    }

    const onApproveClick = (args) => {
      const handleAprove = (data) => {
        setNewFileStatus(data, { id: 2, nomePt: "Aprovado", nomeEn: "Approved", ativo: true, })
        buildDialogMessage('close')
      }
      buildDialogMessage('confirm', null, null, () => { handleAprove(args) }, handleNotOK, "approve")
    }

    const onReproveClick = (args) => {
      const handleReprove = (data) => {
        setNewFileStatus(data, { id: 3, nomePt: "Reprovado", nomeEn: "Reproved", ativo: true, })
        buildDialogMessage('close')
      }
      buildDialogMessage('confirm', null, null, () => { handleReprove(args) }, handleNotOK, "reprove")
    }
    buildDialogMessage('approval', null, null, () => { onApproveClick(args) }, () => { onReproveClick(args) })
  }

  // FORM
  const onSubmit = (data) => {
    if (typeOfForm ==='NEW') createAttachmentItem(data);
    else if (typeOfForm ==='EDIT') updateItem(data);
    reset();
  };
  const onError = (errors, e) => (errors, e);

  const target = document.getElementById('uploadAttachment');
  const asyncSettings = {
    saveUrl: `${process.env.REACT_APP_API_URL}/_api/BrandingAdvertising/AdvertisingBudget/UploadAttachmentChunks?IdOrGuidAdvertisingBudget=${IDAdBudget ? IDAdBudget : tempGuid}&IdOrGuidAdvertisingBudgetAttachment=${tempGuidFile}`,
    removeUrl: `${process.env.REACT_APP_API_URL}/_api/BrandingAdvertising/AdvertisingBudget/RemoveVideoAttachment?IdOrGuidAdvertisingBudget=${IDAdBudget ? IDAdBudget : tempGuid}&IdOrGuidAdvertisingBudgetAttachment=${tempGuidFile}`,
    chunkSize: uploadChunkSize,
  };
  const whenFileSelected = (e) => {
    let chunkSize = e.fileInfo.size / 10;
    setUploadChunkSize((uploadChunkSize) => (uploadChunkSize = chunkSize));
  };
  const uploadObj = React.useRef(null);
  let isInteraction;
  const uploadIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="61.1" height="62.1" viewBox="0 0 61.1 62.1"><defs><style>.a{fill:#dbdbdb;}.b{fill:#007e7a;}</style></defs><g transform="translate(-690 -235)"><path class="a" d="M715.8,285.9H704.7V297H701V285.9H690v-3.7h11.1V271.1h3.7v11.1h11.1v3.7ZM741.6,238a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H712.2v-3.7H738V279.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V241.7H704.9v22.1h-3.7V238Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V279.5Z"/><path class="b" d="M717.8,282.9H706.7V294H703V282.9H692v-3.7h11.1V268.1h3.7v11.1h11.1v3.7ZM743.6,235a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H714.2v-3.7H740V276.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V238.7H706.9v22.1h-3.7V235Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V276.5Z"/></g></svg>';

  const uploadImage = () => {
    document
      .getElementsByClassName('e-file-select-wrap')[0]
      .querySelector('button')
      .click();
    return false;
  };
  const onRemoveFile = (args) => {
    setValue('anexo', null);
    setUploadedFile(null);
    setError('anexo');
    args.postRawFile = false;
    if (args && args.currentRequest) {
      args.currentRequest.setRequestHeader(
        'Authorization',
        'Bearer ' + globalState.accessToken,
      );
    }
  };
  const onPausing = (args) => {
    if (args.event !== null && !navigator.onLine) {
      isInteraction = true;
    } else {
      isInteraction = false;
    }
  };
  const fileUploading = (e) => {
    e.currentRequest.setRequestHeader(
      'Authorization',
      'Bearer ' + globalState.accessToken,
    );
    if (blockIdList.length > 0)
      e.currentRequest.setRequestHeader('blockIdList', blockIdList.join(','));
  };
  const onBeforeFailure = (args) => {
    args.cancel = isInteraction;
  };

  const chunkUploaded = (e) => {
    setBlockIdList((blockIdList) => [
      ...blockIdList,
      e.event.currentTarget.response,
    ]);
  };
  const chunkComplete = async (args) => {
    if (args.operation ==='upload' && args.name ==='success') {
      setUploadedFile(args.file);
      setValue('anexo', args.file);
      clearError('anexo')
    }
  };
  const onResuming = (args) => {
    if (args.event !== null && !navigator.onLine) {
      isInteraction = true;
    } else {
      isInteraction = false;
    }
  };
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    allowEditOnDblClick: false,
    mode: 'Dialog',
  };
  return (
    <div id="AdvertisingFileDiv">
      <div className="topContent caixa">
        <div className="flex ">
          <span className="mdl2 mdl2-attachments" />
          <h2>{i18n.language ==='pt' ? 'Anexos' : 'Attachments'}</h2>
        </div>
        <div className="divider"></div>
        <div className="flex">
          <button
            id='btnNew'
            onClick={() => {
              settypeOfForm('NEW');
              openEdit();
            }}>
            <span className="pr-10 mdl2 mdl2-plus" />
            {i18n.language ==='pt' ? 'Novo Anexo' : 'New Attachment'}
          </button>
        </div>
      </div>

      {/* FORM FILE */}
      <DialogComponent
        id="modalEditAnnual"
        isModal={true}
        visible={openModal}
        ref={dialogInstance}
        close={clearModal}
        showCloseIcon={true}
        locale={i18n.language}
      >
        <form onSubmit={handleSubmit(onSubmit, onError)} id="anexos-form">
          {/* ID */}
          <div className="dropDown">
            <div className="input">
              <label htmlFor="id2">ID</label>
              <div className="e-float-input">
                <TextBoxComponent
                  id="id2"
                  name="id"
                  enabled={false}
                  ref={register({
                    required: true,
                  })}
                  type="text"
                  value={tempGuidFile}
                />
              </div>
            </div>
          </div>

          {/* Tipo */}
          <div className="dropDown">
            <div className="input">
              <label htmlFor="ativo">
                {i18n.language ==='pt' ? 'Tipo' : 'Type'}
                <label class="e-error">{' '}*</label>
              </label>
              <div className="e-float-input">
                <DropDownListComponent
                  id="tipo"
                  name="tipo.id"
                  ref={register({
                    required: true,
                  })}
                  dataSource={options.tipos}
                  fields={{ text: 'text', value: 'value' }}
                  locale={i18n.language}
                  placeholder={
                    i18n.language ==='pt' ? 'Selecione o tipo' : 'Select type'
                  }
                  htmlAttributes={{ tabindex: "0" }}
                />
                {errors.tipo && (
                  <p className="error">{t('Validation:required')}</p>
                )}
              </div>
            </div>
          </div>

          {/* uploadAttachment */}
          {typeOfForm ==='NEW' &&
            <div className="input">
              <label htmlFor="upload">
                {i18n.language ==='pt' ? 'Arquivo' : 'File'}
                <label class="e-error">{' '}*</label>
              </label>
              <div className="e-float-input">
                <div className="upload" id="uploadAttachment">
                  <div className="new">
                    <span dangerouslySetInnerHTML={{ __html: uploadIcon }} />
                    {i18n.language ==='pt' ? (
                      <p>
                        Arraste o arquivo aqui ou faça o
                        <span onClick={() => uploadImage()}> carregamento</span>
                      </p>
                    ) : (
                      <p>
                        Drag the file here or
                        <span onClick={() => uploadImage()}> upload</span>
                      </p>
                    )}
                  </div>
                </div>
                <input
                  type="text"
                  ref={register({
                    required: true,
                  })}

                  name={'anexo'}
                  style={{ display: 'none' }}
                />
                <UploaderComponent
                  id="upload"
                  name="upload"
                  type="file"
                  asyncSettings={asyncSettings}
                  dropArea={target}
                  htmlAttributes={{ tabindex: '0' }}
                  maxFileSize={1000000000}
                  locale={i18n.language}
                  autoUpload={true}
                  removing={onRemoveFile}
                  pausing={onPausing}
                  uploading={fileUploading}
                  chunkUploading={fileUploading}
                  chunkFailure={onBeforeFailure}
                  fileListRendering={whenFileSelected}
                  chunkSuccess={chunkUploaded}
                  success={chunkComplete}
                  resuming={onResuming}
                  multiple={false}
                />
              </div>
              {errors.anexo && (
                <p className="error">{t('Validation:required')}</p>
              )}
            </div>
          }

          <div className="btn-wrapper">
            <button
              type="submit"
              disabled={loading}
              form="anexos-form"
              className={'e-control e-btn e-lib btn-modal'}
            >
              {t('Actions:save')}
            </button>
          </div>
        </form >
        {loading && <div style={{ zIndex: '99999' }} id="loading" />}
      </DialogComponent >

      {globalAdBudget &&
        <GridComponent
          dataSource={gridData}
          contextMenuItems={contextMenuItems}
          filterSettings={{ type: 'CheckBox' }}
          allowPaging={true}
          allowSorting={true}
          allowFiltering={true}
          allowResizing={true}
          pageSettings={{
            pageSize: 10,
            pageSizes: true,
          }}
          ref={gridInstance}
          locale={i18n.language}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="ID"
              isPrimaryKey={true}
              field="id"
              width="80"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('FolderContent:column_file')}
              field="nomeArquivo"
              template={nomeArquivoTemplate}
              width="250"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Status"
              template={(args) => statusTemplate(args)}
              field="status.nome"
              width="170"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('FolderContent:column_type')}
              field="tipo.nome"
              width="100"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('FolderContent:column_approvedBy')}
              field="aprovadoReprovadoPor.nome"
              width="150"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('FolderContent:column_approvedDate')}
              field="aprovadoReprovadoEm"
              width="190"
              type={'date'}
              filter={{ type: 'Menu' }}
              template={formatDateApprovalColumnTemplate}
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('FolderContent:column_createdBy')}
              field="criadoPor.nome"
              width="130"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('FolderContent:column_createdDate')}
              field="criadoEm"
              width="190"
              type={'date'}
              filter={{ type: 'Menu' }}
              template={formatDateColumnTemplate}
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('ManageInternalAreas:edit')}
              template={(args) => editTemplate(args)}
              width="120"
            />
          </ColumnsDirective>
          <Inject
            services={[Resize, Sort, ContextMenu, Filter, Page, Edit]}
          />
        </GridComponent>
      }
      {loading2 && <div style={{ zIndex: '99999' }} id="loading2" />}
      {/* MODAL APROVAÇÃO */}
      { modalMessageParams.show && <GenericModal params={modalMessageParams} />}



    </div >
  );
};
