import * as React from "react";

import { Redirect } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from "../general/GlobalState";
import { Actions } from "../utilities/Actions";
import {
  TextBoxComponent,
  MaskedTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Container, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { getRegional, getSocialMedias } from "../../services/SystemSVC";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { hasPermissions } from "../utilities/Utils";
import { getSignatureItem, addOrUpdateSignatureItem, getSignaturePhoto, deleteSignature } from "../../services/SignatureSVC";
import ModalSignature from './ModalSignature';
import "./signature.css";

export const SignatureItem = (props) => {
  let itemId = props.match.params.id;
  const { t } = useTranslation();

  const [globalState] = React.useContext(GlobalStateContext);
  const [redirect, setRedirect] = React.useState(false);
  const [dialogDelete, setDialogDelete] = React.useState(false);
  const [fillRegionalData, setFillRegionalData] = React.useState([]);
  const [showDialog, setShowDialog] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(-1);
  const [changeInfo, setChangeInfo] = React.useState({
    text: i18n.language === "pt" ? "Português" : "Portuguese",
    value: 1
  });
  const [socialMediaSelected, setSocialMediaSelected] = React.useState([{
    exists: false,
    new: false
  }]);
  const [socialMedias, setSocialMedias] = React.useState([]);
  const[disabledBtn, setDisabledBtn] = React.useState(false);
  const [signatureForm, setSignatureForm] = React.useState(
    {
      tituloPT: "",
      tituloEN: "",
      urlpt: "",
      urlen: "",
      idRegional: "",
      colaboradores: [
        {
          telefone: "",
          celular: "",
          nome: "",
          email: "",
          status: 0
        }
      ]
    }
  );

  const { register, triggerValidation, errors, clearError } = useForm();

  let _controls = {
    customPermissions: itemId ? hasPermissions(64, "Edit", globalState) : hasPermissions(64, "Create", globalState),
    savePrimary: false,
    excludePrimary: true,
    // urlView: () => setShowDialog(true),
    urlPreview: false,
    urlSave: (e) => {
      e.preventDefault();
      setDisabledBtn(true);
      const newSocialMediaSelected = [...socialMediaSelected];

      const payload = newSocialMediaSelected.map(social => {
        return {
          ...social,
          iconeB64: social.new ? social?.iconeB64.split(',')[1] : ""
        }
      });

      addOrUpdateSignatureItem(globalState.accessToken, {
        ...signatureForm,
        redesSociais: [...payload]
      }).then(response => {
        setRedirect(response.ok)
        setDisabledBtn(false);
      }).catch(() => {
        setDisabledBtn(false);
      })
    },
    // previewSignature: true,
    form: "formSignatureItem",
    // urlSend: itemId ? () => setShowSendContent(true) : false,
    urlCancel: () => {
      setRedirect(true);
    },
    urlDelete: itemId ? () => setDialogDelete(true) : false,
  }

  const releaseIdioma = [{
    text: i18n.language === "pt" ? "Português" : "Portuguese",
    value: 1
  }, {
    text: i18n.language === "pt" ? "Inglês" : "English",
    value: 2
  }]

  const confirmDelete = () => {
    deleteSignature(globalState.accessToken, itemId).then(response => {
      if (response.ok) {
        setRedirect(true);
      }
    });
  }

  React.useEffect(() => {
    async function load() {
      _controls = {
        customPermissions: itemId ? hasPermissions(64, "Edit", globalState) : hasPermissions(64, "Create", globalState),
        savePrimary: false,
        excludePrimary: true,
        // urlView: () => setShowDialog(true),
        urlPreview: false,
        urlSave: (e) => {
          e.preventDefault();
          setDisabledBtn(true);
          addOrUpdateSignatureItem(globalState.accessToken, {
            ...signatureForm,
            redesSociais: [...socialMediaSelected]
          }).then(response => {
            setRedirect(response.ok)
            setDisabledBtn(false);
          }).catch(() => {
            setDisabledBtn(false);
          });
        },
        // previewSignature: true,
        form: "formSignatureItem",
        // urlSend: itemId ? () => setShowSendContent(true) : false,
        urlCancel: () => {
          setRedirect(true);
        },
        urlDelete: itemId ? () => setDialogDelete(true) : false,
      };
    }
    load();
  }, [signatureForm, socialMediaSelected])

  React.useEffect(() => {
    async function load() {
      let _regional = await getRegional(globalState.accessToken).then(
        (response) => {
          if (response.ok) {
            return response.json();
          }
        }
      );
      setFillRegionalData(_regional);
      let socialMedia = await getSocialMedias(globalState.accessToken).then(
        (response) => {
          if (response.ok) {
            return response.json();
          }
        }
      );
      setSocialMedias(socialMedia);
      if (itemId) {
        let signatures = await getSignatureItem(globalState.accessToken, itemId).then(res => {
          if (res.ok) {
            return res.json();
          }
        });

        setSocialMediaSelected(signatures?.redesSociais)
        setSignatureForm(signatures);
      }
    }

    load();
  }, [i18n.language]);

  React.useEffect(() => {
    if (signatureForm && signatureForm.email) {
      clearError(["urlpt", "urlen", "tituloPT", "tituloEN", "email"]);
    }
  }, [signatureForm]);

  const setContent = (e) => {
    const newItem = e.itemData;
    setChangeInfo({
      ...newItem
    });
  }

  const addSocialMedia = () => {
    setSocialMediaSelected(socialSelected => ([
      ...socialSelected,
      {
        exists: false,
        new: false,
      }
    ]))
  }

  const addSignature = () => {
    const signature = signatureForm.colaboradores.filter(signature => signature.status !== 2);

    if (signature) {
      setSignatureForm(signatureForm => ({
        ...signatureForm,
        colaboradores: [...signatureForm.colaboradores,
        {
          telefone: "",
          celular: "",
          nome: "",
          email: "",
          status: 0
        }
        ]
      }))
    }
  }

  const moveDown = (idx) => {
    const arr = socialMediaSelected;
    const newArr = [...arr.slice(0, idx), arr[idx + 1], arr[idx], ...arr.slice(idx+2)];
    setSocialMediaSelected(newArr);
  }
  const moveUp = (idx) => {
    const arr = socialMediaSelected;
    const newArr = [...arr.slice(0, idx-1), arr[idx], arr[idx - 1], ...arr.slice(idx+1)];
    setSocialMediaSelected(newArr);
  }

  const selectNew = (index) => {
    const selected = [...socialMediaSelected];

    selected[index].new = true;

    setSocialMediaSelected(selected);
  };

  const selectExistent = (index) => {

    const selected = [...socialMediaSelected];

    selected[index].exists = true;

    setSocialMediaSelected(selected);
  };

  let socialMediaChange = (field, e, index) => {
    const value = e.value;

    const selected = [...socialMediaSelected];

    selected[index] = {
      ...selected[index],
      [field]: value
    }

    setSocialMediaSelected(selected);
  };

  let inputChange = (field, e) => {
    const value = e.value;

    setSignatureForm((signatureForm) => ({
      ...signatureForm,
      [field]: value,
    }));
  };

  let inputUserChange = (field, e, index) => {
    const value = e.value;
    const newSignatureForm = { ...signatureForm };
    const collaborator = newSignatureForm.colaboradores[index];

    if (collaborator.status !== 2) {
      newSignatureForm.colaboradores[index][field] = value;
    }

    if (collaborator.status === 3) {
      newSignatureForm.colaboradores[index].status = 1;
    }

    setSignatureForm(newSignatureForm);
  };

  const removeSignature = (index) => {
    const newSignatureForm = { ...signatureForm };
    const collaborator = newSignatureForm.colaboradores;

    if (collaborator[index] !==null) {
      if (collaborator[index].status !== 0) {
        collaborator[index].status = 2;
      } else {
        collaborator.splice(index, 1);
      }
    }

    newSignatureForm.colaboradores = collaborator;

    setSignatureForm(newSignatureForm);
  }

  const deleteSocialMedia = (index) => {
    const newSelected = [...socialMediaSelected].filter((mediaSelected, indexMap) => index !== indexMap);

    setSocialMediaSelected(newSelected);
  }

  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const selectSocialMedia = async (e, index) => {
    const selected = [...socialMediaSelected];

    const socialMediaSelect = [...socialMedias];

    const [fields] = socialMediaSelect.filter(x => x.id ===e.value);

    selected[index] = {
      ...selected[index],
      ...fields
    }

    setSocialMediaSelected(selected);

  };

  const removeExists = (index) => {
    const selected = [...socialMediaSelected];

    selected[index].exists = false;

    setSocialMediaSelected(selected);
  };

  const removeNew = (index) => {
    const selected = [...socialMediaSelected];

    selected[index].new = false;

    setSocialMediaSelected(selected);
  };

  const richTextDefault = () => {
    const isLangPT = changeInfo.text === "Português" || changeInfo.text === "Portuguese";
    const html = (
      `<table border="0" cellspacing="0" cellpadding="0" style="width: 450pt">
        <tbody>
          <tr>
            <td style="padding: 0">
              <p style="
                font-size: 11pt;
                font-family: Calibri, sans-serif;
                margin-right: 0;
                margin-left: 0;"
              >
                <span style="
                  color: #747678;
                  font-size: 10pt;
                  font-family: Arial, sans-serif;"
                >
                  ${isLangPT ? signatureForm.tituloPT
                    : signatureForm.tituloEN}
                  <br />
                  ${signatureForm.colaboradores.filter(i => i.nome || i.telefone || i.email || i.celular).map((colaborador) => (`
                    <span>
                      ${(colaborador.nome && colaborador.nome)}
                    </span>
                    <span>
                      ${(colaborador.telefone && (colaborador.nome && '- Tel: ') + colaborador.telefone)}
                    </span>
                    <span>
                      ${(colaborador.celular && ((colaborador.telefone && '/ Cel: ') || (colaborador.nome && '- Cel: ')) + colaborador.celular)}
                    </span>
                    ${(colaborador.nome || colaborador.telefone || colaborador.celular) && colaborador.email && `<br />`}
                    <a href="mailto:${colaborador.email}" target="_blank">
                      ${(colaborador.email && colaborador.email)}
                    </a>`)
                  ).join("<br/>")}
                  <a href="" target="_blank">
                    <span style="color: #999999">
                      <br/>
                      ${isLangPT ? signatureForm.urlpt && signatureForm.urlpt
                        : signatureForm.urlen ? signatureForm.urlen
                        : ''
                      }
                    </span>
                  </a>
                  <br />
                  <br />
                </span>
                ${socialMediaSelected.filter(socialMediaSelect => isLangPT ? socialMediaSelect.url : socialMediaSelect.urlen)
                  .map(socialMediaSelect => (`
                  <span style="
                    color: #747678;
                    font-size: 10pt;
                    font-family: Arial, sans-serif;
                    margin-bottom: 5px;
                    display: inline-block;"
                  >
                    ${socialMediaSelect.id ?
                      `<img src="${process.env.REACT_APP_AZURE_STORAGE}/press/socialmedias/${socialMediaSelect.id}/socialMedia.png"
                        border="0"
                        id="x__x0000_i1051"
                        style="width: 24px; height: 24px" />`
                      : `${socialMediaSelect?.iconeB64 &&
                        `<img
                          src="${socialMediaSelect?.iconeB64}"
                          border="0"
                          id="x__x0000_i1051"
                          style="width: 24px; height: 24px" />`
                    }
                  </span>`}
                </span>
                <span style="color: #747678; font-size: 10pt; font-family: Arial, sans-serif;vertical-align: middle;">&nbsp;&nbsp;
                  <a href=""
                    target="_blank"
                  >
                    <span style="color: #999999">${isLangPT ? socialMediaSelect.url : socialMediaSelect.urlen}</span>
                  </a>
                </span>`)).join("<br/>")}
              </p>
            </td>
          </tr>
          <tr style="height: 7.5pt">
            <td style="height: 7.5pt; padding: 0"></td>
          </tr>
        </tbody>
      </table>`
    );

    return html;
  }

  return (
    <div>
      {globalState.accessToken === null ? (
        <Redirect push={true} to="/login" />
      ) : redirect ? (
        <Redirect push={true} to="/admin/signature" />
      ) : (
        <div>
          <PermissionRedirect id="64" />

          <div className="DefaultTop" />
          <Container className="containerTop">
            <div className="topTitleButtons">
              <Row>
                <div className="col-12">
                  <div className="title">
                    <h1>
                      {itemId
                        ? t("Signature:edit_title")
                        : t("Signature:create_title")}
                    </h1>
                  </div>
                </div>
              </Row>
            </div>
            <Row>
              <div className="col-lg-9 col-12">
                <div className="textBox">
                  <form id="formSignatureItem">
                    <div className="top">
                      <div className="lang">
                        <DropDownListComponent
                          id="idioma"
                          name="idioma"
                          cssClass="e-filled"
                          dataSource={releaseIdioma}
                          fields={{ text: "text", value: "value" }}
                          locale={i18n.language}
                          htmlAttributes={{ required: true }}
                          placeholder={t('Press:language')}
                          value={changeInfo.value}
                          change={(e) => setContent(e)}
                        />
                      </div>
                    </div>
                    <div className="box">
                      <div className="line x2">
                        <div className="input">
                          <label>
                            {t("Signature:title")}*
                          </label>
                          <TextBoxComponent
                            id="title"
                            name="title"
                            placeholder={t(
                              "Signature:title_placeholder"
                            )}
                            htmlAttributes={{
                              maxlength: 255,
                            }}
                            value={
                              changeInfo.text === "Português" || changeInfo.text === "Portuguese"
                                ? signatureForm.tituloPT
                                : signatureForm.tituloEN
                            }
                            change={(value) =>
                              inputChange(
                                changeInfo.text === "Português" || changeInfo.text === "Portuguese"
                                  ? "tituloPT"
                                  : "tituloEN"
                                ,
                                value
                              )
                            }
                            ref={register({
                              required: true,
                            })}
                            blur={() =>
                              triggerValidation(
                                (changeInfo.text === "Português" || changeInfo.text === "Portuguese"
                                  ? "tituloPT"
                                  : "tituloEN"
                                )
                              )
                            }
                          />
                          {errors.tituloPT && (
                            <>
                              {errors.tituloPT
                                .type ===
                                "required" && (
                                  <label
                                    className="e-error"
                                    style={{
                                      display:
                                        "block",
                                    }}
                                  >
                                    {t(
                                      "Validation:required"
                                    )}
                                  </label>
                                )}
                            </>
                          )}
                          {errors.tituloEN && (
                            <>
                              {errors.tituloEN
                                .type ===
                                "required" && (
                                  <label
                                    className="e-error"
                                    style={{
                                      display:
                                        "block",
                                    }}
                                  >
                                    {t(
                                      "Validation:required"
                                    )}
                                  </label>
                                )}
                            </>
                          )}
                        </div>
                        <div className="input">
                          <label>
                            {t("Signature:url")}*
                          </label>
                          <TextBoxComponent
                            id="url"
                            name="url"
                            htmlAttributes={{
                              maxlength: "100",
                            }}
                            cssClass="e-flat"
                            placeholder={t(
                              "Signature:url_placeholder"
                            )}
                            value={
                              changeInfo.text === "Português" || changeInfo.text === "Portuguese"
                                ? signatureForm.urlpt
                                : signatureForm.urlen
                            }
                            change={(value) =>
                              inputChange(
                                changeInfo.text === "Português" || changeInfo.text === "Portuguese"
                                  ? "urlpt"
                                  : "urlen",
                                value
                              )
                            }
                            ref={register({
                              required: true,
                            })}
                            blur={() =>
                              triggerValidation(
                                changeInfo.text === "Português" || changeInfo.text === "Portuguese"
                                  ? "urlpt"
                                  : "urlen",
                              )
                            }
                          />
                          {errors.url && (
                            <>
                              {errors.url.type ===
                                "required" && (
                                  <label
                                    className="e-error"
                                    style={{
                                      display:
                                        "block",
                                    }}
                                  >
                                    {t(
                                      "Validation:required"
                                    )}
                                  </label>
                                )}
                            </>
                          )}
                          {errors.urlen && (
                            <>
                              {errors.urlen.type ===
                                "required" && (
                                  <label
                                    className="e-error"
                                    style={{
                                      display:
                                        "block",
                                    }}
                                  >
                                    {t(
                                      "Validation:required"
                                    )}
                                  </label>
                                )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="input">
                        <label>
                          {t("Signature:regional")}*
                        </label>
                        <DropDownListComponent
                          id="regional"
                          name="regional"
                          cssClass="e-flat"
                          placeholder={t(
                            "Signature:regional_placeholder"
                          )}
                          dataSource={
                            fillRegionalData
                          }
                          showClearButton
                          fields={{
                            text:
                              i18n.language === "pt" || changeInfo.text === "Português" || changeInfo.text === "Portuguese"
                                ? "nomePt"
                                : "nomeEn",
                            value: "id",
                          }}
                          value={
                            signatureForm?.idregional
                          }
                          locale={i18n.language}
                          change={(value) =>
                            inputChange(
                              "idRegional",
                              value
                            )
                          }
                          ref={register({
                            required: true,
                          })}
                          blur={() =>
                            triggerValidation(
                              "idRegional"
                            )
                          }
                        />
                        {errors.idRegional && (
                          <>
                            {errors.idRegional
                              .type ===
                              "required" && (
                                <label
                                  className="e-error"
                                  style={{
                                    display:
                                      "block",
                                  }}
                                >
                                  {t(
                                    "Validation:required"
                                  )}
                                </label>
                              )}
                          </>
                        )}
                      </div>
                      <div className="input">
                        {signatureForm.colaboradores.filter(
                          (signature) => signature.status !== 2
                        ) && (
                            <div className="addLine-plus">
                              <div
                                className="mdl2 mdl2-plus"
                                onClick={() =>
                                  addSignature()
                                }
                              />
                            </div>
                          )}
                        {signatureForm.colaboradores.length > 0 &&
                          signatureForm.colaboradores.map(
                            (item, index) =>
                              item.status !== 2 && (
                                <>  <div className="clear-float">
                                  <div className="line x2">
                                    <div className="input">
                                      <label>
                                        {t(
                                          "Signature:name"
                                        )}
                                      </label>
                                      <TextBoxComponent
                                        id="name"
                                        name="name"
                                        placeholder={t(
                                          "Signature:name_placeholder"
                                        )}
                                        htmlAttributes={{
                                          maxlength: 255,
                                        }}
                                        change={(
                                          value
                                        ) =>
                                          inputUserChange(
                                            "nome",
                                            value,
                                            index
                                          )
                                        }
                                        value={
                                          item.nome
                                        }
                                      />
                                    </div>
                                    <div className="input">
                                      <label>
                                        {t("Signature:email")}
                                      </label>
                                      <TextBoxComponent
                                        id="email"
                                        name="email"
                                        htmlAttributes={{
                                          maxlength: "100",
                                        }}
                                        cssClass="e-flat"
                                        placeholder={t(
                                          "Signature:email_placeholder"
                                        )}
                                        ref={register({
                                          required: true,
                                          pattern: /^\S+@\S+$/,
                                        })}
                                        blur={() =>
                                          triggerValidation(
                                            "email"
                                          )
                                        }
                                        change={(value) =>
                                          inputUserChange(
                                            "email",
                                            value,
                                            index
                                          )
                                        }
                                        value={item.email}
                                      />
                                      {errors.email && (
                                        <>
                                          {errors.email.type ===
                                            "pattern" && (
                                              <label
                                                className="e-error"
                                                style={{
                                                  display:
                                                    "block",
                                                }}
                                              >
                                                {t(
                                                  "Validation:invalidMail"
                                                )}
                                              </label>
                                            )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="line x2">
                                    <div className="input">
                                      <label>
                                        {t("Signature:phone")}
                                      </label>
                                      <MaskedTextBoxComponent
                                        id="phone"
                                        name="phone"
                                        htmlAttributes={{
                                          maxlength: "15",
                                        }}
                                        placeholder={t(
                                          "Signature:phone_placeholder"
                                        )}
                                        value={
                                          item.telefone
                                        }
                                        change={(value) =>
                                          inputUserChange(
                                            "telefone",
                                            value,
                                            index
                                          )
                                        }
                                        promptChar=" "
                                        mask="[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]"
                                      />
                                    </div>
                                    <div className="input">
                                      <label>
                                        {t("Signature:cellPhone")}
                                      </label>
                                      <MaskedTextBoxComponent
                                        id="cellPhone"
                                        name="cellPhone"
                                        htmlAttributes={{
                                          maxlength: "15",
                                        }}
                                        placeholder={t(
                                          "Signature:cellPhone_placeholder"
                                        )}
                                        value={
                                          item.celular
                                        }
                                        change={(value) =>
                                          inputUserChange(
                                            "celular",
                                            value,
                                            index
                                          )
                                        }
                                        promptChar=" "
                                        mask="[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]"
                                      />
                                    </div>
                                  </div>
                                </div>
                                  {signatureForm.colaboradores.length > 1 &&
                                    <div className="addLine-minus">
                                      <div className="mdl2 mdl2-minus"
                                        onClick={() => removeSignature(index)}
                                      />
                                    </div>
                                  }
                                </>
                              )
                          )}
                      </div>

                      <div className="addLine-plus" style={{
                        width: "100%",
                        height: "30px"
                      }}>
                        <div
                          className="mdl2 mdl2-plus"
                          onClick={() =>
                            addSocialMedia()
                          }
                        />
                      </div>
                      <div className="input" style={{
                        display: "flex",
                        gap: "20px",
                        flexDirection: "column"
                      }}>
                        {socialMediaSelected.length > 0 &&
                          socialMediaSelected.map((socialMediaSelect, index) => (
                            <div style={{
                              justifyContent: "space-between",
                              display: "flex",
                              alignItems: "center"
                            }}>
                              <div style={{
                                flex: "1",
                                padding: "0 10px 0 0",
                                boxSizing: "border-box"
                              }}>
                                {(!socialMediaSelect.new && !socialMediaSelect.exists) && !socialMediaSelect.id ? (
                                  <div className="buttonsStyle">
                                    <button className="e-control e-btn e-lib e-primary new" onClick={() => selectExistent(index)}>{t("Signature:buttonSelect")}</button>
                                    <button className="e-control e-btn e-lib e-outline export" onClick={() => selectNew(index)}>{t("Signature:addNew")}</button>
                                  </div>
                                ) : (
                                  <div>
                                    {socialMediaSelect.exists && !socialMediaSelect.id && (
                                      <>
                                        <div>
                                          <DropDownListComponent
                                            id="socialMedia"
                                            name="socialMedia.id"
                                            dataSource={socialMedias}
                                            fields={{ text: 'titulo', value: 'id' }}
                                            locale={i18n.language}
                                            change={(e) => selectSocialMedia(e, index)}
                                            value={socialMediaSelected[index].id}
                                            placeholder={
                                              i18n.language === 'pt'
                                                ? 'Selecione a rede social'
                                                : 'Select a social media'
                                            }
                                          />
                                        </div>
                                      </>
                                    )}

                                    {showDialog &&
                                      <ModalSignature
                                        setShowDialog={setShowDialog}
                                        index={currentIndex}
                                        setSocialMediaSelected={setSocialMediaSelected}
                                        socialMediaSelected={socialMediaSelected} />
                                    }

                                    {socialMediaSelect.id && (
                                      <div className="line x2 alignCenter">
                                        <div className="orderButtons">
                                          {index > 0 && <span className="upButton" onClick={() => moveUp(index)} >^</span>}
                                          {index < socialMediaSelected.length - 1 && <span className="downButton" onClick={() => moveDown(index)} >^</span>}
                                        </div>
                                        <img className="icon" src={`${process.env.REACT_APP_AZURE_STORAGE}/press/socialmedias/${socialMediaSelect.id}/socialMedia.png`} />
                                        <div className="input">
                                          <label>{t("Signature:title")}*</label>
                                          <TextBoxComponent
                                            id="title"
                                            name="title"
                                            htmlAttributes={{
                                              maxlength: "100",
                                            }}
                                            cssClass="e-flat"
                                            placeholder={t(
                                              "Signature:email_placeholder"
                                            )}
                                            change={(value) => socialMediaChange("titulo", value, index)}
                                            value={socialMediaSelect.titulo}
                                          />
                                        </div>
                                        <div className="input">

                                          <label>{t("Signature:url")}*</label>
                                          <TextBoxComponent
                                            id="url"
                                            name="url"
                                            htmlAttributes={{
                                              maxlength: "100",
                                            }}
                                            cssClass="e-flat"
                                            placeholder={t(
                                              "Signature:email_placeholder"
                                            )}
                                            change={(value) => socialMediaChange(changeInfo.text === "Português" ? "url" : "urlen", value, index)}
                                            value={changeInfo.text === "Português" || changeInfo.text === "Portuguese"
                                              ? socialMediaSelect.url
                                              : socialMediaSelect.urlen ? socialMediaSelect.urlen : ""}
                                          />
                                        </div>
                                      </div>
                                    )}

                                    {socialMediaSelect.new && (
                                      <div className="line x3 alignCenter" style={{
                                        gap: "10px"
                                      }}>
                                        {!socialMediaSelect?.iconeB64 ?
                                          <a href="" onClick={e => {
                                            e.preventDefault();
                                            setShowDialog(true);
                                            setCurrentIndex(index);
                                          }}>Adicionar ícone</a> :
                                          <img className="icon" src={socialMediaSelect?.iconeB64} />}
                                        <div className="input">
                                          <label>Titulo</label>
                                          <TextBoxComponent
                                            id="title"
                                            name="title"
                                            htmlAttributes={{
                                              maxlength: "100",
                                            }}
                                            cssClass="e-flat"
                                            placeholder={t(
                                              "Signature:title_placeholder"
                                            )}
                                            change={(value) => socialMediaChange("titulo", value, index)}
                                            value={socialMediaSelect.titulo}
                                          />
                                        </div>
                                        <div className="input">

                                          <label>URL</label>
                                          <TextBoxComponent
                                            id="url"
                                            name="url"
                                            htmlAttributes={{
                                              maxlength: "100",
                                            }}
                                            cssClass="e-flat"
                                            placeholder={t(
                                              "Signature:url_placeholder"
                                            )}
                                            change={(value) => socialMediaChange("url", value, index)}
                                            value={changeInfo.text === "Português" || changeInfo.text === "Portuguese"
                                              ? socialMediaSelect.url
                                              : socialMediaSelect.urlen ? socialMediaSelect.urlen : ""}
                                          />
                                        </div>
                                        <div className="input">

                                          <label>URL Inglês</label>
                                          <TextBoxComponent
                                            id="url"
                                            name="url"
                                            htmlAttributes={{
                                              maxlength: "100",
                                            }}
                                            cssClass="e-flat"
                                            placeholder={t(
                                              "Signature:url_english_placeholder"
                                            )}
                                            change={(value) => socialMediaChange("urlen", value, index)}
                                            value={socialMediaSelect.urlen}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className="addLine-minus" style={{
                                margin: "0"
                              }}>
                                <div className="mdl2 mdl2-minus"
                                  onClick={() => deleteSocialMedia(index)}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="boxRichText">
                      <div dangerouslySetInnerHTML={{ __html: richTextDefault() }}></div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-3 col-12">
                <Actions disabled={disabledBtn} controls={_controls} />
              </div>
            </Row>
          </Container>

          <DialogComponent
            id="deleteModal"
            isModal={true}
            visible={dialogDelete}
            locale={i18n.language}
          >
            <div>
              <div className="divTrash">
                <label className="mdl2 mdl2-trash"></label>
                <div className="phraseDelete">
                  <label id="delete">{t('Actions:delete')}</label>
                  <label id="labelDelete">{t('Actions:confirmDeleteMsg')}</label>
                </div>
              </div>
              <hr />
              <div className="footerButtons">
                <button onClick={() => setDialogDelete(false)}>{t('Actions:cancel')}</button>
                <button onClick={() => confirmDelete()}>{i18n.language === "pt" ? "Sim" : "Yes"}</button>
              </div>
            </div>
          </DialogComponent>
        </div>
      )}
    </div>
  );
};
