import React, { useContext } from 'react';
import { useModal } from '../../../hooks/Modal';
import { hasPermissions } from '../Utils';
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from '../../general/GlobalState';

const PermissionRedirect = ({ id, type }) => {
    const [globalState] = useContext(GlobalStateContext)
    const { buildDialogMessage } = useModal();

    if (id) {

        if (!hasPermissions(parseInt(id), type || "", globalState)) {

            buildDialogMessage({
                error: true,
                titlePt: "Você não tem acesso a esse conteúdo",
                titleEn: "You dont have access to this content",
                messagePt: "Se você precisa acessar essa página, fale com o administrador do site",
                messageEn: "If you need to access this page, talk to the site administrator",
                icon: "e-icon-dlg-close e-icons"
            })

            return (
                <Redirect push={true} to="/" />
            );
        }
    }


    return <></>
};

export default PermissionRedirect;
