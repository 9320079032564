import { requestAPI } from '../components/utilities/Utils';

export function getMailingContactPhoto(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      callback(this.response)
    }
  }
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

export async function getCommunicationEmployeesGroupItems(token, qstring = "") {
  return requestAPI("_api/CommunicationEmployeesGroup/GetCommunicationEmployeesGroupItems", "GET", qstring, null, token, false);
}

export async function getCommunicationEmployeesGroupItem(token, id) {
  const qstring = `?communicationEmployeesGroupId=${id}`;
  return requestAPI("_api/CommunicationEmployeesGroup/GetCommunicationEmployeesGroupItem", "GET", qstring, null, token, false);
}

export async function addOrUpdateGroupItem(token, body) {
  const raw = JSON.stringify(body);
  const endPointURL = body.id ? `_api/CommunicationEmployeesGroup/UpdateCommunicationEmployeesGroupItem` : "_api/CommunicationEmployeesGroup/AddCommunicationEmployeesGroupItem"
  const method = body.id ? "PUT" : "POST";
  return requestAPI(endPointURL, method, "", raw, token, false);
}

export async function deleteCommunicationEmployeesGroupItem(token, id) {
  const qstring = `?communicationEmployeesGroupId=${id}`;
  return requestAPI("_api/CommunicationEmployeesGroup/DeleteCommunicationEmployeesGroupItem", "DELETE", qstring, null, token, false);
}

export async function exportCommunicationEmployeesGroup(token, id, language) {
  const qstring = `?communicationEmployeesGroupId=${id}&language=${language}`;
  return requestAPI("_api/CommunicationEmployeesGroup/ExportCommunicationEmployeesGroup", "GET", qstring, null, token, false);
}
