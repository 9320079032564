import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Buttons } from '../utilities/Buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Edit,
  Toolbar,
  Filter,
  Inject,
  Sort,
  ContextMenu,
  Page,
  Resize,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  AggregateColumnDirective,
} from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import { createSpinner, showSpinner, setSpinner, } from '@syncfusion/ej2-react-popups';
import { setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { AdvertisingBudgetContext } from './AdvertisingBudgetContext';
import { uuidv4 } from '../utilities/Utils';
import { getURLParamsByKey } from '../utilities/Utils';
import GenericModal from '../utilities/MessageModal/GenericModal';

export const CostContent = (props) => {
  setCulture('pt');
  setCurrencyCode('BRL');

  const { data, options, IDAdBudget } = props;
  const [globalAdBudget, setGlobalAdBudget] = React.useContext(AdvertisingBudgetContext);
  const { t } = useTranslation();
  const gridInstance = React.useRef(null);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const dialogInstance = React.useRef();
  let sumCostGrid = 0;
  const [gridData, setGridData] = React.useState([]);
  const [loading, setloading] = React.useState(false);
  const [typeOfForm, settypeOfForm] = React.useState('');
  const [errosAPI, seterrosAPI] = React.useState(null);
  const { register, handleSubmit, errors, reset, setValue, clearError } = useForm({
    mode: "onChange",
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
  });
  const contextMenuItems = ['SortAscending', 'SortDescending', 'FirstPage', 'PrevPage', 'LastPage', 'NextPage'];

  const [modalMessageParams, setmodalMessageParams] = React.useState({ show: false });

  // EFFECTS
  React.useEffect(() => {
    sumCostGrid = 0;

  }, [gridData]);

  React.useEffect(() => {
    console.log(IDAdBudget);
  }, [IDAdBudget])

  React.useEffect(() => {
    if (loading) {
      createSpinner({ target: document.getElementById('loading') });
      showSpinner(document.getElementById('loading'));
      setSpinner({ type: 'Bootstrap' });
    }
  }, [loading]);

  React.useEffect(() => {
    if (openEditModal ===false) {
      clearError()
      setValue('id', null);
      setValue('tipoMidia.id', null);
      setValue('custoProducao', null);
      setValue('custoMidia', null);
      setValue('honorarios', null);
      setValue('observacao', null);
    }
  }, [openEditModal])

  React.useEffect(() => {
    gridInstance.current.refresh();
  }, [i18n.language]);


  const addTipoMidiaNomeToAllItens = (custos) => {
    return custos.map((item) => {
      var tipoItem = options.tiposMidia.filter((i) => i.value ===item.tipoMidia.id);
      if (tipoItem[0])
        return {
          ...item,
          tipoMidia: {
            ...item.tipoMidia,
            nomePt: tipoItem[0].text,
          },
        };
      else return item;
    });
  }
  React.useEffect(() => {
    if (globalAdBudget.custos.length > 0 && options.tiposMidia.length > 0) {
      let custos = globalAdBudget.custos;
      custos = addTipoMidiaNomeToAllItens(custos)
      custos = custos.filter(item => item.ativo)
      setGridData(custos)
      gridInstance.current.dataSource = custos;
      gridInstance.current.refresh();
    }
  }, [globalAdBudget, options]);




  // CUSTOM FUNCTIONS
  const buildDialogMessage = (type, msg, callBack, okCallback, notOkCallback, confirmType) => {
    if (type ==='confirm') {
      setmodalMessageParams({
        title: i18n.language ==="pt" ? 'Deletar item' : 'Delete item',
        type: "error",
        message: i18n.language ==="pt" ? 'Tem certeza que deseja excluir esse item?' : 'Are you sure you want to delete this item?',
        actionType: 'confirm',
        confirmOkCallback: okCallback,
        confirmNotOkCallback: notOkCallback,
        icon: 'mdl2 mdl2-trash',
        show: true,
      });
    } else if (type ==='close') {
      setmodalMessageParams({
        show: false,
      });
    }
  };


  // TEMPLATES
  const totalTemplateSum = (args) => {
    const calcularCustoTotal = () => {
      let sumCostGrid = 0
      const custos = globalAdBudget.custos.filter(item => item.ativo)
      custos.map((i) => {
        sumCostGrid += i.custoProducao;
        sumCostGrid += i.custoMidia;
        sumCostGrid += i.honorarios;
      });
      return sumCostGrid
    }
    return (
      <span className="sumClass">
        Total Final: {toBRL(calcularCustoTotal())}
      </span>
    );
  };

  const templateSum = (args) => {
    return (
      <span className="sumClass">
        {i18n.language ==='pt' ? 'Soma' : 'Sum'}: { toBRL(args.Sum)}
      </span>
    );
  };

  const productionCostTemplate = (args) => {
    return <span>${args.custoProducao && toBRL(args.custoProducao)}</span>;
  };

  React.useEffect(() => {
    if (gridInstance.current)
      gridInstance.current.refresh();
  }, [i18n.language]);

  const mediaCostTemplate = (args) => {
    return <span>${args.custoMidia && toBRL(args.custoMidia)}</span>;
  };

  const feesTemplate = (args) => {
    return <span>${args.honorarios && toBRL(args.honorarios)}</span>;
  };

  const editTemplate = (args) => {
    return (
      <div className="editItems">
        <span
          style={{ cursor: 'pointer', padding: '10px', marginRight: '5px' }}
          onClick={() => {
            openEdit(args);
            // edit(args);
          }}
          className="mdl2 mdl2-pencil"
        />
        <span
          style={{ cursor: 'pointer', padding: '10px' }}
          onClick={() => {
            const handleNotOK = () => {
              buildDialogMessage('close')
            }
            const handleOK = (args) => {
              updateItem(args, true)
              buildDialogMessage('close')
            }
            buildDialogMessage('confirm', null, null, () => { handleOK(args) }, handleNotOK, '')

          }}
          className="mdl2 mdl2-trash"
        />
      </div>
    );
  };
  const toBRL = valor => {
    return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  const templateCustoProducao = (data) => {
    return <span>{data.custoProducao && toBRL(data.custoProducao)}</span>;
  };
  const templateCustoMidia = (data) => {
    return <span>{data.custoMidia && toBRL(data.custoMidia)}</span>;
  };

  const templateHonorarios = (data) => {
    return <span>{data.honorarios && toBRL(data.honorarios)}</span>;
  };

  // MODAL DIALOG
  const openEdit = (args) => {
    settypeOfForm('EDIT');
    clearError()
    dialogInstance.current.show();
    setValue('id', args.id);
    setValue('tipoMidia.id', args.tipoMidia.id);
    setValue('custoProducao', args.custoProducao);
    setValue('custoMidia', args.custoMidia);
    setValue('honorarios', args.honorarios);
    setValue('observacao', args.observacao);
  };

  const clearModal = () => {
    setValue('id', null);
    setValue('tipoMidia.id', null);
    setValue('custoProducao', null);
    setValue('custoMidia', null);
    setValue('honorarios', null);
    setValue('observacao', null);
  }



  // BUSINESS
  const createCustItem = (data) => {
    setloading(true)
    const obj = {
      id: uuidv4(),
      tipoMidia: {
        id: data.tipoMidia.id,
        nomePt: options.tiposMidia.filter(
          (i) => i.value ===data.tipoMidia.id,
        )[0].text,
      },
      custoProducao: data.custoProducao,
      custoMidia: data.custoMidia,
      honorarios: data.honorarios ? data.honorarios : 0,
      observacao: data.observacao ? data.observacao : '',
      statusOperacao: 0,
      ativo: true,
    };

    let custosArr = [];
    setGlobalAdBudget((prevState) => {
      custosArr = addTipoMidiaNomeToAllItens(prevState.custos);
      custosArr.unshift(obj);
      return {
        ...prevState,
        custos: custosArr,
      };
    });

    gridInstance.current.dataSource = custosArr.filter(item => item.ativo);
    gridInstance.current.refresh();
    setloading(false)
    dialogInstance.current.hide()
    setOpenEditModal(false)
    gridInstance.current.refresh();
  };
  const updateItem = (data, isLogicalDelete) => {
    setloading(false)
    const tipoMidiaSelectedOption = options.tiposMidia.filter((i) => i.value ===data.tipoMidia.id,)[0]
    const qstringIId = getURLParamsByKey('Id')
    const statusOperacao = IDAdBudget || qstringIId ? 1 : data.statusOperacao

    console.log(statusOperacao);

    const obj = {
      id: isNaN(data.id) ? data.id : parseInt(data.id),
      tipoMidia: {
        id: data.tipoMidia.id,
        nomePt: tipoMidiaSelectedOption ? tipoMidiaSelectedOption.text : data.tipoMidia.nomePt || data.tipoMidia.nomeEn
      },
      custoProducao: data.custoProducao,
      custoMidia: data.custoMidia,
      honorarios: data.honorarios,
      observacao: data.observacao,
      statusOperacao: statusOperacao,
      ativo: isLogicalDelete ? false : true,
    };

    let custosArr = [];
    setGlobalAdBudget((prevState) => {
      custosArr = addTipoMidiaNomeToAllItens(prevState.custos);
      custosArr = custosArr.map((item) => {
        if (item.id ===obj.id) { item = obj; }
        return item;
      });
      return {
        ...prevState,
        custos: custosArr,
      };
    });

    gridInstance.current.dataSource = custosArr.filter(item => item.ativo);
    gridInstance.current.refresh();
    setloading(false)
    dialogInstance.current.hide()
    setOpenEditModal(false)
    gridInstance.current.refresh();
  };



  // FORM
  const onSubmit = (data) => {
    if (typeOfForm ==='NEW') createCustItem(data);
    else if (typeOfForm ==='EDIT') updateItem(data);
    reset();
  };
  const onError = (errors, e) => console.log(errors, e);



  return (
    <div>

      <div className="topContent caixa">
        <div className="flex ">
          <span className="mdl2 mdl2-money" />
          <h2>{i18n.language ==='pt' ? 'Custos' : 'Costs'}</h2>
        </div>
        <div className="divider"></div>
        <div className="flex">
          <button
            id='btnNew'
            onClick={() => {
              settypeOfForm('NEW');
              setOpenEditModal(true)
              dialogInstance.current.show();
            }}>
            <span className="pr-10 mdl2 mdl2-plus" />
            {i18n.language ==='pt' ? 'Novo Custo' : 'New Cost'}
          </button>
        </div>
      </div>

      <DialogComponent
        id="modalEditAnnual"
        isModal={true}
        visible={openEditModal}
        ref={dialogInstance}
        close={clearModal}
        showCloseIcon={true}
        locale={i18n.language}
      >
        <form
          className="newAnnual"
          id="addEditDialog"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          {/* ID */}
          <div className="input" style={{ display: `${typeOfForm ==='EDIT' ? 'block' : 'none'}` }}>
            <label htmlFor="id1">ID</label>
            <div className="e-float-input">
              <TextBoxComponent
                id="id1"
                name="id"
                enabled={false}
                ref={register({
                  required: false,
                })}
                type="text"
              // value={modalData && modalData.id}
              />
              {errors.id && <p className="error">{t('Validation:required')}</p>}
            </div>
          </div>

          <div className={'row'}>
            {/* TIPO DE MÍDIA */}
            <div className="input col-sm-6 col-12">
              <label htmlFor="tipoMidia">
                {i18n.language ==='pt' ? 'Tipo de Mídia' : 'Midia Type'}
                <label class="e-error">{' '}*</label>
              </label>
              <div className="e-float-input">
                <DropDownListComponent
                  id="tipoMidia"
                  name="tipoMidia.id"
                  ref={register({ required: true })}
                  dataSource={options && options.tiposMidia}
                  fields={{ text: 'text', value: 'value' }}
                  locale={i18n.language}
                  // value={modalData && modalData.tipoMidia && modalData.tipoMidia.id}
                  placeholder={
                    i18n.language ==='pt'
                      ? 'Selecione o tipo de mídia'
                      : 'Select midia type'
                  }
                />
                {errors.tipoMidia && (
                  <p className="error">{t('Validation:required')}</p>
                )}
              </div>
            </div>
            {/* Custo de Produção */}
            <div className="input col-sm-6 col-12">
              <label htmlFor="custoProducao">{i18n.language ==='pt' ? 'Custo de Produção' : 'Production cost'}
                <label class="e-error">{' '}*</label>
              </label>
              <div className="e-float-input">
                <NumericTextBoxComponent
                  name="custoProducao"
                  id="custoProducao"
                  format="c2"
                  placeholder={
                    i18n.language ==='pt'
                      ? 'Digite o custo produção (apenas números)'
                      : 'only numbers'
                  }
                  htmlAttributes={{ maxLength: 23 }}
                  // className={errors.custoProducao ? 'error' : ''}
                  // value={modalData && modalData.custoProducao}
                  ref={register({
                    required: true,
                    maxLength: 23,
                    minLength: 2,
                    min: 0,
                  })}
                />
                {errors.custoProducao &&
                  errors.custoProducao.type === 'required' && (
                    <p className="error">{t("Validation:required")}</p>
                  )}
                {errors.custoProducao &&
                  errors.custoProducao.type === 'maxLength' && (
                    <p className="error">{t("Validation:exceeded")}</p>
                  )}
                {errors.custoProducao &&
                  errors.custoProducao.type === 'min' && (
                    <p className="error">{t("Validation:min")}</p>
                  )}
                {errors.custoProducao &&
                  errors.custoProducao.type === 'minLength' && (
                    <p className="error">{t("Validation:exceeded")}</p>
                  )}
                {errosAPI &&
                  errosAPI.errors &&
                  errosAPI.errors.custoProducao && (
                    <p className="error">{errosAPI.errors.custoProducao}</p>
                  )}
              </div>
            </div>
          </div>

          <div className={'row'}>
            {/* Custo de Mídia */}
            <div className="input col-sm-6 col-12">
              <label htmlFor="custoMidia">{i18n.language ==='pt' ? 'Custo de Mídia' : 'Media Cost'}
                <label class="e-error">{' '}*</label>
              </label>
              <div className="e-float-input">
                <NumericTextBoxComponent
                  name="custoMidia"
                  id="custoMidia"
                  format="c2"
                  placeholder={
                    i18n.language ==='pt'
                      ? 'Digite o custo de mídia (apenas números)'
                      : 'only numbers'
                  }
                  htmlAttributes={{ maxLength: 23 }}
                  // value={modalData && modalData.custoMidia}
                  ref={register({
                    required: true,
                    maxLength: 23,
                    minLength: 2,
                    min: 0,
                  })}
                />
                {errors.custoMidia &&
                  errors.custoMidia.type === 'required' && (
                    <p className="error">{t("Validation:required")}</p>
                  )}
                {errors.custoMidia &&
                  errors.custoMidia.type === 'maxLength' && (
                    <p className="error">{t("Validation:exceeded")}</p>
                  )}
                {errors.custoMidia &&
                  errors.custoMidia.type === 'min' && (
                    <p className="error">{t("Validation:min")}</p>
                  )}
                {errors.custoMidia &&
                  errors.custoMidia.type === 'minLength' && (
                    <p className="error">{t("Validation:exceeded")}</p>
                  )}
                {errosAPI && errosAPI.errors && errosAPI.errors.custoMidia && (
                  <p className="error">{errosAPI.errors.custoMidia}</p>
                )}
              </div>
            </div>
            {/* Honorários */}
            <div className="input col-sm-6 col-12">
              <label htmlFor="honorarios1">{i18n.language ==='pt' ? 'Honorários' : 'Fees'}

              </label>
              <div className="e-float-input">
                <NumericTextBoxComponent
                  name="honorarios"
                  id="honorarios1"
                  format="c2"
                  placeholder={
                    i18n.language ==='pt'
                      ? 'Digite o valor (apenas números)'
                      : 'only numbers'
                  }
                  htmlAttributes={{ maxLength: 23 }}
                  ref={register({
                    required: false,
                    maxLength: 23,
                    minLength: 2,
                    min: 0,
                  })}
                />

                {errors.honorarios &&
                  errors.honorarios.type === 'required' && (
                    <p className="error">{t("Validation:required")}</p>
                  )}
                {errors.honorarios &&
                  errors.honorarios.type === 'maxLength' && (
                    <p className="error">{t("Validation:exceeded")}</p>
                  )}
                {errors.honorarios &&
                  errors.honorarios.type === 'min' && (
                    <p className="error">{t("Validation:min")}</p>
                  )}
                {errors.honorarios &&
                  errors.honorarios.type === 'minLength' && (
                    <p className="error">{t("Validation:exceeded")}</p>
                  )}
                {errosAPI && errosAPI.errors && errosAPI.errors.honorarios && (
                  <p className="error">{errosAPI.errors.honorarios}</p>
                )}
              </div>
            </div>
          </div>

          {/* Observação */}
          <div className="input">
            <label htmlFor="saldo">{i18n.language ==='pt' ? 'Observação' : 'Note'}</label>
            <div className="e-float-input">
              <TextBoxComponent
                id="observacao"
                name="observacao"
                enabled={true}
                multiline={true}
                htmlAttributes={{ maxLength: 500 }}
                ref={register({
                  required: false,
                })}
              // value={modalData && modalData.observacao}
              />
              {errors.observacao && (
                <p className="error">{t('Validation:required')}</p>
              )}
            </div>
          </div>

          <div className="btn-wrapper">
            <button
              type="submit"
              form="addEditDialog"
              disabled={loading}
              className={'e-control e-btn e-lib btn-modal'}
            >
              {t('Actions:save')}
            </button>
          </div>
        </form>
        {loading && <div style={{ zIndex: '99999' }} id="loading" />}
      </DialogComponent>

      {data && (
        <GridComponent
          dataSource={gridData}
          contextMenuItems={contextMenuItems}
          filterSettings={{ type: 'CheckBox' }}
          allowPaging={true}
          allowSorting={true}
          allowResizing={true}
          allowFiltering={true}
          locale={i18n.language}
          ref={gridInstance}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="ID"
              field="id"
              width="150" />
            <ColumnDirective
              headerText={t('Contacts:media_type')}
              field="tipoMidia.nomePt"
              width="150"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_production')}
              // template={productionCostTemplate}
              // template={templateCustoProducao}
              format={'C2'}
              field="custoProducao"
              width="150"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('AdvertisingBudget:column_media')}
              // template={mediaCostTemplate}
              format={'C2'}
              field="custoMidia"
              width="150"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('MeasurementReport:column_fees')}
              // template={feesTemplate}
              // template={templateHonorarios}
              format={'C2'}
              field="honorarios"
              width="150"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('FormContainerAdBudget:column_note')}
              field="observacao"
              width="250"
            ></ColumnDirective>
            <ColumnDirective
              headerText={t('ManageInternalAreas:edit')}
              template={(args) => editTemplate(args)}
              width="120"
            />

          </ColumnsDirective>

          <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective
                  field="custoProducao"
                  type="Sum"
                  footerTemplate={templateSum}
                />
                <AggregateColumnDirective
                  field="custoMidia"
                  type="Sum"
                  footerTemplate={templateSum}
                />
                <AggregateColumnDirective
                  field="honorarios"
                  type="Sum"
                  footerTemplate={templateSum}
                />
              </AggregateColumnsDirective>
            </AggregateDirective>

            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective
                  field="observacao"
                  type="Sum"
                  footerTemplate={totalTemplateSum}
                />
              </AggregateColumnsDirective>
            </AggregateDirective>
          </AggregatesDirective>

          <Inject
            services={[
              Resize,
              Sort,
              ContextMenu,
              Filter,
              Page,
              Edit,
              Toolbar,
              Aggregate,
            ]}
          />
        </GridComponent>
      )}
      {/* MODAL EXCLUSÃO */}
      {modalMessageParams.show && <GenericModal params={modalMessageParams} />}

    </div>
  );
};
