import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { Container, Row } from "reactstrap";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from '../general/GlobalState';
import { UserData } from '../profile/UserData';
import { ProfData } from '../profile/ProfData';
import { Actions } from "../utilities/Actions";
import { NewPassword } from '../users/NewPassword';
import { Permissions } from '../users/Permissions';
import { usersFull, addOrUpdateUsersFull, deleteUser } from "../../services/UsersSVC";
import { EventEmitter } from '../utilities/Events'
import { FormValidator } from '@syncfusion/ej2-react-inputs';
import '../profile/profile.css';
import { ProfilePhotoContext } from "../utilities/ProfilePhoto/ProfilePhotoContext";
import { hasPermissions } from "../utilities/Utils";
import PermissionRedirect from '../utilities/PermissionRedirect/PermissionRedirect';
import { createSpinner, DialogComponent, setSpinner, showSpinner } from "@syncfusion/ej2-react-popups";

export const UsersItem = (props) => {
    const emptyUser = {
        ultimoLogin: "",
        adminGlobal: false,
        idTipoUsuario: 2,
        dataNascimento: null,
        nomePaisPT: "",
        nomePaisEN: "",
        nomeRegionalPT: "",
        nomeRegionalEN: "",
        tipoUsuarioPT: "",
        tipoUsuarioEN: "",
        permissions: [],
        nome: "",
        email: "",
        fotoB64: "",
        dataInicioVale: null,
        cargo: "",
        matricula: "",
        enderecoTrabalho: "",
        atividadePrincipal: "",
        cargoGerencia: false,
        funcionarioVale: true,
        idFornecedor: null,
        incluirWhoIsWho: false,
        usuarioTelefone: [],
        idModeloPerfil: null
    };

    let idUser = props.match.params.id;

    const [globalState] = React.useContext(GlobalStateContext);
    const [dataUser, setDataUser] = React.useState(null);
    const [updated, setUpdated] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const [photo, setPhoto] = React.useContext(ProfilePhotoContext);
    const [hasPermissionToEdit, setHasPermissionToEdit] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);

    const [openModalDelete, setOpenModalDelete] = React.useState(false);
    const [openModalConfirm, setOpenModalConfirm] = React.useState(false);
    const [disabledBtn, setDisabledBtn] = React.useState(false);

    const [formObject, setFormObject] = React.useState(null);

    const { t } = useTranslation();

    const _controls = {
        area: "Usuarios",
        class: "profile",
        savePrimary: false,
        area: "Usuários",
        excludePrimary: true,
        urlView: "",
        urlPreview: "",
        urlDuplicate: "",
        urlSave: (e) => changeUser(e),
        urlSend: "",
        customPermissions: idUser ? hasPermissions(51, "Edit", globalState) : hasPermissions(51, "Create", globalState),
        urlCancel: () => setRedirect(true),
        // urlDelete: dataUser && dataUser.userId ? (e) => excludeUser(e, idUser) : null
        urlDelete: dataUser && dataUser.userId ? (e) => {
            e.preventDefault();
            setOpenModalDelete(true)
        } : null
    };

    React.useEffect(() => {
        setHasPermissionToEdit(hasPermissions(51, "Create", globalState) || hasPermissions(51, "Edit", globalState));
    }, []);

    React.useEffect(() => {
        let formValidator = {
            rules: {
                'name': {
                    required: [true, t('Validation:required')]
                },
                'email': {
                    required: [true, t('Validation:required')],
                    email: [true, t('Validation:invalidMail')]
                },
                'country': {
                    required: [true, t('Validation:required')]
                }
            },
            customPlacement: (inputElement, errorElement) => {
                inputElement.parentElement.parentElement.appendChild(errorElement);
            }
        };

        const formObj = new FormValidator('#formUserItem', formValidator);

        if (dataUser && dataUser.funcionarioVale) {
            formObj.addRules("area", { required: [true, t('Validation:required')] });
            formObj.addRules("gerencia", { required: [true, t('Validation:required')] });
            formObj.addRules("departamento", { required: [true, t('Validation:required')] });
        }

        if (dataUser && !dataUser.funcionarioVale) {
            formObj.addRules("supplier", { required: [true, t('Validation:required')] });
        }
        setFormObject(formObj);
    }, [dataUser]);

    React.useEffect(() => {
        createSpinner({ target: document.getElementById('loadingProfile') });
        showSpinner(document.getElementById('loadingProfile'));
        setSpinner({ type: 'Bootstrap' });
    }, [openModal]);

    React.useEffect(() => {
        async function load() {
            let _usersFull = await usersFull(globalState.accessToken);

            if (_usersFull && idUser) {
                _usersFull = _usersFull.filter(i => i.userId == idUser)[0];

                setDataUser(_usersFull);
            }
            else
                setDataUser(emptyUser);
        }
        load();

    }, [i18n.language]);

    React.useEffect(() => {
        if (updated) {
            setUpdated(false);
            setRedirect(true);
        }
    }, [updated])

    let changeUser = (e) => {
        setOpenModal(true);
        e.preventDefault();
        if (formObject.validate()) {
          setDisabledBtn(true);
            let data = dataUser;
            data.fotoB64 = photo && photo.b64 ? photo.b64.split(',')[1] : null;
            addOrUpdateUsersFull(globalState.accessToken, data)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    setDisabledBtn(false);
                })
                .then(result => {
                    if (dataUser.fotoB64 && dataUser.email.toLowerCase() ===globalState.userEmail)
                        EventEmitter.dispatch('updateMyPhoto');

                    setDataUser(emptyUser);
                    setUpdated(true);
                    setDisabledBtn(false);
                })
                .catch(error => {
                    console.error(error);
                    setOpenModal(false);
                    setDisabledBtn(false);
                })
        }
        else {
            setOpenModal(false);
            setDisabledBtn(false);
        }
    }

    const onChangeUser = (user) => {
        console.log("onChange userData", user);
        setDataUser(user);
    };

    const setFormValidationRules = (action, name, obj) => {
        if (action ==="add") {
            formObject.addRules(name, obj);
            formObject.customPlacement = (inputElement, errorElement) => {
                inputElement.parentElement.parentElement.appendChild(errorElement);
            }
        }
        else
            formObject.removeRules(name);
    }

    const excludeUser = async (e) => {
        e.preventDefault();
        await deleteUser(globalState.accessToken, idUser);
        setRedirect(true);
    }

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                redirect ? <Redirect push={true} to='/admin/users' /> :
                    dataUser && (
                        <>
                            <PermissionRedirect id="51" />
                            <div className="profileTop" />
                            <form id="formUserItem">
                                <Container>
                                    <Row>
                                        <div className="col-lg-3 col-12">
                                            <UserData hasPermissionToEdit={hasPermissionToEdit} admin={true} isNewItem={!idUser} setInfo={setDataUser} info={dataUser} setFormValidationRules={setFormValidationRules} />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <ProfData hasPermissionToEdit={hasPermissionToEdit} admin={true} info={dataUser} setInfo={setDataUser} />
                                            {hasPermissionToEdit &&
                                                <Permissions info={dataUser} changeUser={onChangeUser} />
                                            }
                                        </div>
                                        <div className="col-lg-3 col-12">
                                            <Actions disabled={disabledBtn} controls={_controls} />
                                            {hasPermissionToEdit && idUser && <>
                                                <NewPassword info={dataUser} />
                                            </>
                                            }
                                        </div>
                                    </Row>
                                </Container>
                            </form>
                            <DialogComponent
                                id="profile"
                                width="300px"
                                isModal={true}
                                visible={openModal}
                                locale={i18n.language}
                            >
                                <div id="loadingProfile" />
                                {/* {t("Users:loading")} */}
                            </DialogComponent>
                            <DialogComponent
                                id="deleteModal"
                                isModal={true}
                                visible={openModalDelete}
                                locale={i18n.language}
                            >
                                <div>
                                    <div className="divTrash">
                                        <label className="mdl2 mdl2-trash"></label>
                                        <div className="phraseDelete">
                                            <label id="labelDelete">{t("Actions:confirmDeleteMsg")}</label>
                                        </div>
                                    </div>
                                    <hr />
                                    {!openModalConfirm ?
                                        <div className="footerButtons">
                                            <button onClick={() => setOpenModalDelete(false)}>{t("Actions:cancel")}</button>
                                            <button onClick={excludeUser}>{t("Actions:confirm")}</button>
                                        </div>
                                        :
                                        <div className="deleteComplete">
                                            <div className="confirmTrash">
                                                <span className="success mdl2 mdl2-trash"></span>
                                            </div>
                                            <label>{t("DigitalMedia:deletedItem")}</label>
                                            <button onClick={() => setOpenModalDelete(false)}>{t("Actions:exit")}</button>
                                        </div>
                                    }
                                </div >
                            </DialogComponent>
                        </>
                    )
            }
        </>

    );
}
