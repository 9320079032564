import * as React from 'react';

const initialState = {
    data: null,
    startDate: null,
    endDate: null
};

const LiaisonReportStateContext = React.createContext(initialState);

const LiaisonReportStateProvider = ({ children }) => {
    const [liaisonReportState, setLiaisonReportState] = React.useState(initialState);
    return (
        <LiaisonReportStateContext.Provider value={[liaisonReportState,setLiaisonReportState]}>
            {children}
        </LiaisonReportStateContext.Provider>
    );
};

export { LiaisonReportStateContext, LiaisonReportStateProvider };


