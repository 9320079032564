import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, ColumnSeries } from '@syncfusion/ej2-react-charts';
import { Export } from "../utilities/Export";
import { GlobalStateContext } from "../general/GlobalState";
import { LiaisonReportStateContext } from "../liaisonReport/LiaisonReportState";
import { exportLiaisonReport } from "../../services/LiaisonSVC";

export const ReportComponent = () => {

  const { t } = useTranslation();
  const [globalState] = React.useContext(GlobalStateContext);
  const [liaisonReportState] = React.useContext(LiaisonReportStateContext);
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [dateRangeLabel, setDateRangeLabel] = React.useState('');
  const [newData, setNewData] = React.useState([]);

  React.useEffect(() => {
    if (liaisonReportState && liaisonReportState.data.length > 0) {
      let seriesData = liaisonReportState.data.map(i => {
        return {
          AreaNegocio: globalState.userLanguage === "en" ? i.areaNegocio.nomeEn : i.areaNegocio.nomePt,
          IdAreaNegocio: i.areaNegocio.id,
          QuantidadeAtendimentos: i.quantidadeAtendimentos
        };
      })

      let newSeriesData =  {
        IdAreaNegocio: liaisonReportState?.allData?.businessArea,
        language: i18n.language,
        NomeVeiculo: liaisonReportState?.allData?.vehicle,
        Responsaveis: liaisonReportState?.allData?.responsibles,
        DataInicio: liaisonReportState?.allData?.startPeriod,
        DataFim: liaisonReportState?.allData?.endPeriod,
        IdatendimentoStatus: liaisonReportState?.allData?.status,
        IdPais: liaisonReportState?.allData?.country,
        IdRegional: liaisonReportState?.allData?.regional
      };

      setData(seriesData);
      setNewData(newSeriesData);

      let sum = 0;
      liaisonReportState.data.map(i => {
        sum += i.quantidadeAtendimentos;
      })
      setTotal(sum);
    }

  }, [liaisonReportState.data, globalState.userLanguage]);

  React.useEffect(() => {
    if (liaisonReportState &&
      liaisonReportState.data.length > 0) {

      if (liaisonReportState.startDate && liaisonReportState.endDate) {
        let rangeDate;

        if (globalState.userLanguage === "en") {
          rangeDate = `From ${liaisonReportState.startDate.toLocaleDateString("en")} to ${liaisonReportState.endDate.toLocaleDateString("en")}`;
        } else {
          rangeDate = `De ${liaisonReportState.startDate.toLocaleDateString("pt")} até ${liaisonReportState.endDate.toLocaleDateString("pt")}`;
        }

        setDateRangeLabel(rangeDate);
      } else {
        setDateRangeLabel(null);
      }
    }
  }, [liaisonReportState.startDate, liaisonReportState.endDate, globalState.userLanguage]);

  const legendSettings = {
    visible: true,
    position: 'Custom',
    location: { x: 450, y: 20 },
    textStyle: {
      color: '#747876',
      fontFamily: 'Arial'
    }
  };

  const exportReport = (args) => {
    const extension = () => {
      switch (args) {
        case 'doc':
          return ".docx";
        case 'excel':
          return ".xlsx";
        default:
          return;
      }
    }

    const dataExport = {...newData, extension: extension()}

    exportLiaisonReport(globalState.accessToken, dataExport).then(async (res) => {
      const blob = await res.blob();
      const newBlob = new Blob([blob], { type: args === 'doc'
        ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      let url = window.URL.createObjectURL(newBlob);
      let a = document.createElement('a');
      a.download = `export${extension()}`;
      a.href = url;
      a.click();
      a.remove();
    }).catch(console.error);
  }

  const exportPDF = () => {
    window.print();
  }

  const _export = {
    pdf: () => exportPDF(),
    doc: () => exportReport('doc'),
    excel: () => exportReport('excel')
  }

  return (
    <>
      {
        data &&
        data.length > 0 &&

        <div id="reportComponent">
          <div>
            <div className="reportTopLabel">{t('LiaisonReport:topLabel')}</div>
            <div className="dateDiv">
              {
                dateRangeLabel &&
                <div>
                  <span className="mdl2 mdl2-calendar" />
                  <label>{dateRangeLabel}</label>
                </div>
              }
              <p>{t('LiaisonReport:description')}</p>
            </div>
            <ChartComponent
              id="graph"
              width="590"
              height="auto"
              legendSettings={legendSettings}
              background="#F7F9F9"
              primaryXAxis={{
                valueType: 'Category',
                title: '',
                majorTickLines: { width: 0 },
                majorGridLines: { width: 0 },
                labelStyle: { color: '#747876', fontFamily: 'Arial', size: 10, textAlignment: 'End' },
                labelIntersectAction: 'Wrap',
              }}
              primaryYAxis={{
                majorTickLines: { width: 0 },
                majorGridLines: { width: 0 }
              }}
              axisLine={{ color: 'blue' }}
              locale={i18n.language}
              tooltip={{ enable: true }}
            >
              <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, Category]} />
              <SeriesCollectionDirective>
                <SeriesDirective fill='#EDB111' name={i18n.language === "pt" ? "Quantidade" : "Amount"} dataSource={data} xName='AreaNegocio' yName='QuantidadeAtendimentos' type='Column' columnWidth="0.4" />
              </SeriesCollectionDirective>
            </ChartComponent>
          </div>
          <div id="rightTable">
            <div className="header">
              <label>{t('LiaisonReport:tableHeaderBusinessArea')}</label>
              <label>{t('LiaisonReport:tableHeaderLiaisons')}</label>
            </div>
            {liaisonReportState &&
              liaisonReportState.data.length > 0 &&
              liaisonReportState.data.map((i, index) => {
                return (
                  <div key={index} className="dataRightTable">
                    <label>{globalState.userLanguage === "en" ? i.areaNegocio.nomeEn : i.areaNegocio.nomePt}</label>
                    <label>{i.quantidadeAtendimentos}</label>
                  </div>
                );
              })
            }
            <div className="header">
              <label>Total</label>
              <label>{total}</label>
            </div>
            <Export controls={_export} doc='mdl2-word' id='exportReport' />
          </div>
        </div>
      }
    </>
  );
}
