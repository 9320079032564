import * as React from "react";
import { Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState'
import { useTranslation } from 'react-i18next';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Toolbar, Filter, Inject, VirtualScroll, Sort, ContextMenu, Page, Resize } from "@syncfusion/ej2-react-grids";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import i18n from "i18next";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { AdvertisingBudgetContext } from './AdvertisingBudgetContext';
export const HistoricContent = ({ options, gridHistoricInstance }) => {


    const { t } = useTranslation();

    // const gridInstance = React.useRef(null);
    const [
        globalAdBudget,

    ] = React.useContext(AdvertisingBudgetContext);
    const contextMenuItems = ['SortAscending', 'SortDescending', 'FirstPage', 'PrevPage', 'LastPage', 'NextPage'];

    React.useEffect(() => {
        if (gridHistoricInstance && gridHistoricInstance.current) {
            gridHistoricInstance.current.refresh();
        }
    }, [i18n.language]);

    React.useEffect(() => {
        if (gridHistoricInstance && gridHistoricInstance.current) {
            gridHistoricInstance.current.refresh();
        }
    }, [globalAdBudget.historico]);

    const formatDateColumnTemplate = (args) => {
        return args.data ? dateFormat(args.data) : '';
    };

    const dateFormat = (date) => {
        return <span>{new Date(date).toLocaleString(i18n.language)}</span>;
    };


    return (
        <div id="AdvertisingHistoricDiv">
            <div className="historicDiv">
                <div className="flex">
                    <span className="mdl2 mdl2-historic" />
                    <h2>{i18n.language ==="pt" ? "Histórico de alterações" : "Change history"}</h2>
                </div>
            </div>

            <GridComponent
                dataSource={globalAdBudget.historico}
                contextMenuItems={contextMenuItems}
                filterSettings={{ type: 'CheckBox' }}
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                allowResizing={true}
                sortSettings={{
                    columns: [{ field: 'data', direction: 'Descending' }]
                }}
                locale={i18n.language}
                ref={gridHistoricInstance}
            >
                <ColumnsDirective>

                    <ColumnDirective
                        headerText={t('ManageContracts:column_date')}
                        field="data"
                        type={'date'}
                        filter={{ type: 'Menu' }}
                        template={formatDateColumnTemplate}
                        width="180" />
                    <ColumnDirective
                        headerText={t('ManageContracts:column_action')}
                        field={`${i18n.language ==='pt' ? 'tipoAcao.nomePt' : 'tipoAcao.nomeEn'}`}
                        width="160" />
                    <ColumnDirective
                        headerText={t('ManageContracts:column_field')}
                        field={`${i18n.language ==='pt' ? 'campoPt' : 'campoEn'}`}
                        width="220" />
                    <ColumnDirective
                        headerText={t('ManageContracts:column_initialData')}
                        field="dadoInicialNEW"
                        width="200" />
                    <ColumnDirective
                        headerText={t('ManageContracts:column_finalData')}
                        field="dadoFinalNEW" width="150" />
                    <ColumnDirective
                        headerText={t('ManageContracts:column_name')}
                        field="usuarioAlteracao.nome"
                        width="120" />
                    <ColumnDirective
                        headerText={t('ManageContracts:column_email')}
                        field="usuarioAlteracao.email"
                        width="150" />
                </ColumnsDirective>
                <Inject services={[Resize, Sort, ContextMenu, Filter, Page]} />
            </GridComponent >

        </div >
    );
}
