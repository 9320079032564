import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import { AdvertisingBudgetContext } from './AdvertisingBudgetContext';
import { hasPermissions } from "../utilities/Utils";
import { GlobalStateContext } from '../general/GlobalState';

export const DataContent = ({setDataIsValidToInsert, options }) => {
  const [globalState] = React.useContext(GlobalStateContext);
  const [globalAdBudget, setGlobalAdBudget] = React.useContext(AdvertisingBudgetContext);
  const [periodDate, setDateFilter] = React.useState(null);
  const [daysDiff, setDaysDiff] = React.useState(null);
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setValue, setError } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur', });
  React.useEffect(() => {
    let result = 0;
    if (periodDate && periodDate[0] && periodDate[1]) {
      var diffTime = periodDate[1].getTime() - periodDate[0].getTime();
      var diffDays = diffTime / (1000 * 3600 * 24);
      setDaysDiff(diffDays + 1);
    } else {
      setDaysDiff(result);
    }
  }, [periodDate]);

  React.useEffect(() => {
    globalAdBudget && globalAdBudget.agencia && globalAdBudget.agencia.id && setValue('agencia.id', globalAdBudget.agencia.id);
    globalAdBudget && globalAdBudget.areaInterna && globalAdBudget.areaInterna.id && setValue('areaInterna.id', globalAdBudget.areaInterna.id);
    globalAdBudget && globalAdBudget.orcamentoAnual && globalAdBudget.orcamentoAnual.id && setValue('orcamento.id', globalAdBudget.orcamentoAnual.id);
    globalAdBudget && globalAdBudget.abaterOrcamentoAnual && setValue('abaterOrcamentoAnual', globalAdBudget.abaterOrcamentoAnual);
  }, [globalAdBudget, options]);

  const onSubmit = (data) => {
    if (!data.centroCusto && !data.ordemInterna) {
      setError('centroCusto', "required");
      setError('ordemInterna', "required");
      return;
    }

    let areaInterna = null;

    if (data && data.areaInterna && data.areaInterna.id) {
      areaInterna = {
        id: data.areaInterna.id,
      };
    }

    const obj = {
      nome: data.nome,
      agencia: {
        id: data.agencia.id,
      },
      areaInterna,
      orcamentoAnual: {
        id: data.orcamento.id,
      },
      responsavel: data.responsavel,
      centroCusto: data.centroCusto,
      ordemInterna: data.ordemInterna,
      abaterOrcamentoAnual: data.abaterOrcamentoAnual,
      dataInicial: data.period[0],
      dataFinal: data.period[1],
      ativo: true,
    };
    setGlobalAdBudget((prevState) => ({ ...prevState, ...obj }));
    setDataIsValidToInsert(true);
  };

  const onError = (errors, e) => console.log(errors, e);
  const isAdmin = hasPermissions(32, "Admin", globalState);

  return (
    <div>
      <div className="topContent caixa">
        <div className="flex">
          <span className="mdl2 mdl2-data" />
          <h2>{i18n.language ==='pt' ? 'Dados' : 'Data'}</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit, onError)} id="data-form">
        <div className="innerContentAdvertising">
          {/* nome */}
          <div className="topInput">
            <div className="input">
              <label htmlFor="titulo">
                {i18n.language ==='pt' ? 'Nome da Ação' : 'Action Name'}
                <label class="e-error">{' '}*</label>
              </label>
              <div className="e-float-input">
                <TextBoxComponent
                  id="nome"
                  name="nome"
                  enabled={hasPermissions(32, "Admin", globalState)}
                  htmlAttributes={{ maxLength: 100 }}
                  value={globalAdBudget.nome}
                  ref={register({
                    required: true,
                  })}
                  type="text"
                  placeholder={i18n.language ==='pt' ? t('AdvertisingBudget:placeholder_actionName') : 'Type action name'}
                />
                {errors.nome && (
                  <p className="error">{t('Validation:required')}</p>
                )}
              </div>
            </div>
          </div>

          <div className="inputsRow">
            {/* agencia */}
            <div className="input">
              <label htmlFor="titulo">
                {i18n.language ==='pt' ? 'Agência' : 'Agency'}
                <label class="e-error">{' '}*</label>
              </label>
              <div className="e-float-input">
                {/* {globalAdBudget.agencia.id} */}
                <DropDownListComponent
                  id="agencia"
                  cssClass={isAdmin ? "e-filled" : ""}
                  enabled={hasPermissions(32, "Admin", globalState)}
                  fields={{ text: 'text', value: 'value' }}
                  name="agencia.id"
                  dataSource={options.agencia}
                  showClearButton={true}
                  value={globalAdBudget.agencia.id}
                  ref={register({
                    required: true,
                  })}
                  placeholder={t('AdvertisingBudget:placeholder_agency')}
                />
                {errors.agencia && (
                  <p className="error">{t('Validation:required')}</p>
                )}
              </div>
            </div>

            {/* areaInterna */}
            <div className="input">
              <label htmlFor="titulo">
                {i18n.language ==='pt' ? 'Área Interna' : 'Internal Area'}
                <label class="e-error">{' '}</label>
              </label>
              <div className="e-float-input">
                <DropDownListComponent
                  id="areaInterna"
                  name="areaInterna.id"
                  cssClass={isAdmin ? "e-filled" : ""}
                  enabled={hasPermissions(32, "Admin", globalState)}
                  fields={{ text: 'text', value: 'value' }}
                  dataSource={options.areas}
                  showClearButton={true}
                  value={globalAdBudget && globalAdBudget.areaInterna && globalAdBudget.areaInterna.id}
                  ref={register({
                    required: false,
                  })}
                  placeholder={t('AdvertisingBudget:placeholder_internalArea')}
                />
                {errors.areaInterna && (
                  <p className="error">{t('Validation:required')}</p>
                )}
              </div>
            </div>

            {/* responsavel */}
            <div className="input">
              <label htmlFor="titulo">
                {i18n.language ==='pt' ? 'Responsável' : 'Responsible'}
                <label class="e-error">{' '}</label>
              </label>
              <div className="e-float-input">
                <TextBoxComponent
                  id="responsavel"
                  cssClass={isAdmin ? "e-filled" : ""}
                  enabled={hasPermissions(32, "Admin", globalState)}
                  htmlAttributes={{ maxLength: 100 }}
                  name="responsavel"
                  value={globalAdBudget && globalAdBudget.responsavel}
                  ref={register({
                    required: false,
                  })}
                  placeholder={i18n.language ==='pt' ? t('AdvertisingBudget:placeholder_responsible') : 'Type responsible for the internal area'}
                />
                {errors.responsavel && (
                  <p className="error">{t('Validation:required')}</p>
                )}
              </div>
            </div>
          </div>

          <div className="inputsRow">
            {/* centroCusto */}
            <div className="input">
              <label htmlFor="titulo">
                {i18n.language ==='pt' ? 'Centro de Custo' : 'Cost Center'}
                <label class="e-error">{' '}</label>
              </label>
              <div className="e-float-input">
                <TextBoxComponent
                  id="centroCusto"
                  enabled={hasPermissions(32, "Admin", globalState)}
                  name="centroCusto"
                  htmlAttributes={{ maxLength: 50 }}
                  value={globalAdBudget && globalAdBudget.centroCusto}
                  ref={register({
                    required: false,
                  })}
                  placeholder={t('AdvertisingBudget:placeholder_cost')}
                />
                {errors.centroCusto && (
                  <p className="error">{t('AdvertisingBudget:missingFields')}</p>
                )}
              </div>
            </div>

            {/* ordemInterna */}
            <div className="input">
              <label htmlFor="titulo">
                {i18n.language ==='pt' ? 'Ordem Interna' : 'Internal Order'}
                <label class="e-error">{' '}</label>
              </label>
              <div className="e-float-input">
                <TextBoxComponent
                  enabled={hasPermissions(32, "Admin", globalState)}
                  id="ordemInterna"
                  name="ordemInterna"
                  type="text"
                  htmlAttributes={{ maxLength: 50 }}
                  value={globalAdBudget && globalAdBudget.ordemInterna}
                  ref={register({
                    required: false,
                  })}
                  placeholder={t('AdvertisingBudget:placeholder_internalOrder')}
                />
                {errors.ordemInterna && (
                  <p className="error">{t('AdvertisingBudget:missingFields')}</p>
                )}
              </div>
            </div>

            {/* Abater do orçamento anual */}
            <div className="CheckBoxDiv">
              <CheckBoxComponent
                id="abaterOrcamentoAnual"
                name="abaterOrcamentoAnual"
                disabled={!hasPermissions(32, "Admin", globalState)}
                key={'abaterOrcamentoAnualKEY'}
                ref={register({
                  required: false,
                })}
                checked={globalAdBudget && globalAdBudget.abaterOrcamentoAnual}
                onChange={(e) => {
                  setValue('abaterOrcamentoAnual', e.target.checked);
                }}
              />
              <span>
                {i18n.language ==='pt'
                  ? 'Abater do orçamento anual'
                  : 'Discount from Annual Budget'}
              </span>
              {errors.abaterOrcamentoAnual && (
                <p className="error">{t('Validation:required')}</p>
              )}
            </div>
          </div>

          <div className="inputsRow">
            {/* periodo */}
            <div className="input">
              <label htmlFor="titulo">
                {i18n.language ==='pt' ? 'Período' : 'Period'}
                <label class="e-error">{' '}*</label>
              </label>
              <div className="e-float-input">
                <DateRangePickerComponent
                  id="period"
                  cssClass={isAdmin ? "e-filled" : ""}
                  enabled={hasPermissions(32, "Admin", globalState)}
                  placeholder={t('AssignmentNotices:date')}
                  name="period"
                  startDate={
                    globalAdBudget &&
                    globalAdBudget.dataInicial &&
                    new Date(globalAdBudget.dataInicial)
                  }
                  endDate={
                    globalAdBudget &&
                    globalAdBudget.dataFinal &&
                    new Date(globalAdBudget.dataFinal)
                  }

                  ref={register({
                    required: true,
                  })}
                  change={(e) => setDateFilter(e.value)}
                  showClearButton={true}
                  locale={i18n.language}
                  value={periodDate}
                  placeholder={i18n.language ==='pt' ? t('AdvertisingBudget:placeholder_period') : 'Choose period'}
                />
                {errors.period && (
                  <p className="error">{t('Validation:required')}</p>
                )}
              </div>
            </div>

            {/* Duração */}
            <div className="input">
              <label htmlFor="titulo">
                {i18n.language ==='pt' ? 'Duração' : 'Duration'}
                <label class="e-error">{' '}*</label>
              </label>
              <div className="e-float-input">
                <TextBoxComponent
                  id="duration"
                  cssClass={isAdmin ? "e-filled" : ""}
                  enabled={hasPermissions(32, "Admin", globalState)}
                  name="duration"
                  readOnly={true}
                  value={periodDate && daysDiff}

                  placeholder={i18n.language ==='pt' ? 'Escolha o período ' : 'Choose period'}
                  ref={register({
                    required: true,
                  })}
                />
                {errors.duration && (
                  <p className="error">{t('Validation:required')}</p>
                )}
              </div>
            </div>

            {/* Ano do Orçamento */}
            <div className="input">
              <label htmlFor="orcamento">
                {i18n.language ==='pt' ? 'Ano do Orçamento' : 'Budget Year'}
                <label class="e-error">{' '}*</label>
              </label>
              <div className="e-float-input">
                <DropDownListComponent
                  id="orcamento"
                  name="orcamento.id"
                  cssClass={isAdmin ? "e-filled" : ""}
                  enabled={hasPermissions(32, "Admin", globalState)}
                  fields={{ text: 'text', value: 'value' }}
                  dataSource={options.anos}
                  value={globalAdBudget.orcamentoAnual.id}
                  placeholder={t('AdvertisingBudget:placeholder_budgetYear')}
                  ref={register({
                    required: true,
                  })}
                />
                {errors.orcamento && (
                  <p className="error">{t('Validation:required')}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
