import * as React from 'react';

const ProfilePhotoContext = React.createContext();

const ProfilePhotoProvider = ({ children }) => {
    
    const [photo, setPhoto] = React.useState(null);
    
    return (
        <ProfilePhotoContext.Provider value={[photo, setPhoto]}>
            {children}
        </ProfilePhotoContext.Provider>

    );
};

export { ProfilePhotoContext, ProfilePhotoProvider };