import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../general/GlobalState";
import { Container, Row } from "reactstrap";
import { Buttons } from "../utilities/Buttons";
import { ReleasesList } from "../releases/ReleasesList";
import { LiaisonList } from "./LiaisonList";
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";

let userPermission;
export const Liaison = () => {

    const { t } = useTranslation();


    const [globalState] = React.useContext(GlobalStateContext);
    const [redirect, setRedirect] = React.useState(false);
    const [idLiaison, setIdLiaison] = React.useState(null);
    const [gridInstance, setGridInstance] = React.useState(null);

    React.useEffect(() => {
        let globalPermissions = globalState.permissions;
        userPermission = globalPermissions.filter((i) => i.idAreaAcesso === 7).map((i) => (i.permission));
    }, [globalState]);

    const _controls = {
        permit: userPermission ? userPermission : null,
        export: () => exportLiaison(),
        import: "",
        new: () => setRedirect(true),
        back: "",
    };

    const editLiaison = (info) => {
        setIdLiaison(info.id);
        setRedirect(true);
    }

    const exportLiaison = () => {
        if (gridInstance) {
            let lang = i18n.language;
            let gridInstanceExport = gridInstance;
            gridInstanceExport.dataSource.map(function (x) {
                x.dataRecebimento = new Date(x.dataRecebimento).toLocaleDateString(lang);
                if (x.deadLine) x.deadLine = new Date(x.deadLine).toLocaleDateString(lang);
                return x;
            });
            console.log('gridInstanceExport', gridInstanceExport);
            gridInstanceExport.excelExport();
        }
    }

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                redirect ? (
                    idLiaison === null ?
                        <Redirect push={true} to='/press/liaisonItem' />
                        :
                        <Redirect push={true} to={`/press/liaisonItem/${idLiaison}`} />
                ) : (
                        <>
                            <PermissionRedirect id="7" />
                            <div className="DefaultTop" />
                            <Container className="containerTop">
                                <div className="topTitleButtons">
                                    <Row>
                                        <div className="col-sm-8 col-12">
                                            <div className="title">
                                                <h1>{t('Liaison:title')}</h1>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                            <div className="buttons">
                                                <Buttons controls={_controls} />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <Row>
                                    <div className="col-12">
                                        <LiaisonList gridInstance={gridInstance} refLiaison={setGridInstance} edit={editLiaison} />
                                    </div>
                                </Row>
                            </Container>
                        </>
                    )
            }
        </>
    )
}
