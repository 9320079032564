import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { TextBoxComponent, MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import './releases.css';

export const ChangeHistory = (props) => {

    const { content } = props;

    return (
        <div className="changes">
            <div className="top d-flex flex-row align-items-center">
                <div className="mdl2 mdl2-historic" />
                <h2>Histórico de Alterações</h2>
            </div>
            <div className="content d-flex flex-column">
                <div>
                    {content && content.map((item, index) => (
                        <div className="item" key={index}>
                            <div className="info">
                                <span className="name">{item.usuario}</span>
                                <span className="date">{(new Date(item.data)).toLocaleDateString(i18n.language)} {(new Date(item.data)).toLocaleTimeString(i18n.language, { hour12: false })}</span>
                            </div>
                            <div className="status">
                                <span>{i18n.language ==="pt" ? item.tipoAcao.nomePt : item.tipoAcao.nomeEn}</span>
                            </div>
                        </div>
                    )).reverse()}
                </div>
            </div>
        </div>    
    )
}