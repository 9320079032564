import * as React from "react";
import i18n from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { fullReset } from '../../services/UsersSVC';
import { GlobalStateContext } from "../general/GlobalState";
import { LoginStateContext } from '../login/LoginState';

export const NewPassword = (props) => {

    const { info } = props;

    const { t } = useTranslation();

    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const [showSucessText, setShowSucessText] = React.useState(false);

    const onSubmit = () => {
        fullReset(globalState.accessToken, info.userId)
            .then(response => {
                if (response.ok) {
                    setShowSucessText(true);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    return (
        <>
                <div className="changePassword">
                    <div className="top d-flex flex-row align-items-center">
                        <div className="mdl2 mdl2-lock" />
                        <h2>{t('ChangePassword:title')}</h2>
                    </div>
                    <form className="content d-flex flex-column"> 
                        {showSucessText &&
                            <span>E-mail enviado com sucesso</span>
                        }
                        <ButtonComponent
                            id="changePassword"
                            cssClass="e-outline e-primary"
                            onClick={() => onSubmit()}
                            type="button"
                        >{t('ResetPassword:title')}</ButtonComponent>
                    </form>
                </div>
        </>
    )
}