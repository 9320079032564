import * as React from 'react';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { ProfilePhotoContext } from "./ProfilePhotoContext"
import { createSpinner, DialogComponent, setSpinner, showSpinner } from "@syncfusion/ej2-react-popups";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import './profilePhoto.css';

const uploadIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="61.1" height="62.1" viewBox="0 0 61.1 62.1"><defs><style>.a{fill:#dbdbdb;}.b{fill:#007e7a;}</style></defs><g transform="translate(-690 -235)"><path class="a" d="M715.8,285.9H704.7V297H701V285.9H690v-3.7h11.1V271.1h3.7v11.1h11.1v3.7ZM741.6,238a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H712.2v-3.7H738V279.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V241.7H704.9v22.1h-3.7V238Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V279.5Z"/><path class="b" d="M717.8,282.9H706.7V294H703V282.9H692v-3.7h11.1V268.1h3.7v11.1h11.1v3.7ZM743.6,235a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H714.2v-3.7H740V276.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V238.7H706.9v22.1h-3.7V235Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V276.5Z"/></g></svg>';

let dialogRef;
export const ProfilePhotoComponent = ({ journalist, idContact, showAvatar, hasPermissionToEdit, status, toggle }) => {
    let uploaderRef;
    let dropAreaRef;

    const [photo, setPhoto] = React.useContext(ProfilePhotoContext);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [cachePhoto, setCachePhoto] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const { t } = useTranslation();
    const modalButtons = [{
        'click': () => {
            updatePhoto();
            removeFiles();
            setOpenDialog(false);
        },
        buttonModel: {
            isPrimary: true,
            content: t('Actions:save')
        }
    }];

    const target = document.getElementById("uploadPhoto");

    React.useEffect(() => {
        setOpenDialog(status);
    }, [status]);

    React.useEffect(() => {
        if (photo) {
            if (uploaderRef) {
                uploaderRef.dropArea = dropAreaRef;
                uploaderRef.dataBind();
            }
        }
    }, [photo]);

    React.useEffect(() => {
        if (openDialog)
            dialogRef.buttons = modalButtons;
    }, [openDialog]);

    const uploadPhoto = () => {
        document.getElementsByClassName("e-file-select-wrap")[0].querySelector("button").click();
        return false;
    }

    const onClose = () => {
        removeFiles();
        if (photo.remove || !photo.b64) {
            setPhoto(photo => ({
                name: photo.name,
                size: photo.size,
                type: photo.type,
                url: photo.url
            }));
        }
    };

    React.useEffect(() => {
        createSpinner({ target: document.getElementById('loadingPhoto') });
        showSpinner(document.getElementById('loadingPhoto'));
        setSpinner({ type: 'Bootstrap' });
    }, [loading]);

    const removeFiles = () => {
        const clearThumb = document.getElementById("uploadPhoto") && document.getElementById("uploadPhoto").ej2_instances[0];
        if (clearThumb !== null && clearThumb.clearAll !== null && typeof (clearThumb.clearAll) !== "undefined") {
            return clearThumb.clearAll();
        }
    }

    const uploaderCreated = () => {
        if (photo) {
            uploaderRef.files = photo.b64 || photo.url ? [photo] : null;
        }
        if (uploaderRef) {
            uploaderRef.dropArea = dropAreaRef;
            uploaderRef.dataBind();
        }
    }

    const updatePhoto = () => {
        setPhoto(photo => {
            if (photo.remove) {
                if (photo.url)
                    return { ...photo, b64: null, url: null, delete: true, changed: true };
                else
                    return { ...photo, b64: null, url: null, changed: true };
            }
            return { ...photo, changed: true }
        });
    }

    // const onCreated = () => {
    //     if (uploaderRef) {
    //         uploaderRef.dropArea = dropAreaRef;
    //         uploaderRef.dataBind();
    //     }
    // }

    const onRemovingAttachsFiles = (args) => {
        setPhoto(photo => {
            return { ...photo, remove: true }
        });
        removeFiles();
    };

    const onSetAttachmentsFiles = event => {
        if (event.filesData[0].statusCode ==="1") {
            setLoading(true);
            event.filesData.map((file) => {
                const reader = new FileReader();
                const firstFile = document.getElementById("uploadPhoto").files[0];
                reader.addEventListener("load", function () {
                    const name = firstFile.name;
                    const i = name.lastIndexOf('.');
                    const fileName = name.substring(0, i);
                    const ext = name.substring(i + 1);

                    setPhoto(photo => ({ ...photo, name: fileName, remove: false, size: firstFile.size, type: ext, b64: reader.result }));
                    setLoading(false);
                }, false);
                reader.readAsDataURL(file.rawFile);
            });
        } else {
            document.getElementById("validateImage").innerHTML = "O tamanho do arquivo é muito grande";
        }

        document.getElementById("validateImage").innerHTML = "";
    }

    const downloadAttachment = ({ fileName, url }) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    const removeAttachment = () => {
        setPhoto(photo => {
            if (photo.b64 && photo.url)
                return { ...photo, b64: null };
            else
                return { ...photo, remove: true }
        });
        removeFiles();
    };

    var getBriefName = journalist && journalist.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();

    const beforeClose = () => {
        setOpenDialog(false);
        toggle && toggle.close();
    };

    return (
        <div>
            {showAvatar && idContact && !photo ?
                <div className="avatar" onClick={() => setOpenDialog(true)}>
                    <div className="img">
                        {getBriefName &&
                            <span className="briefName">{getBriefName}</span>
                        }
                    </div>
                    <div className="profileIcon">
                        <span className="mdl2 mdl2-user" />
                    </div>
                </div>
                :
                showAvatar &&
                <div className="avatar" onClick={() => setOpenDialog(true)}>
                    <div className="img">
                        {
                            photo && ((photo.changed && photo.b64) || !photo.changed && photo.url) ? <img src={(photo.changed && photo.b64) || photo.url} /> : <div className="mdl2 mdl2-contacts" />
                        }
                    </div>
                </div>
            }

            <DialogComponent
                id="modalProfile"
                ref={dialog => dialogRef = dialog}
                // className={photo && !photo.b64 ? "preload": null}
                width="662px"
                isModal={true}
                // buttons={modalButtons}
                visible={openDialog}
                showCloseIcon={true}
                beforeClose={beforeClose}
                close={onClose}
                locale={i18n.language}
            >

                <div>
                    <div className="upload" ref={dropArea => dropAreaRef = dropArea}>
                        {(photo && ((photo.b64 && !photo.remove) || (photo.changed ===null && photo.url && !photo.remove))) ?
                            <div>
                                <span id="change" onClick={() => uploadPhoto()}>
                                    <img src={photo.b64 || photo.url} />
                                </span>
                            </div>
                            :
                            <div className="new">
                                <span dangerouslySetInnerHTML={{ __html: uploadIcon }} />
                                <p>{t('Photo:uploadMessage')} <span onClick={() => uploadPhoto()}>{t('Photo:uploadMessageLastPart')}</span></p>
                            </div>
                        }

                    </div>

                    <div>
                        {loading &&
                            <div id="loadingPhoto" />}
                    </div>

                    <div>
                        {photo && photo.name && !photo.remove &&
                            <div className="custom-e-upload e-upload e-control-wrapper e-lib e-keyboard e-form-upload">
                                <ul className="e-upload-files">
                                    <li className="e-upload-file-list" data-files-count="1">
                                        <span className="e-file-container pointerClick" onClick={() => downloadAttachment(photo.name, photo.url)}>
                                            <span className="e-file-name">{photo.name}.png</span>
                                        </span>
                                        <span className="e-icons e-file-remove-btn" title="Retirar" onClick={() => onRemovingAttachsFiles()} />
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>

                    <UploaderComponent
                        id="uploadPhoto"
                        ref={uploader => uploaderRef = uploader}
                        allowedExtensions=".jpg, .png, .jpeg"
                        dropArea={target}
                        maxFileSize={3000000}
                        locale={i18n.language}
                        selected={onSetAttachmentsFiles}
                        removing={onRemovingAttachsFiles}
                        multiple={false}
                        created={uploaderCreated}
                    >
                    </UploaderComponent>

                    <div id="validateImage" />
                </div>
            </DialogComponent>
        </div>
    );
}