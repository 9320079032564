import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Sort, ContextMenu, Page, Resize, Inject } from "@syncfusion/ej2-react-grids";
import './releases.css';
import { getMailingReportItems } from "../../services/PressSVC";
import { getReportMailingStatus } from "../../services/SystemSVC";

let grid;
export const ModalReleases = (props) => {

    const { mailingStatusSelected, setMailingStatusSelected } = props;
    const [globalState] = React.useContext(GlobalStateContext);
    const [gridSource, setGridSource] = React.useState([]);
    const [reportMailingStatus, setReportMailingStatus] = React.useState(null);
    const { t } = useTranslation();

    const contextMenuItems = ['SortAscending', 'SortDescending', 'FirstPage', 'PrevPage', 'LastPage', 'NextPage'];

    React.useEffect(() => {
        if (reportMailingStatus) {
            grid.showSpinner();
            getMailingReportItems(globalState.accessToken, mailingStatusSelected).then(response => {
                if (response.ok) {
                    response.json().then(result => {
                        result.map(item => {
                            const reportmailingStatusFiltered = reportMailingStatus.filter(rms => rms.id === item.idReportMailingStatus);
                            if (reportmailingStatusFiltered.length) {
                                item.statusPt = reportmailingStatusFiltered[0].nomePt;
                                item.statusEn = reportmailingStatusFiltered[0].nomeEn;
                            }
                        })
                        setGridSource(result);
                    });
                }
                else
                    grid.hideSpinner();
            })
        }
    }, [reportMailingStatus])

    React.useEffect(() => {
        if (grid) {
            grid.dataSource = gridSource;
        }
    }, [gridSource])

    const onGridCreated = () => {
        getReportMailingStatus(globalState.accessToken).then(response => {
            if (response.ok) {
                response.json().then(result => setReportMailingStatus(result));
            }
            else
                grid.hideSpinner();
        });
    }

    const statusTemplate = (args) => {
        return (
            <span className={args.idReportMailingStatus === 1 ? "status success " : args.idReportMailingStatus === 2 ? "status error" : "status processing"}>
                {i18n.language === "pt" ? args.statusPt : args.statusEn}
            </span>
        );
    }

    React.useEffect(() => {
        if (grid)
            grid.refresh();
    }, [i18n.language]);

    return (
        <DialogComponent
            id="modalReleases"
            width="878px"
            isModal={true}
            visible={mailingStatusSelected > 0}
            showCloseIcon={true}
            beforeClose={() => setMailingStatusSelected(0)}
            locale={i18n.language}
        >
            <span className="mailingReport">{t("Press:reportMailing")} {mailingStatusSelected}</span>
            <GridComponent
                id="gridModalRelease"
                ref={gridComponent => grid = gridComponent}
                dataSource={[]}
                contextMenuItems={contextMenuItems}
                locale={i18n.language}
                filterSettings={{ type: 'CheckBox' }}
                allowFiltering={true}
                allowPaging={true}
                pageSettings={{ pageSize: 7 }}
                allowSorting={true}
                locale={i18n.language}
                created={onGridCreated}
            >
                <ColumnsDirective>
                    <ColumnDirective
                        id=""
                        field="email"
                        template=""
                        headerText="Email"
                        width="250px"
                    ></ColumnDirective>
                    <ColumnDirective
                        id=""
                        field={i18n.language === "pt" ? "statusPt" : "statusEn"}
                        template={statusTemplate}
                        headerText="Status"
                        width="120px"
                    ></ColumnDirective>
                    <ColumnDirective
                        id=""
                        field="detailedError"
                        template=""
                        headerText={t("Press:errorDetail")}
                    ></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Resize, Sort, ContextMenu, Filter, Page,]} />
            </GridComponent>
        </DialogComponent>
    )
}