import React, { useContext, useEffect } from "react";
import i18n from "i18next";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from "../general/GlobalState";
import { MessageHouseContent } from './MessageHouseContent';
import { Container, Row } from "reactstrap";
import { Buttons } from "../utilities/Buttons";
import '../whoiswho/whoiswho.css'
import { useMessageHouse } from "./context/MessageHouseContext";

let userPermission;

export const MessageHouseItem = () => {

    const [globalState] = useContext(GlobalStateContext);

    const { t } = useTranslation();

    const { openEdit } = useMessageHouse();

    useEffect(() => {
        let globalPermissions = globalState.permissions;
        userPermission = globalPermissions.filter((i) => i.idAreaAcesso === 57).map((i) => (i.permission));
        console.log(userPermission);
    }, [globalState]);

    const _controls = {
        permit: userPermission ? userPermission : null,
        export: "",
        import: "",
        new: () => openEdit(),
        back: "",
    };

    console.log(_controls);

    return (
        <div id="WhoIsWhoItem">
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                <>
                    <div className="DefaultTop" />
                    <Container className="containerTop">
                        <div className="topTitleButtons">
                            <Row>
                                <div className="col-sm-8 col-12">
                                    <div className="title">
                                        <h1>{t('MessageHouse:title')}</h1>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <div className="buttons">
                                        <Buttons controls={_controls} />
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <Row>        
                            <div className="col-12">
                                <MessageHouseContent />
                            </div>
                        </Row>
                    </Container>
                </>
            }
        </div>
    );
}