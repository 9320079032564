import * as React from "react";
import i18n from "i18next";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from "../general/GlobalState";
import { WhoIsWhoContent } from './WhoIsWhoContent';
import { Container, Row } from "reactstrap";
import '../whoiswho/whoiswho.css'
import { Buttons } from "../utilities/Buttons";

export const WhoIsWhoItem = (props) => {

    const [globalState] = React.useContext(GlobalStateContext);
    const [gridInstance, setGridInstance] = React.useState(null);

    const { t } = useTranslation();

    const exportExcelWhoisWho = () => {
        if (gridInstance) {
            let lang = i18n.language;
            let gridInstanceExport = gridInstance;
            gridInstanceExport.dataSource.map(function (x) {
                x.dataRecebimento = new Date(x.dataRecebimento).toLocaleDateString(lang);
                if (x.deadLine) x.deadLine = new Date(x.deadLine).toLocaleDateString(lang);
                return x;
            });
            gridInstanceExport.excelExport();
        }
    }

    const exportPdfWhoisWho = () => {
        if (gridInstance) {
            let lang = i18n.language;
            gridInstance.pdfExport();
        }
    }

    const _controls = {
        export: () => exportExcelWhoisWho(),
        exportPdf: () => exportPdfWhoisWho(),
    };

    return (
        <div id="WhoIsWhoItem">
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                <>
                    <div className="DefaultTop" />
                    <Container className="containerTop">
                        <div className="topTitleButtons">
                            <Row>
                                <div className="col-sm-8 col-12">
                                    <div className="title">
                                        <h1>{t('WhoisWho:title')}</h1>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <div className="buttons">
                                        <Buttons controls={_controls} />
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <Row>
                            <div className="col-12">
                                <WhoIsWhoContent refWhoisWho={gridInstance} setRefWhoisWho={setGridInstance} />
                            </div>
                        </Row>
                    </Container>
                </>
            }
        </div>
    );
}