import * as React from "react";
import { Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState'
import { useTranslation } from 'react-i18next';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { DataManager } from '@syncfusion/ej2-data';
import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Toolbar, Filter, Inject, VirtualScroll, Sort, ContextMenu, Page, Resize, ColumnChooser } from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import i18n from "i18next";

import './ErrorLogs.css';
import { SerialNoAdaptor } from './SerialNoAdaptor';

export const ErrorLogs = () => {

    const [globalState] = React.useContext(GlobalStateContext);
    const { t } = useTranslation();

    let ddlElem;
    let ddlObj;
    let gridInstance;
    let tooltipInstance;

    const contextMenuItems = ['SortAscending', 'SortDescending', 'FirstPage', 'PrevPage', 'LastPage', 'NextPage'];
    const editOptions = { allowEditing: true };
    const toolbarOptions = ['ColumnChooser'];
    const hostURL = process.env.REACT_APP_API_URL + '/';
    const dataManager = new DataManager({
        adaptor: new SerialNoAdaptor,
        batchUrl: hostURL + "_api/ErrorLogs/UpdateErrorLogsBatch",
        url: hostURL + "_api/ErrorLogs",
        headers: [{ 'Authorization': 'Bearer ' + globalState.accessToken }]
    });
    const sortSettings = {
        columns: [{ field: 'resolvido', direction: 'Ascending' }]
    };

    const active_colum = (fieldValue) => {
        return (
            <>
                {fieldValue ==="Sim" ?
                    <span className="active yes">{i18n.language ==="pt" ? "Sim" : "Yes"}</span>
                    :
                    <span className="active no">{i18n.language ==="pt" ? "Não" : "No"}</span>
                }
            </>
        );
    }

    let dateFormat = (args) => {
        return <span>{new Date(args.dataAfericao).toLocaleDateString(i18n.language)}</span>;
    };

    let filterTemplateBool = (fieldValue) => {
        return (
            <>
                {fieldValue ?
                    <span className="active yes">{i18n.language ==="pt" ? "Sim" : "Yes"}</span>
                    :
                    <span className="active no">{i18n.language ==="pt" ? "Não" : "No"}</span>
                }
            </>
        );
    };

    const ddlParams = {
        create: () => {
            ddlElem = document.createElement('input');
            return ddlElem;
        },
        destroy: () => {
            ddlObj.destroy();
        },
        read: () => {
            return ddlObj.text;
        },
        write: (args) => {
            ddlObj = new DropDownList({
                dataSource: [{ text: "Sim", value: 1 }, { text: "Não", value: 0 }],
                text: args.rowData[args.column.field],
                fields: { text: 'text', value: 'value' },
                floatLabelType: 'Never'
            });
            ddlObj.appendTo(ddlElem);
        }
    };

    const beforeUpdateBatch = (e) => {
        e.batchChanges.changedRecords = e.batchChanges.changedRecords.map(value => {
            value.resolvido = value.resolvido === "Sim" ? true : false;
            value.mensagemEnviada = value.mensagemEnviada === "Sim" ? true : false;
            return value;
        });
    };

    const beforeDataBound = (args) => {
        if (args.result) {
            args.result.map((value) => {
                value.resolvido = value.resolvido ? "Sim" : "Não";
                value.mensagemEnviada = value.mensagemEnviada ? "Sim" : "Não";
                value.dataAfericao = value.dataAfericao.toISOString();
            });
        }
    };

    const templateCopyClipboard = (id, idOperacao) => {
        return <div>
            <span id={`operationId_${id}`}>{idOperacao}</span>
            <TooltipComponent className="wrap"
                ref={t => tooltipInstance = t}
                content={i18n.language ==="pt" ? "Copiado" : "Copied"}
                opensOn='custom'
                target={`clipboardAnchor_${id}`}
                isSticky={false}>
                <a id={`clipboardAnchor_${id}`} href={`JavaScript:`} onClick={() => copyToClipboard(`operationId_${id}`)}>{i18n.language ==="pt" ? "Copiar" : "Copy"}</a>
            </TooltipComponent>
        </div>
    };

    const copyToClipboard = (eleId) => {
        var copyText = document.getElementById(eleId);
        var textArea = document.createElement("textarea");
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        textArea.setSelectionRange(0, 99999);

        document.execCommand("copy");

        if (copyText.getAttribute('data-tooltip-id')) {
            tooltipInstance.close();
        }
        else {
            tooltipInstance.open(copyText);
            setTimeout(() => {
                tooltipInstance.close();
            }, 1000);
        }
        textArea.remove();
    }

    React.useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language]);

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                <>
                    <div className="DefaultTop" />
                    <Container className="containerTop">
                        <div className="topTitleButtons">
                            <Row>
                                <div className="col-12">
                                    <div className="title">
                                        <h1>{t('ErrorLogs:title')}</h1>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <Row>
                            <div className="col-12">
                                <GridComponent
                                    id="logsErros"
                                    ref={grid => gridInstance = grid}
                                    dataSource={dataManager}
                                    editSettings={editOptions}
                                    contextMenuItems={contextMenuItems}
                                    toolbar={toolbarOptions}
                                    filterSettings={{ type: 'CheckBox' }}
                                    pageSettings={{ pageSize: 8 }}
                                    allowPaging={true}
                                    allowSorting={true}
                                    allowFiltering={true}
                                    locale={i18n.language}
                                    allowTextWrap={true}
                                    allowResizing={true}
                                    showColumnChooser={true}
                                    beforeBatchSave={beforeUpdateBatch}
                                    beforeDataBound={beforeDataBound}
                                    sortSettings={sortSettings}
                                >
                                    <ColumnsDirective>
                                        <ColumnDirective headerText={t('ErrorLogs:column_id')} field="id" allowEditing={false} isPrimaryKey={true} width="80"></ColumnDirective>
                                        <ColumnDirective headerText={t('ErrorLogs:column_name')} field="nome" allowEditing={false} width="150"></ColumnDirective>
                                        <ColumnDirective headerText={t('ErrorLogs:column_code')} field="codigo" allowEditing={false} width="130"></ColumnDirective>
                                        <ColumnDirective headerText={t('ErrorLogs:column_message')} field="mensagem" allowEditing={false} width="165"></ColumnDirective>
                                        <ColumnDirective headerText={t('ErrorLogs:column_operationId')} field="idOperacao" template={(args) => templateCopyClipboard(args.id, args.idOperacao)} allowEditing={false} width="165"></ColumnDirective>
                                        <ColumnDirective headerText={t('ErrorLogs:column_user')} field="usuario.nome" allowEditing={false} width="100"></ColumnDirective>
                                        <ColumnDirective headerText={t('ErrorLogs:column_resolved')} template={(args) => active_colum(args.resolvido)} field="resolvido" editType='dropdownedit' edit={ddlParams} allowEditing={true} filterItemTemplate={(args) => filterTemplateBool(args.resolvido)} width="120"></ColumnDirective>
                                        <ColumnDirective headerText={t('ErrorLogs:column_date')} field="dataAfericao" template={dateFormat} filterItemTemplate={dateFormat} allowEditing={false} width="90"></ColumnDirective>
                                        {/* <ColumnDirective headerText={t('ErrorLogs:column_messageSended')} template={(args) => active_colum(args.mensagemEnviada)} field="mensagemEnviada" allowEditing={false} filterItemTemplate={(args) => filterTemplateBool(args.resolvido)}></ColumnDirective> */}
                                    </ColumnsDirective>
                                    <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Edit, Toolbar, ColumnChooser]} />
                                </GridComponent >
                            </div>
                        </Row>
                    </Container>
                </>
            }

        </>
    );
}