import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../general/GlobalState";
import { GridComponent, ColumnsDirective, ColumnDirective, ForeignKey, Filter, Inject, VirtualScroll, Sort, ContextMenu, Page, Resize, Edit, ExcelExport } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent, AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent, DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { getCountries, getAllBusinessAreas, getRegional, getLiaisonStatus } from "../../services/SystemSVC";
import { getLiaisonItems } from "../../services/LiaisonSVC";
import '../releases/releases.css';
import { hasPermissions } from "../utilities/Utils";
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";
import { useModal } from "../../hooks/Modal";

export const LiaisonList = (props) => {

    const { t } = useTranslation();

    const [globalState] = React.useContext(GlobalStateContext);
    const [isVisible, setIsVisible] = React.useState(false);
    const { refLiaison, gridInstance } = props;

    const [fillBusinessAreaDataPt, setFillBusinessAreaDataPt] = React.useState([]);
    const [fillCountryDataPt, setFillCountryDataPt] = React.useState([]);
    const [fillRegionalDataPt, setFillRegionalDataPt] = React.useState([]);
    const [fillStatusDataPt, setFillStatusDataPt] = React.useState([]);

    const [fillBusinessAreaDataEn, setFillBusinessAreaDataEn] = React.useState([]);
    const [fillCountryDataEn, setFillCountryDataEn] = React.useState([]);
    const [fillRegionalDataEn, setFillRegionalDataEn] = React.useState([]);
    const [fillStatusDataEn, setFillStatusDataEn] = React.useState([]);

    const [allLiaisonItems, setAllLiaisonItems] = React.useState([]);

    const [liaisonItemsPt, setLiaisonItemsPt] = React.useState([]);
    const [liaisonItemsEn, setLiaisonItemsEn] = React.useState([]);

    const [loadedCount, setLoadedCount] = React.useState(0);
    const [loaded, setLoaded] = React.useState(false);

    const [liaisonFilter, setLiaisonFilter] = React.useState(null);

    const [dateFilter, setDateFilter] = React.useState(null);
    const [responsibleFilter, setResponsibleFilter] = React.useState(null);
    const [businessAreaFilter, setBusinessAreaFilter] = React.useState(null);
    // const [jornalistaFilter, setJornalistaFilter] = React.useState(null);
    const [countryFilter, setCountryFilter] = React.useState(null);
    const [regionalFilter, setRegionalFilter] = React.useState(null);
    const [statusFilter, setStatusFilter] = React.useState(null);

    const { buildDialogMessage } = useModal();

    const initialLoad = () => {
        var promises = [];

        getAllBusinessAreas(globalState.accessToken)
            .then(response => {
                if (response.ok) {
                    let p = response.json();
                    promises.push(p);
                    p.then(data => configBusinessArea(data));
                }
            });
        getCountries(globalState.accessToken)
            .then(response => {
                if (response.ok) {
                    let p = response.json();
                    promises.push(p);
                    p.then(data => configCountries(data));
                }
            });
        getRegional(globalState.accessToken)
            .then(response => {
                if (response.ok) {
                    let p = response.json();
                    promises.push(p);
                    p.then(data => configRegionals(data));
                }
            });

        getLiaisonStatus(globalState.accessToken)
            .then(response => {
                if (response.ok) {
                    let p = response.json();
                    promises.push(p);
                    p.then(data => configStatus(data));
                }
            });
    }

    const configBusinessArea = (_businessAreas) => {
        setFillBusinessAreaDataPt(_businessAreas.map(x => {
            return {
                id: x.id,
                nome: x.nomePt
            };
        }));
        setFillBusinessAreaDataEn(_businessAreas.map(x => {
            return {
                id: x.id,
                nome: x.nomeEn
            };
        }));
        setLoadedCount(loadedCount => loadedCount + 1);
    };

    const configStatus = (_status) => {
        setFillStatusDataPt(_status.map(x => {
            return {
                id: x.id,
                nome: x.nomePt
            };
        }));
        setFillStatusDataEn(_status.map(x => {
            return {
                id: x.id,
                nome: x.nomeEn
            };
        }));
        setLoadedCount(loadedCount => loadedCount + 1);
    };

    const configCountries = (_contries) => {
        setFillCountryDataPt(_contries.map(x => {
            return {
                id: x.id,
                nome: x.nomePt
            };
        }));
        setFillCountryDataEn(_contries.map(x => {
            return {
                id: x.id,
                nome: x.nomeEn
            };
        }));
        setLoadedCount(loadedCount => loadedCount + 1);
    };

    const configRegionals = (_regionals) => {
        setFillRegionalDataPt(_regionals.map(x => {
            return {
                id: x.id,
                nome: x.nomePt
            };
        }));
        setFillRegionalDataEn(_regionals.map(x => {
            return {
                id: x.id,
                nome: x.nomeEn
            };
        }));
        setLoadedCount(loadedCount => loadedCount + 1);
    };

    let liaisonName = (args) => {
        return (
            <span className="title" onClick={() => props.edit(args)}>{args.jornalista}</span>
        );
    }

    let formatDataRecebimento = (args) => {
        return dateFormat(args.dataRecebimento);
    };

    let formatDeadline = (args) => {
        return args.deadLine ? dateFormat(args.deadLine) : '';
    };

    let dateFormat = (date) => {
        return <span>{new Date(date).toLocaleDateString(i18n.language)}</span>;
    };

    let liaisonStatus = (args) => {
        if (args.idStatus === 1) {
            return (<span className="status green">{args.status}</span>)
        } else if (args.idStatus === 3 || args.idStatus === 4) {
            return (<span className="status orange">{args.status}</span>)
        } else {
            return (<span className="status red">{args.status}</span>)
        }
    }

    const filterDate = (obj) => {
        setDateFilter(obj.value);
        let sDate = obj.startDate ? `${obj.startDate.toISOString().split('T')[0]}T00:00:00` : null;
        let eDate = obj.endDate ? `${obj.endDate.toISOString().split('T')[0]}T23:59:59` : null;
        setLiaisonFilter(
            liaisonFilter => ({
                ...liaisonFilter,
                dataInicio: sDate,
                dataFim: eDate
            })
        );
    }

    // const filterJornalista = (obj) => {
    //     setJornalistaFilter(jornalistaFilter => obj.value);
    //     setLiaisonFilter(
    //         liaisonFilter => ({
    //             ...liaisonFilter,
    //             responsaveis: obj.value
    //         })
    //     );
    // }


    const filterResponsible = (obj) => {
        setResponsibleFilter(responsibleFilter => obj.value);
        setLiaisonFilter(
            liaisonFilter => ({
                ...liaisonFilter,
                responsaveis: obj.value
            })
        );
    }

    const filterBusinessArea = (obj) => {
        setBusinessAreaFilter(businessAreaFilter => obj.value);
        setLiaisonFilter(
            liaisonFilter => ({
                ...liaisonFilter,
                areaNegocio: obj.value
            })
        );
    }

    const filterCountry = (obj) => {
        setCountryFilter(countryFilter => obj.value);
        setLiaisonFilter(
            liaisonFilter => ({
                ...liaisonFilter,
                pais: obj.value
            })
        );
    }

    const filterRegional = (obj) => {
        setRegionalFilter(regionalFilter => obj.value);
        setLiaisonFilter(
            liaisonFilter => ({
                ...liaisonFilter,
                regional: obj.value
            })
        );
    }

    const filterStatus = (obj) => {
        setStatusFilter(statusFilter => obj.value);
        setLiaisonFilter(
            liaisonFilter => ({
                ...liaisonFilter,
                status: obj.value
            })
        );
    }

    const filterVehicle = (obj) => {
        setLiaisonFilter(
            liaisonFilter => ({
                ...liaisonFilter,
                veiculo: obj.value
            })
        );
    }

    React.useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language]);

    const filteredLiaisons = () => {
        if (liaisonFilter) {
            let filteredItems = null;

            // if(liaisonFilter.jornalista) {
            //     let items = filteredItems ? filteredItems : allLiaisonItems;
            //     let id = parseInt(liaisonFilter.jornalista);
            //     filteredItems = items.filter(x => {
            //         return x.jornalista.id === id;
            //     });
            // }

            if (liaisonFilter.veiculo) {
                let decomposedText = liaisonFilter.veiculo.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                filteredItems = allLiaisonItems.filter(x => {
                    return x.nomeVeiculo.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(decomposedText);
                });
            }

            if (liaisonFilter.dataInicio) {
                let items = filteredItems ? filteredItems : allLiaisonItems;
                let sDate = new Date(liaisonFilter.dataInicio.trim());
                filteredItems = items.filter(x => {
                    return sDate.getTime() <= new Date(x.dataRecebimento).getTime();
                });
            }

            if (liaisonFilter.dataFim) {
                let items = filteredItems ? filteredItems : allLiaisonItems;
                let eDate = new Date(liaisonFilter.dataFim.trim());
                filteredItems = items.filter(x => {
                    return eDate.getTime() >= new Date(x.dataRecebimento).getTime();
                });
            }

            if (liaisonFilter.responsaveis) {
                let items = filteredItems ? filteredItems : allLiaisonItems;
                let decomposedText = liaisonFilter.responsaveis.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                filteredItems = items.filter(x => {
                    return x.responsaveis && x.responsaveis.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(decomposedText);
                });
            }

            if (liaisonFilter.areaNegocio) {
                let items = filteredItems ? filteredItems : allLiaisonItems;
                let id = parseInt(liaisonFilter.areaNegocio);
                filteredItems = items.filter(x => {
                    return x.areaNegocio.id === id;
                });
            }

            if (liaisonFilter.pais) {
                let items = filteredItems ? filteredItems : allLiaisonItems;
                let id = parseInt(liaisonFilter.pais);
                filteredItems = items.filter(x => {
                    return x.idPais === id;
                });
            }

            if (liaisonFilter.regional) {
                let items = filteredItems ? filteredItems : allLiaisonItems;
                let id = parseInt(liaisonFilter.regional);
                filteredItems = items.filter(x => {
                    return x.idRegional === id;
                });
            }

            if (liaisonFilter.status) {
                let items = filteredItems ? filteredItems : allLiaisonItems;
                let id = parseInt(liaisonFilter.status);
                filteredItems = items.filter(x => {
                    return x.idStatus === id;
                });
            }

            if (filteredItems) {
                splitDataSource(filteredItems);
            } else {
                splitDataSource(allLiaisonItems);
            }

            setIsVisible(false);
        } else {
            splitDataSource(allLiaisonItems);
        }
    }

    const splitDataSource = (data) => {
        let resultReduce = data.reduce((output, item, idx) => {
            output[0].push({ ...item });
            let itemPt = output[0][idx];

            let statusPt = fillStatusDataPt.find(st => st.id === itemPt.idStatus);
            if (statusPt) itemPt.status = statusPt.nome;

            itemPt.businessArea = itemPt.areaNegocio.nomePt;

            let countryPt = fillCountryDataPt.find(ct => ct.id === itemPt.idPais);
            if (countryPt) itemPt.country = countryPt.nome;

            let regionalPt = fillRegionalDataPt.find(rg => rg.id === itemPt.idRegional);
            if (regionalPt) itemPt.regional = regionalPt.nome;

            output[1].push({ ...item });
            let itemEn = output[1][idx];

            let statusEn = fillStatusDataEn.find(st => st.id === itemEn.idStatus);
            if (statusEn) itemEn.status = statusEn.nome;

            itemEn.businessArea = itemEn.areaNegocio.nomeEn;

            let countryEn = fillCountryDataEn.find(ct => ct.id === itemEn.idPais);
            if (countryEn) itemEn.country = countryEn.nome;

            let regionalEn = fillRegionalDataEn.find(rg => rg.id === itemEn.idRegional);
            if (regionalEn) itemEn.regional = regionalEn.nome;
            return output;
        }, [[], []]);

        setLiaisonItemsPt(liaisonItemsPt => resultReduce[0]);
        setLiaisonItemsEn(liaisonItemsEn => resultReduce[1]);
    }

    React.useEffect(() => {
        if (refLiaison && refLiaison.element) {
            refLiaison.showSpinner();
            initialLoad();
        }
    }, []);

    React.useEffect(() => {
        initialLoad();
    }, [i18n.language]);

    React.useEffect(() => {
        if (loadedCount === 4) setLoaded(true);
    }, [loadedCount]);

    React.useEffect(() => {
        if (loaded) {
            getLiaisonItems(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        response.json().then(data => {
                            setAllLiaisonItems(data);
                            splitDataSource(data);
                        });
                    }
                });
        }
    }, [loaded]);

    return (
        <>
            <PermissionRedirect id="7" />
            <div className={`busca ${isVisible ? "aberto" : ""}`}>
                <div className="filtroUsuarios d-flex">
                    <div className="SearchTitle">
                        <span className="mdl2 mdl2-search"></span>
                        <p>{t('Liaison:search_text')}</p>
                    </div>
                    <div className="searchUsers">
                        <div className="e-input-in-wrap">
                            <AutoCompleteComponent
                                id="nome"
                                className="e-input"
                                fields={{ value: 'nomeVeiculo' }}
                                dataSource={i18n.language === "en" ? liaisonItemsEn : liaisonItemsPt}
                                placeholder={t('Liaison:search_placeholder')}
                                ignoreAccent={true}
                                autofill={true}
                                change={(e) => filterVehicle(e)}
                            />
                        </div>
                    </div>
                    <div className="btnBusca d-flex">
                        {/* {!isVisible && */}
                        <div className="btnFilter">
                            <ButtonComponent
                                className="e-outline filter"
                                cssClass='e-primary filter'
                                onClick={() => setIsVisible(!isVisible)}>
                                <span className="mdl2 mdl2-filter"></span>
                                {t('Liaison:filters')}
                            </ButtonComponent>
                        </div>
                        {/* }
                        {isVisible &&
                            <div className="btnFilter">
                                <ButtonComponent
                                    className="e-outline filter active-filter"
                                    cssClass='e-primary filter'
                                    onClick={() => setIsVisible(!isVisible)}>
                                    <span className="mdl2 mdl2-filter"></span>
                                    {t('Liaison:filters')}
                                </ButtonComponent>
                            </div>
                        } */}
                        <div className="btnSearch">
                            <ButtonComponent cssClass='e-primary search' onClick={filteredLiaisons}>
                                {t('Liaison:search')}
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
                {isVisible &&
                    <div className="filtrosOcultos seis d-flex">
                        <DateRangePickerComponent
                            id="date"
                            cssClass="e-filled"
                            placeholder={t('Liaison:date')}
                            locale={i18n.language}
                            showClearButton={true}
                            change={filterDate}
                            value={dateFilter}
                        />
                        <AutoCompleteComponent
                            id="autor"
                            cssClass="e-filled"
                            placeholder={t('Liaison:author')}
                            fields={{ value: 'responsaveis' }}
                            dataSource={i18n.language === "en" ? liaisonItemsEn : liaisonItemsPt}
                            autofill={true}
                            ignoreAccent={true}
                            change={(e) => filterResponsible(e)}
                            value={responsibleFilter}
                        />
                        <DropDownListComponent
                            id="areaNegocio"
                            cssClass="e-filled"
                            placeholder={t('Liaison:business_area')}
                            dataSource={i18n.language === "en" ? fillBusinessAreaDataEn : fillBusinessAreaDataPt}
                            fields={{ text: "nome", value: "id" }}
                            locale={i18n.language}
                            ignoreAccent={true}
                            allowFiltering={true}
                            showClearButton={true}
                            change={(e) => filterBusinessArea(e)}
                            value={businessAreaFilter}
                        />
                        <DropDownListComponent
                            id="idPais"
                            cssClass="e-filled"
                            placeholder={t('Liaison:country')}
                            dataSource={i18n.language === "en" ? fillCountryDataEn : fillCountryDataPt}
                            fields={{ text: "nome", value: "id" }}
                            locale={i18n.language}
                            ignoreAccent={true}
                            allowFiltering={true}
                            showClearButton={true}
                            change={(e) => filterCountry(e)}
                            value={countryFilter}
                        />
                        <DropDownListComponent
                            id="regional"
                            cssClass="e-filled"
                            placeholder={t('Liaison:regional')}
                            dataSource={i18n.language === "en" ? fillRegionalDataEn : fillRegionalDataPt}
                            fields={{ text: "nome", value: "id" }}
                            locale={i18n.language}
                            ignoreAccent={true}
                            allowFiltering={true}
                            showClearButton={true}
                            change={(e) => filterRegional(e)}
                            value={regionalFilter}
                        />
                        <DropDownListComponent
                            id="status"
                            cssClass="e-filled"
                            placeholder={t('Liaison:status')}
                            dataSource={i18n.language === "en" ? fillStatusDataEn : fillStatusDataPt}
                            fields={{ text: "nome", value: "id" }}
                            locale={i18n.language}
                            ignoreAccent={true}
                            allowFiltering={true}
                            showClearButton={true}
                            change={(e) => filterStatus(e)}
                            value={statusFilter}
                        />
                    </div>
                }
            </div>
            <div className="liaisonData">
                <GridComponent
                    id='gridcomp'
                    dataSource={i18n.language === "en" ? liaisonItemsEn : liaisonItemsPt}

                    filterSettings={{ type: 'Menu' }}
                    allowFiltering={false}
                    allowPaging={true}
                    allowSorting={true}
                    allowResizing={true}
                    allowExcelExport={true}
                    ref={(grid) => refLiaison(grid)}
                    pageSettings={{ pageSize: 20 }}
                    locale={i18n.language}>
                    <ColumnsDirective >
                        <ColumnDirective
                            field='dataRecebimento'
                            template={formatDataRecebimento}
                            headerText={t('Liaison:date')}
                            width='100'>
                        </ColumnDirective>
                        <ColumnDirective
                            field='jornalista'
                            template={liaisonName}
                            headerText={t('Liaison:name')}
                            width='100'>
                        </ColumnDirective>
                        <ColumnDirective
                            field='status'
                            template={liaisonStatus}
                            headerText={t('Liaison:status')}
                            width={170}>
                        </ColumnDirective>
                        <ColumnDirective
                            field='responsaveis'
                            headerText={t('Liaison:author')}
                            width='100'>
                        </ColumnDirective>
                        <ColumnDirective
                            field='descricao'
                            headerText={t('Liaison:description')}
                            width='100'>
                        </ColumnDirective>
                        <ColumnDirective
                            field='deadLine'
                            template={formatDeadline}
                            headerText={t('Liaison:deadline')}
                            width='100'>
                        </ColumnDirective>
                        <ColumnDirective
                            field='businessArea'
                            headerText={t('Liaison:business_area')}
                            visible={true}
                        >
                        </ColumnDirective>
                        <ColumnDirective
                            field='country'
                            visible={false}
                        >
                        </ColumnDirective>
                        <ColumnDirective
                            field='regional'
                            visible={false}
                        >
                        </ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Edit, ForeignKey, ExcelExport]} />
                </GridComponent>
            </div>
        </>
    )
}
