import * as React from "react";
import i18n from "i18next";
import { Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState';
import { UserData } from '../profile/UserData';
import { ProfData } from '../profile/ProfData';
import { Actions } from "../utilities/Actions";
import { ChangePassword } from "../profile/ChangePassword";
import { WhoIsWho } from "../profile/WhoIsWho";
import { usersFull, getProfile, updateMyProfile } from "../../services/UsersSVC";
import { getRegional } from "../../services/SystemSVC";
import '../profile/profile.css';
import { ProfilePhotoContext } from "../utilities/ProfilePhoto/ProfilePhotoContext";
import { createSpinner, DialogComponent, setSpinner, showSpinner } from "@syncfusion/ej2-react-popups";
import { useTranslation } from "react-i18next";

export const Profile = (props) => {

    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const [dataUser, setDataUser] = React.useState([]);
    const [redirect, setRedirect] = React.useState(false);
    const [hasPermissionToEdit, setHasPermissionToEdit] = React.useState(false);
    const [photo, setPhoto] = React.useContext(ProfilePhotoContext);
    const [openModal, setOpenModal] = React.useState(false);
    const [disabledBtn, setDisabledBtn] = React.useState(false);

    const { t } = useTranslation();

    let idUser = props && props.match.params && props.match.params.id ? props.match.params.id : globalState.userId;

    const _controls = {
        class: "profile",
        savePrimary: false,
        customPermissions: idUser ===globalState.userId,
        excludePrimary: false,
        urlView: "",
        urlPreview: "",
        urlDuplicate: "",
        urlSave: () => changeInfoUser(),
        urlSend: "",
        urlCancel: () => setRedirect(true),
        urlDelete: "",
        area: true,
        hasPermissionToEdit
    };

    React.useEffect(() => {
      async function getItems() {
        const profile = await getProfile(globalState.accessToken, idUser).then(res => {
          if (res.ok)
            return res.json();
        })

        if (profile) {
          profile.userId = idUser;

          setHasPermissionToEdit(globalState.userId == profile.userId);
          setDataUser(profile);
        }
      }
      getItems();
    }, []);

    React.useEffect(() => {
        createSpinner({ target: document.getElementById('loadingProfile') });
        showSpinner(document.getElementById('loadingProfile'));
        setSpinner({ type: 'Bootstrap' });
    }, [openModal]);

    React.useEffect(() => {
        async function load() {
            let _usersFull = await usersFull(globalState.accessToken);

            if (_usersFull && idUser) {
                _usersFull = _usersFull.filter(i => i.userId ===idUser)[0];
                setDataUser(dataUser => ({
                    ...dataUser,
                    idUser: dataUser.idUser
                }));
            }
        }
        load();
    }, [i18n.language]);

    let changeInfoUser = async () => {
        setDisabledBtn(true);
        setOpenModal(true);
        let data = dataUser;
        data.fotoB64 = photo && photo.b64 ? photo.b64.split(',')[1] : null;

        let newRegional = globalState.regional;

        if (dataUser.idRegional !== globalState.regional?.id) {
            const regPromise = await getRegional(globalState.accessToken);

            if (regPromise.ok) {
                const reg = await regPromise.json();

                newRegional = reg.filter(regional => regional.id ===dataUser.idRegional);

                if (newRegional.length > 0) {
                    newRegional = newRegional[0]
                }
            }

        }

        updateMyProfile(globalState.accessToken, data)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(result => {
                setGlobalState(globalState => ({
                    ...globalState,
                    userName: dataUser.nome,
                    userPhoto: globalState.userPhoto,
                    regional: newRegional,
                }));
                setRedirect(true);
                setDisabledBtn(false);
            })
            .catch(error => {
                console.error(error);
                setDisabledBtn(false);
            })
    }

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                redirect ? <Redirect push={true} to='/' /> : (
                    <>
                        <div className="profileTop" />
                        <Container>
                            <Row>
                                <div className="col-lg-3 col-12">
                                    <UserData
                                        hasPermissionToEdit={hasPermissionToEdit}
                                        isProfile={true}
                                        isNewItem={false}
                                        admin={false}
                                        info={dataUser}
                                        setInfo={setDataUser}
                                    />
                                </div>
                                <div className={`col-lg-6 col-12`}>
                                    <ProfData
                                        hasPermissionToEdit={hasPermissionToEdit}
                                        info={dataUser}
                                    />
                                    {dataUser.incluirWhoIsWho && <WhoIsWho idUser={idUser} />}
                                </div>
                                <div className="col-lg-3 col-12">
                                  <Actions disabled={disabledBtn} controls={_controls} />
                                  {
                                      hasPermissionToEdit && <>
                                          <ChangePassword />
                                      </>
                                  }
                                  {dataUser.trocaSenha &&
                                    <div className="changes">
                                      <div className="top d-flex flex-row align-items-center">
                                        <div className="mdl2 mdl2-historic" />
                                        <h2>{t('UserData:title')}</h2>
                                      </div>
                                        <div className="content d-flex flex-column">
                                          <div className="item itemProfile">
                                            <div className="info">
                                              <span className="name">{dataUser.nome}</span>
                                              <span className="date">{(new Date(dataUser.trocaSenha)).toLocaleDateString(i18n.language)} {(new Date(dataUser.trocaSenha)).toLocaleTimeString(i18n.language, { hour12: false })}</span>
                                            </div>
                                            <div className="status">
                                              <span>{i18n.language ==="pt" ? 'Alteração' : 'Change'}</span>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                  }
                                </div>
                            </Row>
                        </Container>
                        <DialogComponent
                            id="profile"
                            width="300px"
                            isModal={true}
                            visible={openModal}
                            locale={i18n.language}
                        >
                            <div id="loadingProfile" />
                            {/* {t("Users:loading")} */}
                        </DialogComponent>
                    </>
                )
            }
        </>

    );
}
