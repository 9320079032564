import * as React from 'react';
import * as ReactDom from 'react-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { Animation, enableRipple } from '@syncfusion/ej2-base';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import { menu } from '../../services/MenuSVC';
import './menu.css';
enableRipple(true);

let listObj;

export const MainMenuMobile = (props) => {

    const { close } = props;

    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const [itemsMain, setItemsMain] = React.useState([]);

    const { t } = useTranslation();

    let listViewDisplay = { display: 'none' };
    let closeSpanDisplay = { display: 'none' };

    React.useEffect(() => {
        menu(globalState.accessToken, false)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(result => {
                setItemsMain(
                    result && result.map((firstItem, firstIndex) => (
                        {
                            iconCss: `mdl2 mdl2-${firstItem.classeIcone}`,
                            child:
                                firstItem.childs.map((secondItem, secondIndex) => (
                                    {
                                        child:
                                            secondItem.childs.map((thirdItem, thirdIndex) => (
                                                {
                                                    text: i18n.language ==="pt" ? thirdItem.nomePT : thirdItem.nomeEN,
                                                    url: thirdItem.url,
                                                    category: i18n.language ==="pt" ? secondItem.nomePT : secondItem.nomeEN
                                                }
                                            )),
                                        text: i18n.language ==="pt" ? secondItem.nomePT : secondItem.nomeEN,
                                        url: secondItem.url,
                                        category: i18n.language ==="pt" ? firstItem.nomePT : firstItem.nomeEN
                                    }
                                )),
                            text: i18n.language ==="pt" ? firstItem.nomePT : firstItem.nomeEN,
                        }
                    ))
                );
            })
            .catch(error => {
                console.error(error);
            })
    }, [i18n.language]);


    return (
        <>
            <ListViewComponent
                id="menuMobile"
                fields={{ iconCss: 'iconCss', tooltip: 'text' }}
                dataSource={itemsMain}
                showHeader={true}
                showIcon={true}
                enablePersistence={false}
                locale={i18n.language}
            ></ListViewComponent>
            <span
                id='close'
                onClick={close}
                className='e-icons'
            />
        </>
    )
}