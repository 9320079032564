import * as React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row } from "reactstrap";
import { ReportComponent } from "../pressReport/ReportComponent";
import { FilterComponent } from "../pressReport/FilterComponent";
import { PressReportStateContext } from "../pressReport/PressReportState";
import '../pressReport/pressReport.css';
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";

export const StatementsReport = () => {

  const { t } = useTranslation();
  const [pressReportState] = React.useContext(PressReportStateContext);
  const idTipo = 2;

  return (
    <>
      <PermissionRedirect id="8" />
      <div id="barOnTop"></div>
      <Container className="containerTop" id="leftContainer">
        <Row>
          <div className="col-12">
            <div className="labelOnTop">
              {t("PressReport:title-statement")}
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-lg-4 col-12">
            <FilterComponent idTipo={idTipo} />
          </div>
          {
            pressReportState &&
            pressReportState.data &&
            pressReportState.data.length > 0 &&

            <div className="col-lg-8 col-12">
              <ReportComponent idTipo={idTipo} />
            </div>
          }
        </Row>
      </Container>
    </>
  )
}
