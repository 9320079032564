import * as React from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../general/GlobalState";
import { Container, Row } from "reactstrap";
import { Buttons } from "../utilities/Buttons";
import { CommunicationEmployeesList } from "./CommunicationEmployeesList";
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";

let userPermission;

export const CommunicationEmployees = () => {

  const { t } = useTranslation();

  const [globalState] = React.useContext(GlobalStateContext);
  const [redirect, setRedirect] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let globalPermissions = globalState.permissions;
    userPermission = globalPermissions.filter((i) => i.idAreaAcesso === 75).map((i) => (i.permission));
    setLoading(true);
  }, [globalState]);

  const _controls = {
    permit: userPermission ? userPermission : null,
    export: false,
    import: "",
    new: () => setRedirect(true),
    back: "",
  };

  return (
    <>
      {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
        redirect ? (
          <Redirect push={true} to='/communicationEmployeesGroup/communicationEmployeesItem' />
        ) : (
          loading &&
          <>
            <PermissionRedirect id="75" />

            <div className="DefaultTop" />
            <Container className="containerTop">
              <div className="topTitleButtons">
                <Row>
                  <div className="col-sm-8 col-12">
                    <div className="title">
                      <h1>{t('CEGroups:title')}</h1>
                    </div>
                  </div>
                  <div className="col-sm-4 col-12">
                    <div className="buttons">
                      <Buttons controls={_controls} />
                    </div>
                  </div>
                </Row>
              </div>
              <Row>
                <div className="col-12">
                  <CommunicationEmployeesList />
                </div>
              </Row>
            </Container>
          </>
        )
      }
    </>
  )
}
