import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, VirtualScroll, Sort, ContextMenu, Page, Resize, Edit } from "@syncfusion/ej2-react-grids";
import { getBanners } from "../../services/BannerHomeSVC";
import { createSpinner, setSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';

export const BannersList = (props) => {

    const { setBannersList, bannersList, catchRefresh } = props;

    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);

    const [loadingBanner, setLoadingBanner] = React.useState(true);

    const contextMenuItems = ['SortAscending', 'SortDescending', 'FirstPage', 'PrevPage', 'LastPage', 'NextPage'];

    const { t } = useTranslation();

    let gridInstance;

    React.useEffect(() => {
        setLoadingBanner(true);
        let version = new Date().toISOString();

        async function load() {
            getBanners(globalState.accessToken)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then(result => {
                    setBannersList(result && result.map((i) => ({
                        ...i,
                        src: `${process.env.REACT_APP_API_URL}/_api/BannerHome/GetBannerHome?token=${globalState.tokenLightWeight}&IdBannerHome=${i.id}&version=${version}`
                    })))

                    hideSpinner(document.getElementById('loadBanner'));
                    setLoadingBanner(false);
                });
        }
        load();
    }, [i18n.language]);

    React.useEffect(() => {
        if (gridInstance) {
            gridInstance.refresh();
        }
    }, [catchRefresh]);

    React.useEffect(() => {
        if (loadingBanner && document.getElementById('loadBanner')) {
            createSpinner({ target: document.getElementById('loadBanner') })
            showSpinner(document.getElementById('loadBanner'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [loadingBanner]);

    const bannerStatus = (props) => {
        if (props.ativo ===true) {
            return (<span className="status sim">{i18n.language ==='pt' ? 'Sim' : 'Yes'}</span>);
        } else {
            return (<span className="status nao">{i18n.language ==='pt' ? 'Não' : 'No'}</span>);
        }
    };

    function bannerImage(args) {
        return (
            <span className="image">
                <img src={args.src} />
            </span>
        )
    }

    const bannerTitle = (args) => {
        return (
            <span className="title" onClick={() => props.edit(args)}>{args.titulo}</span>
        )
    }

    return (
        <div>
            {loadingBanner ? <div id="loadBanner" className="loadBanner" /> :
                <div>
                    {bannersList && bannersList.length > 0 &&
                        <GridComponent
                            id="bannerItems"
                            dataSource={bannersList}
                            contextMenuItems={contextMenuItems}
                            filterSettings={{ type: 'Menu' }}
                            allowPaging={true}
                            allowSorting={true}
                            locale={i18n.language}
                            ref={grid => gridInstance = grid}
                        >
                            <ColumnsDirective>
                                <ColumnDirective headerText="Thumb" template={bannerImage} width="200"></ColumnDirective>
                                <ColumnDirective headerText={t("HeaderBannerList:name")} template={bannerTitle} width="325"></ColumnDirective>
                                <ColumnDirective field="chamada" headerText={t("HeaderBannerList:call")} width="325"></ColumnDirective>
                                <ColumnDirective headerText={t("HeaderBannerList:active")} template={bannerStatus} width="150"></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Resize, Sort, ContextMenu, Filter, Page,]} />
                        </GridComponent >
                    }
                </div>
            }
        </div>
    )
};