import { requestAPI } from '../utilities/Utils';

export async function getManageInternalAreas(token) {
  // const qstring; //= idioma ? `?$filter=IdIdioma eq ${idioma}` : '';
  const qString = "?$orderby=id desc"

  return requestAPI(
    '_api/BrandingAdvertising/Management/InternalArea/GetInternalAreaItems',
    'GET',
    qString,
    null,
    token,
    false,
  );
}

export async function updateManageInternalAreas(token, body) {
  const raw = JSON.stringify(body);
  return requestAPI(
    '_api/BrandingAdvertising/Management/InternalArea/UpdateInternalArea',
    'POST',
    '',
    raw,
    token,
    false,
  );
}

export async function addManageInternalAreas(token, body) {
  const raw = JSON.stringify(body);

  return requestAPI(
    '_api/BrandingAdvertising/Management/InternalArea/AddInternalArea',
    'POST',
    '',
    raw,
    token,
    false,
  );
}

export async function enableDisableInternalArea(token, body) {
  const raw = JSON.stringify({ id: body.id, ativo: body.ativo });
  const url = `_api/BrandingAdvertising/Management/InternalArea/EnableDisableInternalArea?internalAreaId=${body.id}&enable=${body.ativo}`;
  https: return requestAPI(url, 'POST', '', raw, token, false);
}
