import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../../general/GlobalState";
import { Container, Row } from "reactstrap";
import { Buttons } from "../../utilities/Buttons";
import { GroupsList } from "./GroupsList";
import PermissionRedirect from "../../utilities/PermissionRedirect/PermissionRedirect";

let userPermission;

export const Groups = () => {

    const { t } = useTranslation();

    const [globalState] = React.useContext(GlobalStateContext);
    const [redirect, setRedirect] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [idRelease, setIdRelease] = React.useState(null);

    React.useEffect(() => {
        let globalPermissions = globalState.permissions;
        userPermission = globalPermissions.filter((i) => i.idAreaAcesso === 11).map((i) => (i.permission));
        setLoading(true);
    }, [globalState]);

    const _controls = {
        permit: userPermission ? userPermission : null,
        export: false,
        import: "",
        new: () => setRedirect(true),
        back: "",
    };

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                redirect ? (
                    idRelease === null ?
                        <Redirect push={true} to='/press/mailing/groupsItem' />
                        :
                        <Redirect push={true} to={`/press/mailing/groupsItem/${idRelease}`} />
                ) : (
                    loading &&
                        <>
                            <PermissionRedirect id="11" />

                            <div className="DefaultTop" />
                            <Container className="containerTop">
                                <div className="topTitleButtons">
                                    <Row>
                                        <div className="col-sm-8 col-12">
                                            <div className="title">
                                                <h1>{t('Groups:title')}</h1>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                            <div className="buttons">
                                                <Buttons controls={_controls} />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                    <Row>
                                    <div className="col-12">
                                        <GroupsList />
                                    </div>
                                </Row>
                            </Container>
                        </>
                    )
            }
        </>
    )
}
