import React, { useState, useEffect } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from "../general/GlobalState";
import { ComboBoxComponent, AutoCompleteComponent, MultiSelectComponent, CheckBoxSelection, DropDownTreeComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, ForeignKey, Filter, Group, Inject, Sort, ContextMenu, Page, Resize, Edit } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { UploaderComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import './messageHouse.css';
import { useMessageHouse } from "./context/MessageHouseContext";
import MessageHouseModal from "./MessageHouseModal";
import { getMessagesItems, getMessageAttachment, deleteMessage } from '../../services/MessagesHouseSVC';
import { useModal } from "../../hooks/Modal";
import { getDICIAreas } from "../../services/SystemSVC";
import { hasPermissions } from '../utilities/Utils';

const uploadIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="61.1" height="62.1" viewBox="0 0 61.1 62.1"><defs><style>.a{fill:#dbdbdb;}.b{fill:#007e7a;}</style></defs><g transform="translate(-690 -235)"><path class="a" d="M715.8,285.9H704.7V297H701V285.9H690v-3.7h11.1V271.1h3.7v11.1h11.1v3.7ZM741.6,238a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H712.2v-3.7H738V279.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V241.7H704.9v22.1h-3.7V238Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V279.5Z"/><path class="b" d="M717.8,282.9H706.7V294H703V282.9H692v-3.7h11.1V268.1h3.7v11.1h11.1v3.7ZM743.6,235a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H714.2v-3.7H740V276.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V238.7H706.9v22.1h-3.7V235Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V276.5Z"/></g></svg>';

export const MessageHouseContent = (props) => {

    const [globalState] = React.useContext(GlobalStateContext);

    const { t } = useTranslation();

    const { openEdit, modalData, openEditModal, setMessageHouseItems, messageHouseItems, setAreasDICI, tempGuid } = useMessageHouse();

    const { buildDialogMessage } = useModal();

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [currentEditingId, setCurrentEditingId] = useState(-1);

    useEffect(() => {
        async function getItems() {
            const messagesItems = await getMessagesItems(globalState.accessToken).then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    buildDialogMessage({
                        error: res.status
                    })
                }
            });

            const areasDICI = await getDICIAreas(globalState.accessToken).then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    buildDialogMessage({
                        error: res.status
                    })
                }
            });

            setAreasDICI(areasDICI);
            setMessageHouseItems(messagesItems);
        }
        getItems();

    }, [])

    const openDeleteModal = (id) => {
        setCurrentEditingId(id);
        setOpenModalDelete(true);
    };

    const closeModal = () => {
        setOpenModalDelete(false);
        setOpenModalConfirm(false);
        setCurrentEditingId(-1);
    };

    let gridInstance;

    useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language]);

    const editTemplate = (args) => {
        return (
            <div className="editItems">
                { hasPermissions(57, "Delete", globalState) &&
                    <span onClick={() => openDeleteModal(args.id)} className="mdl2 mdl2-trash" />
                }
                { hasPermissions(57, "Edit", globalState) &&
                    <span onClick={() => openEdit(args)} className="mdl2 mdl2-pencil" />
                }
            </div>
        );
    }

    const fileTemplate = (args) => {
        return (
            <a href="" onClick={(e) => {
                e.preventDefault();
                downloadAttachment({
                    fileName: args && args.nomeArquivo,
                    idOrGuid: args.id
                })
            }}>Baixar o arquivo</a>
        );
    }

    const confirmDelete = async (id) => {
        await deleteMessage(globalState.accessToken, id).then(res => {
            if (res.ok) {
                setOpenModalConfirm(true);
                const items = [...messageHouseItems];
                const index = items.findIndex(item => item.id ===id);

                items.splice(index, 1);
                setMessageHouseItems(items);
            }
            else {
                setOpenModalDelete(false);
                buildDialogMessage({
                    error: res.status
                })
            }
        });
    };

    const downloadAttachment = ({ fileName, idOrGuid }) => {
        getMessageAttachment(globalState.accessToken, idOrGuid)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    };

    const areaTemplate = args => (
        <>
            { args.areaDICI && <span>{i18n.language ==="pt" ? args.areaDICI.nomePt : args.areaDICI.nomeEn}</span>}
        </>
    )

    return (
        <div id="MessageHouseContent">
            <GridComponent
                id="managementAgreement"
                className="withoutTopContent"
                dataSource={messageHouseItems}
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                filterSettings={{ type: 'CheckBox' }}
                locale={i18n.language}
                ref={grid => gridInstance = grid}
            >
                <ColumnsDirective>
                    <ColumnDirective headerText={t('MessageHouse:column_title')} field="titulo" width="152"></ColumnDirective>
                    <ColumnDirective headerText={t('MessageHouse:column_description')} field="descricao" width="242"></ColumnDirective>
                    <ColumnDirective headerText={t('MessageHouse:column_area')} template={areaTemplate} field="areaDICI.nomePt" width="112"></ColumnDirective>
                    <ColumnDirective headerText={t('MessageHouse:column_file')} template={fileTemplate} field="file" width="182"></ColumnDirective>
                    <ColumnDirective template={(args) => editTemplate(args)} width="40"></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Resize, Sort, ContextMenu, Filter, Page]} />
            </GridComponent >

            <MessageHouseModal />

            <DialogComponent
                id="deleteModal"
                isModal={true}
                visible={openModalDelete}
                locale={i18n.language}
            >
                <div>
                    {!openModalConfirm &&
                        <div className="divTrash">
                            <label className="mdl2 mdl2-trash"></label>
                            <div className="phraseDelete">
                                <label className="labelModal delete">{t("Actions:delete")}</label>
                                <label id="labelDelete">{t("Actions:confirmDeleteMsg")}</label>
                            </div>
                            <hr />

                        </div>
                    }
                    {!openModalConfirm ?
                        <div className="footerButtons">
                            <button onClick={() => setOpenModalDelete(false)}>{t("Actions:cancel")}</button>
                            <button onClick={() => confirmDelete(currentEditingId)}>{t("Actions:confirm")}</button>
                        </div>
                        :
                        <div className="deleteComplete">
                            <div className="confirmTrash">
                                <span className="success mdl2 mdl2-trash"></span>
                            </div>
                            <label>{t("DigitalMedia:deletedItem")}</label>
                            <button onClick={() => closeModal()}>{t("Actions:exit")}</button>
                        </div>
                    }
                </div>
            </DialogComponent>
        </div>
    );
}