import * as React from "react";
import { SharedDocumentsComponent } from './SharedDocumentsComponent';
import { useTranslation } from "react-i18next";

export const GlobalCalendar = () => {
    const { t } = useTranslation();

    return <SharedDocumentsComponent permissionId="65" path="Calendário Global"
        folderManagerTitle={t("EmpCommunication:global_calendar")}
        title={t("EmpCommunication:global_calendar")}
    />
}