import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';

import { GlobalStateContext } from "../general/GlobalState";
import { LiaisonReportStateContext } from "./LiaisonReportState";
import { getAllBusinessAreas, getCountries, getRegional, getLiaisonStatus } from "../../services/SystemSVC";
import { LiaisonReportFilter, getLiaisonReportItems } from "../../services/LiaisonSVC";

export const FilterComponent = () => {

    const { t } = useTranslation();
    const [globalState] = React.useContext(GlobalStateContext);
    const [liaisonReportState, setLiaisonReportState] = React.useContext(LiaisonReportStateContext);

    const [vehicle, setVehicle] = React.useState(null);
    const [responsible, setResponsible] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    const [fillBusinessAreaDataPt, setFillBusinessAreaDataPt] = React.useState([]);
    const [fillBusinessAreaDataEn, setFillBusinessAreaDataEn] = React.useState([]);
    const [businessArea, setBusinessArea] = React.useState(null);

    const [fillStatusDataPt, setFillStatusDataPt] = React.useState([]);
    const [fillStatusDataEn, setFillStatusDataEn] = React.useState([]);
    const [status, setStatus] = React.useState(null);

    const [fillCountryDataPt, setFillCountryDataPt] = React.useState([]);
    const [fillCountryDataEn, setFillCountryDataEn] = React.useState([]);
    const [country, setCountry] = React.useState(null);

    const [fillRegionalDataPt, setFillRegionalDataPt] = React.useState([]);
    const [fillRegionalDataEn, setFillRegionalDataEn] = React.useState([]);
    const [regionalDdl, setRegionalDdl] = React.useState(null);

    const [spinnerDuration, setSpinnerDuration] = React.useState(20000);

    let refVehicle;
    let refResponsible;

    const initialLoad = () => {
        getAllBusinessAreas(globalState.accessToken)
        .then(response => {
            if (response.ok) {
                response.json().then(data => configBusinessArea(data));
            }
        });

        getLiaisonStatus(globalState.accessToken)
        .then(response => {
            if (response.ok) {
                response.json().then(data => configStatus(data));
            }
        });

        getCountries(globalState.accessToken)
        .then(response => {
            if (response.ok){
                response.json().then(data => configCountries(data));
            }
        });

        getRegional(globalState.accessToken)
        .then(response => {
            if (response.ok) {
                response.json().then(data => configRegionals(data));
            }
        });
    };

    const configBusinessArea = (_businessAreas) => {
        setFillBusinessAreaDataPt(_businessAreas.map(x => {
            return {
                id: x.id,
                nome: x.nomePt
            };
        }));
        setFillBusinessAreaDataEn(_businessAreas.map(x => {
            return {
                id: x.id,
                nome: x.nomeEn
            };
        }));
    };

    const configStatus = (_status) => {
        setFillStatusDataPt(_status.map(x => {
            return {
                id: x.id,
                nome: x.nomePt
            };
        }));
        setFillStatusDataEn(_status.map(x => {
            return {
                id: x.id,
                nome: x.nomeEn
            };
        }));
    };

    const configCountries = (_contries) => {
        setFillCountryDataPt(_contries.map(x => {
            return {
                id: x.id,
                nome: x.nomePt
            };
        }));
        setFillCountryDataEn(_contries.map(x => {
            return {
                id: x.id,
                nome: x.nomeEn
            };
        }));
    };

    const configRegionals = (_regionals) => {
        setFillRegionalDataPt(_regionals.map(x => {
            return {
                id: x.id,
                nome: x.nomePt
            };
        }));
        setFillRegionalDataEn(_regionals.map(x => {
            return {
                id: x.id,
                nome: x.nomeEn
            };
        }));
    };

    React.useEffect(() => {
        initialLoad();
    }, []);

    React.useEffect(() => {
        refVehicle.placeholder = t('Liaison:vehicle_placeholder_text');
        refResponsible.placeholder = t("Liaison:responsible_placeholder");
    }, [i18n.language]);

    const periodChanged = (obj) => {
      setStartDate(obj.startDate);
      setEndDate(obj.endDate);
    }

    const generateReport = () => {
      let filterObject = new LiaisonReportFilter(
        vehicle,
        responsible,
        businessArea,
        startDate,
        endDate,
        status,
        country,
        regionalDdl
      );

      getLiaisonReportItems(globalState.accessToken, filterObject)
      .then(response => {
        if (response.ok){
          Promise.resolve(response.json()).then(data => {
            setLiaisonReportState({
              data: data,
              startDate: startDate,
              endDate: endDate,
              allData: filterObject
            });
            setSpinnerDuration(0);
          });
        }
      });
    }

    return (
        <>
            <div id="filterComponent">
                <div className="headerFilter">
                    <span className="mdl2 mdl2-filter" />
                    <label>{t("Filter:filters")}</label>
                </div>
                <hr className="hrBar" />
                <div className="inputs">
                    <div className="input">
                        <label>{t("Liaison:vehicle")}</label>
                        <TextBoxComponent
                            id="IdVeiculo"
                            placeholder={t('Liaison:vehicle_placeholder_text')}
                            change={(e) => setVehicle(e.value)}
                            ref={veh => refVehicle = veh}
                        />
                    </div>
                    <div className="input">
                        <label>{t("Liaison:responsible")}</label>
                        <TextBoxComponent
                            id="IdResponsible"
                            placeholder={t("Liaison:responsible_placeholder")}
                            change={(e) => setResponsible(e.value)}
                            ref={res => refResponsible = res}
                        />
                    </div>
                    <div className="input">
                        <label>{t("Liaison:business_area")}</label>
                        <DropDownListComponent
                            id="IdAreaNegocio"
                            placeholder={t('Liaison:business_area_placeholder')}
                            dataSource={globalState.userLanguage === "en" ? fillBusinessAreaDataEn : fillBusinessAreaDataPt }
                            fields={{ text: "nome", value: "id" }}
                            value={businessArea}
                            change={(e) => setBusinessArea(e.value)}
                            showClearButton={true}
                        />
                    </div>
                    <div className="input">
                        <label>{t("Liaison:period")}</label>
                        <DateRangePickerComponent
                            id="IdPeriodo"
                            strictMode={true}
                            placeholder={t("Liaison:period")}
                            locale={i18n.language}
                            showClearButton={true}
                            allowEdit={false}
                            change={(e) => periodChanged(e)}
                        />
                    </div>
                    <div className="input">
                        <label>{t("Liaison:status")}</label>
                        <DropDownListComponent
                            id="IdStatus"
                            placeholder={t('Liaison:status_placeholder')}
                            dataSource={globalState.userLanguage === "en" ? fillStatusDataEn: fillStatusDataPt}
                            fields={{ text: "nome", value: "id" }}
                            value={status}
                            change={(e) => setStatus(e.value)}
                            showClearButton={true}
                        />
                    </div>
                    <div className="input">
                        <label>{t("Liaison:country")}</label>
                        <DropDownListComponent
                            id="IdCountry"
                            placeholder={t('Liaison:country_placeholder')}
                            dataSource={globalState.userLanguage === "en" ? fillCountryDataEn: fillCountryDataPt}
                            fields={{ text: "nome", value: "id" }}
                            allowFiltering={true}
                            value={country}
                            change={(e) => setCountry(e.value)}
                            showClearButton={true}
                        />
                    </div>
                    <div className="input">
                        <label>{t("Liaison:regional")}</label>
                        <DropDownListComponent
                            id="IdRegional"
                            placeholder={t('Liaison:regional_placeholder')}
                            dataSource={globalState.userLanguage === "en" ? fillRegionalDataEn: fillRegionalDataPt}
                            fields={{ text: "nome", value: "id" }}
                            value={regionalDdl}
                            change={(e) => setRegionalDdl(e.value)}
                            showClearButton={true}
                        />
                    </div>
                    <div className="button">
                        <ProgressButtonComponent
                            content={t('LiaisonReport:buttonGenerateReport')}
                            enableProgress={false}
                            onClick={() => generateReport()}
                            isPrimary={true}
                            duration={spinnerDuration}
                        />
                    </div>
                </div>
            </div>


        </>
    );
}
