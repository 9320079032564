import { requestAPI } from '../components/utilities/Utils';

export async function getMessagesItems(token) {
    return requestAPI("_api/MessagesHouse/GetMessagesItems", "GET", "", null, token, false);
}

export async function getMessageAttachment(token, idOrGuid) {
    return requestAPI("_api/MessagesHouse/DownloadAttachment", "GET", `?IdOrGuidMessage=${idOrGuid}`, null, token, false);
}

export async function addItem(token, body) {
    return requestAPI("_api/MessagesHouse/AddMessage", "POST", "", body, token, false);
}

export async function updateItem(token, body) {
    return requestAPI("_api/MessagesHouse/UpdateMessage", "POST", "", body, token, false);
}

export async function deleteMessage(token, id) {
    return requestAPI("_api/MessagesHouse/DisableMessage", "POST", `?messageId=${id}`, null, token, false);
}