import * as React from "react";
import { Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState'
import { useTranslation } from 'react-i18next';

import { Actions } from "../utilities/Actions";

import { getMyProfile, updateMyProfile } from "../../services/UsersSVC";
import { UsersList } from "../users/UsersList";
import { Buttons } from "../utilities/Buttons";
import '../profile/profile.css';
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";

export const Users = () => {

    const [globalState] = React.useContext(GlobalStateContext);
    const [dataUser, setDataUser] = React.useState([]);
    const [redirect, setRedirect] = React.useState(false);
    const [idUser, setIdUser] = React.useState(null);
    const [gridExport, setGridExport] = React.useState(false);
    const [userPermission, setUserPermission] = React.useState([]);

    let gridInstance = React.useRef(null);

    const { t } = useTranslation();

    const _controls = {
        permit: userPermission ? userPermission : null,
        export: () => exportRelease(),
        import: "",
        new: () => setRedirect(true),
        back: "",
    };

    React.useEffect(() => {
        let globalPermissions = globalState.permissions;
        console.log(globalState);
        setUserPermission(globalPermissions.filter((i) => i.idAreaAcesso === 51).map((i) => (i.permission)));
    }, [globalState]);

    let editUsers = (id) => {
        setIdUser(id);
    }

    React.useEffect(() => {
        if (idUser) {
            setRedirect(true);
        }
    }, [idUser]);

    const exportRelease = () => {
        if (gridInstance.current) {
            setGridExport(true);
        }
    }

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                redirect ? (
                    idUser === null ?
                        <Redirect push={true} to='/admin/usersItem' />
                        :
                        <Redirect push={true} to={`/admin/usersItem/${idUser}`} />
                ) : (
                    <>
                        <PermissionRedirect id="51" />
                        <div className="DefaultTop" />
                        <Container className="containerTop">
                            <div className="topTitleButtons">
                                <Row>
                                    <div className="col-sm-8 col-12">
                                        <div className="title">
                                            <h1>{t('Users:title')}</h1>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-12">
                                        <div className="buttons">
                                            <Buttons controls={_controls} />
                                        </div>
                                    </div>
                                </Row>
                            </div>
                            <Row>
                                <div className="col-12">
                                    <UsersList
                                        gridInstance={gridInstance} gridExport={gridExport} gridExportChange={setGridExport} edit={editUsers} />
                                </div>
                            </Row>
                        </Container>
                    </>
                )
            }
        </>
    );
}