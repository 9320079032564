import * as React from "react";
import { Container, Row } from "reactstrap";
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";
import { FolderManager } from "../home/FolderManager";
import { Redirect } from 'react-router-dom';
import { GlobalStateContext } from '../general/GlobalState';
import { useTranslation } from "react-i18next";

export const SharedDocumentsComponent = ({ path, permissionId, title, folderManagerTitle, pub }) => {
  const [globalState] = React.useContext(GlobalStateContext);
  const { t } = useTranslation();

  return (
    <>
            <PermissionRedirect id={permissionId} />
            {globalState.accessToken === null && permissionId ? (
                <Redirect push={true} to="/login" />
            ) : <>
                <div className="DefaultTop" />
                <Container className="containerTop">
                    <div className="topTitleButtons">
                        <Row>
                            <div className="col-12">
                                <div className="title">
                                    <h1>{ title || t("MyDocuments:sharedDocuments") }</h1>
                                </div>
                            </div>
                        </Row>
                    </div>
                    <Row>
                        <FolderManager path={path} public={pub} title={folderManagerTitle} />
                    </Row>
                </Container></>
            }
        </>
    )
}
