import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../general/GlobalState";
import { Container, Row, Col } from "reactstrap";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { Actions } from "../utilities/Actions";
import { MailingStatus } from "../releases/MailingStatus";
import { Export } from "../utilities/Export";
import { ChangeHistory } from "../releases/ChangeHistory";
import { SendItem } from "../mailing/SendItem"
import { getPressItem, addPressItem, deletePressItem, duplicatePressItem, getMailingShippingStatusItems, duplicateForwardPressItem } from "../../services/PressSVC";
import { getRegional } from "../../services/SystemSVC";
import { PressForm } from "../Shared/PressForm";
import { ReleaseStateContext } from "../releases/ReleaseState";
import { storageAccUrl } from "../utilities/Constants";
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";
import { hasPermissions } from "../utilities/Utils";
import { getAndMountSignature } from "../../services/SignatureSVC";

// let richTextValue;
//let releaseItem;

export const ReleasesItem = (props) => {
    let itemId = props.match.params.id;
    const { t } = useTranslation();

    const [globalState] = React.useContext(GlobalStateContext);
    const { releaseState, setReleaseState, showSendContent, setShowSendContent, redirectId, setRedirectId } = React.useContext(ReleaseStateContext);
    const [redirect, setRedirect] = React.useState(false);
    const [showDialog, setShowDialog] = React.useState(false);
    const [dialogDelete, setDialogDelete] = React.useState(false);
    const [regionalId, setRegionalId] = React.useState(null);
    const [openModalRegional, setOpenModalRegional] = React.useState(false);
    const [regionalList, setRegionalList] = React.useState([]);
    const [fwdConfirmationModal, setFwdConfirmationModal] = React.useState(false);
    const [disabledBtn, setDisabledBtn] = React.useState(false);

    const modalRef = React.useRef();

    const handleRef = (ref) => {
    if (modalRef?.current?.contains(ref?.target)) {
        return;
    }
    setOpenModalRegional(false);
    }

    let _controls = React.useRef({
        area: "Releases",
        class: "release",
        customPermissions: !itemId,
        savePrimary: !!itemId,
        excludePrimary: true,
        urlView: () => setShowDialog(true),
        urlPreview: false,
        urlDuplicate: itemId ? () => duplicateRelease() : false,
        urlForward: itemId ? () => setOpenModalRegional(true) : false,
        urlSave: undefined,
        form: "formReleaseItem",
        urlSend: itemId ? () => setShowSendContent(true) : false,
        urlCancel: () => {
            setReleaseState({
                releaseItem: null,
                urlItemId: null,
                changeInfo: null
            });
            setRedirect(true);
        },
        urlDelete: itemId ? () => setDialogDelete(true) : false
    });

    const initialValue = {
        id: null,
        titulo: "",
        subtitulo: "",
        autores: "",
        idAreaNegocio: 0,
        idPais: 0,
        idRegional: "",
        data: new Date(),
        aprovadoPor: "",
        conteudo: null,
        bloqueado: false,
        language: 1,
        historico: []
    };

    React.useEffect(() => {
        _controls.current = {
            area: "Releases",
            class: "release",
            customPermissions: !itemId,
            savePrimary: !!itemId,
            excludePrimary: true,
            urlView: () => setShowDialog(true),
            urlPreview: false,
            urlDuplicate: itemId ? () => duplicateRelease() : false,
            urlForward: itemId ? () => setOpenModalRegional(true) : false,
            urlSave: undefined,
            form: "formReleaseItem",
            urlSend: itemId ? () => setShowSendContent(true) : false,
            urlCancel: () => {
                setReleaseState({
                    releaseItem: null,
                    urlItemId: null,
                    changeInfo: null
                });
                setRedirect(true);
            },
            urlDelete: itemId ? () => setDialogDelete(true) : false
        }
    }, [redirectId]);

    React.useEffect(() => {
        setShowSendContent(false);
        setReleaseState({
            releaseItem: initialValue,
            urlItemId: null,
            changeInfo: null,
            mailingStatus: []
        });
        setRedirectId(null);

        if (itemId) {
            async function load() {
                let _releaseItem = await getPressItem(globalState.accessToken, itemId, 1)
                    .then(response => {
                        if (response.ok)
                            return response.json()
                    });

                let mailingSent = await getMailingShippingStatusItems(globalState.accessToken, itemId)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        }
                    });
                // _controls.current.customPermissions = itemId ? mailingSent && mailingSent.length === 0 && hasPermissions(2, "Edit", globalState) : hasPermissions(2, "Create", globalState)
                _controls.current.customPermissions = itemId ? hasPermissions(2, "Edit", globalState) : hasPermissions(2, "Create", globalState)
                setReleaseState(releaseState => ({
                    ...releaseState,
                    releaseItem: _releaseItem,
                    urlItemId: itemId,
                    mailingStatus: mailingSent
                }));
            }
            load();
        }
        else {
            setReleaseState({
                releaseItem: initialValue,
                urlItemId: null,
                changeInfo: null,
                mailingStatus: []
            });
        }
    }, [itemId, redirectId]);

    React.useEffect(() => {
      const getRegionalList = async () => {

        let _regional = await getRegional(globalState.accessToken).then(
          (response) => {
            if (response.ok) {
              return response.json();
            }
          }
        );
        setRegionalList(_regional);
      }
      getRegionalList();

      const getRef = () => {
        document.addEventListener("mousedown", handleRef);

        return () => {
          document.removeEventListener("mousedown", handleRef);
        };
      };
      getRef();

    }, []);

    const _export = {
        pdf: true,
        doc: true,
        excel: ""
    }

    const _info = {
        close: () => setShowDialog(false)
    }

    let duplicateRelease = () => {
        duplicatePressItem(globalState.accessToken, itemId, 1)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            })
            .then(result => {
                setRedirect(true);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const handleDuplicateRegional = () => {
        duplicateRegionalRelease()
    }

    let duplicateRegionalRelease = async () => {

        const lang = releaseState.releaseItem.idIdioma ? releaseState.releaseItem.idIdioma === 1 ? 'pt' : 'en' : i18n.language;
        const {newSig, newSigContent} = await getAndMountSignature(globalState, regionalId, lang);
        const regex = /(<td id="signature" contenteditable="true">)([^]+)(<tr id="rteAttachments" contenteditable="false">)/;
        const contentReplaced = `<td id="signature" contenteditable="true">${newSigContent}</td></tr><tr id="rteAttachments" contenteditable="false">`;
        const conteudo = releaseState.releaseItem.conteudo.replace(regex, contentReplaced)

        duplicateForwardPressItem(globalState.accessToken, itemId, 1, regionalId, conteudo)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            })
            .then(result => {
                setFwdConfirmationModal(result)
            })
            .catch(error => {
                console.error(error);
            });
    }

    let deleteRelease = () => {
        deletePressItem(globalState.accessToken, itemId, 1)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            })
            .then(result => {
                setRedirect(true);
            })
            .catch(error => {
                console.error(error);
            })
    }

    return (
        <div>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                redirect ? <Redirect push={true} to='/press/releases' /> :
                    showSendContent ? <SendItem idType={1} idPress={itemId} /> :
                        redirectId ? <Redirect push={true} to={`/press/releasesItem/${redirectId}`} /> :
                            <div>
                                <PermissionRedirect id="77" />

                                <div className="DefaultTop" />
                                <Container className="containerTop">
                                    <div className="topTitleButtons">
                                        <Row>
                                            <div className="col-12">
                                                <div className="title">
                                                    <h1>{itemId ? t('Release:edit_title') : t('Release:create_title')}</h1>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                    <Row>
                                        <div className="col-lg-9 col-12">
                                            {releaseState.releaseItem && releaseState.releaseItem.id &&
                                                <MailingStatus itemId={itemId} redirectId={redirectId} />
                                            }
                                            {releaseState.releaseItem &&
                                                <PressForm
                                                    idAccessArea={2}
                                                    endPointUrl={`${process.env.REACT_APP_API_URL}/_api/Release/UploadPressMedia`}
                                                    storageUrl={`${storageAccUrl}/press/Press%20Releases/`}
                                                    listItemsUrl="/press/releases"
                                                    idType={1}
                                                    itemExists={itemId}
                                                    disabledBtn={disabledBtn}
                                                    setDisabledBtn={setDisabledBtn}
                                                />
                                            }
                                        </div>
                                        <div className="col-lg-3 col-12">
                                            {releaseState.releaseItem &&
                                                <Actions disabled={disabledBtn} controls={_controls.current} />
                                            }
                                            {false &&
                                                <Export controls={_export} />
                                            }
                                            {releaseState.releaseItem && Object.keys(releaseState.releaseItem).length > 0 && (releaseState.releaseItem.historico).length > 0 &&
                                                <ChangeHistory content={releaseState.releaseItem.historico} />
                                            }
                                        </div>
                                    </Row>
                                </Container>
                                <DialogComponent
                                    id="deleteModal"
                                    isModal={true}
                                    visible={dialogDelete}
                                    locale={i18n.language}
                                >
                                    <div>
                                        <div className="divTrash">
                                            <label className="mdl2 mdl2-trash"></label>
                                            <div className="phraseDelete">
                                                <label id="delete">{t('Actions:delete')}</label>
                                                <label id="labelDelete">{t('Actions:confirmDeleteMsg')}</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="footerButtons">
                                            <button onClick={() => setDialogDelete(false)}>{t('Actions:cancel')}</button>
                                            <button onClick={() => deleteRelease()}>{i18n.language ==="pt" ? "Sim" : "Yes"}</button>
                                        </div>
                                    </div>
                                </DialogComponent>
                                <DialogComponent
                                    id="forwardModal"
                                    isModal={true}
                                    visible={openModalRegional}
                                    locale={i18n.language}
                                >
                                    <div>
                                        <div ref={modalRef} className="divCopy">
                                            <div className="scroll-horizontal">
                                              {regionalList.map((regional, index) => (
                                                <div className={regional.id === regionalId ? "selected" : ""} key={index}
                                                  onClick={() => {
                                                    setRegionalId(regional.id);
                                                  }}
                                                >
                                                  <p>{i18n.language ==="pt" ? regional.nomePt : regional.nomeEn}</p>
                                                </div>
                                              ))}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="footerButtons">
                                            <button
                                              onClick={() => {
                                                setRegionalId(null);
                                                setOpenModalRegional(false);
                                              }}>{t('Actions:cancel')}</button>
                                            <button onClick={() => {
                                              handleDuplicateRegional();
                                              setOpenModalRegional(false);
                                            }}>{i18n.language === "pt" ? "Confirmar" : "Confirm"}</button>
                                        </div>
                                    </div>
                                </DialogComponent>
                                <DialogComponent
                                    id="fwdConfirmationModal"
                                    isModal={true}
                                    visible={fwdConfirmationModal}
                                    locale={i18n.language}
                                >
                                    <div>
                                        <div className="phraseConfirm">
                                            <label id="confirm">Duplicado com sucesso</label>
                                            <label id="labelConfirm">O item foi duplicado com sucesso e está disponível <span onClick={
                                                () => {
                                                    setRedirectId(fwdConfirmationModal);
                                                    setFwdConfirmationModal(false);
                                                }
                                            }>aqui</span> </label>
                                        </div>
                                        <hr />
                                        <div className="footerButtons">
                                            <button onClick={() => setFwdConfirmationModal(false)}>Fechar</button>
                                        </div>
                                    </div>
                                </DialogComponent>
                            </div>
            }
        </div>
    )
}
