import { requestAPI } from '../components/utilities/Utils';

export async function getSuppliers(token) {
    return requestAPI("_api/Supplier/GetSuppliers", "GET", "?$orderby=nome", null, token, false);
}

export async function addSupplier(token, body) {
    const raw = JSON.stringify(body);
    return requestAPI("_api/Supplier/AddSupplier", "POST", "", raw, token, false);
}

export async function addOrUpdateSupplier(token, body) {
    const raw = JSON.stringify(body);
    const endPointURL = body.id ? "_api/Supplier/UpdateSupplier" : "_api/Supplier/AddSupplier"
    return requestAPI(endPointURL, "POST", "", raw, token, false);
}

export async function deleteSupplier(token, id) {
    const qstring = `?supplierId=${id}`;
    return requestAPI("_api/Supplier/DeleteSupplier", "DELETE", qstring, null, token, false);
}
