import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { getProfilePhoto } from "../../services/UsersSVC";
import { getCountries, getRegional } from '../../services/SystemSVC';
import { createSpinner, setSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { getSuppliers } from "../../services/SuppliersSVC";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ProfilePhotoComponent } from "../utilities/ProfilePhoto/ProfilePhotoComponent";
import { ProfilePhotoContext } from "../utilities/ProfilePhoto/ProfilePhotoContext";
import MaskedInput from "react-text-mask";

const phoneNumberMask = [
  "+",
  /[0-9]/,
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const UserData = (props) => {

  const dropdownListCountry = React.useRef(null);
  const dropdownListRegional = React.useRef(null);
  const { info, admin, isProfile, hasPermissionToEdit } = props;
  const [hasPermission, setHasPermission] = React.useState(true);
  const [employeeChecked, setEmployeeChecked] = React.useState(true);
  const [suppliersList, setSuppliersList] = React.useState(null);
  const [countrySelected, setCountrySelected] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [globalState] = React.useContext(GlobalStateContext);
  const [countriesList, setCountriesList] = React.useState([]);
  const [regionalSelect, setRegionalSelect] = React.useState([]);
  const [showImageModal, setShowImageModal] = React.useState(false);
  const [photo, setPhoto] = React.useContext(ProfilePhotoContext);

  const { t } = useTranslation();

  const _info = {
    close: () => setShowImageModal(false)
  }

  React.useEffect(() => {
    if (loading) {
      createSpinner({ target: document.getElementById('loadUser') })
      showSpinner(document.getElementById('loadUser'));
      setSpinner({ type: 'Bootstrap' });
    }
  }, [loading]);

  React.useEffect(() => {
    if (typeof hasPermissionToEdit !== "undefined") {
      setHasPermission(hasPermissionToEdit);
    } else {
      setHasPermission(true);
    }
  }, [hasPermissionToEdit]);

  React.useEffect(() => {
    if (info.userId || isProfile) {
      let version = new Date().toISOString();
      let userIdParam = info.userId ? `&userId=${info.userId}` : `&userId=${globalState.userId}`;
      const photoUrl = `${process.env.REACT_APP_API_URL}/_api/Users/GetUserPhoto?token=${globalState.tokenLightWeight}&sizeType=116${userIdParam}&v=${version}`;
      getProfilePhoto(photoUrl, function (response) {
        if (response.size)
          setPhoto({ name: 'photo', size: response.size, type: '.png', url: photoUrl });
      });
    }
    if (info.usuarioTelefone && info.usuarioTelefone.length > 0) {
      setNewPhone(info.usuarioTelefone);
    } else {
      setNewPhone([{
        telefone: "",
        status: 0
      }]);
    }
  }, [info]);

  React.useEffect(() => {
    setLoading(true);
    async function load() {

      if (admin) {
        let suppliers = await getSuppliers(globalState.accessToken)
          .then(response => {
            if (response.ok) {
              return response.json();
            }
          });
        addTooltip(suppliers, "nome");
        setSuppliersList(suppliers);
      }

      let _country = await getCountries(globalState.accessToken)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        });
      let _regional = await getRegional(globalState.accessToken)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        });

      addTooltip(_country, i18n.language ==="pt" ? "nomePt" : "nomeEn");
      setCountriesList(_country);
      addTooltip(_regional, i18n.language ==="pt" ? "nomePt" : "nomeEn");
      setRegionalSelect(_regional);

      hideSpinner(document.getElementById('loadUser'));
      setLoading(false);
    }
    load();
  }, [i18n.language]);

  React.useEffect(() => {
    if (props.isNewItem) {
      setPhoto(null);
    }
  }, [props.isNewItem]);

  React.useEffect(() => {
    if (info.funcionarioVale) {
      info.idFornecedor = null;
    }
  }, [info.funcionarioVale]);

  const changedEmployee = (e) => {
    props.setInfo({
      ...info,
      funcionarioVale: e.checked
    });

    if (e.checked) {
      info.idTipoUsuario = 2; //user
      props.setFormValidationRules('remove', 'supplier');
    }
    else {
      info.idTipoUsuario = 3; //supplier
      props.setFormValidationRules('add', 'supplier', { required: [true, t('Validation:required')] });
    }

    setEmployeeChecked(e.checked);
  }

  const changedSupplier = (e) => {
    let selectedValue = e.itemData ? e.itemData.id : null;
    info.idFornecedor = selectedValue;
    info.idTipoUsuario = selectedValue ? 3 : 2;
  }

  const changedCountry = (e) => {
    let selectedValue = e.itemData ? e.itemData.id : null;
    info.idPais = selectedValue;
    if (info.idPais !== 6) {
      info.idRegional = null;
    }
    setCountrySelected(selectedValue);
  }

  const [newPhone, setNewPhone] = React.useState(
    () => {
      if (info.usuarioTelefone && info.usuarioTelefone.length > 0) {
        return info.usuarioTelefone;
      }
      return [{
        telefone: "",
        status: 0
      }]
    }
  );



  let addNewPhone = () => {
    const phones = newPhone.filter(phone => phone.status !== 2);
    if (phones.length < 3) {
      setNewPhone(
        newPhone => [...newPhone, { telefone: "", status: 0 }]
      )
    }
  }

  const removePhone = (index) => {
    let temp = [...newPhone];
    if (temp[index] !==null) {
      if (temp[index].telefone !=="") {
        let pos = info.usuarioTelefone.map(function (e) { return e.telefone; }).indexOf(temp[index].telefone);
        let phoneFilter = info.usuarioTelefone.filter(findPhone => findPhone.telefone ===temp[index].telefone)[0];

        if (phoneFilter.status !==0) {
          info.usuarioTelefone[pos].status = 2;
        } else {
          temp.splice(pos, 1);
        }
      } else if (temp[index].status ===0) {
        temp.splice(index, 1);
      }
    }
    setNewPhone(temp);
    info.usuarioTelefone = temp;
  }

  const updatePhone = (index, e) => {
    var numberPattern = /\d+/g;

    let value = e.target.value

    if (value) {
      const formattedValue = value.match(numberPattern).join('');

      if (newPhone[index].telefone !==formattedValue && (newPhone[index].status ===3 || newPhone[index].status ===2)) {
        newPhone[index].status = 1;
      }

      newPhone[index].telefone = formattedValue;
    }
    else if (newPhone[index].status !== 0) {
      newPhone[index].status = 2;
    }

    if (newPhone[index].telefone !=="") {
      let phoneFilter = info.usuarioTelefone.filter(findPhone => findPhone.telefone ===newPhone[index].telefone);
      if (phoneFilter.length ===0) {
        info.usuarioTelefone.push(newPhone[index]);
      }
    }
  }

  const dropdownListCreated = () => {
    if (hasPermission) {
      if (dropdownListRegional && dropdownListRegional.current) {
        dropdownListRegional.current.enabled = true;
        dropdownListCountry.current.enabled = true;
      }
    } else {
      if (dropdownListRegional && dropdownListRegional.current) {
        dropdownListRegional.current.enabled = false;
        dropdownListCountry.current.enabled = false;
      }

    }
  };

  const addTooltip = (items, prop) => {
    items && items.length > 0 && items.map(item => item.tooltip = { title: item[prop] });
  }

  const onChangeRegional = (e) => {
    props.setInfo({
      ...info,
      idRegional: e.itemData ? e.itemData.id : null
    });
  };

  return (
    <>
      {loading ? <div id="loadUser" /> :
        <div className="userData">
          <div>
            <div className="top">
              <div className={!hasPermission ? "img disabled" : "img"} onClick={() => setShowImageModal(hasPermission)}>
                {photo && ((photo.changed && photo.b64) || !photo.changed && photo.url) && <img src={(photo.changed && photo.b64) || photo.url} />}
              </div>
              <div className="userName">
                <TextBoxComponent
                  id="name"
                  htmlattributes={{ maxLength: "100" }}
                  name="name"
                  placeholder={t('UserData:name_placeholder')}
                  multiline={true}
                  cssClass="textAreaContainer"
                  value={info && info.nome}
                  blur={(e) => info.nome = e.value}
                  disabled={!hasPermission}
                />
              </div>
              {admin &&
                <>
                  <div className="checkItem">
                    <CheckBoxComponent
                      name="employee"
                      label={t('UserVale:title')}
                      checked={info.funcionarioVale}
                      change={(e) => changedEmployee(e)}
                      disabled={!hasPermission}

                    />
                    <CheckBoxComponent
                      name="whoiswho"
                      label={t('UserVale:whoiswho')}
                      checked={info.incluirWhoIsWho}
                      change={(e) => info.incluirWhoIsWho = e.checked}
                      disabled={!hasPermission}

                    />
                  </div>
                </>
              }
            </div>
            {(admin && (!info.funcionarioVale || !employeeChecked)) &&
              <div className="d-flex flex-row align-items-top">
                <div className="mdl2 mdl2-globe" />
                <div className="input">
                  <label htmlFor="supplier">{t('UserData:supplier')}</label>
                  <DropDownListComponent
                    id="supplier"
                    name="supplier"
                    enabled={admin}
                    dataSource={suppliersList}
                    fields={{ text: "nome", value: "id", htmlattributes: "tooltip" }}
                    placeholder={t('UserVale:supplier')}
                    value={info && info.idFornecedor}
                    change={(e) => changedSupplier(e)}
                    disabled={!hasPermission}
                    readonly={!hasPermission}
                  />
                </div>
              </div>
            }
            <div className="d-flex flex-row align-items-top">
              <div className="mdl2 mdl2-mail" />
              <div className="input">
                <label htmlFor="email">{t('UserData:email')} <label className="e-error">*</label></label>
                <TextBoxComponent
                  id="email"
                  name="email"
                  htmlattributes={{ maxLength: 100 }}
                  placeholder={t('UserVale:email')}
                  value={info && info.email}
                  blur={(e) => info.email = e.value}
                  disabled={!hasPermission}

                />
              </div>
            </div>
            <div className="d-flex flex-row align-items-top">
              <div className="mdl2 mdl2-birthday" />
              <div className="input">
                <label htmlFor="birthday">{t('UserData:birthday')}</label>
                <DatePickerComponent
                  id="birthday"
                  name="birthday"
                  strictMode={true}
                  placeholder={t('UserVale:date')}
                  value={info && info.dataNascimento}
                  change={(e) => info.dataNascimento = e.value ? e.value.toISOString().split('.')[0] : ''}
                  locale={i18n.language}
                  disabled={!hasPermission}

                />
              </div>
            </div>
            <div className="d-flex flex-row align-items-top">
              <div className="mdl2 mdl2-globe" />
              <div className="input">
                <label htmlFor="country">{t('UserData:country')} <label className="e-error">*</label></label>
                <DropDownListComponent
                  id="country"
                  name="country"
                  ref={ref => dropdownListCountry.current = ref}
                  dataSource={countriesList}
                  fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id", htmlattributes: "tooltip" }}
                  placeholder={t('UserVale:country')}
                  value={info.idPais}
                  change={(e) => changedCountry(e)}
                  created={dropdownListCreated}
                  allowFiltering={true}
                />
              </div>
            </div>
            {(countrySelected === 6 || info.idPais === 6) &&
              <div className="d-flex flex-row align-items-top">
                <div className="mdl2 mdl2-pinmap" />
                <div className="input">
                  <label htmlFor="regional">{t('UserData:regional')}</label>
                  <DropDownListComponent
                    id="regional"
                    name="regional"
                    ref={ref => dropdownListRegional.current = ref}
                    dataSource={regionalSelect}
                    fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id", htmlattributes: "tooltip" }}
                    placeholder={t('UserVale:regional')}
                    value={info && info.idRegional}
                    change={onChangeRegional}
                    created={dropdownListCreated}
                    showClearButton

                  />
                </div>
              </div>
            }
            <div className="d-flex flex-row align-items-top">
              <div className="mdl2 mdl2-cellphone" />
              <div className="input phones">
                <label>{t('UserData:phone')}</label>
                {newPhone.filter(phone => phone.status !== 2).length < 3 &&
                  <div className="addLine">
                    <div className="mdl2 mdl2-plus" onClick={() => addNewPhone()} />
                  </div>}
                {newPhone && newPhone.length > 0 &&
                  newPhone.map((item, index) => item.status !== 2 && (
                    <div className="itemPhone" key={index}>
                      <span className="e-control-wrapper e-mask e-input-group e-valid-input">
                        <MaskedInput
                          mask={phoneNumberMask}
                          id="phone"
                          className="numbers e-control e-maskedtextbox e-lib e-input"
                          placeholder={t('UserVale:number')}
                          htmlattributes={{ maxLength: 20 }}
                          type="text"
                          placeholderChar={'\u2000'}
                          value={item ? item.telefone : ''}
                          onChange={(e) => updatePhone(index, e)}
                        />
                      </span>
                      {hasPermission &&
                        <div className="addLine">
                          <div className="mdl2 mdl2-minus" onClick={() => removePhone(index)} />
                        </div>
                      }
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {showImageModal &&
            <ProfilePhotoComponent
              hasPermissionToEdit={hasPermission}
              status={showImageModal}
              toggle={_info}
            />
          }
        </div>
      }
    </>
  )
}
