import React, { useState, useEffect, useContext, useRef } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from "../general/GlobalState";
import { Redirect, Link } from "react-router-dom";
import './home.css';

import { getHorizontalUsers } from '../../services/UsersSVC';

export const WhoIsWhoHome = () => {

    const [redirect, setRedirect] = useState(false);

    const [globalState] = useContext(GlobalStateContext)
    const [users, setUsers] = useState(null);
    const [scrollingLeft, setScrollingLeft] = useState(false);
    const [scrollingRight, setScrollingRight] = useState(true);
    const containerRef = useRef(null);

    const { t } = useTranslation();

    useEffect(() => {
        async function getUsers() {
            const paginationUsers = await getHorizontalUsers(globalState.accessToken, globalState.userId).then(res => {
                if (res.ok)
                    return res.json();
            });

            setUsers(paginationUsers);
        }
        getUsers();
    }, []);

    const whoRedirect = () => {
        setRedirect(true);
    }

    const prevSlide = () => {
        let container = containerRef.current;
        let scrollWidth = container.clientWidth;
        if (container.children && (container.children[0].clientWidth < container.clientWidth))
        scrollWidth /= 2;
        container.scrollLeft -= scrollWidth;
        
        scrollEnd((container.scrollLeft - scrollWidth), container.clientWidth);
    }

    const nextSlide = () => {
        let container = containerRef.current;
        let scrollWidth = container.clientWidth;
        if (container.children && (container.children[0].clientWidth < container.clientWidth))
        scrollWidth /= 2;
        container.scrollLeft += scrollWidth;
        
        scrollEnd((container.scrollLeft + scrollWidth), container.clientWidth);
    }

    const scrollEnd = (position, total) => {
        if(position <= 0) {
            setScrollingLeft(false);
            setScrollingRight(true);
        }
        
        if(position > 0 && position < total) {
            setScrollingLeft(true);
            setScrollingRight(true);
        }

        if(position >= total) {
            setScrollingLeft(true);
            setScrollingRight(false);
        }
    }

    return (
        redirect ? <Redirect push={true} to='/home/whoiswho' /> :
            <div id="WhoIsWhoHome">
                <div className="whoTitle">
                    <span className="mdl2 mdl2-hierarchy" />
                    <h2>{i18n.language ==="pt" ? "Explore o Quem é Quem" : "Explore Who is Who"}</h2>
                </div>
                <div className="whoMain">
                    {scrollingLeft &&
                        <span className="mdl2 mdl2-arrowLeft" onClick={() => prevSlide()} />
                    }

                    <div className="whoMap" ref={containerRef}>
                        <div className="whoSearchDiv" onClick={whoRedirect}>
                            <div className="searchDiv">
                                <span className="mdl2 mdl2-search" />
                            </div>
                            <label>{i18n.language ==="pt" ? "Buscar" : "Search"}</label>
                        </div>
                        {
                            users && users.length > 0 && users.map((i, index) => {
                                return (
                                    <div key={index} className="whoImgDiv">
                                        <Link to={`/profile/${i.id}`} style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginLeft: "36px",
                                            textDecoration: "none"
                                        }}>
                                            <img src={`${process.env.REACT_APP_API_URL}​​/_api/Users/GetUserPhoto?token=${globalState.tokenLightWeight}&userId=${i.id}&sizeType=62`} alt="" />
                                            <label>{i.nome}</label>
                                        </Link>
                                    </div>

                                );
                            })
                        }
                    </div>
                    {users && scrollingRight && <span className="mdl2 mdl2-arrowRight" onClick={() => nextSlide()} />}
                </div>
            </div>
    );
}