import * as React from "react";
import { SharedDocumentsComponent } from './SharedDocumentsComponent';
import { useTranslation } from "react-i18next";

export const InternalVehicles = () => {
    const { t } = useTranslation();

    return <SharedDocumentsComponent 
        permissionId="72" 
        path="Veículos Internos"
        folderManagerTitle={t("EmpCommunication:internal_vehicles")}
        title={t("EmpCommunication:internal_vehicles")} 
    />
}