import * as React from "react";
import i18n from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { TextBoxComponent, MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { update_password } from '../../services/UsersSVC';
import { createSpinner, showSpinner, hideSpinner, setSpinner } from '@syncfusion/ej2-popups';
import { GlobalStateContext } from "../general/GlobalState";
import { LoginStateContext } from '../login/LoginState';

export const ChangePassword = () => {

    const [loginState, setLoginState] = React.useContext(LoginStateContext);
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const [validateReset, setValidateReset] = React.useState(true);
    const [validateConfirm, setValidateConfirm] = React.useState(true);
    const [similarPassword, setSimilarPassword] = React.useState(true);
    const [showSucessText, setShowSucessText] = React.useState(false);
    const [loadingChangePass, setLoadingChangePass] = React.useState(true);

    const { t } = useTranslation();

    const { handleSubmit, register, triggerValidation, watch } = useForm({ nativeValidation: true });
    const htmlPasswordAttributes = { name: "password", type: "password", maxlength: "100" };

    React.useEffect(() => {
        if (i18n.language) {
            setLoadingChangePass(true);
            createSpinner({ target: document.getElementById('loadingChangePass') })
            showSpinner(document.getElementById('loadingChangePass'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [i18n.language]);

    React.useEffect(() => {
        if (!loadingChangePass)
            hideSpinner(document.getElementById('loadingChangePass'));
        setLoadingChangePass(false);
    }, [loadingChangePass]);


    const onSubmit = async (data) => {

        update_password(globalState.accessToken, data.NewPassword)
            .then(response => {
                if (response.ok) {
                    setShowSucessText(true);
                    document.getElementById("new_password").value= ''
                    document.getElementById("change_password").value= ''
                }
            })           
            .catch(error => {
                console.error(error);
                setValidateReset(false);
                setValidateConfirm(false);
            });
    };

    let changePassword = async () => {
        const triggerReset = await triggerValidation("NewPassword");
        const triggerConfirm = await triggerValidation("ConfirmPassword");
        const watchReset = watch("NewPassword");
        const watchConfirm = watch("ConfirmPassword");

        setValidateReset(triggerReset);
        setValidateConfirm(triggerConfirm);

        if (!validateReset || !validateConfirm) {
            setValidateReset(false);
            setValidateConfirm(false);
        }

        if (watchConfirm !==watchReset) {
            setSimilarPassword(false);
        }

        if ((watchConfirm ===watchReset) && validateReset && validateConfirm) {
            handleSubmit(onSubmit)();
        }
    }


    return (
        <>
            <div id="loadingChangePass" /> 
            {!loadingChangePass &&
                <div className="changePassword">
                    <div className="top d-flex flex-row align-items-center">
                        <div className="mdl2 mdl2-lock" />
                        <h2>{t('ChangePassword:title')}</h2>
                    </div>
                    <form className="content d-flex flex-column">
                        <div className="input">
                            <label htmlFor="NewPassword">{t('Reset:first_password')}</label>
                            <TextBoxComponent
                                id="new_password"
                                name="NewPassword"
                                cssClass={!validateReset || !similarPassword ? "e-filled e-error" : "e-filled"}
                                placeholder={t('Reset:new_placeholder')}
                                htmlAttributes={htmlPasswordAttributes}
                                ref={register({
                                    required: true,
                                    pattern: {
                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#.?&])[A-Za-z\d@$!%*#.?&]{8,16}$/i
                                    }
                                })} />
                        </div>
                        <div className="input">
                            <label htmlFor="ConfirmPassword">{t('Reset:second_password')}</label>
                            <TextBoxComponent
                                id="change_password"
                                name="ConfirmPassword"
                                cssClass={!validateConfirm || !similarPassword ? "e-filled e-error" : "e-filled"}
                                placeholder={t('Reset:confirm_placeholder')}
                                htmlAttributes={htmlPasswordAttributes}
                                ref={register({
                                    required: true,
                                    pattern: {
                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#.?&])[A-Za-z\d@$!%*#.?&]{8,16}$/i
                                    }
                                })} />
                        </div>

                        {(!validateReset || !validateConfirm) &&
                            <div className="msgs">
                                <span className="msgError">{t('Reset:invalid_password_message')}</span>
                            </div>
                        }
                        {!similarPassword &&
                            <div className="msgs">
                                <span className="msgError">{t('Reset:different_password_message')}</span>
                            </div>
                        }
                        {showSucessText &&
                            <div className="msgs">
                                <span className="msgSucess">{t('ChangePassword:sucess_changed')}</span>
                            </div>
                        }

                        <ButtonComponent
                            id="changePassword"
                            cssClass="e-outline e-primary"
                            onClick={() => changePassword()}
                            type="button"
                        >{t('ChangePassword:title')}</ButtonComponent>
                    </form>
                </div>
            }
        </>
    )
}