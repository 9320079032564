import * as React from 'react';
import { GlobalStateContext } from "../../general/GlobalState";


const MailingContactsContext = React.createContext();

const MailingContactsProviderGeneral = ({ children }) => {

    const [item, setItem] = React.useState(null);
    const [groupsSelected, setGroupsSelected] = React.useState([]);
    const [generalGroupsSelected, setGeneralGroupsSelected] = React.useState([]);
    const [contactListItems, setContactListItems] = React.useState(null);

    return (
        <MailingContactsContext.Provider
            value={{ item, setItem, groupsSelected, generalGroupsSelected, setGeneralGroupsSelected, setGroupsSelected, contactListItems, setContactListItems }}>
            {children}
        </MailingContactsContext.Provider>
    );
};

export { MailingContactsContext, MailingContactsProviderGeneral };