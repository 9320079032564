import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Buttons } from '../utilities/Buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Toolbar, Filter, Inject, VirtualScroll, Sort, ContextMenu, Page, Resize, Group, AggregateColumnsDirective, AggregateDirective, AggregatesDirective, Aggregate, AggregateColumnDirective } from "@syncfusion/ej2-react-grids";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import { createSpinner, showSpinner, setSpinner, } from '@syncfusion/ej2-react-popups';
import { setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { AdvertisingBudgetContext } from './AdvertisingBudgetContext';
import { uuidv4 } from '../utilities/Utils';
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import GenericModal from '../utilities/MessageModal/GenericModal';
import { hasPermissions } from "../utilities/Utils";
import { GlobalStateContext } from '../general/GlobalState';

export const ControlContent = (props) => {
    const [globalState] = React.useContext(GlobalStateContext);
    const contextMenuItems = ['SortAscending', 'SortDescending', 'FirstPage', 'PrevPage', 'LastPage', 'NextPage'];
    setCurrencyCode('BRL');
    setCulture('pt')
    const { data, options, IDAdBudget } = props;
    const [globalAdBudget, setGlobalAdBudget] = React.useContext(AdvertisingBudgetContext);
    const { t } = useTranslation();
    const gridInstance = React.useRef(null);
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const dialogInstance = React.useRef();
    const [gridData, setGridData] = React.useState([]);
    const [loading, setloading] = React.useState(false);
    const [typeOfForm, settypeOfForm] = React.useState('');
    const [errosAPI, seterrosAPI] = React.useState(null);
    const [modalMessageParams, setmodalMessageParams] = React.useState({ show: false });
    const { register, handleSubmit, errors, reset, setValue, clearError } = useForm({
        mode: "onChange",
        reValidateMode: 'onChange',
        criteriaMode: 'all',
        shouldFocusError: true,
        shouldUnregister: false,
    });


    // EFFECTS

    React.useEffect(() => {
        if (loading) {
            createSpinner({ target: document.getElementById('loading') });
            showSpinner(document.getElementById('loading'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [loading]);

    React.useEffect(() => {
        if (openEditModal ===false) {
            clearError()
            setValue('id', null);
            setValue('contrato.id', null);
            setValue('valorProducao', null);
            setValue('valorMidia', null);
            setValue('honorarios', null);
            setValue('dataPagamento', null);
        }
    }, [openEditModal])

    React.useEffect(() => {
        gridInstance.current.refresh();
    }, [i18n.language]);

    const addContratoNomeToAllItens = (contratos) => {
        return contratos.map((item) => {
            var tipoItem = options.contratosAll.filter((i) => i.value ===item.contrato.id);
            if (tipoItem[0])
                return {
                    ...item,
                    contrato: {
                        ...item.contrato,
                        nome: tipoItem[0].text,
                    },
                };
            else return item;
        });
    }
    React.useEffect(() => {
        //if (globalAdBudget.pagamentos.length > 0 && options.contratos.length > 0) {
        if (globalAdBudget.pagamentos.length > 0) {
            let pagamentos = globalAdBudget.pagamentos;
            pagamentos = addContratoNomeToAllItens(pagamentos)
            pagamentos = pagamentos.filter(item => item.ativo)
            setGridData(pagamentos)
            gridInstance.current.dataSource = pagamentos;
            gridInstance.current.refresh();
        }
    }, [globalAdBudget, options]);


    // CUSTOM FUNCTIONS
    const buildDialogMessage = (type, msg, callBack, okCallback, notOkCallback, confirmType) => {
        if (type ==='confirm') {
            setmodalMessageParams({
                title: i18n.language ==="pt" ? 'Deletar item' : 'Delete item',
                type: "error",
                message: i18n.language ==="pt" ? 'Tem certeza que deseja excluir esse item?' : 'Are you sure you want to delete this item?',
                actionType: 'confirm',
                confirmOkCallback: okCallback,
                confirmNotOkCallback: notOkCallback,
                icon: 'mdl2 mdl2-trash',
                show: true,
            });
        } else if (type ==='close') {
            setmodalMessageParams({
                show: false,
            });
        }
    };



    // TEMPLATES
    const totalDifference = (args) => {
        const calcularPagamentoTotal = () => {
            let sumCostGrid = 0
            if (globalAdBudget && globalAdBudget.pagamentos && globalAdBudget.pagamentos.length > 0) {
                const pagamentos = globalAdBudget.pagamentos.filter(item => item.ativo)
                pagamentos.map((i) => {
                    sumCostGrid += i.valorProducao;
                    sumCostGrid += i.valorMidia;
                    sumCostGrid += i.honorarios ?? 0;
                });
            }
            return sumCostGrid
        }
        const calcularCustoTotal = () => {
            let sumCostGrid = 0
            if (globalAdBudget && globalAdBudget.custos && globalAdBudget.custos.length > 0) {
                const custos = globalAdBudget.custos.filter(item => item.ativo)
                custos.map((i) => {
                    sumCostGrid += i.custoProducao;
                    sumCostGrid += i.custoMidia;
                    sumCostGrid += i.honorarios ?? 0;
                });
            }
            return sumCostGrid
        }
        const calcularDiferenca = () => {
            const totalPag = calcularPagamentoTotal()
            const totalCusto = calcularCustoTotal()
            return totalCusto - totalPag
        }

        return (
            <span className="sumClass">{i18n.language ==="pt" ? "Saldo" : "Balance"}: {toBRL(calcularDiferenca())}</span>
        )
    }

    const totalTemplateSum = (args) => {
        const calcularPagamentoTotal = () => {
            let sumCostGrid = 0
            if (globalAdBudget && globalAdBudget.pagamentos && globalAdBudget.pagamentos.length > 0) {
                const pagamentos = globalAdBudget.pagamentos.filter(item => item.ativo)
                pagamentos.map((i) => {
                    sumCostGrid += i.valorProducao;
                    sumCostGrid += i.valorMidia;
                    sumCostGrid += i.honorarios ?? 0;
                });
            }
            return sumCostGrid

        }
        return (
            <span className="sumClass">Total Final: {toBRL(calcularPagamentoTotal())}</span>
        )
    }

    const templateSum = (args) => {
        return (
            <span className="sumClass">{i18n.language ==="pt" ? "Total" : "Total"}: {toBRL(args.Sum)}</span>
        );
    }

    React.useEffect(() => {
        if (gridInstance.current)
            gridInstance.current.refresh();
    }, [i18n.language]);

    const editTemplate = (args) => {
        return (

            <div className="editItems">
                {
                    hasPermissions(32, "Admin", globalState) && <span
                        style={{ cursor: 'pointer', padding: '10px', marginRight: '10px' }}
                        onClick={() => {
                            openEdit(args);
                        }}
                        className="mdl2 mdl2-pencil"
                    />
                }
                {
                    hasPermissions(32, "Admin", globalState) && <span
                        style={{ cursor: 'pointer', padding: '10px' }}
                        onClick={() => {
                            const handleNotOK = () => {
                                buildDialogMessage('close')
                            }
                            const handleOK = (args) => {
                                updateItem(args, true)
                                buildDialogMessage('close')
                            }
                            buildDialogMessage('confirm', null, null, () => { handleOK(args) }, handleNotOK, '')
                        }}
                        className="mdl2 mdl2-trash"
                    />
                }
            </div>
        );
    };


    const dateFormat = (date) => {
        return <span>{new Date(date).toLocaleDateString(i18n.language)}</span>;
    };

    const toBRL = valor => {
        return Intl.NumberFormat(i18n.language, {
            style: 'currency',
            currency: 'BRL',
        }).format(valor)

        // return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    const formaValorProducaoColumnTemplate = (data) => {
        return <span>{data.valorProducao && toBRL(data.valorProducao)}</span>;
    };

    const formaValorMidiaColumnTemplate = (data) => {
        return <span>{data.valorMidia && toBRL(data.valorMidia)}</span>;
    };

    const formaHonorariosColumnTemplate = (data) => {
        return <span>{data.honorarios && toBRL(data.honorarios)}</span>;
    };

    const contratoTemplate = (data) => {
        return <span>{data.contrato.nome}</span>;
    };


    // MODAL DIALOG
    const openEdit = (args) => {
        settypeOfForm('EDIT');
        clearError()
        setValue('id', args.id);
        setValue('contrato.id', args.contrato.id);
        setValue('valorProducao', args.valorProducao);
        setValue('valorMidia', args.valorMidia);
        setValue('honorarios', args.honorarios);
        setValue('dataPagamento', args.dataPagamento);
        setOpenEditModal(true);
        dialogInstance.current.show();
    };

    const clearModal = () => {
        setValue('id', null);
        setValue('contrato.id', null);
        setValue('valorProducao', null);
        setValue('valorMidia', null);
        setValue('honorarios', null);
        setValue('dataPagamento', null);
    }



    // BUSINESS
    const createCustItem = (data) => {
        setloading(true)
        const obj = {
            ativo: true,
            id: uuidv4(),
            statusOperacao: 0,
            contrato: {
                id: data.contrato.id,
                nome: options.contratos.filter(
                    (i) => i.value ===data.contrato.id,
                )[0].text,
            },
            dataPagamento: data.dataPagamento,
            honorarios: data && data.honorarios ? data.honorarios : 0,
            valorMidia: data && data.valorMidia ? data.valorMidia : 0,
            valorProducao: data && data.valorProducao ? data.valorProducao : 0
        };

        let pagamentosArr = [];
        setGlobalAdBudget((prevState) => {
            pagamentosArr = addContratoNomeToAllItens(prevState.pagamentos);
            pagamentosArr.unshift(obj);
            return {
                ...prevState,
                pagamentos: pagamentosArr,
            };
        });

        gridInstance.current.dataSource = pagamentosArr.filter(item => item.ativo);
        gridInstance.current.refresh();
        setloading(false)
        dialogInstance.current.hide()
        setOpenEditModal(false)
    };
    const updateItem = (data, isLogicalDelete) => {
        setloading(true)

        const contratoSelected = options.contratos.filter((i) => i.value ===data.contrato.id,
        )[0]

        const obj = {
            id: data.id,
            ativo: isLogicalDelete ? false : true,
            statusOperacao: IDAdBudget ? 1 : data.statusOperacao,
            contrato: {
                id: data.contrato.id,
                nome: contratoSelected ? contratoSelected.text : data.contrato.nome,
            },
            dataPagamento: data.dataPagamento,
            honorarios: data && data.honorarios ? data.honorarios : 0,
            valorMidia: data && data.valorMidia ? data.valorMidia : 0,
            valorProducao: data && data.valorProducao ? data.valorProducao : 0,
        };

        let pagamentosArr = [];
        setGlobalAdBudget((prevState) => {
            pagamentosArr = addContratoNomeToAllItens(prevState.pagamentos);
            pagamentosArr = pagamentosArr.map((item) => {
                if (item.id ===obj.id) { item = obj; }
                return item;
            });
            return {
                ...prevState, pagamentos: pagamentosArr
            };
        });

        gridInstance.current.dataSource = pagamentosArr.filter(item => item.ativo);
        gridInstance.current.refresh();
        gridInstance.current.refresh();
        setloading(false)
        dialogInstance.current.hide()
        setOpenEditModal(false)

    };

    React.useEffect(() => {
        console.log(errors);
    }, [errors]);



    // FORM
    const onSubmit = (data) => {
        if (typeOfForm ==='NEW') createCustItem(data);
        else if (typeOfForm ==='EDIT') updateItem(data);
        reset();
    };
    const onError = (errors, e) => console.log(errors, e);

    const onCalendarOpen = (args) => {
        args.popup.offsetX=300;
        args.popup.offsetY=-303;
    };

    const onCreateDDLContract = (e) => {
        let eleSelected = parseInt(e.popup.element.querySelector('.e-list-item.e-active').getAttribute('data-value'));
        let elements = e.popup.element.querySelectorAll('.e-list-item');

        if (options.contratos.length > 0) {
            elements.forEach((ele) => {
                let eleId = parseInt(ele.getAttribute('data-value'));
                let eleExists = options.contratos.some(item => item.value === eleId);

                if (!eleExists) {
                    if (eleSelected === eleId) {
                        ele.classList.add('e-disabled');
                        ele.classList.add('e-overlay');
                        ele.classList.remove('e-hide');
                    } else {
                        ele.classList.add('e-hide');
                    }
                }
            })
        } else {
            elements.forEach((ele) => {
                let eleId = parseInt(ele.getAttribute('data-value'));

                if (eleSelected === eleId) {
                    ele.classList.add('e-disabled');
                    ele.classList.add('e-overlay');
                    ele.classList.remove('e-hide');
                } else {
                    ele.classList.add('e-hide');
                }
            })
        }
    };

    return (
        <div>

            <div className="topContent caixa">
                <div className="flex ">
                    <span className="mdl2 mdl2-control" />
                    <h2>{i18n.language ==="pt" ? "Controle de Pagamentos" : "Payment Control"}</h2>
                </div>
                <div className="divider"></div>
                {
                    hasPermissions(32, "Admin", globalState) && <div className="flex">
                        <button
                            id='btnNew'
                            onClick={() => {
                                settypeOfForm('NEW');
                                setOpenEditModal(true)
                                dialogInstance.current.show();
                            }}>
                            <span className="pr-10 mdl2 mdl2-plus" />
                            {i18n.language ==='pt' ? 'Novo Pagamento' : 'New Payment'}
                        </button>
                    </div>
                }

            </div>

            <DialogComponent
                id="modalEditAnnual"
                isModal={true}
                visible={openEditModal}
                ref={dialogInstance}
                close={clearModal}
                showCloseIcon={true}
                locale={i18n.language}
            >
                <form
                    className="newAnnual"
                    id="addEditDialogPagamentos"
                    onSubmit={handleSubmit(onSubmit, onError)}
                >
                    {/* ID */}
                    <div className="input">
                        <label htmlFor="id">ID</label>
                        <div className="e-float-input">
                            <TextBoxComponent
                                id="id"
                                name="id"
                                enabled={false}
                                ref={register({
                                    required: false,
                                })}
                                type="number"
                            // value={modalData && modalData.id}
                            />
                            {errors.id && <p className="error">{t('Validation:required')}</p>}
                        </div>
                    </div>

                    <div className={'row'}>
                        {/* contrato */}
                        <div className="input col-sm-6 col-12">
                            <label htmlFor="tipoMidia">
                                {i18n.language ==='pt' ? 'Contrato *' : 'Contract Type *'}
                            </label>
                            <div className="e-float-input">
                                <DropDownListComponent
                                    id="contrato"
                                    name="contrato.id"
                                    ref={register({ required: true })}
                                    dataSource={options && (typeOfForm === 'EDIT' ? options.contratosAll : options.contratos)}
                                    fields={{ text: 'text', value: 'value' }}
                                    locale={i18n.language}
                                    // value={modalData && modalData.contrato && modalData.contrato.id}
                                    placeholder={
                                        i18n.language ==='pt'
                                            ? 'Selecione o contrato'
                                            : 'Select contract'
                                    }
                                    open={typeOfForm === 'EDIT' ? onCreateDDLContract : null}
                                />
                                {errors.contrato && (
                                    <p className="error">{t('Validation:required')}</p>
                                )}
                            </div>
                        </div>
                        {/* valorProducao */}
                        <div className="input col-sm-6 col-12">
                            <label htmlFor="valorProducao">{i18n.language ==='pt' ? 'Valor de Produção ' : 'Production Value '}</label>
                            <div className="e-float-input">
                                <NumericTextBoxComponent
                                    name="valorProducao"
                                    id="valorProducao"
                                    format="c2"
                                    placeholder={
                                        i18n.language ==='pt'
                                            ? 'Digite o valor (apenas números)'
                                            : 'only numbers'
                                    }
                                    htmlAttributes={{ maxLength: 23 }}

                                    // value={modalData && modalData.valorProducao}
                                    ref={register({
                                        required: false,
                                        maxLength: 23,
                                        minLength: 2,
                                        min: 0
                                    })}
                                />
                                {/* {errors.valorProducao &&
                                    errors.valorProducao.type === 'required' && (
                                        <p className="error">{t("Validation:required")}</p>
                                    )} */}
                                {errors.valorProducao &&
                                    errors.valorProducao.type === 'maxLength' && (
                                        <p className="error">{t("Validation:exceeded")}</p>
                                    )}
                                {errors.valorProducao &&
                                    errors.valorProducao.type === 'min' && (
                                        <p className="error">{t("Validation:min")}</p>
                                    )}
                                {errors.valorProducao &&
                                    errors.valorProducao.type === 'minLength' && (
                                        <p className="error">{t("Validation:exceeded")}</p>
                                    )}
                                {errosAPI &&
                                    errosAPI.errors &&
                                    errosAPI.errors.valorProducao && (
                                        <p className="error">{errosAPI.errors.valorProducao}</p>
                                    )}
                            </div>
                        </div>
                    </div>

                    <div className={'row'}>
                        {/* valorMidia */}
                        <div className="input col-sm-6 col-12">
                            <label htmlFor="valorMidia">{i18n.language ==='pt' ? 'Valor de Mídia ' : 'Midia Value '}</label>
                            <div className="e-float-input">
                                <NumericTextBoxComponent
                                    name="valorMidia"
                                    id="valorMidia"
                                    format="c2"
                                    placeholder={
                                        i18n.language ==='pt'
                                            ? 'Digite o custo de mídia (apenas números)'
                                            : 'only numbers'
                                    }
                                    htmlAttributes={{ maxLength: 23 }}
                                    // value={modalData && modalData.valorMidia}
                                    ref={register({
                                        required: false,
                                        maxLength: 23,
                                        minLength: 2,
                                        min: 0
                                    })}
                                />
                                {/* {errors.valorMidia &&
                                    errors.valorMidia.type === 'required' && (
                                        <p className="error">{t("Validation:required")}</p>
                                    )} */}
                                {errors.valorMidia &&
                                    errors.valorMidia.type === 'maxLength' && (
                                        <p className="error">{t("Validation:exceeded")}</p>
                                    )}
                                {errors.valorMidia &&
                                    errors.valorMidia.type === 'min' && (
                                        <p className="error">{t("Validation:min")}</p>
                                    )}
                                {errors.valorMidia &&
                                    errors.valorMidia.type === 'minLength' && (
                                        <p className="error">{t("Validation:exceeded")}</p>
                                    )}
                                {errosAPI && errosAPI.errors && errosAPI.errors.valorMidia && (
                                    <p className="error">{errosAPI.errors.valorMidia}</p>
                                )}
                            </div>
                        </div>
                        {/* Honorários */}
                        <div className="input col-sm-6 col-12">
                            <label htmlFor="honorarios">{i18n.language ==='pt' ? 'Honorários' : 'Fees'}</label>
                            <div className="e-float-input">
                                <NumericTextBoxComponent
                                    name="honorarios"
                                    id="honorarios"
                                    format="c2"
                                    placeholder={
                                        i18n.language ==='pt'
                                            ? 'Digite o valor (apenas números)'
                                            : 'only numbers'
                                    }
                                    htmlAttributes={{ maxLength: 23 }}
                                    // value={modalData && modalData.honorarios}
                                    ref={register({
                                        required: false,
                                        maxLength: 23,
                                        minLength: 2,
                                        min: 0,
                                    })}
                                />

                                {errors.honorarios &&
                                    errors.honorarios.type === 'required' && (
                                        <p className="error">{t("Validation:required")}</p>
                                    )}
                                {errors.honorarios &&
                                    errors.honorarios.type === 'maxLength' && (
                                        <p className="error">{t("Validation:exceeded")}</p>
                                    )}
                                {errors.honorarios &&
                                    errors.honorarios.type === 'min' && (
                                        <p className="error">{t("Validation:min")}</p>
                                    )}
                                {errors.honorarios &&
                                    errors.honorarios.type === 'minLength' && (
                                        <p className="error">{t("Validation:exceeded")}</p>
                                    )}
                                {errosAPI && errosAPI.errors && errosAPI.errors.honorarios && (
                                    <p className="error">{errosAPI.errors.honorarios}</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {/* Data Pagamento */}
                        <div className="input col-6">
                            <label htmlFor="dataPagamento">{i18n.language ==='pt' ? 'Data Pagamento *' : 'Payment Date *'}</label>
                            <div className="e-float-input">
                                <DatePickerComponent
                                    id="dataPagamento"
                                    name="dataPagamento"
                                    ref={register({
                                        required: true,
                                        valueAsDate: true,
                                    })}
                                    placeholder={t('Press:date_placeholder')}
                                    allowEdit={false}
                                    serverTimezoneOffset='0'
                                    open={onCalendarOpen}
                                />
                                {errors.dataPagamento && (
                                    <p className="error">{t('Validation:required')}</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="btn-wrapper">
                        <button
                            type="submit"
                            form="addEditDialogPagamentos"
                            disabled={loading}
                            className={'e-control e-btn e-lib btn-modal'}
                        >
                            {t('Actions:save')}
                        </button>
                    </div>
                </form>
                {loading && <div style={{ zIndex: '99999' }} id="loading" />}
            </DialogComponent>

            <GridComponent
                dataSource={gridData}
                contextMenuItems={contextMenuItems}
                filterSettings={{ type: 'CheckBox' }}
                allowPaging={true}
                allowSorting={true}
                allowResizing={true}
                sortSettings={{
                    columns: [{ field: 'dataPagamento', direction: 'Descending' }]
                }}
                allowFiltering={true}
                locale={i18n.language}
                ref={gridInstance}
            >
                <ColumnsDirective>
                    <ColumnDirective
                        headerText="ID"
                        field="id"
                        width="150" />
                    <ColumnDirective
                        headerText={t('MeasurementReport:column_agreement')}
                        field="contrato.nome"
                        template={contratoTemplate}
                        width="150" />
                    <ColumnDirective
                        headerText={t('MeasurementReport:column_valueProduction')}
                        // template={formaValorProducaoColumnTemplate}
                        format={'C2'}
                        field="valorProducao"
                        width="200" />
                    <ColumnDirective
                        headerText={t('MeasurementReport:column_valueMedia')}
                        // template={formaValorMidiaColumnTemplate}
                        format={'C2'}
                        field="valorMidia"
                        width="170" />
                    <ColumnDirective
                        headerText={t('MeasurementReport:column_fees')}
                        // template={feesTemplate}
                        // template={templateHonorarios}
                        format={'C2'}
                        field="honorarios"
                        width="150"
                    ></ColumnDirective>
                    <ColumnDirective
                        headerText={t('MeasurementReport:column_payday')}
                        field="dataPagamento"
                        width="170"
                        type={'date'}
                        filter={{ type: 'Menu' }}
                        format={i18n.language === "pt" ? "d/M/y" : "M/d/y"}
                    // template={formatDateColumnTemplate}
                    />
                    {
                        hasPermissions(32, "Admin", globalState) &&
                        <ColumnDirective
                            headerText={t('ManageInternalAreas:edit')}
                            template={(args) => editTemplate(args)}
                            width="120" />
                    }
                </ColumnsDirective>

                <AggregatesDirective>
                    <AggregateDirective>
                        <AggregateColumnsDirective>
                            <AggregateColumnDirective field='valorProducao' type='Sum' footerTemplate={templateSum} />
                            <AggregateColumnDirective field='valorMidia' type='Sum' footerTemplate={templateSum} />
                            <AggregateColumnDirective field='honorarios' type='Sum' footerTemplate={templateSum} />
                        </AggregateColumnsDirective>
                    </AggregateDirective>

                    <AggregateDirective>
                        <AggregateColumnsDirective>
                            <AggregateColumnDirective field='valorProducao' type='Sum' footerTemplate={totalTemplateSum} />
                        </AggregateColumnsDirective>
                    </AggregateDirective>

                    <AggregateDirective>
                        <AggregateColumnsDirective>
                            <AggregateColumnDirective field='valorProducao' type='Sum' footerTemplate={totalDifference} />
                        </AggregateColumnsDirective>
                    </AggregateDirective>
                </AggregatesDirective>

                <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Edit, Toolbar, Aggregate]} />
            </GridComponent >

            {/* MODAL EXCLUSÃO */}
            {modalMessageParams.show && <GenericModal params={modalMessageParams} />}

        </div>
    );
}
