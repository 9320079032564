import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../../general/GlobalState";
import { MailingContactsContext } from "./MailingContactsContext";
import { Container, Row } from "reactstrap";
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Sort, ContextMenu, Page, Resize, Inject } from "@syncfusion/ej2-react-grids";
import { createSpinner, setSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { Buttons } from "../../utilities/Buttons";
import { ContactsList } from "./ContactsList";
import { getImportMailingContactsTemplate, importMailingContacts, getMailingContactItems } from "../../../services/MailingGeneralSVC";
import { getPressMediaTypes, getCountries, getStates, getCities } from "../../../services/SystemSVC";
import * as signalR from "@microsoft/signalr";
import '../../mailing/mailing.css'
import PermissionRedirect from "../../utilities/PermissionRedirect/PermissionRedirect";

const uploadIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="61.1" height="62.1" viewBox="0 0 61.1 62.1"><defs><style>.a{fill:#dbdbdb;}.b{fill:#007e7a;}</style></defs><g transform="translate(-690 -235)"><path class="a" d="M715.8,285.9H704.7V297H701V285.9H690v-3.7h11.1V271.1h3.7v11.1h11.1v3.7ZM741.6,238a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H712.2v-3.7H738V279.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V241.7H704.9v22.1h-3.7V238Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V279.5Z"/><path class="b" d="M717.8,282.9H706.7V294H703V282.9H692v-3.7h11.1V268.1h3.7v11.1h11.1v3.7ZM743.6,235a3.194,3.194,0,0,1,1.4.3,8.341,8.341,0,0,1,1.2.8,3.6,3.6,0,0,1,.8,1.2,3.194,3.194,0,0,1,.3,1.4v28.6a4.145,4.145,0,0,0,.3,1.6,5.388,5.388,0,0,0,.7,1.3l.9,1.2a14.64,14.64,0,0,1,.9,1.3,6.69,6.69,0,0,1,.7,1.7,7.315,7.315,0,0,1,.3,2.2v13.8a3.194,3.194,0,0,1-.3,1.4,8.345,8.345,0,0,1-.8,1.2,2.55,2.55,0,0,1-1.2.8,3.194,3.194,0,0,1-1.4.3H714.2v-3.7H740V276.6a6.872,6.872,0,0,1,.3-2.2,8.652,8.652,0,0,1,.7-1.7,14.615,14.615,0,0,1,.9-1.3l.9-1.2a5.388,5.388,0,0,0,.7-1.3,4.145,4.145,0,0,0,.3-1.6V238.7H706.9v22.1h-3.7V235Zm3.7,41.5a4.122,4.122,0,0,0-.1-1.2,4.178,4.178,0,0,0-.4-1l-.6-.9a6.471,6.471,0,0,0-.8-.9c-.3.3-.5.6-.8.9l-.6.9a3,3,0,0,0-.4,1,4.122,4.122,0,0,0-.1,1.2v13.8h3.7V276.5Z"/></g></svg>';
let gridFeedbackRef;

let userPermission;

export const GeneralContacts = (props) => {
    let sidebarRef;
    let uploaderContactsRef;
    let buttonImportRef;

    const { t } = useTranslation();
    const [globalState] = React.useContext(GlobalStateContext);
    const mailingState = React.useContext(MailingContactsContext);
    const [redirect, setRedirect] = React.useState(false);
    const [gridInstance, setGridInstance] = React.useState(null);
    const [importResults, setImportResults] = React.useState([]);
    const [buttonDisabled, setButtonDisabled] = React.useState(true);

    React.useEffect(() => {
        let globalPermissions = globalState.permissions;
        userPermission = globalPermissions.filter((i) => i.idAreaAcesso === 62).map((i) => (i.permission));
    }, [globalState]);

    const _controls = {
        permit: userPermission ? userPermission : null,
        export: "",
        // import: () => sidebarRef.toggle(),
        new: () => {
            mailingState.setItem(null);
            setRedirect(true);
        },
        back: "",
    };

    const contextMenuItems = ['SortAscending', 'SortDescending', 'FirstPage', 'PrevPage', 'LastPage', 'NextPage'];

    React.useEffect(() => {
        let loadingEl = document.getElementById('loading');
        if (loadingEl)
            createSpinner({ target: loadingEl });

        // if (gridFeedbackRef) {
        //     const connection = new signalR.HubConnectionBuilder()
        //         .withUrl(`${process.env.REACT_APP_AZFUNCTION_URL}/api/ImportMailingContacts`)
        //         .configureLogging(signalR.LogLevel.Information)
        //         .withAutomaticReconnect()
        //         .build();

        //     connection.on('importMailingContacts', importFeedbackReceiver);
        //     connection.onclose(() => console.log('disconnected'));
        //     connection.start().then(() => console.log('connected')).catch(console.error);
        // }
    }, []);

    React.useEffect(() => {
        if (mailingState.gridContactsList && mailingState.gridContactsList.element)
            mailingState.gridContactsList.showSpinner();
        loadContactsItems();
    }, []);

    const loadContactsItems = () => {
        // const qString = "?$select=id, nome, nomeEmpresa, cargo, tipoPublico";
        const qString = "?$orderby=nome%20asc"
        getMailingContactItems(globalState.accessToken, qString).then(response => {
            if (response.ok)
                response.json().then(result => {
                    mailingState.setContactListItems(result);
                });
        });
    }

    // const importFeedbackReceiver = feedbackItem => {
    //     if (feedbackItem.IsError)
    //         setImportResults(importResults => ([...importResults, feedbackItem]));
    //     else {
    //         if (feedbackItem.Progress === 100) {
    //             document.getElementById("btnImportContact").style.display = "inline-block";
    //             hideSpinner(document.getElementById('loading'));
    //             document.getElementById('percentage').innerHTML = "";
    //             loadContactsItems();
    //         }
    //         else {
    //             document.getElementById("btnImportContact").style.display = "none";
    //             showSpinner(document.getElementById('loading'));
    //             document.getElementById('percentage').innerHTML = feedbackItem.Progress + "%";
    //         }
    //     }
    // }

    // const uploadTemplate = () => {
    //     document.getElementsByClassName("e-file-select-wrap")[0].querySelector("button").click();
    //     return false;
    // }'

    const onFileUploadSucceeded = (e) => {
        setButtonDisabled(e.operation ==="remove");
    }

    const onFileRemove = () => {
        setButtonDisabled(true);
    };

    const sidebarCreated = () => {
        sidebarRef.element.style.visibility = '';
    }

    const downloadTemplate = () => {
        getImportMailingContactsTemplate(globalState.tokenLightWeight)
            .then(response => response.blob()).then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "template.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    const importContacts = () => {
        gridFeedbackRef.dataSource = [];
        if (uploaderContactsRef.filesData.length)
            importMailingContacts(globalState.accessToken, uploaderContactsRef.filesData[0].rawFile)
                .then().catch(console.error);
    }


    React.useEffect(() => {
        if (gridFeedbackRef)
            gridFeedbackRef.refresh();
    }, [i18n.language]);

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
                redirect ?
                    <Redirect push={true} to='/press/generalMailing/contactsItem' />
                    :
                    <>
                        <PermissionRedirect id="62" />
                        <div className="DefaultTop" />
                        <Container className="containerTop">
                            <div className="topTitleButtons">
                                <Row>
                                    <div className="col-sm-8 col-12">
                                        <div className="title">
                                            <h1>{t('GeneralContacts:title')}</h1>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-12">
                                        <div className="buttons">
                                            <Buttons controls={_controls} />
                                        </div>
                                    </div>
                                </Row>
                            </div>
                            <Row>
                                <div className="col-12">
                                    <ContactsList gridRef={setGridInstance} />
                                </div>
                            </Row>
                        </Container>
                        <SidebarComponent
                            id="sidebar" ref={sidebar => sidebarRef = sidebar}
                            style={{ visibility: "hidden" }}
                            created={sidebarCreated}
                            type="Push"
                            position="Right"
                            closeOnDocumentClick={true}
                        >
                            <div className="sidebar-content">
                                <div id="dropArea" className="upload">
                                    <div className="new">
                                        <span dangerouslySetInnerHTML={{ __html: uploadIcon }} />
                                        <p>{t('Photo:uploadMessage')} <span onClick={() => console.log('teste')}>{t('Photo:uploadMessageLastPart')}</span></p>
                                    </div>
                                </div>
                                <UploaderComponent
                                    id="uploadContacts"
                                    ref={uploader => uploaderContactsRef = uploader}
                                    allowedExtensions=".xls, .xlsx"
                                    dropArea={document.getElementById("dropArea")}
                                    locale={i18n.language}
                                    multiple={false}
                                    removing={onFileRemove}
                                    selected={onFileUploadSucceeded}
                                >
                                </UploaderComponent>
                                <div className="btnDownload">
                                    <ButtonComponent cssClass='e-outline inport' onClick={downloadTemplate}>
                                        {t('Contacts:msg_download_template')}
                                    </ButtonComponent>
                                </div>
                                {/* <div className="btnImport">
                                    <ButtonComponent ref={buttonComponent => buttonImportRef = buttonComponent} id="btnImportContact" disabled={buttonDisabled} cssClass='e-primary new' onClick={importContacts}>
                                        {t('Contacts:import_contacts')}
                                    </ButtonComponent>
                                </div> */}
                                <div id="loading" className="loadBanner" ></div>
                                <span id="percentage"></span>
                                <GridComponent
                                    id="gridFeedback"
                                    ref={gridComponent => gridFeedbackRef = gridComponent}
                                    contextMenuItems={contextMenuItems}
                                    dataSource={importResults}
                                    filterSettings={{ type: 'Menu' }}
                                    allowPaging={true}
                                    allowSorting={true}
                                    locale={i18n.language}
                                    allowFiltering={true}
                                    allowResizing={true}
                                    pageSettings={{ pageSize: 10 }}
                                // queryCellInfo={renderCellGroup}
                                // rowSelected={onGridRowSelected}
                                >
                                    <ColumnsDirective>
                                        <ColumnDirective
                                            id=""
                                            field="ErrorMessage"
                                            headerText={t('Contacts:grid_message')}
                                            allowResizing={true}
                                        ></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject services={[Resize, Sort, ContextMenu, Filter, Page]} />
                                </GridComponent>
                            </div>
                        </SidebarComponent>
                    </>

            }
        </>
    )
}
