import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { GlobalStateContext } from '../general/GlobalState';
import { useTranslation } from 'react-i18next';
import { Internal } from './InternalContent';

import '../manageInternalAreas/manageInternalAreas.css';

export const ManageInternalAreas = () => {
  const [globalState] = React.useContext(GlobalStateContext);

  const { t } = useTranslation();

  return (
    <>
      {globalState.accessToken === null ? (
        <Redirect push={true} to="/login" />
      ) : (
        <>
          <div className="DefaultTop" />
          <Container className="containerTop">
            <Row>
              <div className="col-12">
                <Internal />
              </div>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};
