import * as React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState'
import { useTranslation } from 'react-i18next';
import { GridComponent, ColumnsDirective, ColumnDirective, ExcelExport, Edit, Toolbar, Filter, Inject, Sort, ContextMenu, Page, Resize, ColumnChooser } from "@syncfusion/ej2-react-grids";
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent, AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import i18n from "i18next";
import { requestAPI } from '../utilities/Utils';
import { getPressItems } from "../../services/PressSVC";
import { getAllBusinessAreas, getCountries, getRegional } from "../../services/SystemSVC";
import { users } from "../../services/UsersSVC";
import { Buttons } from "../utilities/Buttons";

import {
  createSpinner,
  showSpinner,
  setSpinner,
  hideSpinner
} from '@syncfusion/ej2-react-popups';

import './Historico.css';

let userPermission;

export const Historico = () => {

  const [globalState] = React.useContext(GlobalStateContext);
  const [isVisible, setIsVisible] = React.useState(true);
  const [filteredHistoryItems, setFilteredHistoryItems] = React.useState({
    conteudo: "",
    dataInicio: "",
    dataFim: "",
    areaNegocio: "",
    regional: "",
    autor: "",
    pais: "",
    itemData: {
      press: '',
      tipo: ''
    }
  });
  const [historyItems, setHistoryItems] = React.useState({});
  const [allHistory, setAllHistory] = React.useState(null);
  const [dataValue, setDataValue] = React.useState(null);
  const [pressValue, setPressValue] = React.useState(null);
  const [businessAreaValue, setBusinessAreaValue] = React.useState(null);
  const [countryValue, setCountryValue] = React.useState(null);
  const [regionalValue, setRegionalValue] = React.useState(null);
  const [autorValue, setAutorValue] = React.useState(null);
  const [fillBusinessAreaValue, setFillBusinessAreaValue] = React.useState([]);
  const [fillCountryValue, setFillCountryValue] = React.useState([]);
  const [fillRegionalValue, setFillRegionalValue] = React.useState([]);
  const [fillAutorValue, setFillAutorValue] = React.useState([]);
  const [gridInstance, setGridInstance] = React.useState(null);

  let history = useHistory();

  const { t } = useTranslation();

  React.useEffect(() => {
    let globalPermissions = globalState.permissions;
    userPermission = globalPermissions.filter((i) => i.accessArea === "Releases").map((i) => (i.permission));
  }, [globalState]);

  const _controls = {
    permit: userPermission ? userPermission : null,
    export: () => exportRelease(),
    import: "",
    new: "",
    back: "",
  };

  const exportRelease = () => {
    if (gridInstance) {
      let lang = i18n.language;
      let gridInstanceExport = gridInstance;
      gridInstanceExport.dataSource.map(x => {
        x.data = new Date(x.data).toLocaleDateString(lang);
        return x;
      });
      gridInstanceExport.excelExport();
    }
  }

  const contextMenuItems = ['SortAscending', 'SortDescending', 'FirstPage', 'PrevPage', 'LastPage', 'NextPage'];
  const editOptions = { allowEditing: false };
  const toolbarOptions = ['ColumnChooser'];
  const hostURL = process.env.REACT_APP_API_URL + '/';

  const changePosition = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  }

  let dateFormat = (args) => {
    return <span>{new Date(args.data).toLocaleDateString(i18n.language)}</span>;
  };

  const showLoading = (id) => {
    createSpinner({ target: document.getElementById(`loading-*${id}*`) });
    showSpinner(document.getElementById(`loading-*${id}*`));
    setSpinner({ type: 'Bootstrap' });
  }

  const hideLoading = (id) => {
    hideSpinner(document.getElementById(`loading-*${id}*`));
  }

  const downloadPDF = (id, cod) => {
    showLoading(id);
    requestAPI("_api/PressLegacy/DownloadPressLegacyPDF"
      , "GET"
      , `?itemId=${id}`
      , null
      , globalState.accessToken).then(async (res) => {
        const blob = await res.blob();
        const newBlob = new Blob([blob], { type: 'application/pdf' });
        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;
        window.open(blobUrl, '_blank')

        hideLoading(id);
      });
  }

  const formatType = (args) => {
    let text = "";
    switch (args.tipo || args.idTipo) {
      case 1:
        text = t('Historico:release');
        break;
      case 2:
        text = t('Historico:notas');
        break;
      case 3:
        text = t('Historico:posicionamento');
        break;
      case 4:
        text = t('Historico:qa');
        break;
      case 6:
        text = t('Historico:avisoWebcast');
        break;
      case 7:
        text = t('Historico:avisoPautas');
        break;
    }
    return (
      <>
        {`${text}`}
      </>
    );
  }

  const pressItem = (args) => {
    switch (args) {
      case 1:
        return 'releasesItem';
      case 2:
        return 'statementsItem';
      case 3:
        return 'positionpaperItem';
      case 4:
        return 'qaItem';
      case 6:
        return 'webcastnoticesItem';
      case 7:
        return 'assignmentNoticesItem';
      default:
        return '';
    }
  }

  let archivePDF = (args) => {
    var id = args.id;
    return (
      <div style={{ position: "relative" }}>
        {!args.idTipo &&
          <>
            <ButtonComponent
              id="pdf"
              cssClass="e-primary e-flat"
              onClick={() => downloadPDF(id, args.cod)}
            >
              <div className="mdl2 mdl2-pdf" />
            </ButtonComponent>
            <div style={{ zIndex: '99999' }} id={`loading-*${id}*`} />
          </>
        }
        {args.idTipo &&
          <>
            <ButtonComponent
              id="link-item"
              cssClass="e-primary e-flat"
              // onClick={() => history.replace(`/press/${pressItem(args.idTipo)}/${args.id}`)}
              onClick={() => window.open(`/press/${pressItem(args.idTipo)}/${args.id}`,"_blank")}
            >
              <div className="mdl2 mdl2-vale" />
            </ButtonComponent>
            <div style={{ zIndex: '99999' }} id={`loading-*${id}*`} />
          </>
        }
      </div>
    );
  }

  const gridActionBegin = args => {
    if (
      args.requestType === "filterchoicerequest" ||
      args.requestType === "filtersearchbegin"
    ) {
      args.filterChoiceCount = 14000;
    }
  }

  const resultValidationTitle = (_filteredHistory) => {
    if (filteredHistoryItems.conteudo) {
      return _filteredHistory.filter(item => {
        if (item?.titulo) {
          if (item?.titulo?.toLowerCase().includes(filteredHistoryItems?.conteudo?.toLowerCase())) {
            return item;
          }
        } else {
          return item;
        }
      });
    }
    return _filteredHistory;
  }

  const resultValidationPress = (_filteredHistory) => {
    if (filteredHistoryItems.itemData?.tipo) {
      return _filteredHistory.filter(item => {
        if (item?.tipo || item?.idTipo) {
          if (item?.tipo === filteredHistoryItems?.itemData?.tipo || item?.idTipo === filteredHistoryItems?.itemData?.tipo) {
            return item;
          }
        } else {
          return item;
        }
      });
    }
    return _filteredHistory;
  }

  const resultValidationBusinessArea = (_filteredHistory) => {
    if (filteredHistoryItems?.areaNegocio) {
      return _filteredHistory.filter(item => {
        if (item?.areaNegocio) {
          if (item?.areaNegocio === filteredHistoryItems?.areaNegocio) {
            return item;
          }
        } else {
          return item;
        }
      });
    }
    return _filteredHistory;
  }

  const resultValidationCountry = (_filteredHistory) => {
    if (filteredHistoryItems?.pais) {
      return _filteredHistory.filter(item => {
        if (item?.pais) {
          if (item?.pais === filteredHistoryItems?.pais) {
            return item;
          }
        } else {
          return item;
        }
      });
    }
    return _filteredHistory;
  }

  const resultValidationRegional = (_filteredHistory) => {
    if (filteredHistoryItems?.regional) {
      return _filteredHistory.filter(item => {
        if (item?.regional && item?.regional !== "") {
          if (item?.regional === filteredHistoryItems?.regional) {
            return item;
          }
        } else {
          return item;
        }
      });
    }
    return _filteredHistory;
  }

  const resultValidationAutor = (_filteredHistory) => {
    if (filteredHistoryItems?.autor) {
      return _filteredHistory.filter(item => {
        if (item?.autor && item?.autor !== "") {
          if (item?.autor === filteredHistoryItems?.autor) {
            return item;
          }
        } else {
          return item;
        }
      });
    }
    return _filteredHistory;
  }

  const resultValidationDataInicio = (_filteredHistory) => {
    if (filteredHistoryItems.dataInicio) {
      let dataInicio = new Date(filteredHistoryItems?.dataInicio.trim());
      return _filteredHistory.filter(x => {
        return dataInicio.getTime() <= new Date(x.data).getTime();
      });
    }
    return _filteredHistory;
  }

  const resultValidationDataFim = (_filteredHistory) => {
    if (filteredHistoryItems.dataFim) {
      let dataFim = new Date(filteredHistoryItems?.dataFim.trim());
      return _filteredHistory.filter(x => {
        return dataFim.getTime() >= new Date(x.data).getTime();
      });
    }
    return _filteredHistory;
  }

  const filteredHistory = () => {
    if (
      filteredHistoryItems?.conteudo
      || filteredHistoryItems?.itemData?.tipo
      || filteredHistoryItems?.dataInicio
      || filteredHistoryItems?.dataFim
      || filteredHistoryItems?.areaNegocio
      || filteredHistoryItems?.pais
      || filteredHistoryItems?.regional
      || filteredHistoryItems?.autor
    ) {
      let _filteredHistory = historyItems;
      _filteredHistory = resultValidationTitle(_filteredHistory);
      _filteredHistory = resultValidationPress(_filteredHistory);
      _filteredHistory = resultValidationBusinessArea(_filteredHistory);
      _filteredHistory = resultValidationCountry(_filteredHistory);
      _filteredHistory = resultValidationRegional(_filteredHistory);
      _filteredHistory = resultValidationAutor(_filteredHistory);
      _filteredHistory = resultValidationDataInicio(_filteredHistory);
      _filteredHistory = resultValidationDataFim(_filteredHistory);
      setAllHistory(_filteredHistory);
      setIsVisible(false);
    } else {
      setAllHistory(historyItems);
      setIsVisible(false);
    }
  }

  const handleChangeDate = (ev) => {
    setDataValue(ev.value)

    let sDate = ev.startDate ? `${ev.startDate.toISOString().split('T')[0]}T00:00:00` : null;
    let eDate = ev.endDate ? `${ev.endDate.toISOString().split('T')[0]}T23:59:59` : null;

    setFilteredHistoryItems(
      filteredHistoryItems => ({
        ...filteredHistoryItems,
        dataInicio: sDate,
        dataFim: eDate
      })
    )
  }

  const handleChangePress = (ev) => {
    const { itemData, value } = ev;
    setPressValue(value);

    setFilteredHistoryItems(
      filteredHistoryItems => ({
        ...filteredHistoryItems,
        itemData: {
          press: itemData?.press || "",
          tipo: itemData?.tipo || "",
        }
      })
    )
  }

  const handleChangeBusinessArea = (ev) => {
    const { value, itemData } = ev;
    setBusinessAreaValue(value);

    setFilteredHistoryItems(
      filteredHistoryItems => ({
        ...filteredHistoryItems,
        areaNegocio: itemData?.nomePt
      })
    )
  }

  const handleChangeCountry = (ev) => {
    const { value, itemData } = ev;
    setCountryValue(value);

    setFilteredHistoryItems(
      filteredHistoryItems => ({
        ...filteredHistoryItems,
        pais: itemData?.nomePt
      })
    )
  }

  const handleChangeRegional = (ev) => {
    const { value, itemData } = ev;
    setRegionalValue(value);

    setFilteredHistoryItems(
      filteredHistoryItems => ({
        ...filteredHistoryItems,
        regional: itemData?.nomePt
      })
    )
  }

  const handleChangeAutor = (ev) => {
    const { value } = ev;
    setAutorValue(value);

    setFilteredHistoryItems(
      filteredHistoryItems => ({
        ...filteredHistoryItems,
        autor: value
      })
    )
  }

  React.useEffect(() => {
    async function load() {
      let [
        resultRequest,
        _businessAreas,
        _countries,
        _regional,
        _users
      ] = await Promise.all([
        requestAPI(
          "_api/PressLegacy/GetPressLegacyItems",
          "GET",
          "",
          null,
          globalState.accessToken
        ),
        getAllBusinessAreas(globalState.accessToken)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        }),
        getCountries(globalState.accessToken)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        }),
        getRegional(globalState.accessToken)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        }),
        users(globalState.accessToken)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
      ]);

      let [
        getReleaseItems,
        getStatementsItems,
        getPositionPaperItems,
        getQAItems,
        getWebcastNoticeItems,
        getAssignmentNoticeItems
      ] = await Promise.all([
        getPressItems(globalState.accessToken, 1, ""),
        getPressItems(globalState.accessToken, 2, ""),
        getPressItems(globalState.accessToken, 3, ""),
        getPressItems(globalState.accessToken, 4, ""),
        getPressItems(globalState.accessToken, 6, ""),
        getPressItems(globalState.accessToken, 7, ""),
      ]);

      let oldHistoryItems = ([
        ...await resultRequest.json(),
        ...await getReleaseItems.json(),
        ...await getStatementsItems.json(),
        ...await getPositionPaperItems.json(),
        ...await getQAItems.json(),
        ...await getWebcastNoticeItems.json(),
        ...await getAssignmentNoticeItems.json(),
      ])

      oldHistoryItems.map(i => {
        if (i.autores) {
          i['autor'] = i['autores'];
          delete i['autores'];
          return i;
        }
      });

      oldHistoryItems.map(i => {
        switch (i.regional) {
          case 'ES':
            return i.regional = 'Espírito Santo';
          case 'MG':
            return i.regional = 'Minas Gerais';
          case 'MA':
            return i.regional = 'Maranhão';
          case 'PA':
            return i.regional = 'Pará';
          default:
            return;
        }
      })

      let newCountries = _countries;
      newCountries = changePosition(_countries, 5, 0)

      setFillBusinessAreaValue(_businessAreas);
      setFillCountryValue(newCountries);
      setFillRegionalValue(_regional);
      setFillAutorValue(_users);
      setHistoryItems(oldHistoryItems);
      setAllHistory(oldHistoryItems);
    }
    load();
  }, []);

  React.useEffect(() => {
    if (gridInstance)
      gridInstance.refresh();
  }, [i18n.language]);

  const pressData = [
    { press: i18n.language === "pt" ? 'Release' : 'Releases', tipo: 1 },
    { press: i18n.language === "pt" ? 'Notas' : 'Statements', tipo: 2 },
    { press: i18n.language === "pt" ? 'Posicionamento' : 'Position Paper', tipo: 3 },
    { press: i18n.language === "pt" ? 'Q & A' : 'Q & A', tipo: 4 },
    { press: i18n.language === "pt" ? 'Aviso de Webcast' : 'Webcast Notices', tipo: 6 },
    { press: i18n.language === "pt" ? 'Aviso de Pautas' : 'Assignment Notices', tipo: 7 },
  ]

  return (
    <>
      {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
        <>
          <div className="DefaultTop" />
          <Container className="containerTop">
            <div className="topTitleButtons">
              <Row>
                <div className="col-sm-8 col-12">
                  <div className="title">
                    <h1>{t('Historico:title')}</h1>
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                    <div className="buttons">
                        <Buttons controls={_controls} />
                    </div>
                </div>
              </Row>
            </div>
            <Row>
              <div className="col-12">
                <div className={`busca ${isVisible ? "aberto" : ""}`}>
                  <div className="filtroUsuarios d-flex">
                    <div className="SearchTitle">
                      <span className="mdl2 mdl2-search"></span>
                      <p>{t('Releases:search_text')}</p>
                    </div>
                    <div className="searchUsers">
                      <div className="e-input-in-wrap">
                        <TextBoxComponent
                          className="e-input"
                          placeholder={t('Historico:placeholder_search')}
                          locale={i18n.language}
                          value={filteredHistoryItems && filteredHistoryItems.conteudo ? filteredHistoryItems.conteudo : ""}
                          blur={(e) => setFilteredHistoryItems(
                            filteredHistoryItems => ({
                              ...filteredHistoryItems,
                              conteudo: e.value
                            })
                          )}
                        />
                      </div>
                    </div>
                    <div className="btnBusca d-flex">
                      <div className="btnFilter">
                        <ButtonComponent
                          className="e-outline filter"
                          cssClass='e-primary filter'
                          onClick={() => setIsVisible(!isVisible)}>
                          <span className="mdl2 mdl2-filter"></span>
                          {t('Releases:filters')}
                        </ButtonComponent>
                      </div>
                      <div className="btnSearch">
                        <ButtonComponent cssClass='e-primary search'
                          onClick={filteredHistory}
                        >
                          {t('Releases:search')}
                        </ButtonComponent>
                      </div>
                    </div>
                  </div>
                  {isVisible &&
                    <div className="filtrosOcultos seis d-flex">
                      <DateRangePickerComponent
                        id="date"
                        cssClass="e-filled"
                        placeholder={t('Releases:date')}
                        locale={i18n.language}
                        showClearButton
                        value={dataValue ? dataValue : ""}
                        change={(ev) => handleChangeDate(ev)}
                      />
                      <DropDownListComponent
                        cssClass="e-filled"
                        placeholder={t('Historico:placeholder_press')}
                        dataSource={pressData}
                        showClearButton
                        allowFiltering={true}
                        fields={{ value: 'press' }}
                        locale={i18n.language}
                        value={pressValue ? pressValue : ""}
                        change={handleChangePress}
                      />
                      <DropDownListComponent
                        cssClass="e-filled"
                        placeholder={t('Historico:placeholder_businessArea')}
                        dataSource={fillBusinessAreaValue}
                        showClearButton
                        allowFiltering={true}
                        fields={{ text: 'nomePt', value: 'id' }}
                        locale={i18n.language}
                        value={businessAreaValue ? businessAreaValue : ""}
                        change={handleChangeBusinessArea}
                      />
                      <DropDownListComponent
                        cssClass="e-filled"
                        placeholder={t('Historico:placeholder_country')}
                        dataSource={fillCountryValue}
                        allowFiltering={true}
                        showClearButton
                        fields={{ text: 'nomePt', value: 'id' }}
                        locale={i18n.language}
                        value={countryValue ? countryValue : ''}
                        change={handleChangeCountry}
                      />
                      <DropDownListComponent
                        cssClass="e-filled"
                        placeholder={t('Historico:placeholder_regional')}
                        dataSource={fillRegionalValue}
                        allowFiltering={true}
                        showClearButton
                        fields={{ text: 'nomePt', value: 'id' }}
                        locale={i18n.language}
                        value={regionalValue ? regionalValue : ''}
                        change={handleChangeRegional}
                      />
                      {/* <AutoCompleteComponent
                        id="autor"
                        autofill={true}
                        cssClass="e-filled"
                        placeholder={t('Historico:column_autor')}
                        dataSource={fillAutorValue}
                        fields={{ value: 'nome' }}
                        showClearButton
                        value={autorValue ? autorValue : ''}

                        change={handleChangeAutor}
                      /> */}
                    </div>
                  }
                </div>
                <GridComponent
                  id="logsErros"
                  ref={(e) => setGridInstance(e)}
                  dataSource={allHistory}
                  editSettings={editOptions}
                  contextMenuItems={contextMenuItems}
                  toolbar={toolbarOptions}
                  filterSettings={{ type: 'CheckBox' }}
                  pageSettings={{ pageSize: 8 }}
                  allowPaging={true}
                  allowSorting={true}
                  allowFiltering={false}
                  allowExcelExport={true}
                  locale={i18n.language}
                  allowTextWrap={true}
                  allowResizing={true}
                  showColumnChooser={true}
                  actionBegin={gridActionBegin}
                >
                  <ColumnsDirective>
                    <ColumnDirective headerText={t('Historico:column_id')} field="id" allowEditing={false} isPrimaryKey={true} width="80"></ColumnDirective>
                    <ColumnDirective headerText={t('Historico:column_cod')} field="cod" allowEditing={false} width="120"></ColumnDirective>
                    <ColumnDirective headerText={t('Historico:column_data')} field="data" format={i18n.language === "pt" ? "dd/MM/yyyy" : "MM/dd/yyyy"} template={dateFormat} filterItemTemplate={dateFormat} allowEditing={false} width="100"></ColumnDirective>
                    <ColumnDirective headerText={t('Historico:column_titulo')} field="titulo" allowEditing={false} width="150"></ColumnDirective>
                    <ColumnDirective headerText={t('Historico:column_areanegocio')} field="areaNegocio" allowEditing={false} width="150"></ColumnDirective>
                    <ColumnDirective headerText={t('Historico:column_regional')} field="regional" allowEditing={false} width="150"></ColumnDirective>
                    <ColumnDirective headerText={t('Historico:column_pais')} field="pais" allowEditing={false} width="150"></ColumnDirective>
                    <ColumnDirective headerText={t('Historico:column_autor')} field="autor" allowEditing={false} width="150"></ColumnDirective>
                    <ColumnDirective headerText={t('Historico:column_tipo')} field="tipo" template={formatType} filterItemTemplate={formatType} allowEditing={false} width="100"></ColumnDirective>
                    <ColumnDirective headerText={t('Historico:column_pdf')} field="PDF" template={archivePDF} allowEditing={false} allowFiltering={false} width="80"></ColumnDirective>
                  </ColumnsDirective>
                  <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Edit, Toolbar, ColumnChooser, ExcelExport]} />
                </GridComponent>

              </div>
            </Row>
          </Container>
        </>
      }

    </>
  );
}
