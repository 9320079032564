import { requestAPI } from '../components/utilities/Utils';

export async function addVideo(token, body) {
    return requestAPI("_api/CoreVideos/AddVideo", "POST", "", body, token, false);
}

export async function updateVideo(token, body) {
    return requestAPI("_api/CoreVideos/UpdateVideo", "POST", "", body, token, false);
}

function getFilters({ filters, loadMore, pending, approved, paginationUrl }) {
    let fullFilterArray = [];
    let fullFilter = '';

    if (filters) {

        if (filters.titulo) fullFilterArray.push(`contains(tolower(titulo), '${filters.titulo.trim().toLowerCase()}')`);
        if (filters.enviadoPor) fullFilterArray.push(`enviadoPor/id eq ${filters.enviadoPor.id}`);
        if (filters.categoria) fullFilterArray.push(`nucleoVideoCategoria/id eq ${filters.categoria.id}`);

        if (filters.enviadoEm && filters.enviadoEm.startDate) {
            let startDay = ("0" + filters.enviadoEm.startDate.getDate()).slice(-2);
            let startMonth = ("0" + (filters.enviadoEm.startDate.getMonth() + 1)).slice(-2);
            let startPeriodDtStr = `${filters.enviadoEm.startDate.getFullYear()}-${startMonth}-${startDay}T00:00:00.000Z`;
            fullFilterArray.push(`enviadoEm gt ${startPeriodDtStr}`);

            let endDay = ("0" + filters.enviadoEm.endDate.getDate()).slice(-2);
            let endMonth = ("0" + (filters.enviadoEm.endDate.getMonth() + 1)).slice(-2);
            let endPeriodDtStr = `${filters.enviadoEm.endDate.getFullYear()}-${endMonth}-${endDay}T23:59:59.999Z`;
            fullFilterArray.push(`enviadoEm lt ${endPeriodDtStr}`);
        }

        if (filters.aprovadoEm && filters.aprovadoEm.startDate) {
            let startDay = ("0" + filters.aprovadoEm.startDate.getDate()).slice(-2);
            let startMonth = ("0" + (filters.aprovadoEm.startDate.getMonth() + 1)).slice(-2);
            let startPeriodDtStr = `${filters.aprovadoEm.startDate.getFullYear()}-${startMonth}-${startDay}T00:00:00.000Z`;
            fullFilterArray.push(`aprovadoEm gt ${startPeriodDtStr}`);

            let endDay = ("0" + filters.aprovadoEm.endDate.getDate()).slice(-2);
            let endMonth = ("0" + (filters.aprovadoEm.endDate.getMonth() + 1)).slice(-2);
            let endPeriodDtStr = `${filters.aprovadoEm.endDate.getFullYear()}-${endMonth}-${endDay}T23:59:59.999Z`;
            fullFilterArray.push(`aprovadoEm lt ${endPeriodDtStr}`);
        }

        if (filters.pais) fullFilterArray.push(`pais/id eq ${filters.pais.id}`);
        if (filters.regional) fullFilterArray.push(`regional/id eq ${filters.regional.id}`);
        if (filters.aprovadoPor) fullFilterArray.push(`aprovadoPor/id eq ${filters.aprovadoPor.id}`);
        if (filters.nucleoVideoStatus) fullFilterArray.push(`nucleoVideoStatus/id eq ${filters.nucleoVideoStatus.id}`);
        if (filters.nomeArquivoVideo) fullFilterArray.push(`contains(tolower(nomeArquivoVideo), '${filters.nomeArquivoVideo.trim().toLowerCase()}')`);

    }

    if (pending) {
        fullFilterArray.push(`nucleoVideoStatus/id ne 2`);
    } else if (approved) {
        fullFilterArray.push(`nucleoVideoStatus/id eq 2`);
    }

    if (fullFilterArray.length > 0) {
        fullFilter = `filter(${fullFilterArray.join(' and ')})`;
    }

    let withFilters = fullFilter && !paginationUrl ? `?$apply=${fullFilter}&$orderby=enviadoEm desc` : "?$orderby=enviadoEm desc";

    return withFilters;
}

export async function approveReproveVideo(token, body) {
    return requestAPI("/_api/CoreVideos/ApproveReproveVideo", "POST", "", body, token);
}

export async function getVideosFour({ token, filters, paginationUrl }) {

    const defaultUrl = "_api/CoreVideoPaginationFour";

    const filterParams = getFilters({
        filters,
        paginationUrl,
        pending: true,
    });

    return requestAPI(paginationUrl ? paginationUrl : defaultUrl, "GET", !paginationUrl ? filterParams : "", null, token, false, false, !!paginationUrl);
}

export async function getVideosEight({ token, filters, paginationUrl }) {

    const defaultUrl = "_api/CoreVideoPaginationEight";

    const filterParams = getFilters({
        filters,
        paginationUrl,
        approved: true
    });

    return requestAPI(paginationUrl ? paginationUrl : defaultUrl, "GET", !paginationUrl ? filterParams : "", null, token, false, false, !!paginationUrl);
}

export async function getVideos({ token, filters, loadMore, pending, approved, url }) {

    const filterParams = getFilters({
        filters,
        loadMore,
        pending,
        approved
    });

    return requestAPI("_api/CoreVideos/GetVideosItems", "GET", filterParams, null, token, false);
}

export async function downloadDigitalMediaAttachment(token, id) {
    // ?token=${globalState.accessToken}&IdOrGuid=${tempGuid ? tempGuid : currentItem && currentItem.id}`
    return requestAPI("_api/CoreVideos/GetVideoContent", "GET", `?token=${token}&IdOrGuid=${id}`, null, token, null, true);
}


export async function addVideoStorage(token, guid, body) {
    const param = '?IdOrGuid=' + guid;
    var form_data = new FormData();
    for (var key in body) {
        form_data.append(key, body[key]);
    }
    return requestAPI("_api/CoreVideos/UploadVideoAttachmentChunks", "POST", param, form_data, token, true, false);
}

export async function removeVideoStorage(token, guid) {
    const param = '?videoId=' + guid;
    return requestAPI("/_api/CoreVideos/DeleteVideo", "POST", param, null, token, false);
}

export async function getApprovalUsers(token) {
    return requestAPI("_api/CoreVideos/GetApprovingUsers", "GET", "", null, token, false);
}
