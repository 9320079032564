import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Inject,
    Filter,
    Page
} from '@syncfusion/ej2-react-grids';
import { DropDownListComponent, ChangeEventArgs, ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { getSuppliers, deleteSupplier } from "../../services/SuppliersSVC";
import { getCountries, getRegional } from "../../services/SystemSVC";
import "../users/users.css";
import "../suppliers/suppliers.css";
import { createSpinner, setSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';

export const SuppliersLists = (props) => {
    let gridComponentRef;
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const [immediateLeader, setImmediateLeader] = React.useState([]);
    const [comboCountries, setComboCountries] = React.useState([]);
    const [comboRegional, setComboRegional] = React.useState([]);
    const [gridItems, setGridItems] = React.useState(null);
    const [placeHolder, setPlaceHolder] = React.useState();
    const [isVisible, setIsVisible] = React.useState(false);
    const [allSuppliers, setAllSuppliers] = React.useState();
    const [loadingSuppliers, setLoadingSuppliers] = React.useState(true);
    const [filter, setFilter] = React.useState({});

    const { t } = useTranslation();


    const [suppliers, setSuppliers] = React.useState([]);

    React.useEffect(() => {
        if (loadingSuppliers && document.getElementById('loadSuppliers')) {
            createSpinner({ target: document.getElementById('loadSuppliers') })
            showSpinner(document.getElementById('loadSuppliers'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [loadingSuppliers]);

    const buildDropDownItems = (data, objMap) => {
        if (data.length > 0)
          return data.map((i) => {
            return {
              text: i[objMap.textKey],
              value: i[objMap.value],
            };
          });
        else
          return data
      }

    React.useEffect(() => {
        setLoadingSuppliers(true);
        async function load() {
            let _suppliers = await getSuppliers(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json() } });
            let _country = await getCountries(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json() } });
            let _region = await getRegional(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json() } });

            if (_suppliers) {
                setSuppliers(_suppliers);
                setAllSuppliers(_suppliers);
            }

            setComboCountries(buildDropDownItems(_country,{ textKey: i18n.language ==='pt' ? 'nomePt' : 'nomeEn', value: 'id' }));
            setComboRegional(buildDropDownItems(_region,{ textKey: i18n.language ==='pt' ? 'nomePt' : 'nomeEn', value: 'id' }));
            setPlaceHolder(t('Suppliers:search_message'));

            if (document.getElementById('loadSuppliers')) {
                hideSpinner(document.getElementById('loadSuppliers'));
                setLoadingSuppliers(false);
            }
        }
        load();
    }, [i18n.language]);

    const inputChange = e => {
        setFilter({ ...filter, [e.event.currentTarget.id]: e.value });
    }
    const dropdownlistChange = e => {
        setFilter({ ...filter, [e.element.id]: e.value });
    }

    //Search

    const filterSuppliers = () => {
        setIsVisible(false)
        let listSuppliers = allSuppliers;
        //Por nome
        if (filter.name)
            listSuppliers = listSuppliers.filter(item => item.nome.toLowerCase().includes(filter.name.toLowerCase()));

        //Por País
        if (filter.country)
            listSuppliers = listSuppliers.filter(item => item.pais.id === filter.country);

        //Por Regional
        if (filter.regional)
            listSuppliers = listSuppliers.filter(item => item.regional && item.regional.id === filter.regional);

        gridComponentRef.dataSource = listSuppliers;
    }

    //fim Search

    const suppliersName = (args) => {
        return (
            <span className="nomeFornecedores" onClick={() => props.edit(args.id)}>{args.nome}</span>
        )
    }

    const contextMenuItems = ['SortAscending', 'SortDescending', 'FirstPage', 'PrevPage', 'LastPage', 'NextPage'];

    //Deletar fornecedor
    const deleteRow = async (item) => {
        await deleteSupplier(globalState.accessToken, item.id);
        let _listSuppliers = await getAllSuppliers();
        await setSuppliers(_listSuppliers);
    }

    //Consultar fornecedores
    const getAllSuppliers = async () => {
        let _suppliers = await getSuppliers(globalState.accessToken)
            .then(response => { if (response.ok) { return response.json() } });
        return _suppliers;
    }

    React.useEffect(() => {
        if (gridComponentRef)
            gridComponentRef.refresh();
    }, [i18n.language]);

    return (
        <>
            <div className={`busca ${isVisible ? "aberto" : ""}`}>
                {loadingSuppliers ? <div id="loadSuppliers" /> :
                    <div className="filtroUsuarios d-flex">
                        <div className="SearchTitle">
                            <span className="mdl2 mdl2-search"></span>
                            <p>{t('Suppliers:search')}</p>
                        </div>
                        <div className="searchUsers">
                            <div className="e-input-in-wrap">
                                <TextBoxComponent
                                    id="name"
                                    fields={{ text: 'nome', value: 'id' }}
                                    fields={{ text: (i18n.language ==="pt") ? "nomePt" : "nomeEn", value: "value" }}
                                    change={inputChange}
                                    placeholder={placeHolder}
                                    lacale={i18n.language}
                                />
                            </div>
                        </div>
                        <div className="btnBusca d-flex">
                            <div className={isVisible ? "btnFilter active" : "btnFilter"}>
                                <ButtonComponent
                                    cssClass='e-primary e-outline'
                                    onClick={() => setIsVisible(!isVisible)}>
                                    <span className="mdl2 mdl2-filter"></span>
                                    {t('Suppliers:filter_button')}
                                </ButtonComponent>
                            </div>
                            <div className="btnSearch">
                                <ButtonComponent
                                    onClick={filterSuppliers}
                                    cssClass='e-primary search'>
                                    {t('Suppliers:search_button')}

                                </ButtonComponent>
                            </div>
                        </div>
                    </div>
                }
                {isVisible &&
                    <div className="filtrosOcultos dois d-flex">
                        <DropDownListComponent
                            cssClass="e-filled"
                            id="country"
                            dataSource={comboCountries}
                            // fields={{ text: (i18n.language ==="pt") ? "nomePt" : "nomeEn", value: "id" }}
                            fields={{ text: 'text', value: 'value' }}
                            change={dropdownlistChange}
                            placeholder={t('Suppliers:country')}
                            value={filter.country}
                            allowFiltering={true}
                            showClearButton={true}
                        />
                        <DropDownListComponent
                            cssClass="e-filled"
                            id="regional"
                            dataSource={comboRegional}
                            fields={{ text: 'text', value: 'value' }}
                            // fields={{ text: (i18n.language ==="pt") ? "nomePt" : "nomeEn", value: "id" }}
                            change={dropdownlistChange}
                            placeholder={t('Suppliers:regional')}
                            value={filter.regional}
                            showClearButton={true}
                        />
                    </div>
                }
            </div>
            <div className="listSuppliers">
                {(suppliers) && loadingSuppliers ? <div id="loadSuppliers" className="loadSuppliersList" /> :
                    <GridComponent
                        id='gridcomp'
                        ref={gridComponent => gridComponentRef = gridComponent}
                        dataSource={!loadingSuppliers && suppliers}
                        allowPaging={true}
                        pageSettings={{ pageSize: 10 }}
                        filterSettings={{ type: 'Menu' }}
                        allowSorting={true}
                        contextMenuItems={contextMenuItems}
                        locale={i18n.language}>

                        <ColumnsDirective >
                            <ColumnDirective
                                headerText={t('Suppliers:name_column')}
                                template={suppliersName}
                                width="350" />
                            <ColumnDirective
                                headerText={t('Suppliers:description_column')}
                                field={i18n.language ==='pt' ? "descricao.nomePt" : "descricao.nomeEn"}
                                width="350" />
                            <ColumnDirective
                                headerText={t('Suppliers:access_column')}
                                field={i18n.language ==='pt' ? "areaDICI.nomePt" : "areaDICI.nomeEn"}
                                width="200" />
                            <ColumnDirective
                                headerText={t('Suppliers:type_column')}
                                field={i18n.language ==='pt' ? "tipoFornecedor.nomePt" : "tipoFornecedor.nomeEn"}
                                width="100" />

                        </ColumnsDirective>
                        <Inject services={[Page, Filter]} />
                    </GridComponent>
                }
            </div>
        </>
    );
}
