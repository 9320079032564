import * as React from 'react';
import { Row } from 'reactstrap';
import { GlobalStateContext } from '../general/GlobalState';
import { useTranslation } from 'react-i18next';
import { Buttons } from '../utilities/Buttons';
import GenericModal from '../utilities/MessageModal/GenericModal';
import {
  TextBoxComponent,
  MaskedTextBoxComponent,
} from '@syncfusion/ej2-react-inputs';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Edit,
  Toolbar,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  ContextMenu,
  Page,
  Resize,
} from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import {
  getManageAnnualBudget,
  addManageAnnualBudget,
  updateManageAnnualBudget,
} from './manageAnnualBudgetSVC';
import {
  createSpinner,
  showSpinner,
  setSpinner,
} from '@syncfusion/ej2-react-popups';
import { setCulture, setCurrencyCode } from '@syncfusion/ej2-base';

import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { hasPermissions } from '../utilities/Utils';
import PermissionRedirect from '../utilities/PermissionRedirect/PermissionRedirect';
import { useModal } from '../../hooks/Modal';

let userPermission;

export const Annual = (props) => {
  // setCulture(i18n.language);
  setCurrencyCode('BRL');
  const [globalState] = React.useContext(GlobalStateContext);
  const { t } = useTranslation();
  const gridInstance = React.useRef(null);

  const [openEditModal, setOpenEditModal] = React.useState(false);

  const { buildDialogMessage } = useModal();

  const [typeOfForm, settypeOfForm] = React.useState('');
  const { register, handleSubmit, errors, reset, clearError, setValue } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const [modalData, setModalData] = React.useState();
  const dialogInstance = React.useRef();
  const [modalMessageParams, setmodalMessageParams] = React.useState({
    show: false,
  });
  const [manageDataArr, setManageDataArr] = React.useState([]);
  const [errosAPI, setErrosApi] = React.useState(null);
  const [loading, setloading] = React.useState(false);
  const contextMenuItems = [
    'SortAscending',
    'SortDescending',
    'FirstPage',
    'PrevPage',
    'LastPage',
    'NextPage',
  ];

  // effects
  React.useEffect(() => {
    buildGridItems();
  }, []);

  React.useEffect(() => {
    gridInstance.current.refresh();
  }, [i18n.language]);

  React.useEffect(() => {
    if (loading) {
      createSpinner({ target: document.getElementById('loading') });
      showSpinner(document.getElementById('loading'));
      setSpinner({ type: 'Bootstrap' });
    }
  }, [loading]);


  // templates
  const active_colum = (args) => {
    return (
      <>
        {args.ativo ===true ? (
          <span className="active yes">
            {i18n.language ==='pt' ? 'Sim' : 'Yes'}
          </span>
        ) : (
          <span className="active no">
            {i18n.language ==='pt' ? 'Não' : 'No'}
          </span>
        )}
      </>
    );
  };

  React.useEffect(() => {
    let globalPermissions = globalState.permissions;
    userPermission = globalPermissions.filter((i) => i.idAreaAcesso === 29).map((i) => (i.permission));
    console.log(userPermission);
  }, [globalState]);

  const editTemplate = (args) => {
    return hasPermissions(29, "Edit", globalState) && (
      <div className="editItems">
        <span
          onClick={() => {
            openEdit(args);
            // edit(args);
          }}
          className="mdl2 mdl2-pencil"
        />
      </div>
    );
  };

  const toBRL = valor => {
    return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  const templateSaldo = (data) => {
    return <span>{data.saldo && toBRL(data.saldo)}</span>;
  };

  const templateOrcamento = (data) => {
    return <span>{data.orcamento && toBRL(data.orcamento)}</span>;
  };

  const bannerStatus = [
    {
      text: i18n.language ==='pt' ? 'Sim' : 'Yes',
      value: 'yes',
    },
    {
      text: i18n.language ==='pt' ? 'Não' : 'No',
      value: 'no',
    },
  ];


  const buildGridItems = async () => {
    await getManageAnnualBudget(globalState.accessToken).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          setManageDataArr(data);
        });
      }
    });
  };



  // MODAL
  const clearModal = () => {
    setModalData(null);
    setValue('orcamento', null);
    setValue('saldo', null);
    setValue('ano', null);
    clearError();
    reset();
    setOpenEditModal(false);
    dialogInstance.current.hide();
  };


  // CUSTOM FunctioS
  const openEdit = (args) => {
    settypeOfForm('EDIT');
    setValue('orcamento', args.orcamento);
    setValue('saldo', args.saldo);
    setModalData(args);
    setOpenEditModal(true);
    dialogInstance.current.show();
  };

  const tranformToBRL = (value, prefix = 'R$ ') => {
    var v = value.replace(/\D/g, '');
    v = (v / 100).toFixed(2) + '';
    v = v.replace('.', ',');
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
    v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
    return prefix + v;
  };

  const buildDialogMessage2 = (type, msg) => {
    if (type ==='success') {
      setmodalMessageParams({
        title: t('ModalMessages:title'),
        message: t('ModalMessages:created'),
        type: 'success',
        icon: 'mdl2-confirm',
        show: true,
      });
    } else if (type ==='error') {
      setmodalMessageParams({
        title: t('ManageInternalAreas:error_title'),
        message: t('ManageInternalAreas:error'),
        type: 'error',
        icon: 'e-icon-dlg-close e-icons',
        show: true,
      });
    } else if (type ==='updated') {
      setmodalMessageParams({
        title: t('ModalMessages:title'),
        message: t('ModalMessages:updated'),
        type: 'success',
        icon: 'mdl2-confirm',
        show: true,
      });
    } else if (type ==='custom-error') {
      setmodalMessageParams({
        title: t('ManageInternalAreas:error_title'),
        message: msg,
        type: 'error',
        icon: 'e-icon-dlg-close e-icons',
        show: true,
      });
    }
  };


  // BUSINESS RULES
  const createItem = async (dataInput) => {
    setloading(true);
    setErrosApi(null);
    const dataToPersist = {
      ano: parseInt(dataInput.ano),
      orcamento: dataInput.orcamento,
    };
    let insert;
    try {
      insert = await addManageAnnualBudget(
        globalState.accessToken,
        dataToPersist,
      );

      if (insert.ok && (insert.status ===200 || insert.status ===201)) {
        insert.json().then((res) => {
          setManageDataArr((prevstate) => {
            const newDataArr = prevstate;
            dataToPersist.id = res.id;
            newDataArr.unshift(dataToPersist);
            return newDataArr;
          });
        });
        gridInstance.current.refresh();
        dialogInstance.current.hide();
        buildDialogMessage2('success');
        setOpenEditModal(false);
        reset();
      } else {
        const r = await insert.json();
        console.log(r);
        if (r.Mensagem || r.title) {
          buildDialogMessage2('custom-error', r.Mensagem || r.title);
          setErrosApi(r.Mensagem);
        } else {
          setErrosApi(t("ManageAnnualBudget:yearExists"));
        }
      }
      setloading(false);
    } catch (r) {
      setloading(false);
      console.log(r);
      if (r.Mensagem || r.title) {
        buildDialogMessage2('custom-error', r.Mensagem || r.title);
        setErrosApi(r.Mensagem);
      } else {
        setErrosApi(t("ManageAnnualBudget:yearExists"));
      }
    }
  };

  const updateItem = async (dataInput) => {
    setloading(true);
    setErrosApi(null);
    const dataToPersist = {
      id: modalData.id,
      ano: parseInt(dataInput.ano),
      orcamento: dataInput.orcamento,
      ativo: dataInput.ativo ==='yes' ? true : false,
    };
    try {
      const r = await updateManageAnnualBudget(
        globalState.accessToken,
        dataToPersist,
      );

      if (r.ok && r.status ===200) {
        r.json().then((res) => {
          const newManageDataArr = gridInstance.current.dataSource.map(
            (item) => {
              if (item.id ===modalData.id) item = res;
              return item;
            },
          );
          setManageDataArr(newManageDataArr);
          gridInstance.current.dataSource = newManageDataArr;
          gridInstance.current.refresh();
        });

        dialogInstance.current.hide();
        buildDialogMessage2('updated');
        setOpenEditModal(false);
        clearModal();
      } else {
        const res = await r.json();
        setErrosApi(res);
        console.log(res);
        res.Mensagem && buildDialogMessage2('custom-error', res.Mensagem);
      }
      setloading(false);
    } catch (r) {
      setloading(false);
      setErrosApi(r);
      console.log(r);
      if (r.Mensagem || r.title)
        buildDialogMessage2('custom-error', r.Mensagem || r.title);
    }
  };


  // FORM
  const onSubmit = (data) => {
    if (typeOfForm ==='NEW') createItem(data);
    else if (typeOfForm ==='EDIT') updateItem(data);
  };
  const onError = (errors, e) => console.log(errors, e);

  return (
    <>
      <GenericModal params={modalMessageParams} />
      <PermissionRedirect id="29" />

      <div className="topTitleButtons">
        <Row>
          <div className="col-sm-8 col-12">
            <div className="title">
              <h1>{t('ManageAnnualBudget:title')}</h1>
            </div>
          </div>
          <div className="col-sm-4 col-12">
            <div className="buttons">
              <Buttons
                controls={{
                  permit: userPermission ? userPermission : null,
                  new: () => {
                    settypeOfForm('NEW');
                    dialogInstance.current.show();
                  },
                }}
              />
            </div>
          </div>
        </Row>
      </div>

      <div id="Annual">
        <div className="topContent">
          <span className="mdl2 mdl2-money" />
          <label>{i18n.language ==='pt' ? 'Custos' : 'Costs'}</label>
        </div>

        <DialogComponent
          id="modalEditAnnual"
          isModal={true}
          visible={openEditModal}
          ref={dialogInstance}
          close={clearModal}
          showCloseIcon={true}
          locale={i18n.language}
        >
          <form
            className="newAnnual"
            id="addEditDialog"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            {/* ID */}
            <div className="dropDown">
              {typeOfForm ==='EDIT' && (
                <div className="input">
                  <label htmlFor="id">ID</label>
                  <div className="e-float-input">
                    <TextBoxComponent
                      id="id"
                      name="id"
                      enabled={false}
                      ref={register({
                        required: true,
                      })}
                      type="number"
                      value={modalData && modalData.id}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* ORCAMENTO */}
            <div className="input">
              <label htmlFor="orcamento">
                {i18n.language ==='pt' ? 'Orçamento *' : 'Budget *'}
              </label>
              <div className="e-float-input">
                <NumericTextBoxComponent
                  name="orcamento"
                  id="orcamento"
                  format="c2"
                  placeholder={
                    i18n.language ==='pt'
                      ? 'Digite o orçamento (apenas números)'
                      : 'Type the budget (only numbers)'
                  }
                  htmlAttributes={{ maxLength: 23 }}
                  className={errors.orcamento && 'error'}
                  ref={register({
                    required: true,
                    maxLength: 23,
                    minLength: 2,
                  })}
                />

              </div>

              {errors && errors.orcamento && (
                <p className="error">{t('Validation:required')}</p>
              )}
              {errors && errors.orcamento && errors.orcamento.type === 'maxLength' && (
                <p className="error">{'Your input exceed max length'}</p>
              )}
              {errors && errors.orcamento && errors.orcamento.type === 'minLength' && (
                <p className="error">{'Your input has no min length'}</p>
              )}
            </div>

            {/* SALDO */}
            {typeOfForm ==='EDIT' && (
              <div className="input">
                <label htmlFor="saldo">
                  {i18n.language ==='pt' ? 'Saldo *' : 'Balance *'}
                </label>
                <div className="e-float-input">
                  <NumericTextBoxComponent
                    id="saldo"
                    name="saldo"
                    enabled={false}
                    ref={register({
                      required: false,
                      maxLength: 24,
                      minLength: 2,
                    })}
                    format="c2"
                    // ref={saldoInput}
                    type="text"
                    placeholder={
                      i18n.language ==='pt'
                        ? 'Digite o saldo (apenas números)'
                        : 'Type the balance (only numbers)'
                    }
                    value={
                      modalData &&
                      modalData.saldo
                    }
                  />
                </div>
                {errors.saldo && (
                  <p className="error">{t('Validation:required')}</p>
                )}
                {errors.saldo && errors.saldo.type === 'maxLength' && (
                  <p className="error">{'Your input exceed max length'}</p>
                )}
                {errors.saldo && errors.saldo.type === 'minLength' && (
                  <p className="error">{'Your input has no min length'}</p>
                )}
              </div>
            )}

            {/* ANO */}
            <div className="input">
              <label htmlFor="ano">
                {i18n.language ==='pt' ? 'Ano *' : 'Year *'}
              </label>
              <div className="e-float-input">
                <MaskedTextBoxComponent
                  id="ano"
                  name="ano"
                  locale={i18n.language}
                  placeholder={
                    i18n.language ==='pt' ? 'Digite o ano' : 'Type year'
                  }
                  ref={register({
                    required: true,
                    maxLength: 4,
                    minLength: 4,
                  })}
                  mask="[0-9][0-9][0-9][0-9]"
                  value={modalData && modalData.ano.toString()}
                />
              </div>
              {errors.ano && (
                <p className="error">{t('Validation:required')}</p>
              )}
              {errors.ano && errors.ano.type === 'maxLength' && (
                <p className="error">{'Your input exceed max length'}</p>
              )}
              {errors.ano && errors.ano.type === 'minLength' && (
                <p className="error">{'Your input has no min length'}</p>
              )}
              {errosAPI && (
                <p className="error">{errosAPI}</p>
              )}

            </div>

            {/* ATIVO */}
            {typeOfForm ==='EDIT' && (
              <div className="input">
                <label htmlFor="ativo">
                  {i18n.language ==='pt' ? 'Ativo *' : 'Active *'}
                </label>
                <div className="e-float-input">
                  <DropDownListComponent
                    id="ativo"
                    name="ativo"
                    ref={register({ required: true })}
                    cssClass="e-filled"
                    dataSource={bannerStatus}
                    fields={{ text: 'text', value: 'value' }}
                    locale={i18n.language}

                    value={modalData ? (modalData.ativo ? 'yes' : 'no') : null}
                    placeholder={
                      i18n.language ==='pt'
                        ? 'Selecione o status'
                        : 'Select status'
                    }
                  />
                  {errors.ano && (
                    <p className="error">{t('Validation:required')}</p>
                  )}
                </div>
              </div>
            )}

            <div className="btn-wrapper">
              <button
                type="submit"
                disabled={loading}
                className={'e-control e-btn e-lib btn-modal'}
              >
                {t('Actions:save')}
              </button>
            </div>
          </form>
          {loading && <div style={{ zIndex: '99999' }} id="loading" />}
        </DialogComponent>

        <GridComponent
          id="managementAnnualItems"
          dataSource={manageDataArr}
          contextMenuItems={contextMenuItems}
          filterSettings={{ type: 'CheckBox' }}
          allowPaging={true}
          allowSorting={true}
          allowFiltering={true}
          ref={gridInstance}
          pageSettings={{
            pageSize: 10,
            pageSizes: true,
          }}
          locale={i18n.language}
        >
          <ColumnsDirective>
            <ColumnDirective headerText={'Id'} field="id" width="100" />
            <ColumnDirective
              headerText={t('ManageAnnualBudget:column_year')}
              field="ano"
              width="250"
            />
            <ColumnDirective
              headerText={t('ManageAnnualBudget:column_budget')}
              field="orcamento"
              width="200"
              filter={{ type: 'Menu' }}
              template={templateOrcamento}
            />
            <ColumnDirective
              headerText={t('ManageAnnualBudget:column_balance')}
              field="saldo"
              filter={{ type: 'Menu' }}
              template={templateSaldo}
              width="200"
            />
            <ColumnDirective
              headerText={t('ManageAnnualBudget:column_active')}
              filterItemTemplate={args => args.ativo ? t('ManageInternalAreas:yes') : t('ManageInternalAreas:no')}
              template={(args) => active_colum(args)}
              field="ativo"
              width="170"
            />
            <ColumnDirective
              template={(args) => editTemplate(args)}
              width="80"
            />
          </ColumnsDirective>
          <Inject
            services={[Resize, Sort, ContextMenu, Filter, Page, Edit, Toolbar]}
          />
        </GridComponent>
      </div>
    </>
  );
};
