import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { GlobalStateContext } from '../general/GlobalState';
import { Container, Row } from "reactstrap";

export const Breadcrumbs = ({ crumbs }) => {
    const { t } = useTranslation();

    const renderBreadcrumb = (item, i) => {
        let render;
        const name = i18n.language ==="pt" ? item.namePt : item.nameEn

        if (!item.breadcrumb || !item.breadcrumb.ignore) {
            if (item.breadcrumb && item.breadcrumb.noLink) {
                render =
                    <label key={i}>
                        {t('Breadcrumb:' + name)}
                    </label>
            }
            else {
                render =
                    <Link key={i} to={(item.breadcrumb && item.breadcrumb.link) || item.path}>
                        {t('Breadcrumb:' + name)}
                    </Link>
            }
            if (i + 1 !== crumbs.length)
                render =
                    <React.Fragment key={i}>
                        {render}
                        <span className="mdl2 mdl2-arrowRightBold" />
                    </React.Fragment>;
        }
        return render;
    }

    const crumbsFiltered = crumbs.filter((c, i) => i ===crumbs.length - 1 && c.breadcrumb && c.breadcrumb.ignore);
    crumbs = crumbsFiltered.length > 0 ? [] : crumbs;
    if (crumbs.length > 0) {
        return (
            <div style={{ backgroundColor: "#fff" }}>
                <Container>
                    <Row>
                        <div className="col-12">
                            <div className="breadcrumbs">
                                {crumbs.map((item, key) =>
                                    renderBreadcrumb(item, key)
                                )}
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
    else
        return <></>
};
