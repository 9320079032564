import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from "../general/GlobalState";
import { Redirect } from "react-router-dom";
import './home.css';
import { hasPermissions } from "../utilities/Utils";


export const MessageHouse = () => {

    const [globalState] = React.useContext(GlobalStateContext)
    const [redirect, setRedirect] = React.useState(false);

    const { t } = useTranslation();

    const messageRedirect = () => {
        setRedirect(true);
    }

    return (
        redirect ? <Redirect push={true} to='/home/messagehouseItem' /> :
            hasPermissions(57, "", globalState) &&
            <>
                <div id="MessageHouseHome">
                    <svg xmlns="http://www.w3.org/2000/svg" width="65.19" height="54" viewBox="0 0 65.19 54">
                        <g id="Casa_de_Mensagens" data-name="Casa de Mensagens" transform="translate(-106 -645)">
                            <ellipse id="Elipse_2394" data-name="Elipse 2394" cx="26.5" cy="27" rx="26.5" ry="27" transform="translate(111 645)" fill="#f7f9f9" />
                            <g id="Grupo_13957" data-name="Grupo 13957" transform="translate(106 645.766)">
                                <path id="Caminho_9064" data-name="Caminho 9064" d="M34.843,1.417A1.611,1.611,0,0,0,33.059.011L1.417,3.777A1.615,1.615,0,0,0,.011,5.564L2.325,25a1.612,1.612,0,0,0,1.785,1.408l31.641-3.768a1.611,1.611,0,0,0,1.406-1.785Z" transform="translate(36.891 30.755) rotate(-173)" fill="#edb111" />
                                <path id="Caminho_9065" data-name="Caminho 9065" d="M1.278.32C.462-.307-.1.016.016,1.037L.87,8.2A1.876,1.876,0,0,0,2.946,9.834l7.422-.894c1.019-.121,1.188-.734.379-1.358Z" transform="translate(32.583 34.412) rotate(-173)" fill="#edb111" />
                                <g id="Grupo_13819" data-name="Grupo 13819" transform="translate(30.759 20.242) rotate(-173)">
                                    <path id="Caminho_9066" data-name="Caminho 9066" d="M4.949,2.189a2.481,2.481,0,1,0-.513,1.83A2.483,2.483,0,0,0,4.949,2.189Z" transform="translate(0 2.08)" fill="#f5f5f5" />
                                    <path id="Caminho_9067" data-name="Caminho 9067" d="M4.949,2.189A2.481,2.481,0,1,0,4.436,4.02,2.483,2.483,0,0,0,4.949,2.189Z" transform="translate(8.727 1.04)" fill="#f5f5f5" />
                                    <path id="Caminho_9068" data-name="Caminho 9068" d="M4.95,2.189A2.483,2.483,0,1,0,2.775,4.95,2.483,2.483,0,0,0,4.95,2.189Z" transform="translate(17.465 0)" fill="#f5f5f5" />
                                </g>
                            </g>
                            <g id="Grupo_13958" data-name="Grupo 13958" transform="matrix(0.995, -0.105, 0.105, 0.995, 126.159, 664.966)">
                                <path id="Caminho_9064-2" data-name="Caminho 9064" d="M39.495,28.345a1.826,1.826,0,0,1-2.021,1.594L1.606,25.669A1.83,1.83,0,0,1,.012,23.644L2.636,1.608A1.828,1.828,0,0,1,4.659.012L40.525,4.283a1.826,1.826,0,0,1,1.593,2.024Z" transform="translate(0 0)" fill="#007e7a" />
                                <path id="Caminho_9065-2" data-name="Caminho 9065" d="M1.449,10.8c-.925.71-1.568.344-1.43-.813L.986,1.868A2.127,2.127,0,0,1,3.339.014l8.413,1.013c1.155.138,1.346.832.43,1.54Z" transform="translate(4.342 23.499)" fill="#007e7a" />
                                <g id="Grupo_13819-2" data-name="Grupo 13819" transform="translate(8.352 10.981)">
                                    <path id="Caminho_9066-2" data-name="Caminho 9066" d="M5.61,3.149a2.813,2.813,0,1,1-.581-2.074A2.815,2.815,0,0,1,5.61,3.149Z" transform="translate(0 0)" fill="#f5f5f5" />
                                    <path id="Caminho_9067-2" data-name="Caminho 9067" d="M5.61,3.149a2.813,2.813,0,1,1-.582-2.075A2.815,2.815,0,0,1,5.61,3.149Z" transform="translate(9.892 1.179)" fill="#f5f5f5" />
                                    <path id="Caminho_9068-2" data-name="Caminho 9068" d="M5.61,3.149A2.815,2.815,0,1,1,3.146.02,2.815,2.815,0,0,1,5.61,3.149Z" transform="translate(19.797 2.357)" fill="#f5f5f5" />
                                </g>
                            </g>
                        </g>
                    </svg>
                    <div className="innerMessage">
                        <span>{t('MessageHouse:title')}</span>
                        <label onClick={messageRedirect}>{i18n.language ==="pt" ? "Acessar" : "Access"}</label>
                    </div>
                </div>
            </>
    );
}