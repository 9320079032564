import * as React from "react";
import i18n from "i18next";
import { useTranslation, getInitialProps } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { TextBoxComponent, MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { CheckBoxSelection, Inject, MultiSelectComponent, DropDownTreeComponent, DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Sort, ContextMenu, Page, Resize, Edit, Toolbar, ExcelExport } from "@syncfusion/ej2-react-grids";
import { CheckBoxComponent, ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { createSpinner, setSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { getPermissions } from '../../services/SystemSVC';
import { getAccessAreas } from '../../services/SystemSVC';
import { getProfileTemplate } from '../../services/ProfileTemplateSVC';
import { DialogComponent } from "@syncfusion/ej2-react-popups";

let gridInstance;
let modalDelete;

export const Permissions = (props) => {

    const { info, changeUser } = props;

    const contextMenuItems = ['SortAscending', 'SortDescending', 'FirstPage', 'PrevPage', 'LastPage', 'NextPage'];

    const [gridItems, setGridItems] = React.useState([]);
    const [loadingPermissions, setLoadingPermissions] = React.useState(true);
    const [changePermission, setChangePermission] = React.useState(info && info.adminGlobal);
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const [profileTemplates, setProfileTemplates] = React.useState([]);
    const [rawAreas, setRawAreas] = React.useState([]);
    const [comboPermission, setComboPermission] = React.useState([]);
    const [areaData, setAreaData] = React.useState([]);
    const [admin, setAdmin] = React.useState(info.adminGlobal);
    const [areaObj, setAreaObj] = React.useState({});
    const [permissionObj, setPermissionObj] = React.useState({});
    const [openModalDelete, setOpenModalDelete] = React.useState(false);
    const [grid, setGrid] = React.useState([]);

    const [accessArea, setAccessArea] = React.useState(null);

    let valuesArea;
    let valuesPermissions;


    modalDelete = [
        {
            type: "submit",
            buttonModel: {
                isPrimary: true,
                content: i18n.language ==="pt" ? "Cancelar" : "Cancel",
                cssClass: "pemissionDelete"
            },
            click: () => {
                setOpenModalDelete(false);
            }
        },
        {
            type: "submit",
            buttonModel: {
                isPrimary: true,
                content: i18n.language ==="pt" ? "Sim" : "Yes",
                cssClass: "pemissionSave"
            },
            click: () => {
                deleteRow();
            }
        }
    ]

    const { t } = useTranslation();

    React.useEffect(() => {
        if (loadingPermissions) {
            createSpinner({ target: document.getElementById('loadPermissions') })
            showSpinner(document.getElementById('loadPermissions'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [loadingPermissions]);

    React.useEffect(() => {
        if (info) {
            setGridItems(info.permissions);
        }
    }, [i18n.language]);

    React.useEffect(() => {
        if (gridItems) {
            info.permissions = gridItems;
            // gridInstance.refresh();
        }
    }, [gridItems]);

    React.useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language]);

    React.useEffect(() => {
        setLoadingPermissions(true);
        async function load() {
            let _profileTemplates = await getProfileTemplate(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json(); } });
            let _areas = await getAccessAreas(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json(); } });
            let _permission = await getPermissions(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json(); } });
            if (_profileTemplates && _areas) {
                _profileTemplates.map((pt) => {
                    pt.permissions.map((pe) => {
                        let area = _areas.filter(i => i.id ===pe.idAccessArea);
                        if (area.length > 0) {
                            pe.area = area[0];
                        }
                    })
                })
            }

            setProfileTemplates(_profileTemplates);
            setRawAreas(_areas);
            setComboPermission(_permission);

            if (_areas) {
                setAreaData(fillAreas(_areas));
            }

            hideSpinner(document.getElementById('loadPermissions'));
            setLoadingPermissions(false);
        }
        load();
    }, [i18n.language]);

    const fillAreas = (items, currentItem = null, child = false) => {
        let arr = [];
        items.map((item) => {
            if (!child) {
                arr.push({ value: item.id, text: i18n.language ==="pt" ? item.nomePt : item.nomeEn, hasChildren: item.filhos.length > 0 ? true : false, breadCrumbAccessAreaEn: item.breadCrumbEn, breadCrumbAccessAreaPt: item.breadCrumbPt, isFather: true });
                if (item.filhos.length > 0) {
                    let children = fillAreas(item.filhos, item, true);
                    arr = arr.concat(children);
                }
            } else {
                arr.push({ value: item.id, text: i18n.language ==="pt" ? item.nomePt : item.nomeEn, parentValue: item.idPai, hasChildren: item.filhos.length > 0 ? true : false, breadCrumbAccessAreaEn: item.breadCrumbEn, breadCrumbAccessAreaPt: item.breadCrumbPt, isFather: false });
                if (item.filhos.length > 0) {
                    let children = fillAreas(item.filhos, item, true);
                    arr = arr.concat(children);
                }
            }
        });
        return arr;
    }

    const accessAreaChange = (item) => {
        debugger;
        valuesArea = item.value;
    }

    const onInsert = async (e) => {
        e.preventDefault();
        if (valuesArea && valuesPermissions) {

            let items = [...gridItems];
            let areaObj;
            let ptPermissionObj = [];
            let enPermissionObj = [];
            let areaBreadCrumb = [];

            valuesArea.map(area => {
                areaObj = areaData.filter(breadValue => breadValue.value ===parseInt(area))[0];
                if (!areaObj.hasChildren) {
                    areaBreadCrumb.push(areaObj)
                }
            });

            valuesPermissions.map(permission => {

                ptPermissionObj.push(comboPermission.filter(idPermission => idPermission.id ===permission)[0].nomePt);
                enPermissionObj.push(comboPermission.filter(idPermission => idPermission.id ===permission)[0].nomeEn);
            });

            areaBreadCrumb.map(async breadText => {
                let catchIdAccessArea = gridItems.filter(grid => grid.idAccessArea ===breadText.value && grid.status !== 1);
                let catchParentValue = gridItems.filter(parent => parent.idAccessArea ===breadText.parentValue);

                if (catchIdAccessArea.length ===0) {

                    let obj = { idAccessArea: breadText.value, permissionsPt: ptPermissionObj, permissionsEn: enPermissionObj, breadCrumbAccessAreaPt: breadText.breadCrumbAccessAreaPt, status: 0, IdsPermissions: valuesPermissions };
                    console.log(obj);

                    i18n.language === "pt" ? obj.breadCrumbAccessAreaPt = breadText.breadCrumbAccessAreaPt : obj.breadCrumbAccessAreaEn = breadText.breadCrumbAccessAreaEn;

                    items.push(obj)

                } else {
                    let newItemObj = {
                        idAccessArea: breadText.value,
                        permissionsPt: ptPermissionObj,
                        permissionsEn: enPermissionObj,
                        breadCrumbAccessAreaPt: breadText.breadCrumbAccessAreaPt,
                        status: 0,
                        IdsPermissions: valuesPermissions
                    };

                    i18n.language === "pt" ? newItemObj.breadCrumbAccessAreaPt = breadText.breadCrumbAccessAreaPt : newItemObj.breadCrumbAccessAreaEn = breadText.breadCrumbAccessAreaEn;

                    const oldItemIndex = items.findIndex(grid => grid.idAccessArea ===breadText.value && grid.status !== 1);

                    if (items[oldItemIndex].status ===2) {
                        items[oldItemIndex].status = 1;
                    } else {
                        items.splice(oldItemIndex, 1);
                    }

                    items.push(newItemObj);
                }
            })

            await setLoadingPermissions(true);
            setGridItems(items)
            hideSpinner(document.getElementById('loadPermissions'));
            setLoadingPermissions(false);

            gridInstance.dataSource = items.filter(grid => grid.status !== 1);

        }
    };

    const columnArea = (args) => {
        return <span>{args && (i18n.language ==="pt" ? args.breadCrumbAccessAreaPt.join(' > ') : args.breadCrumbAccessAreaEn.join(' > '))}</span>
    };

    const columnPermission = (args) => {
        return <span>{args && (i18n.language ==="pt" ? args.permissionsPt.join(', ') : args.permissionsEn.join(', '))}</span>
    };

    async function deleteRow() {
        setLoadingPermissions(true);

        let gridItem = gridItems.find(x => x.idAccessArea === grid.idAccessArea && x.status !== 1);
        if (gridItem.ids) {
            gridItem.status = 1;
        } else {
            let index = gridItems.findIndex(x => x.idAccessArea === gridItem.idAccessArea && x.status !== 1);
            gridItems.splice(index, 1);
        }

        setOpenModalDelete(false);

        hideSpinner(document.getElementById('loadPermissions'));
        setLoadingPermissions(false);

        gridInstance.dataSource = gridItems.filter(x => x.status !==1);

    }

    const columnButton = (args) => {
        return (
            <ButtonComponent
                id=""
                cssClass="e-flat e-danger"
                onClick={(e) => {
                    e.preventDefault();
                    setOpenModalDelete(true)
                    setGrid(args)
                    console.log(args);

                }}
            >Excluir</ButtonComponent>
        )
    }

    const changedPermission = (e) => {
        console.log(e.checked);
        setChangePermission(e.checked);
        info.adminGlobal = e.checked;
    }

    return (
        <>  {loadingPermissions ? <div id="loadPermissions" /> :
            <div className="permissions" >
                <div className="top d-flex flex-row align-items-center" >
                    <div className="mdl2 mdl2-unlock" />
                    <h2>{t('Permissions:title')}</h2>
                    <div className="checkbox">
                        <CheckBoxComponent
                            label={t('AdmGlobal:title')}
                            checked={changePermission}
                            change={(e) => changedPermission(e)}
                        />
                    </div>
                </div>
                {!changePermission && (
                    <>
                        <div className="one inverse d-flex flex-row align-items-center" >
                            <div className="input" >
                                <label> {t('Permissions:model_title')} </label>
                                <DropDownListComponent
                                    id="modelo"
                                    cssClass="e-filled"
                                    //allowFiltering={true} 
                                    dataSource={profileTemplates}
                                    showClearButton
                                    fields={{ text: "nome", value: "id" }}
                                    placeholder={t('ComboPlaceHolder:profile_template')}
                                    value={info.idModeloPerfil}
                                    change={(e) => info.idModeloPerfil = e.itemData && e.itemData.id ? e.itemData.id : null}
                                    showClearButton
                                />
                            </div>
                        </div>
                        <div className="two inverse d-flex flex-row align-items-center" >
                            <div className="input">
                                <label>{t('Permissions:access_title')}</label>
                                <DropDownTreeComponent
                                    id="dropdowntree"
                                    cssClass="e-filled"
                                    fields={{ dataSource: areaData, value: "value", text: "text", parentValue: "parentValue", hasChildren: "hasChildren" }}
                                    showCheckBox={true}
                                    treeSettings={{ autoCheck: true }}
                                    placeholder={t('ComboPlaceHolder:access_area')}
                                    change={(args) => valuesArea = args.value}
                                    showDropDownIcon={false}
                                // change={(args) => console.log("args:", args.value)}
                                />
                            </div>
                            <div className="input" >
                                <label> {t('Permissions:permission_title')} </label>
                                <MultiSelectComponent
                                    id="permission"
                                    cssClass="e-filled"
                                    dataSource={comboPermission}
                                    fields={i18n.language ==='pt' ? { text: "nomePt", value: "id" } : { text: "nomeEn", value: "id" }}
                                    placeholder={t('ComboPlaceHolder:permission')}
                                    mode="CheckBox"
                                    change={(args) => valuesPermissions = args.value}
                                    value={valuesPermissions}
                                //change={(args) => console.log("args permission", args.value)}
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div>
                        </div>
                        <div className="divButton">
                            <ButtonComponent onClick={onInsert}><span>{t("InsertButton:title")}</span></ButtonComponent>
                        </div>
                        {gridItems &&
                            <GridComponent
                                id="gridcomp"
                                dataSource={gridItems}
                                //created={() => gridInstance.dataSource = info.permissions}
                                contextMenuItems={contextMenuItems}
                                filterSettings={{ type: 'Menu' }}
                                locale={i18n.language}
                                ref={x => gridInstance = x}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective
                                        //field="idAccessArea"
                                        template={columnArea}
                                        headerText={t('TablePermissions:area')} width="235"></ColumnDirective>
                                    <ColumnDirective
                                        //field="id" 
                                        template={(args) => columnPermission(args)}
                                        headerText={t('TablePermissions:permission')} width="240"></ColumnDirective>
                                    <ColumnDirective
                                        template={(args) => columnButton(args)}
                                        headerText={t('TablePermissions:action')} width="125"></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Toolbar, ExcelExport]} />
                            </GridComponent>
                        }
                        <DialogComponent
                            id="deleteModal"
                            isModal={true}
                            buttons={modalDelete}
                            visible={openModalDelete}
                            locale={i18n.language}
                        >
                            <div>
                                <div className="divTrash">
                                    <span className="mdl2 mdl2-trash"></span>
                                    <div className="phraseDelete">
                                        <label id="delete">{i18n.language ==="pt" ? "Excluir" : "Delete"}</label>
                                        <label id="labelDelete">{i18n.language ==="pt" ? "Certeza que deseja excluir?" : "Sure you want to delete?"}</label>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </DialogComponent>
                    </>
                )}
            </div>
        }
        </>
    )
}