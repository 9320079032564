import { requestAPI } from '../utilities/Utils';

export async function getFormContainerAdBudgets(token, signal) {
  // const qstring; //= idioma ? `?$filter=IdIdioma eq ${idioma}` : '';
  return requestAPI(
    '_api/BrandingAdvertising/AdvertisingBudget/GetAdvertisingBudgetItems',
    'GET',
    '',
    null,
    token,
    false,
    null,
    null,
    signal
  );
}

export async function getAdvertisingBudgetItemByID(token, id, signal) {
  const qstring = `?advertisingBudgetId=${parseInt(id)}`;
  return requestAPI(
    '_api/BrandingAdvertising/AdvertisingBudget/GetAdvertisingBudgetItem',
    'GET',
    qstring,
    null,
    token,
    false,
    null,
    null,
    signal
  );
}

export async function getAdvertisingBudgetDataTypes(token, signal) {
  return requestAPI(
    '_api/System/GetAdvertisingBudgetDataTypes',
    'GET',
    '',
    null,
    token,
    false,
    null,
    null,
    signal
  );
}
export async function getAdvertisingBudgetDataStatus(token, signal) {
  return requestAPI(
    '_api/System/GetAdvertisingBudgetDataStatus',
    'GET',
    '',
    null,
    token,
    false,
    null,
    null,
    signal
  );
}
export async function getActiveContractInPeriodItems(token, signal) {
  var today = new Date()
  let startDayToEndDay = ("0" + today.getDate()).slice(-2);
  let startMonth = ("0" + (today.getMonth() + 1)).slice(-2);
  let endPeriodDtStr = `${today.getFullYear()}-${startMonth}-${startDayToEndDay}T23:59:59.999Z`;

  const qstring = `?$filter=ativo eq true and inicioVigencia le ${endPeriodDtStr} and fimVigencia ge ${endPeriodDtStr}`

  console.log(qstring);

  return requestAPI(
    '_api/BrandingAdvertising/Management/Contract/GetContractItems',
    'GET',
    qstring,
    null,
    token,
    false,
    null,
    null,
    signal
  );
}
export async function getAllContractItems(token, signal) {
  return requestAPI(
    '_api/BrandingAdvertising/Management/Contract/GetContractItems',
    'GET',
    '',
    null,
    token,
    false,
    null,
    null,
    signal
  );
}
export async function getActiveContractItems(token, signal) {
  return requestAPI(
    '_api/BrandingAdvertising/Management/Contract/GetContractItems',
    'GET',
    '?$filter=ativo eq true',
    null,
    token,
    false,
    null,
    null,
    signal
  );
}
export async function getManageInternalAreas(token, signal) {
  const qstring = `?$filter=ativo eq true`
  return requestAPI(
    '_api/BrandingAdvertising/Management/InternalArea/GetInternalAreaItems',
    'GET',
    qstring,
    null,
    token,
    false,
    null,
    null,
    signal
  );
}


export async function getManageAnnualBudget(token, signal) {
  const qstring = `?$filter=ativo eq true`
  return requestAPI(
    '_api/BrandingAdvertising/Management/AnnualBudget/GetAnnualBudgetItems',
    'GET',
    qstring,
    null,
    token,
    false,
    null,
    null,
    signal
  );
}


export async function updateAdvertisingBudget(token, body, signal) {
  const raw = JSON.stringify(body);
  return requestAPI(
    '_api/BrandingAdvertising/AdvertisingBudget/UpdateAdvertisingBudget',
    'POST',
    '',
    raw,
    token,
    false,
    null,
    null,
    signal
  );
}

export async function addAdvertisingBudget(token, body, signal) {
  const raw = JSON.stringify(body);
  return requestAPI(
    '_api/BrandingAdvertising/AdvertisingBudget/AddAdvertisingBudget',
    'POST',
    '',
    raw,
    token,
    false,
    null,
    null,
    signal
  );
}




export async function downloadAttachment(token, idItem, idAnexo, signal) {
  const qstring = `?IdOrGuidAdvertisingBudget=${idItem}&IdOrGuidAdvertisingBudgetAttachment=${idAnexo}`
  console.log('_api/BrandingAdvertising/AdvertisingBudget/DownloadAttachment' + qstring,);
  return requestAPI(
    '_api/BrandingAdvertising/AdvertisingBudget/DownloadAttachment' + qstring,
    'GET',
    '',
    null,
    token,
    false,
    null,
    null,
    signal
  );
}