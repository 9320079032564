export function getFileExtension(fileName) {

    if (!fileName) return '';
    const arrName = fileName.trim().split('.');
    if (arrName.length > 1) {
      let ext = arrName.slice(-1)[0].split('&');
      return getFileExtensionName(ext[0].toLowerCase(), fileName);
    }
    else return '';
}

export function getFileExtensionName(extension, url) {

    const urlEdited = url.includes('https://cdn.valecgc.com')
      ? url.replace('https://cdn.valecgc.com', `${process.env.REACT_APP_AZURE_STORAGE}`)
      : url.replace('https://filescdncgc.inparlabs.com.br', `${process.env.REACT_APP_AZURE_STORAGE}`);

    switch (extension) {
      case 'pdf':
        return `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-pdf.png`;
      case 'doc':
      case 'docx':
      case 'docm':
      case 'docb':
        return `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-doc.png`;
      case 'ppt':
      case 'pps':
      case 'pptx':
      case 'pptm':
      case 'ppsx':
        return `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-ppt.png`;
      case 'xls':
      case 'csv':
      case 'xlm':
      case 'xlsx':
      case 'xlsm':
      case 'xlsb':
        return `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-excel.png`;
      case 'txt':
        return `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-text.png`;
      case 'jpg':
      case 'png':
      case 'jpeg':
      case 'svg':
      case 'jif':
      case 'jfif':
      case 'jpe':
      case 'jfi':
      case 'gif':
      case 'webp':
      case 'tiff':
      case 'bmp':
        return urlEdited;
      case 'one':
        return `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-onenote.png`;
      case 'mp4':
      case 'mov':
      case 'wmv':
      case 'avi':
      case 'mkv':
        return `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-video.png`;
      case 'zip':
      case 'rar':
      case '7z':
        return `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-compacted.png`;
      case 'psd':
        return `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-psd.png`;
      case 'mp3':
      case 'wma':
      case 'wav':
        return `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-music.png`;
      default:
        return `${process.env.REACT_APP_AZURE_STORAGE}/press/System/icon-attach-black.png`;
    }
}

export function isOrContainsNode(node, container) {

    while (node) {
      if (node === container) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
};

export function langName(langId) {
    switch(langId) {
        case 1: return 'pt'
        case 2: return 'en'
        default: return undefined
    }
}

export function convertDateToUTC(date) {

    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
}

export function elementContainsSelection(el, sel) {
    if (sel) {
      if (sel.rangeCount > 0) {
        for (var i = 0; i < sel.rangeCount; ++i) {
          if (
            !isOrContainsNode(
              sel.getRangeAt(i).commonAncestorContainer,
              el
            )
          ) {
            return false;
          }
        }
        return true;
      }
    } else if ((sel = document.selection) && sel.type !=="Control") {
      return isOrContainsNode(sel.createRange().parentElement(), el);
    }
    return false;
}

export function restoreSelectionDocument(containerEl, savedSel) {

    var textRange = document.body.createTextRange();
    textRange.moveToElementText(containerEl);
    textRange.collapse(true);
    textRange.moveEnd("character", savedSel.end);
    textRange.moveStart("character", savedSel.start);
    return textRange.select();
}

export function getRelativeFilePath(absolutePath) {

    const filePathSplited = absolutePath.split("/");
    const guidOrId = filePathSplited[filePathSplited.length - 2];
    const filename = filePathSplited[filePathSplited.length - 1];
    return `${guidOrId}/${filename}`;
}
