import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../../general/GlobalState";
import { GridComponent, ColumnsDirective, ColumnDirective, ForeignKey, Filter, Inject, VirtualScroll, Sort, ContextMenu, Page, Resize, Edit } from '@syncfusion/ej2-react-grids';
import { AutoCompleteComponent, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { createSpinner, hideSpinner, setSpinner, showSpinner } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { getMailingGroupItemsGeneral } from "../../../services/MailingGeneralSVC";
import { getCountries, getRegional } from "../../../services/SystemSVC";
import { hasPermissions } from "../../utilities/Utils";
import { useModal } from "../../../hooks/Modal";

// import '../releases/releases.css';


export const GroupsList = () => {
    let gridInstance;

    const { t } = useTranslation();

    const [globalState] = React.useContext(GlobalStateContext);
    const [isVisible, setIsVisible] = React.useState(false);
    const [countriesPt, setCountriesPt] = React.useState([]);
    const [countriesEn, setCountriesEn] = React.useState([]);
    const [regionalPt, setRegionalPt] = React.useState([]);
    const [regionalEn, setRegionalEn] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [groups, setGroups] = React.useState();
    const [groupSelected, setGroupSelected] = React.useState();
    const [filter, setFilter] = React.useState({});

    const { buildDialogMessage } = useModal();

    React.useEffect(() => {
        let promises = [
            new Promise((resolve, reject) => {
                getCountries(globalState.accessToken).then(response => {
                    if (response.ok)
                        response.json().then(resolve);
                    else
                        reject();
                });
            }),
            new Promise((resolve, reject) => {
                getRegional(globalState.accessToken).then(response => {
                    if (response.ok)
                        response.json().then(resolve);
                    else
                        reject();
                });
            })
        ];

        Promise.all(promises).then(results => {
            console.log(results);
            const countries = results[0];
            const regional = results[1];

            if (countries)
                loadCountries(countries);
            if (regional)
                loadRegional(regional);

            getMailingGroupItemsGeneral(globalState.accessToken).then(response => {
                if (response.ok) {
                    response.json().then(results => {
                        let arrGroups = [];
                        results.map(item => {
                            const country = countries.find(_item => _item.id === item.idpais);
                            if (country) {
                                item.paisPt = country.nomePt;
                                item.paisEn = country.nomeEn;
                            }
                            const regionalItem = regional.find(_item => _item.id === item.idregional);
                            if (regionalItem) {
                                item.regionalPt = regionalItem.nomePt;
                                item.regionalEn = regionalItem.nomeEn;
                            }
                            arrGroups.push(item)
                        });
                        setGroups(arrGroups);
                    });
                }
            });
        });
    }, []);

    React.useEffect(() => {
        if (loading) {
            createSpinner({ target: document.getElementById('loadGroupList') });
            showSpinner(document.getElementById('loadGroupList'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [loading]);

    React.useEffect(() => {
        const elemSpinner = document.getElementById('loadGroupList');
        if (groups && elemSpinner) {
            hideSpinner(elemSpinner);
            setLoading(false);
        }
    }, [groups]);

    const loadCountries = (items) => {
        let countriesPt = [];
        let countriesEn = [];
        items.map(item => {
            countriesPt.push({ id: item.id, nome: item.nomePt });
            countriesEn.push({ id: item.id, nome: item.nomeEn });
        });
        setCountriesPt(countriesPt);
        setCountriesEn(countriesEn);
    }

    const loadRegional = (items) => {
        let regionalPt = [];
        let regionalEn = [];
        items.map(item => {
            regionalPt.push({ id: item.id, nome: item.nomePt });
            regionalEn.push({ id: item.id, nome: item.nomeEn });
        });
        setRegionalPt(regionalPt);
        setRegionalEn(regionalEn);
    }

    const autocompleteChange = e => {
        setFilter({ ...filter, groupId: e.itemData ? e.itemData.id : null });
    }
    const dropdownlistChange = e => {
        setFilter({ ...filter, [e.element.id]: e.value });
    }

    const gridRowSelected = e => {
        setGroupSelected(e.data.id);
    }

    const filterGrid = () => {
        setIsVisible(false);
        if (groups.length) {
            let dataFiltered = [...groups];
            if (filter.groupId)
                dataFiltered = dataFiltered.filter(item => item.id ===filter.groupId);
            if (filter.countryId)
                dataFiltered = dataFiltered.filter(item => item.idpais ===filter.countryId);
            if (filter.regionalId)
                dataFiltered = dataFiltered.filter(item => item.idregional ===filter.regionalId);

            gridInstance.dataSource = dataFiltered;
        }
    }

    const nameTemplate = (args) => {
        return (
            <>
                <span className="nameTemplate">{args.nome}</span>
            </>
        );
    }

    React.useEffect(() => {
        if (gridInstance)
            gridInstance.refresh();
    }, [i18n.language]);

    return (
        groupSelected ? <Redirect push={true} to={`/press/generalMailing/groupsItem/${groupSelected}`} /> :
            loading ? <div id="loadGroupList" /> :
                <>
                    <div className={`busca ${isVisible ? "aberto" : ""}`}>
                        <div className="filtroUsuarios d-flex">
                            <div className="SearchTitle">
                                <span className="mdl2 mdl2-search"></span>
                                <p>{t('Groups:search_text')}</p>
                            </div>
                            <div className="searchUsers">
                                <div className="e-input-in-wrap">
                                    <AutoCompleteComponent
                                        id="groupId"
                                        className="e-input"
                                        fields={{ text: 'nome', value: 'nome' }}
                                        dataSource={groups}
                                        placeholder={t('Groups:search_placeholder')}
                                        change={autocompleteChange}
                                        locale={i18n.language}
                                    />
                                </div>
                            </div>
                            <div className="btnBusca d-flex">
                                {!isVisible &&
                                    <div className="btnFilter">
                                        <ButtonComponent
                                            className="e-outline filter"
                                            cssClass='e-primary filter'
                                            onClick={() => setIsVisible(!isVisible)}>
                                            <span className="mdl2 mdl2-filter"></span>
                                            {t('Groups:filters')}
                                        </ButtonComponent>
                                    </div>
                                }
                                {isVisible &&
                                    <div className="btnFilter">
                                        <ButtonComponent
                                            className="e-outline filter active-filter"
                                            cssClass='e-primary filter'
                                            onClick={() => setIsVisible(!isVisible)}>
                                            <span className="mdl2 mdl2-filter"></span>
                                            {t('Groups:filters')}
                                        </ButtonComponent>
                                    </div>
                                }
                                <div className="btnSearch">
                                    <ButtonComponent
                                        cssClass='e-primary search'
                                        onClick={filterGrid}
                                    >
                                        {t('Groups:search')}
                                    </ButtonComponent>
                                </div>
                            </div>
                        </div>
                        {isVisible &&
                            <div className="filtrosOcultos dois d-flex">
                                <DropDownListComponent
                                    id="countryId"
                                    cssClass="e-filled"
                                    placeholder={t('Groups:country')}
                                    dataSource={i18n.language === "pt" ? countriesPt : countriesEn}
                                    fields={{ text: 'nome', value: 'id' }}
                                    value={filter.countryId}
                                    allowFiltering={true}
                                    change={dropdownlistChange}
                                    locale={i18n.language}
                                    showClearButton={true}
                                />
                                <DropDownListComponent
                                    id="regionalId"
                                    cssClass="e-filled"
                                    placeholder={t('Groups:regional')}
                                    dataSource={i18n.language === "pt" ? regionalPt : regionalEn}
                                    fields={{ text: 'nome', value: 'id' }}
                                    value={filter.regionalId}
                                    change={dropdownlistChange}
                                    locale={i18n.language}
                                    showClearButton={true}
                                />
                            </div>
                        }
                    </div>
                    <div className="listaGrupos">
                        <GridComponent
                            id='gridcomp'
                            ref={gridComponent => gridInstance = gridComponent}
                            dataSource={groups}
                            filterSettings={{ type: 'Menu' }}
                            allowFiltering={false}
                            allowPaging={true}
                            allowSorting={true}
                            rowSelected={gridRowSelected}
                            locale={i18n.language}>
                            <ColumnsDirective >
                                <ColumnDirective
                                    field='nome'
                                    template={(args) => nameTemplate(args)}
                                    headerText={t('Groups:name')}
                                    width='200'>
                                </ColumnDirective>
                                <ColumnDirective
                                    field={i18n.language ==="pt" ? 'paisPt' : 'paisEn'}
                                    headerText={t('Groups:country')}
                                    //template={(item)=> filterTemplate(i18n.language ==="pt" ? item.nomePaisPT : item.nomePaisEN)}
                                    width='80'>
                                </ColumnDirective>
                                <ColumnDirective
                                    field={i18n.language ==="pt" ? "regionalPt" : "regionalEn"}
                                    headerText={t('Groups:regional')}
                                    width='100'>
                                </ColumnDirective>
                                <ColumnDirective
                                    field='quantidadeMembros'
                                    headerText={t('Groups:quantity')}
                                    width='80'>
                                </ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Edit, ForeignKey]} />
                        </GridComponent>
                    </div>
                </>
    )
}
