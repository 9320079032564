import * as React from "react";
import { SharedDocumentsComponent } from './SharedDocumentsComponent';
import { useTranslation } from "react-i18next";

export const GlobalGuidelines = () => {
    const { t } = useTranslation();

    return <SharedDocumentsComponent permissionId="69" path="Guidelines Globais"
        folderManagerTitle={t("EmpCommunication:global_guidelines")}
        title={t("EmpCommunication:global_guidelines")} 
    />
}