import * as React from 'react';

const initialState = {
    releaseItem: null,
    urlItemId: null,
    changeInfo: null
};

const ReleaseStateContext = React.createContext(initialState);

const ReleaseStateProvider = ({ children }) => {
    const [releaseState, setReleaseState] = React.useState(initialState);
    const [groupsSelected, setGroupsSelected] = React.useState([]);
    const [groupsFilter, setGroupsFilter] = React.useState(null);
    const [generalGroupsSelected, setGeneralGroupsSelected] = React.useState([]);
    const [generalGroupsFilter, setGeneralGroupsFilter] = React.useState(null);
    const [showSendContent, setShowSendContent] = React.useState(false);
    const [redirectId, setRedirectId] = React.useState(false);

    return (
        <ReleaseStateContext.Provider value={{ setGeneralGroupsSelected, generalGroupsSelected, generalGroupsFilter, setGeneralGroupsFilter, redirectId, setRedirectId, releaseState, setReleaseState, groupsSelected, setGroupsSelected, groupsFilter, setGroupsFilter, showSendContent, setShowSendContent }}>
            {children}
        </ReleaseStateContext.Provider>
    );
};

export { ReleaseStateContext, ReleaseStateProvider };