import * as React from 'react';

const initialState = {
  data: null,
  startDate: null,
  endDate: null
};

const PressReportStateContext = React.createContext(initialState);

const PressReportStateProvider = ({ children }) => {
  const [pressReportState, setPressReportState] = React.useState(initialState);
  return (
    <PressReportStateContext.Provider value={[pressReportState, setPressReportState]}>
      {children}
    </PressReportStateContext.Provider>
  );
};

export { PressReportStateContext, PressReportStateProvider };
