import i18n from "i18next";
import { PressBoilerPlatePT, PressBoilerPlateEN, PressHeaderPT, PressHeaderEN, PressFooterPT, PressFooterEN } from '../utilities/Constants';

export function richTextDefault(args, lang, id) {
    let language;
    if (lang === "pt" || lang === "en") {
        language = lang;
    } else {
        language = lang ? lang === '1' ? 'pt' : 'en' : i18n.language;
    }
    return (
        `<style>#key * {color: #747876;font-size: 14px;line-height: 24px;font-family: Arial;margin-bottom: 15px;}</style>
        <!--[if mso]>
            <style type="text/css">
                #rteDefaultTable {
                    margin: 0px 0px 0px 24px !important;
                    padding: 0px 0px 20px 0px !important;
                }                
            </style>
        <![endif]-->
        <table id='rteDefaultTable' align='center' border='0' cellpadding='0' cellspacing='0' width='600' style="color: rgb(116, 118, 120);width: 600px; border-collapse: collapse;max-width:600px;word-break:break-word;">
            <thead>
                <tr contentEditable='false'>
                    <td>
                        <table border='0' cellpadding='0' cellspacing='0' width='600'>
                            <tr id='header'>
                                <tr id="tdSecondHeader">
                                    <td>
                                        ${getImagesTitle(id, lang) || ''}
                                    </td>
                                </tr>
                            </tr>
                        </table>    
                    </td>
                </tr>
                <tr>
                    <td>
                        <p style="font-size:5pt;font-family:Calibri,sans-serif;margin:0;"><span style="color:#868888;">&nbsp;</span></p>
                    </td>
                </tr>
            </thead>
            <tbody id='rteDefaultTBody' contentEditable='false' width="600">
                <tr>
                    <td>
                        <p style="margin:0;margin-top:20px;">
                            <span id='rteDate' style='color: #EDB111;font-weight: bold; font-family: Arial, sans-serif, serif, EmojiFont;font-size:11pt'>
                                ${args.date.toLocaleDateString(lang === 2 ? "en" : "pt") || ''}
                            </span>
                        </p>
                        <h1 style='font-size:24pt;font-family:Calibri,sans-serif;font-weight:bold;line-height: 26px;margin-bottom: 0;'>
                            <span id='rteTitle' style='color: #007E7A; font: bold 14pt/14pt Arial; text-align: left;' >
                                ${args.title || ''}
                            </span>
                        </h1>
                        <p style="font-size:4pt;font-family:Calibri,sans-serif;margin:0;"><span style="color:#868888;">&nbsp;</span></p>
                    </td>
                </tr>
                <!--[if !mso]>-->
                    <tr style="height:6.0pt"><td style="padding:0cm 0cm 0cm 0cm; height:6.0pt"></td></tr>
                    <tr style="height:12.0pt"><td style="padding:0cm 0cm 0cm 0cm; height:12.0pt"></td></tr>
                <!--<![endif]-->
                <tr>
                    <td>
                    <h2 style='font-size:18pt;font-family:Calibri,sans-serif;font-weight:bold;margin: 0;'>
                        <i>
                            <span id='rteSubtitle' style='display: block;color: rgb(116, 118, 120); font: 13pt/16pt Arial;text-align: left;  font-weight: normal; font-style:italic; line-height:26px; margin: 0'>
                                <font face="Arial" size="2" color="#747678" style=""> ${args.subtitle || ''} </font>
                                <ul style="list-style-type: none; visibility:hidden;"></ul>
                            </span>
                        </i>
                    </h2>
                        <p style="font-size:5pt;font-family:Calibri,sans-serif;margin:0;"><span style="color:#868888;">&nbsp;</span></p>
                    </td>
                </tr>
                <tr style='mso-yfti-irow:5;height:6.0pt'>
                    <td style='padding:0cm 0cm 0cm 0cm;height:6.0pt'><!-- --></td>
                </tr>
                <tr style='mso-yfti-irow:6;height:12.0pt'>
                    <td style='padding:0cm 0cm 0cm 0cm;height:12.0pt'><!-- --></td>
                </tr>
                <tr>
                    <td id='rteDefaultTBodyContent' style='line-height: 16.5pt;word-break:break-word;color: rgb(116, 118, 120); font-size: 11pt; font-family: Arial, sans-serif, serif, EmojiFont;max-width: 600px;' contentEditable='true'>
                        ${args.content || ''}
                    </td>
                </tr>
                <tr>
                    <td id="rteVideo" contentEditable='false'>
                    </td>
                </tr>
                <tr contentEditable='false'>
                    <td>
                        <p style="font-size:5pt;font-family:Calibri,sans-serif;margin:0;"><span style="color:#868888;">&nbsp;</span></p>
                    </td>
                </tr>
                <tr>
                    <td id='boiler' contentEditable='false'>
                        ${args.boiler || ''}
                    </td>
                </tr>
                <tr>
                    <td id='signature' contentEditable='true'>
                        ${args.signature || ''}
                    </td>
                </tr>
                <tr id="rteAttachments" contentEditable='false'>
                </tr>
                <tr contentEditable='false'>
                    <td id="rteAudios"></td>
                </tr>
            </tbody>
            <tfoot>
            <tr id="footerVale">
                <td>
                    ${language === "pt" ? PressFooterPT || '' : PressFooterEN || ''}
                </td>
            </tr>
            </tfoot>
        </table>`
    );
}

{/* <tr>
    <td id='footer' contentEditable='true'>
        ${args.footer}
    </td>
</tr> */}


export function getReleaseAttachmentsHeader(attachmentTitle, rows) {
    return (
        `<table align='left' border='0' cellpadding='0' cellspacing='0'>
            <tr id="attachmentsHeader" contentEditable='false'>
                <td colspan="4" style="padding:15px 20px;background: #F7F9F9;">
                    <span id='attachmentItem' style="color: #EDB111; font-family: Arial;font-size: 18px; font-weight: bold;">
                        ${attachmentTitle || ''}
                    </span>
                </td>
            </tr>
            <tr id="rowAttachments" contentEditable='true'>
            </tr>
        </table>`
    );
}

export function getReleaseDefault(type, lang) {
    let language = lang ? lang === 1 ? 'pt' : 'en' : i18n.language;
    switch (type) {
        case 1:
            return {
                header: getImagesTitle(type, language),
                date: new Date(),
                title: language === "pt" ? "Título do release..." : "Release title...",
                subtitle: "",
                content: language === "pt" ? "Digite aqui um conteúdo para o release..." : "Type the release content in here...",
                boiler: '',
                footer: ''
            };
        case 2:
            return {
                header: getImagesTitle(type, language),
                date: new Date(),
                title: language === "pt" ? "Título da nota..." : "Statement title...",
                subtitle: "",
                content: language === "pt" ? "Digite aqui um conteúdo para a nota..." : "Type the statement content in here...",
                boiler: '',
                footer: ''
            };
        case 3:
            return {
                header: getImagesTitle(type, language),
                date: new Date(),
                title: language === "pt" ? "Título do posicionamento..." : "Position paper title...",
                subtitle: "",
                content: language === "pt" ? "Digite aqui um conteúdo para o posicionamento..." : "Type the position paper content in here...",
                boiler: '',
                footer: ''
            };
        case 4:
            return {
                header: getImagesTitle(type, language),
                date: new Date(),
                title: language === "pt" ? "Título do Q&A..." : "Q&A title...",
                subtitle: "",
                content: language === "pt" ? "Digite aqui um conteúdo para o Q & A..." : "Type the Q&A content in here...",
                boiler: '',
                footer: ''
            };
        case 6:
            return {
                header: getImagesTitle(type, language),
                date: new Date(),
                title: language === "pt" ? "Título do aviso de webcast..." : "Webcast notice title...",
                subtitle: "",
                content: language === "pt" ? "Digite aqui um conteúdo para o aviso de webcast..." : "Type the webcast notice content in here...",
                boiler: '',
                footer: ''
            };
        case 7:
            return {
                header: getImagesTitle(type, language),
                date: new Date(),
                title: language === "pt" ? "Título do aviso de pauta..." : "Assignment notice title...",
                subtitle: "",
                content: language === "pt" ? "Digite aqui um conteúdo para o aviso de pauta..." : "Type the assignment notice content in here...",
                boiler: '',
                footer: ''
            };
    }
}

export function getReleaseByProps(props) {
    return {
        header: '',
        date: new Date(props.data),
        title: props.titulo,
        subtitle: props.subtitulo,
        content: props.conteudo,
        boiler: '',
        footer: ''
    }
}

export function emailSnippets(type, lang) {
    switch (type) {
        case "Cab": {
            switch (lang) {
                case 'pt':
                    return (
                        PressHeaderPT
                    )
                case 'en':
                    return (
                        PressHeaderEN
                    )
            }
        }
        case "Rod":
            switch (lang) {
                case 'pt':
                    return (
                        PressFooterPT
                    )
                case 'en':
                    return (
                        PressFooterEN
                    )
            }
        case "BoPla":
            switch (lang) {
                case 'pt':
                    return (
                        PressBoilerPlatePT
                    )
                case 'en':
                    return (
                        PressBoilerPlateEN
                    )
            }
        //case "Img":
        //    return {
        //        insertImage();
        //    };
    }
}

export function getImagesTitleString(id, lang) {
    let language;
    if (lang === "pt" || lang === "en") {
        language = lang;
    } else {
        language = lang ? lang === '1' ? 'pt' : 'en' : i18n.language;
    }

    switch (id) {
        case 1:
            return "header.jpg"
        case 2:
            return language === "pt"
                ? "Cabeçalho_Nota_PT.png"
                : "Cabeçalho_Nota_EN.png"
        case 3:
            return language === "pt"
                ? "Cabeçalho_Posicionamento_PT.png"
                : "Cabeçalho_Posicionamento_EN.png"
        case 4:
            return language === "pt"
                ? "Cabeçalho_QA_PT.png"
                : "Cabeçalho_QA_EN.png"
        case 6:
            return language === "pt"
                ? "Cabeçalho_Webcast_PT.png"
                : "Cabeçalho_Webcast_EN.png"
        case 7:
            return language === "pt"
                ? "Cabeçalho_Aviso_PT.png"
                : "Cabeçalho_Aviso_EN.png"
    }
}

export function getImagesTitle(id, lang) {
    let language;
    if (lang === "pt" || lang === "en") {
        language = lang;
    } else {
        language = lang ? lang === '1' ? 'pt' : 'en' : i18n.language;
    }

    switch (id) {
        case 1:
            return `<img src="${process.env.REACT_APP_AZURE_STORAGE}/press/System/header.jpg" width="596" height="69" alt="" style="display: block;" />`
        case 2:
            return language === "pt"
                ? `<img src="${process.env.REACT_APP_AZURE_STORAGE}/press/System/Cabeçalho_Nota_PT.png" width="596" height="69" alt="" style="display: block;" />`
                : `<img src="${process.env.REACT_APP_AZURE_STORAGE}/press/System/Cabeçalho_Nota_EN.png" width="596" height="69" alt="" style="display: block;" />`
        case 3:
            return language === "pt"
                ? `<img src="${process.env.REACT_APP_AZURE_STORAGE}/press/System/Cabeçalho_Posicionamento_PT.png" width="596" height="69" alt="" style="display: block;" />`
                : `<img src="${process.env.REACT_APP_AZURE_STORAGE}/press/System/Cabeçalho_Posicionamento_EN.png" width="596" height="69" alt="" style="display: block;" />`
        case 4:
            return language === "pt"
                ? `<img src="${process.env.REACT_APP_AZURE_STORAGE}/press/System/Cabeçalho_QA_PT.png" width="596" height="69" alt="" style="display: block;" />`
                : `<img src="${process.env.REACT_APP_AZURE_STORAGE}/press/System/Cabeçalho_QA_EN.png" width="596" height="69" alt="" style="display: block;" />`
        case 6:
            return language === "pt"
                ? `<img src="${process.env.REACT_APP_AZURE_STORAGE}/press/System/Cabeçalho_Webcast_PT.png" width="596" height="69" alt="" style="display: block;" />`
                : `<img src="${process.env.REACT_APP_AZURE_STORAGE}/press/System/Cabeçalho_Webcast_EN.png" width="596" height="69" alt="" style="display: block;" />`
        case 7:
            return language === "pt"
                ? `<img src="${process.env.REACT_APP_AZURE_STORAGE}/press/System/Cabeçalho_Aviso_PT.png" width="596" height="69" alt="" style="display: block;" />`
                : `<img src="${process.env.REACT_APP_AZURE_STORAGE}/press/System/Cabeçalho_Aviso_EN.png" width="596" height="69" alt="" style="display: block;" />`
    }
}
