import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { TextBoxComponent, MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

import './utilities.css';
import { Redirect } from "react-router-dom";

let userPermission = [];

export const Actions = (props) => {

    //Opções:
    //Boolean: savePrimary / excludePrimary
    //String: class/ urlView / urlPreview / urlDuplicate / urlSave / urlSend / urlCancel / urlDelete
    const { controls } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);

    React.useEffect(() => {
        if (controls.area) {
            let globalPermissions = globalState.permissions;
            userPermission = globalPermissions.filter((i) => i.accessArea === controls.area).map((i) => (i.permission));
        }
    }, [globalState]);

    const customPermissionToDelete = () => {
        if (typeof controls.customPermissionsDelete ==="undefined") {
            return (controls.urlDelete && globalState.userAdmin ||
                (controls.area &&
                    userPermission.indexOf("Admin") !==-1 || userPermission.indexOf("Exclusão") !==-1
                ));
        } else {
            return controls.customPermissionsDelete;
        }
    }

    const customPermission = () => {
        if (typeof controls.customPermissions ==="undefined") {
            return (controls.urlSave && globalState.userAdmin ||
                (controls.area &&
                    userPermission.indexOf("Admin") !==-1 || userPermission.indexOf("Inclusão") !==-1 || userPermission.indexOf("Alteração") !==-1 || controls.hasPermissionToEdit
                ));
        } else {
            return controls.customPermissions;
        }
    }


    return (
        <>

            <div className={`actions ${controls.class}`}>
                <div className="top d-flex flex-row align-items-center">
                    <div className="mdl2 mdl2-actions" />
                    <h2>{t('Actions:title')}</h2>
                    {/* {controls.urlView &&
                            <ButtonComponent
                            id="view"
                            cssClass="e-flat e-primary ml-auto"
                            onClick={controls.urlView}
                            >{t('Actions:view')}</ButtonComponent>
                        } */}
                </div>
                {controls.urlPreview &&
                    <div className="highlight">
                        <ButtonComponent
                            id="preview"
                            cssClass="e-flat e-primary"
                            onClick={controls.urlPreview}
                        >{t('Actions:preview')}</ButtonComponent>
                    </div>
                }
                <div className="content d-flex flex-column">
                    {controls.urlReturn &&
                        <ButtonComponent
                            id="return"
                            cssClass="e-flat e-primary"
                            onClick={controls.urlReturn}
                        >{t('Actions:return')}</ButtonComponent>
                    }
                    {controls.urlDuplicateLiaison &&
                        <ButtonComponent
                            id="duplicateLiaison"
                            cssClass="e-flat e-primary"
                            onClick={controls.urlDuplicateLiaison}
                        >{t('Actions:duplicateLiaison')}</ButtonComponent>
                    }
                    {controls.urlForward &&
                        <ButtonComponent
                            id="forward"
                            cssClass="e-flat e-primary"
                            onClick={controls.urlForward}
                        >{t('Actions:forward')}</ButtonComponent>
                    }
                    {controls.urlDuplicate &&
                        <ButtonComponent
                            id="duplicate"
                            cssClass="e-flat e-primary"
                            onClick={controls.urlDuplicate}
                        >{t('Actions:duplicate')}</ButtonComponent>
                    }
                    {controls.previewSignature &&
                        <ButtonComponent
                            id=""
                            cssClass="e-primary"
                            onClick={controls.previewSignature}
                        >{t('Actions:previewSignature')}</ButtonComponent>
                    }
                    {customPermission() &&
                        <ButtonComponent
                            id="save"
                            cssClass={`${controls.savePrimary && 'e-flat'} e-primary`}
                            form={controls.form}
                            onClick={controls.urlSave}
                            disabled={props.disabled}
                        >{t('Actions:save')}</ButtonComponent>
                    }
                    {controls.urlSend &&
                        <ButtonComponent
                            id=""
                            disabled={controls.sendDisabled}
                            cssClass="e-primary"
                            onClick={controls.urlSend}
                        >{t('Actions:send')}</ButtonComponent>
                    }
                    {controls.urlCancel &&
                        <ButtonComponent
                            id="cancel"
                            cssClass={`e-flat ${controls.excludePrimary ? 'e-primary' : 'e-danger'}`}
                            onClick={controls.urlCancel}
                        >{t('Actions:cancel')}</ButtonComponent>
                    }
                    {customPermissionToDelete() &&
                        <ButtonComponent
                            id="exclude"
                            cssClass="e-flat e-danger"
                            onClick={controls.urlDelete}
                        >{t('Actions:delete')}</ButtonComponent>
                    }
                </div>
            </div>
        </>
    )
}
