import * as React from "react";
import i18n from "i18next";
import { setCulture, setCurrencyCode } from '../../../node_modules/@syncfusion/ej2-base';
import { GlobalStateContext } from './GlobalState';
import { DropDownButtonComponent, ItemModel  } from '@syncfusion/ej2-react-splitbuttons';
import { MenuComponent } from '@syncfusion/ej2-react-navigations';
import './general.css';


export const LanguageComponent = () => {
    let dropdownBtnRef;
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);

    const items = [{
        id: "pt",
        text: 'Português - BR',
        iconCss: 'e-ddb-icons e-ptBr',
        selected: i18n.language === 'pt'
    },
    {
        id: "en",
        text: 'English - US',
        iconCss: 'e-ddb-icons e-enUS',
        selected: i18n.language === 'en'
    }];

    let onLanguageSelect = (e) => {
        setGlobalState(globalState => ({
            ...globalState,
            userLanguage: e.item.id
        }))
        // setCulture(e.item.id);
        window.location.reload();
        i18n.changeLanguage(e.item.id);

    };

    React.useEffect(() => {
        dropdownBtnRef.items = items.filter(item => !item.selected);

    }, [i18n.language]);

    return (
        <>
            <ul id="languageDropdown" role="menu">
                {items.filter(item => !item.selected).map((item, index) => (
                    <li id={item.id} className="e-item" key={index}>
                        <span className={`e-btn-icon ${item.iconCss} e-icon-left`}></span>
                        {item.text}
                    </li>
                ))}
            </ul>
            <DropDownButtonComponent
                ref={dropdownButton => dropdownBtnRef = dropdownButton}
                target="#languageDropdown"
                className="choosenLanguage"
                select={(e) => { onLanguageSelect(e) }}
            >
                    <span className={`e-btn-icon ${items.filter(item => item.selected)[0].iconCss} e-icon-left`}></span>
                    {items.filter(item => item.selected)[0].text}
            </DropDownButtonComponent>
        </>
    );
}
