import * as React from "react";
import { Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { GlobalStateContext } from '../general/GlobalState'
import { useTranslation } from 'react-i18next';
import { Actions } from "../utilities/Actions";
import '../profile/profile.css';
import { getMyProfile, updateMyProfile } from "../../services/UsersSVC";
import { TemplatesLists } from "../templates/TemplateLists";
import { Buttons } from "../utilities/Buttons";
import PermissionRedirect from "../utilities/PermissionRedirect/PermissionRedirect";

export const Templates = () => {

    const [globalState] = React.useContext(GlobalStateContext);
    const [dataUser, setDataUser] = React.useState('');
    const [redirect, setRedirect] = React.useState(false);
    const [idTemplate, setIdTemplate] = React.useState(null);

    const { t } = useTranslation();

    const _controls = {
        export: "",
        import: "",
        new: () => setRedirect(true),
        back: "",
    };

    let editTemplates = (id) => {
        setIdTemplate(id);
    };

    React.useEffect(() => {
        if (idTemplate) {
            setRedirect(true);
        }
    }, [idTemplate]);

    return (
        <>
            {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :

                redirect ? (
                    idTemplate === null ?
                        <Redirect push={true} to="/admin/profiletemplatesItem" /> :
                        <Redirect push={true} to={`/admin/profiletemplatesItem/${idTemplate}`} />
                ) : (
                    <>
                        <PermissionRedirect id="52" />
                        <div className="DefaultTop" />
                        <Container className="containerTop">
                            <div className="topTitleButtons">
                                <Row>
                                    <div className="col-sm-8 col-12">
                                        <div className="title">
                                            <h1>{t('ProfileTemplates:title')}</h1>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-12">
                                        <div className="buttons">
                                            <Buttons controls={_controls} />
                                        </div>
                                    </div>
                                </Row>
                            </div>
                            <Row>
                                <div className="col-12">
                                    <TemplatesLists edit={editTemplates} />
                                </div>
                            </Row>
                        </Container>
                    </>
                )
            }
        </>
    );
}