import * as React from "react";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from '../general/GlobalState';
import { LoginStateContext } from '../login/LoginState';
import { EnterComponent } from '../login/EnterComponent';
import { ForgotComponent } from '../login/ForgotComponent';
import { ResetComponent } from '../login/ResetComponent';
import { ReactComponent as ReactLogo } from '../../css/imgs/logo_vale.svg';
import '../login/login.css';

export const LoginComponent = () => {

    const [loginState] = React.useContext(LoginStateContext);
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const { t } = useTranslation(); 

    const stepLogin = loginState.step ===1 ? "enter" : loginState.step ===2 ? "forgot" : loginState.step ===3 ? "reset" : "";
    // const login = globalState.accessToken !==null ? "" : "login";

    return (
        <div className={`login ${stepLogin}`}>
            <div className="boxLogin">
                {/*<div className="topo">
                    <div className="systemName">
                        <span>{t('Generic:initials')}</span>
                        <span>{t('Generic:title')}</span>
                    </div>
                    <ReactLogo />
                </div>*/}
                {loginState.step ===1 &&
                    <EnterComponent />
                }
                {loginState.step ===2 &&
                    <ForgotComponent />
                }
                {loginState.step ===3 &&
                    <ResetComponent />
                }
            </div>
        </div>
    );
}

// Criar função que envia formulário ao clicar na tecla Enter para todo os formulários