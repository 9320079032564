import React, { useState, useContext, useEffect, useCallback } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from "../general/GlobalState";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { UploaderComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useMessageHouse } from "./context/MessageHouseContext";
import { useForm } from "react-hook-form";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { addItem, updateItem, getMessageAttachment } from '../../services/MessagesHouseSVC';
import { useModal } from "../../hooks/Modal";
import { uuidv4 } from "../utilities/Utils";


const MessageHouseModal = () => {

    const target = document.getElementById("uploadAttachment");

    const [globalState] = useContext(GlobalStateContext);
    const [uploadChunkSize, setUploadChunkSize] = React.useState(1000000);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [updateUpload, setUpdateUpload] = useState(false);
    const [blockIdList, setBlockIdList] = useState([]);

    const { setOpenEditModal, openEditModal, modalData, setModalData, areasDICI, setMessageHouseItems, messageHouseItems, tempGuid, setTempGuid } = useMessageHouse();

    const { buildDialogMessage } = useModal();

    const { t } = useTranslation();

    const onSubmitClick = (data) => {
        setOpenEditModal(false);

        console.log(data);
        if (modalData) {
            updateMessageHouseItem(data);
        } else {
            addMessageHouseItem(data);
        }
    }


    const addMessageHouseItem = async data => {
        const item = {
            ...data,
            ativo: true,
            nomeArquivo: uploadedFile && uploadedFile.name
        }

        const newItem = await addItem(globalState.accessToken, JSON.stringify(item)).then(async res => {
            if (res.ok) {
                return res.json();
            } else {
                buildDialogMessage({
                    error: res.ok,
                })
            }
        });

        setMessageHouseItems(state => [...state, newItem]);
    };

    const updateMessageHouseItem = async data => {
        const item = {
            ...data,
            id: modalData.id,
            ativo: true,
            nomeArquivo: uploadedFile && uploadedFile.name
        }

        const newItem = await updateItem(globalState.accessToken, JSON.stringify(item)).then(async res => {
            if (res.ok) {
                return res.json();
            } else {
                buildDialogMessage({
                    error: res.ok,
                })
            }
        });

        if (newItem && newItem.id) {
            const oldMessagesHouseItems = [...messageHouseItems];
            const index = oldMessagesHouseItems.findIndex(messageItem => messageItem.id ===modalData.id);

            oldMessagesHouseItems[index] = newItem;

            setMessageHouseItems(oldMessagesHouseItems);
        }
    };

    let dropAreaRef;
    let uploadObj;
    let isInteraction;

    const chunkComplete = (args) => {
        const validExtensions = ["jpg", "png", "jpeg", "docx", "doc", "ppt", "pdf"];
        setValue("attachment", true)
        setUploadedFile(args.file.rawFile)
        setUpdateUpload(true);
    }

    const uploadImage = () => {
        document.getElementsByClassName("e-file-select-wrap")[0].querySelector("button").click();
        return false;
    }

    const chunkUploaded = (e) => {
        //let responseBlockId = JSON.parse(e.event.currentTarget.response);
        setBlockIdList(blockIdList => [...blockIdList, e.event.currentTarget.response]);
    }

    const onResuming = (args) => {
        if (args.event !== null && !navigator.onLine) {
            isInteraction = true;
        } else {
            isInteraction = false;
        }
    }

    const whenFileSelected = (e) => {
        let chunkSize = e.fileInfo.size / 10;
        //uploadObj.asyncSettings.chunkSize = chunkSize;//parseInt(e.itemData.value, 10);
        setUploadChunkSize(uploadChunkSize => uploadChunkSize = chunkSize);
    }

    const onBeforeFailure = (args) => {
        //let proxy = this;
        args.cancel = isInteraction;
        // interval to check network availability on every 500 milliseconds
        let clearTimeInterval = setInterval(() => {
            if (navigator.onLine && uploadObj && uploadObj.filesData[0] && uploadObj.filesData[0].statusCode ===4) {
                uploadObj.resume(uploadObj.filesData);
                clearSetInterval();
            } else {
                if (!isInteraction && uploadObj && uploadObj.filesData[0] && uploadObj.filesData[0].statusCode ===3) {
                    uploadObj.pause(uploadObj.filesData);
                }
            }
        }, 500);
        // clear Interval after when network is available.
        const clearSetInterval = () => {
            clearInterval(clearTimeInterval);
        }
    }

    const fileUploading = (e) => {
        const validExtensions = ["jpg", "png", "jpeg", "docx", "doc", "ppt", "pdf"];
        if (uploadObj && validExtensions.find(file => file ===e.fileData.type)) {
            e.currentRequest.setRequestHeader("Authorization", "Bearer " + globalState.accessToken);
            if (blockIdList.length > 0) e.currentRequest.setRequestHeader("blockIdList", blockIdList.join(','));
            setError("attachment", "");
        } else {
            setError("attachment", "extension");
            if (uploadObj) {
                uploadObj.clearAll();
            }
        }
    }

    const onPausing = (args) => {
        if (args.event !== null && !navigator.onLine) {
            isInteraction = true;
        } else {
            isInteraction = false;
        }
    }

    const onRemoveFile = (args) => {
        if (false) {
            args.postRawFile = false;
            args.currentRequest.setRequestHeader("Authorization", "Bearer " + globalState.accessToken);
        }
    }

    const asyncSettings = {
        saveUrl: `${process.env.REACT_APP_API_URL}/_api/MessagesHouse/UploadAttachmentChunks?IdOrGuidMessage=${tempGuid ? tempGuid : modalData && modalData.id}`,
        removeUrl: `${process.env.REACT_APP_API_URL}/_api/MessagesHouse/RemoveAttachment?IdOrGuidMessage=${tempGuid ? tempGuid : modalData && modalData.id}`,
        chunkSize: uploadChunkSize
    };

    const autoUpload = true;

    const { register, handleSubmit, setValue, errors, setError, reset } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            titulo: modalData && modalData.titulo,
            descricao: modalData && modalData.descricao,
            areaDICI: modalData && modalData.areaDICI,
            attachment: ""
        },
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    });

    let modalButtons = [
        {
            type: "submit",
            buttonModel: {
                isPrimary: true,
                content: i18n.language ==="pt" ? "Salvar" : "Save",
                cssClass: "bannerSave"
            },
            click: () => {
                handleSubmit(onSubmitClick)();
            }
        }
    ]

    const downloadAttachment = (fileName, idOrGuid) => {
        console.log(fileName, idOrGuid);
        getMessageAttachment(globalState.accessToken, idOrGuid)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    };

    const removeAttachment = () => {
        setValue("attachment", null);
        const newModalData = { ...modalData };
        newModalData.nomeArquivo = "";
        setModalData(newModalData);
    };

    const getError = (error) => {
        switch (error.type) {
            case "required": {
                return (
                    <div className="error">{t("Validation:required")}</div>
                )
            }
            case "extension": {
                return (
                    <div className="error">{t("Validation:extensionNotAllowed")}</div>
                )
            }
        }
    }

    useEffect(() => {
        if (!modalData) {
            setTempGuid(uuidv4());
        } else if (modalData && modalData.nomeArquivo) {
            setValue("attachment", "hasAttachment");
        }
    }, [modalData]);

    useEffect(() => {
        register("areaDICI", {
            required: true
        })
    }, [register]);

    return (
        <DialogComponent
            id="modalNewMessage"
            width="662px"
            isModal={true}
            buttons={modalButtons}
            visible={openEditModal}
            showCloseIcon={true}
            close={() => setOpenEditModal(false)}
            locale={i18n.language}
        >
            <form>
                <div className="input">
                    <label htmlFor="title">{t("MessageHouse:column_title")} *</label>
                    <div className="e-float-input">
                        <TextBoxComponent
                            id="titulo"
                            name="titulo"
                            ref={register({
                                required: true
                            })}
                            htmlAttributes={{ maxlength: "100" }}
                            type="text"
                            placeholder={i18n.language ==="pt" ? "Digite aqui o título..." : "Type the title here ..."}
                            value={modalData && modalData.titulo}
                        />
                        {errors && errors.titulo && getError(errors.titulo)}

                    </div>
                </div>
                <div className="input">
                    <label htmlFor="description">{t("MessageHouse:column_description")}</label>
                    <div className="e-float-input">
                        <TextBoxComponent
                            id="descricao"
                            name="descricao"
                            ref={register({
                                required: true
                            })}
                            htmlAttributes={{ maxlength: "200" }}
                            type="text"
                            placeholder={i18n.language ==="pt" ? "Digite aqui uma descrição ..." : "Type a description here ..."}
                            multiline={true}
                            value={modalData && modalData.descricao && modalData.descricao}
                        />

                        {errors && errors.descricao && getError(errors.descricao)}
                    </div>
                </div>

                <input ref={register({
                    required: true
                })} type="text" id="attachment" name="attachment" style={{ display: "none" }} />

                <div className="input">
                    <label htmlFor="area">{t("MessageHouse:column_area")}</label>
                    <div className="e-float-input">
                        <DropDownListComponent
                            id="areaDICI"
                            name="areaDICI"
                            dataSource={areasDICI}
                            maxLength="20"
                            type="text"
                            fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                            placeholder={i18n.language ==="pt" ? "Selecione a área" : "Select area"}
                            value={modalData && modalData.areaDICI && modalData.areaDICI.id}
                            change={(args) => setValue("areaDICI", args.itemData)}
                        />
                        {errors && errors.areaDICI && getError(errors.areaDICI)}
                    </div>
                </div>
                <div className="input">
                    <label htmlFor="area">{t("MessageHouse:column_attachment")} *</label>
                    <div ref={dropAreaEle => dropAreaRef = dropAreaEle} className="upload" id="uploadAttachment">
                        <div className="new">
                            <p>{i18n.language ==="pt" ? "Arraste o arquivo aqui ou faça o" : "Drag the file here or"}<span onClick={() => uploadImage()}> {i18n.language ==="pt" ? "carregamento" : "upload"}</span></p>
                        </div>
                    </div>
                </div>

                {modalData && modalData.nomeArquivo && !updateUpload &&
                    <div className="e-upload e-control-wrapper e-lib e-keyboard e-form-upload">
                        <ul className="e-upload-files">
                            <li className="e-upload-file-list" data-files-count="1">
                                <span className="e-file-container pointerClick" onClick={() => downloadAttachment(modalData.nomeArquivo, modalData.id)}>
                                    <span className="e-file-name">{modalData.nomeArquivo}</span>
                                </span>
                                <span className="e-icons e-file-remove-btn" title="Retirar" onClick={() => removeAttachment()} />
                            </li>
                        </ul>
                    </div>
                }

                <div id="validateImage" />

                <UploaderComponent
                    id="UploadFiles"
                    name="upload"
                    type="file"
                    asyncSettings={asyncSettings}
                    // allowedExtensions=".jpg, .png, .jpeg, .docx, .doc, .ppt, .pdf"   
                    dropArea={target}
                    htmlAttributes={{ tabindex: "0" }}
                    maxFileSize={1000000000}
                    locale={i18n.language}
                    removing={onRemoveFile}
                    pausing={onPausing}
                    uploading={fileUploading}
                    chunkUploading={fileUploading}
                    chunkFailure={onBeforeFailure}
                    fileListRendering={whenFileSelected}
                    chunkSuccess={chunkUploaded}
                    success={(chunkComplete)}
                    resuming={onResuming}
                    multiple={false}
                    ref={upload => uploadObj = upload}
                />

                {errors && errors.attachment && getError(errors.attachment)}

                <div>
                </div>
            </form>
        </DialogComponent>
    )
}

export default MessageHouseModal;