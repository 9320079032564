import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { GlobalStateContext } from '../general/GlobalState';
import { useTranslation } from 'react-i18next';
import { Annual } from '../manageAnnualBudget/AnnualContent';
import { Actions } from '../utilities/Actions';
import { Buttons } from '../utilities/Buttons';

import '../manageAnnualBudget/manageAnnualBudget.css';

export const ManageAnnualBudget = () => {
  const [globalState] = React.useContext(GlobalStateContext);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [dataBanner, setDataBanner] = React.useState(null);

  const { t } = useTranslation();

  const _controls = {
    export: '',
    import: '',
    new: () => editBanner(null),
    back: '',
  };

  let editBanner = (args) => {
    setDataBanner(args);
    setOpenEditModal(true);
  };

  return (
    <>
      {globalState.accessToken === null ? (
        <Redirect push={true} to="/login" />
      ) : (
        <>
          <div className="DefaultTop" />

          <Container className="containerTop">
            <Row>
              <div className="col-12">
                <Annual/>
              </div>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};
