import { requestAPI } from '../components/utilities/Utils';

export async function getMailingContactItems(token, qstring) {
    return requestAPI("_api/MailingContact/GetMailingContactItems", "GET", qstring, null, token, false);
}

export async function getMailingContactItem(token, id) {
    const qstring = `?mailingContactId=${id}`;
    return requestAPI("_api/MailingContact/GetMailingContactItem", "GET", qstring, null, token, false);
}

export async function getImportMailingContactsTemplate(token) {
    const qstring = `?token=${token}`;
    return requestAPI("_api/MailingContact/GetImportMailingContactsTemplate", "GET", qstring, null, token, false);
}

export async function addOrUpdateContactItem(token, body) {
    const raw = JSON.stringify(body);
    const endPointURL = body.id ? "_api/MailingContact/UpdateMailingContactItem" : "_api/MailingContact/AddMailingContactItem"
    return requestAPI(endPointURL, "POST", "", raw, token, false);
}

export async function deleteMailingContact(token, id) {
    const qstring = `?mailingContactId=${id}`;
    return requestAPI("_api/MailingContact/DeleteMailingContactItem", "POST", qstring, null, token, false);
}

export async function deleteMailingContactPhoto(token, id) {
    const qstring = `?mailingContactId=${id}`;
    return requestAPI("_api/MailingContact/DeleteMailingContactPhoto", "POST", qstring, null, token, false);
}

export async function importMailingContacts(token, file) {
    var formData = new FormData();
    formData.append("importMailingContactsFile", file);
    return requestAPI("_api/MailingContact/ImportMailingContacts", "POST", "", formData, token, true);
}

export function getMailingContactPhoto(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
        if (xhr.readyState ===4) {
            callback(this.response)
        }
    }
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export async function getMailingGroupItems(token, qstring = "") {
    return requestAPI("_api/MailingGroup/GetMailingGroupItems", "GET", qstring, null, token, false);
}

export async function getMailingGroupItem(token, id) {
    const qstring = `?mailingGroupId=${id}`;
    return requestAPI("_api/MailingGroup/GetMailingGroupItem", "GET", qstring, null, token, false);
}

export async function addOrUpdateGroupItem(token, body) {
    const raw = JSON.stringify(body);
    const endPointURL = body.id ? "_api/MailingGroup/UpdateMailingGroupItem" : "_api/MailingGroup/AddMailingGroupItem"
    return requestAPI(endPointURL, "POST", "", raw, token, false);
}

export async function deleteMailingGroupItem(token, id) {
    const qstring = `?mailingGroupId=${id}`;
    return requestAPI("_api/MailingGroup/DeleteMailingGroupItem", "POST", qstring, null, token, false);
}

export async function exportMailingGroupContacts(token, id, language) {
    const qstring = `?mailingGroupId=${id}&language=${language}`;
    return requestAPI("_api/MailingGroup/ExportMailingGroupContacts", "GET", qstring, null, token, false);
}
