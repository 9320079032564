import * as React from "react";
import i18n from "i18next";
import { Redirect } from "react-router-dom";
import { useTranslation, getInitialProps } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { TextBoxComponent, MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { Tooltip } from "@syncfusion/ej2-react-popups";
import { createSpinner, setSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { getProfileAreas, getProfileManagement, getProfileDepartments } from '../../services/SystemSVC';
import { users } from "../../services/UsersSVC";

export const ProfData = (props) => {


    const departmenRef = React.useRef(null);
    const managementRef = React.useRef(null);
    const businessAreaRef = React.useRef(null);
    const superiorRef = React.useRef(null);

    const { setInfo, info, admin, hasPermissionToEdit } = props;

    const [loadingProf, setLoadingProf] = React.useState(true);
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const [hasPermission, setHasPermission] = React.useState(true);
    const [areaSelect, setAreaSelect] = React.useState([]);
    const [departamentSelect, setDepartamentSelect] = React.useState([]);
    const [managementSelect, setManagementSelect] = React.useState([]);
    const [immediateLeader, setImmediateLeader] = React.useState([]);

    const { t } = useTranslation();

    React.useEffect(() => {
        if (loadingProf) {
            createSpinner({ target: document.getElementById('loadProf') })
            showSpinner(document.getElementById('loadProf'));
            setSpinner({ type: 'Bootstrap' });
        }
    }, [loadingProf]);

    React.useEffect(() => {
        if (typeof hasPermissionToEdit !== "undefined") {
            setHasPermission(hasPermissionToEdit);
        } else {
            setHasPermission(true);
        }
    }, [hasPermissionToEdit]);

    React.useEffect(() => {
        setLoadingProf(true);

        async function load() {
            let _management = await getProfileManagement(globalState.accessToken, i18n.language)
                .then(response => { if (response.ok) { return response.json(); } });
            let _areas = await getProfileAreas(globalState.accessToken, i18n.language)
                .then(response => { if (response.ok) { return response.json(); } });
            let _departments = await getProfileDepartments(globalState.accessToken, i18n.language)
                .then(response => { if (response.ok) { return response.json(); } });
            let _immediate = await users(globalState.accessToken)
                .then(response => { if (response.ok) { return response.json(); } });

            addTooltip(_management, i18n.language ==="pt" ? "nomePt" : "nomeEn");
            setManagementSelect(_management);
            addTooltip(_areas, i18n.language ==="pt" ? "nomePt" : "nomeEn");
            setAreaSelect(_areas);
            addTooltip(_departments, i18n.language ==="pt" ? "nomePt" : "nomeEn");
            setDepartamentSelect(_departments);
            addTooltip(_immediate, "nome");
            setImmediateLeader(_immediate);

            hideSpinner(document.getElementById('loadProf'));
            setLoadingProf(false);
        }
        load();
    }, [i18n.language]);

    const addTooltip = (items, prop) => {
        items && items.length > 0 && items.map(item => item.tooltip = { title: item[prop] });
    }

    const dropdownListCreated = () => {
        if (hasPermission) {
            businessAreaRef.current.enabled = true;
            departmenRef.current.enabled = true;
            managementRef.current.enabled = true;
            superiorRef.current.enabled = true;
        } else {
            businessAreaRef.current.enabled = false;
            departmenRef.current.enabled = false;
            managementRef.current.enabled = false;
            superiorRef.current.enabled = false;
        }
    };

    const dateValeChanged = e =>{
        setInfo(info => ({ ...info, dataInicioVale: e.value }));
    }

    return (
        loadingProf ? <div id="loadProf"></div> :
            <div className="profData" >
                <div className="top d-flex flex-row align-items-center" >
                    <div className="mdl2 mdl2-suitcase" />
                    <h2> {t('ProfData:title')} </h2>
                </div>
                <div className="three d-flex flex-row align-items-center" >
                    <div className="input" >
                        <label htmlFor="dataInicio"> {t('ProfData:start_date_vale')} </label>
                        <DatePickerComponent
                            id="dataInicio"
                            cssClass={!hasPermission ? "" : "e-filled"}
                            strictMode={true}
                            placeholder={t("UserVale:date")}
                            value={info && info.dataInicioVale}
                            change={dateValeChanged}
                            locale={i18n.language}
                            disabled={!hasPermission}
                        />
                    </div>
                    <div className="input" >
                        <label htmlFor="cargo"> {t('ProfData:occupation')} </label>
                        <TextBoxComponent
                            id="cargo"
                            cssClass={!hasPermission ? "" : "e-filled"}
                            placeholder={t("UserVale:position")}
                            htmlAttributes={{ maxLength: 150 }}
                            value={info && info.cargo}
                            disabled={!hasPermission}
                            blur={(e) => setInfo(info => ({ ...info, cargo: e.value }))}
                        />
                    </div>
                    <div className="input" >
                        <label htmlFor="matricula"> {t('ProfData:registration')} </label>
                        <TextBoxComponent
                            id="matricula"
                            cssClass={!hasPermission ? "" : "e-filled"}
                            placeholder={t("UserVale:enrollment")}
                            htmlAttributes={{ maxLength: 100 }}
                            value={info && info.matricula}
                            blur={(e) => setInfo(info => ({ ...info, matricula: e.value }))}
                            disabled={!hasPermission}
                        />
                    </div>
                </div>
                <div className="one" >
                    {admin &&
                        <div className="checkItem" >
                            <CheckBoxComponent
                                label={t("UserVale:management")}
                                checked={info.cargoGerencia}
                                change={(e) => setInfo(info => ({ ...info, cargoGerencia: e.checked }))}
                                disabled={!hasPermission}
                            />
                        </div>
                    }
                    <div className="input" >
                        <label htmlFor="atividades"> {t('ProfData:main_activity')} </label>
                        <TextBoxComponent
                            id="atividades"
                            multiline={true}
                            placeholder={t("UserVale:main_activity")}
                            htmlAttributes={{ maxLength: 500 }}
                            value={info && info.atividadePrincipal}
                            blur={(e) => setInfo(info => ({ ...info, atividadePrincipal: e.value }))}
                            disabled={!hasPermission}
                        />
                    </div>
                </div>
                <div className="two d-flex flex-row align-items-center" >
                    <div className="input" >
                        <label htmlFor="departamento"> {t('ProfData:department')} <label className="e-error">{info.funcionarioVale && "*"}</label></label>
                        <DropDownListComponent
                            id="departamento"
                            name="departamento"
                            ref={departmenRef}
                            cssClass={!hasPermission ? "" : "e-filled"}
                            //allowFiltering={true}
                            dataSource={departamentSelect}
                            fields={{ text: (i18n.language) ==="pt" ? "nomePt" : "nomeEn", value: "id", htmlAttributes: "tooltip" }}
                            placeholder={t("UserVale:department")}
                            value={info && info.idPerfilDepartamento}
                            change={(e) => setInfo(info => ({ ...info, idPerfilDepartamento: e.itemData ? e.itemData.id : null }))}
                            created={dropdownListCreated}
                            showClearButton
                        />
                    </div>
                    <div className="input" >
                        <label htmlFor="gerencia"> {t('ProfData:management')} <label className="e-error">{info.funcionarioVale && "*"}</label></label>
                        <DropDownListComponent
                            id="gerencia"
                            name="gerencia"
                            ref={managementRef}
                            cssClass={!hasPermission ? "" : "e-filled"}
                            dataSource={managementSelect}
                            fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id", htmlAttributes: "tooltip" }}
                            placeholder={t("UserVale:choose_management")}
                            value={info && info.idPerfilGerencia}
                            change={(e) => setInfo(info => ({ ...info, idPerfilGerencia: e.itemData ? e.itemData.id : null }))}
                            created={dropdownListCreated}
                            showClearButton

                        />
                    </div>
                    <div className="input" >
                        <label htmlFor="area"> {t('ProfData:business_area')} <label className="e-error">{info.funcionarioVale && "*"}</label></label>
                        <DropDownListComponent
                            id="area"
                            name="area"
                            ref={businessAreaRef}
                            cssClass={!hasPermission ? "" : "e-filled"}
                            //allowFiltering={true}
                            dataSource={areaSelect}
                            fields={{ text: i18n.language ==="pt" ? "nomePt" : "nomeEn", value: "id", htmlAttributes: "tooltip" }}
                            placeholder={t("UserVale:business")}
                            value={info && info.idPerfilArea}
                            change={(e) => setInfo(info => ({ ...info, idPerfilArea: e.itemData ? e.itemData.id : null }))}
                            created={dropdownListCreated}
                            showClearButton

                        />
                    </div>
                    <div className="input" >
                        <label htmlFor="superior"> {t('ProfData:immediate_leader')} </label>
                        <DropDownListComponent
                            id="superior"
                            cssClass={!hasPermission ? "" : "e-filled"}
                            ref={superiorRef}
                            //allowFiltering={true}
                            dataSource={immediateLeader}
                            fields={{ text: "nome", value: "id", htmlAttributes: "tooltip" }}
                            placeholder={t("UserVale:name")}
                            value={info && info.idSuperiorDireto}
                            change={(e) => setInfo(info => ({ ...info, idSuperiorDireto: e.itemData ? e.itemData.id : null }))}
                            created={dropdownListCreated}
                            showClearButton
                        />
                    </div>
                </div>
                <div className="one" >
                    <div className="input" >
                        <label htmlFor="endereco"> {t('ProfData:workplace_address')} </label>
                        <TextBoxComponent
                            id="endereco"
                            multiline={true}
                            placeholder={t("UserVale:address")}
                            htmlAttributes={{ maxLength: 200 }}
                            value={info && info.enderecoTrabalho}
                            change={(e) => setInfo(info => ({ ...info, enderecoTrabalho: e.value }))}
                            disabled={!hasPermission}
                        />
                    </div>
                </div>
            </div>
    )
}
