import * as React from "react";
import { SharedDocumentsComponent } from './SharedDocumentsComponent';
import { useTranslation } from "react-i18next";

export const InternalResearchResults = () => {
    const { t } = useTranslation();

    return <SharedDocumentsComponent 
        permissionId="70" 
        path="Resultado Pesquisas Internas"
        folderManagerTitle={t("EmpCommunication:research_results")}
        title={t("EmpCommunication:research_results")} 
    />
}