import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { GlobalStateContext } from "../../general/GlobalState";
import { MailingContactsContext } from "./MailingContactsContext";
import { ProfilePhotoContext } from "../../utilities/ProfilePhoto/ProfilePhotoContext"
import { Container, Row } from "reactstrap";
import { Actions } from "../../utilities/Actions";
import { TextBoxComponent, MaskedTextBoxComponent, FormValidator } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { GroupsBox } from "../../Shared/GroupsBox";
import { ProfilePhotoComponent } from "../../utilities/ProfilePhoto/ProfilePhotoComponent"
import { getMailingContactItem, addOrUpdateContactItem, deleteMailingContact, deleteMailingContactPhoto, getMailingContactPhoto } from "../../../services/MailingSVC";
import { getPressMediaTypes, getCountries, getStates, getCities } from "../../../services/SystemSVC";
import './contacts.css';
import PermissionRedirect from "../../utilities/PermissionRedirect/PermissionRedirect";
import { hasPermissions } from "../../utilities/Utils";
import { useForm } from "react-hook-form";
import { countriesJSON } from '../../../resources/countries.js';

export const ContactsItem = (props) => {
  const { register, triggerValidation, errors, clearError } = useForm();

  let idContact = props.match.params.id;
  let formObject;
  let ddlMediaTypes;
  let ddlCountries;
  let ddlStates;
  let ddlCities;
  let txtJournalist;
  let txtMediaOutlet;
  let txtAddress;
  let txtEditoria;
  let txtOccupation;
  let txtOtherInfo;

  const { t } = useTranslation();

  const initialValue = {
    id: null,
    jornalista: "",
    idtipoMidia: null,
    nomeVeiculo: "",
    email: [],
    telefone: [],
    idpais: 6,
    idestado: null,
    idcidade: null,
    editoria: "",
    cargo: "",
    endereco: "",
    outrasInformacoes: "",
    grupos: [],
    fotoB64: "",
    importado: true,
  }

  const countryDefault = countriesJSON.find(i => i18n.language === "pt" ? i.namePt === 'Brasil' : i.nameEn === 'Brazil');

  const [globalState] = React.useContext(GlobalStateContext);
  const mailingState = React.useContext(MailingContactsContext);
  const [photo, setPhoto] = React.useContext(ProfilePhotoContext);
  const [redirect, setRedirect] = React.useState(false);
  const [dialogDelete, setDialogDelete] = React.useState(false);
  const [openCountries, setOpenCountries] = React.useState(null);
  const [dataCountries, setDataCountries] = React.useState([]);
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const [dataFlags, setDataFlags] = React.useState([]);

  const [newPhone, setNewPhone] = React.useState(
    () => {
      if (mailingState?.item?.telefone.length > 0) {
        return [...mailingState.item.telefone];
      }
      return [{
        telefone: "",
        status: 0,
        country: countryDefault
      }]
    }
  );

  const [newEmail, setNewEmail] = React.useState(
    () => {
      if (mailingState?.item?.email.length > 0) {
        return mailingState.item.email;
      }
      return [{
        email: "",
        status: 0
      }]
    }
  );

  const phoneRef = React.useRef();

  const handlePhoneRef = (ref) => {
    if (phoneRef?.current?.contains(ref?.target)) {
      return;
    }
    setOpenCountries(null);
  }

  const _controls = {
    class: "contact",
    savePrimary: false,
    excludePrimary: true,
    area: "Contatos Jornalistas",
    customPermissions: idContact ? hasPermissions(10, "Edit", globalState) : hasPermissions(10, "Create", globalState),
    urlView: "",
    urlPreview: "",
    customPermissionsDelete: idContact && hasPermissions(10, "Delete", globalState),
    urlDuplicate: "",
    urlSave: () => {
      if (formObject.validate()) {
        setDisabledBtn(true);
        const data = mailingState.item;
        data.fotoB64 = photo && photo.b64 ? photo.b64.split(',')[1] : null;
        let arrGroups = [];
        mailingState.groupsSelected.map(item => {
          arrGroups.push({ id: item });
        })
        data.grupos = arrGroups;
        data.telefone = newPhone.map(phone => ({
          ...phone,
          country: phone.country.nameEn
        }))
        if (photo && photo.delete) {
          deleteMailingContactPhoto(globalState.accessToken, idContact).then(response => {
            if (response.ok) {
              addOrUpdateContactItem(globalState.accessToken, data).then(response => {
                setRedirect(response.ok)
                setDisabledBtn(false);
              }).catch(() => {
                setDisabledBtn(false);
              });
            }
            setDisabledBtn(false);
          }).catch(() => {
            setDisabledBtn(false);
          });
        }
        else {
          addOrUpdateContactItem(globalState.accessToken, data).then(response => {
            setRedirect(response.ok)
            setDisabledBtn(false);
          }).catch(() => {
            setDisabledBtn(false);
          });
        }

      }
    },
    urlSend: "",
    urlCancel: () => setRedirect(true),
    urlDelete: idContact ? () => setDialogDelete(true) : ""
  };

  React.useEffect(() => {
    const formValidator = {
      rules: {
        'jornalista': {
          required: [true, t('Validation:required')]
        },
        'tipoMidia': {
          required: [true, t('Validation:required')],
        },
        'nomeVeiculo': {
          required: [true, t('Validation:required')],
        },
        'email': {
          required: [true, t('Validation:required')]
        }
      },
      customPlacement: (inputElement, errorElement) => {
        inputElement.parentElement.parentElement.appendChild(errorElement);
      }
    };

    formObject = new FormValidator('#formMailingContact', formValidator);
  });

  const loadSystemData = () => {
    let promises = [];
    if (!mailingState.mediaTypes) {
      promises.push(
        new Promise((resolve, reject) => {
          getPressMediaTypes(globalState.accessToken).then(response => {
            if (response.ok) {
              response.json().then(result => {
                mailingState.setMediaTypes(result);
                resolve();
              });
            }
            else
              reject();
          });
        })
      );
    }
    if (!mailingState.countries) {
      promises.push(
        new Promise((resolve, reject) => {
          getCountries(globalState.accessToken).then(response => {
            if (response.ok) {
              response.json().then(result => {
                mailingState.setCountries(result);
                resolve();
              });
            }
            else
              reject();
          });
        })
      );
    }

    if (!mailingState.states) {
      promises.push(
        new Promise((resolve, reject) => {
          getStates(globalState.accessToken).then(response => {
            if (response.ok) {
              response.json().then(result => {
                mailingState.setStates(result);
                resolve();
              });
            }
            else
              reject();
          });
        })
      )
    }
    if (!mailingState.cities) {
      promises.push(
        new Promise((resolve, reject) => {
          getCities(globalState.accessToken).then(response => {
            if (response.ok) {
              response.json().then(result => {
                mailingState.setCities(result);
                resolve();
              });
            }
            else
              reject();
          });
        })
      );
    }
    return promises;
  }

  const addNewEmail = () => {
    const emails = newEmail.filter(email => email.status !== 2);

    if (emails) {
      setNewEmail(newEmail => [...newEmail, { email: "", status: 0 }])
    }
  }

  const removeEmail = (index) => {
    if (newEmail[index] !==null) {
      if (newEmail[index].email !=="") {
        let pos = mailingState.item.email.map(function (e) { return e.email; }).indexOf(newEmail[index].email);
        let phoneFilter = mailingState.item.email.filter(findEmail => findEmail.email ===newEmail[index].email)[0];
        if (phoneFilter.status !==0) {
          mailingState.item.email[pos].status = 2;
        } else {
          mailingState.item.email.splice(pos, 1);
        }
      } if (newEmail[index].status ===0) {
        newEmail.splice(index, 1);
      }
    }
    setNewEmail(
      newEmail => [...newEmail]
    );
  }

  const handleChangeEmail = (index, e) => {
    let value = e.value

    if (value) {
      if (newEmail[index].status ===3 || newEmail[index].status ===2) {
        newEmail[index].status = 1;
      }

      newEmail[index].email = e.value
    } else if (newEmail[index].status !== 0) {
      newEmail[index].status = 2;
    }
    if (newEmail[index].email !=="") {
      let emailFilter = mailingState.item.email.filter(findEmail => findEmail.email ===newEmail[index].email);
      if (emailFilter.length ===0) {
        mailingState.item.email.push(newEmail[index]);
      }
    }
  }

  const addNewPhone = () => {
    const telefones = newPhone.filter(telefone => telefone.status !== 2);

    if (telefones) {
      setNewPhone(newTelefone => [
        ...newTelefone,
        {
          telefone: "",
          status: 0,
          country: countryDefault
        }
      ])
    }
  }

  const removePhone = (index) => {
    let temp = [...newPhone];

    if (newPhone[index] !==null) {
      if (newPhone[index].telefone !=="") {
        let pos = newPhone.map(function (e) { return e.telefone; }).indexOf(newPhone[index].telefone);
        let phoneFilter = newPhone.filter(findPhone => findPhone.telefone ===newPhone[index].telefone)[0];
        if (phoneFilter.status !==0) {
          mailingState.item.telefone[pos].status = 2;
          temp[index].status = 2;
        } else {
          mailingState.item.telefone.splice(pos, 1);
          // temp[index].splice(index, 1);
        }
      } if (newPhone[index].status ===0) {
        temp.splice(index, 1);
      }
    }
    setNewPhone(temp);
  }

  const handleChangePhone = (index, e) => {
    var numberPattern = /\d+/g;
    let value = e.value

    if (value) {
      const formattedValue = value.match(numberPattern).join('');

      if (newPhone[index].telefone !==formattedValue && (newPhone[index].status ===3 || newPhone[index].status ===2)) {
        newPhone[index].status = 1;
      }

      newPhone[index].telefone = formattedValue;
    }
    else if (newPhone[index].status !== 0) {
      newPhone[index].status = 2;
    }

    if (newPhone[index].telefone !=="") {
      let phoneFilter = mailingState.item.telefone.filter(findPhone => findPhone.telefone ===newPhone[index].telefone);
      if (phoneFilter.length ===0) {
        mailingState.item.telefone.push(newPhone[index]);
      }
    }
  }

  React.useEffect(() => {
    async function getItems() {
      let _countries = await getCountries(globalState.accessToken)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        });
      const currentCountries = countriesJSON.filter(country => {
        return _countries.filter(item => item.nomeEn === country.nameEn).length > 0
      })
      setDataCountries(currentCountries);
      setDataFlags(currentCountries)
    }
    getItems();

    function getRefPhone() {
      document.addEventListener("mousedown", handlePhoneRef);

      return () => {
        document.removeEventListener("mousedown", handlePhoneRef);
      };
    };
    getRefPhone();

    let promises = loadSystemData();
    Promise.all(promises).then(() => {
      setPhoto(null);
      if (idContact) {
        getMailingContactItem(globalState.accessToken, idContact).then(response => {
          if (response.ok) {
            response.json().then((result) => {
              mailingState.setItem(result)

              if (result && result.telefone.length > 0) {
                const phone = result.telefone.map(phone => ({
                  ...phone,
                  country: countriesJSON.find(country => country.nameEn === phone.country)
                }))
                setNewPhone(phone);
              } else {
                setNewPhone([{
                  telefone: "",
                  status: 0,
                  country: countryDefault
                }]);
              }

              if (result && result.email.length > 0) {
                setNewEmail(result.email);
              } else {
                setNewEmail([{
                  email: "",
                  status: 0
                }]);
              }

            }).catch(console.error);
          }
        });
        const photoUrl = `${process.env.REACT_APP_API_URL}/_api/MailingContact/GetMailingContactPhoto?token=${globalState.tokenLightWeight}&mailingContactId=${idContact}`;
        getMailingContactPhoto(photoUrl, function (response) {
          if (response.size)
            setPhoto({ name: 'photo', size: response.size, type: '.png', url: photoUrl });
        });
      }
      else {
        mailingState.setItem(initialValue);
        mailingState.setGroupsSelected([]);
      }
    });
  }, []);

  React.useEffect(() => {
    if (!openCountries) {
      setDataFlags(dataCountries);
    }
  }, [openCountries]);

  React.useEffect(() => {
    if (ddlMediaTypes) {
      ddlMediaTypes.dataSource = [];
      ddlMediaTypes.dataSource = mailingState.mediaTypes;
    }
    if (ddlCountries) {
      ddlCountries.dataSource = [];
      ddlCountries.dataSource = mailingState.countries;
    }
    if (ddlStates) {
      ddlStates.dataSource = [];
      ddlStates.dataSource = mailingState.states;
    }
    if (ddlCities) {
      ddlCities.dataSource = [];
      ddlCities.dataSource = mailingState.cities;
    }
    if (mailingState.item)
      changePlaceholder();

  }, [i18n.language]);

  React.useEffect(() => {
    if (mailingState.mediaTypes && mailingState.item)
      ddlMediaTypes.value = mailingState.item.idtipoMidia;
  }, [mailingState.mediaTypes]);

  React.useEffect(() => {
    if (mailingState.countries && mailingState.item)
      ddlCountries.value = mailingState.item.idpais;
  }, [mailingState.countries]);

  React.useEffect(() => {
    if (mailingState.states && mailingState.item && mailingState.item.idpais === 6)
      ddlStates.value = mailingState.item.idestado;
  }, [mailingState.states]);

  React.useEffect(() => {
    if (mailingState.cities && mailingState.item && mailingState.item.idpais === 6)
      ddlCities.value = mailingState.item.idcidade;
  }, [mailingState.cities]);

  React.useEffect(() => {
    if (mailingState.item)
      if (mailingState.item.grupos)
        mailingState.setGroupsSelected(mailingState.item.grupos.map(item => item.id));
  }, [mailingState.item])

  React.useEffect(() => {
    if (mailingState.item && mailingState.item.email) {
      clearError(["email"]);
    }
  }, [mailingState.item]);

  const inputChange = e => {
    if (e.event) {
      const prop = e.event.currentTarget.type === 'textarea' ? e.event.currentTarget.name : e.event.currentTarget.id;
      mailingState.setItem({ ...mailingState.item, [prop]: e.value });
    }
  }

  const dropdownlistChange = e => {
    if (e.element) {
      if ([e.element.id] ==='idpais' && e.value !== 6)
        mailingState.setItem({ ...mailingState.item, idpais: e.value, idestado: null, idcidade: null });
      else
        mailingState.setItem({ ...mailingState.item, [e.element.id]: e.value });
    }
  }

  const handleFormSubmit = e => {
    e.preventDefault();
  }

  const confirmDelete = () => {
    deleteMailingContactPhoto(globalState.accessToken, idContact).then(response => {
      if (response.ok) {
        deleteMailingContact(globalState.accessToken, idContact).then(response => {
          if (response.ok) {
            const itemsFiltered = mailingState.contactListItems.filter(contactItem => contactItem.id !==idContact);
            mailingState.setContactListItems(itemsFiltered);
            setRedirect(true);
          }
        });
      }
    })
  }

  const changePlaceholder = () => {
    txtJournalist.placeholder = t("Contact:journalist_placeholder");
    txtMediaOutlet.placeholder = t("Contact:media_outlet_placeholder");
    txtAddress.placeholder = t("Contact:address_placeholder");
    txtEditoria.placeholder = t("Contact:editorial_placeholder");
    txtOccupation.placeholder = t("Contact:occupation_placeholder");
    txtOtherInfo.placeholder = t("Contact:other_information_placeholder");
  }

  const handleChangeCountry = (country, index) => {
    const newSelectedPhone = [...newPhone];
    newSelectedPhone[index] = {
      ...newSelectedPhone[index],
      country: country
    }

    if (newSelectedPhone[index].id) {
      newSelectedPhone[index].status = 1;
    }
    setNewPhone(newSelectedPhone);
    setOpenCountries(null);
  }

  const newCountries = (item) => {
    const country = dataCountries.filter(i => i.nameEn === item);

    return country.map(i => i.flag);
  }

  const handleChangeFlags = (ev) => {
    const newDataFlags = dataCountries.filter(
      flags => ev.target.value !== ""
        ? i18n.language === "pt"
          ? flags?.namePt.toLowerCase().includes(ev.target.value.toLowerCase())
          : flags?.nameEn.toLowerCase().includes(ev.target.value.toLowerCase())
        : true
      );

    setDataFlags(newDataFlags);
  }

  return (
    <>
      {globalState.accessToken === null ? <Redirect push={true} to='/login' /> :
        redirect ? <Redirect push={true} to='/press/mailing/contacts' /> :
          (mailingState.item && mailingState.mediaTypes && mailingState.countries && mailingState.states && mailingState.cities) &&
          <>
            <PermissionRedirect id="10" />

            <div className="DefaultTop" />
            <Container className="containerTop">
              <div className="topTitleButtons">
                <Row>
                  <div className="col-12">
                    <div className="title">
                      <h1>{idContact ? t('Contact:edit_title') : t('Contact:create_title')}</h1>
                    </div>
                  </div>
                </Row>
              </div>
              <Row>
                <div className="col-xl-1 col-auto">
                  <ProfilePhotoComponent showAvatar={true} journalist={mailingState.item.jornalista} idContact={idContact} />
                </div>
                <div className="col-xl-8 col-md-7 col-12">
                  <form id="formMailingContact" onSubmit={handleFormSubmit}>
                    <div className="contactItem">
                      <div className="top d-flex flex-row align-items-center" >
                        <div className="mdl2 mdl2-suitcase" />
                        <h2>{t("Contact:contact_information")}</h2>
                      </div>
                      <div className="content">
                        <div className="line x2 l">
                          <div className="input">
                            <label>{t("Contact:journalist")}*</label>
                            <TextBoxComponent
                              id="jornalista"
                              ref={textBox => txtJournalist = textBox}
                              name="jornalista"
                              htmlAttributes={{ maxlength: "100" }}
                              cssClass="e-flat"
                              placeholder={t("Contact:journalist_placeholder")}
                              value={mailingState.item ? mailingState.item.jornalista : ""}
                              change={inputChange}
                            />
                          </div>
                          <div className="input">
                            <label>{t("Contacts:media_type")}*</label>
                            <DropDownListComponent
                              id="idtipoMidia"
                              ref={(dropdownlist) => ddlMediaTypes = dropdownlist}
                              name="tipoMidia"
                              dataSource={mailingState.mediaTypes}
                              fields={{ text: (i18n.language) ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                              cssClass="e-flat"
                              locale={i18n.language}
                              placeholder={t("Contact:media_type_placeholder")}
                              showClearButton={true}
                              value={mailingState.item ? mailingState.item.idtipoMidia : null}
                              change={dropdownlistChange}
                            />
                          </div>
                        </div>
                        <div className="line">
                          <div className="input">
                            <label>{t("Contacts:media_outlet")}*</label>
                            <TextBoxComponent
                              id="nomeVeiculo"
                              ref={textBox => txtMediaOutlet = textBox}
                              name="nomeVeiculo"
                              htmlAttributes={{ maxlength: "100" }}
                              cssClass="e-flat"
                              placeholder={t("Contact:media_outlet_placeholder")}
                              value={mailingState.item ? mailingState.item.nomeVeiculo : ""}
                              change={inputChange}
                            />
                          </div>
                        </div>
                        <div className="line x2">

                          <div className="input scroll-horizontal">
                            {newEmail.filter(email => email.status !== 2) &&
                              <div className='addLineMailing'>
                                <label>{t("Contacts:email")}*</label>
                                <div className="mdl2 mdl2-plus"
                                  onClick={() => addNewEmail()}
                                />
                              </div>
                            }
                            {newEmail.length > 0 &&
                              newEmail.map((item, index) => item.status !== 2 && (
                                <div key={index} className="lineMailing">
                                  <TextBoxComponent
                                    id="email"
                                    ref={register({ required: true, pattern: /^\S+@\S+$/ })}
                                    blur={() => triggerValidation('email')}
                                    name="email"
                                    htmlAttributes={{ maxlength: "100" }}
                                    cssClass="e-flat"
                                    placeholder={t("Contact:email_placeholder")}
                                    value={mailingState.item.email ? item.email : ""}
                                    change={(e) => handleChangeEmail(index, e)}
                                  />
                                  <div className="mdl2 mdl2-minus"
                                    onClick={() => removeEmail(index)}
                                  />
                                </div>
                              ))
                            }
                            {errors.email &&
                              <>
                                {errors.email.type === "pattern" &&
                                  <label className="e-error" style={{ display: "block" }}>{t('Validation:invalidMail')}</label>
                                }
                              </>
                            }
                          </div>
                          <div className="input scroll-horizontal">
                            {newPhone.filter(phone => phone.status !== 2) &&
                              <div className="addLineMailing">
                                <label>{t("Contacts:phone")}</label>
                                <div className="mdl2 mdl2-plus" onClick={() => addNewPhone()} />
                              </div>
                            }
                            {newPhone?.length > 0 &&
                              newPhone.map((item, index) => item.status !== 2 && (
                                <div key={index} className="lineMailing">

                                  <MaskedTextBoxComponent
                                    id="telefone"
                                    name="telefone"
                                    htmlAttributes={{ maxlength: "20" }}
                                    className="numbers"
                                    // placeholder={t("Contact:phone_placeholder")}
                                    value={mailingState?.item?.telefone ? item.telefone : ""}
                                    change={(e) => handleChangePhone(index, e)}
                                    promptChar=" "
                                    mask={`${item?.country?.dialCode ? item.country.dialCode : '+'}[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]`}
                                  />

                                  <div className="mdl2 mdl2-minus"
                                    onClick={() => removePhone(index)}
                                  />

                                  <div
                                    className="containerCountry" onClick={() => setOpenCountries(index)}>
                                    <img
                                      className='countryFlag'
                                      src={item?.country?.flag || newCountries(item?.country)}
                                      alt={i18n.language === "pt" ? item?.country?.namePt : item?.country?.nameEn || item?.country}
                                    />
                                    <span className='countryIcon'>&#9660;</span>
                                  </div>

                                  {openCountries === index &&
                                    <div ref={phoneRef} className="dropDownList">
                                      <input onChange={handleChangeFlags} className="inputFlags" placeholder="Pesquise o país"/>
                                      {dataFlags.map((country, i) => (
                                        <div
                                          className="flex choosenLanguage e-control e-dropdown-btn e-lib e-btn"
                                          key={i}
                                          onClick={() => handleChangeCountry(country, index)}
                                        >
                                          <img className='countryFlag' src={country?.flag} alt={i18n.language === "pt" ? country?.namePt : country?.nameEn} />
                                          <p className='countryName'>{country && `${i18n.language === "pt" ? country?.namePt : country?.nameEn} ${country?.dialCode}`}</p>
                                        </div>
                                      ))}
                                    </div>
                                  }
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="line x3">
                          <div className="input">
                            <label>{t("Contacts:country")}</label>
                            <DropDownListComponent
                              id="idpais"
                              name="pais"
                              ref={(dropdownlist) => ddlCountries = dropdownlist}
                              dataSource={mailingState.countries}
                              fields={{ text: (i18n.language) ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                              cssClass="e-flat"
                              locale={i18n.language}
                              placeholder={t("Contact:country_placeholder")}
                              showClearButton={true}
                              value={mailingState.item ? mailingState.item.idpais : null}
                              change={dropdownlistChange}
                              allowFiltering={true}
                            />
                          </div>
                          {(mailingState.item.idpais === 6) ? (
                            <>
                              <div className="input">
                                <label>{t("Contacts:state")}</label>
                                <DropDownListComponent
                                  id="idestado"
                                  name="estado"
                                  ref={(dropdownlist) => ddlStates = dropdownlist}
                                  dataSource={mailingState.states}
                                  fields={{ text: (i18n.language) ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                                  cssClass="e-flat"
                                  locale={i18n.language}
                                  placeholder={t("Contact:state_placeholder")}
                                  showClearButton={true}
                                  value={mailingState.item ? mailingState.item.idestado : null}
                                  change={dropdownlistChange}
                                />
                              </div>
                              <div className="input">
                                <label>{t("Contacts:city")}</label>
                                <DropDownListComponent
                                  id="idcidade"
                                  name="cidade"
                                  ref={(dropdownlist) => ddlCities = dropdownlist}
                                  readOnly={mailingState.item && !mailingState.item.idestado}
                                  dataSource={mailingState.cities.filter(city => mailingState.item && city.idEstado === mailingState.item.idestado)}
                                  fields={{ text: (i18n.language) ==="pt" ? "nomePt" : "nomeEn", value: "id" }}
                                  cssClass="e-flat"
                                  locale={i18n.language}
                                  placeholder={t("Contact:city_placeholder")}
                                  showClearButton={true}
                                  value={mailingState.item ? mailingState.item.idcidade : null}
                                  change={dropdownlistChange}
                                />
                              </div>
                            </>
                          ) : (
                            <>

                              <div className="input">
                                <label>{t("Contact:editorial")}</label>
                                <TextBoxComponent
                                  id="editoria"
                                  ref={textBox => txtEditoria = textBox}
                                  name="editoria"
                                  htmlAttributes={{ maxlength: "500" }}
                                  cssClass="e-flat"
                                  placeholder={t("Contact:editorial_placeholder")}
                                  value={mailingState.item ? mailingState.item.editoria : ""}
                                  change={inputChange}
                                />
                              </div>
                              <div className="input">
                                <label>{t("Contact:occupation")}</label>
                                <TextBoxComponent
                                  id="cargo"
                                  ref={textBox => txtOccupation = textBox}
                                  name="cargo"
                                  htmlAttributes={{ maxlength: "120" }}
                                  cssClass="e-flat"
                                  placeholder={t("Contact:occupation_placeholder")}
                                  value={mailingState.item ? mailingState.item.cargo : ""}
                                  change={inputChange}
                                />
                              </div>
                            </>
                          )
                          }
                        </div>
                        {(mailingState.item.idpais === 6) && <div className="line x2">
                          <div className="input">
                            <label>{t("Contact:editorial")}</label>
                            <TextBoxComponent
                              id="editoria"
                              ref={textBox => txtEditoria = textBox}
                              name="editoria"
                              htmlAttributes={{ maxlength: "500" }}
                              cssClass="e-flat"
                              placeholder={t("Contact:editorial_placeholder")}
                              value={mailingState.item ? mailingState.item.editoria : ""}
                              change={inputChange}
                            />
                          </div>
                          <div className="input">
                            <label>{t("Contact:occupation")}</label>
                            <TextBoxComponent
                              id="cargo"
                              ref={textBox => txtOccupation = textBox}
                              name="cargo"
                              htmlAttributes={{ maxlength: "120" }}
                              cssClass="e-flat"
                              placeholder={t("Contact:occupation_placeholder")}
                              value={mailingState.item ? mailingState.item.cargo : ""}
                              change={inputChange}
                            />
                          </div>
                        </div>}
                        <div className="line">
                          <div className="input">
                            <label>{t("Contact:address")}</label>
                            <TextBoxComponent
                              id="endereco"
                              ref={textBox => txtAddress = textBox}
                              name="endereco"
                              htmlAttributes={{ maxlength: "250" }}
                              cssClass="e-flat"
                              placeholder={t("Contact:address_placeholder")}
                              value={mailingState.item ? mailingState.item.endereco : ""}
                              change={inputChange}
                            />
                          </div>
                        </div>
                        <div className="line">
                          <div className="input">
                            <label>{t("Contact:other_information")}</label>
                            <TextBoxComponent
                              id="outrasInformacoes"
                              ref={textBox => txtOtherInfo = textBox}
                              name="outrasInformacoes"
                              multiline
                              htmlAttributes={{ maxlength: "1100", rows: "20" }}
                              cssClass="e-flat"
                              placeholder={t("Contact:other_information_placeholder")}
                              value={mailingState.item ? mailingState.item.outrasInformacoes : ""}
                              change={inputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-3 col-12">
                  <Actions disabled={disabledBtn} controls={_controls} />
                  <div className="groupsBox">
                    <div className="top d-flex flex-row align-items-center">
                      <div className="mdl2 mdl2-group" />
                      <h2>{t("Contact:groups")}</h2>
                    </div>
                    <GroupsBox />
                  </div>
                </div>
              </Row>
            </Container>
            <DialogComponent
              id="deleteModal"
              isModal={true}
              visible={dialogDelete}
              locale={i18n.language}
            >
              <div>
                <div className="divTrash">
                  <label className="mdl2 mdl2-trash"></label>
                  <div className="phraseDelete">
                    <label id="delete">{t('Actions:delete')}</label>
                    <label id="labelDelete">{t('Actions:confirmDeleteMsg')}</label>
                  </div>
                </div>
                <hr />
                <div className="footerButtons">
                  <button onClick={() => setDialogDelete(false)}>{t('Actions:cancel')}</button>
                  <button onClick={() => confirmDelete()}>{i18n.language ==="pt" ? "Sim" : "Yes"}</button>
                </div>
              </div>
            </DialogComponent>
          </>
      }
    </>
  )
}
