import * as React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';

import { GlobalStateContext } from "../general/GlobalState";
import { PressReportStateContext } from "./PressReportState";
import { getAllBusinessAreas, getCountries, getRegional } from "../../services/SystemSVC";
import { getPressItemsFiltered } from "../../services/PressSVC";

export const FilterComponent = ({ idTipo }) => {
  const { t } = useTranslation();
  const [globalState] = React.useContext(GlobalStateContext);
  const [pressReportState, setPressReportState] = React.useContext(PressReportStateContext);
  const [fillBusinessAreaData, setFillBusinessAreaData] = React.useState([]);
  const [fillCountriesData, setFillCountriesData] = React.useState([]);
  const [fillRegionalData, setFillRegionalData] = React.useState([]);
  const [filteredPressItems, setFilteredPressItems] = React.useState({
    idTipo: idTipo,
    isPT: i18n.language === "pt" ? true : false,
    idAreaNegocio: null,
    dataInicio: null,
    dataFim: null,
    bloqueado: null,
    idPais: null,
    idRegional: null,
  });

  const [spinnerDuration, setSpinnerDuration] = React.useState(20000);

  const initialLoad = async () => {
    let _businessAreas = await getAllBusinessAreas(globalState.accessToken)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      });
    let _countries = await getCountries(globalState.accessToken)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      });
    let _regional = await getRegional(globalState.accessToken)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      });

    setFillBusinessAreaData(_businessAreas);
    setFillCountriesData(_countries);
    setFillRegionalData(_regional);
  };

  React.useEffect(() => {
    initialLoad();
  }, [i18n.language]);

  const generateReport = () => {
    let filterObject = {
      businessArea: filteredPressItems.idAreaNegocio,
      startPeriod: filteredPressItems.dataInicio,
      endPeriod: filteredPressItems.dataFim,
      status: filteredPressItems.bloqueado,
      country: filteredPressItems.idPais,
      regional: filteredPressItems.idRegional,
    }

    const reports = true;

    getPressItemsFiltered(globalState.accessToken, filteredPressItems, idTipo, reports)
      .then(response => {
        if (response.ok) {
          Promise.resolve(response.json()).then(data => {

            setPressReportState({
              data: data,
              allData: filterObject,
            });
            setSpinnerDuration(0);
          });
        }
      });
  }

  const statusRelease = [
    {
      text: i18n.language === 'pt' ? 'Bloqueado' : 'Blocked',
      value: true
    }, {
      text: i18n.language === 'pt' ? 'Terminado' : 'Finished',
      value: false
    }
  ]

  return (
    <>
      <div id="filterComponent">
        <div className="headerFilter">
          <span className="mdl2 mdl2-filter" />
          <label>{t("PressReport:filters")}</label>
        </div>
        <hr className="hrBar" />
        <div className="inputs">
          <div className="input">
            <label>{t("PressReport:business_area")}</label>
            <DropDownListComponent
              id="IdAreaNegocio"
              placeholder={t('PressReport:business_area_placeholder')}
              dataSource={fillBusinessAreaData}
              fields={{ text: i18n.language === "pt" ? "nomePt" : "nomeEn", value: "id" }}
              value={filteredPressItems ? filteredPressItems.idAreaNegocio : ''}
              change={(e) => setFilteredPressItems(
                filteredPressItems => ({
                  ...filteredPressItems,
                  idAreaNegocio: e.value
                })
              )}
              showClearButton={true}
            />
          </div>
          <div className="input">
            <label>{t("PressReport:period")}</label>
            <DateRangePickerComponent
              id="IdPeriodo"
              strictMode={true}
              placeholder={t("PressReport:period")}
              locale={i18n.language}
              showClearButton={true}
              allowEdit={false}
              change={(e) => setFilteredPressItems(
                filteredPressItems => ({
                  ...filteredPressItems,
                  dataInicio: e.startDate && e.startDate.toISOString().split('.')[0],
                  dataFim: e.endDate && e.endDate.toISOString().split('.')[0]
                })
              )}
            />
          </div>
          <div className="input">
            <label>{t("PressReport:status")}</label>
            <DropDownListComponent
              id="IdStatus"
              placeholder={t('PressReport:status_placeholder')}
              dataSource={statusRelease}
              fields={{ text: "text", value: "value" }}
              value={filteredPressItems ? filteredPressItems.bloqueado : ''}
              change={(e) => setFilteredPressItems(
                filteredPressItems => ({
                  ...filteredPressItems,
                  bloqueado: e.value
                })
              )}
              showClearButton={true}
            />
          </div>
          <div className="input">
            <label>{t("PressReport:country")}</label>
            <DropDownListComponent
              id="IdCountry"
              placeholder={t('PressReport:country_placeholder')}
              dataSource={fillCountriesData}
              fields={{ text: (i18n.language === "pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
              allowFiltering={true}
              value={filteredPressItems ? filteredPressItems.idPais : ""}
              change={(e) => setFilteredPressItems(
                filteredPressItems => ({
                  ...filteredPressItems,
                  idPais: e.value
                })
              )}
              showClearButton={true}
            />
          </div>
          <div className="input">
            <label>{t("PressReport:regional")}</label>
            <DropDownListComponent
              id="IdRegional"
              placeholder={t('PressReport:regional_placeholder')}
              dataSource={fillRegionalData}
              fields={{ text: (i18n.language === "pt") ? 'nomePt' : 'nomeEn', value: 'id' }}
              value={filteredPressItems ? filteredPressItems.idRegional : ''}
              change={(e) => setFilteredPressItems(
                filteredPressItems => ({
                  ...filteredPressItems,
                  idRegional: e.value
                })
              )}
              showClearButton={true}
            />
          </div>
          <div className="button">
            <ProgressButtonComponent
              content={t('PressReport:buttonGenerateReport')}
              enableProgress={false}
              onClick={generateReport}
              isPrimary={true}
              duration={spinnerDuration}
            />
          </div>
        </div>
      </div>
    </>
  );
}
