import * as React from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { TextBoxComponent, MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import './utilities.css';

export const Export = (props) => {

    const { controls } = props;
    const { t } = useTranslation();

    return (
        (controls.pdf || controls.doc || controls.excel ) &&
        <div className="export" id={props.id ? props.id : ''}>
            <div className="top d-flex flex-row align-items-center">
                <div className="mdl2 mdl2-export" />
                <h2>{t('Export:title')}</h2>
            </div>
            <div className="content d-flex flex-column">
            {controls.pdf &&
                <ButtonComponent
                id="pdf"
                cssClass="e-primary e-flat"
                onClick={controls.pdf}
                >
                <div className="mdl2 mdl2-pdf" />
                {t('Export:generate_PDF')}</ButtonComponent>
            }
            {controls.doc &&
                <ButtonComponent
                id="doc"
                cssClass="e-primary e-flat"
                onClick={controls.doc}
                >
                <div className={`mdl2 mdl2-doc ${props.doc}`} />
                {t('Export:generate_Doc')}</ButtonComponent>
            }
            {controls.excel &&
                <ButtonComponent
                id="excel"
                cssClass="e-primary e-flat"
                onClick={controls.excel}
                >
                <div className="mdl2 mdl2-excel" />
                {t('Export:generate_Excel')}</ButtonComponent>
            }
            </div>
        </div>

    )
}
