import React, { useContext, createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DigitalMediaContext = createContext({});

const DigitalMediaProvider = ({ children }) => {
    const [currentItem, setCurrentItem] = useState(null);
    const [modalIsVisible, setModalIsVisible] = useState(false);

    const [regionalList, setRegionalList] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [users, setUsers] = useState([]);

    const { t } = useTranslation();

    const [pendingVideos, setPendingVideos] = useState({
        value: [],
    });

    const [approvedVideos, setApprovedVideos] = useState({
        value: [],
    });

    const changeCurrentItem = (id) => {
        const findCurrentVideo = [...pendingVideos.value, ...approvedVideos.value].find(video => video.id ===id);

        setCurrentItem(findCurrentVideo);
    };

    const editModal = (id) => {
        if (!id) {
            setCurrentItem(null);
            setModalIsVisible(true);
            return;
        }
        const findCurrentVideo = [...pendingVideos.value, ...approvedVideos.value].find(video => video.id ===id);

        setCurrentItem(findCurrentVideo);
        setModalIsVisible(true);
    };

    const changeModalVisible = (visible) => {
        setModalIsVisible(visible);
        setCurrentItem(null);
    };

    return (
        <DigitalMediaContext.Provider value={{
            changeCurrentItem,
            currentItem,
            setCurrentItem,
            editModal,
            changeModalVisible,
            modalIsVisible,
            regionalList,
            setRegionalList,
            countriesList,
            setCountriesList,
            categoryList,
            setCategoryList,
            statusList,
            setStatusList,
            users,
            setUsers,
            pendingVideos,
            setPendingVideos,
            approvedVideos,
            setApprovedVideos
        }}>
            {children}
        </DigitalMediaContext.Provider>
    );
};

const useDigitalMedia = () => {
    const context = useContext(DigitalMediaContext);

    if (!context) {
        throw new Error('useDigitalMedia must be within an DigitalMediaProvider');
    }

    return context;
};

export { useDigitalMedia, DigitalMediaProvider };