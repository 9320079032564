import React from 'react';
import i18n from 'i18next';
import { GlobalStateContext } from '../general/GlobalState';
import { MenuComponent } from '@syncfusion/ej2-react-navigations';
import { menu } from '../../services/MenuSVC';
import './menu.css';


export const MainMenu = () => {
    
    const [globalState, setGlobalState] = React.useContext(GlobalStateContext);
    const [itemsMain, setItemsMain] = React.useState([]);
    const [subItemsMain, setSubItemsMain] = React.useState([]);

    let animation = { duration: 800 };

    React.useEffect(() => {
        menu(globalState.accessToken, false)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(result => {
                setSubItemsMain(result);
            })
            .catch(error => {
                console.error(error);
            })
    }, []);

    React.useEffect(() => {
        setItemsMain(
            subItemsMain && subItemsMain.map((firstItem, firstIndex) => (
                {
                    iconCss: `mdl2 mdl2-${firstItem.classeIcone}`,
                    items:
                        firstItem.childs.map((secondItem, secondIndex) => (
                            {
                                items:
                                    secondItem.childs.map((thirdItem, thirdIndex) => (
                                        {
                                            text: i18n.language ==="pt" ? thirdItem.nomePT : thirdItem.nomeEN,
                                            url: thirdItem.url
                                        }
                                    )),
                                text: i18n.language ==="pt" ? secondItem.nomePT : secondItem.nomeEN,
                                url: secondItem.url
                            }
                        )),
                    text: i18n.language ==="pt" ? firstItem.nomePT : firstItem.nomeEN,
                }
            ))
        );
    }, [i18n.language, subItemsMain]);

    return (
        <MenuComponent
            id="mainMenu"
            items={itemsMain}
            enableScrolling={true}
            animationSettings={animation}
            locale={i18n.language}
        />
    )
}